import { Button, Col, Icon, Input, message, Modal, Row, Tree, Popover, Checkbox, Upload, Form, Empty } from 'antd';
import React, { forwardRef, useEffect, useState, useRef } from 'react';
import { CONSTANTS_REQ, IMAGE_FORMATS, VIDEO_FORMATS, CONSTANTS_FILE_TYPE, FONT_FORMATS } from '../utils/Constants';
import { requestGet, RESPONSE } from '../utils/RequestResolver';
import { geti18nText, Session } from '../utils/Session';
import { selectFolder } from '../slices/fileUploadSlices';
import moment from 'moment';
import { FormatFileSize } from '../utils/Utils';
import { FileUpload } from './fileUpload/FileUpload';
import { Spinner } from './Spinner';
import { useDispatch } from 'react-redux';
const { Search } = Input;
const { Dragger } = Upload;

const initTreeDate = [];

function updateTreeData(list, key, children) {
    return list.map((node) => {
        if (node.key === key) {
            if (children != undefined) {
                return {
                    ...node,
                    children,
                };
            } else {
                delete node.children;
                return {
                    ...node,
                };
            }
        } else if (node.children) {
            return {
                ...node,
                children: updateTreeData(node.children, key, children),
            };
        }
        return node;
    });
}

export const SelectFile = forwardRef((props, ref) => {
    const { value, onChange } = props;
    const [treeData, setTreeData] = useState(initTreeDate);
    const [filesTreeVisible, setFilesTreeVisible] = useState(null);
    const [separator, setSeparator] = useState('/');
    const [searchFilesText, setSearchFilesText] = useState(null);
    const [loadedKeys, setLoadedKeys] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [uploadsWithErrorExists, setUploadsWithErrorExists] = useState(false);
    const [uploadingFiles, setUploadingFiles] = useState([]);
    const [folderValue, setFolderValue] = useState(null);
    const [uploadVisible, setUploadVisible] = useState(false);
    const [arrayLengthNumber, setArrayLengthNumber] = useState(0);
    const [iterationNum, setIterationNum] = useState(0);
    const [isImage, setIsImage] = useState(false);
    const dispatch = useDispatch();
    const UploadProgress = props.uploadProgress;
    const [selectedKeys, setSelectedKeys] = useState(null);
    let draggerRef = React.createRef();

    const { TreeNode } = Tree;
    const intervalSearchText = useRef(null);
    const closeFilesTreeVisible = () => {
        setFilesTreeVisible(false);
        setTreeData(initTreeDate);
    };

    function handleChange(changedValue) {
        onChange(changedValue);
    }

    useEffect(() => {
        setTreeData(initTreeDate);
        setLoadedKeys([]);
        fetchTree(searchFilesText);
    }, [searchFilesText]);

    useEffect(() => {
        props.checkboxes && props.closeUploadModal && setFilesTreeVisible(false);
    }, [props.closeUploadModal]);

    const fetchTree = (searchText = null) => {
        const projectId = props.addedData.projectId == 0 ? 0 : Session.getProjectId();
        var params = {};
        params.type =
            props.fileType == CONSTANTS_FILE_TYPE.IMAGE
                ? 'image'
                : props.fileType == CONSTANTS_FILE_TYPE.VIDEO
                ? 'video'
                : null;
        if (params.type == null) delete params.type;
        if (searchText != null) {
            params.searchText = searchText;
        }

        if (props.folderRootTreeValue != null) {
            params.directory = props.folderRootTreeValue;
        }

        requestGet(CONSTANTS_REQ.DIRECTORY.TREE + '/' + projectId, params).then((result) => {
            if (result.status === RESPONSE.OK) {
                if (Array.isArray(result.data)) {
                    let children = [];
                    for (const element of result.data) {
                        switch (element.type) {
                            case 'DIRECTORY':
                                if (
                                    element.contentsLength > 0 ||
                                    props.fileType == CONSTANTS_FILE_TYPE.OTHER ||
                                    props.fileType == null
                                ) {
                                    children.push({
                                        title: generateNodeTitle(element.name, element.type),
                                        key: 'FOLDER-' + element.name, // '*' + new Date().getTime().toString(),
                                        value: element.name,
                                    });
                                }

                                break;
                            case 'FILE':
                                let node = {
                                    title: generateNodeTitle(element.name, element.type),
                                    key: 'FILE-' + element.name,
                                    isLeaf: true,
                                    value: element.name,
                                    path: element.relativePath,
                                    size: element.size,
                                    dateCreated: element.dateCreated,
                                };
                                if (props.fileType == CONSTANTS_FILE_TYPE.IMAGE) {
                                    if (
                                        IMAGE_FORMATS.includes(
                                            element.relativePath
                                                .toLowerCase()
                                                .substring(element.relativePath.lastIndexOf('.'))
                                        )
                                    ) {
                                        children.push(node);
                                    }
                                } else if (props.fileType == CONSTANTS_FILE_TYPE.VIDEO) {
                                    if (
                                        VIDEO_FORMATS.includes(
                                            element.relativePath
                                                .toLowerCase()
                                                .substring(element.relativePath.lastIndexOf('.'))
                                        )
                                    ) {
                                        children.push(node);
                                    }
                                } else {
                                    children.push(node);
                                }

                                break;
                        }
                    }

                    setTreeData(children);
                }
            }
        });
    };

    const getId = (key) => {
        const arr = (key + '').split('-');
        if (arr.length > 0) {
            return encodeURI(arr[1]);
        }
        return undefined;
    };

    const getAllowedFileTypes = () => {
        if (props.fileType == CONSTANTS_FILE_TYPE.IMAGE) {
            return 'image/*';
        } else if (props.fileType == CONSTANTS_FILE_TYPE.VIDEO) {
            return 'video/*';
        } else if (props.fileType == CONSTANTS_FILE_TYPE.APPLICATION) {
            return '.apk';
        } else if (props.fileType == CONSTANTS_FILE_TYPE.FONT) {
            return FONT_FORMATS;
        } else {
            // return '*';
            return '';
        }
    };

    function clearText(inp) {
        var wrong = 'čćšđžČĆŠĐŽ';
        var right = 'ccsdzCCSDZ';
        var re = new RegExp('[' + wrong + ']', 'ig');
        return inp.replace(re, function (m) {
            return right.charAt(wrong.indexOf(m));
        });
    }

    const closeUploadModal = (force = true) => {
        if (!uploading || force) {
            setUploadVisible(false);
            let newValues = selectedKeys;
            let fileName;
            let extension;
            let pos;
            let allowedImagesFormat = ['.png', '.jpg', '.svg', '.jpeg'];
            onEditCall(
                newValues.map((key) => {
                    pos = key.lastIndexOf('.');
                    if (pos > 0 && pos < key.length - 1) {
                        fileName = key
                            .substring(0, pos)
                            .replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/g, '')
                            .replace(/[ ]/g, '-');
                        fileName = clearText(fileName);
                        extension = key.substring(pos);
                        if (allowedImagesFormat.includes(extension)) {
                            return fileName + extension;
                        } else {
                            return null;
                        }
                    }
                }),
                null
            );
        }
    };

    const uploadFile = {
        name: 'file',
        accept: getAllowedFileTypes(),
        action: CONSTANTS_REQ.DISK_FILE.UPLOAD,
        showUploadList: false,
        multiple: props.multiple ? true : false,
        data: {
            projectId:
                props.addedData.projectId == 0 || props.addedData.projectId == undefined
                    ? 0
                    : props.addedData.projectId,
            folder: folderValue != null ? folderValue : '',
        },
        headers: {
            Authorization: 'Bearer ' + Session.getUserToken(),
        },
        beforeUpload: (file, files) => {
            setArrayLengthNumber(files.length);
            if (!(file.type == 'image/jpeg' || file.type == 'image/png' || file.type == 'video/mp4')) {
                return true;
            }
            const settings = Session.getAppValue('SETTINGS');
            if (file.type == 'image/jpeg' || file.type == 'image/png') {
                const maxImageFileSize = Number(settings['upload.max-image-file-size']);

                if (file.size / 1024 / 1024 > maxImageFileSize) {
                    message.warning(
                        geti18nText('disk.file.edit.maxsize.exception') + String(maxImageFileSize) + ' MB!'
                    );
                    return true;
                } else {
                    setSelectedKeys(files.map((file) => file.name));
                    return true;
                }
            }
            if (file.type === 'video/mp4') {
                const maxVideoFileSize = Number(settings['upload.max-video-file-size']);
                if (file.size / 1024 / 1024 > maxVideoFileSize) {
                    message.warning(
                        geti18nText('disk.file.edit.maxsize.exception') + String(maxVideoFileSize) + ' MB!'
                    );
                    return false;
                }

                var reader = new FileReader();
                var MP4Box = require('mp4box');
                var mp4box = MP4Box.createFile();
                mp4box.onError = function (e) {
                    console.log(e);
                };
                mp4box.onReady = function (info) {
                    if (info.tracks != null && info.tracks.length > 0) {
                        var track = info.tracks.find((x) => x.type.toLowerCase().includes('video'));
                        if (track == null) {
                            track = info.tracks[0];
                        }
                        const codec = track.codec;
                        if (codec != null && codec.substring(0, 4) != 'avc1') {
                            message.warning(geti18nText('disk.file.edit.video.codec.info'), 10);
                        }
                        if (track.timescale != null) {
                            const maxFR = Number(settings['upload.max-video-framerate']);
                            if (Number(track.timescale) > maxFR * 1000) {
                                message.warning(
                                    geti18nText('disk.file.edit.video.frameRate.info') + String(maxFR) + ' fps',
                                    10
                                );
                            }
                        }
                        if (track.video != null) {
                            const video = track.video;
                            const maxVideoSize = Number(settings['upload.max-video-size']);
                            if (video.width > maxVideoSize || video.height > maxVideoSize) {
                                message.warning(
                                    geti18nText('disk.file.edit.video.resolution.info') + String(maxVideoSize) + 'px',
                                    10
                                );
                            }
                        }
                    }
                };

                var filepos = 0;
                var append_data_to_mp4box = function (event) {
                    var arraybuffer = event.target.result;
                    if (arraybuffer) {
                        arraybuffer.fileStart = filepos; // adding fileStart property
                        var ret = mp4box.appendBuffer(arraybuffer);
                        filepos = event.loaded;
                    }
                };
                reader.onloadstart = append_data_to_mp4box;
                reader.onprogress = append_data_to_mp4box;
                reader.onloadend = append_data_to_mp4box;

                reader.readAsArrayBuffer(file);
            }
        },
        transformFile(file) {
            return new Promise((resolve) => {
                if (file.type == 'image/jpeg' || file.type == 'image/png') {
                    resizeImage(file).then((resized) => {
                        resolve(resized);
                    });
                } else {
                    resolve(file);
                }
            });
        },
        onChange: (info) => {
            setUploadingFiles(info.fileList.slice());
            if (info.fileList.some((f) => f.status === 'uploading')) {
                setUploading(true);
            } else {
                if (!info.fileList.some((f) => f.status === 'error')) {
                    setUploading(false);

                    dispatch(
                        selectFolder({
                            folderName: folderValue,
                        })
                    );
                    closeUploadModal(true);
                } else {
                    setUploadsWithErrorExists(true);
                    setUploading(false);
                }
            }

            if (info.file.status === 'done') {
                message.success(
                    `${geti18nText('upload.text.file')} ${geti18nText('upload.text.file')} ${
                        info.file.name
                    } ${geti18nText('upload.text.done')}`
                );

                if (props.checkboxes) {
                    setIterationNum(iterationNum + 1);
                    if (iterationNum === arrayLengthNumber - 1) {
                        setIterationNum(0);
                        setArrayLengthNumber(0);
                        setUploadVisible(false);
                    }
                }
                if (!props.multiple) {
                    onChange({
                        key: folderValue
                            ? separator + folderValue + separator + info.file.response
                            : separator + info.file.response,
                        label: info.file.response,
                        file_path: folderValue
                            ? separator + folderValue + separator + info.file.response
                            : separator + info.file.response,
                        name: info.file.response,
                        file_type: props.fileType,
                    });
                }
            } else if (info.file.status === 'error') {
                message.destroy();
                message.error(
                    `${geti18nText('upload.text.file')} ${info.file.name} ${geti18nText('upload.text.error')}`
                );
            }
        },
    };

    function resizeImage(file) {
        return new Promise((resolve) => {
            const settings = Session.getAppValue('SETTINGS');
            const maxSize = Number(settings['upload.max-image-size']);
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                const image = document.createElement('img');
                image.src = reader.result;
                image.onload = () => {
                    const maxImageFileSize = Number(settings['upload.max-image-file-size']);
                    const fileSize = file.size / 1024 / 1024;
                    var canvas = document.createElement('canvas'),
                        max_size = maxSize,
                        width = image.width,
                        height = image.height;
                    if (image.width <= maxSize && image.height <= maxSize && fileSize < maxImageFileSize) {
                        return resolve(file);
                    }
                    if (width > height) {
                        if (width > max_size) {
                            height *= max_size / width;
                            width = max_size;
                        }
                    } else {
                        if (height > max_size) {
                            width *= max_size / height;
                            height = max_size;
                        }
                    }

                    canvas.width = width;
                    canvas.height = height;
                    const ctx = canvas.getContext('2d');
                    ctx.drawImage(image, 0, 0, width, height);
                    ctx.canvas.toBlob(
                        (blob) => {
                            message.info(geti18nText('disk.file.edit.image.resize.info'), 10);
                            resolve(
                                new File([blob], file.name, {
                                    lastModified: file.lastModified,
                                    uid: file.uid,
                                    webkitRelativePath: file.webkitRelativePath,
                                    type: 'image/jpeg',
                                })
                            );
                        },
                        'image/jpeg',
                        0.8
                    );
                };
            };
        });
    }

    function generateNodeTitle(title, type) {
        return (
            <div
                style={{
                    color:
                        type == 'NEW' ? '#1890ff' : type == 'FIRSTPAGE' || type == 'NOTINMENU' ? '#52c41a' : undefined,
                }}
            >
                {type == 'FOLDER' && <Icon type="folder" style={{ marginRight: '10px' }} />}
                {type == 'FILE' && <Icon type="file" style={{ marginRight: '10px' }} />}
                {type == 'ROOT' && <Icon type="home" style={{ marginRight: '10px' }} />}
                {title}
            </div>
        );
    }

    function getUrlAndParamsForChildren(key) {
        const projectId = props.addedData.projectId == 0 ? 0 : Session.getProjectId();
        if (key.startsWith('FILE-')) {
            return { url: CONSTANTS_REQ.DIRECTORY.TREE + '/' + getId(key) };
        } else if (key.startsWith('FOLDER-')) {
            const folder = key.split['-'];
            return {
                url: CONSTANTS_REQ.DIRECTORY.TREE + '/' + projectId,
                params: { directory: getId(key) },
            };
        }

        return { url: CONSTANTS_REQ.DIRECTORY.TREE + '/' + projectId, params: {} };
    }

    function onLoadData(treeNode) {
        const key = treeNode.props.eventKey;
        return new Promise((resolve) => {
            const url = getUrlAndParamsForChildren(key + '');
            url.params.type =
                props.fileType == CONSTANTS_FILE_TYPE.IMAGE
                    ? 'image'
                    : props.fileType == CONSTANTS_FILE_TYPE.VIDEO
                    ? 'video'
                    : null;
            if (url.params.type == null) delete url.params.type;
            if (searchFilesText != null) {
                url.params.searchText = searchFilesText;
            }
            requestGet(url.url, url.params).then((result) => {
                if (result.status === RESPONSE.OK) {
                    if (Array.isArray(result.data)) {
                        let nodes = [];
                        for (const element of result.data) {
                            if (element.active == false) {
                                continue;
                            }
                            switch (element.type) {
                                case 'DIRECTORY':
                                    if (
                                        element.contentsLength > 0 ||
                                        props.fileType == CONSTANTS_FILE_TYPE.OTHER ||
                                        props.fileType == null
                                    ) {
                                        nodes.push({
                                            title: generateNodeTitle(element.name, 'FOLDER'),
                                            key: key + separator + element.name, //+ new Date().getTime(),
                                            value: element.name,
                                        });
                                    }
                                    break;
                                case 'FILE':
                                    let node = {
                                        title: generateNodeTitle(element.name, element.type),
                                        key: key + separator + element.name,
                                        isLeaf: true,
                                        value: element.name,
                                        path: element.relativePath,
                                        size: element.size,
                                        dateCreated: element.dateCreated,
                                    };

                                    if (props.fileType == CONSTANTS_FILE_TYPE.IMAGE) {
                                        if (
                                            IMAGE_FORMATS.includes(
                                                element.relativePath
                                                    .toLowerCase()
                                                    .substring(element.relativePath.lastIndexOf('.'))
                                            )
                                        ) {
                                            nodes.push(node);
                                        }
                                    } else if (props.fileType == CONSTANTS_FILE_TYPE.VIDEO) {
                                        if (
                                            VIDEO_FORMATS.includes(
                                                element.relativePath
                                                    .toLowerCase()
                                                    .substring(element.relativePath.lastIndexOf('.'))
                                            )
                                        ) {
                                            nodes.push(node);
                                        }
                                    } else {
                                        nodes.push(node);
                                    }

                                    break;
                            }
                        }

                        setTreeData((origin) => updateTreeData(origin, key, nodes));
                    }
                }
                resolve();
            });
        });
    }

    const onSelect = (selectedKeys, info) => {
        if (info.node.props.isLeaf) {
            handleChange({
                key: info.node.props.props.path,
                label: info.node.props.props.value,
                name: info.node.props.props.value,
                file_path: info.node.props.props.path,
                file_type: props.fileType,
            });
            setFilesTreeVisible(false);
        } else {
            message.error(`${geti18nText('upload.select.file.error')}`);
        }
    };

    function onEditCall(selectedKeys, e) {
        props.addMultiImages(selectedKeys.filter((key) => key !== null));
        setFilesTreeVisible(false);
    }

    function handleKeys(key, info) {
        setSelectedKeys(key);
    }

    const renderTreeNodes = (data) =>
        data.map((item) => {
            if (item.children) {
                return (
                    <TreeNode
                        title={
                            <div>
                                <span>{item.value}</span>
                            </div>
                        }
                        key={item.key}
                        props={item}
                    >
                        {renderTreeNodes(item.children)}
                    </TreeNode>
                );
            }
            return (
                <TreeNode
                    isLeaf={item.isLeaf ? item.isLeaf : false}
                    props={item}
                    title={
                        <div style={{ display: 'block' }}>
                            {item.isLeaf && props.fileType == CONSTANTS_FILE_TYPE.IMAGE && (
                                <>
                                    <Popover
                                        placement="bottomLeft"
                                        content={
                                            <img
                                                style={{ maxHeight: '300px', maxWidth: '500px' }}
                                                src={
                                                    CONSTANTS_REQ.STATIC +
                                                    props.addedData.projectId +
                                                    encodeURI(item.path)
                                                }
                                            />
                                        }
                                    >
                                        <Icon style={{ marginRight: '15px' }} type="file"></Icon>
                                    </Popover>
                                </>
                            )}
                            <span>{item.value}</span>
                            {item.isLeaf && (
                                <span
                                    style={{
                                        color: 'rgb(0 0 0 / 45%)',
                                        fontSize: '12px',
                                        marginRight: '15px',
                                        marginLeft: '15px',
                                    }}
                                >
                                    {moment(item.dateCreated).format('DD.MM.YYYY HH:mm:ss')}
                                </span>
                            )}
                            {item.isLeaf && (
                                <span style={{ color: 'rgb(0 0 0 / 45%)', fontSize: '12px' }}>
                                    {FormatFileSize(item.size)}
                                </span>
                            )}
                        </div>
                    }
                    key={item.key}
                ></TreeNode>
            );
        });
    const onSearchFiles = (value) => {
        if (intervalSearchText.current != null) {
            clearInterval(intervalSearchText.current);
        }
        setSearchFilesText(value);
        console.log('');
    };

    const onSearchChange = (name) => {
        if (intervalSearchText.current != null) {
            clearInterval(intervalSearchText.current);
        }
        const text = name.target.value;
        intervalSearchText.current = setTimeout(function () {
            setSearchFilesText(text);
        }, 2000);
    };

    function transformValue() {
        if (value instanceof Array && value.length > 0) {
            if (value[0].name) return value[0].name;
            else
                return value[0].file_path.substring(
                    value[0].file_path.lastIndexOf(separator) + 1,
                    value[0].file_path.length
                );
        }
        if (value instanceof Object) {
            if (value && value.name) {
                return value.name;
            } else return value.file_path.substring(value.file_path.lastIndexOf(separator) + 1, value.file_path.length);
        }

        return undefined;
    }

    function clearValue() {
        if (value) {
            handleChange(null);
        }
    }

    return (
        <React.Fragment>
            {!props.checkboxes && !props.fontSelect && (
                <Row>
                    <Col span={20}>
                        <Input
                            placeholder="Select file"
                            ref={ref}
                            value={transformValue()}
                            prefix={<Icon type="folder" />}
                            suffix={
                                value ? (
                                    <Icon
                                        onClick={(e) => {
                                            clearValue();
                                        }}
                                        type="close-circle"
                                        style={{ cursor: 'pointer' }}
                                    />
                                ) : null
                            }
                        />
                    </Col>
                    <Col hidden={props.disabled} span={4}>
                        <Button
                            className="margin-left"
                            icon="folder-open"
                            onClick={(e) => {
                                setFilesTreeVisible(true);
                                fetchTree();
                            }}
                        ></Button>
                    </Col>
                </Row>
            )}
            {props.fontSelect && (
                <Row>
                    {/*   <Col hidden={props.disabled}>
                        <Button
                            style={{ marginLeft: '2px', marginRight: '0.6rem' }}
                            className="margin-left"
                            icon="folder-open"
                            onClick={(e) => {
                                setFilesTreeVisible(true);
                                fetchTree();
                            }}
                        >
                            {geti18nText('design.settings.component.font_upload')}
                        </Button>
                        {transformValue()}
                    </Col> */}
                </Row>
            )}
            {props.checkboxes && (
                <Row>
                    <Col hidden={props.disabled} gutter={24} span={20} /* span={4} */>
                        <Button
                            type="primary"
                            style={{ marginBottom: '8px', marginLeft: '0px' }}
                            className="margin-left"
                            icon="folder-open"
                            onClick={(e) => {
                                setFilesTreeVisible(true);
                                fetchTree();
                            }}
                        >
                            {geti18nText('upload.multiple.pictures')}
                        </Button>
                    </Col>
                </Row>
            )}
            <Modal
                title={geti18nText('upload.select.file')}
                visible={filesTreeVisible}
                onCancel={closeFilesTreeVisible}
                destroyOnClose={true}
                footer={null}
                width={'1000px'}
                style={{ minHeight: '600px' }}
            >
                <Form.Item>
                    <Search
                        placeholder={geti18nText('navbar.search')}
                        enterButton
                        onSearch={onSearchFiles}
                        onChange={onSearchChange}
                    />
                </Form.Item>
                {!props.checkboxes && (
                    <Tree
                        style={{
                            marginTop: '10px',
                            height: '30vh',
                            overflow: 'auto',
                        }}
                        loadData={onLoadData}
                        onSelect={onSelect}
                        loadedKeys={loadedKeys}
                    >
                        {renderTreeNodes(treeData)}
                    </Tree>
                )}
                {props.checkboxes && (
                    <>
                        <Tree
                            checkable={true}
                            style={{
                                marginTop: '10px',
                                height: '30vh',
                                overflow: 'auto',
                            }}
                            loadData={onLoadData}
                            onSelect={undefined}
                            loadedKeys={loadedKeys}
                            onCheck={(e) => {
                                handleKeys(e);
                            }}
                        >
                            {renderTreeNodes(treeData)}
                        </Tree>
                        <div
                            style={{ textAlign: 'right', marginTop: '1rem', marginRight: '1rem', marginBottom: '1rem' }}
                        >
                            <Dragger
                                style={{ marginTop: '1rem' }}
                                {...uploadFile}
                                ref={(ref) => {
                                    if (ref != null) {
                                        draggerRef = ref;
                                    }
                                }}
                            >
                                {props.uploading ? (
                                    <React.Fragment>
                                        <Spinner />
                                        <p className="ant-upload-hint">{geti18nText('upload.text.uploading')}</p>
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>
                                        <p className="ant-upload-drag-icon">
                                            <Icon type="inbox" />
                                        </p>
                                        <p className="ant-upload-hint">{geti18nText('upload.text')}</p>
                                    </React.Fragment>
                                )}
                            </Dragger>
                            {(uploading || uploadsWithErrorExists) && (
                                <UploadProgress uploadingFiles={uploadingFiles} />
                            )}
                            <Button
                                style={{ marginTop: '1rem' }}
                                type="primary"
                                onClick={(e) => {
                                    onEditCall(
                                        selectedKeys.filter((key) => key.includes('.')),
                                        e
                                    );
                                }}
                            >
                                {geti18nText('upload.save.all')}
                            </Button>
                        </div>
                    </>
                )}
            </Modal>
        </React.Fragment>
    );
});
