import React, { useState } from 'react';
import { Checkbox, Form, Row, Col, Input, InputNumber } from 'antd';
import { CONSTANTS_REQ, CONSTANTS_FILE_TYPE } from '../../../utils/Constants';
import { DataEdit } from '../../../components/DataEdit';
import { InputByLanguage, translationsToObject, getTranslated } from '../../../components/InputByLanguage';
import { Session, geti18nText } from '../../../utils/Session';
import { setProjectPageTypes } from '../../project/ProjectIndex';
import { SearchField } from '../../../components//Search';
import { FileUpload } from '../../../components/fileUpload/FileUpload';
import { useDispatch } from 'react-redux';
import { refreshMenu } from '../../../slices/menuSlice';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { requestGet, RESPONSE, requestPost } from '../../../utils/RequestResolver';
import { ColorPicker } from './../../../components/ColorPicker';

const PageTypeEdit = Form.create({ name: 'PageTypeEdit' })((props) => {
    const { getFieldDecorator, setFieldsValue } = props.form;
    const [loading, setLoading] = useState(false);
    const [nameLangJson, setNameLangJson] = useState(undefined);
    const [showPageLink, setShowPageLink] = useState(true);
    const [viewForPageMenu, setViewForPageMenu] = useState(true);
    const [onlyMap, setOnlyMap] = useState(false);
    const [color, setColor] = useState(undefined);
    const [showPosition, setShowPosition] = useState(false);
    const [showSublinkOption, setShowSublinkOption] = useState(false);
    const [editHeader, setEditHeader] = useState(geti18nText('page.type.edit.title.for.page'));
    const dispatch = useDispatch();
    const history = useHistory();
    const { themeShortName } = useSelector((state) => state.projectSlices);

    function setValues(type) {
        getNavbar();
        if (props.onlyMap || props.additionalValues == 'onlyMap') {
            setViewForPageMenu(false);
            setEditHeader(getTranslated(type.name));
            setOnlyMap(true);

            delete type.webPage;
            delete type.imagePath;
        }
        setColor(type.color);
        type = translationsToObject(type, 'name', type.name);
        setNameLangJson(type.name);

        setShowPageLink(type.forPage);
        delete type.name;
        delete type.active;
        delete type.categoryType;
        if (type.imagePath != null) {
            const imagePath = {
                file_path: type.imagePath,
                name: type.imagePath.substring(type.imagePath.lastIndexOf('/') + 1, type.imagePath.length),
            };
            type.imagePath = imagePath;
        }
        if (type.menuPosition) {
            type.menuPosition = positions.find(({ id }) => id === type.menuPosition);
        }
        setFieldsValue(type);
    }

    async function getNavbar() {
        const resp = await requestGet(CONSTANTS_REQ.SETTINGS.ADMIN, {
            group: 'nav',
            key: 'nav.id',
            projectId: Session.getProjectId(),
        });
        if (resp.status === RESPONSE.OK) {
            if (resp.data.settingValue != null) {
                let navbar = JSON.parse(resp.data.settingValue);
                let menuForPosition = Session.getAppValue('COMPONENTS').find(
                    (element) => element.id === parseInt(navbar.key)
                );
                if (menuForPosition != null && menuForPosition.name === 'magicMenu') {
                    setShowPosition(true);
                }
                if (menuForPosition != null && menuForPosition.name === 'headerMenuSubLinks') {
                    setShowSublinkOption(true);
                }
            }
        }
    }

    function setDefaultValues() {
        if (props.onlyMap || props.additionalValues == 'onlyMap') {
            setViewForPageMenu(false);
            setEditHeader(geti18nText('page.type.edit.new'));
            setOnlyMap(true);
        }
        props.form.resetFields();
        setNameLangJson(null);
        setShowPageLink(true);
    }

    function onSave() {
        if (viewForPageMenu) {
            setProjectPageTypes(Session.getProjectId());
            dispatch(
                refreshMenu({
                    refreshLastNode: true,
                    refreshProjectNode: false,
                    refreshNodes: [],
                })
            );
        }
        if (props.onSave) {
            props.onSave();
        }
    }

    function onSaveAndGetId(id) {
        if (viewForPageMenu) {
            history.push(history.location.pathname.substr(0, history.location.pathname.lastIndexOf('/')) + '/' + id);
        }
        if (props.onSaveAndGetID) {
            props.onSaveAndGetID(id);
        }
    }

    function forPageOnChange(value) {
        setShowPageLink(value.target.checked);
    }

    const positions = [
        { id: 'left', text: geti18nText('page.type.edit.menu.position.left') },
        { id: 'right', text: geti18nText('page.type.edit.menu.position.right') },
        { id: 'center', text: geti18nText('page.type.edit.menu.position.center') },
    ];

    return (
        <>
            <DataEdit
                editHeader={editHeader}
                loading={loading}
                setLoading={setLoading}
                setDefaultValues={setDefaultValues}
                url={CONSTANTS_REQ.PAGETYPE.EDIT}
                setValues={setValues}
                width={1200}
                {...props}
                onSave={onSave}
                onSaveAndGetID={onSaveAndGetId}
                buttonsPosition="left"
                hideCancelButton={true}
                normalize={(values) => {
                    return {
                        ...values,
                        project: { id: Session.getProjectId() },
                        imagePath:
                            values.imagePath != null && values.imagePath instanceof Object
                                ? values.imagePath.file_path
                                : values.imagePath,
                        webPage:
                            values.webPage != null && values.webPage.constructor === String
                                ? { id: parseInt(values.webPage) }
                                : values.webPage,
                        forPage: viewForPageMenu ? true : values.forPage,
                        checkAsRectangle: values.checkAsRectangle,
                        showBtnMore: values.showBtnMore,
                        breakPoint: themeShortName === 'nytourist' ? true : false,
                    };
                }}
            >
                <Row gutter={24}>
                    <Col span={16}>
                        <Form.Item hidden={true}>{getFieldDecorator('id')(<Input hidden={true} />)}</Form.Item>
                        <InputByLanguage
                            fieldName="name"
                            langJson={nameLangJson}
                            label={geti18nText('page.type.edit.name')}
                            {...props}
                        />
                        <Form.Item hidden={true} style={{ marginTop: '20px' }}>
                            {getFieldDecorator('forPage', {
                                valuePropName: 'checked',
                                initialValue: onlyMap ? false : true,
                            })(<Checkbox onChange={forPageOnChange}>{geti18nText('page.type.edit.forPage')}</Checkbox>)}
                        </Form.Item>

                        {viewForPageMenu && (
                            <>
                                <Form.Item label={geti18nText('page.type.edit.webpage')}>
                                    {getFieldDecorator('webPage')(
                                        <SearchField
                                            url={CONSTANTS_REQ.WEBPAGE.SEARCH}
                                            map={{ id: 'id', label: 'name' }}
                                            searchBy="name"
                                            disabled={false}
                                            translate={true}
                                            addedData={{ projectId: Session.getProjectId() }}
                                        />
                                    )}
                                </Form.Item>

                                <Form.Item label={geti18nText('page.type.edit.link')}>
                                    {getFieldDecorator('externalLink')(<Input />)}
                                </Form.Item>

                                <Form.Item label={geti18nText('page.type.edit.image')}>
                                    {getFieldDecorator('imagePath')(
                                        <FileUpload
                                            fileType={CONSTANTS_FILE_TYPE.IMAGE}
                                            map={{ id: 'id', label: 'name' }}
                                            searchBy="name"
                                            disabled={false}
                                            addedData={{ projectId: Session.getProjectId() }}
                                        />
                                    )}
                                </Form.Item>
                            </>
                        )}
                        <Form.Item hidden={true}>
                            {getFieldDecorator('forMap', {
                                valuePropName: 'checked',
                                initialValue: viewForPageMenu ? false : true,
                            })(<Checkbox>{geti18nText('page.type.edit.forMap')}</Checkbox>)}
                        </Form.Item>
                        <Form.Item
                            hidden={viewForPageMenu ? true : false}
                            label={geti18nText('page.type.edit.typeOrder')}
                        >
                            {getFieldDecorator('typeOrder')(<InputNumber min={0} precision={0} />)}
                        </Form.Item>
                        {showPosition && showPosition === true && (
                            <Form.Item label={geti18nText('page.type.edit.menu.position')}>
                                {getFieldDecorator('menuPosition')(<SearchField options={positions} />)}
                            </Form.Item>
                        )}
                        {showSublinkOption && showSublinkOption === true && (
                            <Form.Item >
                            {getFieldDecorator('checkAsRectangle', {
                                valuePropName: 'checked',
                                initialValue: false,
                            })(<Checkbox>{geti18nText('page.type.edit.check.as.rectangle')}</Checkbox>)}
                        </Form.Item>
                        )}
                        {showSublinkOption && showSublinkOption === true && (
                            <Form.Item >
                            {getFieldDecorator('showBtnMore', {
                                valuePropName: 'checked',
                                initialValue: false,
                            })(<Checkbox>{geti18nText('page.type.edit.show.btn.more')}</Checkbox>)}
                        </Form.Item>
                        )}

                        <Form.Item hidden={true}>{getFieldDecorator('project')(<Input hidden={true} />)}</Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={10}>
                        <Form.Item label={geti18nText('page.type.edit.color')}>
                            <Col span={8}>{getFieldDecorator('color')(<Input></Input>)}</Col>

                            <Col span={2}>
                                <ColorPicker
                                    value={color}
                                    onChange={(value) => {
                                        let data = { color: value };
                                        props.form.setFieldsValue(data);
                                        setColor(value);
                                    }}
                                ></ColorPicker>
                            </Col>
                        </Form.Item>
                    </Col>
                </Row>
            </DataEdit>
        </>
    );
});

export default PageTypeEdit;
