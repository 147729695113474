import React, { useState, useEffect } from 'react';
import { Form, Row, Col, Input, Button, Divider,notification } from 'antd';
import { Session, geti18nText } from '../../utils/Session';
import { InputByLanguage, translationsToObject } from '../InputByLanguage';
import { requestGet, requestPut, RESPONSE } from '../../utils/RequestResolver';
import { CONSTANTS_REQ } from '../../utils/Constants';
import { SearchField } from '../Search';
import { unstable_renderSubtreeIntoContainer } from 'react-dom';

const DestinationMapEdit = Form.create({ name: 'DestinationMapEdit' })((props) => {
    const [fetching, setFetching] = useState(false);
    const { getFieldDecorator, setFieldsValue } = props.form;
    const [savedData, setSavedData] = useState({});
    const jsonFieldKeys = ['title', 'location', 'page_types'];
    const jsonDbFieldName = 'json';
    const [titleLangJson, setTitleLangJson] = useState(undefined);

    useEffect(() => {
        fetch();
    }, [props.visible]);

    function fetch() {
        setFetching(true);
        requestGet(CONSTANTS_REQ.WEBPAGECOMPONENT.EDIT + '/' + props.id).then((result) => {
            setFetching(false);
            if (result.status === RESPONSE.OK) {
                setValues(result.data);
            } else {
            }
        });
    }

    function setValues(component_data) {
        var addedData = component_data;
        var json;
        var location;
        var page_types;
        if (component_data.json) {
            json = JSON.parse(component_data.json);
            delete addedData.json;
        }
        delete addedData.active;
        setSavedData(addedData);
        var populate_data = {};
        if (json) {
            location =
                json.location != null && json.location.constructor === String
                    ? { id: parseInt(json.location) }
                    : json.location;
            page_types = json.page_types;
            populate_data = translationsToObject(json, 'title', JSON.stringify(json.title));
            setTitleLangJson(JSON.stringify(json.title));
            delete populate_data.title;
        }
        populate_data.id = component_data.id;
        setFieldsValue(populate_data);

        if (location != null) {
            requestGet(CONSTANTS_REQ.LOCATION.EDIT + '/' + location.id).then((result) => {
                setFetching(false);
                if (result.status === RESPONSE.OK) {
                    var locationData = {};
                    locationData['location'] = { id: result.data.id, name: result.data.name };
                    props.form.setFieldsValue(locationData);
                } else {
                    console.log('response failed');
                }
            });
        }
        if (page_types != null && page_types.length > 0) {
            requestGet(CONSTANTS_REQ.PAGETYPE.LIST, {
                max: 500,
                search: encodeURI(
                    JSON.stringify([{ field: 'id', condition: 'IN_PARSE_LONG', value: page_types.toString() }])
                ),
            }).then((result) => {
                if (result.status === RESPONSE.OK) {
                    var content;
                    if (result.data instanceof Array && result.data.length > 0) {
                        content = result.data;
                    } else if (result.data.content instanceof Array && result.data.content.length > 0) {
                        content = result.data.content;
                    }
                    if (content instanceof Array && content.length > 0) {
                        setFieldsValue({
                            ['page_types']: content.map((pt) => {
                                return { id: pt.id + '', text: pt.name };
                            }),
                        });
                    }
                } else {
                    console.log('response failed');
                }
            });
        }
    }

    function showNotification(title, message, duration, type) {
        notification[type]({
            message: title,
            description: message,
            duration: duration,
        });
    }

    function okNotification() {
        showNotification(geti18nText('app.default.save.ok'), geti18nText('app.default.save.ok.desc'), 3, 'success');
    }

    function error(message) {
        showNotification(geti18nText('app.default.save.nok'), message, 3, 'error');
    } 

    function save(e, close) {
        e.preventDefault();
        props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                requestPut(CONSTANTS_REQ.WEBPAGECOMPONENT.EDIT + '/' + props.id, null, normalize(values)).then(
                    (result) => {
                        if (result && result.status === RESPONSE.CREATED) {
                            console.log('okNotification');                        
  			                okNotification();
                             close && props.closePopup();
                        } else {
                            error();
                            if (result && result.status === RESPONSE.BAD_REQUEST) {
                                if (result.data && result.data.field) {
                                    switch (result.data.field) {
                                        case 'mandatory_fields_required':
                                            console.log(geti18nText('app.default.mandatory_fields_required'));
                                            return;
                                        default:
                                            break;
                                    }
                                }
                            }
                            if (result.data && result.data.error) {
                                console.log(JSON.stringify(result.data.error));
                            }
                        }
                    }
                );
            }
        });
    }

    function normalize(values) {
        if (values.location && values.location.key) {
            values.location = { id: values.location.key, name: values.location.label };
        }
        let normalizedValues = { ...savedData };
        let translationKeys = {};
        for (var key in values) {
            if (values.hasOwnProperty(key)) {
                if (values[key] instanceof Array) {
                    if (values[key].length === 1) {
                        normalizedValues[key] = values[key][0].hasOwnProperty('key')
                            ? values[key][0].key
                            : values[key][0].id;
                    } else {
                        normalizedValues[key] = values[key].map((element) => {
                            return '' + (element.hasOwnProperty('key') ? element.key : element.id);
                        });
                    }
                } else if (values[key] instanceof Object && values[key]['key']) {
                    normalizedValues[key] = values[key]['key'];
                } else {
                    // translations - to json
                    if (key.startsWith('translation-')) {
                        const fieldKey = key.substr(15);
                        const fieldLanguage = key.substr(12, 2);
                        if (normalizedValues[fieldKey] == undefined) {
                            normalizedValues[fieldKey] = {};
                        }
                        normalizedValues[fieldKey][fieldLanguage] = values[key];
                        if (!translationKeys[fieldKey] && !jsonFieldKeys.includes(fieldKey)) {
                            translationKeys[fieldKey] = fieldKey;
                        }
                    } else {
                        if (values[key] === undefined) {
                            normalizedValues[key] = null;
                        } else {
                            normalizedValues[key] = values[key];
                        }
                    }
                }
            }
        }

        // create JSON for json field
        if (jsonDbFieldName && jsonFieldKeys) {
            var json = {};
            jsonFieldKeys.forEach((key) => {
                if (normalizedValues[key] != null) {
                    json[key] = normalizedValues[key];
                    delete normalizedValues[key];
                }
            });
            if (json instanceof Object) {
                json = JSON.stringify(json);
            }
            normalizedValues[jsonDbFieldName] = json;
        }

        // json object to string
        for (var key in translationKeys) {
            if (normalizedValues[key] instanceof Object) {
                normalizedValues[key] = JSON.stringify(normalizedValues[key]);
            }
        }

        if (props.normalize) {
            //normalizedValues = props.normalize(normalizedValues);
        }
        return normalizedValues;
    }

    return (
        <React.Fragment>
            <Divider>{geti18nText('component.destinationmap')}</Divider>
            <Row gutter={24}>
                <Col span={24}>
                    <Form.Item hidden={true}>{getFieldDecorator('id')(<Input hidden={true} />)}</Form.Item>
                    <InputByLanguage
                        fieldName="title"
                        label={geti18nText('component.destinationmap.edit.title')}
                        langJson={titleLangJson}
                        {...props}
                    />
                    <Form.Item label={geti18nText('component.destinationmap.edit.location')}>
                        {getFieldDecorator('location', {
                            rules: [{ required: true, message: geti18nText('app.default.required') }],
                        })(
                            <SearchField
                                url={CONSTANTS_REQ.LOCATION.SEARCH}
                                map={{ id: 'id', label: 'name' }}
                                searchBy="name"
                                disabled={false}
                            />
                        )}
                    </Form.Item>
                    <Form.Item label={geti18nText('component.destinationmap.edit.page.types')}>
                        {getFieldDecorator('page_types')(
                            <SearchField
                                mode="multiple"
                                url={CONSTANTS_REQ.PAGETYPE.SEARCH}
                                map={{ id: 'id', label: 'name' }}
                                //addedSearch={{ field: 'for_map', condition: 'equals_bool', value: '1' }}
                                searchBy="name"
                                disabled={false}
                                addedData={{ projectId: Session.getProjectId(), forMap: true }}
                                translate={true}
                            />
                        )}
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={24} style={{ textAlign: 'right', marginTop: '1rem' }}>
                    <Button style={{ marginRight: '1em' }} onClick={(e) => props.closePopup()}>
                        {geti18nText('app.default.button.cancel')}
                    </Button>
                    <Button style={{ marginRight: '1em' }} type="primary" onClick={(e) => save(e, false)}>
                        {geti18nText('app.default.button.save')}
                    </Button>
                    <Button
                        type="primary"
                        style={{ background: '#31b531', borderColor: '#31b531', textColor: 'white' }}
                        onClick={(e) => {
                            save(e, true);
                        }}
                    >
                        {geti18nText('app.default.button.save.close')}
                    </Button>
                </Col>
            </Row>
        </React.Fragment>
    );
});

export default DestinationMapEdit;
