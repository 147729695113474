import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Row, Col, Button, Modal, Popconfirm, notification, Menu, Dropdown, Collapse, Tag, Checkbox, Icon } from 'antd';
import { CONSTANTS_REQ } from '../../../utils/Constants';
import { getTranslated } from '../../../components/InputByLanguage';
import { requestGet, requestDelete, requestPut, RESPONSE } from '../../../utils/RequestResolver';
import { Session, geti18nText } from '../../../utils/Session';
import { Spinner } from '../../../components/Spinner';
import { useDispatch, useSelector } from 'react-redux';
import { refreshMenu } from '../../../slices/menuSlice';
import { refreshPagePreview } from '../../../slices/webPageSlice';
import TextWithTitleAndSubtitleEdit from '../../../components/textWithTitleAndSubtitle/TextWithTitleAndSubtitleEdit';
import HeaderImageVideoEdit from '../../../components/headerImageVideo/HeaderImageVideoEdit';
import ImageWithTitleAndDescriptionEdit from '../../../components/imageWithtitleAndDescription/ImageWithTitleAndDescriptionEdit';
import VideoBlockEdit from '../../../components/videoBlock/VideoBlockEdit';
import ImageLinksEdit from '../../../components/imageLinks/ImageLinksEdit';
import GenerateElement from '../../../components/GenerateElement';
import DestinationMapEdit from '../../../components/destinationMap/DestinationMapEdit';
import GlobalMapEdit from '../../../components/globalMap/GlobalMapEdit';
import ImageWithTextEdit from '../../../components/imageWithText/ImageWithTextEdit';
import VideoWithTextEdit from '../../../components/videoWithText/VideoWithTextEdit';
import ImageGridEdit from '../../../components/imageGrid/ImageGridEdit';
import ImagesCarouselWithTextEdit from '../../../components/imagesCarouselWithText/ImagesCarouselWithTextEdit';
import ImageGalleryEdit from '../../../components/imageGallery/ImageGalleryEdit';
import FlexImageVerticalEdit from '../../../components/fleximagevertical/FlexImageVerticalEdit';
import BtcCircleEdit from '../../../components/BtcCircle/BtcCircleEdit';
import BtcReferenceEdit from '../../../components/btcreference/BtcReferenceEdit';
import BtcNewsletterEdit from '../../../components/btcNewsletter/BtcNewsletterEdit';
import FlexTitleTextEdit from '../../../components/flexTitleText/FlexTitleTextEdit';
import FlexImageHorizontalEdit from '../../../components/flexImageHorizontal/FlexImageHorizontalEdit';
import BtcPartnersEdit from '../../../components/btcPartners/BtcPartnersEdit';
import PdfPreviewEdit from '../../../components/pdfPreview/PdfPreviewEdit';
import BtcContactEdit from '../../../components/btcContact/BtcContactEdit';
import BtcCareerEdit from '../../../components/btccareer/BtcCareerEdit';
import HeaderVideoEdit from '../../../components/headervideo/HeaderVideoEdit';
import HeaderImageEdit from '../../../components/headerImage/HeaderImageEdit';
import ClsmCircleEdit from '../../../components/clsmCircle/ClsmCircleEdit';
import ClsmHeaderImageEdit from '../../../components/clsmHeaderImage/ClsmHeaderImageEdit';
import AccomodationEdit from '../../../components/accomodation/AccomodationEdit';
import FacilitiesEdit from '../../../components/facility/FacilitiesEdit';
import NoContentComponentEdit from '../../../components/no-content-component/NoContentComponentEdit';
import VideoChatEdit from '../../../components/videoCall/VideoChatEdit';
import SpaceEdit from '../../../components/space/SpaceEdit';
import WysiwygComponentEdit from '../../../components/WysiwygComponentEdit';
import DeseignEditor from '../../../components/deseign-editor';
import InstagramFeedEdit from '../../../components/instagramfeed/InstagramFeedEdit';
import FacebookFeedEdit from '../../../components/facebook-feed/FacebookFeedEdit';
import SlideContentEdit from '../../../components/slidecontent/SlideContentIndex';
import LinkIconsEdit from '../../../components/linkIcons/LinkIconsEdit';
import CardDescriptionEdit from '../../../components/carddescription/CardDescriptionEdit';
import CardDescriptionEdit2 from '../../../components/carddescription2/CardDescriptionEdit2';
import TitleLinkEdit from '../../../components/titleLink/TitleLinkEdit';
import FooterComponentEdit from '../../../components/footercomponent/FooterComponentEdit';
import ImageSlideEdit from '../../../components/imageslide/ImageSlideEdit';
import FlexContentHorizontalEdit from '../../../components/flexContentHorizontal/FlexContentHorizontalEdit';
import MagicNewsletterEdit from '../../../components/magicNewsletter/MagicNewsletterEdit';
import AvailabilityEdit from '../../../components/availability-options/AvailabilityEdit';
import ConfiguratorEdit from '../../../components/configurator/ConfiguratorEdit';
import ImageGalleryWithDescriptionEdit from '../../../components/imageGalleryWithDescription/ImageGalleryWithDescriptionEdit';
import ClsmViewComponentEdit from '../../../components/clsm-view/ClsmViewComponentEdit';
import CardImageTextEdit from '../../../components/cardimagetext/CardImageTextEdit';
import SpecificationEdit from '../../../components/specification/SpecificationEdit';
import DescriptionTabsEdit from '../../../components/descriptiontabs/DescriptionTabsEdit';
import ImageCardLinksEdit from '../../../components/imagecardlinks/ImageCardLinksEdit';
import CalendarEdit from '../../../components/calendar/CalendarEdit';
import TabWithListAndDescriptionEdit from '../../../components/tabWithListAndDescription/TabWithListAndDescriptionEdit';
import HeroAreaYlEdit from '../../../components/hero-area-yl/HeroAreaYlEdit';
import ProgressAreaYlEdit from '../../../components/yl-progress-area/ProgressAreaYlEdit';
import FeatureAreaYlEdit from '../../../components/yl-feature-area/FeatureAreaYlEdit';
import BrandAreaYlEdit from '../../../components/yl-brand-area/BrandAreaYlEdit';
import ContactAreaYlEdit from '../../../components/yl-contact-area/ContactAreaEdit';
import BreadCrumbAreaYlEdit from '../../../components/yl-breadcrumb-area/BreadCrumbAreaYlEdit';
import AboutAreaYlEdit from '../../../components/yl-about-area/AboutAreaYlEdit';
import BlogYlEdit from '../../../components/yl-blog/BlogYlEdit';
import FaqYlEdit from '../../../components/yl-faq/FaqYlEdit';
import YlShopEdit from '../../../components/yl-shop/YlShopEdit';
import YlShopDetailsEdit from '../../../components/yl-shop-details/YlShopDetails';
import PackageYlEdit from '../../../components/yl-package/YlPackageEdit';
import ChoosePackageYlEdit from '../../../components/yl-choose-package/ChoosepPackageYlEdit';
import CheckAvailabilityYlEdit from '../../../components/yl-check-availability/CheckAvailabilityYlEdit';



const AdminComponentsMap = {
    TextWithTitleAndSubtitleEdit: TextWithTitleAndSubtitleEdit,
    HeaderImageVideoEdit: HeaderImageVideoEdit,
    ImageWithTitleAndDescriptionEdit: ImageWithTitleAndDescriptionEdit,
    DestinationMapEdit: DestinationMapEdit,
    GlobalMapEdit: GlobalMapEdit,
    VideoBlockEdit: VideoBlockEdit,
    ImageLinksEdit: ImageLinksEdit,
    ImageLinksCarouselEdit: ImageLinksEdit,
    VideoWithTextEdit: VideoWithTextEdit,
    ImageGridEdit: ImageGridEdit,
    ImageWithTextEdit: ImageWithTextEdit,
    VideoWithTextEdit: VideoWithTextEdit,
    ImagesCarouselWithTextEdit: ImagesCarouselWithTextEdit,
    ImageGalleryEdit: ImageGalleryEdit,
    FlexImageVerticalEdit: FlexImageVerticalEdit,
    BtcCircleEdit: BtcCircleEdit,
    BtcReferenceEdit: BtcReferenceEdit,
    BtcNewsletterEdit: BtcNewsletterEdit,
    FlexTitleTextEdit: FlexTitleTextEdit,
    FlexImageHorizontalEdit: FlexImageHorizontalEdit,
    BtcPartnersEdit: BtcPartnersEdit,
    PdfPreviewEdit: PdfPreviewEdit,
    BtcContactEdit: BtcContactEdit,
    BtcCareerEdit: BtcCareerEdit,
    HeaderVideoEdit: HeaderVideoEdit,
    HeaderImageEdit: HeaderImageEdit,
    ClsmCircleEdit: ClsmCircleEdit,
    ClsmHeaderImageEdit: ClsmHeaderImageEdit,
    AccomodationEdit: AccomodationEdit,
    FacilitiesEdit: FacilitiesEdit,
    VideoChatEdit: VideoChatEdit,
    SpaceEdit: SpaceEdit,
    InstagramFeedEdit: InstagramFeedEdit,
    LinkIconsEdit: LinkIconsEdit,
    TitleLinkEdit: TitleLinkEdit,
    WysiwygComponentEdit: WysiwygComponentEdit,
    NoContentComponentEdit: NoContentComponentEdit,
    FacebookFeedEdit: FacebookFeedEdit,
    SlideContentEdit: SlideContentEdit,
    CardDescriptionEdit: CardDescriptionEdit,
    CardDescriptionEdit2: CardDescriptionEdit2,
    ImageSlideEdit: ImageSlideEdit,
    FlexContentHorizontalEdit: FlexContentHorizontalEdit,
    MagicNewsletterEdit: MagicNewsletterEdit,
    AvailabilityEdit: AvailabilityEdit,
    ConfiguratorEdit: ConfiguratorEdit,
    ImageGalleryWithDescriptionEdit: ImageGalleryWithDescriptionEdit,
    ClsmViewComponentEdit: ClsmViewComponentEdit,
    CardImageTextEdit: CardImageTextEdit,
    SpecificationEdit: SpecificationEdit,
    DescriptionTabsEdit: DescriptionTabsEdit,
    ImageCardLinksEdit: ImageCardLinksEdit,
    calendarEdit: CalendarEdit,
    tabWithListAndDescriptionEdit:TabWithListAndDescriptionEdit,
    HeroAreaYlEdit: HeroAreaYlEdit,
    ProgressAreaYlEdit:ProgressAreaYlEdit,
    FeatureAreaYlEdit:FeatureAreaYlEdit,
    BrandAreaYlEdit:BrandAreaYlEdit,
    ContactAreaYlEdit:ContactAreaYlEdit,
    BreadCrumbAreaYlEdit:BreadCrumbAreaYlEdit,
    AboutAreaYlEdit: AboutAreaYlEdit,
    BlogYlEdit: BlogYlEdit,
    FaqYlEdit: FaqYlEdit,    
    ShopYlEdit: YlShopEdit,    
    ShopDetailsYlEdit: YlShopDetailsEdit,    
    PackageYlEdit: PackageYlEdit,
    ChoosePackageYlEdit: ChoosePackageYlEdit,
    CheckAvailabilityYlEdit: CheckAvailabilityYlEdit
};

const SettingsModal = (props) => {
    const [width, setWidth] = useState(1000);

    function closePopup() {
        props.setVisible(false);
    }

    return (
        <>
            <Modal
                visible={props.visible}
                onCancel={closePopup}
                keyboard={false}
                maskClosable={false}
                width={width}
                footer={[]}
                destroyOnClose={true}
            >
                {props.id > 0 && (
                    <React.Fragment>
                        {React.createElement(AdminComponentsMap[props.adminComponent], {
                            id: props.id,
                            closePopup: closePopup,
                            showReorder: props.showReorder,
                            userComponent: props.userComponent,
                            setModalWidth: setWidth,
                        })}
                    </React.Fragment>
                )}
            </Modal>
        </>
    );
};

const PreviewModal = (props) => {
    function closePopup() {
        props.setVisible(false);
    }

    return (
        <>
            <Modal
                zIndex={1100}
                visible={props.visible}
                onCancel={closePopup}
                width={1200}
                destroyOnClose={true}
                footer={[]}
            >
                <Page id={props.id} pageComponentId={props.pageComponentId} />
            </Modal>
        </>
    );
};

const DeseignModal = (props) => {
    function closePopup() {
        props.setVisible(false);
    }

    return (
        <>
            <Modal
                zIndex={1100}
                visible={props.visible}
                onCancel={closePopup}
                width={1200}
                destroyOnClose={true}
                footer={[]}
            >
                <DeseignEditor
                    id={props.id}
                    pageComponentId={props.pageComponentId}
                    closePopup={closePopup}
                    webPageComponentId={props.webPageComponentId}
                    componentName={props.name}
                    type={'page_component'}
                ></DeseignEditor>
            </Modal>
        </>
    );
};

const CloneDataModal = (props) => {
    const [webPages, setWebPages] = useState([]);

    useEffect(() => {
        if (props.componentTypeId) {
            getWebpagesWithComponent();
        }
    }, [props.componentTypeId]);

    function closePopup() {
        props.setVisible(false);
    }

    async function getWebpagesWithComponent() {
        let params = {
            componentId: props.componentTypeId,
            projectId: Session.getProjectId(),
        };
        const resp = await requestGet(CONSTANTS_REQ.WEBPAGECOMPONENT.PAGESWITHCOMPONENTS, params);
        if (resp.status === RESPONSE.OK) {
            setWebPages(resp.data.content);
        }
    }

    function copyComponentContent(sourceId) {
        requestPut(CONSTANTS_REQ.WEBPAGECOMPONENT.COPYJSON, null, {
            sourceId: sourceId,
            targetId: props.targetPageComponentId,
        }).then((result) => {
            if (result && result.status === RESPONSE.CREATED) {
                okNotification();
                closePopup();
            } else {
                error();
            }
        });
    }

    function showNotification(title, message, duration, type) {
        notification[type]({
            message: title,
            description: message,
            duration: duration,
        });
    }

    function okNotification() {
        showNotification(geti18nText('app.default.save.ok'), geti18nText('webpage.edit.clone.ok'), 3, 'success');
    }

    function error() {
        showNotification(geti18nText('app.default.save.nok'), geti18nText('webpage.edit.clone.nok'), 0, 'error');
    }
    return (
        <>
            <Modal
                visible={props.visible}
                onCancel={closePopup}
                width={1000}
                destroyOnClose={true}
                footer={[]}
                title={geti18nText('webpage.edit.cloneTitle')}
            >
                {webPages != null &&
                    webPages.map((item, index) => (
                        <Row key={index} style={{ borderBottom: '1px solid #e8e8e8', padding: '15px 5px 5px 5px' }}>
                            <Col span={17} offset={1}>
                                {getTranslated(item.webPage.name)}
                            </Col>
                            <Col span={2}>
                                <Button
                                    shape="circle"
                                    icon="html5"
                                    onClick={() => {
                                        props.showComponentPreview(item.id);
                                    }}
                                />
                            </Col>
                            <Col span={3}>
                                <Button
                                    onClick={() => {
                                        copyComponentContent(item.id);
                                    }}
                                >
                                    {geti18nText('webpage.edit.copyJson')}
                                </Button>
                            </Col>
                        </Row>
                    ))}
            </Modal>
        </>
    );
};

const MergeComponentsModal = (props) => {
    const [projects, setProjects] = useState([]);
    const [webPages, setWebPages] = useState([]);
    const [selectedComponents, setSelectedtComponents] = useState([]);
    const { Panel } = Collapse;
    useEffect(() => {
        if (props.componentTypeId) {
            setSelectedtComponents([]);
            getWebpagesWithComponent();
        }
    }, [props.componentTypeId]);

    async function getWebpagesWithComponent() {
        let params = {
            componentId: props.componentTypeId,
        };
        const resp = await requestGet(CONSTANTS_REQ.WEBPAGECOMPONENT.PAGESWITHCOMPONENTS, params);
        if (resp.status === RESPONSE.OK) {
            var projectList = [];
            resp.data.content.forEach((item) => {
                let index = projectList.findIndex((el) => el.id == item.project.id);
                if (index < 0) {
                    projectList.push(item.project);
                }
            });
            setProjects(projectList);
            setWebPages(resp.data.content);
        }
    }

    const handleChange = (event, element) => {
        const items = JSON.parse(JSON.stringify(selectedComponents));
        const index = items.findIndex((item) => item.projectId == element.project.id && item.componentId == element.id);
        if (index != -1) {
            items.splice(index, 1);
        }
        if (event.target.checked == true) {
            items.push({ projectId: element.project.id, item: element, componentId: element.id });
        }
        setSelectedtComponents(items);
    };

    function closePopup() {
        props.setVisible(false);
    }
    function removeTag(e, element) {
        const items = JSON.parse(JSON.stringify(selectedComponents));
        const index = items.findIndex(
            (item) => item.projectId == element.projectId && item.componentId == element.componentId
        );
        if (index != -1) {
            items.splice(index, 1);
        }
        setSelectedtComponents(items);
    }
    function mergeComponents() {
        var list = JSON.parse(JSON.stringify(selectedComponents));
        var listForMerge = list.map((x) => x.componentId);
        requestPut(CONSTANTS_REQ.WEBPAGECOMPONENT.MERGE_COMPONENTS, null, {
            components: listForMerge,
            targetId: props.targetPageComponentId,
            projectId: Session.getProjectId(),
        }).then((result) => {
            if (result && result.status === RESPONSE.CREATED) {
                okNotification();
                closePopup();
            } else {
                error();
            }
        });
    }

    function showNotification(title, message, duration, type) {
        notification[type]({
            message: title,
            description: message,
            duration: duration,
        });
    }

    function okNotification() {
        showNotification(geti18nText('app.default.save.ok'), geti18nText('webpage.edit.merge.ok'), 3, 'success');
    }

    function error() {
        showNotification(geti18nText('app.default.save.nok'), geti18nText('webpage.edit.merge.error'), 0, 'error');
    }

    return (
        <Modal
            visible={props.visible}
            onCancel={closePopup}
            width={1000}
            destroyOnClose={true}
            footer={[]}
            title={geti18nText('webpage.edit.cloneTitle')}
        >
            <Row>
                <Col gutter={20}>
                    {selectedComponents.map((item, index) => (
                        <Tag
                            key={item.id}
                            /* closable
                            onClose={(e) => {
                                removeTag(e, item);FDesei
                            }}*/
                        >
                            {item.item.project.name + ' - ' + getTranslated(item.item.webPage.name)}
                        </Tag>
                    ))}
                </Col>
            </Row>
            {selectedComponents.length > 0 && (
                <Row style={{ textAlign: 'right' }}>
                    <Button onClick={mergeComponents}>{geti18nText('webpage.edit.merge.btn')}</Button>
                </Row>
            )}
            <Collapse bordered={false} style={{ marginBottom: '15px' }}>
                {projects != null &&
                    projects.map((projectItem, index) => (
                        <Panel header={projectItem.name} key={index} forceRender={false}>
                            {webPages != null &&
                                webPages
                                    .filter(
                                        (element) =>
                                            element.project.id == projectItem.id &&
                                            props.targetPageComponentId != element.id
                                    )
                                    .map((item, index) => (
                                        <Row
                                            key={index}
                                            style={{
                                                borderBottom: '1px solid #e8e8e8',
                                                padding: '15px 5px 5px 5px',
                                            }}
                                        >
                                            <Col span={17} offset={1}>
                                                {getTranslated(item.webPage.name)}
                                            </Col>
                                            <Col span={2}>
                                                <Button
                                                    shape="circle"
                                                    icon="html5"
                                                    style={{
                                                        marginBottom: '15px',
                                                    }}
                                                    onClick={() => {
                                                        props.showComponentPreview(item.id);
                                                    }}
                                                />
                                            </Col>
                                            <Col span={3}>
                                                <Checkbox key={index} onChange={(e) => handleChange(e, item)} />
                                            </Col>
                                        </Row>
                                    ))}
                        </Panel>
                    ))}
            </Collapse>
        </Modal>
    );
};

const Page = (props) => {
    const [webpagesComponent, setWebpagesComponent] = useState([]);
    const [highlightComponent, setHighlightComponent] = useState(0);
    const [settingsPopupVisible, setSettingsPopupVisible] = useState(false);
    const [adminComponent, setAdminComponent] = useState(undefined);
    const [userComponent, setUserComponent] = useState(undefined);
    const [selectedComponentId, setSelectedComponentId] = useState(0);
    const [selectedComponentName, setSelectedComponentName] = useState(null);
    const [selectedComponentReorder, setSelectedComponentReorder] = useState(false);
    const [loading, setLoading] = useState(false);
    const [componentForDelete, setComponentForDelete] = useState(0);
    const [previewPopupVisible, setPreviewPopupVisible] = useState(false);
    const [clonePopupVisible, setClonePopupVisible] = useState(false);
    const [designPopupVisible, setDeseignPopupVisible] = useState(false);
    const [mergePopupVisible, setMergePopupVisible] = useState(false);
    const [selectedComponentTypeId, setSelectedComponentTypeId] = useState(null);
    const [targetId, setTargetId] = useState(null);
    const [pageComponentId, setPageComponentId] = useState(null);
    const { pagePreviewToRefresh } = useSelector((state) => state.webPageSlices);
    const dispatch = useDispatch();
    const [scrollContainerView, setScrollContainerView] = useState(null);
    const componentIdPrefix = 'component-';
    const { webPageComponentToScroll } = useSelector((state) => state.webPageSlices);
    
    useEffect(() => {
        if (props.scrollToComponent) {
            setScrollContainerView(componentIdPrefix + props.scrollToComponent);
            scrollToComponent(componentIdPrefix + props.scrollToComponent);
        }
    }, [props.scrollToComponent]);

    useEffect(() => {
        getWebpagesComponent();
        dispatch(refreshPagePreview({ page: 0 }));
    }, [props.refresh]);

    function scrollToComponent(containerView = scrollContainerView) {
        var elmnt = document.getElementById(containerView);
        if (elmnt != null) {
            elmnt.scrollIntoView({ block: 'nearest', inline: 'start' });
        }
    }

    useEffect(() => {
        if (pagePreviewToRefresh == props.id) {
            getWebpagesComponent();
        }
        dispatch(refreshPagePreview({ page: 0 }));
    }, [pagePreviewToRefresh]);

    useEffect(() => {
        scrollToComponent(componentIdPrefix + webPageComponentToScroll);
    }, [webPageComponentToScroll]);

    function setSettingsPopupVisibleAndRefresh() {
        setSettingsPopupVisible(false);
        setHighlightComponent(0);
        getWebpagesComponent();
        scrollToComponent();
    }

    function setClonePopupVisibleAndRefresh() {
        setClonePopupVisible(false);
        setHighlightComponent(0);
        getWebpagesComponent();
    }

    function setMergePopupVisibleAndRefresh() {
        setMergePopupVisible(false);
        setSelectedComponentTypeId(0);
        setHighlightComponent(0);
        getWebpagesComponent();
    }

    function setDeseignPopupVisibleAndRefresh() {
        setDeseignPopupVisible(false);
        setSelectedComponentTypeId(0);
        setHighlightComponent(0);
        getWebpagesComponent();
    }

    async function getWebpagesComponent() {
        setLoading(true);
        let params = { order: 'componentOrder', orderType: 'asc', max: 1000 };

        if (props.pageComponentId) {
            params.pageComponentId = props.pageComponentId;
        } else {
            params.webPageId = props.id;
        }
        const resp = await requestGet(CONSTANTS_REQ.WEBPAGECOMPONENT.LIST, params);
        if (resp.status === RESPONSE.OK) {
            setWebpagesComponent(resp.data.content);
            setLoading(false);
            scrollToComponent();
        }
    }

    function showSettings(componentId, id, reorder) {
        const adminComp = Session.getComponentAdmin(componentId);
        const userComp = Session.getComponentUser(componentId);
        if (adminComp != '') {
            setUserComponent(userComp);
            setAdminComponent(adminComp);
            setSelectedComponentId(id);
            setSettingsPopupVisible(true);
            setSelectedComponentReorder(reorder);
            setScrollContainerView(componentIdPrefix + id);
        }
    }

    function deleteComponent() {
        requestDelete(CONSTANTS_REQ.WEBPAGECOMPONENT.DELETE + '/' + componentForDelete).then((result) => {
            if (result && result.status === RESPONSE.OK) {
                setComponentForDelete(0);
                getWebpagesComponent();
                setScrollContainerView(null);

                //refresh menu
                let refreshNodes = ['WEBPAGE-' + props.id];
                if (props.firstPage) {
                    refreshNodes = ['FIRSTPAGE-' + props.id];
                }
                dispatch(
                    refreshMenu({
                        refreshLastNode: false,
                        refreshProjectNode: false,
                        refreshNodes: refreshNodes,
                    })
                );
            }
        });
    }

    function showClone(componentTypeId, targetId) {
        setSelectedComponentTypeId(componentTypeId);
        setTargetId(targetId);
        setClonePopupVisible(true);
        setScrollContainerView(null);
    }

    function showDeseign(pageComponentId, componentId, componentName) {
        setPageComponentId(pageComponentId);
        setSelectedComponentId(componentId);
        setSelectedComponentName(componentName);
        setDeseignPopupVisible(true);
        setScrollContainerView(componentIdPrefix + componentId);
    }

    function mergeComponent(componentTypeId, targetId) {
        setSelectedComponentTypeId(componentTypeId);
        setTargetId(targetId);
        setMergePopupVisible(true);
        setScrollContainerView(null);
    }

    function menu(componentId, itemId, componentName) {
        return (
            <Menu>
                <Menu.Item onClick={() => showSettings(componentId, itemId, false)}>
                    {geti18nText('webpage.edit.submenu.edit')}
                </Menu.Item>
                <Menu.Item onClick={() => setComponentForDelete(itemId)}>
                    {geti18nText('webpage.edit.submenu.delete')}
                </Menu.Item>
                <Menu.Item onClick={() => showClone(componentId, itemId)}>
                    {geti18nText('webpage.edit.submenu.copy')}
                </Menu.Item>
                <Menu.Item onClick={() => showDeseign(componentId, itemId, componentName)}>
                    {<Icon style={{ color: 'orange' }} type="bg-colors" />}
                    {geti18nText('design.settings.component.menu')}
                </Menu.Item>
                {['DestinationMapEdit', 'ImageLinksEdit', 'ImagesCarouselWithTextEdit', 'VideoBlockEdit'].includes(
                    Session.getComponentAdmin(componentId)
                ) && (
                    <Menu.Item onClick={() => mergeComponent(componentId, itemId)}>
                        {geti18nText('webpage.edit.submenu.merge')}
                    </Menu.Item>
                )}
                {['ImageGalleryEdit', 'ImageLinksEdit', 'LinkIconsEdit', 'FlexImageVerticalEdit'].includes(
                    Session.getComponentAdmin(componentId)
                ) && (
                    <Menu.Item onClick={() => showSettings(componentId, itemId, true)}>
                        {geti18nText('webpage.edit.submenu.order')}
                    </Menu.Item>
                )}
            </Menu>
        );
    }

    function menuVisibleChange(visible) {
        if (!visible) setHighlightComponent(0);
    }

    function showPreview(pageComponentId = null) {
        setPageComponentId(pageComponentId);
        setPreviewPopupVisible(true);
    }

    return (
        <React.Fragment>
            {loading && <Spinner />}
            {!loading &&
                webpagesComponent != null &&
                webpagesComponent.map((item, index) => {
                    return (
                        <div
                            className="webpage-preview-content"
                            style={{
                                position: 'relative',
                                border: highlightComponent == item.id ? '5px solid #cccccc' : undefined,
                            }}
                            id={componentIdPrefix + item.id}
                        >
                            {!props.pageComponentId && (
                                <Popconfirm
                                    placement="rightTop"
                                    title={geti18nText('app.default.button.delete')}
                                    okText={geti18nText('app.default.button.yes')}
                                    cancelText={geti18nText('app.default.button.no')}
                                    onConfirm={() => deleteComponent()}
                                    onCancel={() => {
                                        setComponentForDelete(0);
                                    }}
                                    visible={componentForDelete > 0 && componentForDelete == item.id}
                                >
                                    <div style={{ position: 'absolute', top: '50px', right: '50px', zIndex: 999 }}>
                                        <Dropdown
                                            overlay={menu(item.component.id, item.id, item.component.name)}
                                            placement="bottomRight"
                                            trigger={['click']}
                                            onVisibleChange={menuVisibleChange}
                                        >
                                            <Button onClick={() => setHighlightComponent(item.id)}>
                                                {geti18nText('webpage.edit.submenu') + ' (' + (index + 1) + ')'}
                                            </Button>
                                        </Dropdown>
                                    </div>
                                </Popconfirm>
                            )}
                            {GenerateElement(item)}
                        </div>
                    );
                })}

            <SettingsModal
                visible={settingsPopupVisible}
                setVisible={setSettingsPopupVisibleAndRefresh}
                adminComponent={adminComponent}
                userComponent={userComponent}
                id={selectedComponentId}
                showReorder={selectedComponentReorder}
            />
            <CloneDataModal
                visible={clonePopupVisible}
                setVisible={setClonePopupVisibleAndRefresh}
                targetPageComponentId={targetId}
                showComponentPreview={showPreview}
                componentTypeId={selectedComponentTypeId}
            />
            <MergeComponentsModal
                visible={mergePopupVisible}
                setVisible={setMergePopupVisibleAndRefresh}
                targetPageComponentId={targetId}
                showComponentPreview={showPreview}
                componentTypeId={selectedComponentTypeId}
            ></MergeComponentsModal>
            <PreviewModal
                visible={previewPopupVisible}
                setVisible={setPreviewPopupVisible}
                id={props.id}
                pageComponentId={pageComponentId}
            />
            <DeseignModal
                visible={designPopupVisible}
                setVisible={setDeseignPopupVisibleAndRefresh}
                id={props.id}
                webPageComponentId={selectedComponentId}
                pageComponentId={pageComponentId}
                name={selectedComponentName}
            />
        </React.Fragment>
    );
};

export default Page;
