import { Col, Divider, Form, Row, Tabs, notification,Button,Input,Radio } from 'antd';
import React, { useEffect, useState } from 'react';
import { normalizeJSONForComponent } from '../../utils/ComponentHelper';
import { CONSTANTS_FILE_TYPE, CONSTANTS_REQ } from '../../utils/Constants';
import { RESPONSE, requestGet, requestPut } from '../../utils/RequestResolver';
import { Session, geti18nText } from '../../utils/Session';
import { normalizeComponents } from '../../utils/Utils';
import { InputByLanguage, translationsToObject } from '../InputByLanguage';
import { Spinner } from '../Spinner';
import { FileUpload } from '../fileUpload/FileUpload';
import { SearchField } from '../../components/Search';


const BreadCrumbAreaYlEdit = Form.create({ name: 'BreadCrumbAreaYlEdit' })((props) => {
    const { getFieldDecorator, setFieldsValue } = props.form;
    const [fetching, setFetching] = useState(false);   
    const [formValues, setFormValues] = useState(null);
    const LINK_TYPE = {
        LINK: 'LINK',
        WEBPAGE: 'WEBPAGE',
    };
    const [linkTypeTerms, setLinkTypeTerms] = useState(LINK_TYPE.WEBPAGE);
    const [externalLink, setExternalLink] = useState(undefined);
    
    useEffect(() => {
        fetch();
    }, [props.visible]);

    useEffect(() => {
        props.form.resetFields();
        setFieldsValue(formValues);
    }, [formValues]);

    function fetch() {
        setFetching(true);
        requestGet(CONSTANTS_REQ.WEBPAGECOMPONENT.EDIT + '/' + props.id).then((result) => {
            setFetching(false);
            if (result.status === RESPONSE.OK) {
                setValues(result.data);
            }
        });
    }
    
    function showNotification(title, message, duration, type) {
        notification[type]({
            message: title,
            description: message,
            duration: duration,
        });
    }

    function okNotification() {
        showNotification(geti18nText('app.default.save.ok'), geti18nText('app.default.save.ok.desc'), 3, 'success');
    }

    function error(message) {
        showNotification(geti18nText('app.default.save.nok'), message, 3, 'error');
    } 

    function setWepPageValue(key, id) {
        console.log('key',key,'id',id)
        requestGet(CONSTANTS_REQ.WEBPAGE.EDIT + '/' + id).then((result) => {
            if (result.status === RESPONSE.OK) {
                let field = {};
                field[key] = { id: id, name: result.data.name };
                setFieldsValue(field);
            }
        });
    }

    function setValues(component_data) {
        var json;
        if (component_data.json) {
            json = JSON.parse(component_data.json);
            json = translationsToObject(json, 'title', json.title);
            json = translationsToObject(json, 'subtitle', json.subtitle);
            if(json.file_path) {
                json['file_path'] = {
                    key: json.file_path,
                    file_path: json.file_path,
                };
            }
            setLinkTypeTerms(json.linkType)
            if(json.externalLink) { 
                console.log('jsonTu',json)
                setExternalLink(json.externalLink)
            }
            if(json.link) {
                setWepPageValue('link',parseInt(json.link) )
            }
            setFormValues(json);
        }
    }

    function normalize(values, translationKeys) {
        let jsonFieldGroups = ['title', 'subtitle', 'file_path', 'externalLink', 'link', 'linkType'];
        let jsonFieldKeys = {};
        return normalizeJSONForComponent(values, undefined, jsonFieldGroups, jsonFieldKeys, props);
    }

    function save(e, close) {
        e.preventDefault();
        props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                values = normalizeComponents(values);
                
                values = normalize(values);
                console.log('values',values)
                requestPut(CONSTANTS_REQ.WEBPAGECOMPONENT.UPDATEJSON + '/' + props.id, null, {
                    id: props.id,
                    json: values.json,
                }).then((result) => {
                    if (result && result.status === RESPONSE.CREATED) {
                        console.log('okNotification');
                        okNotification();
                        close && props.closePopup();
                    } else {
                        error();
                        if (result && result.status === RESPONSE.BAD_REQUEST) {
                            if (result.data && result.data.field) {
                                switch (result.data.field) {
                                    case 'mandatory_fields_required':
                                        console.log(geti18nText('app.default.mandatory_fields_required'));
                                        return;
                                    default:
                                        break;
                                }
                            }
                        }
                        if (result.data && result.data.error) {
                            console.log(JSON.stringify(result.data.error));
                        }
                    }
                   
                });
            }
        });
    }

    
    if (fetching) {
        return <Spinner />
    } else {
        return (
            <React.Fragment>
                <Divider>{geti18nText('component.breadCrumbAreaYl')}</Divider>
                <Form>
                    <Form.Item label={geti18nText('component.breadCrumbAreaYl.image')}>
                        {getFieldDecorator('file_path', {
                                    rules: [{ required: true, message: geti18nText('app.default.required') }],
                                } )(
                            <FileUpload
                                fileType={CONSTANTS_FILE_TYPE.IMAGE}
                                map={{ id: 'id', label: 'name' }}
                                searchBy="name"
                                disabled={false}
                                addedData={{ projectId: Session.getProjectId() }}
                            />
                        )}
                    </Form.Item>
                    <InputByLanguage
                        notRequred
                        fieldName={'title'}
                        label={geti18nText('component.breadCrumbAreaYl.title')}
                        langJson={props.titleJson}
                        {...props}
                    />
                     <Form.Item>
                                {getFieldDecorator('linkType', { initialValue: LINK_TYPE.WEBPAGE })(
                                    <Radio.Group
                                        onChange={(e) => {
                                            setLinkTypeTerms(e.target.value);
                                        }}
                                    >
                                        <Radio value={LINK_TYPE.WEBPAGE}>
                                            {geti18nText('component.footercomponent.edit.radio_button.webPage')}
                                        </Radio>
                                        <Radio value={LINK_TYPE.LINK}>
                                            {geti18nText('component.footercomponent.edit.external_link')}
                                        </Radio>
                                    </Radio.Group>
                                )}
                            </Form.Item>
                    

                            {linkTypeTerms == LINK_TYPE.LINK ? (
                                <Row gutter={24} type="flex" justify="space-between" align="bottom">
                                    <Col span={16}>
                                        <Form.Item label={geti18nText('component.footercomponent.edit.text_link')}>
                                            {getFieldDecorator('externalLink')(
                                                <Input defaultValue={externalLink} />
                                            )}
                                        </Form.Item>
                                    </Col>
                                </Row>
                            ) : (
                                <Form.Item label={geti18nText('component.footercomponent.edit.radio_button.webPage')}>
                                    {getFieldDecorator('link')(
                                        <SearchField
                                            url={CONSTANTS_REQ.WEBPAGE.SEARCH}
                                            map={{ id: 'id', label: 'name' }}
                                            searchBy="name"
                                            disabled={false}
                                            translate={true}
                                            addedData={{ projectId: Session.getProjectId(), forMap: true }}
                                        />
                                    )}
                                </Form.Item>
                            )}
                    <InputByLanguage
                        notRequred
                        fieldName={'subtitle'}
                        label={geti18nText('component.breadCrumbAreaYl.subtitle')}
                        langJson={props.subtitleJson}
                        {...props}
                    />
                    
                </Form>
                <Row>
                        <Col span={24} style={{ textAlign: 'right', marginTop: '1rem' }}>
                            <Button style={{ marginRight: '1em' }} onClick={(e) => props.closePopup()}>
                                {geti18nText('app.default.button.cancel')}
                            </Button>
                            <Button style={{ marginRight: '1em' }} type="primary" onClick={(e) => save(e, false)}>
                                {geti18nText('app.default.button.save')}
                            </Button>
                            <Button
                                type="primary"
                                style={{ background: '#31b531', borderColor: '#31b531', textColor: 'white' }}
                                onClick={(e) => {
                                    save(e, true);
                                }}
                            >
                                {geti18nText('app.default.button.save.close')}
                            </Button>
                        </Col>
                    </Row>
            </React.Fragment>
        );
    }
});

export default BreadCrumbAreaYlEdit;
