import React, { useState, useEffect } from "react";
import { Icon, Form, Row, Col, Input, Radio,DatePicker,Checkbox } from "antd";
import { CONSTANTS_REQ,CONSTANTS_FILE_TYPE } from "../../../utils/Constants";
import { DataEdit } from "../../../components/DataEdit";
import { Session, geti18nText } from "../../../utils/Session";
import { requestGet, RESPONSE } from "../../../utils/RequestResolver";
import { setProjectLanguages } from "../../project/ProjectIndex";
import { SearchField, mapDataToSelect } from '../../../components/Search';
import { FileUpload } from '../../../components/fileUpload/FileUpload';
import moment from 'moment';
import { InputByLanguage, translationsToObject } from '../../../components/InputByLanguage';



const ShopEdit = Form.create({ name: "ShopEdit" })((props) => {
    const { getFieldDecorator, setFieldsValue } = props.form;
    const [editHeader, setEditHeader] = useState(geti18nText("shop.edit.new"));
    const [loading, setLoading] = useState(false);
    const LINK_TYPE = {
        LINK: 'LINK',
        WEBPAGE: 'WEBPAGE',
    };
    const [linkType, setLinkType] = useState(props.type ? props.type : LINK_TYPE.WEBPAGE);
    const [selectedAsAction, setSelectedAsAction] = useState(false);



    function setValues(values) {
        console.log(values);
        setEditHeader(values.id);
        values = translationsToObject(values, 'name', values.name);
        values = translationsToObject(values, 'description', values.description);
        values = translationsToObject(values, 'price', values.price);
        values = translationsToObject(values, 'discountedPrice', values.discountedPrice);
        delete values.active;
        if(values.imagePath) {
            values['imagePath'] = {
                key: values.imagePath,
                file_path: values.imagePath,
            };
        }
        if(values.imagePath1) {
            values['imagePath1'] = {
                key: values.imagePath1,
                file_path: values.imagePath1,
            };
        }
        if(values.imagePath2) {
            values['imagePath2'] = {
                key: values.imagePath2,
                file_path: values.imagePath2,
            };
        }
        if(values.categoryList) {
            values.category=values.categoryList.map((c)=>{
                return {id:c.id, text:c.name}
            })
        }
        
        setFieldsValue(values);
    }

    function onSave() {
        setProjectLanguages(Session.getProjectId());
        props.onSave();
    }

    function setDefaultValues() {
        
        setEditHeader(geti18nText("shop.edit.new"));
     
    }

    return (
        <>
            <DataEdit
                editHeader={editHeader}
                loading={loading}
                setLoading={setLoading}
                url={CONSTANTS_REQ.SHOP.EDIT}
                setValues={setValues}
                setDefaultValues={setDefaultValues}
                width={1200}
                {...props}
                onSave={onSave}
                normalize={(values) => {
                    if(values.category ) {
                        if(Array.isArray(values.category)) {
                            values.categoryList=values.category.map((c)=>{
                                return {id: parseInt(c)}
                            })
                        }
                        else values.categoryList =[{id: parseInt(values.category)}]
                    }
                    delete values.category;
                    return { ...values, project: { id: Session.getProjectId() } };
                }}
            >
                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item hidden={true}>{getFieldDecorator("id")(<Input hidden={true} />)}</Form.Item>
                        <InputByLanguage
                            fieldName="name"
                            
                            label={geti18nText('blog.edit.name')}
                            {...props}
                        />
                        <InputByLanguage
                            fieldName="description"
                            
                            label={geti18nText('blog.edit.description')}
                            {...props}
                        />
                        <InputByLanguage
                            fieldName="price"
                            
                            label={geti18nText('shop.edit.price')}
                            {...props}
                        />
                        <InputByLanguage

                            fieldName="discountedPrice"
                            notRequred
                            label={geti18nText('shop.edit.discounted.price')}
                            {...props}
                        />
                        <Form.Item label={geti18nText('shop.edit.category')}>
                                {getFieldDecorator('category',{
                                    rules: [{ required: true, message: geti18nText('app.default.required') }],
                                })(
                                    <SearchField
                                        mode="multiple"
                                        url={CONSTANTS_REQ.CATEGORY.SEARCH}
                                        map={{ id: 'id', label: 'text' }}
                                        searchBy="text"
                                        disabled={false}
                                        translate={true}

                                        addedData={{ projectId: Session.getProjectId(), type:1 }}
                                    />
                                )}
                        </Form.Item>
                        
                    </Col>
                    
                    <Col span={24}>
                        <Form.Item label={geti18nText('blog.edit.image')}>
                            {getFieldDecorator('imagePath', {rules: [{ required: true, message: geti18nText('app.default.required') }],} )(
                                <FileUpload
                                    fileType={CONSTANTS_FILE_TYPE.IMAGE}
                                    map={{ id: 'id', label: 'name' }}
                                    searchBy="name"
                                    disabled={false}
                                    addedData={{ projectId: Session.getProjectId() }}
                                />
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item label={geti18nText('shop.edit.image1')}>
                            {getFieldDecorator('imagePath1', )(
                                <FileUpload
                                    fileType={CONSTANTS_FILE_TYPE.IMAGE}
                                    map={{ id: 'id', label: 'name' }}
                                    searchBy="name"
                                    disabled={false}
                                    addedData={{ projectId: Session.getProjectId() }}
                                />
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item label={geti18nText('shop.edit.image2')}>
                            {getFieldDecorator('imagePath2', )(
                                <FileUpload
                                    fileType={CONSTANTS_FILE_TYPE.IMAGE}
                                    map={{ id: 'id', label: 'name' }}
                                    searchBy="name"
                                    disabled={false}
                                    addedData={{ projectId: Session.getProjectId() }}
                                />
                            )}
                        </Form.Item>
                    </Col>
                    
                    <Col span={24}>
                        {getFieldDecorator('sale', {
                                valuePropName: 'checked',
                                initialValue: false,
                            })(
                                <Checkbox
                                    onChange={(e) => {
                                        setSelectedAsAction(e);
                                    }}
                                >
                                    {geti18nText('shop.edit.select.as.sale')}
                                </Checkbox>
                            )}
                    </Col>
                    
                   
                </Row>
            </DataEdit>
        </>
    );
});

export default ShopEdit;
