import { Button, Icon, Input, message, Modal } from 'antd';
import React, { forwardRef, useEffect, useState } from 'react';
import { CONSTANTS_REQ } from '../utils/Constants';
import { requestPost, RESPONSE } from '../utils/RequestResolver';
import { geti18nText, Session } from '../utils/Session';

export const AddFolder = forwardRef(({ onChange, visible, folderValue, projectId, fetchTree }, ref) => {
    const [newFolderName, setNewFolderName] = useState(null);
    const [show, setShow] = useState(false);

    useEffect(() => {
        setShow(visible);
    }, [visible]);

    const closeNewFolderModal = () => {
        setShow(false);
        onChange(false);
    };

    const saveNewFolder = () => {
        requestPost(CONSTANTS_REQ.DIRECTORY.TREE, undefined, {
            id: projectId,
            folder: folderValue,
            name: newFolderName,
        }).then((result) => {
            if (result && result.status === RESPONSE.CREATED) {
                message.success(`${geti18nText('upload.new.folder.success')} `);
                closeNewFolderModal();
                if (isFunction(fetchTree)) {
                    fetchTree();
                }
            } else {
                message.error(`${geti18nText('upload.new.folder.error')} `);
                closeNewFolderModal();
                if (isFunction(fetchTree)) {
                    fetchTree();
                }
            }
        });
    };
    const onChangeNewFolderName = (name) => {
        setNewFolderName(name.target.value);
    };

    function isFunction(functionToCheck) {
        return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
    }

    return (
        <Modal
            title={geti18nText('upload.new.folder')}
            visible={show}
            onCancel={closeNewFolderModal}
            destroyOnClose={true}
            footer={[
                <Button key="cancelNewFolder" onClick={closeNewFolderModal}>
                    {geti18nText('app.default.button.cancel')}
                </Button>,
                <Button type="primary" key="saveNewFolder" onClick={saveNewFolder}>
                    {geti18nText('app.default.button.save')}
                </Button>,
            ]}
        >
            <Input
                onChange={onChangeNewFolderName}
                allowClear
                placeholder="Insert folder name"
                prefix={<Icon type="folder" />}
            />
        </Modal>
    );
});
