import { Col, Checkbox, DatePicker, Form, Input, Row, Popconfirm, Modal,Radio } from 'antd';
import React, { useState } from 'react';
import { DataEdit } from '../../../components/DataEdit';
import { FileUpload } from '../../../components/fileUpload/FileUpload';
import { InputByLanguage, translationsToObject } from '../../../components/InputByLanguage';
import { CONSTANTS_FILE_TYPE, CONSTANTS_REQ } from '../../../utils/Constants';
import { geti18nText, Session, hasAnyRole } from '../../../utils/Session';
import moment from 'moment';
import { requestGet, RESPONSE, requestPost } from '../../../utils/RequestResolver';
import { SearchField } from '../../../components/Search';
import { normalizeComponents } from '../../../utils/Utils';

const WebPageLinksEdit = Form.create({ name: 'WebPageLinksEdit' })((props) => {
    const { getFieldDecorator,getFieldValue, setFieldsValue } = props.form;
    const [editHeader, setEditHeader] = useState(geti18nText('webPageLinks.edit.new'));
    const [loading, setLoading] = useState(false);
    const [contextTextLangJson, setContextTextLangJson] = useState(null);
    const LINK_TYPE = {
        LINK: 'LINK',
        WEBPAGE: 'WEBPAGE',
    };
    const [linkType, setLinkType] = useState(LINK_TYPE.WEBPAGE);

    function setValues(webPageLink) {
        webPageLink = translationsToObject(webPageLink, 'name', webPageLink.name);
        if(webPageLink.linkType) {
            setLinkType(webPageLink.linkType)
        }    
        setFieldsValue(webPageLink);
    }

    
    function setDefaultValues() {
        setContextTextLangJson(null);
    }

    function dummy(value) {
    }


    return (
        <>
            <DataEdit
                editHeader={editHeader}
                loading={loading}
                setLoading={setLoading}
                setDefaultValues={setDefaultValues}
                url={CONSTANTS_REQ.WEBPAGELINKS.EDIT}
                setValues={setValues}
                width={1200}
                {...props}
                normalize={(values) => {
                    let val=values;
                    val=normalizeComponents(val)
                    if(val.webPageLink) {
                        val.webPageLink={id: parseInt(val.webPageLink)}
                    }
                    return {
                        ...val,
                        idWebPage: parseInt(props.addedData.webPageId)
                        
                    };
                }}
            >
                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item hidden={true}>{getFieldDecorator('id')(<Input hidden={true} />)}</Form.Item>
                        
                       
                    </Col>
                    <InputByLanguage
                                notRequred
                                fieldName={'name'}
                                label={geti18nText('component.headerMenuSubLinks.topLinks.name')}
                                {...props}
                            />
                    <Col span={24}>
                    <Form.Item>
                        {getFieldDecorator('linkType', { initialValue: LINK_TYPE.WEBPAGE })(
                            <Radio.Group
                                onChange={(e) => {
                                    setLinkType(e.target.value);
                                }}
                            >
                                <Radio value={LINK_TYPE.WEBPAGE}>
                                    {geti18nText('component.footercomponent.edit.radio_button.webPage')}
                                </Radio>
                                <Radio value={LINK_TYPE.LINK}>
                                    {geti18nText('component.footercomponent.edit.external_link')}
                                </Radio>
                            </Radio.Group>
                        )}
                    </Form.Item>
                    {linkType == LINK_TYPE.LINK ? (
                                <Row gutter={24} type="flex" justify="space-between" align="bottom">
                                    <Col span={16}>
                                        <Form.Item label={geti18nText('component.footercomponent.edit.text_link')}>
                                            {getFieldDecorator('link')(
                                                <Input  />
                                            )}
                                        </Form.Item>
                                    </Col>
                                </Row>
                            ) : (
                                <Form.Item label={geti18nText('component.footercomponent.edit.radio_button.webPage')}>
                                    {getFieldDecorator('webPageLink')(
                                        <SearchField
                                            url={CONSTANTS_REQ.WEBPAGE.SEARCH}
                                            map={{ id: 'id', label: 'name' }}
                                            searchBy="name"
                                            disabled={false}
                                            translate={true}
                                            addedData={{ projectId: Session.getProjectId(), forMap: true }}
                                        />
                                    )}
                                </Form.Item>
                            )}
                    </Col>
                </Row>
            </DataEdit>
        </>
    );
});

export default WebPageLinksEdit;
