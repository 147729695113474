import React, { Component } from 'react';
import RichTextEditor, {
    getTextAlignClassName,
    getTextAlignStyles,
    getTextAlignBlockMetadata,
    EditorValue,
} from 'react-rte';
import { RichUtils, EditorState, Modifier, ContentState, convertFromHTML } from 'draft-js';
class RteEditor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value:
                props.value === ''
                    ? RichTextEditor.createEmptyValue()
                    : RichTextEditor.createValueFromString(this.replaceLine(props.value), 'html', {
                          blockStyleFn: getTextAlignStyles,
                      }),
        };
        this.state.id = props.id;
    }

    componentWillReceiveProps = (nextProps) => {
        if (nextProps.value !== this.state.value.toString('html')) {
            this.setState({
                value: nextProps.value
                    ? RichTextEditor.createValueFromString(
                          this.cleanHeaderBold(this.replaceLine(nextProps.value)),
                          'html',
                          {
                              customBlockFn: getTextAlignBlockMetadata,
                          }
                      )
                    : RichTextEditor.createEmptyValue(),
            });
        }
    };

    cleanHeaderBold(e) {
        var value = e;
        const headers = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'];
        headers.forEach((element) => {
            value = value.replaceAll('<' + element + '><strong>', '<' + element + '>');
            value = value.replaceAll('</strong>' + '</' + element + '>', '</' + element + '>');
        });
        return value;
    }

    replaceLine(e) {
        var value = e;
        if (value != undefined) {
            value = value.replaceAll("<span class='border-bottom'></span>", '<p>---</p>');
        }
        return value;
    }

    onEditorStateChange = (value) => {
        console.log(value);
    };

    handleEditorChange = (value) => {
        const isTextChanged =
            this.state.value.toString('html', { blockStyleFn: getTextAlignStyles }) !=
            value.toString('html', { blockStyleFn: getTextAlignStyles });

        this.setState(
            { value },
            (e) =>
                isTextChanged &&
                this.props.onChange(this.cleanHeaderBold(value.toString('html', { blockStyleFn: getTextAlignStyles })))
        );
    };

    setLine = () => {
        let lineKey = '---';
        //  console.log(this.state.value.getEditorState().getSelection());
        var cursorPosition = this.state.value.getEditorState().getSelection().getAnchorOffset();
        // this.state.value.setEditorState(this.insertSplit(lineKey, this.state.value.getEditorState()));
        if (cursorPosition > 0) {
            this.setState({
                value: this.state.value.setEditorState(this.insertSplit(lineKey, this.state.value.getEditorState())),
            });
        }
        setTimeout(() => {
            const res = this.state.value.setEditorState(this.insertText(lineKey, this.state.value.getEditorState()));
            this.setState({
                value: res,
            });
            this.props.onChange(this.cleanHeaderBold(res.toString('html', { blockStyleFn: getTextAlignStyles })));
            if (cursorPosition == 0) {
                this.setState({
                    value: this.state.value.setEditorState(
                        this.insertSplit(lineKey, this.state.value.getEditorState())
                    ),
                });
            }
        }, 100);
    };

    insertText = (text, editorState) => {
        const currentContent = editorState.getCurrentContent(),
            currentSelection = editorState.getSelection();

        const newContent = Modifier.replaceText(currentContent, currentSelection, text);

        const newEditorState = EditorState.push(editorState, newContent, 'split-block');
        return EditorState.forceSelection(newEditorState, newContent.getSelectionAfter());
    };

    insertTextFragment = (text, editorState) => {
        const currentContent = editorState.getCurrentContent(),
            currentSelection = editorState.getSelection();
        const blocks = convertFromHTML('<p>' + text + '</p>');
        const newContent = Modifier.replaceWithFragment(
            currentContent,
            currentSelection,

            ContentState.createFromBlockArray(blocks, blocks.entityMap).getBlockMap()
        );
        console.log('html ', ContentState.createFromText('<p>' + text + '</p>').getBlockMap());
        const newEditorState = EditorState.push(editorState, newContent, 'insert-fragment');
        return EditorState.forceSelection(newEditorState, newContent.getSelectionAfter());
    };
    insertSplit = (text, editorState) => {
        const currentContent = editorState.getCurrentContent(),
            currentSelection = editorState.getSelection();
        const newContent = Modifier.splitBlock(currentContent, currentSelection);
        const newEditorState = EditorState.push(editorState, newContent, 'split-block');

        // return newEditorState;
        return EditorState.forceSelection(newEditorState, newContent.getSelectionAfter());
    };

    render() {
        const toolbarConfig = {
            display: [
                'INLINE_STYLE_BUTTONS',
                'BLOCK_TYPE_BUTTONS',
                'LINK_BUTTONS',
                'BLOCK_TYPE_DROPDOWN',
                'HISTORY_BUTTONS',
                'BLOCK_ALIGNMENT_BUTTONS',
            ], //'LINK_BUTTONS',
            INLINE_STYLE_BUTTONS: [
                { label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
                { label: 'Italic', style: 'ITALIC' },
                { label: 'Underline', style: 'UNDERLINE' },
            ],
            BLOCK_ALIGNMENT_BUTTONS: [
                { label: 'Align Left', style: 'ALIGN_LEFT', className: 'custom-css-class' },
                { label: 'Align Center', style: 'ALIGN_CENTER' },
                { label: 'Align Right', style: 'ALIGN_RIGHT' },
                { label: 'Align Justify', style: 'ALIGN_JUSTIFY' },
            ],
            BLOCK_TYPE_DROPDOWN: [
                { label: 'Normal', style: 'unstyled' },
                { label: 'Heading Large', style: 'header-one' },
                { label: 'Heading Medium', style: 'header-two' },
                { label: 'Heading Small', style: 'header-three' },
            ],
            BLOCK_TYPE_BUTTONS: [
                { label: 'UL', style: 'unordered-list-item' },
                { label: 'OL', style: 'ordered-list-item' },
            ],
        };

        const customControl = [
            () => (
                <div className="buttonLineGroup">
                    <button className="lineSearch" onClick={() => this.setLine()}>
                        -
                    </button>
                </div>
            ),
        ];

        return (
            <React.Fragment>
                <RichTextEditor
                    value={this.state.value}
                    onChange={(event) => this.handleEditorChange(event)}
                    id={this.state.id}
                    toolbarConfig={toolbarConfig}
                    blockStyleFn={getTextAlignClassName}
                    customControls={customControl}
                    style={{ minHeight: '300px' }}
                />
            </React.Fragment>
        );
    }
}

export default RteEditor;
