import React, { useState, useEffect, useImperativeHandle } from 'react';
import { Form, Row, Col, Button, Modal, Table, Tag } from 'antd';
import { Spinner } from '../Spinner';
import { geti18nText } from '../../utils/Session';
import { getTranslated } from '../InputByLanguage';
import ConfiguratorPackageEdit from './ConfiguratorPackageEdit';
import ConfiguratorGroupOptionsEdit from './ConfiguratorGroupOptionsEdit';
import { getColumnSearch } from '../../components/DataTable';
import { InputByLanguage, translationsToObject } from '../InputByLanguage';
import { normalizeComponents } from '../../utils/Utils';

const ConfiguratorGroupOptionsTable = Form.create({ name: 'ConfiguratorGroupOptionsTable' })((props) => {
    const [fetching, setFetch] = useState(false);
    const [dataSource, setDataSource] = useState([]);
    const [dataSourceFiltered, setDataSourceFiltered] = useState([]);
    const [popupVisible, setPopupVisible] = useState(false);
    const [popupValue, setPopupValue] = useState(undefined);
    const { getFieldDecorator, setFieldsValue } = props.form;

    useEffect(() => {
        setValues(props.data);
    }, [props.data]);

    function setValues(values) {
        if (values != null) {
            setFetch(true);
            var json = JSON.parse(JSON.stringify(values));
            json = translationsToObject(json, 'option_title', json['option_title']);
            delete json['option_title'];
            setDataSource(json.items);
            setDataSourceFiltered(json.items);
            setFieldsValue(json);
            setFetch(false);
        }
    }

    function onNewItemHandler() {
        setPopupValue(undefined);
        setPopupVisible(true);
    }

    function onRowSelectHandler(record) {
        setPopupValue(record);
        setPopupVisible(true);
    }
    function closePopup() {
        setPopupVisible(false);
    }
    function handleTableChange(pagination, filters, sorter) {
        let params = {
            max: pagination.pageSize,
            offset: pagination.current - 1,
            ...getSort(sorter),
            ...getFilters(filters),
        };
    }
    function getFilters(filters) {
        const transformedFilters = [];
        if (filters) {
            for (var key in filters) {
                if (filters.hasOwnProperty(key) && filters[key].length > 0) {
                    transformedFilters.push({ field: key, ...filters[key][0] });
                }
            }
            if (props.setDefaultFilterValue) {
                const defaultFilter = props.setDefaultFilterValue();
                for (let dFilter of defaultFilter) {
                    if (transformedFilters.find(({ field }) => field == dFilter['field']) === undefined) {
                        transformedFilters.push(dFilter);
                    }
                }
            }
            applayFilter(transformedFilters);
        }
    }

    function getSort(sorter) {
        if (sorter.field) {
            const sort = { order: undefined, orderType: undefined };
            sort.order = sorter.field;
            sort.orderType = sorter.order === 'ascend' ? 'asc' : 'desc';
            return sort;
        } else {
            return undefined;
        }
    }

    function applayFilter(filterArray) {
        var elements = [...dataSource];
        filterArray.forEach((filter) => {
            elements = elements.filter((item) => {
                if (filter.condition == 'contains') {
                    const field = getTranslated(item[filter['field']]);
                    const value = filter['value'];
                    return field.includes(value);
                } else {
                    const field =
                        typeof item[filter['field']] == 'number'
                            ? item[filter['field']].toString()
                            : getTranslated(item[filter['field']].toString());
                    const value = filter['value'];
                    return field == value;
                }
            });
        });
        setDataSourceFiltered(elements);
    }

    function updateItemsFromModal(json) {
        const elements = [...dataSource];
        var currentElementIndex = elements.findIndex((x) => x.id === json.id);

        if (currentElementIndex === -1) {
            elements.push(json);
        } else {
            elements[currentElementIndex] = json;
        }
        setDataSource(elements);
        setDataSourceFiltered(elements);
        console.log('elements ', elements);
        closePopup();
    }

    function deleteItem(id) {
        const elements = [...dataSource];
        var currentElementIndex = elements.findIndex((x) => x.id === id);

        if (currentElementIndex != -1) {
            elements.splice(currentElementIndex, 1);
        }
        console.log('deletes ', elements);
        setDataSource(elements);
        setDataSourceFiltered(elements);
        closePopup();
    }

    const columns = [
        {
            title: geti18nText('component.configurator.option.name'),
            dataIndex: 'option_name',
            key: 'option_name',
            render: (text, record) => <div>{getTranslated(text)}</div>,
            sorter: (a, b) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('component.configurator.option.price'),
            dataIndex: 'option_price',
            key: 'option_price',
            sorter: (a, b) => {},
            render: (text, record) => <div>{text?.toFixed(2).toString().replace('.', ',') + ' €'}</div>,
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('component.configurator.option.description'),
            dataIndex: 'option_desc',
            key: 'option_desc',
            render: (text, record) => (
                <div
                    className="magicPackagesDescription"
                    dangerouslySetInnerHTML={{
                        __html: text ? getTranslated(text).replace(/(<? *script)/gi, 'illegalscript') : '',
                    }}
                ></div>
            ),
            ...getColumnSearch('string'),
        },
    ];

    function save(e) {
        e.preventDefault();
        console.log('save');
        props.form.validateFieldsAndScroll((err, values) => {
            console.log('save1');
            // if (showError == false) {
            values = normalizeComponents(values);
            values.items = dataSource;
            props.updateItemsFromModal(values);
            //}
        });
    }

    if (fetching) {
        return <Spinner />;
    } else {
        return (
            <React.Fragment>
                <Row gutter={24}>
                    <Col span={24}>
                        <InputByLanguage
                            notRequred
                            fieldName={'option_title'}
                            label={geti18nText('component.configurator.option.name')}
                            // langJson={item.nameJson}
                            notRequred={true}
                            {...props}
                        />
                    </Col>
                </Row>
                <Table
                    dataSource={dataSourceFiltered}
                    columns={columns}
                    onChange={handleTableChange}
                    onRow={(record, rowIndex) => {
                        return {
                            onClick: (event) => {},
                            onDoubleClick: (event) => {
                                onRowSelectHandler(record);
                            },
                        };
                    }}
                />
                <Row gutter={24}>
                    <Col style={{ textAlign: 'left', marginTop: '1rem' }}>
                        <Button type="primary" onClick={(e) => onNewItemHandler()}>
                            {geti18nText('component.configurator.table.btn.add')}
                        </Button>
                    </Col>
                    <Col style={{ textAlign: 'right', marginTop: '1rem' }}>
                        <Button style={{ marginLeft: '20px' }} type="primary" onClick={(e) => save(e)}>
                            {geti18nText('app.default.button.save')}
                        </Button>
                    </Col>
                </Row>

                <Modal
                    visible={popupVisible}
                    onCancel={closePopup}
                    keyboard={false}
                    maskClosable={false}
                    width={1200}
                    footer={[]}
                    destroyOnClose={true}
                >
                    <ConfiguratorGroupOptionsEdit
                        popupValue={popupValue}
                        updateItemsFromModal={updateItemsFromModal}
                        deleteItem={deleteItem}
                    ></ConfiguratorGroupOptionsEdit>
                </Modal>
            </React.Fragment>
        );
    }
});

export default ConfiguratorGroupOptionsTable;
