import React, { useState, useEffect } from 'react';
import { Form, Row, Col, Input, Button, Divider, Tabs, Popconfirm, InputNumber, Radio, notification } from 'antd';
import { Session, geti18nText } from '../../utils/Session';
import { requestGet, requestPut, RESPONSE } from '../../utils/RequestResolver';
import { CONSTANTS_FILE_TYPE, CONSTANTS_REQ } from '../../utils/Constants';
import { normalizeComponents } from '../../utils/Utils';
import { FileUpload } from '../fileUpload/FileUpload';
import { InputByLanguage, translationsToObject } from '../InputByLanguage';
import { SearchField } from '../Search';
import { Spinner } from '../Spinner';
import { normalizeJSONForComponent, getDiskFiles } from '../../utils/ComponentHelper';
import SortImagesOrVideos, { ReorderImageKeys } from '../SortImagesOrVideos';
import { ColorPicker } from '../ColorPicker';

const TitleLinkEdit = Form.create({ name: 'TitleLinkEdit' })((props) => {
    const [fetching, setFetching] = useState(false);
    const { getFieldDecorator, setFieldsValue } = props.form;
    const [mainTitleLangJson, setMainTitleLangJson] = useState(undefined);
    const [leftTitleLangJson, setLeftTitleLangJson] = useState(undefined);
    const [rightTitleLangJson, setRightTitleLangJson] = useState(undefined);
    const [formValues, setFormValues] = useState(undefined);
    const [rightExternalLink, setRightExternalLink] = useState(JSON.stringify(props.rightExternalLink));
    const LINK_TYPE = {
        LINK: 'LINK',
        WEBPAGE: 'WEBPAGE',
    };
    const [leftLinkType, setLeftLinkType] = useState(props.linkType ? props.linkType : LINK_TYPE.WEBPAGE);
    const [rightLinkType, setRightLinkType] = useState(props.linkType ? props.linkType : LINK_TYPE.WEBPAGE);

    useEffect(() => {
        fetch();
    }, [props.visible]);

    useEffect(() => {
        props.form.resetFields();
        setFieldsValue(formValues);
    }, [formValues]);

    function fetch() {
        setFetching(true);
        requestGet(CONSTANTS_REQ.WEBPAGECOMPONENT.EDIT + '/' + props.id).then((result) => {
            setFetching(false);
            if (result.status === RESPONSE.OK) {
                setValues(result.data);
            }
        });
    }

    function setValues(component_data) {
        var json;
        if (component_data.json) {
            json = JSON.parse(component_data.json);
            setWepPageValue('left-link', parseInt(json['left-link']));
            setWepPageValue('right-link', parseInt(json['right-link']));
            setLeftLinkType(json['left-link_type']);
            setRightLinkType(json['right-link_type']);

            setMainTitleLangJson(json['main-title']);
            setLeftTitleLangJson(json['left-title']);
            setRightTitleLangJson(json['right-title']);

            json = translationsToObject(json, 'main-title', json['main-title']);
            json = translationsToObject(json, 'left-title', json['left-title']);
            json = translationsToObject(json, 'right-title', json['right-title']);
            console.log('moj json');
            console.log(json);
            setFormValues(json);
        }
    }

    function setWepPageValue(key, id) {
        requestGet(CONSTANTS_REQ.WEBPAGE.EDIT + '/' + id).then((result) => {
            if (result.status === RESPONSE.OK) {
                setFieldsValue({ [key]: { id: id, name: result.data.name } });
            }
        });
    }

    function showNotification(title, message, duration, type) {
        notification[type]({
            message: title,
            description: message,
            duration: duration,
        });
    }

    function okNotification() {
        showNotification(geti18nText('app.default.save.ok'), geti18nText('app.default.save.ok.desc'), 3, 'success');
    }

    function error(message) {
        showNotification(geti18nText('app.default.save.nok'), message, 3, 'error');
    } 

    function save(e, close) {
        e.preventDefault();

        props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                values = normalizeComponents(values);

                values = normalize(values);
                requestPut(CONSTANTS_REQ.WEBPAGECOMPONENT.UPDATEJSON + '/' + props.id, null, {
                    id: props.id,
                    json: values.json,
                }).then((result) => {
                    if (result && result.status === RESPONSE.CREATED) {
                        console.log('okNotification');
                        okNotification();
                        close && props.closePopup();
                    } else {
                        error();
                        if (result && result.status === RESPONSE.BAD_REQUEST) {
                            if (result.data && result.data.field) {
                                switch (result.data.field) {
                                    case 'mandatory_fields_required':
                                        console.log(geti18nText('app.default.mandatory_fields_required'));
                                        return;
                                    default:
                                        break;
                                }
                            }
                        }
                        if (result.data && result.data.error) {
                            console.log(JSON.stringify(result.data.error));
                        }
                    }
                });
            }
        });
    }

    function normalize(values, translationKeys) {
        let jsonFieldKeys = {};
        let jsonFieldGroups = [
            'left-link_type',
            'left-link',
            'left-external-link',
            'left-title',
            'right-link_type',
            'right-external-link',
            'right-link',
            'main-title',
            'right-title',
        ];
        console.log('vrijednostiiii');
        console.log(values);
        if (values['left-external-link'] === null) {
            values['left-external-link'] = '';
        }
        if (values['right-external-link'] === null) {
            values['right-external-link'] = '';
        }
        if (values['left-link'] !== null) {
            values['left-link'] = values['left-link'].key ? values['left-link'].key + '' : values['left-link'];
            values['left-link'] = values['left-link'].id ? values['left-link'].id + '' : values['left-link'];
        }

        if (values['right-link'] !== null) {
            values['right-link'] = values['right-link'].key ? values['right-link'].key + '' : values['right-link'];
            values['right-link'] = values['right-link'].id ? values['right-link'].id + '' : values['right-link'];
        }

        return normalizeJSONForComponent(values, undefined, jsonFieldGroups, jsonFieldKeys, props);
    }

    if (fetching) {
        return <Spinner />;
    } else {
        return (
            <React.Fragment>
                <Divider>{geti18nText('component.titlelink')}</Divider>
                <Form span={24} style={{ marginLeft: '12px', marginRight: '12px' }}>
                    <Row gutter={24}>
                        <Row>
                            <Col span={24}>
                                <InputByLanguage
                                    fieldName={'main-title'}
                                    label={geti18nText('component.titlelink.title')}
                                    langJson={mainTitleLangJson}
                                    {...props}
                                />
                            </Col>
                        </Row>
                        <Row
                            className="flex-box"
                            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                        >
                            <Col style={{ marginRight: '20px', width: '100%' }}>
                                <Row>
                                    <Col span={24}>
                                        <InputByLanguage
                                            notRequred
                                            fieldName={'left-title'}
                                            label={geti18nText('component.titlelink.left.title')}
                                            langJson={leftTitleLangJson}
                                            {...props}
                                        />
                                    </Col>
                                </Row>
                                {geti18nText('component.titlelink.left')}
                                <Form.Item>
                                    {getFieldDecorator('left-link_type', {
                                        initialValue:
                                            leftLinkType === LINK_TYPE.LINK ? LINK_TYPE.LINK : LINK_TYPE.WEBPAGE,
                                    })(
                                        <Radio.Group
                                            onChange={(e) => {
                                                setLeftLinkType(e.target.value);
                                            }}
                                        >
                                            <Radio value={LINK_TYPE.WEBPAGE}>
                                                {geti18nText('component.imagewithtext.edit.radio_button.webPage')}
                                            </Radio>
                                            <Radio value={LINK_TYPE.LINK}>
                                                {geti18nText('component.imagewithtext.edit.radio_button.link')}
                                            </Radio>
                                        </Radio.Group>
                                    )}
                                </Form.Item>
                                <Form.Item
                                    hidden={leftLinkType !== LINK_TYPE.LINK}
                                    label={geti18nText('webpage.edit.search.external.link')}
                                >
                                    {getFieldDecorator('left-external-link')(<Input />)}
                                </Form.Item>
                                <Form.Item
                                    hidden={leftLinkType !== LINK_TYPE.WEBPAGE}
                                    label={geti18nText('component.imagewithtext.edit.webpage')}
                                >
                                    {getFieldDecorator('left-link')(
                                        <SearchField
                                            url={CONSTANTS_REQ.WEBPAGE.SEARCH}
                                            map={{ id: 'id', label: 'name' }}
                                            searchBy="name"
                                            disabled={false}
                                            translate={true}
                                            addedData={{ projectId: Session.getProjectId(), forMap: true }}
                                        />
                                    )}
                                </Form.Item>{' '}
                            </Col>
                            <Col style={{ marginLeft: '20px', width: '100%' }}>
                                <Row>
                                    <Col span={24}>
                                        <InputByLanguage
                                            notRequred
                                            fieldName={'right-title'}
                                            label={geti18nText('component.titlelink.right.title')}
                                            langJson={rightTitleLangJson}
                                            {...props}
                                        />
                                    </Col>
                                </Row>
                                {geti18nText('component.titlelink.right')}
                                <Form.Item>
                                    {getFieldDecorator('right-link_type', {
                                        initialValue:
                                            rightLinkType === LINK_TYPE.LINK ? LINK_TYPE.LINK : LINK_TYPE.WEBPAGE,
                                    })(
                                        <Radio.Group
                                            onChange={(e) => {
                                                setRightLinkType(e.target.value);
                                            }}
                                        >
                                            <Radio value={LINK_TYPE.WEBPAGE}>
                                                {geti18nText('component.imagewithtext.edit.radio_button.webPage')}
                                            </Radio>
                                            <Radio value={LINK_TYPE.LINK}>
                                                {geti18nText('component.imagewithtext.edit.radio_button.link')}
                                            </Radio>
                                        </Radio.Group>
                                    )}
                                </Form.Item>
                                <Form.Item
                                    hidden={rightLinkType !== LINK_TYPE.LINK}
                                    label={geti18nText('webpage.edit.search.external.link')}
                                >
                                    {getFieldDecorator('right-external-link')(
                                        <Input
                                            value={rightExternalLink}
                                            onChange={(event) => {
                                                setRightExternalLink(event.target.value);
                                            }}
                                        />
                                    )}
                                </Form.Item>
                                <Form.Item
                                    hidden={rightLinkType !== LINK_TYPE.WEBPAGE}
                                    label={geti18nText('component.imagewithtext.edit.webpage')}
                                >
                                    {getFieldDecorator('right-link')(
                                        <SearchField
                                            url={CONSTANTS_REQ.WEBPAGE.SEARCH}
                                            map={{ id: 'id', label: 'name' }}
                                            searchBy="name"
                                            disabled={false}
                                            translate={true}
                                            addedData={{ projectId: Session.getProjectId(), forMap: true }}
                                        />
                                    )}
                                </Form.Item>{' '}
                            </Col>
                        </Row>
                    </Row>
                    <Row>
                        <Col span={24} style={{ textAlign: 'right', marginTop: '1rem' }}>
                            <Button style={{ marginRight: '1em' }} onClick={(e) => props.closePopup()}>
                                {geti18nText('app.default.button.cancel')}
                            </Button>
                            <Button style={{ marginRight: '1em' }} type="primary" onClick={(e) => save(e, false)}>
                                {geti18nText('app.default.button.save')}
                            </Button>
                            <Button
                                type="primary"
                                style={{ background: '#31b531', borderColor: '#31b531', textColor: 'white' }}
                                onClick={(e) => {
                                    save(e, true);
                                }}
                            >
                                {geti18nText('app.default.button.save.close')}
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </React.Fragment>
        );
    }
});

export default TitleLinkEdit;
