import React, { useState, useEffect } from "react";
import { Icon, Form, Row, Col, Input, Radio,DatePicker,InputNumber } from "antd";
import { CONSTANTS_REQ,CONSTANTS_FILE_TYPE } from "../../../utils/Constants";
import { DataEdit } from "../../../components/DataEdit";
import { Session, geti18nText } from "../../../utils/Session";
import { requestGet, RESPONSE } from "../../../utils/RequestResolver";
import { setProjectLanguages } from "../../project/ProjectIndex";
import { SearchField, mapDataToSelect } from '../../../components/Search';
import { InputByLanguage, translationsToObject } from '../../../components/InputByLanguage';


const FaqQuestionAnswerEdit = Form.create({ name: "FaqQuestionAnswerEdit" })((props) => {
    const { getFieldDecorator, setFieldsValue } = props.form;
    const [editHeader, setEditHeader] = useState(geti18nText("faqQuestionAnswer.edit.new"));
    const [loading, setLoading] = useState(false);

    
    function setValues(values) {
        setEditHeader(values.id);
        values = translationsToObject(values, 'question', values.question);
        values = translationsToObject(values, 'answer', values.answer);
        if(values.category) {
            values.category= { id: values.category.id , text: values.category.name  }
        }
        delete values.active;
        setFieldsValue(values);
    }


    function isCreate() {
        return props.value == undefined;
    }

    function onSave() {
        setProjectLanguages(Session.getProjectId());
        props.onSave();
    }

    function setDefaultValues() {
        
        setEditHeader(geti18nText("faqQuestionAnswer.edit.new"));
     
}


    return (
        <>
            <DataEdit
                editHeader={editHeader}
                loading={loading}
                setLoading={setLoading}
                url={CONSTANTS_REQ.FAQ_QUESTION_ANSWER.EDIT}
                setValues={setValues}
                setDefaultValues={setDefaultValues}
                width={1200}
                {...props}
                onSave={onSave}
                normalize={(values) => {
                    return { ...values, project: { id: Session.getProjectId() }, category:{id: values.category.id? parseInt(values.category.id):  parseInt(values.category)} };
                }}
            >
                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item hidden={true}>{getFieldDecorator("id")(<Input hidden={true} />)}
                        </Form.Item>
                        <Form.Item label={geti18nText('faq.edit.category')}>
                            {getFieldDecorator('category',{
                                rules: [{ required: true, message: geti18nText('app.default.required') }],
                            })(
                                <SearchField
                                    url={CONSTANTS_REQ.CATEGORY.SEARCH}
                                    map={{ id: 'id', label: 'text' }}
                                    searchBy="text"
                                    disabled={false}
                                    translate={true}

                                    addedData={{ projectId: Session.getProjectId(), type:0 }}
                                />
                            )}
                        </Form.Item>
                        <InputByLanguage
                            fieldName="question"
                            
                            label={geti18nText('faqQuestionAnswer.table.column.questions')}
                            {...props}
                        />
                        <InputByLanguage
                            fieldName="answer"
                            
                            label={geti18nText('faqQuestionAnswer.table.column.answer')}
                            {...props}
                        />

                        <Form.Item label={geti18nText('faqYl.order')}>
                        {getFieldDecorator('order', {
                                    rules: [{ required: true, message: geti18nText('app.default.required') }],
                                } )(
                            <InputNumber min={1} />
                        )}
                        
                        </Form.Item>
                        
                        
                        
                    </Col>
                </Row>
            </DataEdit>
        </>
    );
});

export default FaqQuestionAnswerEdit;
