import React, { useState, useEffect } from "react";
import { Icon, Form, Row, Col, Input, Radio,DatePicker } from "antd";
import { CONSTANTS_REQ,CONSTANTS_FILE_TYPE } from "../../../utils/Constants";
import { DataEdit } from "../../../components/DataEdit";
import { Session, geti18nText } from "../../../utils/Session";
import { requestGet, RESPONSE } from "../../../utils/RequestResolver";
import { setProjectLanguages } from "../../project/ProjectIndex";
import { SearchField, mapDataToSelect } from '../../../components//Search';
import { FileUpload } from '../../../components/fileUpload/FileUpload';
import moment from 'moment';
import { InputByLanguage, translationsToObject } from '../../../components/InputByLanguage';



const BlogEdit = Form.create({ name: "BlogEdit" })((props) => {
    const { getFieldDecorator, setFieldsValue } = props.form;
    const [editHeader, setEditHeader] = useState(geti18nText("blog.edit.new"));
    const [loading, setLoading] = useState(false);
    const LINK_TYPE = {
        LINK: 'LINK',
        WEBPAGE: 'WEBPAGE',
    };
    const [linkType, setLinkType] = useState(props.type ? props.type : LINK_TYPE.WEBPAGE);


    function setValues(values) {
        setEditHeader(values.id);
        values = translationsToObject(values, 'title', values.title);
        values = translationsToObject(values, 'description', values.description);
        delete values.active;
        if(values.imagePath) {
            values['imagePath'] = {
                key: values.imagePath,
                file_path: values.imagePath,
            };
        }
        if(values.linkType) {
            setLinkType(values.linkType)
        }
        if(values.date) {
            values.date=moment(values.date)
        }
        if(values.linkType) {
            if(values.linkType === 'WEBPAGE') {
                setWepPageValue('link',parseInt(values.link) )
            }

        }
        setFieldsValue(values);
    }

    function setWepPageValue(key, id) {
        requestGet(CONSTANTS_REQ.WEBPAGE.EDIT + '/' + id).then((result) => {
            if (result.status === RESPONSE.OK) {
                let field = {};
                field[key] = { id: id, name: result.data.name };
                setFieldsValue(field);
            }
        });
    }

   

    function isCreate() {
        return props.value == undefined;
    }

    function onSave() {
        setProjectLanguages(Session.getProjectId());
        props.onSave();
    }

    function setDefaultValues() {
        
        setEditHeader(geti18nText("blog.edit.new"));
     
    }


    return (
        <>
            <DataEdit
                editHeader={editHeader}
                loading={loading}
                setLoading={setLoading}
                url={CONSTANTS_REQ.BLOG.EDIT}
                setValues={setValues}
                setDefaultValues={setDefaultValues}
                width={1200}
                {...props}
                onSave={onSave}
                normalize={(values) => {
                    if(values.link) {
                        if (values.link.id) {
                            values.link=values.link.id
                        }
                    }
                    return { ...values, project: { id: Session.getProjectId() } };
                }}
            >
                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item hidden={true}>{getFieldDecorator("id")(<Input hidden={true} />)}</Form.Item>
                        <InputByLanguage
                            fieldName="title"
                            
                            label={geti18nText('blog.edit.name')}
                            {...props}
                        />
                        <InputByLanguage
                            fieldName="description"
                            
                            label={geti18nText('blog.edit.description')}
                            {...props}
                        />
                        
                    </Col>
                    <Col span={12}>
                        <Form.Item label={geti18nText('blog.edit.date')}>
                            {getFieldDecorator('date', {
                                initialValue: moment(),
                                
                            })(<DatePicker format="DD.MM.YYYY" />)}
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item label={geti18nText('blog.edit.image')}>
                            {getFieldDecorator('imagePath', )(
                                <FileUpload
                                    fileType={CONSTANTS_FILE_TYPE.IMAGE}
                                    map={{ id: 'id', label: 'name' }}
                                    searchBy="name"
                                    disabled={false}
                                    addedData={{ projectId: Session.getProjectId() }}
                                />
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                    <Form.Item>
                        {getFieldDecorator('linkType', { initialValue: LINK_TYPE.WEBPAGE })(
                            <Radio.Group
                                onChange={(e) => {
                                    setLinkType(e.target.value);
                                }}
                            >
                                <Radio value={LINK_TYPE.WEBPAGE}>
                                    {geti18nText('blog.edit.radio_button.webPage')}
                                </Radio>
                                <Radio value={LINK_TYPE.LINK}>
                                    {geti18nText('blog.edit.external_link')}
                                </Radio>
                            </Radio.Group>
                        )}
                    </Form.Item>
                    </Col>
                    <Col span={24}>
                    {linkType == LINK_TYPE.LINK ? (
                                <Row gutter={24} type="flex" justify="space-between" align="bottom">
                                    <Col span={16}>
                                        <Form.Item label={geti18nText('blog.edit.text_link')}>
                                            {getFieldDecorator('link')(
                                                <Input defaultValue={props.externalLink} />
                                            )}
                                        </Form.Item>
                                    </Col>
                                </Row>
                            ) : (
                                <Form.Item label={geti18nText('blog.edit.radio_button.webPage')}>
                                    {getFieldDecorator('link')(
                                        <SearchField
                                            url={CONSTANTS_REQ.WEBPAGE.SEARCH}
                                            map={{ id: 'id', label: 'name' }}
                                            searchBy="name"
                                            disabled={false}
                                            translate={true}
                                            addedData={{ projectId: Session.getProjectId(), forMap: true }}
                                        />
                                    )}
                                </Form.Item>
                    )}
                    </Col>
                   
                </Row>
            </DataEdit>
        </>
    );
});

export default BlogEdit;
