import React, { useState, useEffect } from 'react';
import { Form, Row, Col, Input, Button, Divider, Tabs, Popconfirm, Checkbox, InputNumber, notification } from 'antd';
import { Session, geti18nText } from '../../utils/Session';
import { normalizeComponents } from '../../utils/Utils';
import { requestGet, requestPut, RESPONSE } from '../../utils/RequestResolver';
import { CONSTANTS_FILE_TYPE, CONSTANTS_REQ } from '../../utils/Constants';
import { FileUpload } from '../fileUpload/FileUpload';
import { InputByLanguage, translationsToObject } from '../InputByLanguage';
import { Spinner } from '../Spinner';
import { SearchField } from '../Search';

const CardImageTextEdit = Form.create({ name: 'CardImageTextEdit' })((props) => {
    const { getFieldDecorator, setFieldsValue } = props.form;
    const [fetching, setFetching] = useState(false);
    const [linkTextJson, setLinkTextJson] = useState(undefined);
    const [imagesKeys, setImageKeys] = useState([0]);
    const [lastKey, setLastKey] = useState(0);
    const [images, setImages] = useState([]);
    const [formValues, setFormValues] = useState(null);
    const [deletedImages, setDeletedImages] = useState([]);
    const [activeTab, setActiveTab] = useState('0');
    const [confirmVisible, setConfirmVisible] = useState(false);
    const [imageToDelete, setImageToDelete] = useState(null);
    const [textOnImage, setTextOnImage] = useState(false);
    const [cardScrolling, setCardScrolling] = useState(false);
    const [tabsKey, setTabsKey] = useState(Date.now());
    const { TabPane } = Tabs;

    useEffect(() => {
        fetch();
    }, [props.visible]);

    useEffect(() => {
        props.form.resetFields();

        for (const key of imagesKeys) {
            if (formValues && formValues['webPage_' + key]) {
                setWepPageValue(key, formValues['webPage_' + key].id);
                delete formValues['webPage_' + key];
            }
        }
        setFieldsValue(formValues);
    }, [formValues]);

    function fetch() {
        setFetching(true);
        requestGet(CONSTANTS_REQ.WEBPAGECOMPONENT.EDIT + '/' + props.id).then((result) => {
            setFetching(false);
            if (result.status === RESPONSE.OK) {
                setValues(result.data);
            }
        });
    }

    function setValues(component_data) {
        let vidArray = [];
        var json;
        if (component_data.json) {
            json = JSON.parse(component_data.json);
            json['textOnImage'] && setTextOnImage(true);
            json['cardScrolling'] && setCardScrolling(true);

            let max_key = 0;
            for (const key of json.imagesKeys) {
                vidArray.push({ id: key, titleLangJson: json['title_' + key], textLangJson: json['text_' + key] });
                json = translationsToObject(json, 'title_' + key, json['title_' + key]);
                delete json['title_' + key];
                json = translationsToObject(json, 'description_' + key, json['description_' + key]);
                delete json['description_' + key];
                json = translationsToObject(json, 'link_text_' + key, json['link_text_' + key]);
                delete json['link_text_' + key];
                json = translationsToObject(json, 'title_bottom_' + key, json['title_bottom_' + key]);
                delete json['title_bottom_' + key];
                json = translationsToObject(json, 'text_bottom_' + key, json['text_bottom_' + key]);
                delete json['text_bottom_' + key];
                max_key = key > max_key ? key : max_key;
            }

            setLastKey(max_key);
            setImages(vidArray);
            setImageKeys(json.imagesKeys);
            setActiveTab('' + json.imagesKeys[0]);

            delete json.imagesKeys;
            delete json['title'];

            setFormValues(json);
        } else {
            setImageKeys([0]);
            setImages([{ id: 0 }]);
        }
    }

    function setWepPageValue(key, id) {
        requestGet(CONSTANTS_REQ.WEBPAGE.EDIT + '/' + id).then((result) => {
            if (result.status === RESPONSE.OK) {
                let field = {};
                field['webPage_' + key] = { id: id, name: result.data.name };
                setFieldsValue(field);
            }
        });
    }
    function showNotification(title, message, duration, type) {
        notification[type]({
            message: title,
            description: message,
            duration: duration,
        });
    }

    function okNotification() {
        showNotification(geti18nText('app.default.save.ok'), geti18nText('app.default.save.ok.desc'), 3, 'success');
    }

    function error(message) {
        showNotification(geti18nText('app.default.save.nok'), message, 3, 'error');
    } 

    function save(e, close) {
        e.preventDefault();
        props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                values = normalizeComponents(values);
                values.imagesKeys = imagesKeys;
                values['textOnImage'] = textOnImage;
                values['cardScrolling'] = cardScrolling;
                for (const key of imagesKeys) {
                    if (values['image-path_' + key]) {
                        values['image-path_' + key] = normalizeFileUpload('image-path_' + key);
                    }
                    values['webPage_' + key] =
                        values['webPage_' + key] != null && values['webPage_' + key].constructor === String
                            ? { id: parseInt(values['webPage_' + key]) }
                            : values['webPage_' + key];
                }

                for (const deleted of deletedImages) {
                    if (values['image-path_' + deleted]) {
                        delete values['image-path_' + deleted];
                    }
                }

                requestPut(CONSTANTS_REQ.WEBPAGECOMPONENT.UPDATEJSON + '/' + props.id, null, {
                    id: props.id,
                    json: JSON.stringify(values),
                }).then((result) => {
                    if (result && result.status === RESPONSE.CREATED) {
                        console.log('okNotification');
                        okNotification();
                        close && props.closePopup();
                    } else {
                        error();
                        if (result && result.status === RESPONSE.BAD_REQUEST) {
                            if (result.data && result.data.field) {
                                switch (result.data.field) {
                                    case 'mandatory_fields_required':
                                        console.log(geti18nText('app.default.mandatory_fields_required'));
                                        return;
                                    default:
                                        break;
                                }
                            }
                        }
                        if (result.data && result.data.error) {
                            console.log(JSON.stringify(result.data.error));
                        }
                    }
                });
            }
        });
    }

    function normalizeFileUpload(field) {
        let file = props.form.getFieldsValue([field])[field];
        if (file instanceof Array) {
            file = file[0];
        }
        return file.id ? file : { file_path: file.file_path, name: file.name };
    }

    const onEdit = (targetKey, action) => {
        if (action == 'add') {
            props.form.validateFieldsAndScroll((err, values) => {
                if (!err) {
                    let newId = lastKey;
                    newId++;

                    let arrVideos = images.slice();
                    let arrKeys = imagesKeys.slice();
                    arrVideos.push({ id: newId });
                    arrKeys.push(newId);
                    setImageKeys(arrKeys);
                    setImages(arrVideos);
                    setActiveTab('' + newId);
                    setLastKey(newId);
                }
            });
        }
        if (action == 'remove') {
            if (imagesKeys.length > 1) {
                setConfirmVisible(true);
                setImageToDelete(targetKey);
            }
        }
    };

    function onChangeImagePlacement(checked) {
        if (checked.target.checked === true) {
            setTextOnImage(true);
        } else if (checked.target.checked === false) {
            setTextOnImage(false);
        }
    }

    function onChangeCardScrolling(checked) {
        if (checked.target.checked === true) {
            setCardScrolling(true);
        } else if (checked.target.checked === false) {
            setCardScrolling(false);
        }
    }

    const deleteImage = () => {
        if (imageToDelete) {
            var newArrKeys = imagesKeys.filter((key) => key != parseInt(imageToDelete));
            var newArrVideos = images.filter((video) => video.id != parseInt(imageToDelete));
            setImages(newArrVideos);
            setImageKeys(newArrKeys);

            if (activeTab == imageToDelete) {
                setActiveTab('' + newArrKeys[0]);
            }
            setConfirmVisible(false);
            setImageToDelete(null);
            let deleted = deletedImages.slice();
            deleted.push(imageToDelete);
            setDeletedImages(deleted);
        }
    };

    const tabOnChange = (activeKey) => {
        props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                setActiveTab(activeKey);
            }
        });
    };

    if (fetching) {
        return <Spinner />;
    } else {
        return (
            <React.Fragment>
                <Divider>{geti18nText('component.cardimagetext')}</Divider>
                <Form>
                    <Row>
                        <Col span={24}>
                            <Popconfirm
                                placement="rightTop"
                                title={geti18nText('app.default.button.delete')}
                                visible={confirmVisible}
                                okText={geti18nText('app.default.button.yes')}
                                cancelText={geti18nText('app.default.button.no')}
                                onCancel={() => {
                                    setConfirmVisible(false);
                                }}
                                onConfirm={deleteImage}
                            >
                                <span></span>
                            </Popconfirm>
                        </Col>
                    </Row>
                    <Row span={24}>
                        <Col span={24} type={'flex'}>
                            <div style={{ alignItems: 'top', justifyContent: 'left', display: 'flex' }}>
                                <Form.Item label={geti18nText('component.cardimagetext.edit.label.cardwidth')}>
                                    {getFieldDecorator('cardWidth')(<InputNumber {...props} />)}
                                </Form.Item>
                                <Form.Item
                                    label={geti18nText('component.cardimagetext.edit.label.imgheight')}
                                    style={{ marginLeft: '2rem' }}
                                >
                                    {getFieldDecorator('cardImgHeight')(<InputNumber notRequred {...props} />)}
                                </Form.Item>
                                <Form.Item
                                    label={geti18nText('component.cardimagetext.edit.label.textfieldheight')}
                                    style={{ marginLeft: '2rem' }}
                                >
                                    {getFieldDecorator('textfieldheight')(
                                        <InputNumber notRequred disabled={textOnImage} {...props} />
                                    )}
                                </Form.Item>
                                <Checkbox
                                    checked={textOnImage}
                                    onChange={onChangeImagePlacement}
                                    style={{ marginLeft: '1.5rem', marginTop: '3rem' }}
                                >
                                    {geti18nText('component.cardimagetext.edit.label.textplacement')}
                                </Checkbox>

                                <Checkbox
                                    checked={cardScrolling}
                                    onChange={onChangeCardScrolling}
                                    style={{ marginLeft: '1.5rem', marginTop: '3rem' }}
                                >
                                    {geti18nText('component.cardimagetext.edit.label.cardscroll')}
                                </Checkbox>
                            </div>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '20px ' }} key={tabsKey}>
                        <Col span={24}>
                            <Tabs type="editable-card" onEdit={onEdit} onChange={tabOnChange} activeKey={activeTab}>
                                {images.map((video, index) => (
                                    <TabPane
                                        tab={geti18nText('component.cardimagetext.tab') + ' ' + (index + 1)}
                                        key={video.id}
                                        forceRender={true}
                                    >
                                        <EditTab
                                            index={video.id}
                                            tileLangJson={video.titleLangJson}
                                            textLangJson={video.textLangJson}
                                            {...props}
                                        />
                                    </TabPane>
                                ))}
                            </Tabs>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24} style={{ textAlign: 'right', marginTop: '1rem' }}>
                            <Button style={{ marginRight: '1em' }} onClick={(e) => props.closePopup()}>
                                {geti18nText('app.default.button.cancel')}
                            </Button>
                            <Button style={{ marginRight: '1em' }} type="primary" onClick={(e) => save(e, false)}>
                                {geti18nText('app.default.button.save')}
                            </Button>
                            <Button
                                type="primary"
                                style={{ background: '#31b531', borderColor: '#31b531', textColor: 'white' }}
                                onClick={(e) => {
                                    save(e, true);
                                }}
                            >
                                {geti18nText('app.default.button.save.close')}
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </React.Fragment>
        );
    }
});

const EditTab = (props) => {
    const { getFieldDecorator, setFieldsValue } = props.form;

    return (
        <>
            <Row gutter={24}>
                <Col span={24}>
                    <InputByLanguage
                        notRequred
                        fieldName={'title_' + props.index}
                        label={geti18nText('component.cardimagetext.edit.title')}
                        langJson={props.textLangJson}
                        {...props}
                    />
                    <InputByLanguage
                        notRequred
                        fieldName={'description_' + props.index}
                        label={geti18nText('component.cardimagetext.edit.description')}
                        langJson={props.tileLangJson}
                        type={'rteEditor'}
                        minHeight="80px"
                        {...props}
                    />

                    <InputByLanguage
                        notRequred
                        fieldName={'link_text_' + props.index}
                        label={geti18nText('component.cardimagetext.edit.link.text')}
                        langJson={props.textLangJson}
                        {...props}
                    />
                    <Divider orientation="left">{geti18nText('component.cardimagetext.edit.label.link')}</Divider>
                    <Form.Item label={geti18nText('component.cardimagetext.edit.link')}>
                        {getFieldDecorator('webPage_' + props.index)(
                            <SearchField
                                url={CONSTANTS_REQ.WEBPAGE.SEARCH}
                                map={{ id: 'id', label: 'name' }}
                                searchBy="name"
                                disabled={false}
                                translate={true}
                                addedData={{ projectId: Session.getProjectId() }}
                            />
                        )}
                    </Form.Item>
                    <Form.Item label={geti18nText('component.cardimagetext.edit.link.outside')}>
                        {getFieldDecorator('link_' + props.index)(<Input />)}
                    </Form.Item>
                    <Form.Item label={geti18nText('component.cardimagetext.edit.image')}>
                        {getFieldDecorator('image-path_' + props.index)(
                            <FileUpload
                                fileType={CONSTANTS_FILE_TYPE.IMAGE}
                                map={{ id: 'id', label: 'name' }}
                                searchBy="name"
                                disabled={false}
                                addedData={{ projectId: Session.getProjectId(), fileType: CONSTANTS_FILE_TYPE.IMAGE }}
                            />
                        )}
                    </Form.Item>
                </Col>
            </Row>
        </>
    );
};

export default CardImageTextEdit;
