import React, { useState, useEffect } from 'react';
import { Form, Row, Col, Button, Divider,notification } from 'antd';
import { Session, geti18nText } from '../../utils/Session';
import { normalizeComponents } from '../../utils/Utils';
import { normalizeJSONForComponent } from '../../utils/ComponentHelper';
import { requestGet, requestPut, RESPONSE } from '../../utils/RequestResolver';
import { CONSTANTS_REQ } from '../../utils/Constants';
import { InputByLanguage, translationsToObject, getTranslated } from '../InputByLanguage';
import { Spinner } from '../../components/Spinner';
import { SearchField } from '../../components/Search';

const FacilitiesEdit = Form.create({ name: 'FacilitiesEdit' })((props) => {
    const [fetching, setFetching] = useState(false);
    const [titleLangJson, setTitleLangJson] = useState(undefined);
    const [descriptionLangJson, setDescriptionLangJson] = useState(undefined);
    const [webPage, setWebPage] = useState(undefined);
    const { getFieldDecorator, setFieldsValue } = props.form;

    useEffect(() => {
        fetch();
    }, [props.visible]);

    function fetch() {
        setFetching(true);
        requestGet(CONSTANTS_REQ.WEBPAGECOMPONENT.EDIT + '/' + props.id).then((result) => {
            setFetching(false);
            if (result.status === RESPONSE.OK) {
                setValues(result.data);
            } else {
                console.log('response failed');
            }
        });
    }

    function setValues(component_data) {
        var json;
        setWebPage(component_data.webPage);
        if (component_data.json) {
            json = JSON.parse(component_data.json);
        }

        var populate_data = {};
        if (json) {
            populate_data = translationsToObject(json, 'title', JSON.stringify(json.title));
            populate_data = translationsToObject(json, 'description', JSON.stringify(json.description));
            setTitleLangJson(JSON.stringify(json.title));
            setDescriptionLangJson(JSON.stringify(json.description));
        }
        delete populate_data.title;
        delete populate_data.description;
        populate_data.id = component_data.id;

        setFieldsValue(populate_data);
    }

    function showNotification(title, message, duration, type) {
        notification[type]({
            message: title,
            description: message,
            duration: duration,
        });
    }

    function okNotification() {
        showNotification(geti18nText('app.default.save.ok'), geti18nText('app.default.save.ok.desc'), 3, 'success');
    }

    function error(message) {
        showNotification(geti18nText('app.default.save.nok'), message, 3, 'error');
    } 

    function save(e, close) {
        e.preventDefault();
        props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                values = normalize(values);
                requestPut(CONSTANTS_REQ.WEBPAGECOMPONENT.UPDATEJSONANDFACILITIES + '/' + props.id, null, {
                    id: props.id,
                    json: values.json,
                    facilities: values.facilities,
                    webPage: webPage,
                }).then((result) => {
                    if (result && result.status === RESPONSE.CREATED) {
                        console.log('okNotification');
                        okNotification();
                        close && props.closePopup();
                    } else {
                        error();
                        if (result && result.status === RESPONSE.BAD_REQUEST) {
                            if (result.data && result.data.field) {
                                switch (result.data.field) {
                                    case 'mandatory_fields_required':
                                        console.log(geti18nText('app.default.mandatory_fields_required'));
                                        return;
                                    default:
                                        break;
                                }
                            }
                        }
                        if (result.data && result.data.error) {
                            console.log(JSON.stringify(result.data.error));
                        }
                    }
                });
            }
        });
    }

    function normalize(values) {
        let facilities = [];
        var normalized = values;
        normalized = normalizeComponents(values);
        let jsonFieldGroups = ['title', 'description'];
        let jsonFieldKeys = {};
        normalized = normalizeJSONForComponent(normalized, undefined, jsonFieldGroups, jsonFieldKeys, props);
        //Facilities
        var prop;
        for (prop in values) {
            if (hasOwnProperty.call(values, prop)) {
                if (prop.startsWith('facility-') && values[prop] !== undefined) {
                    const selectedFacilities =
                        values[prop] != null
                            ? values[prop].constructor === String
                                ? [parseInt(values[prop])]
                                : values[prop] instanceof Array
                                ? values[prop].length > 0
                                    ? values[prop].map((fac) => {
                                          return fac.key
                                              ? parseInt(fac.key)
                                              : fac.id
                                              ? parseInt(fac.id)
                                              : parseInt(fac);
                                      })
                                    : null
                                : [values[prop].id]
                            : null;

                    if (selectedFacilities != null) {
                        facilities = facilities.concat(selectedFacilities);
                    }
                    delete normalized[prop];
                }
            }
        }
        if (facilities.length > 0) {
            normalized.facilities = facilities;
        } else {
            normalized.facilities = null;
        }
        normalized.webPageId = webPage.id;
        return normalized;
    }

    if (fetching) {
        return <Spinner />;
    } else {
        return (
            <React.Fragment>
                <Divider>{geti18nText('component.facilities')}</Divider>
                <Form>
                    <Form.Item>
                        <InputByLanguage
                            fieldName="title"
                            label={geti18nText('component.facilities.edit.title')}
                            langJson={titleLangJson}
                            {...props}
                        />
                    </Form.Item>
                    <Form.Item>
                        <InputByLanguage
                            fieldName="description"
                            label={geti18nText('component.facilities.edit.description')}
                            langJson={descriptionLangJson}
                            type="rteEditor"
                            {...props}
                        />
                    </Form.Item>
                    {webPage && (
                        <React.Fragment>
                            <MainFacilities form={props.form} pageId={webPage.id} />
                            <Divider>{geti18nText('component.facilities.edit.other.facilities')}</Divider>
                            <FacilityGroups form={props.form} pageId={webPage.id} />
                        </React.Fragment>
                    )}
                    <Row>
                        <Col span={24} style={{ textAlign: 'right', marginTop: '1rem' }}>
                            <Button style={{ marginRight: '1em' }} onClick={(e) => props.closePopup()}>
                                {geti18nText('app.default.button.cancel')}
                            </Button>
                            <Button style={{ marginRight: '1em' }} type="primary" onClick={(e) => save(e, false)}>
                                {geti18nText('app.default.button.save')}
                            </Button>
                            <Button
                                type="primary"
                                style={{ background: '#31b531', borderColor: '#31b531', textColor: 'white' }}
                                onClick={(e) => {
                                    save(e, true);
                                }}
                            >
                                {geti18nText('app.default.button.save.close')}
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </React.Fragment>
        );
    }
});
const MainFacilities = (props) => {
    const { getFieldDecorator, setFieldsValue } = props.form;

    useEffect(() => {
        if (props.pageId) {
            requestGet(CONSTANTS_REQ.FACILITY.MAINBYPAGE + '/' + props.pageId).then((result) => {
                if (result.status === RESPONSE.OK) {
                    if (
                        (result.data instanceof Object && result.data.id) ||
                        (result.data instanceof Array && result.data.length > 0)
                    ) {
                        setFieldsValue({
                            ['facility-main']: result.data.map((fac) => {
                                return { id: fac.id + '', text: fac.name };
                            }),
                        });
                    }
                }
            });
        }
    }, [props.pageId]);

    return (
        <Form.Item label={geti18nText('component.facilities.edit.main.facilities')} style={{ marginBottom: '0px' }}>
            {getFieldDecorator('facility-main')(
                <SearchField
                    mode="multiple"
                    url={CONSTANTS_REQ.FACILITY.SEARCH}
                    addedSearch={{ field: 'main', condition: 'equals_bool', value: 1 }}
                    map={{ id: 'id', label: 'name' }}
                    searchBy="name"
                    disabled={false}
                    translate={true}
                />
            )}
        </Form.Item>
    );
};

const FacilityGroups = (props) => {
    const { getFieldDecorator, setFieldsValue } = props.form;
    const [groups, setGroups] = useState([]);
    useEffect(() => {
        if (props.pageId) {
            requestGet(CONSTANTS_REQ.FACILITY.GROUPS + '?webPageId=' + props.pageId).then((result) => {
                if (result.status === RESPONSE.OK) {
                    setGroups(result.data);
                    loadData(result.data);
                } else {
                    setGroups([]);
                }
            });
        } else {
            setGroups([]);
        }
    }, [props.pageId]);

    function loadData(groups) {
        for (const group of groups) {
            requestGet(CONSTANTS_REQ.FACILITY.FORPAGEBYGROUP + '/' + props.pageId + '/' + group.id).then((result) => {
                if (result.status === RESPONSE.OK) {
                    setFieldsValue({
                        ['facility-' + group.id]: result.data.map((fac) => {
                            return { id: fac.id + '', text: fac.name };
                        }),
                    });
                }
            });
        }
    }

    if (groups.length == 0) {
        return <></>;
    } else {
        return groups.map((stype, index) => (
            <Row gutter={24} key={index}>
                <Col span={24}>
                    <Form.Item
                        label={getTranslated(stype.sct.name) + ' - ' + getTranslated(stype.name)}
                        style={{ marginBottom: '0px' }}
                    >
                        {getFieldDecorator('facility-' + stype.id)(
                            <SearchField
                                mode="multiple"
                                url={CONSTANTS_REQ.FACILITY.SEARCH}
                                addedSearch={{ field: 'parentId', condition: 'equals', value: stype.id }}
                                map={{ id: 'id', label: 'name' }}
                                searchBy="name"
                                disabled={false}
                                translate={true}
                            />
                        )}
                    </Form.Item>
                </Col>
            </Row>
        ));
    }
};

export default FacilitiesEdit;
