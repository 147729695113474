import { requestGet, requestPut, RESPONSE } from "./RequestResolver";

export const normalizeFileUploadPath = (field, props) => {
    let file = props.form.getFieldsValue([field])[field];
    if (file instanceof Array) {
        file = file[0];
    }
    return file.id ? file.id : file.key;
}

export const normalizeJSONForComponent = (values, translationKeys, jsonFieldGroups, jsonFieldKeys, props) => {
    if (jsonFieldGroups.length > 0) {
        var json = {};
        jsonFieldGroups.forEach(groupKey => {
            var jsonOneGroup = {};
            if (jsonFieldKeys[groupKey]) {
                for (var key in jsonFieldKeys) {
                    if (groupKey == key) {
                        jsonFieldKeys[key].forEach(element => {
                            if (values[groupKey + "-" + element] != null) {
                                if ((groupKey.startsWith("image") || groupKey.startsWith("video") ) && (["path", "thumbnail_path", "link"].includes(element))) {
                                    jsonOneGroup[element] = normalizeFileUploadPath(groupKey + "-" + element, props);
                                } else {
                                    jsonOneGroup[element] = values[groupKey + "-" + element];
                                }                                    
                                delete values[groupKey + "-" + element];
                                if (translationKeys && translationKeys[groupKey + "-" + element]) {
                                    delete translationKeys[groupKey + "-" + element];
                                }
                            }
                        });
                    }
                }
            } else {
                if (values[groupKey] != null) {
                    if (groupKey == "link") {
                        jsonOneGroup = normalizeFileUploadPath(groupKey, props);
                    } else {
                        jsonOneGroup = values[groupKey];
                    }
                    delete values[groupKey];
                }
            }
            json[groupKey] = jsonOneGroup;
        });
        
        if (json instanceof Object) {
            json = JSON.stringify(json);
        }            
        console.log(json);
        values.json = json;
    }        
    return values;
}

export async function getDiskFiles(url, imagePaths, props) {
    if (Object.keys(imagePaths).length == 0) {
        return;
    }
    var diskFiles = "";
    for (var key in imagePaths) {
        diskFiles += imagePaths[key] ? (diskFiles.length > 0 ? "," : "") + imagePaths[key] : "";
    }
    if (diskFiles && diskFiles.length > 0){        
        requestGet(url, { max:500, search: encodeURI(JSON.stringify([{ field: "id", condition: "IN_PARSE_LONG" , value: diskFiles.toString()}]))}).then(result => {
            if (result.status === RESPONSE.OK) {
                console.log("result.data");console.log(result.data);
                console.log(result.data instanceof Array);
                var content;
                if (result.data instanceof Array && result.data.length > 0) {
                    content = result.data;
                } else if (result.data.content instanceof Array && result.data.content.length > 0) {
                    content = result.data.content;
                }
                if (content instanceof Array && content.length > 0) {
                    var imageAndVideoData = {};
                    content.forEach(element => {
                        for (var key in imagePaths) {
                            if (imagePaths[key] && element.id.toString() == imagePaths[key].toString()) {
                                imageAndVideoData[key] = { id: element.id, name: (element.name ? element.name : (element.text ? element.text : null)) };
                                //break;
                            }
                        }
                    });
                    console.log(imageAndVideoData);
                    props.form.setFieldsValue(imageAndVideoData);
                }
            } else {
                console.log("response failed");
            }
        });
    }
}