import React, { useState, useEffect } from "react";
import { Icon, Form, Row, Col, Input, Radio,DatePicker,InputNumber } from "antd";
import { CONSTANTS_REQ,CONSTANTS_FILE_TYPE } from "../../../utils/Constants";
import { DataEdit } from "../../../components/DataEdit";
import { Session, geti18nText } from "../../../utils/Session";
import { requestGet, RESPONSE } from "../../../utils/RequestResolver";
import { setProjectLanguages } from "../../project/ProjectIndex";
import { SearchField, mapDataToSelect } from '../../../components/Search';
import { FileUpload } from '../../../components/fileUpload/FileUpload';
import moment from 'moment';
import { InputByLanguage, translationsToObject } from '../../../components/InputByLanguage';



const FaqCategoryEdit = Form.create({ name: "FaqCategoryEdit" })((props) => {
    const { getFieldDecorator, setFieldsValue } = props.form;
    const [editHeader, setEditHeader] = useState(geti18nText("faqCategory.edit.new"));
    const [loading, setLoading] = useState(false);
    const [contextTextLangJson, setContextTextLangJson] = useState(null);

    
    function setValues(values) {
        setEditHeader(values.id);
        values = translationsToObject(values, 'name', values.name);

        delete values.active;
        setFieldsValue(values);
    }


    function isCreate() {
        return props.value == undefined;
    }

    function onSave() {
        setProjectLanguages(Session.getProjectId());
        props.onSave();
    }

    function setDefaultValues() {
        
            setEditHeader(geti18nText("faqCategory.edit.new"));
         
    }

    return (
        <>
            <DataEdit
                editHeader={editHeader}
                loading={loading}
                setLoading={setLoading}
                url={CONSTANTS_REQ.CATEGORY.EDIT}
                setValues={setValues}
                setDefaultValues={setDefaultValues}
                width={1200}
                {...props}
                onSave={onSave}
                normalize={(values) => {
                    return { ...values, project: { id: Session.getProjectId() }, type: 0 };
                }}
            >
                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item hidden={true}>{getFieldDecorator("id")(<Input hidden={true} />)}</Form.Item>
                        <InputByLanguage
                            fieldName="name"
                            
                            label={geti18nText('faqCategory.table.column.name')}
                            {...props}
                        />
                        <Form.Item label={geti18nText('faqYl.order')}>
                        {getFieldDecorator('order', {
                                    rules: [{ required: true, message: geti18nText('app.default.required') }],
                                } )(
                            <InputNumber min={1} />
                        )}
                        
                        </Form.Item>
                        
                        
                    </Col>
                </Row>
            </DataEdit>
        </>
    );
});

export default FaqCategoryEdit;
