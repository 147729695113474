import React, { useState, useEffect } from 'react';
import { Form, Row, Col, Input, Button, Divider, Collapse, notification } from 'antd';
import { Session, geti18nText } from '../../utils/Session';
import { requestGet, requestPut, RESPONSE } from '../../utils/RequestResolver';
import { CONSTANTS_FILE_TYPE, CONSTANTS_REQ } from '../../utils/Constants';
import { FileUpload } from '../fileUpload/FileUpload';
import { InputByLanguage, translationsToObject } from '../InputByLanguage';
import { normalizeJSONForComponent } from '../../utils/ComponentHelper';
import { normalizeComponents } from '../../utils/Utils';

const { Panel } = Collapse;

const HeaderImageVideoEdit = Form.create({ name: 'HeaderImageVideoEdit' })((props) => {
    const [fetching, setFetching] = useState(false);
    const { getFieldDecorator, setFieldsValue } = props.form;
    const [savedData, setSavedData] = useState({});
    const jsonFieldGroups = ['image', 'video'];
    const jsonFieldKeys = { image: ['path', 'title', 'description'], video: ['path', 'start_text'] };
    const jsonDbFieldName = 'json';
    const [titleLangJson, setTitleLangJson] = useState(undefined);
    const [descriptionLangJson, setDescriptionLangJson] = useState(undefined);
    const [startTextLangJson, setStartTextLangJson] = useState(undefined);
    useEffect(() => {
        fetch();
    }, [props.visible]);

    function fetch() {
        setFetching(true);
        requestGet(CONSTANTS_REQ.WEBPAGECOMPONENT.EDIT + '/' + props.id).then((result) => {
            setFetching(false);
            if (result.status === RESPONSE.OK) {
                setValues(result.data);
            } else {
                console.log('response failed');
            }
        });
    }

    function setValues(component_data) {
        var addedData = component_data;
        var json;
        if (component_data.json) {
            json = JSON.parse(component_data.json);
            delete addedData.json;
        }
        delete addedData.active;
        setSavedData(addedData);
        var populate_data = {};
        var diskFiles = {};

        if (json) {
            //image
            populate_data = translationsToObject(json, 'image-title', JSON.stringify(json.image.title));
            populate_data = translationsToObject(json, 'image-description', JSON.stringify(json.image.description));
            diskFiles['image-file_path'] = json.image.path ? json.image.path : null;
            populate_data['image-file_path'] = json.image.file_path
                ? { key: json['image'].file_path, file_path: json['image'].file_path }
                : null;
            populate_data['video-file_path'] = json.video.file_path
                ? { key: json['video'].file_path, file_path: json['video'].file_path }
                : null;
            setTitleLangJson(JSON.stringify(json.image.title));
            setDescriptionLangJson(JSON.stringify(json.image.description));
            //delete populate_data.image;
            //video
            populate_data = translationsToObject(json, 'video-start_text', JSON.stringify(json.video.start_text));
            diskFiles['video-file_path'] = json.video.path ? json.video.path : null;
            setStartTextLangJson(JSON.stringify(json.video.start_text));

            //delete populate_data.video;
        }
        populate_data.id = component_data.id;

        setFieldsValue(populate_data);
    }

    function showNotification(title, message, duration, type) {
        notification[type]({
            message: title,
            description: message,
            duration: duration,
        });
    }

    function okNotification() {
        showNotification(geti18nText('app.default.save.ok'), geti18nText('app.default.save.ok.desc'), 3, 'success');
    }

    function error(message) {
        showNotification(geti18nText('app.default.save.nok'), message, 3, 'error');
    } 

    function save(e, close) {
        e.preventDefault();
        props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                values = normalize(values);
                requestPut(CONSTANTS_REQ.WEBPAGECOMPONENT.UPDATEJSON + '/' + props.id, null, {
                    id: props.id,
                    json: values.json,
                }).then((result) => {
                    if (result && result.status === RESPONSE.CREATED) {
                        console.log('okNotification');
                        okNotification();
                        close && props.closePopup();
                    } else {
                        error();
                        if (result && result.status === RESPONSE.BAD_REQUEST) {
                            if (result.data && result.data.field) {
                                switch (result.data.field) {
                                    case 'mandatory_fields_required':
                                        console.log(geti18nText('app.default.mandatory_fields_required'));
                                        return;
                                    default:
                                        break;
                                }
                            }
                        }
                        if (result.data && result.data.error) {
                            console.log(JSON.stringify(result.data.error));
                        }
                    }
                });
            }
        });
    }

    function normalize(values) {
        let jsonFieldGroups = ['image', 'video'];
        let jsonFieldKeys = { image: ['file_path', 'title', 'description'], video: ['file_path', 'start_text'] };
        values = normalizeComponents(values);
        return normalizeJSONForComponent(values, undefined, jsonFieldGroups, jsonFieldKeys, props);
    }

    return (
        <React.Fragment>
            <Divider>{geti18nText('component.headerimagevideo')}</Divider>
            <Form>
                <Row gutter={24}>
                    <Divider orientation="left">{geti18nText('component.headerimagevideo.edit.imageinfo')}</Divider>
                    <Col span={24}>
                        <Form.Item hidden={true}>{getFieldDecorator('id')(<Input hidden={true} />)}</Form.Item>
                        <InputByLanguage
                            fieldName="image-title"
                            label={geti18nText('component.headerimagevideo.edit.title')}
                            notRequred={true}
                            langJson={titleLangJson}
                            {...props}
                        />
                        <InputByLanguage
                            fieldName="image-description"
                            label={geti18nText('component.headerimagevideo.edit.description')}
                            notRequred={true}
                            langJson={descriptionLangJson}
                            {...props}
                        />
                        <Form.Item label={geti18nText('component.headerimagevideo.edit.image')}>
                            {getFieldDecorator('image-file_path', {
                                rules: [{ required: true, message: geti18nText('app.default.required') }],
                            })(
                                <FileUpload
                                    fileType={CONSTANTS_FILE_TYPE.IMAGE}
                                    map={{ id: 'id', label: 'name' }}
                                    searchBy="name"
                                    disabled={false}
                                    addedData={{
                                        projectId: Session.getProjectId(),
                                        fileType: CONSTANTS_FILE_TYPE.IMAGE,
                                    }}
                                />
                            )}
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Divider orientation="left">{geti18nText('component.headerimagevideo.edit.videoinfo')}</Divider>
                    <Col span={24}>
                        <InputByLanguage
                            fieldName="video-start_text"
                            label={geti18nText('component.headerimagevideo.edit.textforstartingvideo')}
                            notRequred={true}
                            langJson={startTextLangJson}
                            {...props}
                        />
                        <Form.Item label={geti18nText('component.headerimagevideo.edit.video')}>
                            {getFieldDecorator('video-file_path')(
                                <FileUpload
                                    fileType={CONSTANTS_FILE_TYPE.VIDEO}
                                    map={{ id: 'id', label: 'name' }}
                                    searchBy="name"
                                    disabled={false}
                                    addedData={{
                                        projectId: Session.getProjectId(),
                                        fileType: CONSTANTS_FILE_TYPE.VIDEO,
                                    }}
                                />
                            )}
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24} style={{ textAlign: 'right', marginTop: '1rem' }}>
                        <Button style={{ marginRight: '1em' }} onClick={(e) => props.closePopup()}>
                            {geti18nText('app.default.button.cancel')}
                        </Button>
                        <Button style={{ marginRight: '1em' }} type="primary" onClick={(e) => save(e, false)}>
                            {geti18nText('app.default.button.save')}
                        </Button>
                        <Button
                            type="primary"
                            style={{ background: '#31b531', borderColor: '#31b531', textColor: 'white' }}
                            onClick={(e) => {
                                save(e, true);
                            }}
                        >
                            {geti18nText('app.default.button.save.close')}
                        </Button>
                    </Col>
                </Row>
            </Form>
        </React.Fragment>
    );
});

export default HeaderImageVideoEdit;
