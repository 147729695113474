import React, { useState, useRef, useEffect } from 'react';
import { Form, Icon, Input, Button, Checkbox, Row, Col, Divider } from 'antd';
import { Redirect, withRouter } from 'react-router-dom';
import { LanguageSelector } from '../../components/Language';
import { getTranslated } from '../../components/InputByLanguage';
import { CONSTANTS_REQ } from '../../utils/Constants';
import { requestGet, RESPONSE } from '../../utils/RequestResolver';
import './login.css';
import 'animate.css';
import { Session, geti18nText, hasAnyRole } from '../../utils/Session';
import { setProjectLanguages } from '../../utils/Utils';
import { applicationLogo } from '../../components/adminLayout/AdminMainMenu';
import { useDispatch } from 'react-redux';
import { selectProject } from '../../slices/projectSlices';

function ShowError(props) {
    if (props.show) {
        return (
            <Row type="flex" className="margin-bottom">
                <Col span={24}>
                    <p style={{ color: 'red' }}>{geti18nText('login.error')}</p>
                </Col>
            </Row>
        );
    }
    return null;
}

export const LoginIndex = Form.create({ name: 'login' })(
    withRouter((props) => {
        const [validateStatus, setValidateStatus] = useState('');
        const usernameInput = useRef(null);
        const { getFieldDecorator } = props.form;
        const dispatch = useDispatch();
        const [logo, setLogo] = useState(undefined);

        useEffect(() => {
            if (usernameInput) {
                try {
                    usernameInput.current.focus();
                } catch {}
            }
            setLogo(applicationLogo(logo));
        }, []);

        function login(e) {
            e.preventDefault();
            setValidateStatus('validating');
            props.form.validateFields((err, values) => {
                if (!err) {
                    loginUser(values.username, values.password);
                } else {
                    setValidateStatus('error');
                    try {
                        usernameInput.current.focus();
                    } catch (error) {
                        this.setState({ error });
                    }
                }
            });
        }

        function loginUser(username, password) {
            Session.loginUser(username, password).then((isOk) => {
                if (isOk) {
                    setValidateStatus('success');
                } else {
                    setValidateStatus('error');
                    usernameInput.current.focus();
                }
            });
        }

        function setProjectForLocalCustomer() {
            if (Session.getUser().id) {
                requestGet(CONSTANTS_REQ.PROJECT.FORLOCALUSER + '/' + Session.getUser().id).then((result) => {
                    if (result.status === RESPONSE.OK) {
                        if (typeof result.data === 'object') {
                            Session.setProject(result.data);
                            Session.getProjectInfo().webPage.id = result.data.webPage.id;
                            dispatch(
                                selectProject({
                                    projectId: result.data.id,
                                    projectName: getTranslated(result.data.name),
                                    themeShortName:
                                        result.data.theme && result.data.theme.shortName
                                            ? result.data.theme.shortName
                                            : '',
                                })
                            );
                            setProjectLanguages(result.data.id);
                        }
                    }
                });
            }
        }

        if (Session.isUserAuthenticated()) {
            if (hasAnyRole(['ROLE_ADMIN', 'ROLE_PROJECT_ADMIN'])) {
                return <Redirect to="/" />;
            } else if (hasAnyRole(['ROLE_LOCAL_USER'])) {
                setProjectForLocalCustomer();
                return <Redirect to="/news" />;
            } else if (hasAnyRole(['ROLE_CONTENT_UPLOAD'])) {
                return <Redirect to="/" />;
            }
        } else {
            return (
                <Row type="flex" justify="space-around" align="middle" className="height-100">
                    <Col span={24} lg={{ span: 6 }} xxl={{ span: 4 }}>
                        <Form onSubmit={login} className="login-form animated fadeIn faster">
                            {logo && (
                                <img
                                    src={logo}
                                    alt=""
                                    height={230}
                                    style={{ marginLeft: 'auto', marginRight: 'auto', display: 'block' }}
                                />
                            )}
                            <Divider orientation="center">
                                {Session.getSetting('ux.login-title')
                                    ? Session.getSetting('ux.login-title')
                                    : geti18nText('app.title')}
                            </Divider>
                            <Form.Item hasFeedback validateStatus={validateStatus}>
                                {getFieldDecorator('username', {
                                    rules: [{ required: true, message: geti18nText('login.validation.username') }],
                                })(
                                    <Input
                                        disabled={validateStatus === 'validating'}
                                        ref={usernameInput}
                                        prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                        placeholder={geti18nText('login.username')}
                                    />
                                )}
                            </Form.Item>
                            <Form.Item hasFeedback validateStatus={validateStatus}>
                                {getFieldDecorator('password', {
                                    rules: [{ required: true, message: geti18nText('login.validation.password') }],
                                })(
                                    <Input
                                        disabled={validateStatus === 'validating'}
                                        prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                        type="password"
                                        placeholder={geti18nText('login.password')}
                                    />
                                )}
                            </Form.Item>
                            <LanguageSelector disabled={validateStatus === 'validating'} />
                            <Form.Item>
                                {getFieldDecorator('remember', {
                                    valuePropName: 'checked',
                                    initialValue: false,
                                })(
                                    <Checkbox disabled={validateStatus === 'validating'}>
                                        {geti18nText('login.rememberMe')}
                                    </Checkbox>
                                )}
                            </Form.Item>
                            <ShowError show={validateStatus === 'error'} />
                            <Form.Item>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    disabled={validateStatus === 'validating'}
                                    className="login-form-button"
                                >
                                    {geti18nText('login.signin')}
                                </Button>
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
            );
        }
    })
);
