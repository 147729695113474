import React, { useState, useEffect } from 'react';
import { Form, Row, Col, Input, Button, Divider, notification } from 'antd';
import { Session, geti18nText } from '../../utils/Session';
import { InputByLanguage, translationsToObject } from '../InputByLanguage';
import { requestGet, requestPut, RESPONSE } from '../../utils/RequestResolver';
import { CONSTANTS_REQ } from '../../utils/Constants';
import { normalizeJSONForComponent } from '../../utils/ComponentHelper';
import { normalizeComponents } from '../../utils/Utils';

const TextWithTitleAndSubtitleEdit = Form.create({ name: 'TextWithTitleAndSubtitleEdit' })((props) => {
    const [fetching, setFetching] = useState(false);
    const { getFieldDecorator, setFieldsValue } = props.form;
    const [savedData, setSavedData] = useState({});
    const [titleLangJson, setTitleLangJson] = useState(undefined);
    const [subtitleLangJson, setSubtitleLangJson] = useState(undefined);
    const [contentLangJson, setContentLangJson] = useState(undefined);

    useEffect(() => {
        fetch();
    }, [props.visible]);

    function fetch() {
        setFetching(true);
        requestGet(CONSTANTS_REQ.WEBPAGECOMPONENT.EDIT + '/' + props.id).then((result) => {
            setFetching(false);
            if (result.status === RESPONSE.OK) {
                setValues(result.data);
            } else {
            }
        });
    }

    function setValues(component_data) {
        var addedData = component_data;
        var json;
        if (component_data.json) {
            json = JSON.parse(component_data.json);
            delete addedData.json;
        }
        delete addedData.active;
        setSavedData(addedData);
        var populate_data = {};
        if (json) {
            populate_data = translationsToObject(json, 'title', JSON.stringify(json.title));
            populate_data = translationsToObject(json, 'subtitle', JSON.stringify(json.subtitle));
            populate_data = translationsToObject(json, 'content', JSON.stringify(json.content));
            setTitleLangJson(JSON.stringify(json.title));
            setSubtitleLangJson(JSON.stringify(json.subtitle));
            setContentLangJson(JSON.stringify(json.content));
            delete populate_data.title;
            delete populate_data.subtitle;
            delete populate_data.content;
        }
        populate_data.id = component_data.id;
        setFieldsValue(populate_data);
    }

    function showNotification(title, message, duration, type) {
        notification[type]({
            message: title,
            description: message,
            duration: duration,
        });
    }

    function okNotification() {
        showNotification(geti18nText('app.default.save.ok'), geti18nText('app.default.save.ok.desc'), 3, 'success');
    }

    function error(message) {
        showNotification(geti18nText('app.default.save.nok'), message, 3, 'error');
    } 


    function save(e, close) {
        e.preventDefault();
        props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                values = normalize(values);
                requestPut(CONSTANTS_REQ.WEBPAGECOMPONENT.UPDATEJSON + '/' + props.id, null, {
                    id: props.id,
                    json: values.json,
                }).then((result) => {
                    if (result && result.status === RESPONSE.CREATED) {
                        console.log('okNotification');
                        okNotification();
                        close && props.closePopup();
                    } else {
                        error();
                        if (result && result.status === RESPONSE.BAD_REQUEST) {
                            if (result.data && result.data.field) {
                                switch (result.data.field) {
                                    case 'mandatory_fields_required':
                                        console.log(geti18nText('app.default.mandatory_fields_required'));
                                        return;
                                    default:
                                        break;
                                }
                            }
                        }
                        if (result.data && result.data.error) {
                            console.log(JSON.stringify(result.data.error));
                        }
                    }
                });
            }
        });
    }

    function normalize(values) {
        let jsonFieldGroups = ['title', 'subtitle', 'content'];
        values = normalizeComponents(values);
        return normalizeJSONForComponent(values, undefined, jsonFieldGroups, {}, props);
    }

    return (
        <React.Fragment>
            <Divider>{geti18nText('component.textwithtitleandsubtitle')}</Divider>
            <Row gutter={24}>
                <Col span={24}>
                    <Form.Item hidden={true}>{getFieldDecorator('id')(<Input hidden={true} />)}</Form.Item>
                    <InputByLanguage
                        fieldName="title"
                        label={geti18nText('component.textwithtitleandsubtitle.edit.title')}
                        langJson={titleLangJson}
                        {...props}
                    />
                    <InputByLanguage
                        fieldName="subtitle"
                        label={geti18nText('component.textwithtitleandsubtitle.edit.subtitle')}
                        langJson={subtitleLangJson}
                        {...props}
                    />
                    <InputByLanguage
                        fieldName="content"
                        label={geti18nText('component.textwithtitleandsubtitle.edit.content')}
                        langJson={contentLangJson}
                        minHeight={'250px'}
                        {...props}
                        type="rteEditor"
                    />
                </Col>
            </Row>
            <Row>
                <Col span={24} style={{ textAlign: 'right', marginTop: '1rem' }}>
                    <Button style={{ marginRight: '1em' }} onClick={(e) => props.closePopup()}>
                        {geti18nText('app.default.button.cancel')}
                    </Button>
                    <Button style={{ marginRight: '1em' }} type="primary" onClick={(e) => save(e, false)}>
                        {geti18nText('app.default.button.save')}
                    </Button>
                    <Button
                        type="primary"
                        style={{ background: '#31b531', borderColor: '#31b531', textColor: 'white' }}
                        onClick={(e) => {
                            save(e, true);
                        }}
                    >
                        {geti18nText('app.default.button.save.close')}
                    </Button>
                </Col>
            </Row>
        </React.Fragment>
    );
});

export default TextWithTitleAndSubtitleEdit;
