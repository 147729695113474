import React, { useEffect, useState } from 'react';
import { Icon } from 'antd';
import { DataTable, getColumnSearch, getColumnSearchOption } from '../../../components/DataTable';
import { ENUMS, geti18nText, Session } from '../../../utils/Session';
import { Button, Collapse, Divider, Form, notification, Row, Table, Modal } from 'antd';
import { requestPost, RESPONSE } from '../../../utils/RequestResolver';
import { CONSTANTS_FILE_TYPE, CONSTANTS_REQ } from '../../../utils/Constants';
import DeseignEditor from '../../../components/deseign-editor';
import { useSelector } from 'react-redux';
import Column from 'antd/lib/table/Column';
import ComponentEdit from './ComponentEdit';

const ComponentList = (props) => {
    const { projectId, themeShortName } = useSelector((state) => state.projectSlices);
    function setDefaultFilterValue() {
        return [{ field: 'active', condition: 'equals_bool', value: 1 }];
    }

    useEffect(() => {}, []);

    const columns = [
        {
            title: geti18nText('component.id'),
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: geti18nText('design.settings.component.text_option.component_title'),
            dataIndex: 'text',
            key: 'text',
        },
        {
            title: geti18nText('design.settings.component.shortname'),
            dataIndex: 'name',
            key: 'name',
        },
    ];

    //vidljivost modala
    const [isModalVisible, setIsModalVisible] = useState(false);
    const showModal = () => {
        setIsModalVisible(true);
    };
    const hideModal = () => {
        setIsModalVisible(false);
    };
    // koji modal za komponentu se priazuje
    const [modalId, setModalId] = useState(undefined);
    function showNotification(title, message, duration, type) {
        notification[type]({
            message: title,
            description: message,
            duration: duration,
        });
    }
    const save = (e) => {
        requestPost(CONSTANTS_REQ.DESIGN_SETTINGS.PROJECT_SAVE, null, { projectId: props.projectId, cssJson: '' }).then(
            (resp) => {
                if (resp.status == RESPONSE.OK) {
                    showNotification(
                        geti18nText('app.default.save.ok'),
                        geti18nText('settings.save.success'),
                        3,
                        'success'
                    );
                } else {
                    showNotification(geti18nText('app.default.save.nok'), geti18nText('settings.save.not'), 0, 'error');
                }
            }
        );
    };

    return (
        <React.Fragment>
            <Row type="flex" className="margin-bottom">
                <Divider orientation="left" style={{ fontSize: '18px' }}>
                    {geti18nText('design.settings.component.general')}
                </Divider>
            </Row>
            <DataTable
                tableData={Session.getComponentsForTable()}
                compTypesEnum={Session.getComponentTypes()}
                columns={columns}
                size="small"
                rowKey="id"
                showRecordModal={true}
                modalComponent={ComponentEdit}
                /*  onRow={(record, rowIndex) => {
                    setModalId(record.name);
                    return {
                        onDoubleClick: (event) => {
                            console.log(event);
                            console.log('kliknuti id:' + record.name);
                            showModal();
                            console.log('modal id:' + modalId);
                        },
                    };
                }} */
                setDefaultFilterValue={setDefaultFilterValue}
                columns={[
                    {
                        title: geti18nText('component.id'),
                        dataIndex: 'id',
                        width: '10%',
                    },
                    {
                        title: geti18nText('design.settings.component.text_option.component_title'),
                        dataIndex: 'text',
                    },
                    {
                        title: geti18nText('design.settings.component.shortname'),
                        dataIndex: 'name',
                    },
                    /*  {
                        title: geti18nText('domain.table.column.active'),
                        dataIndex: 'active',
                        width: '10%',
                        render: (text, record) => {
                            if (record.active) {
                                return <Icon type="check" style={{ color: 'green' }} />;
                            } else {
                                return <Icon type="close" style={{ color: 'red' }} />;
                            }
                        },
                        ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', setDefaultFilterValue()[0]['value']),
                        defaultFilteredValue: setDefaultFilterValue(),
                    }, */
                ]}
            />
            {/*    <Modal
                zIndex={1100}
                visible={isModalVisible}
                onCancel={hideModal}
                width={1200}
                destroyOnClose={true}
                footer={[]}
            >
                <DeseignEditor closePopup={hideModal} projectId={modalId} className="margin-bottom"></DeseignEditor>
            </Modal> */}
        </React.Fragment>
    );
};
export default ComponentList;
