import { Button, Col, DatePicker, Divider, Form, Icon, Input, Row, Select, Radio,notification } from 'antd';
import moment from 'moment';
import React, { forwardRef, useEffect, useState } from 'react';
import { Spinner } from '../Spinner';
import { CONSTANTS_FILE_TYPE, CONSTANTS_REQ } from '../../utils/Constants';
import { requestGet, requestPut, RESPONSE } from '../../utils/RequestResolver';
import { geti18nText, Session } from '../../utils/Session';
import { normalizeComponents } from '../../utils/Utils';
import { FileUpload } from '../fileUpload/FileUpload';
import { InputByLanguage, translationsToObject } from '../InputByLanguage';
import { SearchField } from '../../components/Search';

const ClsmViewComponentEdit = Form.create({ name: 'ClsmViewComponentEdit' })((props) => {
    const { getFieldDecorator, getFieldValue, setFieldsValue } = props.form;
    const [fetching, setFetching] = useState(false);
    const [formValues, setFormValues] = useState(null);
    const [projects, setProjects] = useState(undefined);
    const [project, setProject] = useState(undefined);
    const [typeWeb, setTypeWeb] = useState('map');
    const [selPeriod, setSelPeriod] = useState(undefined);

    const period = [
        { id: 'last-24h', text: geti18nText('component.clsmviewcomponent.form.period.last.day') },
        { id: 'last-7day', text: geti18nText('component.clsmviewcomponent.form.period.last.7day') },
        { id: 'last-week', text: geti18nText('component.clsmviewcomponent.form.period.last.week') },
        { id: 'last-30day', text: geti18nText('component.clsmviewcomponent.form.period.last.30day') },
        { id: 'last-month', text: geti18nText('component.clsmviewcomponent.form.period.last.month') },
        { id: 'last-year', text: geti18nText('component.clsmviewcomponent.form.period.last.year') },
        { id: 'last-1year', text: geti18nText('component.clsmviewcomponent.form.period.last.1year') },
    ];

    useEffect(() => {
        fetchProjects();
        fetch();
    }, [props.visible]);

    useEffect(() => {
        if (formValues) {
            setFieldsValue(formValues);
        }
    }, [typeWeb]);

    useEffect(() => {
        props.form.resetFields();
        console.log(formValues);
        if (formValues && !formValues.type) {
            setFieldsValue({ type: 'map' });
            setTypeWeb('map');
        }
        setFieldsValue(formValues);
    }, [formValues]);

    function fetch() {
        setFetching(true);
        requestGet(CONSTANTS_REQ.WEBPAGECOMPONENT.EDIT + '/' + props.id).then((result) => {
            setFetching(false);
            if (result.status === RESPONSE.OK) {
                setValues(result.data);
            }
        });
    }
    function fetchProjects() {
        setFetching(true);
        requestGet(CONSTANTS_REQ.CLSM.PROJECTS).then((result) => {
            setFetching(false);
            if (result.status === RESPONSE.OK) {
                setProjects(result.data);
            }
        });
    }

    function showNotification(title, message, duration, type) {
        notification[type]({
            message: title,
            description: message,
            duration: duration,
        });
    }

    function okNotification() {
        showNotification(geti18nText('app.default.save.ok'), geti18nText('app.default.save.ok.desc'), 3, 'success');
    }

    function error(message) {
        showNotification(geti18nText('app.default.save.nok'), message, 3, 'error');
    } 

    function setValues(component_data) {
        var json;
        if (component_data.json) {
            let setJson = {};
            json = JSON.parse(component_data.json);
            console.log(json);
            if (json.project != null) setProject(json.project);
            if (json.type != null) setTypeWeb(json.type);
            if (json.period != null) setSelPeriod(json.period);
            setJson = translationsToObject(json, 'title', JSON.stringify(json.title));
            setJson = translationsToObject(json, 'chartTitlePower', JSON.stringify(json.chartTitlePower));
            setJson = translationsToObject(json, 'chartTitleEnergy', JSON.stringify(json.chartTitleEnergy));
            setJson = translationsToObject(json, 'chartTextPower', JSON.stringify(json.chartTextPower));
            setJson = translationsToObject(json, 'chartTextEnergy', JSON.stringify(json.chartTextEnergy));

            setJson = translationsToObject(json, 'textCurrentPower', JSON.stringify(json.textCurrentPower));
            setJson = translationsToObject(json, 'textExpectedPower', JSON.stringify(json.textExpectedPower));
            setJson = translationsToObject(json, 'textFactoryId', JSON.stringify(json.textFactoryId));
            setJson = translationsToObject(json, 'textPower', JSON.stringify(json.textPower));
            setJson = translationsToObject(json, 'textType', JSON.stringify(json.textType));
            setJson = translationsToObject(json, 'textInitialHoldS', JSON.stringify(json.textInitialHoldS));
            setJson = translationsToObject(json, 'textPhase', JSON.stringify(json.textPhase));
            setJson = translationsToObject(json, 'textSequencerSerial', JSON.stringify(json.textSequencerSerial));
            setJson = translationsToObject(json, 'textDevice', JSON.stringify(json.textDevice));
            setJson = translationsToObject(json, 'textSubstation', JSON.stringify(json.textSubstation));
            setJson = translationsToObject(json, 'textStatus', JSON.stringify(json.textStatus));
            setJson = translationsToObject(json, 'textStatus0', JSON.stringify(json.textStatus0));
            setJson = translationsToObject(json, 'textStatus1', JSON.stringify(json.textStatus1));
            setJson = translationsToObject(json, 'textStatus2', JSON.stringify(json.textStatus2));
            setJson = translationsToObject(json, 'textStatus3', JSON.stringify(json.textStatus3));
            setJson = translationsToObject(json, 'textStatus4', JSON.stringify(json.textStatus4));
            setJson = translationsToObject(json, 'textStatus5', JSON.stringify(json.textStatus5));
            setJson = translationsToObject(json, 'textBtnGrouped', JSON.stringify(json.textBtnGrouped));
            setJson = translationsToObject(json, 'textBtnNormal', JSON.stringify(json.textBtnNormal));
            setFormValues(setJson);
        }
    }

    function save(e, close) {
        e.preventDefault();
        props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                let normalizedValues = normalizeComponents(values);
                normalizedValues.project = project;
                normalizedValues.period = selPeriod;
                requestPut(CONSTANTS_REQ.WEBPAGECOMPONENT.UPDATEJSON + '/' + props.id, null, {
                    id: props.id,
                    json: JSON.stringify(normalizedValues),
                }).then((result) => {
                    if (result && result.status === RESPONSE.CREATED) {
                        console.log('okNotification');
                        close && props.closePopup();
                        okNotification();
                    } else {
                        error();
                        if (result && result.status === RESPONSE.BAD_REQUEST) {
                            if (result.data && result.data.field) {
                                switch (result.data.field) {
                                    case 'mandatory_fields_required':
                                        console.log(geti18nText('app.default.mandatory_fields_required'));
                                        return;
                                    default:
                                        break;
                                }
                            }
                        }
                        if (result.data && result.data.error) {
                            console.log(JSON.stringify(result.data.error));
                        }
                    }
                
                });
            }
        });
    }

    function onChangeType(e) {
        let tmpFormVal = formValues;
        tmpFormVal.type = e.target.value;
        setTypeWeb(e.target.value);
        setFieldsValue(formValues);
    }

    function onChangeProject(p) {
        setProject(p);
    }
    function onChangePeriod(p) {
        setSelPeriod(p);
    }

    if (fetching) {
        return <Spinner />;
    } else {
        return (
            <React.Fragment>
                <Divider>{geti18nText('component.clsmviewcomponent')}</Divider>
                <Form>
                    <Row gutter={24}>
                        <Col span={24}>
                            <InputByLanguage
                                fieldName="title"
                                notRequred={true}
                                label={geti18nText('component.clsmviewcomponent.form.title')}
                                //langJson={descriptionLangJson}
                                {...props}
                            />
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item label={geti18nText('component.clsmviewcomponent.form.project')}>
                                {getFieldDecorator('project', {
                                    rules: [{ required: true, message: geti18nText('app.default.required') }],
                                })(
                                    <SearchField
                                        onChange={onChangeProject}
                                        options={projects}
                                        map={{ id: 'id', label: 'name' }}
                                        searchBy="name"
                                    />
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item label={geti18nText('component.clsmviewcomponent.form.type.view')}>
                                {getFieldDecorator('type', {
                                    rules: [{ required: true, message: geti18nText('app.default.required') }],
                                })(
                                    <Radio.Group onChange={onChangeType}>
                                        <Radio value={'map'}>
                                            {geti18nText('component.clsmviewcomponent.form.type.map')}
                                        </Radio>
                                        <Radio value={'chart'}>
                                            {geti18nText('component.clsmviewcomponent.form.type.chart')}
                                        </Radio>
                                    </Radio.Group>
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                    {typeWeb === 'chart' && (
                        <>
                            <Row gutter={24}>
                                <Col span={12}>
                                    <Form.Item label={geti18nText('component.clsmviewcomponent.form.time.period')}>
                                        {getFieldDecorator('period')(
                                            <SearchField
                                                value={selPeriod}
                                                options={period}
                                                onChange={onChangePeriod}
                                                map={{ id: 'id', label: 'name' }}
                                                searchBy="name"
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Divider>{geti18nText('component.clsmviewcomponent.settings.title.text')}</Divider>
                            <Row gutter={24}>
                                <Col span={12}>
                                    <InputByLanguage
                                        fieldName="chartTitlePower"
                                        label={geti18nText('component.clsmviewcomponent.form.chart.title.power')}
                                        {...props}
                                    />
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={12}>
                                    <InputByLanguage
                                        fieldName="chartTextPower"
                                        label={geti18nText('component.clsmviewcomponent.form.chart.text.power')}
                                        {...props}
                                    />
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={12}>
                                    <InputByLanguage
                                        fieldName="chartTitleEnergy"
                                        label={geti18nText('component.clsmviewcomponent.form.chart.title.energy')}
                                        {...props}
                                    />
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={12}>
                                    <InputByLanguage
                                        fieldName="chartTextEnergy"
                                        label={geti18nText('component.clsmviewcomponent.form.chart.text.energy')}
                                        {...props}
                                    />
                                </Col>
                            </Row>
                        </>
                    )}
                    {typeWeb === 'map' && (
                        <>
                            <Divider>{geti18nText('component.clsmviewcomponent.settings.map.title')}</Divider>
                            <Row gutter={24}>
                                <Col span={12}>
                                    <InputByLanguage
                                        fieldName="textCurrentPower"
                                        label={geti18nText(
                                            'component.clsmviewcomponent.settings.map.title.current.power'
                                        )}
                                        {...props}
                                    />
                                </Col>
                                <Col span={12}>
                                    <InputByLanguage
                                        fieldName="textExpectedPower"
                                        label={geti18nText(
                                            'component.clsmviewcomponent.settings.map.title.expected.power'
                                        )}
                                        {...props}
                                    />
                                </Col>
                            </Row>

                            <Divider>{geti18nText('component.clsmviewcomponent.settings.map.details')}</Divider>
                            <Row gutter={24}>
                                <Col span={12}>
                                    <InputByLanguage
                                        fieldName="textFactoryId"
                                        label={geti18nText('component.clsmviewcomponent.lights.edit.factoryId')}
                                        {...props}
                                    />
                                </Col>
                                <Col span={12}>
                                    <InputByLanguage
                                        fieldName="textPower"
                                        label={geti18nText('component.clsmviewcomponent.lights.edit.power')}
                                        {...props}
                                    />
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={12}>
                                    <InputByLanguage
                                        fieldName="textType"
                                        label={geti18nText('component.clsmviewcomponent.lights.edit.type')}
                                        {...props}
                                    />
                                </Col>
                                <Col span={12}>
                                    <InputByLanguage
                                        fieldName="textInitialHoldS"
                                        label={geti18nText('component.clsmviewcomponent.lights.edit.initialHoldS')}
                                        {...props}
                                    />
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={12}>
                                    <InputByLanguage
                                        fieldName="textPhase"
                                        label={geti18nText('component.clsmviewcomponent.lights.edit.phase')}
                                        {...props}
                                    />
                                </Col>
                                <Col span={12}>
                                    <InputByLanguage
                                        fieldName="textSequencerSerial"
                                        label={geti18nText(
                                            'component.clsmviewcomponent.lights.table.column.sequencerSerial'
                                        )}
                                        {...props}
                                    />
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={12}>
                                    <InputByLanguage
                                        fieldName="textDevice"
                                        label={geti18nText('component.clsmviewcomponent.lights.edit.device')}
                                        {...props}
                                    />
                                </Col>
                                <Col span={12}>
                                    <InputByLanguage
                                        fieldName="textSubstation"
                                        label={geti18nText('component.clsmviewcomponent.lights.edit.substation')}
                                        {...props}
                                    />
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={12}>
                                    <InputByLanguage
                                        fieldName="textStatus"
                                        label={geti18nText('component.clsmviewcomponent.lights.edit.status')}
                                        {...props}
                                    />
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={12}>
                                    <InputByLanguage
                                        fieldName="textBtnGrouped"
                                        label={geti18nText('component.clsmviewcomponent.settings.map.btn.grouped')}
                                        {...props}
                                    />
                                </Col>
                                <Col span={12}>
                                    <InputByLanguage
                                        fieldName="textBtnNormal"
                                        label={geti18nText('component.clsmviewcomponent.settings.map.btn.normal')}
                                        {...props}
                                    />
                                </Col>
                            </Row>
                            <Divider>{geti18nText('component.clsmviewcomponent.settings.map.details.status')}</Divider>
                            <Row gutter={24}>
                                <Col span={12}>
                                    <InputByLanguage
                                        fieldName="textStatus0"
                                        label={geti18nText(
                                            'component.clsmviewcomponent.settings.map.text.light.status.0'
                                        )}
                                        {...props}
                                    />
                                </Col>
                                <Col span={12}>
                                    <InputByLanguage
                                        fieldName="textStatus1"
                                        label={geti18nText(
                                            'component.clsmviewcomponent.settings.map.text.light.status.1'
                                        )}
                                        {...props}
                                    />
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={12}>
                                    <InputByLanguage
                                        fieldName="textStatus2"
                                        label={geti18nText(
                                            'component.clsmviewcomponent.settings.map.text.light.status.2'
                                        )}
                                        {...props}
                                    />
                                </Col>
                                <Col span={12}>
                                    <InputByLanguage
                                        fieldName="textStatus3"
                                        label={geti18nText(
                                            'component.clsmviewcomponent.settings.map.text.light.status.3'
                                        )}
                                        {...props}
                                    />
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={12}>
                                    <InputByLanguage
                                        fieldName="textStatus4"
                                        label={geti18nText(
                                            'component.clsmviewcomponent.settings.map.text.light.status.4'
                                        )}
                                        {...props}
                                    />
                                </Col>
                                <Col span={12}>
                                    <InputByLanguage
                                        fieldName="textStatus5"
                                        label={geti18nText(
                                            'component.clsmviewcomponent.settings.map.text.light.status.5'
                                        )}
                                        {...props}
                                    />
                                </Col>
                            </Row>
                        </>
                    )}
                    <Row>
                        <Col span={24} style={{ textAlign: 'right', marginTop: '1rem' }}>
                            <Button style={{ marginRight: '1em' }} onClick={(e) => props.closePopup()}>
                                {geti18nText('app.default.button.cancel')}
                            </Button>
                            <Button style={{ marginRight: '1em' }} type="primary" onClick={(e) => save(e, false)}>
                                {geti18nText('app.default.button.save')}
                            </Button>
                            <Button
                                type="primary"
                                style={{ background: '#31b531', borderColor: '#31b531', textColor: 'white' }}
                                onClick={(e) => {
                                    save(e, true);
                                }}
                            >
                                {geti18nText('app.default.button.save.close')}
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </React.Fragment>
        );
    }
});

export default ClsmViewComponentEdit;
