import React, { useState, useEffect ,useRef} from "react";
import { Icon, Form, Row, Col, Input, Radio,DatePicker, Divider,List,Typography,Modal,Button,Popconfirm } from "antd";
import { CONSTANTS_REQ,CONSTANTS_FILE_TYPE } from "../../../utils/Constants";
import { DataEdit } from "../../../components/DataEdit";
import { Session, geti18nText } from "../../../utils/Session";
import { requestGet, RESPONSE } from "../../../utils/RequestResolver";
import { setProjectLanguages } from "../../project/ProjectIndex";
import { SearchField, mapDataToSelect } from '../../../components/Search';
import { FileUpload } from '../../../components/fileUpload/FileUpload';
import moment from 'moment';
import { InputByLanguage, getTranslated, translationsToObject } from '../../../components/InputByLanguage';
import AddNote from "./AddNote";




const PackageEdit = Form.create({ name: "PackageEdit" })((props) => {
    const { getFieldDecorator, setFieldsValue, getFieldsValue } = props.form;
    const [editHeader, setEditHeader] = useState(geti18nText("package.edit.new"));
    const [loading, setLoading] = useState(false);
    const [dataNotes, setDataNotes] = useState([]);
    const [visible, setVisible] = useState(false);
    const LINK_TYPE = {
        LINK: 'LINK',
        WEBPAGE: 'WEBPAGE',
    };
    const [linkType, setLinkType] = useState(props.type ? props.type : LINK_TYPE.WEBPAGE);
    const [noteEdit, setNoteEdit] = useState(false);
    const [selectedNote, setSelectedNote] = useState(undefined);


    function setValues(values) {
        setEditHeader(values.id);
        values = translationsToObject(values, 'name', values.name);
        values = translationsToObject(values, 'subtitle', values.subtitle);
        values = translationsToObject(values, 'description', values.description);
        values = translationsToObject(values, 'price', values.price);
        values = translationsToObject(values, 'installationFee', values.installationFee);
        values = translationsToObject(values, 'btnText', values.btnText);
        delete values.active;
        if(values.imagePath) {
            values['imagePath'] = {
                key: values.imagePath,
                file_path: values.imagePath,
            };
        }
        if(values.imagePath1) {
            values['imagePath1'] = {
                key: values.imagePath1,
                file_path: values.imagePath1,
            };
        }
        if(values.imagePath2) {
            values['imagePath2'] = {
                key: values.imagePath2,
                file_path: values.imagePath2,
            };
        }
        if(values.linkType) {
            setLinkType(values.linkType)
        }
        if(values.linkType) {
            if(values.linkType === 'WEBPAGE') {
                setWepPageValue('link',parseInt(values.link) )
            }

        }
        if(values.notes) {
            let list=JSON.parse(values.notes)
            setDataNotes(list);
        }
        setFieldsValue(values);
    }

    function setWepPageValue(key, id) {
        requestGet(CONSTANTS_REQ.WEBPAGE.EDIT + '/' + id).then((result) => {
            if (result.status === RESPONSE.OK) {
                let field = {};
                field[key] = { id: id, name: result.data.name };
                setFieldsValue(field);
            }
        });
    }


    function onSave() {
        setProjectLanguages(Session.getProjectId());
        props.onSave();
    }

    function setDefaultValues() {
        setDataNotes([])
        setEditHeader(geti18nText("package.edit.new"));
     
    }

    function closePopup() {
        setVisible(false);
        setSelectedNote(undefined)
        setNoteEdit(false);
    }

    const callSave=()=>{
        childRef.current.saveNote()
    }


    const saveNote=(values)=>{
                let list=dataNotes;
                if(!noteEdit) {
                    list=list.concat({note:values.note, id:Math.floor(Math.random() * (9999999999))})
                }
                else {
                    list=list.map((item) =>{
                        return item.id === selectedNote.id ? { note:values.note, id: item.id } : item
                 })
                }
                setDataNotes(list)
                setSelectedNote(undefined)
                setNoteEdit(false);
    }

    const onDeleteConfirm=(id)=>{
            let list=dataNotes
            list=list.filter((item) =>{
                    return item.id !== id })
            setDataNotes(list)
    }

    const onEditNoteItem = (item, type) => {
        setSelectedNote(item)
        setNoteEdit(true);
        setVisible(true)
    };


    const childRef = useRef();


    return (
        <>
            <DataEdit
                editHeader={editHeader}
                loading={loading}
                setLoading={setLoading}
                url={CONSTANTS_REQ.PACKAGE.EDIT}
                setValues={setValues}
                setDefaultValues={setDefaultValues}
                width={1200}
                {...props}
                onSave={onSave}
                normalize={(values) => {
                    if(values.link) {
                        if (values.link.id) {
                            values.link=values.link.id
                        }
                    }
                    if(dataNotes.length>0) {
                        values.notes=JSON.stringify(dataNotes);
                    }
                    return { ...values, project: { id: Session.getProjectId() } };
                }}
            >
                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item hidden={true}>{getFieldDecorator("id")(<Input hidden={true} />)}</Form.Item>
                        <InputByLanguage
                            fieldName="name"
                            
                            label={geti18nText('blog.edit.name')}
                            {...props}
                        />
                        <InputByLanguage
                            fieldName="subtitle"
                            
                            label={geti18nText('package.edit.subtitle')}
                            {...props}
                        />
                        <InputByLanguage
                            fieldName="description"
                            
                            label={geti18nText('blog.edit.description')}
                            {...props}
                        />
                        <InputByLanguage
                            fieldName="price"
                            label={geti18nText('package.edit.price')}
                            {...props}
                        />
                        <InputByLanguage
                            notRequred
                            fieldName="installationFee"
                            label={geti18nText('package.edit.installation.fee')}
                            {...props}
                        />
                        <InputByLanguage
                            notRequred
                            fieldName="btnText"
                            label={geti18nText('package.edit.notes.btn.text')}
                            {...props}
                        />
                    </Col>
                    <Divider>{geti18nText('package.edit.notes')}</Divider>
                    <Button style={{marginBottom:20}} type="primary" onClick={(e) => setVisible(true)}>
                                {geti18nText('package.table.add')}
                    </Button>
                    <List
                        bordered
                        dataSource={dataNotes}
                        renderItem={item => {
                            return         <List.Item
                                        actions={[
                                            <Button
                                                
                                                style={{ marginRight: '15px' }}
                                                key="ok"
                                                type="default"
                                                onClick={(e) => onEditNoteItem(item)}
                                            >
                                                {geti18nText('component.ylFooter.list.item.edit.btn')}
                                            </Button>,
                                            <Popconfirm placement="top" title={geti18nText('component.calendar.delete.confirm')} onConfirm={()=>onDeleteConfirm(item.id)} okText={geti18nText('app.default.button.yes')} cancelText={geti18nText('app.default.button.no')}>
                                                <Button
                                                    
                                                    style={{ marginRight: '15px' }}
                                                    key="ok"
                                                    type="danger"
                                                    
                                                >
                                                    {geti18nText('component.ylFooter.list.item.delete.btn')}
                                                </Button>
                                          </Popconfirm>
                                        ]}
                                    >
                                        <div  style={{width:'80%'}}>{getTranslated(item.note)}</div>
                                    </List.Item>
                        }}
                    />
                    
                    <Col span={24}>
                        <Form.Item label={geti18nText('blog.edit.image')}>
                            {getFieldDecorator('imagePath', )(
                                <FileUpload
                                    fileType={CONSTANTS_FILE_TYPE.IMAGE}
                                    map={{ id: 'id', label: 'name' }}
                                    searchBy="name"
                                    disabled={false}
                                    addedData={{ projectId: Session.getProjectId() }}
                                />
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item label={geti18nText('shop.edit.image1')}>
                            {getFieldDecorator('imagePath1', )(
                                <FileUpload
                                    fileType={CONSTANTS_FILE_TYPE.IMAGE}
                                    map={{ id: 'id', label: 'name' }}
                                    searchBy="name"
                                    disabled={false}
                                    addedData={{ projectId: Session.getProjectId() }}
                                />
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item label={geti18nText('shop.edit.image2')}>
                            {getFieldDecorator('imagePath2', )(
                                <FileUpload
                                    fileType={CONSTANTS_FILE_TYPE.IMAGE}
                                    map={{ id: 'id', label: 'name' }}
                                    searchBy="name"
                                    disabled={false}
                                    addedData={{ projectId: Session.getProjectId() }}
                                />
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                    <Form.Item>
                        {getFieldDecorator('linkType', { initialValue: LINK_TYPE.WEBPAGE })(
                            <Radio.Group
                                onChange={(e) => {
                                    setLinkType(e.target.value);
                                }}
                            >
                                <Radio value={LINK_TYPE.WEBPAGE}>
                                    {geti18nText('blog.edit.radio_button.webPage')}
                                </Radio>
                                <Radio value={LINK_TYPE.LINK}>
                                    {geti18nText('blog.edit.external_link')}
                                </Radio>
                            </Radio.Group>
                        )}
                    </Form.Item>
                    </Col>
                    <Col span={24}>
                    {linkType == LINK_TYPE.LINK ? (
                                <Row gutter={24} type="flex" justify="space-between" align="bottom">
                                    <Col span={16}>
                                        <Form.Item label={geti18nText('blog.edit.text_link')}>
                                            {getFieldDecorator('link')(
                                                <Input defaultValue={props.externalLink} />
                                            )}
                                        </Form.Item>
                                    </Col>
                                </Row>
                            ) : (
                                <Form.Item label={geti18nText('blog.edit.radio_button.webPage')}>
                                    {getFieldDecorator('link')(
                                        <SearchField
                                            url={CONSTANTS_REQ.WEBPAGE.SEARCH}
                                            map={{ id: 'id', label: 'name' }}
                                            searchBy="name"
                                            disabled={false}
                                            translate={true}
                                            addedData={{ projectId: Session.getProjectId(), forMap: true }}
                                        />
                                    )}
                                </Form.Item>
                    )}
                    </Col>
                   
                </Row>
                
                <Modal
                visible={visible}
                onCancel={closePopup}
                width={500}
                destroyOnClose
                onOk={()=>callSave()}
                
                okText={geti18nText('app.default.button.save')}
                title={geti18nText('package.edit.notes.item.new')}
                >
                    <AddNote form={props.form} ref={childRef} onClose={closePopup} edit={noteEdit} selectedItem={selectedNote} onSave={saveNote} visible={visible} setVisible={setVisible} >              
                    </AddNote>  
                 </Modal> 
                
            </DataEdit>
            
        </>
    );
});

export default PackageEdit;
