import React, { useState, useEffect } from "react";
import { Form, Input, Button, Icon } from "antd";
import { Session, geti18nText } from "../utils/Session";
import TextArea from "antd/lib/input/TextArea";
import RteEditor from "../components/RteEditor";

const ButtonGroup = Button.Group;

export const translationsToObject = (obj, field, translations) => {
    if (translations != undefined && translations.length != 0) {
        let trans;
        if (typeof translations == "string") {
            trans = JSON.parse(translations);
        }
        else {
            trans = translations;
        }
        for (var key in trans) {
            let newKey = "translation-" + key + "-" + field;
            obj[newKey] = trans[key];
        }
    }

    return obj;
};

export const getTranslated = (translations, language = Session.getProjectDefaultLanguage()) => {
    if (translations == undefined || translations.length == 0) {
        return "";
    }

    if (typeof translations == "string") {
        translations = JSON.parse(translations);
    }

    if (translations[language]) {
        return translations[language];
    } else {
        return "";
    }
};

export const InputByLanguage = props => {
    const { getFieldDecorator, setFieldsValue } = props.form;
    const { type } = props;
    const [seletedLang, setSeletedLang] = useState(Session.getProjectDefaultLanguage());
    const [langHasValue, setLangHasValue] = useState(new Map());
 
    useEffect(() => {
        setLangHasValue(fillLanguageHasValueMap());
    }, [props.langJson]);

    function fillLanguageHasValueMap() {
        let myMap = new Map();
        if (Session.getProjectInfo().languages != null) {
            let translations = {};
            if (props.langJson) {
                if(typeof props.langJson === 'object') {
                    translations = props.langJson;
                }
                else if (props.langJson.length > 0) {
                    translations = JSON.parse(props.langJson);
                }
            }

            Session.getProjectInfo().languages.forEach((lang) => {
                myMap.set(Object.keys(lang)[0], translations[Object.keys(lang)[0]] ? true: false);
            });
        }
        return myMap;
    }

    const checkIfLangHasValue = (lang) => {
        let hasValue = langHasValue;
        if (hasValue.has(lang)) {
            return hasValue.get(lang);
        }
        else {
            hasValue = fillLanguageHasValueMap();
            setLangHasValue(hasValue);
            return hasValue.get(lang);
        }
    }

    function changeLang(showLang) {
        if (seletedLang == Session.getProjectDefaultLanguage()) {
            if (!props.form.getFieldValue("translation-" + seletedLang + "-" + props.fieldName)) {
                props.form.validateFields(["translation-" + seletedLang + "-" + props.fieldName]);
                return;
            }
        }
        let hasValue = langHasValue;
        const value = props.form.getFieldValue("translation-" + seletedLang + "-" + props.fieldName);
        hasValue.set(seletedLang, (value != undefined && value.trim().length > 0));
        setLangHasValue(hasValue);

        setSeletedLang(showLang);
    }

    return (
        <React.Fragment>
            { props.label }&nbsp;&nbsp;&nbsp;&nbsp;
            {Session.getProjectInfo().languages != null && 
                Session.getProjectInfo().languages.map((value, index) => {
                    const lang = Object.keys(value)[0];
                    let color = "";
                    if (lang == seletedLang) color = "#1890ff";
                    else if (!checkIfLangHasValue(lang)) color = "red";
                    return (
                        <Button key={lang}
                            onClick={() => {changeLang(lang);}}
                            style={{ 
                                color: color, 
                                borderColor: color,
                                marginRight: "3px" }}
                        >
                            {lang}
                            {color == "red" &&
                                <Icon type="exclamation" />
                            }
                        </Button>
                    );
                })
            }
            {Session.getProjectInfo().languages != null &&
                Session.getProjectInfo().languages.map((value, index) => {
                    const lang = Object.keys(value)[0];
                    const langName = value[lang];
                    return (
                        <Form.Item key={index} style={{ marginBottom: "20px", marginTop: "10px" }} hidden={lang != seletedLang}>
                            {type == "textArea"
                                ? getFieldDecorator("translation-" + lang + "-" + props.fieldName, {
                                      rules: [{ required: index == 0 && !props.notRequred, message: geti18nText("app.default.required") }]
                                  })(<TextArea style={(props.minHeight ? {minHeight : props.minHeight} : "")} />)
                                : ( type == "rteEditor"
                                    ? getFieldDecorator("translation-" + lang + "-" + props.fieldName, {
                                        rules: [{ required: index == 0 && !props.notRequred, message: geti18nText("app.default.required") }]
                                        })(<RteEditor id={"translation-" + lang + "-" + props.fieldName} {...props} />)
                                    
                                    : getFieldDecorator("translation-" + lang + "-" + props.fieldName, {
                                        rules: [{ required: index == 0 && !props.notRequred, message: geti18nText("app.default.required") }]
                                        })(<Input />))}
                        </Form.Item>
                    );
                })
            }
        </React.Fragment>
    );
};
