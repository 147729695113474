import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import { Form, Row, Col, Button, Divider, notification, Result, Modal, Popconfirm, Icon } from 'antd';
import { Spinner } from './Spinner';
import { requestPost, requestGet, requestPut, RESPONSE } from '../utils/RequestResolver';
import moment from 'moment';
import { geti18nText } from '../utils/Session';

export const DataEdit = withRouter((props) => {
    const [fetching, setFetching] = useState(false);
    const [recordError, setRecordError] = useState(false);
    const [record, setRecord] = useState(undefined);
    const [newRecordID, setNewRecordID] = useState(undefined);
    var ToggleActive = null;

    useEffect(() => {
        if (!isCreate()) {
            if (props.setDefaultValues) {
                props.setDefaultValues();
            }
            fetch();
        } else {
            if (props.setDefaultValues) {
                props.setDefaultValues();
            }
        }
    }, []);

    useEffect(() => {
        if (!isCreate()) {
            if (props.setDefaultValues) {
                props.setDefaultValues();
            }
            fetch();
        } else {
            if (props.setDefaultValues) {
                props.setDefaultValues();
            }
        }
    }, [props.match.params.id]);

    useEffect(() => {
        if (props.isModal && props.visible && !isCreate()) {
            fetch();
        } else if (props.setDefaultValues) {
            props.setDefaultValues();
        }
    }, [props.visible]);

    function closePopup(isSave = false) {
        setNewRecordID(undefined);
        props.setVisible(false);
        if (isSave === true && props.onSave) {
            props.onSave();
        }
        props.form.resetFields();
    }

    function makeRequest(values) {
        if (isCreate()) {
            return requestPost(getUrl(), undefined, normalize(values));
        } else {
            return requestPut(getUrl(), undefined, normalize(values));
        }
    }

    function getUrl() {
        if (!props.isModal) {
            return isCreate() ? props.url : props.url + '/' + (newRecordID ? newRecordID : props.match.params.id);
        } else {
            return isCreate() ? props.url : props.url + '/' + (newRecordID ? newRecordID : props.value.id);
        }
    }

    function fetch() {
        setFetching(true);
        setRecordError(false);
        requestGet(getUrl()).then((result) => {
            setFetching(false);
            if (result.status === RESPONSE.OK) {
                setNewRecordID(undefined);
                let resultObject = result.data;
                if (resultObject.id) {
                    setRecord({ ...resultObject });
                    if (!props.isModal || props.visible) {
                        props.setValues(resultObject);
                    }
                } else {
                    setRecordError(true);
                }
            } else {
                setRecordError(true);
            }
        });
    }

    function isCreate() {
        if (newRecordID) {
            return false;
        }
        if (!props.isModal) {
            return props.match.params.id === 'create';
        } else {
            if (props.value) {
                return false;
            } else {
                return true;
            }
        }
    }

    function normalize(values) {
        let normalizedValues = { ...props.addedData };
        let translationKeys = {};
        for (var key in values) {
            if (values.hasOwnProperty(key)) {
                if (values[key] instanceof Array) {
                    if (values[key].length === 1) {
                        normalizedValues[key] = values[key][0].hasOwnProperty('key')
                            ? values[key][0].key
                            : values[key][0].id;
                    } else {
                        normalizedValues[key] = values[key].map((element) => {
                            return '' + (element.hasOwnProperty('key') ? element.key : element.id);
                        });
                    }
                } else if (values[key] instanceof moment) {
                    if (props.momentsFormat && props.momentsFormat[key]) {
                        normalizedValues[key] = values[key].format(props.momentsFormat[key]);
                    } else {
                        normalizedValues[key] = values[key];
                    }
                } else if (values[key] instanceof Object && values[key]['key']) {
                    normalizedValues[key] = values[key]['key'];
                } else {
                    // translations - to json
                    if (key.startsWith('translation-')) {
                        const fieldKey = key.substr(15);
                        const fieldLanguage = key.substr(12, 2);
                        if (normalizedValues[fieldKey] == undefined) {
                            normalizedValues[fieldKey] = {};
                        }
                        normalizedValues[fieldKey][fieldLanguage] = values[key];
                        if (!translationKeys[fieldKey]) {
                            translationKeys[fieldKey] = fieldKey;
                        }
                    } else {
                        if (values[key] === undefined) {
                            normalizedValues[key] = null;
                        } else {
                            normalizedValues[key] = values[key];
                        }
                    }
                }
            }
        }
        // json object to string
        for (var key in translationKeys) {
            if (normalizedValues[key] instanceof Object) {
                normalizedValues[key] = JSON.stringify(normalizedValues[key]);
            }
        }
        if (props.normalize) {
            normalizedValues = props.normalize(normalizedValues);
        }
        return normalizedValues;
    }

    function save(e) {
        e.preventDefault();

        if (props.onBeforeSave) {
            props.onBeforeSave();
        }
        props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                if (ToggleActive != null) {
                    values['active'] = ToggleActive;
                }
                if (newRecordID && 'id' in values && typeof values.id === 'undefined') {
                    values['id'] = newRecordID;
                }
                props.setLoading(true);
                // console.log('make request vrijedosti' + JSON.stringify(values));
                makeRequest(values).then((result) => {
                    props.setLoading(false);
                    //   console.log('result vrijedosti' + JSON.stringify(result));
                    //     console.log('data vrijednost ' + JSON.stringify(result.data));
                    if (result && result.status === RESPONSE.CREATED) {
                        props.setLoading(false);
                        okNotification();
                        if (props.onSave) {
                            props.onSave();
                        }
                        if (result.data && result.data.id) {
                            //setNewRecordID(result.data.id);
                            setRecord({ ...result.data });
                        }
                        if (props.onSaveAndGetID && result.data && result.data.id) {
                            props.onSaveAndGetID(result.data.id);
                        }
                        if (props.onSaveAndGetData && result.data) {
                            props.onSaveAndGetData(result.data);
                        }
                    } else {
                        if (result && result.status === RESPONSE.BAD_REQUEST) {
                            if (result.data && result.data.field) {
                                switch (result.data.field) {
                                    case 'username_exist':
                                        error(geti18nText('app.default.username_exist'));
                                        return;
                                    case 'mandatory_fields_required':
                                        error(geti18nText('app.default.mandatory_fields_required'));
                                        return;
                                    default:
                                        break;
                                }
                            }
                        }
                        if (result.data && result.data.error) {
                            error(JSON.stringify(result.data.error));
                        }
                    }
                    toggleActive(null);
                    if (props.isModal) {
                        if (props.shouldCloseModal) {
                            if (props.shouldCloseModal()) {
                                closePopup(true);
                            } else {
                                return;
                            }
                        } else if (result && result.status === RESPONSE.CREATED) {
                            closePopup(true);
                        } else {
                            error();
                        }
                    }
                });
            } else {
                for (const [key, value] of Object.entries(err)) {
                    var formItem = props.form.getFieldProps(key)['data-__meta'];
                    if ('errorTitle' in formItem) {
                        value.errors.forEach((errorData) => {
                            notification.error({
                                key: key,
                                message: formItem.errorTitle,
                                description: errorData.message,
                            });
                        });
                    }
                }
                props.setLoading(false);
            }
        });
    }

    function toggleActive(value) {
        ToggleActive = value;
    }

    function showNotification(title, message, duration, type) {
        notification[type]({
            message: title,
            description: message,
            duration: duration,
        });
    }

    function okNotification() {
        showNotification(geti18nText('app.default.save.ok'), geti18nText('app.default.save.ok.desc'), 3, 'success');
    }

    function error(message) {
        showNotification(geti18nText('app.default.save.nok'), message, 3, 'error');
    }

    function hideActivate() {
        return (
            isCreate() ||
            record === undefined ||
            record.active === true ||
            (props.hideActivationButtons !== undefined && props.hideActivationButtons === true)
        );
    }

    function hideDeactivate() {
        return (
            isCreate() ||
            record === undefined ||
            record.active === false ||
            (props.hideActivationButtons !== undefined && props.hideActivationButtons === true)
        );
    }

    function hideSubmit() {
        return props.hideSubmitButton;
    }

    function getCustomButtons() {
        const customButtons = [];
        if (props.customButtons) {
            props.customButtons.map((custom, index) => {
                if (custom.addPopconfirm == true) {
                    customButtons.push(
                        <Popconfirm
                            key={'confirm' + index}
                            placement="top"
                            title={custom.popConfirmMsg ? custom.popConfirmMsg : custom.text}
                            onConfirm={(e) => {
                                custom.onClick(e);
                            }}
                            okText={geti18nText('app.default.button.yes')}
                            cancelText={geti18nText('app.default.button.no')}
                        >
                            <Button
                                key={'custom' + index}
                                className={custom.text === undefined ? 'hidden' : ''}
                                loading={custom.loading}
                                style={custom.style}
                            >
                                {custom.icon && <Icon type={custom.icon} />}
                                {custom.text}
                            </Button>
                        </Popconfirm>
                    );
                } else {
                    customButtons.push(
                        <Button
                            key={'custom' + index}
                            className={custom.text === undefined ? 'hidden' : ''}
                            loading={custom.loading}
                            style={custom.style}
                            onClick={custom.onClick}
                        >
                            {custom.icon && <Icon type={custom.icon} />}
                            {custom.text}
                        </Button>
                    );
                }
            });
        }
        return customButtons;
    }

    function getButtons() {
        return [
            <Button key="back" onClick={(e) => closePopup()}>
                {geti18nText('app.default.button.cancel')}
            </Button>,
            ...getCustomButtons(),
            <Button
                key="activate"
                style={{ backgroundColor: 'green', color: 'white' }}
                className={hideActivate() ? 'hidden' : ''}
                onClick={(e) => {
                    toggleActive(true);
                    save(e);
                }}
                disabled={props.loading}
            >
                {' '}
                {geti18nText('app.default.button.activate')}
            </Button>,
            <Popconfirm
                key="confirm"
                placement="top"
                title={geti18nText('app.default.confirm.deactivate')}
                onConfirm={(e) => {
                    toggleActive(false);
                    save(e);
                }}
                okText={geti18nText('app.default.button.yes')}
                cancelText={geti18nText('app.default.button.no')}
            >
                <Button
                    key="deactivate"
                    type="danger"
                    className={hideDeactivate() ? 'hidden' : ''}
                    disabled={props.loading}
                >
                    {' '}
                    {geti18nText('app.default.button.deactivate')}
                </Button>
            </Popconfirm>,
            <Button
                key="submit"
                type="primary"
                loading={props.loading}
                className={hideSubmit() ? 'hidden' : ''}
                onClick={(e) => save(e)}
            >
                {geti18nText('app.default.button.save')}
            </Button>,
        ];
    }

    if (props.isModal) {
        return (
            <>
                <Modal
                    visible={props.visible}
                    title={props.editHeader}
                    width={props.width}
                    okText={geti18nText('app.default.button.save')}
                    onCancel={closePopup}
                    onOk={save}
                    footer={props.hideButtons ? null : getButtons()}
                >
                    <Form>{fetching ? <Spinner /> : props.children}</Form>
                </Modal>
            </>
        );
    } else {
        if (fetching) {
            return <Spinner />;
        } else {
            if (recordError) {
                return (
                    <Result
                        status="warning"
                        title={geti18nText('app.default.record.error')}
                        extra={
                            <Button
                                type="primary"
                                key="console"
                                onClick={() => {
                                    props.history.goBack();
                                }}
                            >
                                {geti18nText('table.pagination.previous')}
                            </Button>
                        }
                    />
                );
            } else {
                return (
                    <>
                        <Divider orientation="left" style={{ fontSize: '18px' }}>
                            {props.editHeader}
                        </Divider>
                        <Form onSubmit={save} layout={props.layout}>
                            {props.children}
                            <Row>
                                <Col
                                    span={24}
                                    style={{ textAlign: props.buttonsPosition ? props.buttonsPosition : 'right' }}
                                >
                                    <Button
                                        key="custom1"
                                        style={{ marginRight: '1em' }}
                                        className={props.customButtonText === undefined ? 'hidden' : ''}
                                        onClick={props.customButtonOnClick}
                                        disabled={props.loading}
                                    >
                                        {props.customButtonText}
                                    </Button>
                                    {getCustomButtons().map((item) => item)}
                                    {props.hideSubmitButton != true && (
                                        <Button
                                            type="primary"
                                            style={{ marginRight: '1em' }}
                                            htmlType="submit"
                                            loading={props.loading}
                                        >
                                            {geti18nText('app.default.button.save')}
                                        </Button>
                                    )}
                                    <Button
                                        type="primary"
                                        style={{ marginRight: '1em' }}
                                        className={hideActivate() ? 'hidden' : ''}
                                        onClick={(e) => {
                                            toggleActive(true);
                                            save(e);
                                        }}
                                        style={{ background: '#00c700' }}
                                        disabled={props.loading}
                                    >
                                        {' '}
                                        {geti18nText('app.default.button.activate')}
                                    </Button>
                                    <Popconfirm
                                        key="confirm"
                                        placement="top"
                                        title={geti18nText('app.default.confirm.deactivate')}
                                        onConfirm={(e) => {
                                            toggleActive(false);
                                            save(e);
                                        }}
                                        okText={geti18nText('app.default.button.yes')}
                                        cancelText={geti18nText('app.default.button.no')}
                                    >
                                        <Button
                                            type="danger"
                                            style={{ marginRight: '1em' }}
                                            className={hideDeactivate() ? 'hidden' : ''}
                                            disabled={props.loading}
                                        >
                                            {' '}
                                            {geti18nText('app.default.button.deactivate')}
                                        </Button>
                                    </Popconfirm>
                                    {props.hideCancelButton != true && (
                                        <Button onClick={(e) => props.history.goBack()}>
                                            {geti18nText('app.default.button.cancel')}
                                        </Button>
                                    )}
                                </Col>
                            </Row>
                        </Form>
                    </>
                );
            }
        }
    }
});
