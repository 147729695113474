import React, { useState, useEffect } from 'react';

const NyBadge = ({ count = 0, defaultColor = 'red', minWidth = '24px', minHeight = '24px' }) => {
    const [badgeValue, setBadgeValue] = useState(count);

    useEffect(() => {
        setBadgeValue(count);
    }, [count]);

    return (
        <div
            className="notification-badge"
            style={{
                visibility: 'hidden',
                marginLeft: '5px',
                borderRadius: '50%',
                minWidth: minWidth,
                minHeight: minHeight,
                //  fontWeight: 'bold',
                textAlign: 'center',
                display: 'inline-block',
                background: defaultColor,
                color: 'white',
            }}
        >
            {badgeValue}
        </div>
    );
};

export default NyBadge;
