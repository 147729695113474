import { Button, Col, DatePicker, Divider, Form, Icon, Input, Row, Upload, message, notification } from 'antd';
import moment from 'moment';
import React, { forwardRef, useEffect, useState } from 'react';
import { Spinner } from '../Spinner';
import { CONSTANTS_FILE_TYPE, CONSTANTS_REQ } from '../../utils/Constants';
import { requestGet, requestPut, RESPONSE } from '../../utils/RequestResolver';
import { geti18nText, Session } from '../../utils/Session';
import { normalizeComponents } from '../../utils/Utils';
import { FileUpload } from '../fileUpload/FileUpload';
import { InputByLanguage, translationsToObject } from '../InputByLanguage';

const AvailabilityEdit = Form.create({ name: 'AvailabilityEdit' })((props) => {
    const { getFieldDecorator, getFieldValue, setFieldsValue } = props.form;
    const [fetching, setFetching] = useState(false);
    const [formValues, setFormValues] = useState(null);
    const { RangePicker } = DatePicker;
    const [titleLangJson, setTitleLangJson] = useState(undefined);
    const [subtitleLangJson, setSubTitleLangJson] = useState(undefined);
    const [descriptionLangJson, setDescriptionLangJson] = useState(undefined);
    const [componentId, setComponentId] = useState();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetch();
    }, [props.visible]);

    useEffect(() => {
        props.form.resetFields();
        setFieldsValue(formValues);
    }, [formValues]);

    function fetch() {
        setFetching(true);
        requestGet(CONSTANTS_REQ.WEBPAGECOMPONENT.EDIT + '/' + props.id).then((result) => {
            setFetching(false);
            if (result.status === RESPONSE.OK) {
                setValues(result.data);
            }
        });
    }

    function showNotification(title, message, duration, type) {
        notification[type]({
            message: title,
            description: message,
            duration: duration,
        });
    }

    function okNotification() {
        showNotification(geti18nText('app.default.save.ok'), geti18nText('app.default.save.ok.desc'), 3, 'success');
    }

    function error(message) {
        showNotification(geti18nText('app.default.save.nok'), message, 3, 'error');
    } 

    function setValues(component_data) {
        var json;
        if (component_data.json) {
            let setJson = {};

            json = JSON.parse(component_data.json);
            setComponentId(json.id);
            setJson = translationsToObject(json, 'title', JSON.stringify(json.title));
            setJson = translationsToObject(json, 'subtitle', JSON.stringify(json.subtitle));
            setJson = translationsToObject(json, 'description', JSON.stringify(json.description));
            setJson = translationsToObject(json, 'availabilityButton', JSON.stringify(json.availabilityButton));
            setJson = translationsToObject(
                json,
                'availabilitySuccessfull',
                JSON.stringify(json.availabilitySuccessfull)
            );
            setJson = translationsToObject(json, 'availabilityError', JSON.stringify(json.availabilityError));
            setJson = translationsToObject(json, 'textLink', JSON.stringify(json.textLink));

            setTitleLangJson(JSON.stringify(json.title));

            setJson['link'] = json.link;
            setFormValues(setJson);
        }
    }

    function save(e) {
        e.preventDefault();
        props.form.validateFieldsAndScroll((err, values) => {
            let minPrice = {};
            if (!err) {
                let valuesToSave = {};
                let normalizedValues = normalizeComponents(values);

                requestPut(CONSTANTS_REQ.WEBPAGECOMPONENT.UPDATEJSON + '/' + props.id, null, {
                    id: props.id,
                    json: JSON.stringify(normalizedValues),
                }).then((result) => {
                    if (result && result.status === RESPONSE.CREATED) {
                        console.log('okNotification');
                        okNotification();
                        props.closePopup();
                    } else {
                        error();
                        if (result && result.status === RESPONSE.BAD_REQUEST) {
                            if (result.data && result.data.field) {
                                switch (result.data.field) {
                                    case 'mandatory_fields_required':
                                        console.log(geti18nText('app.default.mandatory_fields_required'));
                                        return;
                                    default:
                                        break;
                                }
                            }
                        }
                        if (result.data && result.data.error) {
                            console.log(JSON.stringify(result.data.error));
                        }
                    }
                   
                });
            }
        });
    }

    const upload = {
        name: 'file',
        accept: '.csv,',
        action: CONSTANTS_REQ.COMPONENT.CSV + 1,
        showUploadList: false,
        multiple: false,
        headers: {
            Authorization: 'Bearer ' + Session.getUserToken(),
        },
        onChange(info, event) {
            if (info.file.status === 'uploading') {
                setLoading(true);
                message.destroy();
                message.loading(`${info.file.name} ${geti18nText('import.text.uploading')}`);
            }
            if (info.file.status === 'done') {
                setLoading(false);
                message.destroy();
                message.success(`${info.file.name} ${geti18nText('import.text.done')}`);
                if (info.file.response) {
                    //showImage(componentId, selectedImage);
                }
            } else if (info.file.status === 'error') {
                setLoading(false);
                message.destroy();
                message.error(`${geti18nText('component.magicavailability.import.error')} ${info.file.name} `);
            }
        },
    };

    if (fetching) {
        return <Spinner />;
    } else {
        return (
            <React.Fragment>
                <Divider></Divider>
                <Form>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item hidden={true}>{getFieldDecorator('id')(<Input hidden={true} />)}</Form.Item>
                            <InputByLanguage
                                fieldName="title"
                                label={geti18nText('component.magicavailability.title')}
                                langJson={titleLangJson}
                                {...props}
                            />

                            <InputByLanguage
                                fieldName="subtitle"
                                label={geti18nText('component.magicavailability.subtitle')}
                                langJson={subtitleLangJson}
                                {...props}
                            />
                            <InputByLanguage
                                fieldName="description"
                                label={geti18nText('component.magicavailability.description')}
                                langJson={descriptionLangJson}
                                {...props}
                            />
                            <InputByLanguage
                                fieldName="availabilityButton"
                                label={geti18nText('component.magicavailability.btn.check')}
                                langJson={descriptionLangJson}
                                {...props}
                            />
                            <InputByLanguage
                                fieldName="availabilitySuccessfull"
                                label={geti18nText('component.magicavailability.title.succes')}
                                langJson={descriptionLangJson}
                                {...props}
                            />
                            <InputByLanguage
                                fieldName="availabilityError"
                                label={geti18nText('component.magicavailability.title.error')}
                                langJson={descriptionLangJson}
                                {...props}
                            />
                            <Form.Item label={geti18nText('component.magicavailability.link.text')}>
                                {getFieldDecorator('link', {
                                    rules: [{ required: true, message: geti18nText('app.default.required') }],
                                })(<Input />)}
                            </Form.Item>
                            <InputByLanguage
                                fieldName="textLink"
                                label={geti18nText('component.magicavailability.link.text.title')}
                                langJson={descriptionLangJson}
                                {...props}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24} style={{ textAlign: 'right', marginTop: '1rem' }}>
                            <Upload {...upload}>
                                <Button>
                                    <Icon type="upload" /> {geti18nText('component.magicavailability.import')}
                                </Button>
                            </Upload>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24} style={{ textAlign: 'right', marginTop: '1rem' }}>
                            <Button style={{ marginRight: '1em' }} onClick={(e) => props.closePopup()}>
                                {geti18nText('app.default.button.cancel')}
                            </Button>
                            <Button type="primary" onClick={(e) => save(e)} loading={loading}>
                                {geti18nText('app.default.button.save')}
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </React.Fragment>
        );
    }
});

export default AvailabilityEdit;
