import React, { useState, useEffect } from 'react';
import { Form, Row, Col, Button, Divider, Input, Radio, Select } from 'antd';
import { normalizeComponents } from '../../utils/Utils';
import { requestGet, requestPut, RESPONSE } from '../../utils/RequestResolver';
import { Spinner } from '../../components/Spinner';
import { CONSTANTS_REQ } from '../../utils/Constants';
import { SearchField } from '../../components/Search';
import { Session, geti18nText } from '../../utils/Session';
import { ColorPicker } from '../../components/ColorPicker';
import { InputByLanguage, translationsToObject } from '../InputByLanguage';

const BtcCareerEdit = Form.create({ name: 'VideoBlockEdit' })((props) => {
    const { getFieldDecorator, setFieldsValue } = props.form;
    const [fetching, setFetching] = useState(false);
    const [formValues, setFormValues] = useState(null);
    const [mainTitleLangJson, setMainTitleLangJson] = useState(undefined);
    const [textJson, setTextJson] = useState(null);
    const [titleJson, setTitleJsons] = useState(null);
    const [linkTitle, setLinkTitle] = useState(null);
    const [externalLink, setExternalLink] = useState(JSON.stringify(props.externalLink));
    const [imageLink, setImageLink] = useState({});
    const LINK_TYPE = {
        LINK: 'LINK',
        WEBPAGE: 'WEBPAGE',
    };
    const [linkType, setLinkType] = useState(props.linkType ? props.linkType : LINK_TYPE.WEBPAGE);
    const [emails, setEmails] = useState(null);

    useEffect(() => {
        fetch();
    }, [props.visible]);

    useEffect(() => {
        props.form.resetFields();

        if (formValues && formValues['webPageTerms']) {
            setWepPageValue('webPageTerms', formValues['webPageTerms'].id);
            delete formValues['webPageTerms'];
        }

        setFieldsValue(formValues);
    }, [formValues]);

    function fetch() {
        setFetching(true);
        requestGet(CONSTANTS_REQ.WEBPAGECOMPONENT.EDIT + '/' + props.id).then((result) => {
            setFetching(false);
            if (result.status === RESPONSE.OK) {
                setValues(result.data);
            }
        });
    }

    const changeEmails = (value) => {
        setEmails(value);
    };

    function setValues(component_data) {
        var json;
        if (component_data.json) {
            json = JSON.parse(component_data.json);
            json = translationsToObject(json, 'title', json.title);
            json = translationsToObject(json, 'link-title', json['link-title']);
            json = translationsToObject(json, 'text', json.text);

            setLinkType(json['linkType']);
            setEmails(json.emails);
            setTextJson(json.text);
            setTitleJsons(json.title);
            setLinkTitle(json['link-title']);
            setFormValues(json);
        }
    }

    function setWepPageValue(key, id) {
        requestGet(CONSTANTS_REQ.WEBPAGE.EDIT + '/' + id).then((result) => {
            if (result.status === RESPONSE.OK) {
                let field = {};
                field[key] = { id: id, name: result.data.name };
                setFieldsValue(field);
            }
        });
    }

    function save(e, close) {
        e.preventDefault();

        props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                values = normalizeComponents(values);
                values.webPageTerms =
                    values.webPageTerms != null && values.webPageTerms.constructor === String
                        ? { id: parseInt(values.webPageTerms) }
                        : values.webPageTerms;
                values.emails = emails != null && emails;
                requestPut(CONSTANTS_REQ.WEBPAGECOMPONENT.UPDATEJSON + '/' + props.id, null, {
                    id: props.id,
                    json: JSON.stringify(values),
                }).then((result) => {
                    if (result && result.status === RESPONSE.CREATED) {
                        console.log('okNotification');
                    } else {
                        if (result && result.status === RESPONSE.BAD_REQUEST) {
                            if (result.data && result.data.field) {
                                switch (result.data.field) {
                                    case 'mandatory_fields_required':
                                        console.log(geti18nText('app.default.mandatory_fields_required'));
                                        return;
                                    default:
                                        break;
                                }
                            }
                        }
                        if (result.data && result.data.error) {
                            console.log(JSON.stringify(result.data.error));
                        }
                    }
                    close && props.closePopup();
                });
            }
        });
    }

    if (fetching) {
        return <Spinner />;
    } else {
        return (
            <React.Fragment>
                <Divider>{geti18nText('component.btccareer')}</Divider>
                <Form>
                    <Row style={{ marginTop: '20px ' }}>
                        <Col span={24}>
                            <Row>
                                <Row>
                                    <Col span={24}>
                                        <InputByLanguage
                                            fieldName={'title'}
                                            label={geti18nText('component.flextitletext.edit.title')}
                                            langJson={titleJson}
                                            {...props}
                                        />

                                        <Form.Item label={geti18nText('component.btcnewsletter.edit.emails')}>
                                            {getFieldDecorator('emails')(
                                                <Select
                                                    mode="tags"
                                                    notFoundContent={null}
                                                    style={{ width: '100%' }}
                                                    onChange={changeEmails}
                                                />
                                            )}
                                        </Form.Item>

                                        <InputByLanguage
                                            fieldName={'text'}
                                            label={geti18nText('component.flextitletext.edit.text')}
                                            langJson={textJson}
                                            {...props}
                                        />
                                        <InputByLanguage
                                            fieldName={'link-title'}
                                            label={'link naslov'}
                                            langJson={linkTitle}
                                            {...props}
                                        />
                                    </Col>
                                </Row>
                            </Row>
                            <Row>
                                <Row>
                                    <Row>
                                        <Col>
                                            <Form.Item>
                                                {getFieldDecorator('linkType', {
                                                    initialValue:
                                                        linkType === LINK_TYPE.LINK
                                                            ? LINK_TYPE.LINK
                                                            : LINK_TYPE.WEBPAGE,
                                                })(
                                                    //
                                                    <Radio.Group
                                                        onChange={(e) => {
                                                            setLinkType(e.target.value);
                                                        }}
                                                    >
                                                        <Radio value={LINK_TYPE.WEBPAGE}>
                                                            {geti18nText(
                                                                'component.imagewithtext.edit.radio_button.webPage'
                                                            )}
                                                        </Radio>
                                                        <Radio value={LINK_TYPE.LINK}>
                                                            {geti18nText(
                                                                'component.imagewithtext.edit.radio_button.link'
                                                            )}
                                                        </Radio>
                                                    </Radio.Group>
                                                )}
                                            </Form.Item>
                                            <Form.Item
                                                hidden={linkType !== LINK_TYPE.LINK}
                                                label={geti18nText('component.pdfpreview.edit.link')}
                                            >
                                                {getFieldDecorator('link')(<Input />)}
                                            </Form.Item>
                                            <Form.Item
                                                hidden={linkType !== LINK_TYPE.WEBPAGE && linkType}
                                                label={geti18nText('component.imagewithtext.edit.webpage')}
                                            >
                                                {getFieldDecorator('webPageTerms', {
                                                    rules: [
                                                        {
                                                            required: false,
                                                            message: geti18nText('app.default.required'),
                                                        },
                                                    ],
                                                })(
                                                    <SearchField
                                                        url={CONSTANTS_REQ.WEBPAGE.SEARCH}
                                                        map={{ id: 'id', label: 'name' }}
                                                        searchBy="name"
                                                        disabled={false}
                                                        translate={true}
                                                        addedData={{ projectId: Session.getProjectId() }}
                                                    />
                                                )}
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Row>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24} style={{ textAlign: 'right', marginTop: '1rem' }}>
                            <Button style={{ marginRight: '1em' }} onClick={(e) => props.closePopup()}>
                                {geti18nText('app.default.button.cancel')}
                            </Button>
                            <Button style={{ marginRight: '1em' }} type="primary" onClick={(e) => save(e, false)}>
                                {geti18nText('app.default.button.save')}
                            </Button>
                            <Button
                                type="primary"
                                style={{ background: '#31b531', borderColor: '#31b531', textColor: 'white' }}
                                onClick={(e) => {
                                    save(e, true);
                                }}
                            >
                                {geti18nText('app.default.button.save.close')}
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </React.Fragment>
        );
    }
});

export default BtcCareerEdit;
