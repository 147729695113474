import React, { useState, useEffect } from 'react';
import { Form, Row, Col, Input, Button, Divider, Tabs, Popconfirm, Card, notification } from 'antd';
import { Session, geti18nText } from '../../utils/Session';
import { requestGet, requestPut, RESPONSE } from '../../utils/RequestResolver';
import { CONSTANTS_FILE_TYPE, CONSTANTS_REQ } from '../../utils/Constants';
import { normalizeComponents } from '../../utils/Utils';
import { FileUpload } from '../fileUpload/FileUpload';
import { InputByLanguage, translationsToObject } from '../InputByLanguage';
import { Spinner } from '../Spinner';
import { normalizeJSONForComponent, getDiskFiles } from '../../utils/ComponentHelper';
import { getSelectedClientLanguage } from '../../utils/Utils';
import SortImagesOrVideos, { ReorderImageKeys } from '../SortImagesOrVideos';

const ImageGalleryEdit = Form.create({ name: 'ImageGalleryEdit' })((props) => {
    const [fetching, setFetching] = useState(false);
    const { getFieldDecorator, setFieldsValue } = props.form;
    const [mainTitleLangJson, setMainTitleLangJson] = useState(undefined);
    const [formValues, setFormValues] = useState(undefined);
    const [newFormValues, setNewFormValues] = useState(undefined);
    const [imagesKeys, setImagesKeys] = useState([0]);
    const [lastKey, setLastKey] = useState(0);
    const [images, setImages] = useState([]);
    const [deleteConfirmVisible, setDeleteConfirmVisible] = useState(false);
    const [imageToDelete, setImageToDelete] = useState(null);
    const [deletedImages, setDeletedImages] = useState([]);
    const [activeTab, setActiveTab] = useState('0');
    const { TabPane } = Tabs;
    const { Meta } = Card;
    const selLang = getSelectedClientLanguage();
    const [imageArrSize, setImageArrSize] = useState();

    useEffect(() => {
        fetch();
    }, [props.visible]);

    useEffect(() => {
        props.form.resetFields();
        setFieldsValue(formValues);
    }, [formValues]);

    useEffect(() => {
        setFieldsValue(newFormValues);
    }, [newFormValues]);

    function fetch() {
        setFetching(true);
        requestGet(CONSTANTS_REQ.WEBPAGECOMPONENT.EDIT + '/' + props.id).then((result) => {
            setFetching(false);
            if (result.status === RESPONSE.OK) {
                setValues(result.data);
            }
        });
    }

    function setValues(component_data) {
        let imgArray = [];
        var json;
        if (component_data.json) {
            var imagePaths = {};
            json = JSON.parse(component_data.json);

            let max_key = 0;
            for (const key of json.image_keys) {
                imgArray.push({
                    id: key,
                    titleLangJson: json['image_' + key].description,
                    textLangJson: json['text_' + key],
                    rdlPath: json['image_' + key].thumbnail_path
                        ? json['image_' + key].thumbnail_path
                        : json['image_' + key].file_path,
                });
                json = translationsToObject(json, 'image_' + key + '-description', json['image_' + key].description);

                imagePaths['image_' + key + '-file_path'] = json['image_' + key].file_path;
                imagePaths['image_' + key + '-thumbnail_path'] = json['image_' + key].thumbnail_path;
                json['image_' + key + '-file_path'] = {
                    key: json['image_' + key].file_path,
                    file_path: json['image_' + key].file_path,
                };
                if (json['image_' + key].thumbnail_path) {
                    json['image_' + key + '-thumbnail_path'] = {
                        key: json['image_' + key].thumbnail_path,
                        file_path: json['image_' + key].thumbnail_path,
                    };
                }
                delete json['image_' + key];
                json = translationsToObject(json, 'text_' + key, json['text_' + key]);
                delete json['text_' + key];

                max_key = key > max_key ? key : max_key;
            }

            setLastKey(max_key);
            json = translationsToObject(json, 'title', json['title']);
            setMainTitleLangJson(json['title']);
            setImages(imgArray);
            setImagesKeys(json.image_keys);
            setImageArrSize(imgArray.length);

            setActiveTab('' + json.image_keys[0]);
            delete json.totalImages;
            delete json.image_keys;
            delete json['title'];

            setFormValues(json);

            // getDiskFiles(CONSTANTS_REQ.DISK_FILE.LIST, imagePaths, props);
        } else {
            setImagesKeys([0]);
            setImages([{ id: 0 }]);
        }
    }

    function showNotification(title, message, duration, type) {
        notification[type]({
            message: title,
            description: message,
            duration: duration,
        });
    }

    function okNotification() {
        showNotification(geti18nText('app.default.save.ok'), geti18nText('app.default.save.ok.desc'), 3, 'success');
    }

    function error(message) {
        showNotification(geti18nText('app.default.save.nok'), message, 3, 'error');
    } 

    function save(e, close) {
        e.preventDefault();
        props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                values = normalizeComponents(values);
                values.image_keys = imagesKeys;
                for (var i = 0; i < values.image_keys.length; i++) {
                    if (values['image_' + values.image_keys[i] + '-file_path'] === null) {
                        error();
                        return;
                    }
                }
                if (props.showReorder) {
                    values.image_keys = ReorderImageKeys(images);
                }
                values = normalize(values);
                requestPut(CONSTANTS_REQ.WEBPAGECOMPONENT.UPDATEJSON + '/' + props.id, null, {
                    id: props.id,
                    json: values.json,
                }).then((result) => {
                    if (result && result.status === RESPONSE.CREATED) {
                        console.log('okNotification');
                        okNotification();
                        close && props.closePopup();
                    } else {
                        error();
                        if (result && result.status === RESPONSE.BAD_REQUEST) {
                            if (result.data && result.data.field) {
                                switch (result.data.field) {
                                    case 'mandatory_fields_required':
                                        console.log(geti18nText('app.default.mandatory_fields_required'));
                                        return;
                                    default:
                                        break;
                                }
                            }
                        }
                        if (result.data && result.data.error) {
                            console.log(JSON.stringify(result.data.error));
                        }
                    }
                });
            }
        });
    }

    function normalize(values, translationKeys) {
        let jsonFieldGroups = ['title', 'image_keys'];
        let jsonFieldKeys = {};
        let imageJsonArr = ['file_path', 'thumbnail_path', 'description'];
        imagesKeys.forEach((imageIndex) => {
            jsonFieldGroups.push('image_' + imageIndex.toString());
            jsonFieldKeys['image_' + imageIndex.toString()] = imageJsonArr;
        });
        return normalizeJSONForComponent(values, undefined, jsonFieldGroups, jsonFieldKeys, props);
    }

    const onEdit = (targetKey, action) => {
        if (action == 'add') {
            props.form.validateFieldsAndScroll((err, values) => {
                if (!err) {
                    let newId = imagesKeys.at(imagesKeys.length - 1);
                    newId++;
                    let arrImages = images.slice();
                    let arrKeys = imagesKeys.slice();
                    arrImages.push({ id: newId });
                    arrKeys.push(newId);
                    setImagesKeys(arrKeys);
                    setImages(arrImages);
                    setActiveTab('' + newId);
                    setLastKey(newId);
                }
            });
        }
        if (action == 'remove') {
            if (imagesKeys.length > 1) {
                setDeleteConfirmVisible(true);
                setImageToDelete(targetKey);
                deleteImage();
            }
        }
    };


    const addMultiImages = (newImagesArr) => {
        props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                setImageArrSize(imageArrSize);
                let imgId = imagesKeys.at(imagesKeys.length - 1);
                let newId = lastKey;
                var arryKey = imagesKeys.at(imagesKeys.length - 1);
                let lastKeyNum = imagesKeys.at(imagesKeys.length - 1);
                let newImages = newImagesArr.map((newImg) =>
                    newImg.includes('FILE-')
                        ? {
                              id: ++imgId,
                              titleLangJson: {},

                              rdlPath: '/' + newImg.slice(5),
                              file_path: '/' + newImg.slice(5),
                              file_type: '/' + newImg.slice(5),
                          }
                        : newImg.includes('FOLDER-')
                        ? (newImg = {
                              id: ++imgId,
                              titleLangJson: {},
                              rdlPath: '/' + newImg.slice(7),
                              file_path: '/' + newImg.slice(7),
                              file_type: '/' + newImg.slice(7),
                          })
                        : (newImg = {
                              id: ++imgId,
                              titleLangJson: {},
                              rdlPath: '/' + newImg,
                              file_path: '/' + newImg,
                              file_type: '/' + newImg,
                          })
                );
                setImageArrSize(imgId);
                let newKeys = newImagesArr.map((newKey) => ++lastKeyNum);
                setImagesKeys([...imagesKeys, ...newKeys]);
                setLastKey(lastKeyNum);
                setImages([...images, ...newImages]);

                var newJson = {};
                if (!formValues && !newFormValues) {
                    setImagesKeys([...newKeys]);
                    setImages([...newImages]);
                }
                for (let i = 0; i < newImagesArr.length; i++) {
                    arryKey++;
                    {
                        newJson['image_' + arryKey + '-file_path'] = {
                            key: newImages[i].rdlPath,
                            file_path: newImages[i].rdlPath,
                        };
                        newJson = translationsToObject(newJson, 'text_' + arryKey, newJson['text_' + arryKey]);
                    }
                }

                setNewFormValues(newJson);

                var acTab = newKeys.at(newKeys.length - 1);
                setActiveTab('' + acTab);
            }
        });
    };

    const tabOnChange = (activeKey) => {
        props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                setActiveTab(activeKey);
            }
        });
    };

    const deleteImage = () => {
        if (imageToDelete) {
            var newArrKeys = imagesKeys.filter((key) => key != parseInt(imageToDelete));
            var newArrImages = images.filter((image) => image.id != parseInt(imageToDelete));
            setImagesKeys(newArrKeys);
            setImages(newArrImages);
            setImageArrSize(imageArrSize - 1);
            setLastKey(lastKey - 1);

            if (activeTab == imageToDelete) {
                setActiveTab('' + newArrKeys[0]);
            }

            setFieldsValue(newArrImages);
            setDeleteConfirmVisible(false);
            setImageToDelete(null);
            let deleted = deletedImages.slice();
            deleted.push(imageToDelete);
            setDeletedImages(deleted);
        }
    };

    const reorderImages = (reordered) => {
        setImages(reordered);
    };

    if (fetching) {
        return <Spinner />;
    } else {
        return (
            <React.Fragment>
                <Divider>{geti18nText('component.imagegallery')}</Divider>
                <Form>
                    {props.showReorder && <SortImagesOrVideos images={images} reorderImages={reorderImages} />}
                    <Row hidden={props.showReorder ? true : false}>
                        <Col span={24}>
                            <Form.Item hidden={true}>{getFieldDecorator('id')(<Input hidden={true} />)}</Form.Item>
                            <InputByLanguage
                                notRequred
                                fieldName="title"
                                label={geti18nText('component.imagegallery.edit.title')}
                                langJson={mainTitleLangJson}
                                {...props}
                            />
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <FileUpload
                                addMultiImages={addMultiImages}
                                onEdit={onEdit}
                                multiple={true}
                                checkboxes={true}
                                fileType={CONSTANTS_FILE_TYPE.IMAGE}
                                map={{ id: 'id', label: 'name' }}
                                searchBy="name"
                                disabled={false}
                                addedData={{ projectId: Session.getProjectId() }}
                            />
                        </Col>
                    </Row>
                    <Row hidden={props.showReorder ? true : false}>
                        <Col span={24}>
                            <Popconfirm
                                placement="rightTop"
                                title={geti18nText('app.default.button.delete')}
                                visible={deleteConfirmVisible}
                                okText={geti18nText('app.default.button.yes')}
                                cancelText={geti18nText('app.default.button.no')}
                                onCancel={() => {
                                    setDeleteConfirmVisible(false);
                                }}
                                onConfirm={deleteImage}
                            >
                                <span></span>
                            </Popconfirm>
                        </Col>
                    </Row>
                    <Row hidden={props.showReorder ? true : false} style={{ marginTop: '20px ' }}>
                        <Col span={24}>
                            <Tabs type="editable-card" onEdit={onEdit} onChange={tabOnChange} activeKey={activeTab}>
                                {images &&
                                    images.map((image, index) => (
                                        <TabPane tab={'Image ' + (index + 1)} key={image.id} forceRender={true}>
                                            <ImageTab
                                                index={image.id}
                                                tileLangJson={image.titleLangJson}
                                                textLangJson={image.textLangJson}
                                                {...props}
                                            />
                                        </TabPane>
                                    ))}
                            </Tabs>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24} style={{ textAlign: 'right', marginTop: '1rem' }}>
                            <Button style={{ marginRight: '1em' }} onClick={(e) => props.closePopup()}>
                                {geti18nText('app.default.button.cancel')}
                            </Button>
                            <Button style={{ marginRight: '1em' }} type="primary" onClick={(e) => save(e, false)}>
                                {geti18nText('app.default.button.save')}
                            </Button>
                            <Button
                                type="primary"
                                style={{ background: '#31b531', borderColor: '#31b531', textColor: 'white' }}
                                onClick={(e) => {
                                    save(e, true);
                                }}
                            >
                                {geti18nText('app.default.button.save.close')}
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </React.Fragment>
        );
    }
});

const ImageTab = (props) => {
    const { getFieldDecorator, setFieldsValue } = props.form;

    return (
        <>
            <Row gutter={24}>
                <Col span={24}>
                    <Form.Item label={geti18nText('component.imagegallery.edit.image')}>
                        {getFieldDecorator('image_' + props.index + '-file_path', {
                            rules: [
                                {
                                    required: !props.formValues && !props.newFormValues ? false : true,
                                    message: geti18nText('app.default.required'),
                                },
                            ],
                        })(
                            <FileUpload
                                fileType={CONSTANTS_FILE_TYPE.IMAGE}
                                map={{ id: 'id', label: 'name' }}
                                searchBy="name"
                                disabled={false}
                                addedData={{ projectId: Session.getProjectId() }}
                            />
                        )}
                    </Form.Item>
                    <Form.Item label={geti18nText('component.imagegallery.edit.thumbnail_image')}>
                        {getFieldDecorator('image_' + props.index + '-thumbnail_path', {
                            rules: [
                                {
                                    required: false,
                                    message: geti18nText('app.default.required'),
                                },
                            ],
                        })(
                            <FileUpload
                                fileType={CONSTANTS_FILE_TYPE.IMAGE}
                                map={{ id: 'id', label: 'name' }}
                                searchBy="name"
                                disabled={false}
                                addedData={{ projectId: Session.getProjectId() }}
                            />
                        )}
                    </Form.Item>
                    <InputByLanguage
                        notRequred
                        fieldName={'image_' + props.index + '-description'}
                        label={geti18nText('component.imagegallery.edit.image_description')}
                        langJson={props.tileLangJson}
                        {...props}
                    />
                </Col>
            </Row>
        </>
    );
};

export default ImageGalleryEdit;
