import React, { useEffect, useState } from 'react';
import { Icon } from 'antd';
import { DataTable, getColumnSearch, getColumnSearchOption } from '../../../components/DataTable';
import { Spinner } from '../../../components/Spinner';
import WebPageLinksEdit from './WebPageLinksEdit';
import { CONSTANTS_REQ } from '../../../utils/Constants';
import { ENUMS, geti18nText, Session } from '../../../utils/Session';
import { getTranslated } from '../../../components/InputByLanguage';
import { getSelectedLocale } from '../../../utils/Utils';

const WebPageLinkList = (props) => {
    const [webPageId, setWebPageId] = useState(props.webPageId);

    useEffect(() => {
        setWebPageId(
            Session.getProjectInfo() && Session.getProjectInfo().webPage
                ? Session.getProjectInfo().webPage.id
                : props.webPageId
                ? props.webPageId
                : undefined
        );
    }, [props.webPageId]);

    function setDefaultFilterValue() {
        return [{ field: 'active', condition: 'equals_bool', value: 1 }];
    }

    return webPageId == null ? (
        <Spinner />
    ) : (
        <DataTable
            headerTitle={geti18nText('webPageLinks.table.header')}
            url={CONSTANTS_REQ.WEBPAGELINKS.LIST}
            addNewButtonText={geti18nText('webPageLinks.table.add')}
            showRecordModal={true}
            modalComponent={WebPageLinksEdit}
            setDefaultFilterValue={setDefaultFilterValue}
            addedData={{ webPageId: webPageId }}
            columns={[
                {
                    title: geti18nText('bulletin.table.column.id'),
                    dataIndex: 'id',
                    width: '10%',
                    sorter: (a, b) => {},
                    ...getColumnSearch('number'),
                },
                {
                    title: geti18nText('component.headerMenuSubLinks.topLinks.name'),
                    dataIndex: 'name',
                    sorter: (a, b) => {},
                    ...getColumnSearch('string'),
                    render: (text, record) => {
                        return <div>{getTranslated(text)}</div>;
                    },
                },
                {
                    title: geti18nText('webPageLinks.edit.link'),
                    dataIndex: ['webPageLink','name'],
                    sorter: (a, b) => {},
                    ...getColumnSearch('string'),
                    render: (text, record) => {
                        return <div>{record.webPageLink? getTranslated(text): record.link}</div>;
                        
                    },
                },
                {
                    title: geti18nText('webPageLinks.edit.linkType'),
                    dataIndex: 'linkType',
                    sorter: (a, b) => {},
                    ...getColumnSearch('string'),
                    render: (text, record) => {
                        return <div>{text}</div>;
                    },
                },
                
                {
                    title: geti18nText('bulletin.table.column.active'),
                    dataIndex: 'active',
                    width: '10%',
                    render: (text, record) => {
                        if (record.active) {
                            return <Icon type="check" style={{ color: 'green' }} />;
                        } else {
                            return <Icon type="close" style={{ color: 'red' }} />;
                        }
                    },
                    ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', setDefaultFilterValue()[0]['value']),
                    defaultFilteredValue: setDefaultFilterValue(),
                },
            ]}
        />
    );
};

export default WebPageLinkList;
