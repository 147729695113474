import React from 'react';
import {
    ControlBar,
    CurrentTimeDisplay,
    DurationDisplay,
    FullscreenToggle,
    Player,
    PlayToggle,
    TimeDivider,
    VolumeMenuButton,
} from 'video-react';
import { CONSTANTS_FILE_TYPE, CONSTANTS_REQ } from '../../utils/Constants';
import { Session } from '../../utils/Session';
import { useState } from 'react';

export const FilePreview = (props) => {
    const file = props.file;
    const PreviewContent = () => {
        if (file) {
            const projectId =
                props.addedData.projectId == undefined
                    ? undefined
                    : props.addedData.projectId == 0
                    ? 0
                    : Session.getProjectId();
            if (file.fileType === CONSTANTS_FILE_TYPE.IMAGE) {
                let path =
                    projectId == undefined
                        ? CONSTANTS_REQ.STATIC_MEDIA + encodeURI(file.path)
                        : CONSTANTS_REQ.STATIC + projectId + encodeURI(file.path);
                if (file.path.length > 3) {
                    if (file.path.substring(file.path.length - 3, file.path.length) == 'svg') {
                        path =
                            projectId == undefined
                                ? CONSTANTS_REQ.STATIC_MEDIA + file.path
                                : projectId == 0
                                ? CONSTANTS_REQ.STATIC_CLIENT + file.path
                                : CONSTANTS_REQ.STATIC + projectId + file.path;
                    }
                }
                return <img width={'100%'} src={path}></img>;
            } else if (file.fileType === CONSTANTS_FILE_TYPE.VIDEO) {
                return (
                    <Player fluid src={CONSTANTS_REQ.STATIC + projectId + encodeURI(file.path)}>
                        <ControlBar autoHide={false} disableDefaultControls={true}>
                            <PlayToggle />
                            <VolumeMenuButton />
                            <CurrentTimeDisplay />
                            <TimeDivider />
                            <DurationDisplay />
                            <FullscreenToggle />
                        </ControlBar>
                    </Player>
                );
            }
        }
        return null;
    };

    return <PreviewContent />;
};
