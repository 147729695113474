import React, {useEffect, useState, useRef} from 'react';
import moment from 'moment';
import { getSelectedLanguage } from '../../utils/Utils';
import { getTranslated, InputByLanguage, translationsToObject } from '../InputByLanguage';

import {
    Form,
    Row,
    Col,
    Button,
    Divider,
    Collapse,
    Tabs,
    InputNumber,
    Popconfirm,
    Input,
    Modal,
    Checkbox,
    Select,
    notification,
    Table,
    Icon,
    Radio
} from 'antd';
import { CONSTANTS_FILE_TYPE, CONSTANTS_REQ } from '../../utils/Constants';
import { requestGet, requestPut, RESPONSE } from '../../utils/RequestResolver';
import { FileUpload } from '../fileUpload/FileUpload';
import { Session, geti18nText } from '../../utils/Session';
import JoditEditor from 'jodit-react';
import { SearchField } from '../../components/Search';
import { ColorPicker } from '../../components/ColorPicker';




const Option = Select.Option;

const TabWithListAndDescriptionEdit = Form.create({ name: 'TabWithListAndDescriptionEdit' })((props) => {
    const LINK_TYPE = {
        LINK: 'LINK',
        WEBPAGE: 'WEBPAGE',
    };
    const [addNew, setAddNew] = useState(false);
    const [descriptionJson, setDescriptionJson] = useState(null);
    const [nameJson, setNameJson] = useState(null);
    const [nameTabJson, setTabNameJson] = useState(null);
    const [titleJson, setTitleJson] = useState(null);
    const [titleRightJsonFirst, setTitleRightJsonFirst] = useState(null);
    const [titleRightJsonSecond, setTitleRightJsonSecond] = useState(null);
    const [specialTextJson, setSpecialTextJson] = useState(null);
    const [imgDescJson, setImgDescJson] = useState(null);
    const [subTitleRightJson, setSubTitleRightJson] = useState(null);
    const [descRightJson, setDescRightJson] = useState(null);
    const [buttonText, setButtonText] = useState(null);
    const [selectedAsAction, setSelectedAsAction] = useState(false);
    const [selectedCalculateDouble, setSelectedCalculateDouble] = useState(false);
    const { getFieldDecorator, setFieldsValue, resetFields,getFieldsValue } = props.form;
    const [fetching, setFetching] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [formValues, setFormValues] = useState(null);
    const [data, setData] = useState([{ id: 0, listItems: [], type:1 }]);
    const [imageKeys, setImageKeys] = useState(undefined);
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const [selectedListId, setSelectedListId] = useState(undefined);
    const [seletedLang, setSeletedLang] = useState(Session.getProjectDefaultLanguage());
    const [joditValue, setJoditValue] = useState('<p>   </p>');
    const [componentValue, setComponentValue] = useState(initComponentValue());
    const editor = useRef(null);
    const [selectedProjectId, setSelectedProjectId] = useState(props.project ? props.project : Session.getProjectId());
    const [linkType, setLinkType] = useState(props.type ? props.type : LINK_TYPE.WEBPAGE);
    const [itemBackgroundColor, setItemBackgroundColor] = useState(undefined);
    const [itemBackgroundColorSpecial, setItemBackgroundColorSpecial] = useState(undefined);
    const [itemBackgroundColorBtn, setItemBackgroundColorBtn] = useState(undefined);

    function initComponentValue() {
        var initValue = {};
        Session.getProjectInfo().languages.forEach((value, index) => {
            const lang = Object.keys(value)[0];
            initValue[lang] = '<p></p>';
        });

        return initValue;
    }

    useEffect(() => {
        fetch();
    }, [props.visible]);


    function onSelectComponentProject(component, index) {
        props.form.resetFields(['projectWebLink']);
        if (component) {
            setSelectedProjectId(parseInt(component.key));
        } else {
            setSelectedProjectId(Session.getProjectId());
        }
    }


    useEffect(()=>{
        if(addNew === true) {
            if(formValues){
                let json=formValues;
                if(json.name){
                    json = translationsToObject(json, 'name', json.name);
                    setNameJson(json.name)
                }                
                if(json.title){
                    json = translationsToObject(json, 'title', json.title);
                    setTitleJson(json.title)
                }
                if(json.description){
                    json = translationsToObject(json, 'description', json.description);
                    setDescriptionJson(json.description)
                }
                if(json.rightTitleFirst){
                    json = translationsToObject(json, 'rightTitleFirst', json.rightTitleFirst);
                    setTitleRightJsonFirst(json.rightTitleFirst)
                }
                if(json.rightTitleSecond){
                    json = translationsToObject(json, 'rightTitleSecond', json.rightTitleSecond);
                    setTitleRightJsonFirst(json.rightTitleSecond)
                }
                
                if(json.rightSubtitle){
                    json = translationsToObject(json, 'rightSubtitle', json.rightSubtitle);
                    setDescriptionJson(json.rightSubtitle)
                }
                
                if(json.rightDescription){
                    json = translationsToObject(json, 'rightDescription', json.rightDescription);
                    setDescriptionJson(json.rightDescription)
                }
                if(json.specialText){
                    json = translationsToObject(json, 'specialText', json.specialText);
                    setDescriptionJson(json.specialText)
                }
                if(json.buttonText){
                    json = translationsToObject(json, 'buttonText', json.buttonText);
                    setDescriptionJson(json.buttonText)
                }
                if(json.buttonText){
                    json = translationsToObject(json, 'buttonText', json.buttonText);
                    setDescriptionJson(json.buttonText)
                }
                if(json.imageKeys) {
                    json.imageKeys.forEach((img)=>{
                        if(json["image_"+img.id]) {
                            img.image=json["image_"+img.id]
                            delete json["image_"+img.id]
                        }
                        if(json["imgDesc_"+img.id]) {
                            img.desc=json["imgDesc_"+img.id]
                            //delete json["imgDesc_"+img.id]
                        }
                    })
                }
                if(json.linkType) {
                    setLinkType(json.linkType)
                }
                if(json["item_background_color"]) {
                    setItemBackgroundColor(json["item_background_color"])
                }
                if(json["item_background_color_special"]) {
                    setItemBackgroundColorSpecial(json["item_background_color_special"])
                }
                if(json["item_background_color_btn"]) {
                    setItemBackgroundColorBtn(json["item_background_color_btn"])
                }

                
                setImageKeys(json.imageKeys)
                setFieldsValue(json);
            }
        }
    },[addNew,formValues])

    useEffect(()=>{
        let tabNames={}
        data.forEach((json)=>{
            if(json.tabName) {
                tabNames["tabName_"+json.id]=json.tabName
                tabNames=translationsToObject(tabNames,"tabName_"+json.id,json.tabName)
            }
        })
        setFieldsValue(tabNames)        
    },[data])

    useEffect(()=>{        
        if(imageKeys){
            imageKeys.forEach((item)=>{
                if(item.image) {
                    setFieldsValue({["image_"+item.id] : item.image} )
                }
                if(item.desc) {
                    let imgValues={}
                    imgValues["imgDesc_"+item.id]=item.desc
                    imgValues=translationsToObject(imgValues,"imgDesc_"+item.id,item.desc)
                    setFieldsValue(imgValues )
                }
            })
            
        }
    },[imageKeys])

    function fetch() {
        setFetching(true);
        requestGet(CONSTANTS_REQ.WEBPAGECOMPONENT.EDIT + '/' + props.id).then((result) => {
            setFetching(false);
            if (result.status === RESPONSE.OK) {
                setValues(result.data);
            }
        });
    }

    function setValues(component_data) {
        let json;
        if (component_data.json) {
            json = JSON.parse(component_data.json);
            setData(json)
            setType(json[0].type)
        }
    }

    function changeLang(showLang) {
        //setJoditValue(componentValue[showLang]);
        setSeletedLang(showLang);
    }

    const onItemDoubleClick=(record, index)=>{
        setSelectedListId(record.id)
        setIsEdit(true);
        if(record.linkType) {
            setLinkType(record.linkType)
        }
        setFormValues(record)
        setAddNew(true);
    }

    function setContent(newValue) {
        var temp = componentValue;
        temp[seletedLang] = newValue;
        setComponentValue(temp);
        let newData=data.map((item) => {
            if(item.type===2 && item.id.toString()===selectedTabIndex.toString()) {
                item.advancedEdit=temp
                return item
            } 
            else {
                return item
            }
        })
        setData(newData);
    }

    const deleteRow=(record)=>{
        let newData=data.map((item) => {
            if(item.id === selectedTabIndex) {
                let list=item.listItems.filter(l=>l.id!==record.id);
                item.listItems=list
                return item
            } 
            else return item
        })
        setData(newData)
        saveComponent(false,false,newData)
    }
    function showNotification(title, message, duration, type) {
        notification[type]({
            message: title,
            description: message,
            duration: duration,
        });
    }
    function okNotification() {
        showNotification(geti18nText('app.default.save.ok'), geti18nText('app.default.save.ok.desc'), 3, 'success');
    }

    function error(message) {
        showNotification(geti18nText('app.default.save.nok'), message, 3, 'error');
    }


    function saveComponent(close, notification, tabs){
        tabs.forEach((tab)=>{
            if(tab.type===2) tab.listItems=[]
            else delete tab.advancedEdit;
        })
        requestPut(CONSTANTS_REQ.WEBPAGECOMPONENT.UPDATEJSON + '/' + props.id, null, {
            id: props.id,
            json: JSON.stringify(tabs),
        }).then((result) => {
            if (result && result.status === RESPONSE.CREATED) {
                notification && okNotification();
                close && props.closePopup();
            } else {
                error();
                if (result && result.status === RESPONSE.BAD_REQUEST) {
                    if (result.data && result.data.field) {
                        switch (result.data.field) {
                            case 'mandatory_fields_required':
                                console.log(geti18nText('app.default.mandatory_fields_required'));
                                return;
                            default:
                                break;
                        }
                    }
                }
                if (result.data && result.data.error) {
                    console.log(JSON.stringify(result.data.error));
                }
            }
        });
}

    const columns = [
        {
          title: geti18nText('component.tabWithListAndDescription.list.item.name'),
          dataIndex: 'name',
          render: (text, record) => {
            if (record.name) {
                return <div>{getTranslated(record.name, getSelectedLanguage()).replace(
                    /(<? *script)/gi,
                    'illegalscript'
                )}</div>;
            }
          },
        },
        {
          title: geti18nText('component.tabWithListAndDescription.list.item.title'),
          dataIndex: 'title',
          render: (text, record) => {
            if (record.title) {
                return <div>{getTranslated(record.title, getSelectedLanguage()).replace(
                    /(<? *script)/gi,
                    'illegalscript'
                )}</div>;
            }
          },
          
        },
        {
            title: '',
            dataIndex: '',
            key: 'x',
            render: (text, record) => 
            <Popconfirm
                            key={'delete'}
                            placement="top"
                            title={geti18nText('component.calendar.delete.confirm')}
                            onConfirm={(e) => {
                                deleteRow(record)
                            }}
                            style={{justifyContent: 'flex-end'}}
                            okText={geti18nText('app.default.button.yes')}
                            cancelText={geti18nText('app.default.button.no')}
                        >
            <Button><Icon  style={{fontSize:'15px', color: 'red'}} type="delete" /></Button>
            </Popconfirm> 
          },
      ];

      const normalizeComponents = (values) => {
        let normalizedValues = {};
        let translationKeys = {};
        for (var key in values) {
            if (values.hasOwnProperty(key)) {
                    // translations - to json
                    if (key.startsWith('translation-')) {
                        const fieldKey = key.substr(15);
                        const fieldLanguage = key.substr(12, 2);
                        if (normalizedValues[fieldKey] == undefined) {
                            normalizedValues[fieldKey] = {};
                        }
                        normalizedValues[fieldKey][fieldLanguage] = values[key];
                        if (!translationKeys[fieldKey]) {
                            translationKeys[fieldKey] = fieldKey;
                        }
                    } else {
                        if (values[key] === undefined) {
                            normalizedValues[key] = null;
                        } else {
                            normalizedValues[key] = values[key];
                        }
                    }
                
            }
        }
        return normalizedValues;
    };
    const saveListItem=()=>{
           props.form.validateFieldsAndScroll((err, values) => {
            
                setAddNew(false)
                let images={}
                if(imageKeys) {
                    imageKeys.forEach((img)=>{
                        if(values["image_"+img.id]) {
                            images["image_"+img.id]=values["image_"+img.id]
                            delete values["image_"+img.id]
                        }
                    })
                }
                values = normalizeComponents(values);
                values ={...values, ...images}
                
                if(!isEdit) values.id= Math.floor(Math.random() * (9999999999))
                values.imageKeys=imageKeys
                if(values["tabName_"+selectedTabIndex]){
                    values.tabName=values["tabName_"+selectedTabIndex]
                }
                delete values["tabName_"+selectedTabIndex]
                if(isEdit) {
                    let newData=data.map((item) => {
                        if(item.id.toString() === selectedTabIndex.toString()) {
                            let list=item.listItems.map(l=>l.id===selectedListId? {id: l.id, ...values}: l);
                            item.listItems=list
                            return item
                        } 
                        else {
                            return item
                        
                        }
                    })
                    setData(newData)
                    saveComponent(false,false,newData)
                }
                
                else {
                    let newData=data.map((item) => {
                        if( item.id.toString() === selectedTabIndex.toString()) { 
                            let obj= {...item}
                            if(obj.listItems){
                                obj.listItems= [...item.listItems, values]
                            }
                            else obj.listItems=[values]
                            return  obj
                        }
                        else return item
                        }  
                        )
                        setData(newData);
                        saveComponent(false,false,newData)
                    } 
                setImageKeys(undefined)
                //props.form.resetFields();
            
           })
    }  
    const closePopup=()=>{
        setImageKeys(undefined)
        setAddNew(false);
    }  

    const config = {
        readonly: false, // all options from https://xdsoft.net/jodit/doc/
        allowTabNavigation: false,
        minHeight: '600px',
        uploader: {
            insertImageAsBase64URI: true,
        },
        removeButtons: 'fullsize',
    };

    const renderTabImage=(item,index)=>{
        return <Row gutter={24} style={{marginBottom:'10px'}}>
            <Col span={24}>
                <Form.Item >
                {getFieldDecorator('image_'+index)(
                    <FileUpload
                        fileType={CONSTANTS_FILE_TYPE.IMAGE}
                        map={{ id: 'id', label: 'name' }}
                        searchBy="name"
                        disabled={false}
                        addedData={{
                            projectId: Session.getProjectId(),
                            fileType: CONSTANTS_FILE_TYPE.IMAGE,
                        }}
                        notRequred
                    />
                )}
                 </Form.Item>
        </Col>
        <Col span={24}>
        <InputByLanguage
                                fieldName={'imgDesc_'+index}
                                label={geti18nText('component.tabWithListAndDescription.list.item.img.desc')}
                                langJson={imgDescJson}
                                minHeight={'250px'}
                                notRequred
                                {...props}
                            />  
                            </Col>
        <Divider/>                    
    </Row>
    }

    const resetModalFields=()=>{
        //resetFields(["name", ])
    }

    const [type,setType]=useState(1)
    
    const onChangeType=(checked)=>{
        let tmpTab=data;
        
        if(checked.target.value===2) {
            if(tmpTab.find(d=>(d.id===selectedTabIndex)).advancedEdit !== undefined) {
                tmpTab= tmpTab.map(d=>{
                    if(d.id===selectedTabIndex){
                       d.advancedEdit={}
                       return d; 
                    }
                    else return d
                })
            }
                
        }
        tmpTab= tmpTab.map(d=>{
            if(d.id===selectedTabIndex){ 
                d.type=checked.target.value
                return d
            }  
            else return d; 
        })
        setData(tmpTab)
        setType(checked.target.value)
    }

    const showAdvancedEditValue=()=>{
        if (data && data.find(d=>(d.id.toString()===selectedTabIndex.toString())) && data.find(d=>(d.id.toString()===selectedTabIndex.toString())).advancedEdit && data.find(d=>(d.id.toString()===selectedTabIndex.toString())).advancedEdit[Session.getProjectDefaultLanguage()]) {
            let value= data.find(d=>(d.id.toString()===selectedTabIndex.toString())).advancedEdit[Session.getProjectDefaultLanguage()]
            return value
        }
        else { 
            return '' 
    }
    }

    

    const renderTabPane=(item, index)=>{
        return <> 
            <Row>
            <Radio.Group style={{marginBottom: 20}} onChange={onChangeType} value={type}>
                <Radio value={1}>{geti18nText('component.tabWithListAndDescription.type.list')}</Radio>
                <Radio value={2}>{geti18nText('component.tabWithListAndDescription.type.advanced')}</Radio>
            </Radio.Group>
            </Row>
            <InputByLanguage
                                fieldName={'tabName_'+item.id}
                                label={geti18nText('component.tabWithListAndDescription.tab.name')}
                                langJson={nameTabJson}
                                minHeight={'250px'}
                                {...props}
            />
           
            <Row>
            {data.find(d=>d.id === item.id).type ===1 &&<Button type='primary' style={{ marginBottom: '1em' }} onClick={(e) => {
                setSelectedTabIndex(item.id)
                setFormValues(null)
                setImageKeys(undefined);
                resetModalFields();
                setAddNew(true)}}>
            {geti18nText('component.tabWithListAndDescription.add.new.item')}
            </Button>}
           
        </Row>
        {data.find(d=>d.id === item.id).type ===1 &&<Table onRow={(record, rowIndex) => {
                    return {
                        onDoubleClick: (event) => {
                            onItemDoubleClick(record, index);
                        },
                    };
                }} rowKey={(record) => record.id} columns={columns} dataSource={item.listItems}  />}
        {data.find(d=>d.id === item.id).type ===2 && <div>
                    <Row gutter={24}>
                        <Col span={24}>
                            {Session.getProjectInfo().languages != null &&
                                Session.getProjectInfo().languages.map((value, index) => {
                                    const lang = Object.keys(value)[0];
                                    let color = '';
                                    if (lang == seletedLang) color = '#1890ff';
                                    return (
                                        <Button
                                            key={lang}
                                            onClick={() => {
                                                changeLang(lang);
                                            }}
                                            style={{
                                                color: color,
                                                borderColor: color,
                                                marginRight: '3px',
                                            }}
                                        >
                                            {lang}
                                        </Button>
                                    );
                                })}
                        </Col>
                    </Row>
                    <Row gutter={24} style={{ marginTop: '15px' }}>
                        <Col span={24}>
                            <React.Fragment>
                                <JoditEditor
                                    toolbar={true}
                                    ref={editor}
                                    value={showAdvancedEditValue()}
                                    config={config}
                                    tabIndex={1} // tabIndex of textarea
                                    onBlur={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                                    onChange={(newContent) => {}}
                                />
                            </React.Fragment>
                        </Col>
                    </Row>
                </div>}       
               
        </>
    }

    const onChangeImageTab=(active)=>{
        props.form.validateFieldsAndScroll((err, values) => {
            values = normalizeComponents(values);
            if(imageKeys){
                imageKeys.forEach((item)=>{
                    if(values["image_"+item.id] != undefined) {
                        item.image=values["image_"+item.id]
                        
                    }
                    else if(item.image) {
                        setFieldsValue({["image_"+item.id] : item.image} )
                    }

                    if(values["imgDesc_"+item.id] != undefined && Object.keys(values["imgDesc_"+item.id]).length>0 && Object.entries(values["imgDesc_"+item.id]).filter(([key, val])=> val !== undefined).length>0) {
                        item.desc=values["imgDesc_"+item.id]
                        
                    }
                    else if(item.desc) {
                        let imgValues={}
                        imgValues["imgDesc_"+item.id]=item.desc
                        imgValues=translationsToObject(imgValues,"imgDesc_"+item.id,item.desc)
                        setFieldsValue(imgValues )
                    }
                })
                setImageKeys(imageKeys)
                
            }
        })
        
    }

    const onChangeTab=(active)=>{
        props.form.validateFieldsAndScroll((err, values) => {
            values = normalizeComponents(values);
            let newData=data.map((item) => {
                if(item.id.toString() === selectedTabIndex.toString()) {
                    item.tabName=values["tabName_"+item.id]
                    return item
                } 
                else {
                    return item
                }
            })
            let tabNames={}
            newData.forEach((json)=>{
                if(json.tabName) {
                    tabNames["tabName_"+json.id]=json.tabName
                    tabNames=translationsToObject(tabNames,"tabName_"+json.id,json.tabName)
                }
                if(json.id.toString()===active.toString()) {
                    setType(json.type)
                }
                
            })
            setFieldsValue(tabNames)  
            setData(newData);
            setSelectedTabIndex(active);
            
              
            //saveComponent(false,false,newData)

        })
        
    }

    const save=(close, notification, tabs)=>{
        props.form.validateFieldsAndScroll((err, values) => {
            values = normalizeComponents(values);
            if (!err) {
                tabs.forEach((tab)=>{
                    if(values["tabName_"+tab.id]) {
                            tab.tabName=values["tabName_"+tab.id]
                    }
                    //if(tab.type===2 && componentValue)
                    
                }) 
                saveComponent(close,notification,tabs)
            }
        })
            
            
    }

    const onNew=()=>{
        setType(1)
    }
   

    return <React.Fragment>
        <Divider>{geti18nText('component.tabWithListAndDescription')}</Divider>
        <Form >
        <TabItems onNew={onNew} defaultField={{listItems: [], type:1}} form={props.form} slides={data} setSlides={setData} setActiveTab={setSelectedTabIndex} tabOnChange={onChangeTab} tabTitle={geti18nText('component.tabWithListAndDescription.tab')} renderItem={renderTabPane} />
            <Modal
                    visible={addNew}
                    title={geti18nText('component.tabWithListAndDescription.new')}
                    width={800}
                    okText={geti18nText('app.default.button.save')}
                    onCancel={closePopup}
                    onOk={()=>saveListItem()}
                    forceRender={true}
                    destroyOnClose
                   
                >
               
                   <Row>
                        <InputByLanguage
                                fieldName={'name'}
                                label={geti18nText('component.tabWithListAndDescription.list.item.name')}
                                langJson={nameJson}
                                minHeight={'250px'}
                                notRequred
                                {...props}
                            />
                        <InputByLanguage
                                fieldName={'title'}
                                label={geti18nText('component.tabWithListAndDescription.list.item.title')}
                                langJson={titleJson}
                                minHeight={'250px'}
                                notRequred
                                {...props}
                            />
                         <TabItems form={props.form} slides={imageKeys} tabOnChange={onChangeImageTab}  setSlides={setImageKeys} tabTitle={geti18nText('component.tabWithListAndDescription.tab.image')} renderItem={renderTabImage} />

                        <InputByLanguage
                                notRequred
                                fieldName={'description'}
                                label={geti18nText('component.tabWithListAndDescription.list.item.desc')}
                                langJson={descriptionJson}
                                minHeight={'250px'}
                                type="rteEditor"
                                {...props}
                            />
                        <InputByLanguage
                                fieldName={'rightTitleFirst'}
                                label={geti18nText('component.tabWithListAndDescription.list.item.right.title.first')}
                                langJson={titleRightJsonFirst}
                                minHeight={'250px'}
                                notRequred
                                {...props}
                            />    
                        <InputByLanguage
                                fieldName={'rightTitleSecond'}
                                label={geti18nText('component.tabWithListAndDescription.list.item.right.title.second')}
                                langJson={titleRightJsonSecond}
                                minHeight={'250px'}
                                notRequred
                                {...props}
                            />    
                        <InputByLanguage
                                fieldName={'rightSubtitle'}
                                label={geti18nText('component.tabWithListAndDescription.list.item.right.subtitle')}
                                langJson={subTitleRightJson}
                                minHeight={'250px'}
                                notRequred
                                
                                {...props}
                            />    
                        <InputByLanguage
                                fieldName={'rightDescription'}
                                label={geti18nText('component.tabWithListAndDescription.list.item.right.desc')}
                                langJson={descRightJson}
                                minHeight={'250px'}
                                notRequred
                                type="rteEditor"
                                {...props}
                            />   
                        <InputByLanguage
                                fieldName={'buttonText'}
                                label={geti18nText('component.tabWithListAndDescription.list.item.btn.text')}
                                langJson={buttonText}
                                minHeight={'250px'}
                                notRequred
                                {...props}
                            />    
                        <Form.Item>
                        {getFieldDecorator('selectedAsAction', {
                            valuePropName: 'checked',
                            initialValue: false,
                        })(
                            <Checkbox
                                onChange={(e) => {
                                    setSelectedAsAction(e);
                                }}
                            >
                                {geti18nText('component.tabWithListAndDescription.list.item.selected.as.special')}
                            </Checkbox>
                        )}
                        </Form.Item>
                        <InputByLanguage
                                fieldName={'specialText'}
                                label={geti18nText('component.tabWithListAndDescription.list.item.selected.special.text')}
                                langJson={specialTextJson}
                                minHeight={'250px'}
                                notRequred
                                {...props}
                            /> 
                        <Row>
                       <Form.Item>
                       {getFieldDecorator('linkType', { initialValue: linkType })(
                           <Radio.Group
                               onChange={(e) => {
                                   setLinkType(e.target.value);
                               }}
                           >
                               <Radio value={LINK_TYPE.WEBPAGE}>
                                   {geti18nText('component.calendar.link')}
                               </Radio>
                               <Radio value={LINK_TYPE.LINK}>
                                   {geti18nText('component.calendar.link.outside')}
                               </Radio>
                           </Radio.Group>
                       )}
                       </Form.Item>
                       {linkType == LINK_TYPE.LINK ? (
                       
                       <Row>
                       <Col span={12}>
                           <Form.Item label={geti18nText('component.imagelinks.edit.external_link')}>
                                   {getFieldDecorator('externalLink')(
                                       <Input />
                                   )}
                               </Form.Item>
                       </Col>
                    </Row>
                   ) : (
                       <Row>
                           <Col span={8}>
                               <Form.Item label={geti18nText('component.calendar.link.project')}>
                                   {getFieldDecorator('linkProject')(
                                       <SearchField
                                           onChange={(com) => onSelectComponentProject(com, props.index)}
                                           url={CONSTANTS_REQ.PROJECT.SEARCH}
                                           map={{ id: 'id', label: 'name' }}
                                           searchBy="name"
                                           translate={false}
                                           allowClear={false}
                                       />
                                   )}
                               </Form.Item>
                           </Col>
                           <Col span={8} offset={1}>
                               <Form.Item label={geti18nText('component.calendar.link.project.link')}>
                                   {getFieldDecorator('projectWebLink')(

                                       <SearchField
                                           url={CONSTANTS_REQ.WEBPAGE.SEARCH}
                                           map={{ id: 'id', label: 'name' }}
                                           searchBy="name"
                                           //disabled={!getFieldValue('linkProject')}
                                           translate={true}
                                           addedData={{ projectId: selectedProjectId, forMap: true }}
                                       />
                                   )}
                               </Form.Item>
                           </Col>
                       </Row>
                   )}
                    <Row>
                        <Form.Item label={geti18nText('component.tabWithListAndDescription.design.settings.background.color')}>
                            <Col span={8}>{getFieldDecorator('item_background_color')(<Input></Input>)}</Col>
                            <Col span={2}>
                                <ColorPicker
                                    value={itemBackgroundColor}
                                    onChange={(value) => {
                                        let data = {};
                                        const key = 'item_background_color';
                                        data[key] = value;
                                        props.form.setFieldsValue(data);
                                        setItemBackgroundColor(value);
                                    }}
                                ></ColorPicker>
                            </Col>
                        </Form.Item>
                    </Row>
                    <Row>
                        <Form.Item label={geti18nText('component.tabWithListAndDescription.design.settings.background.color.special')}>
                            <Col span={8}>{getFieldDecorator('item_background_color_special')(<Input></Input>)}</Col>
                            <Col span={2}>
                                <ColorPicker
                                    value={itemBackgroundColorSpecial}
                                    onChange={(value) => {
                                        let data = {};
                                        const key = 'item_background_color_special';
                                        data[key] = value;
                                        props.form.setFieldsValue(data);
                                        setItemBackgroundColorSpecial(value);
                                    }}
                                ></ColorPicker>
                            </Col>
                        </Form.Item>
                    </Row>
                    <Row>
                        <Form.Item label={geti18nText('component.tabWithListAndDescription.design.settings.background.color.btn')}>
                            <Col span={8}>{getFieldDecorator('item_background_color_btn')(<Input></Input>)}</Col>
                            <Col span={2}>
                                <ColorPicker
                                    value={itemBackgroundColorBtn}
                                    onChange={(value) => {
                                        let data = {};
                                        const key = 'item_background_color_btn';
                                        data[key] = value;
                                        props.form.setFieldsValue(data);
                                        setItemBackgroundColorBtn(value);
                                    }}
                                ></ColorPicker>
                            </Col>
                        </Form.Item>
                    </Row>
                   </Row>

                   </Row>
                </Modal>
                    <Row>
                        <Col span={24} style={{ textAlign: 'right', marginTop: '1rem' }}>
                            <Button style={{ marginRight: '1em' }} onClick={(e) => props.closePopup()}>
                                {geti18nText('app.default.button.cancel')}
                            </Button>
                            <Button style={{ marginRight: '1em' }} type="primary" onClick={(e) => save(false, true,data)}>
                                {geti18nText('app.default.button.save')}
                            </Button>
                            <Button
                                type="primary"
                                style={{ background: '#31b531', borderColor: '#31b531', textColor: 'white' }}
                                onClick={(e) => {
                                    save(true, true,data)
                                }}
                            >
                                {geti18nText('app.default.button.save.close')}
                            </Button>
                        </Col>
                    </Row>

        </Form>
    </React.Fragment>
});

const TabItems = React.forwardRef((props, ref) => {
    const { TabPane } = Tabs;
    const [lastKey, setLastKey] = useState(0);
    const [slides, setSlides] = useState(null);
    const [activeTab, setActiveTab] = useState('0');
    const [slidesKeys, setSlidesKeys] = useState([0]);
    const [slideToDelete, setSlideToDelete] = useState(null);
    const [confirmVisible, setConfirmVisible] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showOptionsModal, setShowOptionsModal] = useState(false);
    const [deletedSlides, setDeletedSlides] = useState([]);


    useEffect(()=>{
        if(props.slides) {
            
            setLastKey(props.slides[props.slides.length-1].id)
            setSlides(props.slides);
            setSlidesKeys(props.slides.map((s=>s.id)));
            if(props.setSlides){props.setSlides(props.slides)
            
            }

        }
        else {
            setSlides([{ id: 0 }]);
            setSlidesKeys([0]);
            if(props.setSlides)props.setSlides([{ id: 0 }]);
        }
    },[])

    useEffect(()=>{
        if(props.slides) {
            setLastKey(props.slides[props.slides.length-1].id)
            setSlides(props.slides);
            setSlidesKeys(props.slides.map((s=>s.id)));
            if(props.setSlides)props.setSlides(props.slides);
        }
    },[props.slides])

    const onEdit = (targetKey, action) => {
        if (action == 'add') {
            props.form.validateFieldsAndScroll((err, values) => {
                
                    let newId = lastKey;
                    newId++;
                    let arrSlides = slides.slice();
                    let arrKeys = slidesKeys.slice();
                    arrSlides.push({ id: newId, ...props.defaultField });
                    arrKeys.push(newId);
                    setSlidesKeys(arrKeys);
                    setSlides(arrSlides);
                    if(props.setSlides)props.setSlides(arrSlides);
                    setActiveTab('' + newId);
                    setLastKey(newId);
                    if(props.setActiveTab){
                        props.setActiveTab(newId)
                    }
                    if(props.onNew) props.onNew()
                
            });
        }
        if (action == 'remove') {
            if (slidesKeys.length > 1) {
                setConfirmVisible(true);
                setSlideToDelete(targetKey);
            }
        }
    };

    const deleteSlide = () => {
        if (slideToDelete) {
            var newArrKeys = slidesKeys.filter((key) => key != parseInt(slideToDelete));
            var newArr = slides.filter((video) => video.id != parseInt(slideToDelete));
            setSlides(newArr);
            if(props.setSlides)props.setSlides(newArr);
            setSlidesKeys(newArrKeys);

            if (activeTab == slideToDelete) {
                setActiveTab('' + newArrKeys[0]);
                if(props.setActiveTab){
                    props.setActiveTab(newArrKeys[0])
                }
            }
            setConfirmVisible(false);
            setSlideToDelete(null);
            let deleted = deletedSlides.slice();
            deleted.push(slideToDelete);
            setDeletedSlides(deleted);
        }
    };

    const tabOnChange = (activeKey) => {
        props.form.validateFieldsAndScroll((err, values) => {
                setActiveTab(activeKey);
                if(props.tabOnChange){
                    props.tabOnChange(activeKey)
                }
            
        });
    };
    
    return <React.Fragment><Row>
    <Col span={24}>
        <Popconfirm
            placement="rightTop"
            title={geti18nText('app.default.button.delete')}
            visible={confirmVisible}
            okText={geti18nText('app.default.button.yes')}
            cancelText={geti18nText('app.default.button.no')}
            onCancel={() => {
                setConfirmVisible(false);
            }}
            onConfirm={deleteSlide}
        >
            <span></span>
        </Popconfirm>
    </Col>
    </Row> <Tabs type="editable-card" onEdit={onEdit} onChange={tabOnChange} activeKey={activeTab} >
        {slides &&
                    slides.map((item, index) => (
                        <TabPane  tab={props.tabTitle + (index + 1)} key={item.id} destroyInactiveTabPane forceRender>
                            {props.renderItem(item, index)}
                        </TabPane>))}

        </Tabs>
        </React.Fragment>       
        
})


export default TabWithListAndDescriptionEdit;
