import { Button, Col, Row ,notification} from 'antd';
import React, { useEffect, useState } from 'react';
import { InputByLanguage,translationsToObject } from '../../../components/InputByLanguage';
import { CONSTANTS_REQ } from '../../../utils/Constants';
import { RESPONSE, requestPut } from '../../../utils/RequestResolver';
import { geti18nText } from '../../../utils/Session';
import { normalizeComponents } from '../../../utils/Utils';
import WebPageLinkList from '../webPageLinks/WebPageLinksList';
import { Spinner } from '../../../components/Spinner';

const WebPageLinks = (props) => {
    const [descrption, setDescription] = useState(undefined);
    const { getFieldDecorator, setFieldsValue,getFieldsValue } = props.form;
    const [formValues, setFormValues] = useState(undefined);
    const [fetching, setFetching] = useState(false);


    useEffect(()=>{
        setFetching(true)
        let json={}
        if(props.title) {
            json = translationsToObject(json, 'title', props.title);
            
        }
        
        if(props.description) {
            json = translationsToObject(json, 'description', props.description);
        } 
        setFetching(false)
        setFormValues(json)
    },[])

    useEffect(()=>{
        if(formValues) {
            setFieldsValue(formValues)
        }
    },[formValues])
    function showNotification(title, message, duration, type) {
        notification[type]({
            message: title,
            description: message,
            duration: duration,
        });
    }

    function okNotification() {
        showNotification(geti18nText('app.default.save.ok'), geti18nText('app.default.save.ok.desc'), 3, 'success');
    }

    function error(message) {
        showNotification(geti18nText('app.default.save.nok'), message, 3, 'error');
    }
    const saveDescription=()=>{
        props.form.validateFieldsAndScroll(async (err, values) => {
            values = normalizeComponents(values);   
            let res=await requestPut(CONSTANTS_REQ.WEBPAGE.UPDATE_DESCRIPTION, null, {
                id: props.pageID,
                description: values.description,
                title: values.title
            })
            if(res.status === RESPONSE.OK) {
                okNotification()
            }
            else {
                error(geti18nText('app.default.save.nok'))
            }
        })
        
    }
    if (fetching) {
        return <Spinner />
    } else {
        return<><Row gutter={24} type="flex" justify="space-between"  align="bottom">
        <Col span={16}>
            <InputByLanguage
                requred
                fieldName="title"
                langJson={descrption}
                label={geti18nText('webpage.edit.tab.page.list.title')}
                {...props}
            />

        </Col>
        <Col span={16}>
            <InputByLanguage
                requred
                fieldName="description"
                langJson={descrption}
                label={geti18nText('webpage.edit.tab.page.list.desc')}
                {...props}
            />

        </Col>
        
        <Col span={8}>
            <Button style={{marginBottom:25}} type="primary" key="saveDescription" onClick={()=>saveDescription()}>
            {geti18nText('webpage.edit.tab.page.list.save.desc')}
            </Button>
        </Col>
    </Row>
    <WebPageLinkList webPageId={props.pageID} /></>
    }
};

export default WebPageLinks;
