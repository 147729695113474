import React, { useState, useEffect, useRef, useImperativeHandle } from 'react';
import {
    Form,
    Row,
    Col,
    Button,
    Divider,
    Collapse,
    Tabs,
    InputNumber,
    Popconfirm,
    Input,
    Modal,
    Checkbox,
    Select,
    notification,
} from 'antd';
import { Session, geti18nText } from '../../utils/Session';
import { normalizeComponents } from '../../utils/Utils';
import { requestGet, requestPut, RESPONSE } from '../../utils/RequestResolver';
import { InputByLanguage, translationsToObject } from '../InputByLanguage';
import { Spinner } from '../Spinner';
import { CONSTANTS_REQ } from '../../utils/Constants';
import ConfiguratorGroupsItems from './ConfiguratorItemEdit';
import ConfiguratorTablePackages from './ConfiguratorTablePackages';
import ConfiguratorPackageEdit from './ConfiguratorPackageEdit';
import { getTranslated } from '../InputByLanguage';
import ConfiguratorGroupOptionsTable from './ConfiguratorGroupOptionsTable';
const { Option } = Select;
const ConfiguratorEdit = Form.create({ name: 'ConfiguratorEdit' })((props) => {
    const filtersRef = useRef();
    const groupsRef = useRef();
    const optionsRef = useRef();
    const configuratorTableRef = useRef();
    const { TabPane } = Tabs;
    const { Panel } = Collapse;
    const { getFieldDecorator, setFieldsValue } = props.form;
    const [fetching, setFetching] = useState(false);
    const [formValues, setFormValues] = useState(null);

    ///START
    const [filters, setFilters] = useState(null);
    const [filtersValues, setFiltersValues] = useState(null);
    const [filterSelect, setFilterSelected] = useState(null);
    const [groups, setGroups] = useState(null);
    const [groupsValues, setGroupsValues] = useState(null);
    const [groupsItems, setGroupsItems] = useState(null);
    const [groupsItemsTranslations, setGroupsItemsTranslations] = useState(null);
    const [refresh, setRefresh] = useState();
    const [options, setOptions] = useState(null);
    const [optionsValues, setOptionsValues] = useState(null);

    const [componentTitleJson, setComponentTitleJson] = useState(null);
    const [checkboxJson, setCheckboxJson] = useState(null);
    const [btnConfirmJson, setBtnConfirmJson] = useState(null);
    const [btnUnnecessarilyJson, setBtnUnnecessarilyJson] = useState(null);
    const [btnNecessarilyJson, setBtnNecessarilyJson] = useState(null);
    const [groupItemsFilter, setGroupItemsFilter] = useState(null);
    const [additionalHeaderJson, setAdditionalHeaderJson] = useState(null);
    const [priceDescriptionJson, setPriceDescriptionJson] = useState(null);
    const [mobileSubtitleJson, setMobileSubtitleJson] = useState(null);
    const [initConfTableValues, setInitConfTableValues] = useState(null);
    const [initAditionalOptionsConf, setInitAditionalOptionsConf] = useState(null);
    const [initGroupsOptionsConf, setInitGroupsOptionsConf] = useState(null);
    const [emails, setEmails] = useState(null);
    const [groupAdditionalOption, setGroupAdditionalOption] = useState();
    useEffect(() => {
        fetch();
        props.setModalWidth('90%');
    }, [props.visible]);

    useEffect(() => {
        props.form.resetFields();
        setFieldsValue(formValues);
    }, [formValues]);

    function fetch() {
        setFetching(true);
        requestGet(CONSTANTS_REQ.WEBPAGECOMPONENT.EDIT + '/' + props.id).then((result) => {
            setFetching(false);
            if (result.status === RESPONSE.OK) {
                setValues(result.data);
            }
        });
    }

    function setValues(component_data) {
        var json;
        var vidArray = [];
        if (component_data.json) {
            json = JSON.parse(component_data.json);
            console.log(json)
            if (json.filtersKeys) {
                for (const key of json.filtersKeys) {
                    vidArray.push({
                        id: key,
                        nameJson: json['filter_' + key],
                    });
                    json = translationsToObject(json, 'filter_' + key, json['filter_' + key]);
                    delete json['filter_' + key];
                }
                setFiltersValues(vidArray);
                setFilters(json.filtersKeys);
            }
            vidArray = [];

            if (json.optionsKeys) {
                for (const key of json.optionsKeys) {
                    vidArray.push({
                        id: key,
                        nameJson: json['option_name_' + key],
                    });
                    json = translationsToObject(json, 'option_name_' + key, json['option_name_' + key]);
                    delete json['option_name_' + key];
                }
                setOptionsValues(vidArray);
                setOptions(json.optionsKeys);
            }
            vidArray = [];

            if (json.groupsKeys) {
                for (const key of json.groupsKeys) {
                    vidArray.push({
                        id: key,
                        nameJson: json['group_name_' + key],
                    });
                    json = translationsToObject(json, 'group_name_' + key, json['group_name_' + key]);
                    delete json['group_name_' + key];
                }
                setGroupsValues(vidArray);
                setGroups(json.groupsKeys);
            }

            var translation = {};

            if (json.groupItemsKey) {
                for (const item of json.groupItemsKey) {
                    if (item.keys) {
                        for (const key of item.keys) {
                            translation['group_' + item.id + '_item_name_' + key] =
                                json['group_' + item.id + '_item_name_' + key];
                            translation['group_' + item.id + '_item_description_' + key] =
                                json['group_' + item.id + '_item_description_' + key];
                            translation['group_' + item.id + '_item_service_name_' + key] =
                                json['group_' + item.id + '_item_service_name_' + key];

                            translation['group_' + item.id + '_item_price_' + key] =
                                json['group_' + item.id + '_item_price_' + key];
                            delete json['group_' + item.id + '_item_name_' + key];
                            delete json['group_' + item.id + '_item_description_' + key];
                            delete json['group_' + item.id + '_item_service_name_' + key];
                            delete json['group_' + item.id + '_item_price_' + key];
                        }
                    }
                }

                setGroupsItemsTranslations(translation);
                setGroupsItems(json.groupItemsKey);
            }

            json = translationsToObject(json, 'component_title', json['component_title']);
            setComponentTitleJson(json.component_title);

            json = translationsToObject(json, 'checkbox_text', json['checkbox_text']);
            setCheckboxJson(json.checkbox_text);

            json = translationsToObject(json, 'confirm_button_text', json['confirm_button_text']);
            setBtnConfirmJson(json.confirm_button_text);

            json = translationsToObject(json, 'unnecessarily_button_text', json['unnecessarily_button_text']);
            setBtnUnnecessarilyJson(json.unnecessarily_button_text);
            json = translationsToObject(json, 'necessarily_button_text', json['necessarily_button_text']);
            setBtnNecessarilyJson(json.necessarily_button_text);
            json = translationsToObject(json, 'additional_header', json['additional_header']);
            setAdditionalHeaderJson(json.additional_header);
            json = translationsToObject(json, 'price_description', json['price_description']);
            setPriceDescriptionJson(json.price_description);
            json = translationsToObject(json, 'mobile_subtitle', json['mobile_subtitle']);
            setMobileSubtitleJson(json.mobile_subtitle);

            json = translationsToObject(json, 'quantity_lbl', json['quantity_lbl']);
            json = translationsToObject(json, 'form_title_lbl', json['form_title_lbl']);
            json = translationsToObject(json, 'form_name_lbl', json['form_name_lbl']);
            json = translationsToObject(json, 'form_address_lbl', json['form_address_lbl']);
            json = translationsToObject(json, 'form_email_lbl', json['form_email_lbl']);
            json = translationsToObject(json, 'form_phone_lbl', json['form_phone_lbl']);
            json = translationsToObject(json, 'error_modal_title', json['error_modal_title']);
            json = translationsToObject(json, 'error_modal_msg', json['error_modal_msg']);
            json = translationsToObject(json, 'onError', json.onError);
            json = translationsToObject(json, 'onSuccess', json.onSuccess);
            setEmails(json.emails);

            delete json['quantity_lbl'];
            delete json['form_title_lbl'];
            delete json['form_name_lbl'];
            delete json['form_address_lbl'];
            delete json['form_email_lbl'];

            delete json['form_phone_lbl'];
            delete json['error_modal_title'];
            delete json['error_modal_msg'];

            delete json['unnecessarily_button_text'];
            delete json['component_title'];
            delete json['checkbox_text'];
            delete json['confirm_button_text'];
            delete json['additional_header'];
            delete json['price_description'];
            delete json['mobile_subtitle'];
            delete json.onError;
            delete json.onSuccess;

            setInitConfTableValues(json['confTableValues']);
            delete json['confTableValues'];
            setInitAditionalOptionsConf(json['aditionalOptionsConf']);
            delete json['aditionalOptionsConf'];
            setGroupItemsFilter(json.groupItemsFilter);

            setInitGroupsOptionsConf(json['groupsOptionsConf']);
            setGroupAdditionalOption(json['groupAdditionalOption']);
            setFormValues(json);
        }
    }
    function showNotification(title, message, duration, type) {
        notification[type]({
            message: title,
            description: message,
            duration: duration,
        });
    }

    function okNotification() {
        showNotification(geti18nText('app.default.save.ok'), geti18nText('app.default.save.ok.desc'), 3, 'success');
    }

    function error(message) {
        showNotification(geti18nText('app.default.save.nok'), message, 3, 'error');
    }

    function save(e) {
        e.preventDefault();
        props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                const translationObj = groupsRef.current.getTranslations();
                if (translationObj != null) {
                    Object.keys(translationObj).forEach((key) => {
                        values[key] = translationObj[key];
                    });
                }
                values = normalizeComponents(values);
                values.emails = emails != null && emails;
                values.filtersKeys = filtersRef.current.getFiltersKey();
                values.groupsKeys = groupsRef.current.getGroupsKey();
                values.groupItemsKey = groupsRef.current.getGroupsItemsKey();
                values.groupItemsFilter = groupsRef.current.getGroupsItemResValues();
                //  values.optionsKeys = optionsRef.current.getOptionsKey();
                values.confTableValues = configuratorTableRef.current.getTableDataSource();
                //values.aditionalOptionsConf = optionsRef.current.getAdditionalOptionsConf();
                values.groupsOptionsConf = groupsRef.current.getGroupsOptionsConf();
                values.groupAdditionalOption = groupsRef.current.getGroupAdditionalOption();
                console.log('--->', groupsRef.current.getGroupAdditionalOption());

                requestPut(CONSTANTS_REQ.WEBPAGECOMPONENT.UPDATEJSON + '/' + props.id, null, {
                    id: props.id,
                    json: JSON.stringify(values),
                }).then((result) => {
                    if (result && result.status === RESPONSE.CREATED) {
                        console.log('okNotification');
                        okNotification();
                        props.closePopup();
                    } else {
                        error();
                        if (result && result.status === RESPONSE.BAD_REQUEST) {
                            if (result.data && result.data.field) {
                                switch (result.data.field) {
                                    case 'mandatory_fields_required':
                                        console.log(geti18nText('app.default.mandatory_fields_required'));
                                        return;
                                    default:
                                        break;
                                }
                            }
                        }
                        if (result.data && result.data.error) {
                            console.log(JSON.stringify(result.data.error));
                        }
                    }
                });
            }
        });
    }

    function onPanelChange(key) {
        console.log(key);
        setRefresh(new Date());
    }

    const changeEmails = (value) => {
        setEmails(value);
    };

    function getGroupNames() {
        const keys = groupsRef.current && groupsRef.current.getGroupsKey();
        var names = [];
        if (keys) {
            keys.forEach((id) => {
                const label = props.form.getFieldValue(
                    'translation-' + Session.getProjectDefaultLanguage() + '-group_name_' + id
                );
                names.push({ label: label, id: id });
            });
        }

        return names;
    }

    if (fetching) {
        return <Spinner />;
    } else {
        return (
            <React.Fragment>
                <Divider>{geti18nText('component.configurator')}</Divider>
                <Form>
                    <Collapse bordered={false} style={{ marginBottom: '15px' }} accordion onChange={onPanelChange}>
                        <Panel header={geti18nText('component.configurator.header.filters')} key="1" forceRender={true}>
                            <Row>
                                <Col span={24}>
                                    <ConfiguratorFilters
                                        packages={configuratorTableRef.current?.getTableDataSource()}
                                        ref={filtersRef}
                                        filters={filters}
                                        filtersValues={filtersValues}
                                        {...props}
                                    ></ConfiguratorFilters>
                                </Col>
                            </Row>
                        </Panel>
                        <Panel header={geti18nText('component.configurator.header.groups')} key="2" forceRender={true}>
                            <Row>
                                <Col span={24}>
                                    <ConfiguratorGroups
                                        ref={groupsRef}
                                        groups={groups}
                                        groupsValues={groupsValues}
                                        groupsItems={groupsItems}
                                        formValues={formValues}
                                        filters={filtersRef.current?.getFiltersKey()}
                                        refresh={refresh}
                                        groupItemsFilter={groupItemsFilter}
                                        translations={groupsItemsTranslations}
                                        initGroupsOptionsConf={initGroupsOptionsConf}
                                        groupAdditionalOption={groupAdditionalOption}
                                        {...props}
                                    ></ConfiguratorGroups>
                                </Col>
                            </Row>
                        </Panel>
                        <Panel
                            header={geti18nText('component.configurator.header.packages')}
                            key="3"
                            forceRender={true}
                        >
                            <Row>
                                <ConfiguratorTablePackages
                                    groupsRef={groupsRef}
                                    ref={configuratorTableRef}
                                    initConfTableValues={initConfTableValues}
                                    getGroupNames={getGroupNames}
                                    filtersValues={filtersValues}
                                ></ConfiguratorTablePackages>
                            </Row>
                        </Panel>
                        {/* <Panel
                            header={geti18nText('component.configurator.header.additional.options')}
                            key="4"
                            forceRender={true}
                        >
                            <Row>
                                <Col span={24}>
                                    <Row>
                                        <Col span={24}>
                                            <ConfiguratorAdditionalOptions
                                                ref={optionsRef}
                                                options={options}
                                                optionsValues={optionsValues}
                                                groupsRef={groupsRef}
                                                initAditionalOptionsConf={initAditionalOptionsConf}
                                                {...props}
                                            ></ConfiguratorAdditionalOptions>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Panel> */}
                        <Panel header={geti18nText('component.configurator.header.general')} key="5" forceRender={true}>
                            <Row gutter={24}>
                                <Col span={24}>
                                    <InputByLanguage
                                        notRequred
                                        fieldName={'component_title'}
                                        label={geti18nText('component.configurator.general.title')}
                                        notRequred={true}
                                        langJson={componentTitleJson}
                                        {...props}
                                    />
                                </Col>
                            </Row>

                            <Row gutter={24}>
                                <Col span={24}>
                                    <InputByLanguage
                                        notRequred
                                        fieldName={'necessarily_button_text'}
                                        label={geti18nText('component.configurator.general.necessarily.button')}
                                        notRequred={true}
                                        langJson={btnNecessarilyJson}
                                        {...props}
                                    />
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={24}>
                                    <InputByLanguage
                                        notRequred
                                        fieldName={'unnecessarily_button_text'}
                                        label={geti18nText('component.configurator.general.unnecessarily.button')}
                                        notRequred={true}
                                        langJson={btnUnnecessarilyJson}
                                        {...props}
                                    />
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={24}>
                                    <InputByLanguage
                                        notRequred
                                        fieldName={'additional_header'}
                                        label={geti18nText('component.configurator.general.additional.option.header')}
                                        notRequred={true}
                                        langJson={additionalHeaderJson}
                                        {...props}
                                    />
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={24}>
                                    <InputByLanguage
                                        notRequred
                                        fieldName={'quantity_lbl'}
                                        label={geti18nText('component.configurator.general.quantity.label')}
                                        notRequred={true}
                                        {...props}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <Form.Item label={geti18nText('component.configurator.general.currency')}>
                                        {getFieldDecorator('currency')(<Input disabled={false} />)}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <Form.Item label={geti18nText('component.configurator.general.unit')}>
                                        {getFieldDecorator('unit')(<Input />)}
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={24}>
                                <Col span={24}>
                                    <InputByLanguage
                                        notRequred
                                        fieldName={'price_description'}
                                        label={geti18nText('component.configurator.general.price.description')}
                                        notRequred={true}
                                        langJson={priceDescriptionJson}
                                        type="textArea"
                                        {...props}
                                    />
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={24}>
                                    <InputByLanguage
                                        notRequred
                                        fieldName={'mobile_subtitle'}
                                        label={geti18nText('component.configurator.general.mobile.subtitle')}
                                        notRequred={true}
                                        langJson={mobileSubtitleJson}
                                        {...props}
                                    />
                                </Col>
                            </Row>
                        </Panel>
                        <Panel header={geti18nText('component.configurator.header.form')} key="6" forceRender={true}>
                            <Row style={{ marginTop: '20px ' }}>
                                <Col span={17}>
                                    <Form.Item label={geti18nText('component.configurator.general.emails')}>
                                        {getFieldDecorator('emails')(
                                            <Select
                                                mode="tags"
                                                notFoundContent={null}
                                                style={{ width: '100%' }}
                                                onChange={changeEmails}
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={24}>
                                    <InputByLanguage
                                        notRequred
                                        fieldName={'form_title_lbl'}
                                        label={geti18nText('component.configurator.general.form.title')}
                                        notRequred={true}
                                        {...props}
                                    />
                                </Col>
                            </Row>

                            <Row gutter={24}>
                                <Col span={24}>
                                    <InputByLanguage
                                        notRequred
                                        fieldName={'form_name_lbl'}
                                        label={geti18nText('component.configurator.general.form.name.label')}
                                        notRequred={true}
                                        {...props}
                                    />
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={24}>
                                    <InputByLanguage
                                        notRequred
                                        fieldName={'form_address_lbl'}
                                        label={geti18nText('component.configurator.general.form.address.label')}
                                        notRequred={true}
                                        {...props}
                                    />
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={24}>
                                    <InputByLanguage
                                        notRequred
                                        fieldName={'form_email_lbl'}
                                        label={geti18nText('component.configurator.general.form.email.label')}
                                        notRequred={true}
                                        {...props}
                                    />
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={24}>
                                    <InputByLanguage
                                        notRequred
                                        fieldName={'form_phone_lbl'}
                                        label={geti18nText('component.configurator.general.form.phone.label')}
                                        notRequred={true}
                                        {...props}
                                    />
                                </Col>
                            </Row>

                            <Row gutter={24}>
                                <Col span={24}>
                                    <InputByLanguage
                                        notRequred
                                        fieldName={'checkbox_text'}
                                        label={geti18nText('component.configurator.general.confirm.checkbox')}
                                        notRequred={true}
                                        langJson={checkboxJson}
                                        {...props}
                                    />
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={24}>
                                    <InputByLanguage
                                        notRequred
                                        fieldName={'confirm_button_text'}
                                        label={geti18nText('component.configurator.general.confirm.button')}
                                        notRequred={true}
                                        nameJson={btnConfirmJson}
                                        {...props}
                                    />
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={24}>
                                    <InputByLanguage
                                        notRequred
                                        fieldName={'error_modal_title'}
                                        label={geti18nText('component.configurator.error.modal.title')}
                                        notRequred={true}
                                        {...props}
                                    />
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={24}>
                                    <InputByLanguage
                                        notRequred
                                        fieldName={'error_modal_msg'}
                                        label={geti18nText('component.configurator.error.modal.msg')}
                                        notRequred={true}
                                        {...props}
                                    />
                                </Col>
                            </Row>
                            <InputByLanguage
                                notRequred
                                fieldName={'onSuccess'}
                                label={geti18nText('component.configurator.general.onSuccess')}
                                {...props}
                            />
                            <InputByLanguage
                                notRequred
                                fieldName={'onError'}
                                label={geti18nText('component.configurator.general.onError')}
                                {...props}
                            />
                        </Panel>
                        <Row>
                            <Col span={24} style={{ textAlign: 'right', marginTop: '1rem' }}>
                                <Button style={{ marginRight: '1em' }} onClick={(e) => props.closePopup()}>
                                    {geti18nText('app.default.button.cancel')}
                                </Button>
                                <Button type="primary" onClick={(e) => save(e)}>
                                    {geti18nText('app.default.button.save')}
                                </Button>
                            </Col>
                        </Row>
                    </Collapse>
                </Form>
            </React.Fragment>
        );
    }
});

export default ConfiguratorEdit;

const ConfiguratorAdditionalOptions = React.forwardRef((props, ref) => {
    const { TabPane } = Tabs;
    const { getFieldDecorator, setFieldsValue } = props.form;
    const [lastKey, setLastKey] = useState(0);
    const [slides, setSlides] = useState([]);
    const [activeTab, setActiveTab] = useState('0');
    const [slidesKeys, setSlidesKeys] = useState([0]);
    const [deletedSlides, setDeletedSlides] = useState([]);
    const [slideToDelete, setSlideToDelete] = useState(null);
    const [confirmVisible, setConfirmVisible] = useState(false);
    const [additionalOptionsConf, setAditionalOptionsConf] = useState([]);

    useImperativeHandle(
        ref,
        () => ({
            getOptionsKey: () => {
                return slidesKeys;
            },
            getAdditionalOptionsConf: () => {
                return additionalOptionsConf;
            },
        }),
        [slidesKeys, additionalOptionsConf]
    );

    useEffect(() => {
        if (props.options == null) {
            setSlidesKeys([0]);
            setSlides([{ id: 0 }]);
        } else {
            var max_key = 0;
            for (const key of props.options) {
                max_key = key > max_key ? key : max_key;
            }
            setLastKey(max_key);
            setSlidesKeys(props.options);
            setSlides(props.optionsValues);
            setActiveTab('' + props.options[0]);
        }
    }, [props.options]);

    useEffect(() => {
        if (props.initAditionalOptionsConf) {
            setAditionalOptionsConf(props.initAditionalOptionsConf);
        }
    }, [props.initAditionalOptionsConf]);

    const onEdit = (targetKey, action) => {
        if (action == 'add') {
            props.form.validateFieldsAndScroll((err, values) => {
                if (!err) {
                    let newId = lastKey;
                    newId++;
                    let arrSlides = slides.slice();
                    let arrKeys = slidesKeys.slice();
                    arrSlides.push({ id: newId });
                    arrKeys.push(newId);
                    setSlidesKeys(arrKeys);
                    setSlides(arrSlides);
                    setActiveTab('' + newId);
                    setLastKey(newId);
                }
            });
        }
        if (action == 'remove') {
            if (slidesKeys.length > 1) {
                setConfirmVisible(true);
                setSlideToDelete(targetKey);
            }
        }
    };

    const tabOnChange = (activeKey) => {
        props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                setActiveTab(activeKey);
            }
        });
    };

    const deleteSlide = () => {
        if (slideToDelete) {
            var newArrKeys = slidesKeys.filter((key) => key != parseInt(slideToDelete));
            var newArrVideos = slides.filter((video) => video.id != parseInt(slideToDelete));
            setSlides(newArrVideos);
            setSlidesKeys(newArrKeys);

            if (activeTab == slideToDelete) {
                setActiveTab('' + newArrKeys[0]);
            }
            setConfirmVisible(false);
            setSlideToDelete(null);
            let deleted = deletedSlides.slice();
            deleted.push(slideToDelete);
            setDeletedSlides(deleted);
        }
    };

    function onPriceChange(value, id) {
        console.log('checked = ', value);
        updateResValues('price', value, id);
    }

    function updateResValues(key, value, id) {
        let elements = [...additionalOptionsConf];
        let currentElementIndex = elements.findIndex((x) => x.id === id);
        var item = null;
        if (currentElementIndex === -1) {
            var obj = { id: id };
            obj[key] = value;
            elements.push(obj);
        } else {
            item = elements[currentElementIndex];
            item[key] = value;
        }

        setAditionalOptionsConf(elements);
    }

    function onFilterChange(checkedValues, id) {
        updateResValues('checkedValues', checkedValues, id);
    }

    function getValueByKey(id, key, def) {
        const elements = [...additionalOptionsConf];
        const item = elements.find((x) => x.id === id);
        if (item != null) {
            const res = item[key];
            return res;
        }
        return def;
    }
    return (
        <>
            <Row>
                <Col span={24}>
                    <Popconfirm
                        placement="rightTop"
                        title={geti18nText('app.default.button.delete')}
                        visible={confirmVisible}
                        okText={geti18nText('app.default.button.yes')}
                        cancelText={geti18nText('app.default.button.no')}
                        onCancel={() => {
                            setConfirmVisible(false);
                        }}
                        onConfirm={deleteSlide}
                    >
                        <span></span>
                    </Popconfirm>
                </Col>
            </Row>
            <Tabs type="editable-card" onEdit={onEdit} onChange={tabOnChange} activeKey={activeTab}>
                {slides &&
                    slides.map((item, index) => (
                        <TabPane
                            tab={geti18nText('component.configurator.header.additional.option') + ' ' + (index + 1)}
                            key={item.id}
                            forceRender={true}
                        >
                            <Row gutter={24}>
                                <Col span={24}>
                                    <InputByLanguage
                                        notRequred
                                        fieldName={'option_name_' + item.id}
                                        label={geti18nText('component.configurator.additional.option.name')}
                                        notRequred={true}
                                        {...props}
                                    />
                                </Col>
                                <Col span={24} style={{ marginBottom: '10px' }}>
                                    <Form.Item
                                        hidden={false}
                                        label={geti18nText('component.configurator.additional.option.price')}
                                    >
                                        {getFieldDecorator('option_price_' + item.id)(
                                            <InputNumber
                                                min={0}
                                                precision={2}
                                                onChange={(value) => onPriceChange(value, item.id)}
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Form.Item>
                                    {getFieldDecorator('quantity_allow_' + item.id, {
                                        valuePropName: 'checked',
                                        initialValue: false,
                                    })(
                                        <Checkbox
                                            key={item.id}
                                            onChange={(value) =>
                                                updateResValues('quantity_allow', value.target.checked, item.id)
                                            }
                                        >
                                            {geti18nText('component.configurator.additional.allow.quantity')}
                                        </Checkbox>
                                    )}
                                </Form.Item>
                            </Row>
                            <Row span={24}>
                                {props.groupsRef.current && props.groupsRef.current.getFilterOptions() && (
                                    <Col span={24}>
                                        <Checkbox.Group
                                            key={item.id}
                                            value={getValueByKey(item.id, 'checkedValues', [])}
                                            options={props.groupsRef.current.getFilterOptions()}
                                            onChange={(values) => onFilterChange(values, item.id)}
                                        />
                                    </Col>
                                )}
                            </Row>
                        </TabPane>
                    ))}
            </Tabs>
        </>
    );
});

const ConfiguratorFilters = React.forwardRef((props, ref) => {
    const { TabPane } = Tabs;
    const { getFieldDecorator, setFieldsValue } = props.form;
    const [filters, setFilters] = useState();
    const [lastKey, setLastKey] = useState(0);
    const [slides, setSlides] = useState([]);
    const [activeTab, setActiveTab] = useState('0');
    const [slidesKeys, setSlidesKeys] = useState([0]);
    const [deletedSlides, setDeletedSlides] = useState([]);
    const [slideToDelete, setSlideToDelete] = useState(null);
    const [confirmVisible, setConfirmVisible] = useState(false);
    const [packages, setPackages] = useState([]);
    const [defaultPackage, setDefaultPackage] = useState(null);

    useImperativeHandle(
        ref,
        () => ({
            getFiltersKey: () => {
                return slidesKeys;
            },
        }),
        [slidesKeys]
    );

    useEffect(() => {
        setPackages(props.packages);
    }, [props.packages]);

    useEffect(() => {
        if (props.filters == null) {
            setSlidesKeys([0]);
            setSlides([{ id: 0 }]);
        } else {
            var max_key = 0;
            for (const key of props.filters) {
                max_key = key > max_key ? key : max_key;
            }
            setLastKey(max_key);
            setSlidesKeys(props.filters);
            setSlides(props.filtersValues);
            setActiveTab('' + props.filters[0]);
        }
    }, [props.filters]);

    const onEdit = (targetKey, action) => {
        if (action == 'add') {
            props.form.validateFieldsAndScroll((err, values) => {
                if (!err) {
                    let newId = lastKey;
                    newId++;
                    let arrSlides = slides.slice();
                    let arrKeys = slidesKeys.slice();
                    arrSlides.push({ id: newId });
                    arrKeys.push(newId);
                    setSlidesKeys(arrKeys);
                    setSlides(arrSlides);
                    setActiveTab('' + newId);
                    setLastKey(newId);
                }
            });
        }
        if (action == 'remove') {
            if (slidesKeys.length > 1) {
                setConfirmVisible(true);
                setSlideToDelete(targetKey);
            }
        }
    };

    const tabOnChange = (activeKey) => {
        props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                setActiveTab(activeKey);
            }
        });
    };

    const deleteSlide = () => {
        if (slideToDelete) {
            var newArrKeys = slidesKeys.filter((key) => key != parseInt(slideToDelete));
            var newArrVideos = slides.filter((video) => video.id != parseInt(slideToDelete));
            setSlides(newArrVideos);
            setSlidesKeys(newArrKeys);

            if (activeTab == slideToDelete) {
                setActiveTab('' + newArrKeys[0]);
            }
            setConfirmVisible(false);
            setSlideToDelete(null);
            let deleted = deletedSlides.slice();
            deleted.push(slideToDelete);
            setDeletedSlides(deleted);
        }
    };

    function onItemsSelect(item) {
        setDefaultPackage(item);
    }

    return (
        <>
            <Row>
                <Col span={24}>
                    <Popconfirm
                        placement="rightTop"
                        title={geti18nText('app.default.button.delete')}
                        visible={confirmVisible}
                        okText={geti18nText('app.default.button.yes')}
                        cancelText={geti18nText('app.default.button.no')}
                        onCancel={() => {
                            setConfirmVisible(false);
                        }}
                        onConfirm={deleteSlide}
                    >
                        <span></span>
                    </Popconfirm>
                </Col>
            </Row>
            <Tabs type="editable-card" onEdit={onEdit} onChange={tabOnChange} activeKey={activeTab}>
                {slides &&
                    slides.map((item, index) => (
                        <TabPane tab={'Filter ' + (index + 1)} key={item.id} forceRender={true}>
                            <Row gutter={24}>
                                <Col span={24}>
                                    <InputByLanguage
                                        notRequred
                                        fieldName={'filter_' + item.id}
                                        label={geti18nText('component.configurator.filter.name')}
                                        notRequred={true}
                                        {...props}
                                    />
                                </Col>
                            </Row>
                            {packages != undefined && (
                                <Row>
                                    <Form.Item label={geti18nText('component.configurator.filter.select.package')}>
                                        {getFieldDecorator('default_package_' + item.id)(
                                            <Select
                                                style={{ width: 350 }}
                                                onSelect={(value) => onItemsSelect(value.id)}
                                                value={defaultPackage}
                                                showSearch
                                                filterOption={(input, option) =>
                                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
                                                    0
                                                }
                                                allowClear={true}
                                            >
                                                {packages.map((value, index) => {
                                                    return (
                                                        <Option key={index} value={value.id}>
                                                            {getTranslated(value.package_name) +
                                                                ' - ' +
                                                                value.package_price +
                                                                ' €'}
                                                        </Option>
                                                    );
                                                })}
                                            </Select>
                                        )}
                                    </Form.Item>
                                </Row>
                            )}
                        </TabPane>
                    ))}
            </Tabs>
        </>
    );
});

const ConfiguratorGroups = React.forwardRef((props, ref) => {
    const { TabPane } = Tabs;
    const [items, setItems] = useState(null);
    const [lastKey, setLastKey] = useState(0);
    const [slides, setSlides] = useState(null);
    const [activeTab, setActiveTab] = useState('0');
    const [slidesKeys, setSlidesKeys] = useState([0]);
    const [deletedSlides, setDeletedSlides] = useState([]);
    const [slideToDelete, setSlideToDelete] = useState(null);
    const [confirmVisible, setConfirmVisible] = useState(false);
    const [filterOptions, setFilterOptions] = useState([]);
    const [seletedLang, setSeletedLang] = useState(Session.getProjectDefaultLanguage());
    const [showModal, setShowModal] = useState(false);
    const [showOptionsModal, setShowOptionsModal] = useState(false);
    const [groupItemsFilter, setGroupItemsFilter] = useState([]);
    const groupItemsTranslationValues = useRef();
    const [configuratorTableOptionsRef, setConfiguratorTableOptionsRef] = useState();
    //const configuratorTableOptionsRef = useRef();
    const [groupsOptionsConf, setGroupsOptionsConf] = useState([]);
    const [configuratorGroupAdditionalOption, setConfiguratorGroupAdditionalOption] = useState({});

    useEffect(() => {
        if (props.filters) {
            var filterArray = [];
            props.filters.forEach((filter) => {
                const label = props.form.getFieldValue('translation-' + seletedLang + '-filter_' + filter);
                filterArray.push({ label: label, value: filter });
            });
            setFilterOptions(filterArray);
        }
    }, [props.filters, props.refresh]);

    useImperativeHandle(
        ref,
        () => ({
            getGroupsKey: () => {
                return slidesKeys;
            },

            getGroupsItemsKey: () => {
                const arry = items ? [...items] : [];
                return arry;
            },
            getGroupsItemResValues: () => {
                const arry = groupItemsFilter ? [...groupItemsFilter] : [];
                return arry;
            },
            getTranslations: () => {
                return groupItemsTranslationValues.current;
            },

            getFilterOptions: () => {
                return filterOptions;
            },
            getGroupsOptionsConf: () => {
                return groupsOptionsConf;
            },
            getGroupAdditionalOption: () => {
                console.log(configuratorGroupAdditionalOption);
                return configuratorGroupAdditionalOption;
            },
        }),
        [slidesKeys, items, groupItemsFilter, filterOptions, groupsOptionsConf, configuratorGroupAdditionalOption]
    );

    useEffect(() => {
        if (props.initGroupsOptionsConf) {
            setGroupsOptionsConf(props.initGroupsOptionsConf);
        }
    }, [props.initGroupsOptionsConf]);

    useEffect(() => {
        if (props.groupAdditionalOption) {
            setConfiguratorGroupAdditionalOption(props.groupAdditionalOption);
        }
    }, [props.groupAdditionalOption]);

    useEffect(() => {
        groupItemsTranslationValues.current = {};
        if (props.groups == null) {
            setSlidesKeys([0]);
            setSlides([{ id: 0 }]);
        } else {
            let max_key = 0;

            for (const key of props.groups) {
                max_key = key > max_key ? key : max_key;
            }

            setSlides(props.groupsValues);
            setSlidesKeys(props.groups);
            setActiveTab('' + props.groups[0]);
            setLastKey(max_key);
        }
    }, [props.groups]);

    useEffect(() => {
        if (props.groupsItems) {
            setItems(props.groupsItems);
        }
    }, [props.groupsItems]);

    useEffect(() => {
        if (props.groupItemsFilter) {
            setGroupItemsFilter(props.groupItemsFilter);
        }
    }, [props.groupItemsFilter]);

    useEffect(() => {
        if (props.translations) {
            groupItemsTranslationValues.current = props.translations;
        }
    }, [props.translations]);

    const onEdit = (targetKey, action) => {
        if (action == 'add') {
            props.form.validateFieldsAndScroll((err, values) => {
                if (!err) {
                    let newId = lastKey;
                    newId++;
                    let arrSlides = slides.slice();
                    let arrKeys = slidesKeys.slice();
                    arrSlides.push({ id: newId });
                    arrKeys.push(newId);
                    setSlidesKeys(arrKeys);
                    setSlides(arrSlides);
                    setActiveTab('' + newId);
                    setLastKey(newId);
                }
            });
        }
        if (action == 'remove') {
            if (slidesKeys.length > 1) {
                setConfirmVisible(true);
                setSlideToDelete(targetKey);
            }
        }
    };

    const tabOnChange = (activeKey) => {
        props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                setActiveTab(activeKey);
            }
        });
    };
    const deleteSlide = () => {
        if (slideToDelete) {
            var newArrKeys = slidesKeys.filter((key) => key != parseInt(slideToDelete));
            var newArrVideos = slides.filter((video) => video.id != parseInt(slideToDelete));
            setSlides(newArrVideos);
            setSlidesKeys(newArrKeys);

            if (activeTab == slideToDelete) {
                setActiveTab('' + newArrKeys[0]);
            }
            setConfirmVisible(false);
            setSlideToDelete(null);
            let deleted = deletedSlides.slice();
            deleted.push(slideToDelete);

            //delete items
            var elements = [...items];
            elements = elements.filter((x) => x.id != slideToDelete);
            setItems(elements);

            setDeletedSlides(deleted);
        }
    };

    function closePopup() {
        setShowModal(false);
    }
    function updateResValues(key, value, id) {
        let elements = [...groupsOptionsConf];
        let currentElementIndex = elements.findIndex((x) => x.id === id);
        var item = null;
        if (currentElementIndex === -1) {
            var obj = { id: id };
            obj[key] = value;
            elements.push(obj);
        } else {
            item = elements[currentElementIndex];
            item[key] = value;
        }

        setGroupsOptionsConf(elements);
    }

    function onFilterChange(checkedValues, id) {
        updateResValues('checkedValues', checkedValues, id);
    }

    function getValueByKey(id, key, def) {
        const elements = [...groupsOptionsConf];
        const item = elements.find((x) => x.id === id);
        if (item != null) {
            const res = item[key];
            return res;
        }
        return def;
    }

    function updateItemsFromModal(json) {
        const elements = [...groupItemsFilter];
        var currentElementIndex = elements.findIndex((x) => x.group === json.groupId);

        var element = null;
        if (currentElementIndex === -1) {
            let values={ group: json.groupId, values: json.itemResValues }
            
            elements.push(values);
        } else {
            element = elements[currentElementIndex];
            element.values = json.itemResValues;
            
        }
        console.log("EL",elements);
        setGroupItemsFilter(elements);
        const elementsKeys = items ? [...items] : [];
        currentElementIndex = elementsKeys.findIndex((x) => x.id === json.groupId);
        var item = null;
        if (currentElementIndex === -1) {
            elementsKeys.push({ id: json.groupId, keys: json.groupsItemsKey });
        } else {
            item = elementsKeys[currentElementIndex];
            item.keys = json.groupsItemsKey;
        }

        setItems(elementsKeys);

        closePopup();
        const translationArray = groupItemsTranslationValues.current;
        Object.keys(json.formValues).forEach((key) => {
            translationArray[key] = json.formValues[key];
        });
        groupItemsTranslationValues.current = translationArray;
    }

    return (
        <>
            <Row>
                <Col span={24}>
                    <Popconfirm
                        placement="rightTop"
                        title={geti18nText('app.default.button.delete')}
                        visible={confirmVisible}
                        okText={geti18nText('app.default.button.yes')}
                        cancelText={geti18nText('app.default.button.no')}
                        onCancel={() => {
                            setConfirmVisible(false);
                        }}
                        onConfirm={deleteSlide}
                    >
                        <span></span>
                    </Popconfirm>
                </Col>
            </Row>
            <Tabs type="editable-card" onEdit={onEdit} onChange={tabOnChange} activeKey={activeTab}>
                {slides &&
                    slides.map((item, index) => (
                        <TabPane
                            tab={geti18nText('component.configurator.header.group') + ' ' + (index + 1)}
                            key={item.id}
                            forceRender={true}
                        >
                            <Row gutter={24}>
                                <Col span={24}>
                                    <InputByLanguage
                                        notRequred
                                        fieldName={'group_name_' + item.id}
                                        label={geti18nText('component.configurator.group.name')}
                                        notRequred={true}
                                        {...props}
                                    />
                                </Col>
                            </Row>
                            <Button style={{ marginRight: '1em' }} onClick={(e) => setShowModal(true)}>
                                {geti18nText('component.configurator.header.items.edit.btn')}
                            </Button>
                            <Button style={{ marginRight: '1em' }} onClick={(e) => setShowOptionsModal(true)}>
                                {geti18nText('component.configurator.header.options.edit.btn')}
                            </Button>
                            <Row span={24} style={{ marginTop: '1em' }}>
                                {filterOptions && (
                                    <Col span={24}>
                                        <Checkbox.Group
                                            key={item.id}
                                            value={getValueByKey(item.id, 'checkedValues', [])}
                                            options={filterOptions}
                                            onChange={(values) => onFilterChange(values, item.id)}
                                        />
                                    </Col>
                                )}
                            </Row>
                        </TabPane>
                    ))}
            </Tabs>
            <Modal
                visible={showOptionsModal}
                onCancel={() => setShowOptionsModal(false)}
                keyboard={false}
                maskClosable={false}
                width={1200}
                footer={[]}
                title={geti18nText('component.configurator.option.header.title')}
                destroyOnClose={true}
            >
                <ConfiguratorGroupOptionsTable
                    data={configuratorGroupAdditionalOption[activeTab]}
                    updateItemsFromModal={(data) => {
                        let json = configuratorGroupAdditionalOption;
                        // if (json[activeTab] != null) {
                        //     let item = json[activeTab];
                        //     item['items'] = data;
                        //     json[activeTab] = item;
                        // } else {
                        json[activeTab] = data;
                        //}
                        setConfiguratorGroupAdditionalOption(json);
                        setShowOptionsModal(false);
                    }}
                ></ConfiguratorGroupOptionsTable>
            </Modal>

            <Modal
                visible={showModal}
                onCancel={closePopup}
                keyboard={false}
                maskClosable={false}
                width={1200}
                footer={[]}
                destroyOnClose={true}
            >
                <ConfiguratorGroupsItems
                    index={parseInt(activeTab)}
                    filters={props.filters}
                    filterOptions={filterOptions}
                    key={new Date()}
                    visible={showModal}
                    updateItemsFromModal={updateItemsFromModal}
                    items={items}
                    groupsValues={props.groupsValues}
                    formValues={props.formValues}
                    groupItemsFilter={groupItemsFilter}
                    groupItemsTranslationValues={groupItemsTranslationValues.current}
                ></ConfiguratorGroupsItems>
            </Modal>
        </>
    );
});
