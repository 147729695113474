import React, { useEffect, useState } from 'react';
import { Icon } from 'antd';
import { DataTable, getColumnSearch, getColumnSearchOption } from '../../../components/DataTable';
import AccountEdit from './AccountEdit';
import { CONSTANTS_REQ } from '../../../utils/Constants';
import { Session, ENUMS, geti18nText, hasAnyRole } from '../../../utils/Session';
import { getTranslated } from '../../../components/InputByLanguage';
import { getDefaultMiddleware } from '@reduxjs/toolkit';
import { Spinner } from '../../../components/Spinner';
const AccountList = (props) => {
    const [webPageId, setWebPageId] = useState(props.webPageId);

    function setDefaultFilterValue() {
        return [{ field: 'active', condition: 'equals_bool', value: 1 }];
    }

    useEffect(() => {
        setWebPageId(props.webPageId);
    }, [props.webPageId]);

    function getData() {
        let data = {};
        if (props.webPageId != null) {
            data.webPageId = webPageId;
        }
        return data;
    }
    return webPageId == 0 ? (
        <Spinner />
    ) : (
        <DataTable
            headerTitle={geti18nText('users.table.header')}
            url={CONSTANTS_REQ.USER.LIST_PER_PAGE}
            addNewButtonText={geti18nText('users.table.add')}
            showRecordModal={true}
            modalComponent={AccountEdit}
            setDefaultFilterValue={setDefaultFilterValue}
            addedData={getData()}
            hideButtons={webPageId != undefined}
            columns={[
                {
                    title: geti18nText('users.table.column.id'),
                    dataIndex: 'id',
                    width: '10%',
                    sorter: (a, b) => {},
                    ...getColumnSearch('number'),
                },
                {
                    title: geti18nText('users.table.column.last_name'),
                    dataIndex: 'lastName',
                    sorter: (a, b) => {},
                    ...getColumnSearch('string'),
                },

                {
                    title: geti18nText('users.table.column.first_name'),
                    //dataIndex: 'user1.firstName',
                    dataIndex: 'firstName',
                    sorter: (a, b) => {},
                    ...getColumnSearch('string'),
                },
                /* {
                    title: geti18nText('users.table.column.role'),
                    dataIndex: 'authority',
                    sorter: (a, b) => {},
                    ...getColumnSearch('string'),
                },*/
                {
                    title: geti18nText('users.table.column.project'),
                    dataIndex: 'project.name',
                    sorter: (a, b) => {},
                    ...getColumnSearch('string'),
                },
                {
                    title: geti18nText('users.table.column.web_page'),
                    dataIndex: 'webPage.name',
                    sorter: (a, b) => {},
                    ...getColumnSearch('string'),
                    render: (text, record) => {
                        return <div>{getTranslated(text)}</div>;
                    },
                },
                {
                    title: geti18nText('users.table.column.active'),
                    dataIndex: 'active',
                    width: '10%',
                    render: (text, record) => {
                        if (record.active) {
                            return <Icon type="check" style={{ color: 'green' }} />;
                        } else {
                            return <Icon type="close" style={{ color: 'red' }} />;
                        }
                    },
                    ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', setDefaultFilterValue()[0]['value']),
                    defaultFilteredValue: setDefaultFilterValue(),
                },
            ]}
        />
    );
};

export default AccountList;
