import React, { useState, useEffect } from 'react';
import { Menu, Icon, Layout, Input, Avatar } from 'antd';
import { withRouter, useHistory } from 'react-router-dom';
import { applicationLogo, LogoRow } from './AdminMainMenu';
import { geti18nText, hasAnyRole, Session } from '../../utils/Session';
import { CONSTANTS_FILE_TYPE, CONSTANTS_REQ, UPLAD_CONTENT_FOLDER } from '../../utils/Constants';
import { requestGet, requestPost, requestPut, RESPONSE } from '../../utils/RequestResolver';
import { selectFolder, selectFile, setRefreshFolder } from '../../slices/filesSlices';
import { selectProject } from '../../slices/projectSlices';
import { useDispatch, useSelector } from 'react-redux';
import { setProjectLanguages } from '../../utils/Utils';
import { getTranslated } from '../../components/InputByLanguage';
const { SubMenu } = Menu;

const MENU = [
    {
        key: 'menu.webPage',
        path: '/news',
        icon: 'cloud-server',
        i18n: 'menu.news',
    },
];

export const LocalUserMenu = withRouter((props) => {
    const { Sider } = Layout;
    const { Search } = Input;
    const [logo, setLogo] = useState(undefined);
    const [selectedKeys, setSelectedKeys] = useState([]);
    const [_menu, setMenu] = useState([]);
    const [webProject, setWebProject] = useState([]);
    const [collapsed, setCollapsed] = useState(false);
    let history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        let list = [];
        Session.setProject({});
        dispatch(selectProject({}));
        if (hasAnyRole(['ROLE_LOCAL_USER'])) {
            if (hasAnyRole(['ROLE_MOBILE_APP_DOWNLOAD'])) {
                MENU.push({
                    i18n: 'menu.mobileApp',
                    key: 'MOBILEAPP',
                    path: '/mobileApp',
                    icon: 'mobile',
                });
            }
            setMenu(MENU);
            list = MENU;
            history.push('/news');
            setSelectedKeys(findKey(MENU, undefined, props.location.pathname));
            setProjectForLocalCustomer();
        }
        if (hasAnyRole(['ROLE_CONTENT_UPLOAD'])) {
            setProjectTree(list);
        }
        setLogo(applicationLogo(logo));
    }, []);

    function setProjectTree(list) {
        requestGet(CONSTANTS_REQ.PROJECT.FORUPLOADUSER + '/' + Session.getUser().id).then((result) => {
            if (result.status === RESPONSE.OK) {
                if (Array.isArray(result.data)) {
                    let projects = [];
                    for (const element of result.data) {
                        if (element.active == false) {
                            continue;
                        }
                        projects.push({
                            title: element.name,
                            key: 'PROJECT-' + element.id,
                            path: '/' + element.id,
                            icon: 'compass',
                            project: element,
                        });
                    }
                    if (hasAnyRole(['ROLE_MOBILE_APP_DOWNLOAD'])) {
                        projects.push({
                            i18n: 'menu.mobileApp',
                            key: 'MOBILEAPP',
                            path: '/mobileApp',
                            icon: 'mobile',
                        });
                        MENU.push({
                            i18n: 'menu.mobileApp',
                            key: 'MOBILEAPP',
                            path: '/mobileApp',
                            icon: 'mobile',
                        });
                    }
                    list = list.concat(projects);
                    setMenu(list);
                    if (!hasAnyRole(['ROLE_LOCAL_USER'])) {
                        if (projects.length > 0) {
                            setSelectedKeys(projects[0].key);
                            checkIfFolderExist(projects[0].project);
                        } else {
                            selectProjectFolder(null);
                            history.push('/');
                        }
                    }
                }
            }
        });
    }

    function setProjectForLocalCustomer() {
        if (Session.getUser().id) {
            requestGet(CONSTANTS_REQ.PROJECT.FORLOCALUSER + '/' + Session.getUser().id).then((result) => {
                if (result.status === RESPONSE.OK) {
                    if (typeof result.data === 'object') {
                        setWebProject(result.data);
                        Session.setProject(result.data);
                        Session.getProjectInfo().webPage.id = result.data.webPage.id;
                        dispatch(
                            selectProject({
                                projectId: result.data.id,
                                projectName: getTranslated(result.data.name),
                                themeShortName:
                                    result.data.theme && result.data.theme.shortName ? result.data.theme.shortName : '',
                            })
                        );
                        setProjectLanguages(result.data.id);
                    }
                }
            });
        }
    }

    function generateMenu(menus) {
        let retValue = [];
        let menu;
        for (menu of menus) {
            let title = menu.i18n ? geti18nText(menu.i18n) : menu.title;
            if (menu.role == undefined || hasAnyRole(menu.role)) {
                if (menu.submenu) {
                    retValue.push(
                        <SubMenu
                            key={menu.key}
                            onTitleClick={menuSelected}
                            title={
                                <span>
                                    <Icon type={menu.icon} />
                                    {title}
                                </span>
                            }
                        >
                            {title}
                        </SubMenu>
                    );
                } else {
                    retValue.push(
                        <Menu.Item key={menu.key}>
                            <Icon type={menu.icon} />
                            {title}
                        </Menu.Item>
                    );
                }
            }
        }
        return retValue;
    }

    function menuSelected(selected) {
        let project = _menu.find((x) => x.key == selected.key).project;
        if (project != null) {
            setSelectedKeys(selected.key);
            checkIfFolderExist(project);
        } else {
            setSelectedKeys(findKey(MENU, selected.key));
            Session.setProject(webProject);
            dispatch(
                selectProject({
                    projectId: webProject.id,
                    projectName: getTranslated(webProject.name),
                })
            );
        }
    }

    const selectProjectFolder = (project) => {
        dispatch(
            selectFolder({
                folderPath: project ? '/' + UPLAD_CONTENT_FOLDER : '/',
            })
        );
        Session.setProject(project ? project : {});
        if (project != null) {
            dispatch(
                selectProject({
                    projectId: project.id,
                    projectName: project.name,
                })
            );
        }

        history.push('/directory');

        dispatch(setRefreshFolder());
    };

    const createNewUploadFolder = (project) => {
        requestPost(CONSTANTS_REQ.DIRECTORY.TREE, undefined, {
            id: project.id,
            name: UPLAD_CONTENT_FOLDER,
        }).then((result) => {
            selectProjectFolder(project);
        });
    };

    const checkIfFolderExist = (project) => {
        requestGet(CONSTANTS_REQ.DIRECTORY.TREE + '/' + project.id).then((result) => {
            if (result.status === RESPONSE.OK && result.data) {
                let folder = result.data.find(
                    (x) => x.type == 'DIRECTORY' && x.relativePath == '/' + UPLAD_CONTENT_FOLDER
                );
                if (folder == null) {
                    createNewUploadFolder(project);
                } else {
                    selectProjectFolder(project);
                }
            } else {
                createNewUploadFolder(project);
            }
        });
    };

    function findKey(menus, key, path = undefined) {
        let menu;
        for (menu of menus) {
            if (menu.submenu) {
                let submenuMatch = findKey(menu.submenu, key, path);
                if (submenuMatch) {
                    return [menu.key, ...submenuMatch];
                } else if (key && menu.key === key) {
                    return [menu.key];
                }
            } else if (key && menu.key === key) {
                props.history.push(menu.path);
                return [menu.key];
            } else if (path && menu.path === path) {
                return [menu.key];
            }
        }
        return undefined;
    }

    return (
        <React.Fragment>
            {!collapsed && <LogoRow logo={logo} />}

            <Sider
                width={330}
                className="sider"
                breakpoint="md"
                collapsedWidth="0"
                onCollapse={(collapsed) => {
                    setCollapsed(collapsed);
                }}
                theme={'dark'}
                style={{
                    // overflow: 'auto',
                    height: logo ? 'calc(100vh - 89px)' : '100vh',
                    position: 'fixed',
                    left: 0,
                    marginTop: logo && '89px',
                    paddingTop: logo && '0px',
                    zIndex: 3000,
                }}
            >
                {_menu && (
                    <Menu
                        style={{ width: '320px' }}
                        mode="inline"
                        className="menu thin-scrollbar"
                        onClick={menuSelected}
                        selectedKeys={selectedKeys}
                        openKeys={selectedKeys}
                    >
                        {generateMenu(_menu)}
                    </Menu>
                )}
            </Sider>
        </React.Fragment>
    );
});
