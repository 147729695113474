import React, { useState, useEffect } from 'react';
import { Form, Row, Col, Input, Button, Divider, Tabs, Popconfirm, notification } from 'antd';
import { Session, geti18nText } from '../../utils/Session';
import { normalizeComponents } from '../../utils/Utils';
import { requestGet, requestPut, RESPONSE } from '../../utils/RequestResolver';
import { CONSTANTS_FILE_TYPE, CONSTANTS_REQ } from '../../utils/Constants';
import { FileUpload } from '../fileUpload/FileUpload';
import { InputByLanguage, translationsToObject } from '../InputByLanguage';
import { Spinner } from '../../components/Spinner';
import { SearchField } from '../../components/Search';
import { ColorPicker } from '../../components/ColorPicker';
const BtcCircleEdit = Form.create({ name: 'VideoBlockEdit' })((props) => {
    const { getFieldDecorator, setFieldsValue } = props.form;
    const [fetching, setFetching] = useState(false);
    const [tabKeys, setTabKeys] = useState([0, 1, 2, 3, 4, 5]);
    const [tabs, setTabs] = useState(setTabArray());
    const [formValues, setFormValues] = useState(null);
    const [tabsKey, setTabsKey] = useState(Date.now());
    const { TabPane } = Tabs;
    useEffect(() => {
        fetch();
    }, [props.visible]);

    useEffect(() => {
        props.form.resetFields();
        setFieldsValue(formValues);
    }, [formValues]);

    function fetch() {
        setFetching(true);
        requestGet(CONSTANTS_REQ.WEBPAGECOMPONENT.EDIT + '/' + props.id).then((result) => {
            setFetching(false);
            if (result.status === RESPONSE.OK) {
                setValues(result.data);
            }
        });
    }
    
    function showNotification(title, message, duration, type) {
        notification[type]({
            message: title,
            description: message,
            duration: duration,
        });
    }

    function okNotification() {
        showNotification(geti18nText('app.default.save.ok'), geti18nText('app.default.save.ok.desc'), 3, 'success');
    }

    function error(message) {
        showNotification(geti18nText('app.default.save.nok'), message, 3, 'error');
    } 

    function setValues(component_data) {
        let tabArray = [];
        var json;
        if (component_data.json) {
            json = JSON.parse(component_data.json);

            for (const key of json.tabKeys) {
                tabArray.push({
                    id: key,
                    titleLangJson: json['title_' + key],
                    subtitleLangJson: json['subtitle_' + key],
                    textLangJson: json['text_' + key],
                });
                json = translationsToObject(json, 'title_' + key, json['title_' + key]);
                delete json['title_' + key];
                json = translationsToObject(json, 'subtitle_' + key, json['subtitle_' + key]);
                delete json['subtitle_' + key];
                json = translationsToObject(json, 'text_' + key, json['text_' + key]);
                delete json['text_' + key];
            }

            delete json['tabKeys'];
            setTabs(tabArray);
            setFormValues(json);
        }
    }

    function setTabArray() {
        let tabArray = [];
        for (const key of [0, 1, 2, 3, 4, 5]) {
            tabArray.push({ id: key, titleLangJson: null, subtitleLangJson: null, textLangJson: null });
        }
        return tabArray;
    }

    function save(e, close) {
        e.preventDefault();
        props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                values = normalizeComponents(values);
                //  if (values.background_image) values.background_image = normalizeFileUpload('background_image');
                if (values.logo_image) values.logo_image = normalizeFileUpload('logo_image');
                values.tabKeys = tabKeys;

                requestPut(CONSTANTS_REQ.WEBPAGECOMPONENT.UPDATEJSON + '/' + props.id, null, {
                    id: props.id,
                    json: JSON.stringify(values),
                }).then((result) => {
                    if (result && result.status === RESPONSE.CREATED) {
                        console.log('okNotification');
                        okNotification();
                        close && props.closePopup();
                    } else {
                        error();
                        if (result && result.status === RESPONSE.BAD_REQUEST) {
                            if (result.data && result.data.field) {
                                switch (result.data.field) {
                                    case 'mandatory_fields_required':
                                        console.log(geti18nText('app.default.mandatory_fields_required'));
                                        return;
                                    default:
                                        break;
                                }
                            }
                        }
                        if (result.data && result.data.error) {
                            console.log(JSON.stringify(result.data.error));
                        }
                    }
                   
                });
            }
        });
    }

    function normalizeFileUpload(field) {
        let file = props.form.getFieldsValue([field])[field];
        if (file instanceof Array) {
            file = file[0];
        }
        return file.id ? file : { file_path: file.file_path, name: file.name };
    }

    if (fetching) {
        return <Spinner />;
    } else {
        return (
            <React.Fragment>
                <Divider>{geti18nText('component.btccircle')}</Divider>
                <Form>
                    <Row style={{ marginTop: '20px ' }}>
                        {/* <Col span={24}>
                            <Form.Item label={geti18nText('component.btccircle.edit.background')}>
                                {getFieldDecorator(
                                    'background_image',
                                    {}
                                )(
                                    <FileUpload
                                        fileType={CONSTANTS_FILE_TYPE.IMAGE}
                                        map={{ id: 'id', label: 'name' }}
                                        searchBy="name"
                                        disabled={false}
                                        addedData={{
                                            projectId: Session.getProjectId(),
                                            fileType: CONSTANTS_FILE_TYPE.IMAGE,
                                        }}
                                    />
                                )}
                            </Form.Item>
                        </Col> */}
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Form.Item label={geti18nText('component.btccircle.edit.logo')}>
                                {getFieldDecorator(
                                    'logo_image',
                                    {}
                                )(
                                    <FileUpload
                                        fileType={CONSTANTS_FILE_TYPE.IMAGE}
                                        map={{ id: 'id', label: 'name' }}
                                        searchBy="name"
                                        disabled={false}
                                        addedData={{
                                            projectId: Session.getProjectId(),
                                            fileType: CONSTANTS_FILE_TYPE.IMAGE,
                                        }}
                                    />
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row key={tabsKey}>
                        <Col span={24}>
                            <Tabs>
                                {tabs.map((tab, index) => (
                                    <TabPane
                                        tab={geti18nText('component.btccircle.tab.' + index)}
                                        key={index}
                                        forceRender={true}
                                    >
                                        <EditTab
                                            index={index}
                                            tileLangJson={tab.titleLangJson}
                                            subtileLangJson={tab.subtitleLangJson}
                                            textLangJson={tab.textLangJson}
                                            {...props}
                                        />
                                    </TabPane>
                                ))}
                            </Tabs>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24} style={{ textAlign: 'right', marginTop: '1rem' }}>
                            <Button style={{ marginRight: '1em' }} onClick={(e) => props.closePopup()}>
                                {geti18nText('app.default.button.cancel')}
                            </Button>
                            <Button style={{ marginRight: '1em' }} type="primary" onClick={(e) => save(e, false)}>
                                {geti18nText('app.default.button.save')}
                            </Button>
                            <Button
                                type="primary"
                                style={{ background: '#31b531', borderColor: '#31b531', textColor: 'white' }}
                                onClick={(e) => {
                                    save(e, true);
                                }}
                            >
                                {geti18nText('app.default.button.save.close')}
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </React.Fragment>
        );
    }
});

const EditTab = (props) => {
    return (
        <>
            <Row gutter={24}>
                <Col span={24}>
                    <InputByLanguage
                        notRequred
                        fieldName={'title_' + props.index}
                        label={geti18nText('component.btccircle.edit.title')}
                        langJson={props.tileLangJson}
                        {...props}
                    />
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={24}>
                    <InputByLanguage
                        notRequred
                        fieldName={'subtitle_' + props.index}
                        label={geti18nText('component.btccircle.edit.subtitle')}
                        langJson={props.subtileLangJson}
                        {...props}
                    />
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={24}>
                    <InputByLanguage
                        notRequred
                        fieldName={'text_' + props.index}
                        type={'rteEditor'}
                        label={geti18nText('component.btccircle.edit.description')}
                        langJson={props.textLangJson}
                        {...props}
                    />
                </Col>
            </Row>
        </>
    );
};

export default BtcCircleEdit;
