import hr from 'antd/es/locale/hr_HR';
import en from 'antd/es/locale/en_GB';
import sr from 'antd/es/locale/sr_RS';
import moment from 'moment';
import { CONSTANTS_REQ } from './Constants';
import { requestGet, RESPONSE } from './RequestResolver';
import { Session } from './Session';
import { CONSTANTS_STORAGE, DEFAULTS } from './Constants';
import { notification } from 'antd';
import {  useEffect } from 'react';


export const cloneObject = (object) => {
    return JSON.parse(JSON.stringify(object));
};

export const saveDefaults = (newDefaults) => {
    let saveDefaults = cloneObject(DEFAULTS);
    for (var key in newDefaults) {
        if (newDefaults.hasOwnProperty(key) && saveDefaults.hasOwnProperty(key)) {
            saveDefaults[key] = newDefaults[key];
        } else {
            saveDefaults = { ...saveDefaults, ...newDefaults };
        }
    }

    localStorage.setItem(CONSTANTS_STORAGE.DEFAULTS, JSON.stringify(saveDefaults));
};

export const loadDefaults = () => {
    const defaults = load(CONSTANTS_STORAGE.DEFAULTS);
    if (defaults) {
        return defaults;
    } else {
        return DEFAULTS;
    }
};

export const loadSession = () => {
    return load(CONSTANTS_STORAGE.SESSION);
};

export const saveSession = (session) => {
    save(CONSTANTS_STORAGE.SESSION, session);
};

export const getSelectedLanguage = () => {
    const i18n = Session.getAppValue('I18N');
    const defaults = loadDefaults();
    return defaults
        ? defaults.lang
            ? defaults.lang.toLowerCase()
            : i18n.language.default.toLowerCase()
        : i18n.language.default.toLowerCase();
};

export const getSelectedClientLanguage = () => {
    const defaults = loadDefaults();
    return defaults
        ? defaults.clientLang
            ? defaults.clientLang.toLowerCase()
            : Session.getProjectDefaultLanguage().toLowerCase()
        : Session.getProjectDefaultLanguage().toLowerCase();
};

export const getSelectedLocale = () => {
    if (getSelectedLanguage() === 'hr') {
        return hr;
    } else {
        return en;
    }
};

export const getTimeValue = (stringValue) => {
    if (stringValue && stringValue !== '') {
        return moment(stringValue, 'HH:mm');
    }
    return undefined;
};

export const downloadFile = (result) => {
    if (result.data) {
        const url = window.URL.createObjectURL(result.data.file);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        if (result.data.filename) {
            a.download = result.data.filename;
        } else {
            a.download = 'download';
        }
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        return true;
    } else {
        return false;
    }
};

function load(key) {
    const values = localStorage.getItem(key);
    if (values && values !== 'undefined') {
        return JSON.parse(values);
    }
    return undefined;
}

function save(key, value) {
    localStorage.setItem(key, JSON.stringify(value));
}

export const formatNumber = (num, scale = 0) => {
    return num.toLocaleString(getSelectedLocale().locale, {
        minimumFractionDigits: scale,
        maximumFractionDigits: scale,
    });
};

export const normalizeComponents = (values) => {
    let normalizedValues = {};
    let translationKeys = {};
    for (var key in values) {
        if (values.hasOwnProperty(key)) {
            if (values[key] instanceof Array) {
                if (values[key].length === 1) {
                    normalizedValues[key] = values[key][0].key;
                } else {
                    normalizedValues[key] = values[key].map((element) => '' + element.key);
                }
            } else if (values[key] instanceof Object && values[key]['key']) {
                normalizedValues[key] = values[key]['key'];
            } else {
                // translations - to json
                if (key.startsWith('translation-')) {
                    const fieldKey = key.substr(15);
                    const fieldLanguage = key.substr(12, 2);
                    if (normalizedValues[fieldKey] == undefined) {
                        normalizedValues[fieldKey] = {};
                    }
                    normalizedValues[fieldKey][fieldLanguage] = values[key];
                    if (!translationKeys[fieldKey]) {
                        //&& !jsonFieldKeys.includes(fieldKey)
                        translationKeys[fieldKey] = fieldKey;
                    }
                } else {
                    if (values[key] === undefined) {
                        normalizedValues[key] = null;
                    } else {
                        normalizedValues[key] = values[key];
                    }
                }
            }
        }
    }
    return normalizedValues;
};

export const FormatFileSize = (bytes) => {
    if (!bytes) {
        return 'N/A';
    } else if (bytes === 0) {
        return '0 B';
    }
    let sizeName = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const divisor = 1024;
    const sizeNameIdx = Math.floor(Math.log(bytes) / Math.log(divisor));
    return parseFloat((bytes / Math.pow(divisor, sizeNameIdx)).toFixed(2)) + ' ' + sizeName[sizeNameIdx];
};

export const showNotification = (title, message, duration, type) => {
    notification[type]({
        message: title,
        description: message,
        duration: duration,
    });
};

export const cookieExists = (cookieToFind) => {
    let cookies = document.cookie;
    let cookieArr = cookies.split(';');

    for (var i = 0; i < cookieArr.length; i++) {
        var name = cookieArr[i].split('=')[0];
        console.log("'" + name + "'");
        if (name.trim() === cookieToFind) {
            return true;
        }
    }
    return false;
};

export const saveGDPRCookie = () => {
    var now = new Date();
    now.setMonth(now.getMonth() + 1);
    document.cookie = 'gdpr=true;expires=' + now.toUTCString() + ';';
};

export async function setProjectLanguages(id) {
    const resp = await requestGet(CONSTANTS_REQ.LANG.FORPROJECT + '/' + id);
    if (resp.status === RESPONSE.OK) {
        let project = Session.getProjectInfo();
        project.languages = resp.data;
        if (resp.data && resp.data.length > 0) {
            project.defaultLanguage = Object.keys(resp.data[0])[0];
        }
        Session.setProject(project);
    }
}

export const formatBytes = (a, b) => {
    if (0 == a) {
        return '0 bytes';
    }
    var c = 1024,
        d = b || 2,
        e = ['bytes', 'kb', 'mb', 'gb', 'tb'],
        f = Math.floor(Math.log(a) / Math.log(c));
    return parseFloat((a / Math.pow(c, f)).toFixed(d)) + ' ' + e[f];
};

export const dateFormat = (date, time = false) => {
    var fDate = new Date(date);
    var dd = fDate.getDate();
    var mm = fDate.getMonth() + 1;
    var yyyy = fDate.getFullYear();
    var h = fDate.getHours();
    var m = fDate.getMinutes();
    var s = fDate.getSeconds();
    if (dd < 10) {
        dd = '0' + dd;
    }
    if (mm < 10) {
        mm = '0' + mm;
    }

    if (time) {
        fDate = dd + '.' + mm + '.' + yyyy + '. ' + h + ':' + m + ':' + s;
    } else {
        fDate = dd + '.' + mm + '.' + yyyy + '.';
    }

    return fDate;
};

export const useScript = (url,id) => {
    useEffect(() => {
        const scriptR = document.getElementById(id);
        if (scriptR != null) {
            scriptR.parentNode.removeChild(scriptR);
        } 
        const script = document.createElement('script');
        script.src = url;
        if(id)script.id=id
        document.body.appendChild(script);
    }, [url]);
  };
