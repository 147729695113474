import { Col, Form, Modal, Row } from "antd";
import React, { useEffect,useState, useImperativeHandle,forwardRef } from "react";
import { InputByLanguage,translationsToObject } from '../../../components/InputByLanguage';
import { geti18nText } from "../../../utils/Session";
import { normalizeComponents } from '../../../utils/Utils';

const AddNote = forwardRef((props,ref) => {
    const { getFieldDecorator, setFieldsValue, getFieldsValue } = props.form;
    const [visible, setVisible] = useState(false);
   

    useEffect(()=>{
        if(props.edit){
            let json={};
             json = translationsToObject(json, 'note', props.selectedItem.note);
             console.log('tu', json);
             setFieldsValue(json) 
        }
    },[props.visible])


    useImperativeHandle(ref, () => ({

        saveNote() {
            props.form.validateFieldsAndScroll((err, values) => {
                values=normalizeComponents(values);
                props.onSave(values)
                props.setVisible(false);
                setVisible(false)
            })
        }
    
      }));


    return (
        <>
        <Form form={props.form}>
            
                    
                    <Row gutter={24}>
                        <Col span={24} style={{ width: '100%' }}>
                       
                            <InputByLanguage
                                fieldName="note"
                                label={geti18nText('package.edit.notes.item')}
                                {...props}
                            />
                        </Col>
                    </Row>
                    
                
                </Form>
            
        </>
    );
});

export default AddNote;
