import React, { useState, useEffect } from "react";
import { Icon, Form, Row, Col, Input } from "antd";
import { CONSTANTS_REQ } from "../../../utils/Constants";
import { DataEdit } from "../../../components/DataEdit";
import { Session, geti18nText } from "../../../utils/Session";
import { requestGet, RESPONSE } from "../../../utils/RequestResolver";
import { setProjectLanguages } from "../../project/ProjectIndex";
var countries = require("i18n-iso-countries");

const LangEdit = Form.create({ name: "LangEdit" })((props) => {
    const { getFieldDecorator, setFieldsValue } = props.form;
    const [editHeader, setEditHeader] = useState(geti18nText("lang.edit.new"));
    const [loading, setLoading] = useState(false);
    const [abbreviation, setAbbreviation] = useState([]);

    useEffect(() => {
        requestGet(CONSTANTS_REQ.LANG.VALIDATEABB + "/" + Session.getProjectId()).then((result) => {
            if (result.status === RESPONSE.OK) {
                setAbbreviation(result.data);
            } else {
                setAbbreviation([]);
            }
        });
    }, [loading]);


    function setValues(lang) {
        setEditHeader(lang.name);
        delete lang.active;
        setFieldsValue(lang);
    }

    function validateAbbreviation(rule, value, callback) {
        if (value !== undefined && value.length == 2) {
            if(abbreviation.includes(value.toLowerCase())) callback(geti18nText("lang.validation.abbreviation.exists"));
            else callback();
        } else callback();
    }

    function isCreate() {
        return props.value == undefined;
    }

    function onSave() {
        setProjectLanguages(Session.getProjectId());
        props.onSave();
    }

    function isoOnChange(rule, value, callback){
        (value === undefined || value === "") ? callback() : (countries.isValid(value)) ? callback() : callback(geti18nText("lang.validation.isoCode"));
    }

    return (
        <>
            <DataEdit
                editHeader={editHeader}
                loading={loading}
                setLoading={setLoading}
                url={CONSTANTS_REQ.LANG.EDIT}
                setValues={setValues}
                width={1200}
                {...props}
                onSave={onSave}
                normalize={(values) => {
                    values.abbreviation = values.abbreviation.toLowerCase();
                    values.isoCode = values.isoCode.toUpperCase();
                    return { ...values, project: { id: Session.getProjectId() } };
                }}
            >
                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item hidden={true}>{getFieldDecorator("id")(<Input hidden={true} />)}</Form.Item>
                        <Form.Item label={geti18nText("lang.edit.abbreviation")}>
                            {getFieldDecorator("abbreviation", {
                                rules: [
                                    { required: true, message: geti18nText("app.default.required") },
                                    { len: 2, message: geti18nText("lang.validation.abbreviation.length") },
                                    {
                                        validator: isCreate() ? validateAbbreviation : undefined,
                                    },
                                ],
                            })(<Input disabled={loading || !isCreate()}  style={{textTransform: "lowercase"}}/>)}
                        </Form.Item>
                        <Form.Item label={geti18nText("lang.edit.name")}>
                            {getFieldDecorator("name", {
                                rules: [{ required: true, message: geti18nText("app.default.required") }],
                            })(<Input disabled={loading} />)}
                        </Form.Item>
                        <Form.Item label={geti18nText("lang.edit.isoCode")}>{getFieldDecorator("isoCode", {
                            rules: [
                                { required: true, message: geti18nText("app.default.required") },
                                {validator: isoOnChange}
                            ]
                        })(<Input disabled={loading} style={{textTransform: "uppercase"}} />)}</Form.Item>
                    </Col>
                </Row>
            </DataEdit>
        </>
    );
});

export default LangEdit;
