import { Button, Collapse, Divider, Form, notification, Row } from 'antd';
import React, { useState } from 'react';

import { requestPost, RESPONSE } from '../../../utils/RequestResolver';
import { geti18nText, Session } from '../../../utils/Session';
import { CONSTANTS_FILE_TYPE, CONSTANTS_REQ } from '../../../utils/Constants';
import DeseignEditor from '../../../components/deseign-editor';
import { useSelector } from 'react-redux';
//uvoz tablice
import { Table, Modal } from 'antd';

const DesignSettings = Form.create({ name: 'DesignSettings' })((props) => {
    const { projectId, themeShortName } = useSelector((state) => state.projectSlices);
    // panel
    const { Panel } = Collapse;
    //nazivi stupca
    const columns = [
        {
            title: geti18nText('component.id'),
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: geti18nText('design.settings.component.text_option.component_title'),
            dataIndex: 'text',
            key: 'text',
        },
        {
            title: geti18nText('design.settings.component.shortname'),
            dataIndex: 'name',
            key: 'name',
        },
    ];
    //vidljivost modala
    const [isModalVisible, setIsModalVisible] = useState(false);
    const showModal = () => {
        setIsModalVisible(true);
    };
    const hideModal = () => {
        setIsModalVisible(false);
    };
    // koji modal za komponentu se priazuje
    const [component, setComponent] = useState(undefined);

    function showNotification(title, message, duration, type) {
        notification[type]({
            message: title,
            description: message,
            duration: duration,
        });
    }

    const save = (e) => {
        requestPost(CONSTANTS_REQ.DESIGN_SETTINGS.PROJECT_SAVE, null, { projectId: props.projectId, cssJson: '' }).then(
            (resp) => {
                if (resp.status == RESPONSE.OK) {
                    showNotification(
                        geti18nText('app.default.save.ok'),
                        geti18nText('settings.save.success'),
                        3,
                        'success'
                    );
                } else {
                    showNotification(geti18nText('app.default.save.nok'), geti18nText('settings.save.not'), 0, 'error');
                }
            }
        );
    };

    return (
        <React.Fragment>
            <Row type="flex" className="margin-bottom">
                <Divider orientation="left" style={{ fontSize: '18px' }}>
                    {geti18nText('design.settings.title')}
                </Divider>
            </Row>
            <DeseignEditor projectId={projectId} type={'project'} className="margin-bottom"></DeseignEditor>
            <p className="margin-bottom"></p>
            <Collapse>
                <Panel header={geti18nText('design.settings.component.general')} key="1">
                    <Table
                        dataSource={Session.getComponentsForTable()}
                        columns={columns}
                        rowKey="id"
                        onRow={(record, rowIndex) => {
                            return {
                                onDoubleClick: (event) => {
                                    console.log('kliknuti id:' + record.name);
                                    showModal();
                                    setComponent(record);
                                },
                                // kliknuti redak
                            };
                        }}
                    />
                </Panel>
            </Collapse>
            <Modal
                zIndex={1100}
                visible={isModalVisible}
                onCancel={hideModal}
                width={1200}
                destroyOnClose={true}
                footer={[]}
            >
                <DeseignEditor
                    closePopup={hideModal}
                    projectId={projectId}
                    componentName={component?.name}
                    pageComponentId={component?.id}
                    type={'component'}
                    className="margin-bottom"
                ></DeseignEditor>
            </Modal>
        </React.Fragment>
    );
});

export default DesignSettings;
