import { Col, Form, Input, InputNumber, Row, Select, Descriptions, Modal, Button } from 'antd';
import React, { useState } from 'react';
import { DataEdit } from '../../../components/DataEdit';
import { CONSTANTS_FILE_TYPE, CONSTANTS_REQ } from '../../../utils/Constants';
import { geti18nText, Session } from '../../../utils/Session';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Map, TileLayer, Marker } from 'react-leaflet';
import L from 'leaflet';
const blueIcon = new L.Icon({
    iconUrl: 'https://cdn.rawgit.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-blue.png',
    shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
});

const ReportedproblemEdit = Form.create({ name: 'ReportedproblemEdit' })((props) => {
    const { getFieldDecorator, setFieldsValue } = props.form;
    const [editHeader, setEditHeader] = useState(geti18nText('reported.edit.problem.header'));
    const [loading, setLoading] = useState(false);
    const [problem, setProblem] = useState(undefined);
    const [curentImage, setCurrentImage] = useState(null);
    const [contextTextLangJson, setContextTextLangJson] = useState(null);
    const [modalVisible, setModalVisible] = useState(false);
    const [previewVisible, setPreviewVisible] = useState(false);
    let history = useHistory();
    const dispatch = useDispatch();

    function setValues(data) {
        setProblem(data);
    }

    function setDefaultValues() {
        setContextTextLangJson(null);
    }

    const URL_PREFIX = process.env.NODE_ENV === 'development' ? 'http://localhost:8080/api/' : '/api/';

    function showImage(url) {
        setCurrentImage(url);
        setPreviewVisible(true);
    }

    function setImage() {
        return problem.listImages.map((v) => {
            let url=URL_PREFIX + 'media/' + v.split('files/')[1]
            return (
                <div
                    onClick={()=>showImage(url)}
                    style={{
                        backgroundImage: `url(${url})`,
                        backgroundSize: 'contain',
                        height: 250,
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: '50% 50%',
                        marginTop: '10px',
                        maxWidth:400,
                        flex: '0 0 392px',
                        cursor: 'pointer'
                    }}
                ></div>
            );
        });
    }

    const MyMarker = (props) => {
        const initMarker = (ref) => {
            if (ref) {
                ref.leafletElement.openPopup();
            }
        };

        return <Marker icon={blueIcon} ref={initMarker} {...props} />;
    };

    return (
        <>
            <DataEdit
                editHeader={editHeader}
                loading={loading}
                setLoading={setLoading}
                setDefaultValues={setDefaultValues}
                url={CONSTANTS_REQ.REPORTED_PROBLEM.EDIT}
                setValues={setValues}
                width={1200}
                {...props}
                hideButtons={true}
                hideActivationButtons={true}
                hideSubmitButton={true}
            >
                <Row gutter={24}>
                    {problem && (
                        <>
                            <Descriptions column={2}>
                                <Descriptions.Item label={geti18nText('reported.problem.edit.sender.name')}>
                                    {problem.senderName}
                                </Descriptions.Item>
                                <Descriptions.Item label={geti18nText('reported.problem.edit.sender.mail')}>
                                    {problem.senderMail}
                                </Descriptions.Item>
                                <Descriptions.Item label={geti18nText('reported.problem.edit.type')}>
                                    {problem.reportType}
                                </Descriptions.Item>
                                <Descriptions.Item label={geti18nText('reported.problem.edit.description')}>
                                    {problem.problemDescription}
                                </Descriptions.Item>
                                {problem.address && (
                                    <Descriptions.Item label={geti18nText('reported.problem.edit.address')}>
                                        {problem.address}
                                    </Descriptions.Item>
                                )}

                                {problem.latitude && problem.longitude && (
                                    <Descriptions.Item>
                                        <Button type="primary" onClick={() => setModalVisible(true)}>
                                            {geti18nText('reported.problem.edit.address.btn')}
                                        </Button>
                                    </Descriptions.Item>
                                )}
                            </Descriptions>

                            {problem.listImages && (
                                <>
                                {geti18nText('reported.problem.edit.images')}
                                <div  style={{
                                    display: 'flex',
                                    width: '100%',
                                    flexWrap: 'wrap',
                                    
                                    
                                    
                                }}>
                                    
                                    {setImage()}
                                </div>
                                </>
                            )}
                        </>
                    )}
                </Row>
                <Modal
                    title={geti18nText('reported.problem.edit.address.map.title')}
                    visible={modalVisible}
                    onCancel={() => setModalVisible(false)}
                    destroyOnClose={true}
                    footer={null}
                    width={'400px'}
                >
                    {problem && problem.latitude && problem.longitude && (
                        <Row>
                            <Map
                                center={[problem.latitude, problem.longitude]}
                                zoom={7}
                                style={{ height: '350px', width: '350px' }}
                            >
                                <TileLayer
                                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                />
                                <MyMarker position={[problem.latitude, problem.longitude]}></MyMarker>
                            </Map>
                            <Descriptions column={1} style={{ marginTop: '15px' }}>
                                <Descriptions.Item label={geti18nText('reported.problem.edit.address.map.lat')}>
                                    {problem.latitude}
                                </Descriptions.Item>
                                <Descriptions.Item label={geti18nText('reported.problem.edit.address.map.long')}>
                                    {problem.longitude}
                                </Descriptions.Item>
                            </Descriptions>
                        </Row>
                    )}
                </Modal>
                <Modal
                    title={geti18nText('menu.preview')}
                    visible={previewVisible}
                    onCancel={() => setPreviewVisible(false)}
                    destroyOnClose={true}
                    footer={null}
                    width={1000}
                >
                {curentImage && <img width={'100%'} src={curentImage}></img>}
            </Modal>
            </DataEdit>
        </>
    );
});

export default ReportedproblemEdit;
