import React, { useState, useEffect } from 'react';
import { Form, Row, Col, Input } from 'antd';
import { CONSTANTS_REQ, CONSTANTS_FILE_TYPE } from '../../../utils/Constants';
import { DataEdit } from '../../../components/DataEdit';
import { InputByLanguage, translationsToObject, getTranslated } from '../../../components/InputByLanguage';
import { geti18nText } from '../../../utils/Session';
import { FileUpload } from '../../../components/fileUpload/FileUpload';
import { SearchField } from '../../../components/Search';
import { refreshMenu } from '../../../slices/menuSlice';
import { useDispatch } from 'react-redux';

const FacilityEdit = Form.create({ name: 'FacilityEdit' })((props) => {
    const { getFieldDecorator, setFieldsValue } = props.form;
    const [editHeader, setEditHeader] = useState(geti18nText('facility.edit.new')); //todo:
    const [loading, setLoading] = useState(false);
    const [nameJson, setNameJson] = useState(null);
    const [formValues, setFormValues] = useState(null);
    const dispatch = useDispatch();

    function setValues(data) {
        data = translationsToObject(data, 'name', data.name);
        setNameJson(data.name);
        setEditHeader(getTranslated(data.name));
        delete data.name;
        delete data.active;
        if (data.icon != null) {
            const imagePath = {
                file_path: data.icon,
                name: data.icon.substring(data.icon.lastIndexOf('\\') + 1, data.icon.length),
            };
            data.icon = imagePath;
        }
        setFormValues(data);
        setFieldsValue(data);
    }

    function onSave() {
        dispatch(
            refreshMenu({
                refreshLastNode: false,
                refreshProjectNode: false,
                refreshNodes: ['FACILITYGROUPS', 'FACILITYGROUP', 'FACILITY'],
            })
        );
        props.onSave();
    }

    function onSaveAndGetId() {
        console.log('save and get id', props);
    }

    return (
        <DataEdit
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            url={CONSTANTS_REQ.FACILITY.EDIT}
            setValues={setValues}
            width={1200}
            {...props}
            onSave={onSave}
            onSaveAndGetID={onSaveAndGetId}
            normalize={(values) => {
                const mediaPath = '/media/0';
                return {
                    ...values,
                    main: true,
                    icon:
                        values.icon != null && values.icon instanceof Object
                            ? ((values.icon.file_path.includes('media_client') || values.icon.file_path.includes(mediaPath)) ? values.icon.file_path : mediaPath + values.icon.file_path)
                            : ((values.icon.includes('media_client') || values.icon.includes(mediaPath)) ? values.icon : mediaPath + values.icon),
                    parent: 
                        values.parent != null && values.parent instanceof Object
                            ? values.parent
                            : values.parent != null ? { id: values.parent } 
                            : null,
                };
            }}
        >
            <Row gutter={24}>
                <Col span={24}>
                    <Form.Item hidden={true}>{getFieldDecorator('id')(<Input hidden={true} />)}</Form.Item>
                    <Form.Item label={geti18nText('facility.edit.name')}>
                        <InputByLanguage
                            disabled={loading}
                            fieldName="name"
                            langJson={nameJson}
                            {...props}
                        />
                    </Form.Item>
                    <Form.Item label={geti18nText('facility.edit.image')}>
                        {' '}
                        {getFieldDecorator('icon', {
                            rules: [{ required: true, message: geti18nText('app.default.required') }],
                        })(
                            <FileUpload
                                fileType={CONSTANTS_FILE_TYPE.IMAGE}
                                map={{ id: 'id', label: 'name' }}
                                searchBy="name"
                                disabled={false}
                                addedData={{ projectId: undefined, staticMedia: true }}
                            />
                        )}
                    </Form.Item>
                    <Form.Item label={geti18nText('facility.group.edit.new')}>
                        {getFieldDecorator('parent')(
                            <SearchField
                                url={CONSTANTS_REQ.FACILITY.GROUPS_SEARCH}
                                map={{ id: 'id', label: 'name' }}
                                searchBy="name"
                                disabled={false}
                                addedData={{ active: true }}
                                translate={true}
                            />
                        )}
                    </Form.Item>
                </Col>
            </Row>
        </DataEdit>
    );
});

export default FacilityEdit;
