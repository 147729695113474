const URL_PREFIX = process.env.NODE_ENV === 'development' ? 'http://localhost:8080/api/' : '/api/';

export const DEFAULTS = {
    lang: 'hr',
};

export const CONSTANTS_REQ = {
    INIT: URL_PREFIX + 'init',
    REFRESHTOKEN: URL_PREFIX + 'refresh_token',
    LANG: {
        LIST: URL_PREFIX + 'lang/list',
        EDIT: URL_PREFIX + 'lang',
        FORPROJECT: URL_PREFIX + 'lang/forProject',
        VALIDATEABB: URL_PREFIX + 'lang/validateAbb',
        FORAPP: URL_PREFIX + 'lang/forApp',
    },
    COMPONENT: {
        NEWSLETTERADD: URL_PREFIX + 'component/newsletterAdd',
        INQUIRY: URL_PREFIX + 'component/inquiry',
        CV: URL_PREFIX + 'component/cv',
        EDIT: URL_PREFIX + 'component', // dodani image i download
        IMAGE: URL_PREFIX + 'component/image/',
        DOWNLOAD: URL_PREFIX + 'component/download/',
        DELETE: URL_PREFIX + 'component/delete/',
        CSV: URL_PREFIX + 'component/importCsv/',
        MAGIC_PACKAGE_CONFIGURATOR: URL_PREFIX + 'component/packageConfigurator',
    },
    LOGIN: URL_PREFIX + 'login',
    REGISTER: URL_PREFIX + 'register',
    USER: {
        LIST: URL_PREFIX + 'users/list',
        LIST_PER_PAGE: URL_PREFIX + 'users/listPerPages',
        SEARCH: URL_PREFIX + 'users/search',
        EDIT: URL_PREFIX + 'users',
    },
    ROLE: {
        LIST: URL_PREFIX + 'role/list',
        SEARCH: URL_PREFIX + 'role/search',
        EDIT: URL_PREFIX + 'role',
    },
    PAGETYPE: {
        LIST: URL_PREFIX + 'pageType/list',
        SEARCH: URL_PREFIX + 'pageType/search',
        EDIT: URL_PREFIX + 'pageType',
        TREE: URL_PREFIX + 'pageType/tree',
        ORDER: URL_PREFIX + 'pageType/order',
    },
    PROJECT: {
        LIST: URL_PREFIX + 'project/list',
        SEARCH: URL_PREFIX + 'project/search',
        EDIT: URL_PREFIX + 'project',
        COPY: URL_PREFIX + 'project/copy',
        TREE: URL_PREFIX + 'project/tree',
        SUBDOMAIN: URL_PREFIX + 'project/subdomain',
        FORLOCALUSER: URL_PREFIX + 'project/forLocalUser',
        FORUPLOADUSER: URL_PREFIX + 'project/forUploadUser',
        PREVIEWCODE: URL_PREFIX + 'project/getcode',
        PUBLISH: URL_PREFIX + 'project/publish',
        SETDOMAIN: URL_PREFIX + 'project/saveProjectDomain',
        GETDEFAULTDOMAIN: URL_PREFIX + 'project/getProjectDomain',
    },
    LOCATION: {
        LIST: URL_PREFIX + 'location/list',
        SEARCH: URL_PREFIX + 'location/search',
        EDIT: URL_PREFIX + 'location',
        TREE: URL_PREFIX + 'location/tree',
        ORDER: URL_PREFIX + 'location/order',
    },
    MAP_PIN: {
        LIST: URL_PREFIX + 'mapPin/list',
        EDIT: URL_PREFIX + 'mapPin',
    },
    WEBPAGE: {
        LIST: URL_PREFIX + 'webPage/list',
        SEARCH: URL_PREFIX + 'webPage/search',
        EDIT: URL_PREFIX + 'webPage',
        TREE: URL_PREFIX + 'webPage/tree',
        ORDER: URL_PREFIX + 'webPage/order',
        ORDER_WEB_TYPE: URL_PREFIX + 'webPage/orderWebPagesType',
        PRICE: URL_PREFIX + 'webPage/price',
        CLIENTGET: URL_PREFIX + 'webPage/client',
        COPY: URL_PREFIX + 'webPage/copy',
        HAS_LINK: URL_PREFIX + 'webPage/hasLink',
        VALIDATEALIAS: URL_PREFIX + 'webPage/validateAlias',
        UPDATE_DESCRIPTION: URL_PREFIX + 'webPage/updateDescription',
    },
    WEBPAGECOMPONENT: {
        LIST: URL_PREFIX + 'pageComponent/list',
        EDIT: URL_PREFIX + 'pageComponent',
        ORDER: URL_PREFIX + 'pageComponent/order',
        LISTCOMPONENTS: URL_PREFIX + 'pageComponent/listComponents',
        DELETE: URL_PREFIX + 'pageComponent/delete',
        UPDATEJSON: URL_PREFIX + 'pageComponent/updateJson',
        COPYJSON: URL_PREFIX + 'pageComponent/copyJson',
        PAGESWITHCOMPONENTS: URL_PREFIX + 'pageComponent/pagesWithComponent',
        TREE: URL_PREFIX + 'pageComponent/tree',
        UPDATEJSONANDFACILITIES: URL_PREFIX + 'pageComponent/updateJsonAndFacilities',
        MERGE_COMPONENTS: URL_PREFIX + 'pageComponent/mergeComponents',
    },
    BULLETIN: {
        LIST: URL_PREFIX + 'bulletin/list',
        SEARCH: URL_PREFIX + 'bulletin/search',
        EDIT: URL_PREFIX + 'bulletin',
        FIRSTPAGE: URL_PREFIX + 'bulletin/checkFirstPageForWebPage',
    },
    DISK_FILE: {
        LIST: URL_PREFIX + 'diskFile/list',
        SEARCH: URL_PREFIX + 'diskFile/search',
        EDIT: URL_PREFIX + 'diskFile',
        UPLOAD: URL_PREFIX + 'diskFile/upload',
        DOWNLOAD: URL_PREFIX + 'diskFile/download',
        PDF: URL_PREFIX + 'diskFile/pdf',
        DELETE: URL_PREFIX + 'diskFile/delete/',
        COPY: URL_PREFIX + 'diskFile/copy',
    },
    SETTINGS: {
        EDIT: URL_PREFIX + 'settings',
        ADMIN: URL_PREFIX + 'settings/admin',
        SAVE_SETTINGS: URL_PREFIX + 'settings/saveSettings',
        REMOVE_DEFAULTS: URL_PREFIX + 'settings/removeDefaults',
        DELETE_FOOTER: URL_PREFIX + 'settings/deleteFooterSettings',
    },
    THEME: {
        SEARCH: URL_PREFIX + 'theme/search',
    },
    CLIENT: {
        PROJECT_LIST: URL_PREFIX + 'project/client/list',
        CONNECTED_PROJECTS: URL_PREFIX + 'project/client/connectedProjects',
        SETTINGS_EDIT: URL_PREFIX + 'settings/client',
        PAGETYPE_LIST: URL_PREFIX + 'pageType/client/list',
        WEBPAGE_LIST: URL_PREFIX + 'webPage/client/list',
        WEBPAGECOMPONENT_LIST: URL_PREFIX + 'pageComponent/client/list',
        DISK_FILE_DOWNLOAD: URL_PREFIX + 'diskFile/client/download',
        DISK_FILE_PDF: URL_PREFIX + 'diskFile/client/pdf',
        MAP_PIN_LIST: URL_PREFIX + 'mapPin/client/list',
        LOCATION_LIST: URL_PREFIX + 'location/client/list',
        LOCATION_LIST_PROJECTS: URL_PREFIX + 'location/client/listConnectedProjects',
        LOCATION_EDIT: URL_PREFIX + 'location/client',
        FACILITY_LIST: URL_PREFIX + 'facility/client/listByPage',
        SEARCH_CONTENT_TYPE: URL_PREFIX + 'searchContentType/client',
        VIDEO_RANGE_STREAM: URL_PREFIX + 'diskFile/videoRangeStream',
        DOMAIN_SUBDOMAIN: URL_PREFIX + 'project/client/domainSubdomain',
    },
    DIRECTORY: {
        TREE: URL_PREFIX + 'directory',
        CHECK_DELETE: URL_PREFIX + 'directory/checkDelete',
        DELETE: URL_PREFIX + 'directory/deleteFile',
        LAST_SEEN: URL_PREFIX + 'directory/lastSeen',
        ROOT_NOTIFICATION: URL_PREFIX + 'directory/notificationRoot',
        DIRECTORY_TREE: URL_PREFIX + 'directory/directoryTree',
        CHECK_UPLOAD_FOLDER_PERMISSION: URL_PREFIX + 'directory/checkUploadFolderPermission',
    },
    FACILITY: {
        LIST: URL_PREFIX + 'facility/list',
        SEARCH: URL_PREFIX + 'facility/search',
        EDIT: URL_PREFIX + 'facility',
        GROUPS: URL_PREFIX + 'facility/groups',
        GROUPS_SEARCH: URL_PREFIX + 'facility/groupSearch',
        FORPAGEBYGROUP: URL_PREFIX + 'facility/forPageByGroup',
        MAINBYPAGE: URL_PREFIX + 'facility/mainForPage',
        TREE: URL_PREFIX + 'facility/tree',
        ORDER: URL_PREFIX + 'facility/order',
    },
    STATIC: URL_PREFIX + 'media/',
    STATIC_CLIENT: URL_PREFIX + 'media_client',
    STATIC_MEDIA: URL_PREFIX,
    SEARCH_TYPE: {
        LIST: URL_PREFIX + 'searchContentType/list',
        EDIT: URL_PREFIX + 'searchContentType',
        SEARCH: URL_PREFIX + 'searchContentType/search',
        CHILDREN: URL_PREFIX + 'searchContentType/children',
        FORPAGEBYGROUP: URL_PREFIX + 'searchContentType/forPageByGroup',
        TREE: URL_PREFIX + 'searchContentType/tree',
        ORDER: URL_PREFIX + 'searchContentType/order',
    },
    ACCOMMODATION_TYPE: {
        LIST: URL_PREFIX + 'accommodationType/list',
        EDIT: URL_PREFIX + 'accommodationType',
    },
    WEB_PAGE_PAGE_TYPE: {
        EDIT: URL_PREFIX + 'webPagePageType',
    },
    TRANSLATIONS: {
        LIST: URL_PREFIX + 'translations/list',
        EDIT: URL_PREFIX + 'translations',
    },
    DOMAIN: {
        LIST: URL_PREFIX + 'domains/list',
        EDIT: URL_PREFIX + 'domains',
        SEARCH: URL_PREFIX + 'domains/search',
    },
    NEXTJSLOG: {
        LIST: URL_PREFIX + 'nextJsLog/list',
        STATUS: URL_PREFIX + 'nextJsLog/status',
        PUBLISH_LIST: URL_PREFIX + 'nextJsLog/published_projects',
    },
    VIDEO_CHAT: {
        USERS: URL_PREFIX + 'chat/users',
    },
    MOBILE_APPLICATION: {
        DOWNLOAD: URL_PREFIX + 'mobileApplication/download',
    },
    REPORTED_PROBLEM: {
        LIST: URL_PREFIX + 'problemReport/list',
        EDIT: URL_PREFIX + 'problemReport',
    },
    DESIGN_SETTINGS: {
        PROJECT_SAVE: URL_PREFIX + 'designSettings/project',
        PAGE_COMPONENT_SAVE: URL_PREFIX + 'designSettings/pageComponent',
        COMPONENT_SAVE: URL_PREFIX + 'designSettings/component',
        FONTS: URL_PREFIX + 'designSettings/fonts',
    },
    FACEBOOK_FEED: {
        CHECK_TOKEN: 'https://graph.facebook.com/v12.0/debug_token',
        GET_POSTS: 'https://graph.facebook.com/v12.0/',
    },
    INSTAGRAM_FEED: {
        MEDIA: 'https://graph.instagram.com/me/media',
        ME: 'https://graph.instagram.com/me',
    },
    CLSM: {
        PROJECTS: URL_PREFIX + 'clsmApi/projects',
        CURRENT_CONSUMPTION: URL_PREFIX + 'clsmApi/currentConsumpion',
        SUBSTATION_LIST: URL_PREFIX + 'clsmApi/substation/list',
        LIGHTS_LIST: URL_PREFIX + 'clsmApi/light/list',
        REPORT_CHART_CONSUMPTION: URL_PREFIX + 'clsmApi/report/chartsConsumption',
    },
    JS_FILE: {
        JQUERY: URL_PREFIX +'media/js/vendor/jquery.js',
        WAYPOINTS: URL_PREFIX +'media/js/vendor/waypoints.js',
        BOOSTRAP_BUNDLE: URL_PREFIX +'media/js/bootstrap-bundle.js',
        MEAN_MENU: URL_PREFIX +'media/js/meanmenu.js',
        GSAP_MIN: URL_PREFIX +'media/js/gsap.min.js',
        SCROLLER_TRIGGER: URL_PREFIX +'media/js/ScrollTrigger.min.js',
        SPLIT_TEXT: URL_PREFIX +'media/js/split-text.min.js',
        SWIPER: URL_PREFIX +'media/js/swiper-bundle.js',
        RANGE_SLIDER: URL_PREFIX +'media/js/range-slider.js',
        MAGNIFIC_POPUP: URL_PREFIX +'media/js/magnific-popup.js',
        NICE_SELECT: URL_PREFIX +'media/js/nice-select.js',
        PURE_COUNTER: URL_PREFIX +'media/js/purecounter.js',
        COUNTDOWN: URL_PREFIX +'media/js/countdown.js',
        JQUERY_KNOB: URL_PREFIX +'media/js/jequery-knob.js',
        JQUERY_APPEAR: URL_PREFIX +'media/js/jequery-appear.js',
        WOW: URL_PREFIX +'media/js/wow.js',
        ISOTOPE: URL_PREFIX +'media/js/isotope-pkgd.js',
        IMAGES_LOAD: URL_PREFIX +'media/js/imagesloaded-pkgd.js',
        AJAX_FORM: URL_PREFIX +'media/js/ajax-form.js',
        SLICK: URL_PREFIX +'media/js/slick.js',
        MAIN: URL_PREFIX +'media/js/mainScript.js',
    },
    BLOG: {
        LIST: URL_PREFIX + 'blog/list',
        EDIT: URL_PREFIX + 'blog',
        SEARCH: URL_PREFIX + 'blog/search',
    },
    PACKAGE: {
        LIST: URL_PREFIX + 'package/list',
        EDIT: URL_PREFIX + 'package',
        SEARCH: URL_PREFIX + 'package/search',
    },
    CATEGORY: {
        LIST: URL_PREFIX + 'category/list',
        EDIT: URL_PREFIX + 'category',
        SEARCH: URL_PREFIX + 'category/search',
    },
    
    FAQ_QUESTION_ANSWER: {
        LIST: URL_PREFIX + 'faqQuestionAnswer/list',
        EDIT: URL_PREFIX + 'faqQuestionAnswer',
        SEARCH: URL_PREFIX + 'faqQuestionAnswer/search',
    },
    SHOP: {
        LIST: URL_PREFIX + 'shopItem/list',
        EDIT: URL_PREFIX + 'shopItem',
        SEARCH: URL_PREFIX + 'shopItem/search',
    },
    WEBPAGELINKS: {
        LIST: URL_PREFIX + 'webPageLinks/list',
        EDIT: URL_PREFIX + 'webPageLinks',
    }
};

export const CONSTANTS_FILE_TYPE = {
    IMAGE: 0,
    VIDEO: 1,
    OTHER: 2,
    APPLICATION: 3,
    FONT: 4,
};

export const CONSTANTS_STORAGE = {
    DEFAULTS: 'hr.nybble.nytourist.defaults',
    SESSION: 'hr.nybble.nytourist.session',
};

export const IMAGE_FORMATS = ['.png', '.jpg', '.svg', '.jpeg'];
export const VIDEO_FORMATS = ['.m4v', '.mp4', '.mp3', '.mov'];
export const PDF_FORMATS = ['.pdf'];
export const FONT_FORMATS = ['.otf', '.ttf', '.woff', '.woff2'];

export const UPLAD_CONTENT_FOLDER = 'UPLOAD';

export const UPLAD_MOBILEAPP_FOLDER = 'MOBILEAPP';

export const UPLAD_STYLE_FOLDER = 'STYLE';
export const UPLAD_STYLE_FONT_FOLDER = 'FONT';
