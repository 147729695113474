import React, { useState, useEffect, useImperativeHandle } from 'react';
import { Row, Col, Button, Modal, Table, Tag, Tabs } from 'antd';
import { Spinner } from '../Spinner';
import { geti18nText } from '../../utils/Session';
import { getTranslated } from '../InputByLanguage';
import ConfiguratorPackageEdit from './ConfiguratorPackageEdit';
import { getColumnSearch } from '../../components/DataTable';

const ConfiguratorTablePackages = React.forwardRef((props, ref) => {
    const [fetching, setFetch] = useState(false);
    const [dataSource, setDataSource] = useState([]);
    const [dataSourceFiltered, setDataSourceFiltered] = useState([]);
    const [popupVisible, setPopupVisible] = useState(false);
    const [popupValue, setPopupValue] = useState(undefined);
    const [filterItems, setFilterItems] = useState([]);
    const [activeTab, setActiveTab] = useState('0');
    const [selectedFilter, setSelectedFilter] = useState(0);

    const { TabPane } = Tabs;
    useImperativeHandle(
        ref,
        () => ({
            getTableDataSource: () => {
                let elements = [];
                for (let el of dataSource) {
                    if (el.id.toString().includes('TEMP')) {
                        console.log('TEMP');
                        el.id = '';
                    }
                    elements.push(el);
                }
                return elements;
            },
        }),
        [dataSource]
    );

    useEffect(() => {
        if (props.initConfTableValues) {
            let elements = [];
            let i = 0;

            for (let el of props.initConfTableValues) {
                console.log('el ', el);
                if (el.id == undefined || el.id == '' || el.id == []) {
                    el.id = 'TEMP_' + i;
                    i = i + 1;
                }
                elements.push(el);
            }
            setDataSource(elements);
            setDataSourceFiltered(elements);
        }
    }, [props.initConfTableValues]);

    useEffect(() => {
        if (props.groupsRef.current && props.groupsRef.current.getFilterOptions()) {
            setFilterItems(props.groupsRef.current.getFilterOptions());
            let elements = [];
            let i = 0;

            for (let el of [...dataSource]) {
                console.log('el ', el);
                if (el.id == undefined || el.id == '' || el.id == []) {
                    el.id = 'TEMP_' + i;
                    i = i + 1;
                }
                elements.push(el);
            }
            setDataSource(elements);
        }
    }, [props.groupsRef.current && props.groupsRef.current.getFilterOptions()]);

    const columns = [
        {
            title: geti18nText('component.configurator.table.package.name'),
            dataIndex: 'package_name',
            key: 'package_name',
            render: (text, record) => {
                const error =
                    record.id == undefined ||
                    record.id == '' ||
                    record.id == [] ||
                    record.id.toString().includes('TEMP');
                return <div style={error ? { color: 'red' } : undefined}>{getTranslated(text)}</div>;
            },

            // sorter: (a, b) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('component.configurator.table.package.price'),
            dataIndex: 'package_price',
            key: 'package_price',
            //sorter: (a, b) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('component.configurator.table.package.description'),
            dataIndex: 'package_desc',
            key: 'package_desc',
            render: (text, record) => (
                <div
                    className="magicPackagesDescription"
                    dangerouslySetInnerHTML={{
                        __html: text ? getTranslated(text).replace(/(<? *script)/gi, 'illegalscript') : '',
                    }}
                ></div>
            ),
            ...getColumnSearch('string'),
        },
        /* {
            title: 'Filters',
            dataIndex: 'filters',
            key: 'filters',

            render: (text, record) => (
                <>
                    {record.filterChecked &&
                        record.filterChecked.map((tag) => (
                            <Tag color="blue" key={tag}>
                                {getTagFilterName(tag)}
                            </Tag>
                        ))}
                </>
            ),
        }, */
    ];

    function getTagFilterName(value) {
        const item = filterItems?.find((x) => x.value == value);
        return item ? item.label : '';
    }

    function onNewItemHandler(filter) {
        setPopupValue(undefined);
        setPopupVisible(true);
    }

    function closePopup() {
        setPopupVisible(false);
    }

    function updateItemsFromModal(json) {
        const elements = [...dataSource];
        var currentElementIndex = elements.findIndex((x) => x.id === json.id);

        if (currentElementIndex === -1) {
            if (json.id == undefined || json.id == '' || json.id == []) {
                json.id = 'TEMP_' + new Date().getTime();
            }
            elements.push(json);
        } else {
            elements[currentElementIndex] = json;
        }
        setDataSource(elements);
        setDataSourceFiltered(elements);
        closePopup();
    }

    function deleteItem(id) {
        const elements = [...dataSource];
        console.log('ID delete ', id);
        var currentElementIndex = elements.findIndex((x) => x.id === id);

        if (currentElementIndex != -1) {
            console.log('delete ', currentElementIndex);
            elements.splice(currentElementIndex, 1);
        }
        setDataSource(elements);
        setDataSourceFiltered(elements);
        closePopup();
    }

    function onRowSelectHandler(record) {
        setPopupValue(record);
        setPopupVisible(true);
    }
    function handleTableChange(pagination, filters, sorter) {
        let params = {
            max: pagination.pageSize,
            offset: pagination.current - 1,
            ...getSort(sorter),
            ...getFilters(filters),
        };
    }
    function getFilters(filters) {
        const transformedFilters = [];
        if (filters) {
            for (var key in filters) {
                if (filters.hasOwnProperty(key) && filters[key].length > 0) {
                    transformedFilters.push({ field: key, ...filters[key][0] });
                }
            }
            if (props.setDefaultFilterValue) {
                const defaultFilter = props.setDefaultFilterValue();
                for (let dFilter of defaultFilter) {
                    if (transformedFilters.find(({ field }) => field == dFilter['field']) === undefined) {
                        transformedFilters.push(dFilter);
                    }
                }
            }
            applayFilter(transformedFilters);
        }
    }

    function applayFilter(filterArray) {
        var elements = [...dataSource];
        filterArray.forEach((filter) => {
            elements = elements.filter((item) => {
                if (filter.condition == 'contains') {
                    const field = getTranslated(item[filter['field']]);
                    const value = filter['value'];
                    return field.includes(value);
                } else {
                    const key = filter['field'];
                    if (item[key] != null) {
                        const field =
                            typeof item[key] == 'number' ? item[key]?.toString() : getTranslated(item[key].toString());
                        const value = filter['value'];
                        return field == value;
                    }
                    return false;
                }
            });
        });
        setDataSourceFiltered(elements);
    }

    function getSort(sorter) {
        if (sorter.field) {
            const sort = { order: undefined, orderType: undefined };
            sort.order = sorter.field;
            sort.orderType = sorter.order === 'ascend' ? 'asc' : 'desc';
            return sort;
        } else {
            return undefined;
        }
    }
    const tabOnChange = (activeKey) => {
        setSelectedFilter(activeKey);
        setActiveTab(activeKey);
    };

    if (fetching) {
        return <Spinner />;
    } else {
        return (
            <React.Fragment>
                <Tabs type="editable-card" onChange={tabOnChange} activeKey={activeTab}>
                    {props.filtersValues &&
                        props.filtersValues.map((item, index) => (
                            <TabPane tab={getTranslated(item.nameJson)} key={item.id} forceRender={true}>
                                <Table
                                    dataSource={dataSourceFiltered.filter((x) => x.filterChecked.includes(item.id))}
                                    columns={columns}
                                    onChange={handleTableChange}
                                    onRow={(record, rowIndex) => {
                                        return {
                                            onClick: (event) => {},
                                            onDoubleClick: (event) => {
                                                onRowSelectHandler(record);
                                            },
                                        };
                                    }}
                                />
                                <Row type="flex" className="height-100 margin-bottom margin-top">
                                    <Col span={24}>
                                        <Button type="primary" onClick={(e) => onNewItemHandler(item.id)}>
                                            {geti18nText('component.configurator.table.btn.add')}
                                        </Button>
                                    </Col>
                                </Row>
                            </TabPane>
                        ))}
                </Tabs>

                <Modal
                    visible={popupVisible}
                    onCancel={closePopup}
                    keyboard={false}
                    maskClosable={false}
                    width={1200}
                    footer={[]}
                    destroyOnClose={true}
                >
                    <ConfiguratorPackageEdit
                        popupValue={popupValue}
                        groupsRef={props.groupsRef}
                        updateItemsFromModal={updateItemsFromModal}
                        getGroupNames={props.getGroupNames}
                        filter={selectedFilter}
                        dataSource={dataSource}
                        deleteItem={deleteItem}
                    ></ConfiguratorPackageEdit>
                </Modal>
            </React.Fragment>
        );
    }
});

export default ConfiguratorTablePackages;
