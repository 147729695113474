import React, { useState, useEffect } from 'react';
import { Form, Modal, Button, message, Typography, Icon, List, Avatar, Skeletonn, Input, Radio } from 'antd';
import { CONSTANTS_REQ } from '../../../utils/Constants';
import { geti18nText } from '../../../utils/Session';
import { RESPONSE, requestGet } from '../../../utils/RequestResolver';
import { Spinner } from '../../../components/Spinner';

const { Text } = Typography;

const { Search } = Input;
const WebPageComponentPreview = Form.create({ name: 'WebPageComponentPreview' })((props) => {
    const [defComponent, setDefComponent] = useState(null);
    const [maxImageWidth, setmaxImageWidth] = useState('100%');
    const [fetching, setFetching] = useState(false);
    useEffect(() => {
        if (props.component != null) {
            fetchComponent();
        }
    }, [props.component]);

    function fetchComponent() {
        setFetching(true);
        requestGet(CONSTANTS_REQ.COMPONENT.EDIT + '/' + props.component?.id).then((result) => {
            setFetching(false);
            if (result.status == RESPONSE.OK) {
                const component = result.data;
                var imgs = 0;
                if (component.image1) {
                    component.image1 = 'data:image/jpeg;base64,' + component.image1;
                    imgs += 1;
                }
                if (component.image2) {
                    component.image2 = 'data:image/jpeg;base64,' + component.image2;
                    imgs += 1;
                }
                if (component.image3) {
                    component.image3 = 'data:image/jpeg;base64,' + component.image3;
                    imgs += 1;
                }
                if (imgs > 0) {
                    //setmaxImageWidth(100 / imgs + '%');
                }

                setDefComponent(component);
            }
        });
    }

    const closeModal = () => {
        //setDefComponent(null);
        props.closeModal();
    };

    return (
        <>
            <Modal
                visible={props.visible}
                onCancel={closeModal}
                width={800}
                title={defComponent ? geti18nText('component.' + defComponent.name) : ''}
                footer={[
                    <Button onClick={(e) => props.selectComponent()}>
                        {geti18nText('webpage.edit.component.add')}
                    </Button>,
                ]}
            >
                {fetching == true ? (
                    <Spinner />
                ) : (
                    <>
                        {defComponent ? (
                            <div>
                                <div style={{ margintTop: '10px', marginBottom: '10px' }}>
                                    <Text>
                                        {defComponent ? geti18nText('component.' + defComponent.name + '.desc') : ''}
                                    </Text>
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    {defComponent.image1 && (
                                        <img
                                            style={{ maxWidth: maxImageWidth, height: 'auto', marginTop: '5px' }}
                                            src={defComponent.image1}
                                            alt="Image 1 is empty"
                                            onError={(event) => (event.target.style.display = 'none')}
                                        />
                                    )}
                                    {defComponent.image2 && (
                                        <img
                                            style={{ maxWidth: maxImageWidth, height: 'auto', marginTop: '5px' }}
                                            src={defComponent.image2}
                                            alt="Image 1 is empty"
                                            onError={(event) => (event.target.style.display = 'none')}
                                        />
                                    )}
                                    {defComponent.image3 && (
                                        <img
                                            style={{ maxWidth: maxImageWidth, height: 'auto', marginTop: '5px' }}
                                            src={defComponent.image3}
                                            alt="Image 1 is empty"
                                            onError={(event) => (event.target.style.display = 'none')}
                                        />
                                    )}
                                </div>
                            </div>
                        ) : (
                            <div>{geti18nText('component.image.empty')}</div>
                        )}
                    </>
                )}
            </Modal>
        </>
    );
});

export default WebPageComponentPreview;
