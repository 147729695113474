import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Row, Col, Button, Divider, Modal } from 'antd';
import { Session, geti18nText } from '../utils/Session';
import { requestGet, requestPut, RESPONSE } from '../utils/RequestResolver';
import { CONSTANTS_REQ } from '../utils/Constants';
import JoditEditor from 'jodit-react';
import { Spinner } from './Spinner';
import { notification, Icon } from 'antd';

const WysiwygComponentEdit = (props) => {
    const editor = useRef(null);
    const [fetching, setFetching] = useState(false);
    const [seletedLang, setSeletedLang] = useState(Session.getProjectDefaultLanguage());
    const [componentValue, setComponentValue] = useState(initComponentValue());
    const [showSpinner, setShowSpinner] = useState(true);
    const [joditValue, setJoditValue] = useState('<p>   </p>');

    useEffect(() => {
        fetch();
        props.setModalWidth('90%');
    }, [props.visible]);

    function fetch() {
        setShowSpinner(true);
        setFetching(true);
        requestGet(CONSTANTS_REQ.WEBPAGECOMPONENT.EDIT + '/' + props.id).then((result) => {
            setFetching(false);
            if (result.status === RESPONSE.OK) {
                setValues(result.data);
                setShowSpinner(false);
            } else {
                setShowSpinner(false);
            }
        });
    }

    function initComponentValue() {
        var initValue = {};
        Session.getProjectInfo().languages.forEach((value, index) => {
            const lang = Object.keys(value)[0];
            initValue[lang] = '<p></p>';
        });

        return initValue;
    }

    function setValues(component_data) {
        var json;
        if (component_data.json) {
            json = JSON.parse(component_data.json);
            if (json) {
                setComponentValue(json);
                setJoditValue(json[Session.getProjectDefaultLanguage()]);
            }
        }
    }

    function roughSizeOfObject(object) {
        var objectList = [];
        var stack = [object];
        var bytes = 0;

        while (stack.length) {
            var value = stack.pop();

            if (typeof value === 'boolean') {
                bytes += 4;
            } else if (typeof value === 'string') {
                bytes += value.length * 2;
            } else if (typeof value === 'number') {
                bytes += 8;
            } else if (typeof value === 'object' && objectList.indexOf(value) === -1) {
                objectList.push(value);

                for (var i in value) {
                    stack.push(value[i]);
                }
            }
        }
        return bytes;
    }

    function showNotification(title, message, duration, type) {
        notification[type]({
            message: title,
            description: message,
            duration: duration,
        });
    }

    function okNotification() {
        showNotification(geti18nText('app.default.save.ok'), geti18nText('app.default.save.ok.desc'), 3, 'success');
    }

    function error(message) {
        showNotification(geti18nText('app.default.save.nok'), message, 3, 'error');
    } 

    function save(e, close) {
        var size = roughSizeOfObject(componentValue);
        if (size < Session.getSetting('ux.wysiwyg-compnent-max-imagesize')) {
            close && setShowSpinner(true);
            e.preventDefault();
            requestPut(CONSTANTS_REQ.WEBPAGECOMPONENT.UPDATEJSON + '/' + props.id, null, {
                id: props.id,
                json: JSON.stringify(componentValue),
            }).then((result) => {
                if (result && result.status === RESPONSE.CREATED) {
                    console.log('okNotification');
                    okNotification();
                    close && props.closePopup();
                } else {
                    if (result && result.status === RESPONSE.BAD_REQUEST) {
                        if (result.data && result.data.field) {
                            switch (result.data.field) {
                                case 'mandatory_fields_required':
                                    console.log(geti18nText('app.default.mandatory_fields_required'));
                                    return;
                                default:
                                    break;
                            }
                        }
                    }
                    if (result.data && result.data.error) {
                        console.log(JSON.stringify(result.data.error));
                    }
                }
            });
        } else {
            notification.open({
                message: geti18nText('component.wysiwygcomponent.uplod.nok'),
                icon: <Icon type="exclamation-circle" theme="twoTone" twoToneColor="#ff0000" />,
            });
            setShowSpinner(false);
        }
    }

    function changeLang(showLang) {
        setJoditValue(componentValue[showLang]);
        setSeletedLang(showLang);
    }

    function setContent(newValue) {
        var temp = componentValue;
        temp[seletedLang] = newValue;
        setComponentValue(temp);
    }

    const config = {
        readonly: false, // all options from https://xdsoft.net/jodit/doc/
        allowTabNavigation: false,
        minHeight: '600px',
        uploader: {
            insertImageAsBase64URI: true,
        },
        removeButtons: 'fullsize',
    };

    return (
        <React.Fragment>
            <Divider>{geti18nText('component.wysiwygcomponent')}</Divider>
            {showSpinner && <Spinner />}
            {!showSpinner && (
                <div>
                    <Row gutter={24}>
                        <Col span={24}>
                            {Session.getProjectInfo().languages != null &&
                                Session.getProjectInfo().languages.map((value, index) => {
                                    const lang = Object.keys(value)[0];
                                    let color = '';
                                    if (lang == seletedLang) color = '#1890ff';
                                    return (
                                        <Button
                                            key={lang}
                                            onClick={() => {
                                                changeLang(lang);
                                            }}
                                            style={{
                                                color: color,
                                                borderColor: color,
                                                marginRight: '3px',
                                            }}
                                        >
                                            {lang}
                                        </Button>
                                    );
                                })}
                        </Col>
                    </Row>
                    <Row gutter={24} style={{ marginTop: '15px' }}>
                        <Col span={24}>
                            <React.Fragment>
                                <JoditEditor
                                    toolbar={true}
                                    ref={editor}
                                    value={joditValue}
                                    config={config}
                                    tabIndex={1} // tabIndex of textarea
                                    onBlur={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                                    onChange={(newContent) => {}}
                                />
                            </React.Fragment>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24} style={{ textAlign: 'right', marginTop: '1rem' }}>
                            <Button style={{ marginRight: '1em' }} onClick={(e) => props.closePopup()}>
                                {geti18nText('app.default.button.cancel')}
                            </Button>
                            <Button style={{ marginRight: '1em' }} type="primary" onClick={(e) => save(e, false)}>
                                {geti18nText('app.default.button.save')}
                            </Button>
                            <Button
                                type="primary"
                                style={{ background: '#31b531', borderColor: '#31b531', textColor: 'white' }}
                                onClick={(e) => {
                                    save(e, true);
                                }}
                            >
                                {geti18nText('app.default.button.save.close')}
                            </Button>
                        </Col>
                    </Row>
                </div>
            )}
        </React.Fragment>
    );
};

export default WysiwygComponentEdit;
