import React, { useState, forwardRef, useEffect } from 'react';
import { Popover } from 'antd';
import { AlphaPicker, SketchPicker } from 'react-color';
export const ColorPicker = forwardRef((props, ref) => {
    const { value, onChange } = props;
    const defColor = { r: '255', g: '255', b: '255', a: '1' };
    const [color, setColor] = useState(defColor);
    const [displayColorPicker, setDisplayColorPicker] = useState(false);
    useEffect(() => {
        if (value != null) {
            var valueColor = value;
            if (value.includes('#')) {
                valueColor = hexToRGB(value);
            }
            setColor(parseColor(valueColor));
        } else {
            setColor(defColor);
        }
    }, [value]);
    const handleClick = () => {
        let v = value;
        setDisplayColorPicker(!displayColorPicker);
    };

    const handleClose = () => {
        setDisplayColorPicker(false);
    };

    const handleChange = (color) => {
        setColor(color.rgb);

        onChange(rgbaToString(color.rgb));
    };

    const hexToRGB = (hex, alpha = 1) => {
        let parseString = hex;
        if (hex.startsWith('#')) {
            parseString = hex.slice(1, 7);
        }
        if (parseString.length !== 6) {
            return null;
        }
        const r = parseInt(parseString.slice(0, 2), 16);
        const g = parseInt(parseString.slice(2, 4), 16);
        const b = parseInt(parseString.slice(4, 6), 16);
        if (isNaN(r) || isNaN(g) || isNaN(b)) {
            return null;
        }
        return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    };

    const parseColor = (color) => {
        if (color != null && (typeof color === 'string' || color instanceof String)) {
            var rgba = color
                .substring(4, color.length - 1)
                .replace('(', '')
                .replace(/ /g, '')
                .split(',');
            return {
                r: rgba[0] ? rgba[0] : defColor.r,
                g: rgba[1] ? rgba[1] : defColor.g,
                b: rgba[2] ? rgba[2] : defColor.b,
                a: rgba[3] ? rgba[3] : defColor.a,
            };
        }
        return defColor;
    };

    const rgbaToString = (color) => {
        if (color != null) {
            return `rgba(${color.r},${color.g},${color.b},${color.a})`;
        }
        return `rgba(${defColor.r},${defColor.g},${defColor.b},${defColor.a})`;
    };

    return (
        <Popover trigger="click" content={<SketchPicker disableAlpha={false} color={color} onChange={handleChange} />}>
            <div
                style={{
                    width: '24px',
                    height: '24px',
                    marginTop: '8px',
                    borderRadius: '4px',
                    border: '2px solid',
                    borderColor: 'rgba(0, 0, 0, 0.2)',
                    background: color ? rgbaToString(color) : '#000',
                }}
                onClick={handleClick}
            >
                <div
                    style={{
                        width: '24px',
                        height: '24px%',
                        marginTop: '8px',
                        borderRadius: '4px',

                        background: color ? rgbaToString(color) : '#000',
                    }}
                />
            </div>
        </Popover>
    );
});
