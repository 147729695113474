import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    selectedPageType: 0,
    selectedFirstPage: false,
    pagePreviewToRefresh: 0,
    webPageComponentToScroll: 0
};

const webPage = createSlice({
    name: 'webPage',
    initialState,
    reducers: {
        selectPageType(state, action) {
            const { selectedPageType } = action.payload;
            state.selectedPageType = selectedPageType;
        },
        refreshPagePreview(state, action) {
            const { page } = action.payload;
            state.pagePreviewToRefresh = page;
        },
        selectFirstPage(state, action) {
            const { selectedFirstPage } = action.payload;
            state.selectedFirstPage = selectedFirstPage;
        },
        selectWebPageComponentToScroll(state,action) {
            const {webPageComponentToScroll } = action.payload;
            state.webPageComponentToScroll = webPageComponentToScroll;
        }
    },
});

export const { selectPageType, refreshPagePreview, selectFirstPage, selectWebPageComponentToScroll } = webPage.actions;

export default webPage.reducer;
