import React, { useEffect, useState } from 'react';
import { requestGet, requestPost, RESPONSE } from '../../../utils/RequestResolver';
import { CONSTANTS_REQ } from '../../../utils/Constants';
import { geti18nText, Session } from '../../../utils/Session';
import { List, Card, Icon, Row, Checkbox, Col, Button, Typography, message, Tag, Popconfirm } from 'antd';
import moment from 'moment';
import packageJson from './../../../../package.json';
import IdleTimer from 'react-idle-timer';
const Dashboard = (props) => {
    const [fetching, setFetching] = useState(false);
    const [refresh, setRefresh] = useState(undefined);
    const [values, setValues] = useState(null);
    const [nyTouristVersion, setNyTouristVersion] = useState('');
    const [checkedItems, setCheckedItems] = useState([]);
    const [disabledPushButton, setDisabledPushButton] = useState(true);
    const { Title } = Typography;

    useEffect(() => {
        setNyTouristVersion(packageJson.dependencies['@nybble/ny-tourist-components'].replace('^', ''));
        fetch();
        setValues([]);
        setCheckedItems([]);
    }, [refresh]);

    function fetch() {
        setFetching(true);
        requestGet(CONSTANTS_REQ.NEXTJSLOG.PUBLISH_LIST, {}).then((result) => {
            setFetching(false);
            if (result.status === RESPONSE.OK) {
                setValues(result.data);
            }
        });
    }

    const setTagColor = (status = null) => {
        var _status = status ? status : null;
        if (_status) {
            switch (_status) {
                case 0:
                    return 'blue';
                case 1:
                    return 'red';
                case 2:
                    return 'orange';
            }
        }
    };

    const IconText = ({ type, text, item }) => (
        <Popconfirm
            placement="top"
            title={geti18nText('projectManagement.edit.publish')}
            onConfirm={(e) => {
                publish(item);
            }}
            okText={geti18nText('app.default.button.yes')}
            cancelText={geti18nText('app.default.button.no')}
        >
            <div>
                <Icon type={type} style={{ marginRight: 8 }} />
                {text}
            </div>
        </Popconfirm>
    );

    function addActions(item) {
        if (nyTouristVersion != item.nyTouristComponentsVersion || item.status == 1) {
            return [<IconText item={item} text={geti18nText('dashboard.publish.project')} type={'cloud'}></IconText>];
        }
        return [<IconText text={''} type={''}></IconText>];
    }
    function publishProjects(e) {
        const items = checkedItems;
        items.forEach((item) => {
            publish(item.item);
        });
    }

    function publish(item) {
        if (item == undefined) return;
        requestPost(CONSTANTS_REQ.PROJECT.PUBLISH, undefined, {
            projectId: item.projectId,
            domainId: item.domainId,
            subdomain: item.subdomain,
            componentVersion: nyTouristVersion,
        }).then((result) => {
            setRefresh(new Date().getTime());
            if (result && result.status === RESPONSE.OK) {
                message.success(`${geti18nText('projectManagement.edit.publish.ok')} `);
            } else {
                message.error(`${geti18nText('projectManagement.edit.publish.error')} `);
            }
        });
    }

    const handleChange = (event, projectId, item) => {
        const items = checkedItems;
        const index = items.findIndex((item) => item.projectId == projectId);
        if (index != -1) {
            items.splice(index, 1);
        }
        if (event.target.checked == true) {
            items.push({ projectId: projectId, item: item });
        }
        setCheckedItems(items);
        setDisabledPushButton(items.length > 0 ? false : true);
    };
    let idleTimer = null;

    function onIdle(e) {
        setRefresh(true);
    }

    return (
        <React.Fragment>
            <IdleTimer
                ref={(ref) => {
                    idleTimer = ref;
                }}
                element={document}
                onIdle={onIdle}
                timeout={1000 * 60}
            />
            <Row>
                <Col style={{ marginTop: '15px' }}>
                    <Title level={3}>{geti18nText('dashboard.title')}</Title>
                </Col>
                <Col style={{ textAlign: 'right', paddingBottom: '15px' }}>
                    <Popconfirm
                        placement="left"
                        title={geti18nText('projectManagement.edit.publish')}
                        onConfirm={(e) => {
                            publishProjects();
                        }}
                        okText={geti18nText('app.default.button.yes')}
                        cancelText={geti18nText('app.default.button.no')}
                    >
                        <Button type="primary" icon="cloud" disabled={disabledPushButton}>
                            {geti18nText('dashboard.publish.projects')}
                        </Button>
                    </Popconfirm>
                </Col>
            </Row>
            {values && (
                <List
                    grid={{ gutter: 16, xs: 1, sm: 1, md: 2, lg: 4 }}
                    dataSource={values}
                    renderItem={(item) => (
                        <List.Item>
                            <Card
                                title={item.name}
                                actions={addActions(item)}
                                extra={
                                    item.nyTouristComponentsVersion != nyTouristVersion || item.status == 1 ? (
                                        <Checkbox onChange={(e) => handleChange(e, item.projectId, item)} />
                                    ) : (
                                        <div></div>
                                    )
                                }
                            >
                                <Row>
                                    <div style={{ padding: '5px' }}>
                                        {moment(item.created).format('DD.MM.YYYY HH:mm')}
                                    </div>
                                </Row>
                                <Row
                                    style={item.nyTouristComponentsVersion != nyTouristVersion ? { color: 'red' } : {}}
                                >
                                    <div style={{ padding: '5px', fontWeight: 'bold' }}>
                                        {item.nyTouristComponentsVersion
                                            ? 'v' + item.nyTouristComponentsVersion
                                            : 'v0.0.0'}
                                    </div>
                                </Row>
                                <Row style={{ textAlign: 'right' }}>
                                    <Tag
                                        style={{ padding: '2px', cursor: 'pointer', position: 'right' }}
                                        color={setTagColor(item.status)}
                                    >
                                        {geti18nText('nextjs.log.status.' + item.status)}
                                    </Tag>
                                </Row>
                            </Card>
                        </List.Item>
                    )}
                />
            )}
        </React.Fragment>
    );
};

export default Dashboard;
