import { combineReducers } from '@reduxjs/toolkit';
import projectSlicesReducer from './slices/projectSlices';
import menuSlicesReducer from './slices/menuSlice';
import fileUploadSlicesReducer from './slices/fileUploadSlices';
import webPageSlicesReducer from './slices/webPageSlice';
import filesSlicesReducer from './slices/filesSlices';
import directoryPreviewSlicesReducer from './slices/directoryPreviewSlices';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';

const rootReducer = combineReducers({
    projectSlices: projectSlicesReducer,
    menuSlices: menuSlicesReducer,
    fileUploadSlices: fileUploadSlicesReducer,
    webPageSlices: webPageSlicesReducer,
    filesSlices: filesSlicesReducer,
    directoryPreviewSlices: directoryPreviewSlicesReducer,
});

const persistConfig = {
    key: 'root',
    storage: storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default persistedReducer;
