import React, { useEffect, useState, useRef } from 'react';
import { ColorPicker } from './../ColorPicker';
import {
    Form,
    Row,
    Col,
    Input,
    Button,
    Divider,
    notification,
    Collapse,
    InputNumber,
    Slider,
    Typography,
    Select,
    Radio,
    Popconfirm,
} from 'antd';
import { Session, geti18nText } from '../../utils/Session';
import { CONSTANTS_REQ, CONSTANTS_FILE_TYPE, UPLAD_STYLE_FOLDER, UPLAD_STYLE_FONT_FOLDER } from '../../utils/Constants';
import { requestPost, RESPONSE, requestGet } from '../../utils/RequestResolver';
import { Spinner } from '../Spinner';
import { FileUpload } from '../fileUpload/FileUpload';
import { getSelectedLanguage } from '../../utils/Utils';

const DeseignEditor = Form.create({ name: 'DeseignEditor' })((props, type) => {
    const { Panel } = Collapse;
    const { Option } = Select;

    const { getFieldDecorator, setFieldsValue } = props.form;
    const { Text } = Typography;
    const componentFormRuls = {
        headervideo: ['header-component-max-width', 'component-background-color'],
        headerimagevideo: [
            'header-component-max-width',
            'component-background-color',
            'text-font-color',
            'component-title-font-color',
            'button-font-color',
            'primary-color',
            'secondary-color',
            'hover-text-color',
            'border-color',
            'hover-border-color',
        ],
        clsmheaderimage: ['container-margin-top', 'container-max-width', 'component-title-font-color'],
        headerimage: [
            'container-max-width',
            'container-margin-top',
            'component-title-font-color',
            'container-max-height',
            'header-text-divider-centering',
        ],
        textwithtitleandsubtitle: [
            'container-max-width',
            'container-margin-top',
            'component-background-color',
            'text-font-color',
            'title-font-color',
            'component-title-font-color',
            'header-text-divider-centering',
        ],
        imagewithtitleanddescription: [
            'container-max-width',
            'component-background-color',
            'content-background-color',
            'title-font-color',
            'text-font-color',
            'special-font-color',
            'link-text-color',
            'hover-text-color',
            'header-text-divider-centering',
        ],
        destinationmap: [
            'container-margin-top',
            'container-max-width',
            'text-background-color',
            'component-background-color',
            'content-background-color',
            'text-font-color',
            'title-font-color',
            'primary-color',
            'secondary-color',
            'header-text-divider-centering',
        ],
        globalmap: [
            'container-margin-top',
            'container-max-width',
            'component-background-color',
            'content-background-color',
            'title-font-color',
            'component-title-font-color',
            'header-text-divider-centering',
            'text-font-color',
            'special-font-color',
            'button-font-color',
            'text-background-color',
            'primary-color',
            'secondary-color',
        ],
        imagelinks: [
            'container-max-width',
            'component-background-color',
            'text-font-color',
            'primary-color',
            'title-font-color',
            'hover-text-color',
            'container-margin-top',
            'image-background-color',
            'header-text-divider-centering',
        ],
        videoblock: [
            'container-margin-top',
            'container-max-width',
            'component-background-color',
            'content-background-color',
            'text-font-color',
            'component-title-font-color',
            'title-font-color',
            'button-font-color',
            'primary-color',
            'link-text-color',
            'hover-text-color',
            'secondary-color',
            'border-color',
            'hover-border-color',
            'header-text-divider-centering',
        ],
        imagelinkscarousel: [
            'title-font-color',
            'container-max-width',
            'component-background-color',
            'component-title-font-color',
            'primary-color',
            'secondary-color',
            'hover-text-color',
            'header-text-divider-centering',
        ],
        icons: [
            'container-max-width',
            'component-background-color',
            'primary-color',
            'text-font-color',
            'container-margin-top',
        ],
        titlelink: [
            'container-max-width',
            'component-background-color',
            'primary-color',
            'text-font-color',
            'container-margin-top',
            'component-title-font-color',
            'header-text-divider-centering',
        ],
        videowithtext: [
            'container-max-width',
            'component-background-color',
            'content-background-color',
            'text-font-color',
            'component-title-font-color',
            'title-font-color',
            'special-font-color',
            'button-font-color',
            'primary-color',
            'secondary-color',
            'link-text-color',
            'hover-text-color',
            'border-color',
            'hover-border-color',
            'header-text-divider-centering',
        ],
        imagewithtext: [
            'container-max-width',
            'component-background-color',
            'content-background-color',
            'component-title-font-color',
            'special-font-color',
            'title-font-color',
            'text-font-color',
            'hover-text-color',
            'container-margin-top',
            'header-text-divider-centering',
            'primary-color',
        ],
        imagegrid: [
            'container-max-width',
            'component-background-color',
            'text-font-color',
            'component-title-font-color',
            'title-font-color',
            'header-text-divider-centering',
        ],
        imagescarouselwithtext: [
            'special-font-color',
            'container-max-width',
            'component-background-color',
            'content-background-color',
            'component-title-font-color',
            'primary-color',
            'secondary-color',
            'title-font-color',
            'hover-text-color',
            'text-font-color',
            'header-text-divider-centering',
        ],
        imagegallery: [
            'container-max-width',
            'component-background-color',
            'title-font-color',
            'secondary-color',
            'primary-color',
            'hover-text-color',
            'header-text-divider-centering',
        ],
        imagegallerywithdescription: [
            'container-max-width',
            'component-background-color',
            'title-font-color',
            'text-font-color',
            'special-font-color',
            'secondary-color',
            'primary-color',
            'hover-text-color',
            'container-margin-top',
            'header-text-divider-centering',
        ],
        cardimagetext: [
            'component-background-color',
            'container-max-width',
            'component-title-font-color',
            'container-margin-top',
            'text-font-color',
            'primary-color',
            'content-background-color',
            // 'special-font-color',
            'image-background-color',
            'button-font-color',
            'secondary-color',
            'hover-text-color',
        ],
        fleximagevertical: [
            'container-max-width',
            'component-background-color',
            'container-margin-top',
            'secondary-color',
            'primary-color',
            'button-font-color',
            'component-title-font-color',
            'text-font-color',
            'items-in-row',
        ],

        btccircle: [
            'container-margin-top',
            'component-background-color',
            'component-title-font-color',
            'text-font-color',
            'link-text-color',
            'hover-text-color',
            'primary-color',
            'secondary-color',
        ],
        btcreference: [
            'component-background-color',
            'content-background-color',
            'text-font-color',
            'special-font-color',
            'button-font-color',
            'secondary-color',
            'primary-color',
        ],
        btcnewsletter: [
            'container-margin-top',
            'container-max-width',
            'component-background-color',
            'component-title-font-color',
            'text-font-color',
            'special-font-color',
            'button-font-color',
            'secondary-color',
            'primary-color',
        ],
        flextitletext: [
            'container-max-width',
            'container-margin-top',
            'component-background-color',
            'text-font-color',
            'container-max-width',
            'primary-color',
            'link-text-color',
            'hover-text-color',
            'component-title-font-color',
        ],

        fleximagehorizontal: [
            'container-margin-top',
            'container-max-width',
            'component-background-color',
            'content-background-color',
            'text-font-color',
            'container-margin-top',
            'link-text-color',
            'hover-text-color',
        ],
        btcpartners: [
            'container-margin-top',
            'container-max-width',
            'component-background-color',
            'text-font-color',
            'primary-color',
            'link-text-color',
            'hover-text-color',
        ],
        pdfpreview: [
            'container-max-width',
            'container-margin-top',
            'component-background-color',
            'text-font-color',
            'primary-color',
            'component-title-font-color',
        ],
        btccareer: [
            'container-margin-top',
            'container-max-width',
            'component-background-color',
            'component-title-font-color',
            'text-font-color',
            'special-font-color',
            'button-font-color',
            'secondary-color',
            'primary-color',
        ],
        btccontact: [
            'container-margin-top',
            'container-max-width',
            'component-background-color',
            'content-background-color',
            'text-font-color',
            'primary-color',
            'link-text-color',
            'hover-text-color',
        ],
        clsmcircle: [
            'container-margin-top',
            'component-background-color',
            'component-title-font-color',
            'text-font-color',
            'link-text-color',
            'hover-text-color',
            'primary-color',
            'secondary-color',
        ],
        accomodation: [
            'container-max-width',
            'component-background-color',
            'content-background-color',
            'text-font-color',
            'title-font-color',
            'special-font-color',
            'primary-color',
            'hover-text-color',
            'link-text-color',
            'hover-text-color',
            'link-text-color',
            'header-text-divider-centering',
        ],
        facilities: [
            'container-margin-top',
            'container-max-width',
            'component-background-color',
            'content-background-color',
            'title-font-color',
            'component-title-font-color',
            'text-font-color',
            'special-font-color',
            'button-font-color',
            'link-text-color',
            'hover-text-color',
            'header-text-divider-centering',
        ],
        search: [
            'container-max-width',
            'component-background-color',
            'component-title-font-color',
            'button-font-color',
            'primary-color',
            'secondary-color',
            'title-font-color',
            'text-font-color',
            'special-font-color',
        ],
        textsearch: [
            'container-max-width',
            'content-background-color',
            'component-title-font-color',
            'button-font-color',
            'primary-color',
            'secondary-color',
            'title-font-color',
            'text-font-color',
            'special-font-color',
        ],
        videochat: [
            'container-max-width',
            'component-background-color',
            'content-background-color',
            'title-font-color',
        ],
        space: ['container-max-width', 'title-font-color'],
        textwithtitleandsubtitle: [
            'container-max-width',
            'container-margin-top',
            'component-background-color',
            'title-font-color',
            'component-title-font-color',
            'text-font-color',
            'link-text-color',
            'hover-text-color',
            'header-text-divider-centering',
        ],
        wysiwygcomponent: [
            'container-max-width',
            'container-margin-top',
            'component-background-color',
            'title-font-color',
            'component-title-font-color',
            'text-font-color',
            'link-text-color',
            'hover-text-color',
        ],
        btcMenu: [
            'primary-color',
            'title-font-color',
            'component-background-color',
            'search-btn-text',
            'search-btn-text-hover',
            'search-color',
            'search-btn-color',
        ],
        touristMenu: [
            'primary-color',
            'title-font-color',
            'text-font-color',
            'component-background-color',
            'content-background-color',
            'text-background-color',
            'search-btn-text',
            'search-btn-text-hover',
            'search-color',
            'search-btn-color',
        ],
        headerMenuSubLinks: [
            'primary-color',
            'title-font-color',
            'text-font-color',
            'component-background-color',
            'content-background-color',
            'secondary-color',
            'special-font-color',
            'container-max-width'
        ],
        kioskTime: ['text-font-color'],
        chameleonWeatherState: ['component-title-font-color', 'primary-color', 'secondary-color','border-color','text-font-color', 'button-font-color','tab-font-color'],
        btcFooter: [
            'content-background-color',
            'component-background-color',
            'component-title-font-color',
            'special-font-color',
            'primary-color',
        ],
        touristFooter: ['component-title-font-color', 'component-background-color', 'container-margin-top'],
        space: ['component-background-color'],
        instagramfeed: [
            'container-margin-top',
            'container-max-width',
            'component-background-color',
            'content-background-color',
            'text-font-color',
            'primary-color',
            'hover-text-color',
            'secondary-color',
        ],
        facebookFeed: [
            'container-margin-top',
            'container-max-width',
            'component-background-color',
            'content-background-color',
            'primary-color',
            'secondary-color',
            'items-in-row',
        ],
        slidecontent: [
            'container-max-width',
            'component-background-color',
            'content-background-color',
            'component-title-font-color',
            'text-font-color',
            'hover-text-color',
            'primary-color',
            'secondary-color',
            'container-margin-top',
            'button-font-color',
            'hover-text-color',
            'link-text-color',
            'hover-text-color',
            'container-max-height',
            'text-container-max-height',
            'image-background-color',
        ],
        magicMenu: [
            'component-background-color',
            'container-max-width',
            'title-font-color',
            'text-font-color',
            'border-color',
            'primary-color',
            'content-background-color',
            'special-font-color',
            'search-btn-text',
            'search-btn-text-hover',
            'search-color',
        ],
        magicCardMenu: [
            // 'component-background-color',
            'container-max-width',
            'title-font-color',
            'text-font-color',
            'border-color',
            'primary-color',
            'content-background-color',
            'special-font-color',
            'search-btn-text',
            'search-btn-text-hover',
            'search-color',
            'content-max-width',
            'component-alignment',
            'container-max-height',
            'content-max-height',
        ],
        footercomponent: [
            'component-background-color',
            'content-background-color',
            'container-max-width',
            'container-margin-top',
            'component-title-font-color',
            'text-font-color',
            'special-font-color',
            'button-font-color',
            'link-text-color',
            'hover-text-color',
        ],

        carddescription: [
            'component-background-color',
            'container-max-width',
            'component-title-font-color',
            'container-margin-top',
            'text-font-color',
            'primary-color',
            'content-background-color',
            'special-font-color',
            'secondary-color',
            'hover-text-color',
        ],

        carddescription2: [
            'component-background-color',
            'component-title-font-color',
            'title-font-color',
            'container-margin-top',
            'text-font-color',
            'primary-color',
            'content-background-color',
            'special-font-color',
            'secondary-color',
            'button-font-color',
            'container-max-width',
            'content-max-height',
            'content-max-width',
            'marked-font-color',
        ],
        imageslide: [
            'content-background-color',
            'container-max-width',
            'container-max-height',
            'container-margin-top',
            'primary-color',
        ],
        flexcontenthorizontal: [
            'container-max-width',
            'component-background-color',
            'content-background-color',
            'text-font-color',
            'hover-text-color',
            'primary-color',
            'container-margin-top',
            'button-font-color',
            'link-text-color',
            'container-max-height',
            'text-container-max-height',
        ],
        magicnewsletter: [
            'container-max-width',
            'component-background-color',
            'primary-color',
            'title-font-color',
            'link-text-color',
            'component-title-font-color',
            'hover-text-color',
            'text-font-color',
            'container-margin-top',
            'secondary-color',
            'border-color',
            'button-font-color',
            'margin-left',
            'special-font-color',
        ],
        magicavailability: [
            'container-max-width',
            'component-background-color',
            'content-background-color',
            'text-font-color',
            'container-margin-top',
            'button-font-color',
            'link-text-color',
            'title-font-color',
            'component-title-font-color',
            'secondary-color',
            'primary-color',
            'special-font-color',
            'image-background-color',
        ],
        configurator: [
            'component-background-color',
            'container-margin-top',
            'content-background-color',
            'container-max-width',
            'image-background-color',
            'title-font-color',
            'primary-color',
            'secondary-color',
            'button-font-color',
            'border-color',
            'component-title-font-color',
            'text-font-color',
            'special-font-color',
        ],
        clsmviewcomponent: [
            'component-background-color',
            'content-background-color',
            'container-max-width',
            'primary-color',
            'secondary-color',
            'text-font-color',
            'title-font-color',
            'component-title-font-color',
            'special-font-color',
            'container-margin-top',
        ],
        specification: [
            'primary-color',
            'secondary-color',
            'component-background-color',
            'text-font-color',
            'title-font-color',
            'container-margin-top',
            'container-max-width',
            'link-text-color',
            'hover-text-color',
            'special-font-color',
            'header-text-divider-centering',
        ],
        descriptiontabs: [
            'component-background-color',
            'container-max-width',
            //  'component-title-font-color',
            'title-font-color',
            'container-margin-top',
            'text-font-color',
            'primary-color',
            'content-background-color',
            // 'special-font-color',
            'image-background-color',
            'button-font-color',
            'hover-text-color',
            'selected-tab-font-color',
            'tab-font-color',
            'header-text-divider-centering',
        ],
        imagecardlinks: [
            'component-background-color',
            'container-max-width',
            'title-font-color',
            'container-margin-top',
            'text-font-color',
            'primary-color',
            'image-background-color',
            'secondary-color',
            'hover-text-color',
            'header-text-divider-centering',
        ],
        calendar: [
            'component-background-color',
            'container-max-width',
            'container-margin-top',
            'content-background-color',
            'component-title-font-color',
            'text-font-color',
            'primary-color',
            'secondary-color',
            'special-font-color',
            'border-color',
            'button-font-color',
            'link-text-color',
            'hover-text-color',
            'text-font-color',
            'marked-font-color',
            'container-max-height',
            'emphasis-font-color',
            'title-font-color',
        ],
        tabWithListAndDescription: [
            'component-background-color',
            'container-max-width',
            'container-margin-top',
            'primary-color',
            'secondary-color',
            'special-font-color',
            'border-color',
            'marked-font-color',
            'text-font-color',
            'button-font-color',
            'component-title-font-color',
            'title-font-color',
            'emphasis-font-color',
        ],
    };

    const projectFromExceptionRules = ['header-component-max-width', 'hover-border-color'];

    function showInputProperty(key) {
        if (
            props.type == 'project' &&
            props.projectId != null &&
            key != 'search-btn-text' &&
            key != 'search-btn-text-hover' &&
            key != 'search-color' &&
            key != 'search-btn-color'
        ) {
            const rules = projectFromExceptionRules;
            if (rules && rules.includes(key)) {
                return false;
            }
            return true;
        }

        const componentName = props.componentName;
        if (componentName) {
            const rules = componentFormRuls[componentName];
            if (rules && rules.includes(key)) {
                return true;
            }
        }
        return false;
    }

    const [jsonData, setJsonData] = useState({
        'text-background-color': null,
        'text-background-image': null,
        'text-background-opacity': null,
        'text-background-attachment': null,
        'text-background-position': null,
        'text-background-repeat': null,
        'text-background-size': null,
        'component-background-color': null,
        'component-background-image': null,
        'component-background-opacity': null,
        'component-background-attachment': null,
        'component-background-position': null,
        'component-background-repeat': null,
        'component-background-size': null,
        'content-background-color': null,
        'content-background-image': null,
        'content-background-opacity': null,
        'content-background-attachment': null,
        'content-background-position': null,
        'content-background-repeat': null,
        'content-background-size': null,
        'background-color': null,
        'background-image': null,
        'background-opacity': null,
        'background-attachment': null,
        'background-position': null,
        'background-repeat': null,
        'background-size': null,
        'title-font-color': null,
        'title-font-family': null,
        'title-font-size': null,
        'title-font-weight': null,
        'title-line-height': null,
        'component-title-font-color': null,
        'component-title-font-family': null,
        'component-title-font-size': null,
        'component-title-font-weight': null,
        'component-title-line-height': null,
        'text-font-color': null,
        'text-font-family': null,
        'text-font-size': null,
        'text-font-weight': null,
        'text-line-height': null,
        'special-font-color': null,
        'special-font-size': null,
        'special-font-family': null,
        'special-font-weight': null,
        'special-line-height': null,
        'button-font-color': null,
        'button-font-size': null,
        'button-font-family': null,
        'button-font-weight': null,
        'button-line-height': null,
        'container-margin-top': null,
        'container-max-width': null,
        'container-max-height': null,
        'text-container-max-height': null,
        'primary-color': null,
        'secondary-color': null,
        'image-background-color': null,
        'hover-text-color': null,
        'header-component-max-width': null,
        'items-in-row': null,
        'link-text-color': null,
        'border-color': null,
        'selected-tab-font-color': null,
        'tab-font-color': null,
        'header-text-divider-centering': null,
        'content-max-width': null,
        'content-max-height': null,
        'marked-font-color': null,
        'marked-font-size': null,
        'marked-font-family': null,
        'marked-font-weight': null,
        'marked-line-height': null,
        'emphasis-font-color': null,
        'emphasis-font-size': null,
        'emphasis-font-family': null,
        'emphasis-font-weight': null,
        'emphasis-line-height': null,
        'component-alignment': null,
    });

    const fontGroups = [
        {
            value: 'title',
            name: geti18nText('design.settings.component.text_option.title'),
        },
        {
            value: 'component-title',
            name: geti18nText('design.settings.component.text_option.component_title'),
        },
        {
            value: 'text',
            name: geti18nText('design.settings.component.text_option.text'),
        },
        {
            value: 'special',
            name: geti18nText('design.settings.component.text_option.special'),
        },
        {
            value: 'marked',
            name: geti18nText('design.settings.component.text_option.marked'),
        },
        {
            value: 'emphasis',
            name: geti18nText('design.settings.component.text_option.emphasis'),
        },
        {
            value: 'button',
            name: geti18nText('design.settings.component.text_option.button'),
        },
    ];

    // const [fontGroups, setFontGroups] = useEState()

    const backgroundGroups = [
        {
            value: '',
            name: geti18nText('design.settings.component.background_option.project'),
        },
        {
            value: 'component',
            name: geti18nText('design.settings.component.background_option.component'),
        },
        {
            value: 'content',
            name: geti18nText('design.settings.component.background_option.content'),
        },
        {
            value: 'text',
            name: geti18nText('design.settings.component.background_option.text'),
        },
    ];
    const [backgroundGroupSelected, seBackgroundGroupSelect] = useState('component');
    const [fetching, setFetching] = useState(false);

    const defaultFontsList = [
        'Arial, sans-serif',
        'Verdana, sans-serif',
        'Helvetica, sasns-serif',
        'Times New Roman, serif',
        'Georgia, serif',
        'Garamond, serif',
        'SourceSansPro',
        'Roboto',
        'Playfair-Display',
    ];
    const [fonts, setGlobalFonts] = useState(defaultFontsList);
    const [fontSize, setFontSize] = useState(null);
    const [fontFamilySelect, setFontFamilySelect] = useState('');
    const [fontColorSelect, setFontColorSelect] = useState();
    const [fontGroupSelected, setFontGroupSelect] = useState('title');
    const [containerMarginTop, setContainerMarginTop] = useState();
    const [containerWidthPercent, setContainerWidthPercent] = useState();
    const [containerWidthPx, setContainerWidthPx] = useState();
    const [headerWidthPercent, setHeaderWidthPercent] = useState();
    const [headerWidthPx, setHeaderWidthPx] = useState();
    const [fontLineHeightSelected, setFontLineHeightSelected] = useState();
    const [fontLineHeightUnitSelected, setFontLineHeightUnitSelected] = useState('none');
    const [fontLineHeightPreview, setFontLinePreview] = useState();
    const [componentAlignment, setComponentAlignment] = useState(null);
    const fontDefaultGroup = useRef();
    const backgroundDefaultGroup = useRef();
    const fontWeights = [
        'normal',
        'bold',
        'bolder',
        'lighter',
        '100',
        '200',
        '300',
        '400',
        '500',
        '600',
        '700',
        '800',
        '900',
        'initial',
        'inherit',
    ];
    const fontWeightsCustom = ['normal', 'bold'];
    const [fontWeightSelect, setFontWeightSelect] = useState();
    useEffect(() => {
        fontDefaultGroup.current = fontGroups.filter((x) => showInputProperty(x.value + '-font-color'))[0]?.value;
        backgroundDefaultGroup.current = backgroundGroups.filter((x) =>
            showInputProperty(x.value + '-background-color')
        )[0]?.value;

        fetch(backgroundDefaultGroup.current ? backgroundDefaultGroup.current : backgroundGroups[0]?.value);
        getFonts();
        setFontGroupSelect(fontDefaultGroup.current ? fontDefaultGroup.current : 'title');
        seBackgroundGroupSelect(
            backgroundDefaultGroup.current ? backgroundDefaultGroup.current : backgroundGroups[0]?.value
        );
    }, []);

    function getFonts() {
        requestGet(CONSTANTS_REQ.DIRECTORY.TREE + '/' + 0, {
            directory: UPLAD_STYLE_FONT_FOLDER,
        }).then((result) => {
            if (result.status === RESPONSE.OK) {
                console.log(result);
                if (Array.isArray(result.data)) {
                    setFonts(result.data);
                }
            }
        });
    }

    function loadFont(font, refresh = false) {
        if (font == null) return;
        let n = font.name.split('.')[0];
        let url = CONSTANTS_REQ.STATIC + 0 + '/' + UPLAD_STYLE_FONT_FOLDER + '/' + font.name;
        var junction_font = new FontFace(
            n,
            `url(${CONSTANTS_REQ.STATIC + 0 + '/' + UPLAD_STYLE_FONT_FOLDER + '/' + font.name})`
        );
        return junction_font
            .load()
            .then((loaded_face) => {
                document.fonts.add(loaded_face);
                if (refresh == true) {
                    setGlobalFonts(fonts.concat(n));
                }
                return n;
            })
            .catch((error) => {
                console.log(error);
                return null;
            });
    }

    function setFonts(data) {
        var promisses = [];
        data.forEach((font) => {
            promisses.push(loadFont(font));
        });
        Promise.all(promisses).then((list) => {
            var result = [];
            list.forEach((item) => {
                if (result.indexOf(item) < 0 && item != null) {
                    result.push(item);
                }
            });

            setGlobalFonts(fonts.concat(result));
        });
    }

    function setFontStyle(key, data = jsonData) {
        if (key == null) return;
        setFontGroupSelect(key);
        const fSize = data[key + '-font-size']?.toString().replace('px', '');
        setFontSize(fSize);
        setFontFamilySelect(data[key + '-font-family']);
        setFontColorSelect(data[key + '-font-color']);
        setFontWeightSelect(data[key + '-font-weight']);

        if (data[key + '-line-height']) {
            let lineHeight = parseInt(data[key + '-line-height']);
            setFontLineHeightSelected(lineHeight);
            setFontLineHeightUnitSelected(data[key + '-line-height'].replace(lineHeight.toString(), ''));
            setFontLinePreview(data[key + '-line-height']);
        } else {
            setFontLineHeightSelected(null);
            setFontLineHeightUnitSelected(null);
            setFontLinePreview(null);
        }
    }
    const [backgroundOpacity, setBackgroundOpacity] = useState();
    const [backgroundAttachment, setBackgroundAttachment] = useState();
    const [backgroundPosition, setBackgroundPosition] = useState();
    const [titlePosition, setTitlePosition] = useState();
    const [backgroundRepeat, setBackgroundRepeat] = useState();
    const [backgroundSize, setBackgroundSize] = useState();
    const [backgroundColor, setBackgroundColor] = useState();
    const titlePositionOptions = ['center', 'left', 'right'];
    const titleOptionsHr = ['Centar', 'Lijevo', 'Desno'];
    const titleOptionsEn = ['Center', 'Left', 'Right'];
    function setBackgroundStyle(key, data = jsonData) {
        if (key == null) return;
        seBackgroundGroupSelect(key?.replace('-', ''));
        setBackgroundAttachment(data[key + 'background-attachment']);
        setBackgroundOpacity(data[key + 'background-opacity']);
        const bcolor = data[key + 'background-color'];
        setBackgroundColor(data[key + 'background-color']);
        setBackgroundPosition(data[key + 'background-position']);
        setTitlePosition(data[key + 'header-text-divider-centering']);
        setBackgroundRepeat(data[key + 'background-repeat']);
        setBackgroundSize(data[key + 'background-size']);
        if (data[key + 'background-image'] != null) {
            props.form.setFieldsValue({ 'background-image': data[key + 'background-image'] });
            setBackgroundColor(null);
        } else {
            props.form.setFieldsValue({ 'background-image': null });
        }
    }

    function setFontLineHeight(value, unit) {
        if (unit != null) {
            setFontLineHeightUnitSelected(unit);
        }
        if (value != null) {
            updateJsonDataValue(fontGroupSelected + '-line-height', `${value}${unit == 'none' ? '' : unit}`);
            setFontLinePreview(`${value}${unit}`);
            setFontLineHeightSelected(value);
        } else {
            delete jsonData[fontGroupSelected + '-line-height'];
            setFontLinePreview(null);
            setFontLineHeightSelected(null);
        }
    }

    function handleFontGroupChange(e) {
        setFontStyle(e.target.value);
    }

    function handleBackgroundGroupChange(e) {
        setBackgroundStyle(e.target.value + '-');
    }

    function onChangeFontSize(value) {
        if (value == null || value == '') {
            updateJsonDataValue(fontGroupSelected + '-font-size', null);
            return;
        }
        updateJsonDataValue(fontGroupSelected + '-font-size', value + 'px');
        setFontSize(value);
    }

    function onChangeFontFamily(value) {
        setFontFamilySelect(value);
        updateJsonDataValue(fontGroupSelected + '-font-family', value);
        setFontWeightSelect(null);
        updateJsonDataValue(fontGroupSelected + '-font-weight', null);
    }

    function onChangeFontWeight(value) {
        setFontWeightSelect(value);
        updateJsonDataValue(fontGroupSelected + '-font-weight', value);
    }

    function updateJsonDataValue(key, value) {
        var data = jsonData;
        data[key] = value;
        setJsonData(data);
    }

    function setMarginStyle(data = jsonData) {
        let containerMarginTop = data['container-margin-top']?.toString();
        setContainerMarginTop(containerMarginTop?.replace('px', ''));
        let containerWidth = data['container-max-width'];
        if (containerWidth) {
            if (containerWidth.includes('px')) {
                setContainerWidthPx(containerWidth.replace('px', ''));
            } else {
                setContainerWidthPercent(containerWidth.replace('%', ''));
            }
        }
        let headerWidth = data['header-component-max-width'];
        if (headerWidth) {
            if (headerWidth.includes('px')) {
                setHeaderWidthPx(headerWidth.replace('px', ''));
            } else {
                setHeaderWidthPercent(headerWidth.replace('%', ''));
            }
        }
    }

    function onChangeCntainerMarginTop(value) {
        if (value === null || value === '') {
            setContainerMarginTop(value);
            delete jsonData['container-margin-top'];
            return;
        }
        updateJsonDataValue('container-margin-top', value + 'px');
        setContainerMarginTop(value);
    }

    function onChangeContainerWidth(value, unit = 'px') {
        unit == 'px' ? setContainerWidthPercent(null) : setContainerWidthPx(null);
        if (value === null || value === '') {
            delete jsonData['container-max-width'];
            return;
        }
        updateJsonDataValue('container-max-width', `${value}${unit}`);
        unit == 'px' ? setContainerWidthPx(value) : setContainerWidthPercent(value);
    }

    function onChangeHeaderWidth(value, unit = 'px') {
        unit == 'px' ? setHeaderWidthPercent(null) : setHeaderWidthPx(null);
        if (value === null || value === '') {
            delete jsonData['header-component-max-width'];
            return;
        }
        updateJsonDataValue('header-component-max-width', `${value}${unit}`);
        unit == 'px' ? setHeaderWidthPx(value) : setHeaderWidthPercent(value);
    }

    function fetch(backgroundGroup = null) {
        setFetching(true);
        const backgroundPrefix = backgroundGroup != null ? getBackgroundPrefix(backgroundGroup) : getBackgroundPrefix();
        var params = {};
        var constants = CONSTANTS_REQ.DESIGN_SETTINGS.PROJECT_SAVE;
        if (props.type == 'project' && props.projectId != null) {
            params = { projectId: props.projectId };
            constants = CONSTANTS_REQ.DESIGN_SETTINGS.PROJECT_SAVE;
        } else if (props.type == 'page_component' && props.webPageComponentId != undefined) {
            params = {
                webPageComponentId: props.webPageComponentId,
            };
            constants = CONSTANTS_REQ.DESIGN_SETTINGS.PAGE_COMPONENT_SAVE;
        } else if (props.type == 'component' && props.projectId != null && props.pageComponentId) {
            params = {
                projectId: props.projectId,
                componentId: props.pageComponentId,
            };
            constants = CONSTANTS_REQ.DESIGN_SETTINGS.COMPONENT_SAVE;
        }

        requestGet(constants, params).then((result) => {
            setFetching(false);
            if (result.status === RESPONSE.OK) {
                if (result.data != null && result.data.cssJson != null) {
                    const json = JSON.parse(result.data.cssJson);
                    if (json['container-max-height']) {
                        json['container-max-height'] = json['container-max-height'].replaceAll('px', '');
                    }
                    if (json['content-max-height']) {
                        json['content-max-height'] = json['content-max-height'].replaceAll('px', '');
                    }
                    if (json['content-max-width']) {
                        json['content-max-width'] = json['content-max-width'].toString().replaceAll('px', '');
                    }
                    if (json['text-container-max-height']) {
                        json['text-container-max-height'] = json['text-container-max-height'].replaceAll('px', '');
                    }
                    if (json['component-alignment']) {
                        setComponentAlignment(json['component-alignment']);
                    }
                    setJsonData(json);
                    setValues(json);
                    setFontStyle(fontDefaultGroup.current ? fontDefaultGroup.current : fontGroups[0]?.value, json);
                    setBackgroundStyle(backgroundPrefix, json);
                    setMarginStyle(json);
                }
            }
        });
    }

    function resetAllSettings(json = jsonData) {
        Object.keys(json).forEach((key) => {
            json[key] = null;
        });
        setJsonData(json);
        setValues(json);
        setFontStyle(fontDefaultGroup.current ? fontDefaultGroup.current : fontGroups[0]?.value, json);
        setBackgroundStyle(getBackgroundPrefix(), json);
        setMarginStyle(json);
    }

    function setValues(json) {
        props.form.resetFields();
        setFieldsValue(json);
    }

    function save(e) {
        e.preventDefault();
        console.log(jsonData);
        props.form.validateFieldsAndScroll((err, values) => {
            if (values['background-image'] == null) {
                // delete jsonData['background-color'];
                delete jsonData['background-image'];
                delete jsonData['component-background-image'];
                delete jsonData['content-background-image'];
                delete jsonData['text-background-image'];
            }

            Object.keys(jsonData).forEach((key) => {
                if (jsonData[key] == null || jsonData[key] == '') {
                    delete jsonData[key];
                }
                if (jsonData['component-background-image'] != null) {
                    delete ['component-background-color'];
                }
                if (jsonData['content-background-image'] != null) {
                    delete ['content-background-color'];
                }
                if (jsonData['text-background-image'] != null) {
                    delete ['text-background-color'];
                }
            });
            if (jsonData['container-max-height'] != null) {
                jsonData['container-max-height'] = jsonData['container-max-height'] + 'px';
            }
            if (jsonData['content-max-height'] != null) {
                jsonData['content-max-height'] = jsonData['content-max-height'] + 'px';
            }
            if (jsonData['content-max-width'] != null) {
                jsonData['content-max-width'] = jsonData['content-max-width'] + 'px';
            }
            if (jsonData['text-container-max-height'] != null) {
                jsonData['text-container-max-height'] = jsonData['text-container-max-height'] + 'px';
            }
            var params = { cssJson: JSON.stringify(jsonData) };
            var constants = CONSTANTS_REQ.DESIGN_SETTINGS.PROJECT_SAVE;
            if (props.type == 'project' && props.projectId != null) {
                params.projectId = props.projectId;
                constants = CONSTANTS_REQ.DESIGN_SETTINGS.PROJECT_SAVE;
            } else if (props.type == 'page_component' && props.webPageComponentId != undefined) {
                params.webPageComponentId = props.webPageComponentId;
                constants = CONSTANTS_REQ.DESIGN_SETTINGS.PAGE_COMPONENT_SAVE;
            } else if (props.type == 'component' && props.projectId != null && props.pageComponentId) {
                params.projectId = props.projectId;
                params.componentId = props.pageComponentId;
                constants = CONSTANTS_REQ.DESIGN_SETTINGS.COMPONENT_SAVE;
            }

            requestPost(constants, null, params).then((result) => {
                if (result && result.status === RESPONSE.OK) {
                    console.log('okNotification');
                    okNotification();
                } else {
                    if (result.data && result.data.error) {
                        console.log(JSON.stringify(result.data.error));
                        error();
                    }
                }
                if (props.closePopup) {
                    props.closePopup();
                }
            });
        });
    }

    function showNotification(title, message, duration, type) {
        notification[type]({
            message: title,
            description: message,
            duration: duration,
        });
    }

    function okNotification() {
        showNotification(geti18nText('app.default.save.ok'), geti18nText('app.default.save.ok.desc'), 3, 'success');
    }

    function error() {
        showNotification(geti18nText('app.default.save.nok'), geti18nText('app.default.save.nok.desc'), 0, 'error');
    }

    function getBackgroundPrefix(group = backgroundGroupSelected) {
        //   if (props.type != 'project') {
        return group == 'component' ? 'component-' : group == 'text' ? 'text-' : group == '' ? '' : 'content-';
        // }
        // return '';
    }

    if (fetching) return <Spinner />;
    return (
        <React.Fragment>
            <Divider>{geti18nText('design.settings.component.menu')}</Divider>
            <Form>
                <Row>
                    <Collapse style={{ marginBottom: '15px' }} accordion>
                        <Panel
                            header={
                                props.type != 'project'
                                    ? geti18nText('design.settings.component.backround.section_component')
                                    : geti18nText('design.settings.component.backround.section_project')
                            }
                            key="1"
                            forceRender={true}
                        >
                            <Row>
                                <Radio.Group value={backgroundGroupSelected} onChange={handleBackgroundGroupChange}>
                                    {backgroundGroups
                                        .filter((x) => showInputProperty(x.value + '-background-color'))
                                        .map((element, index) => {
                                            {
                                                return (
                                                    <Radio.Button key={index} value={element.value}>
                                                        {element.name}
                                                    </Radio.Button>
                                                );
                                            }
                                        })}
                                </Radio.Group>
                            </Row>

                            <Row gutter={16}>
                                <Form.Item label={geti18nText('design.settings.component.backround_color')}>
                                    <Row gutter={12}>
                                        <Col span={4}>
                                            <Input
                                                value={backgroundColor}
                                                onChange={(e) => {
                                                    let key = getBackgroundPrefix() + 'background-color';
                                                    updateJsonDataValue(key, e.target.value);
                                                    setBackgroundColor(e.target.value);
                                                }}
                                            ></Input>
                                        </Col>

                                        <Col span={2}>
                                            <ColorPicker
                                                value={backgroundColor}
                                                onChange={(value) => {
                                                    let key = getBackgroundPrefix() + 'background-color';
                                                    updateJsonDataValue(key, value);
                                                    setBackgroundColor(value);
                                                }}
                                            ></ColorPicker>
                                        </Col>
                                    </Row>
                                </Form.Item>
                            </Row>
                            <Row hidden={false}>
                                <Form.Item label={geti18nText('design.settings.component.backround_image')}>
                                    {getFieldDecorator(
                                        'background-image',
                                        {}
                                    )(
                                        <FileUpload
                                            fileType={CONSTANTS_FILE_TYPE.IMAGE}
                                            folderValue={UPLAD_STYLE_FOLDER + '/' + Session.getProjectId()}
                                            visible={false}
                                            disabled={false}
                                            //  multiple={true}
                                            folderRootTreeValue={UPLAD_STYLE_FOLDER + '/' + Session.getProjectId()}
                                            map={{ id: 'id', label: 'name' }}
                                            searchBy="name"
                                            addedData={{ projectId: 0, fileType: CONSTANTS_FILE_TYPE.IMAGE }}
                                            onChange={(value) => {
                                                let key = getBackgroundPrefix() + 'background-image';
                                                updateJsonDataValue(key, value);
                                                updateJsonDataValue(getBackgroundPrefix() + 'background-color', null);
                                                setBackgroundColor(null);
                                            }}
                                        />
                                    )}
                                </Form.Item>
                            </Row>
                            <Row>
                                <Col span={4}>
                                    <Form.Item label={geti18nText('design.settings.component.backround_image.opacity')}>
                                        <InputNumber
                                            min={0}
                                            max={1}
                                            style={{ margin: '0 16px' }}
                                            step={0.01}
                                            value={backgroundOpacity}
                                            onChange={(value) => {
                                                let key = getBackgroundPrefix() + 'background-opacity';
                                                updateJsonDataValue(key, value);
                                                setBackgroundOpacity(value);
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={4}>
                                    <Form.Item
                                        label={geti18nText('design.settings.component.backround_image.attachment')}
                                    >
                                        <Select
                                            value={backgroundAttachment}
                                            onSelect={(value) => {
                                                let key = getBackgroundPrefix() + 'background-attachment';
                                                updateJsonDataValue(key, value);
                                                setBackgroundAttachment(value);
                                            }}
                                            style={{ width: '90%' }}
                                        >
                                            {['unset', 'fixed', 'local', 'scroll', 'inherit', 'initial'].map(
                                                (value, index) => {
                                                    return (
                                                        <Option key={index} value={value}>
                                                            {value}
                                                        </Option>
                                                    );
                                                }
                                            )}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={4}>
                                    <Form.Item
                                        label={geti18nText('design.settings.component.backround_image.position')}
                                    >
                                        <Select
                                            value={backgroundPosition}
                                            onSelect={(value) => {
                                                let key = getBackgroundPrefix() + 'background-position';
                                                updateJsonDataValue(key, value);
                                                setBackgroundPosition(value);
                                            }}
                                            style={{ width: '90%' }}
                                        >
                                            {['center', 'left', 'right', 'top', 'unset'].map((value, index) => {
                                                return (
                                                    <Option key={index} value={value}>
                                                        {value}
                                                    </Option>
                                                );
                                            })}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={4}>
                                    <Form.Item label={geti18nText('design.settings.component.backround_image.repeat')}>
                                        <Select
                                            value={backgroundRepeat}
                                            onSelect={(value) => {
                                                let key = getBackgroundPrefix() + 'background-repeat';
                                                updateJsonDataValue(key, value);
                                                setBackgroundRepeat(value);
                                            }}
                                            style={{ width: '90%' }}
                                        >
                                            {[
                                                'no-repeat',
                                                'repeat',
                                                'repeat-x',
                                                'repeat-y',
                                                'round',
                                                'space',
                                                'unset',
                                            ].map((value, index) => {
                                                return (
                                                    <Option key={index} value={value}>
                                                        {value}
                                                    </Option>
                                                );
                                            })}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={4}>
                                    <Form.Item label={geti18nText('design.settings.component.backround_image.size')}>
                                        <Select
                                            value={backgroundSize}
                                            onSelect={(value) => {
                                                let key = getBackgroundPrefix() + 'background-size';
                                                updateJsonDataValue(key, value);
                                                setBackgroundSize(value);
                                            }}
                                            style={{ width: '90%' }}
                                        >
                                            {['auto', 'contain', 'cover', 'unset'].map((value, index) => {
                                                return (
                                                    <Option key={index} value={value}>
                                                        {value}
                                                    </Option>
                                                );
                                            })}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Panel>

                        <Panel
                            header={geti18nText('design.settings.component.font.section')}
                            key="2"
                            // forceRender={true}
                        >
                            <Row hidden={false}>
                                <Form.Item /* label={geti18nText('design.settings.component.font_upload')} */>
                                    {getFieldDecorator(
                                        'font-family',
                                        {}
                                    )(
                                        //7155
                                        <FileUpload
                                            fileType={CONSTANTS_FILE_TYPE.FONT}
                                            folderValue={UPLAD_STYLE_FONT_FOLDER}
                                            visible={false}
                                            disabled={false}
                                            fontSelect={true}
                                            //  multiple={true}
                                            folderRootTreeValue={UPLAD_STYLE_FONT_FOLDER}
                                            map={{ id: 'id', label: 'name' }}
                                            searchBy="name"
                                            addedData={{ projectId: 0, fileType: CONSTANTS_FILE_TYPE.FONT }}
                                            onChange={(value) => {
                                                loadFont(value, true);
                                            }}
                                        />
                                    )}
                                </Form.Item>
                            </Row>

                            <Row>
                                <Radio.Group value={fontGroupSelected} onChange={handleFontGroupChange}>
                                    {fontGroups
                                        .filter((x) => showInputProperty(x.value + '-font-color'))
                                        .map((element, index) => {
                                            return (
                                                <Radio.Button key={index} value={element.value}>
                                                    {element.name}
                                                </Radio.Button>
                                            );
                                        })}
                                </Radio.Group>
                            </Row>
                            {fontDefaultGroup.current != null && (
                                <>
                                    <Row>
                                        <Form.Item label={geti18nText('design.settings.component.font_size')}>
                                            <Col span={12}>
                                                <Slider
                                                    //  min={5}
                                                    max={150}
                                                    onChange={onChangeFontSize}
                                                    value={fontSize ? fontSize : 0}
                                                />
                                            </Col>
                                            <Col span={4}>
                                                <InputNumber
                                                    min={5}
                                                    max={230}
                                                    style={{ margin: '0 16px' }}
                                                    value={fontSize}
                                                    onChange={onChangeFontSize}
                                                />
                                            </Col>
                                        </Form.Item>
                                    </Row>
                                    <Row gutter={24}>
                                        <Col span={11}>
                                            <Form.Item label={geti18nText('design.settings.component.font_color')}>
                                                <Col span={8}>
                                                    <Input
                                                        value={fontColorSelect}
                                                        onChange={(e) => {
                                                            updateJsonDataValue(
                                                                fontGroupSelected + '-font-color',
                                                                e.target.value
                                                            );
                                                            setFontColorSelect(e.target.value);
                                                        }}
                                                    ></Input>
                                                </Col>

                                                <Col span={3}>
                                                    <ColorPicker
                                                        value={fontColorSelect}
                                                        onChange={(value) => {
                                                            updateJsonDataValue(
                                                                fontGroupSelected + '-font-color',
                                                                value
                                                            );
                                                            setFontColorSelect(value);
                                                        }}
                                                    ></ColorPicker>
                                                </Col>
                                            </Form.Item>
                                        </Col>
                                        <Col span={10}>
                                            <Form.Item label={geti18nText('design.settings.component.font_family')}>
                                                <Select value={fontFamilySelect} onSelect={onChangeFontFamily}>
                                                    {fonts.map((value, index) => {
                                                        return (
                                                            <Option key={index} value={value}>
                                                                {value}
                                                            </Option>
                                                        );
                                                    })}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col span={10}>
                                            <Form.Item label={geti18nText('design.settings.component.font_weight')}>
                                                <Select value={fontWeightSelect} onSelect={onChangeFontWeight}>
                                                    {defaultFontsList.includes(fontFamilySelect) ||
                                                    fontFamilySelect == null
                                                        ? fontWeights.map((value, index) => {
                                                              return (
                                                                  <Option key={index} value={value}>
                                                                      {value}
                                                                  </Option>
                                                              );
                                                          })
                                                        : fontWeightsCustom.map((value, index) => {
                                                              return (
                                                                  <Option key={index} value={value}>
                                                                      {value}
                                                                  </Option>
                                                              );
                                                          })}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Form.Item label={geti18nText('design.settings.component.line_height')}>
                                            <InputNumber
                                                min={0}
                                                max={5000}
                                                style={{ margin: '0 16px' }}
                                                value={fontLineHeightSelected}
                                                onChange={(e) => setFontLineHeight(e, fontLineHeightUnitSelected)}
                                            />

                                            <Radio.Group
                                                value={fontLineHeightUnitSelected}
                                                onChange={(e) => {
                                                    setFontLineHeight(fontLineHeightSelected, e.target.value);
                                                }}
                                            >
                                                {['rem', 'px', '%', 'none'].map((element, index) => {
                                                    return (
                                                        <Radio.Button key={index} value={element}>
                                                            {element}
                                                        </Radio.Button>
                                                    );
                                                })}
                                            </Radio.Group>
                                        </Form.Item>
                                    </Row>

                                    <Row>
                                        <Text
                                            style={{
                                                fontSize: `${fontSize}px`,
                                                fontFamily: fontFamilySelect,
                                                color: fontColorSelect,
                                                fontWeight: fontWeightSelect,
                                                lineHeight: fontLineHeightPreview,
                                            }}
                                        >
                                            {geti18nText('design.settings.component.preview')}
                                        </Text>
                                    </Row>
                                </>
                            )}
                        </Panel>
                        <Panel
                            header={geti18nText('design.settings.component.margin.section')}
                            key="3"
                            // forceRender={true}
                        >
                            {/* visina teksta */}
                            <Row hidden={!showInputProperty('container-margin-top')} gutter={24}>
                                <Form.Item label={geti18nText('design.settings.component.container_margin_top')}>
                                    <Col span={4}>
                                        <InputNumber
                                            min={0}
                                            step={1}
                                            max={5000}
                                            style={{ margin: '0 16px' }}
                                            value={containerMarginTop}
                                            onChange={onChangeCntainerMarginTop}
                                        />
                                        <Text>px</Text>
                                    </Col>
                                </Form.Item>
                            </Row>
                            <Row hidden={!showInputProperty('container-max-width')} gutter={24}>
                                <Form.Item label={geti18nText('design.settings.component.container_max_width')}>
                                    <Col span={4}>
                                        <InputNumber
                                            min={0}
                                            max={5000}
                                            style={{ margin: '0 16px' }}
                                            value={containerWidthPx}
                                            onChange={(e) => onChangeContainerWidth(e, 'px')}
                                        />
                                        <Text>px</Text>
                                    </Col>
                                    <Col span={4}>
                                        <InputNumber
                                            min={0}
                                            max={100}
                                            style={{ margin: '0 16px' }}
                                            value={containerWidthPercent}
                                            onChange={(e) => onChangeContainerWidth(e, '%')}
                                        />
                                        <Text>%</Text>
                                    </Col>
                                </Form.Item>
                                <Form.Item label={geti18nText('design.settings.component.container_max_height')}>
                                    <Col hidden={!showInputProperty('container-max-height')} span={4}>
                                        <InputNumber
                                            min={0}
                                            max={5000}
                                            style={{ margin: '0 16px' }}
                                            value={jsonData['container-max-height']}
                                            onChange={(value) => {
                                                setJsonData({ ...jsonData, 'container-max-height': value });
                                            }}
                                        />
                                        <Text>px</Text>
                                    </Col>
                                </Form.Item>

                                <Form.Item label={geti18nText('design.settings.component.content_max_width')}>
                                    <Col hidden={!showInputProperty('content-max-width')} span={4}>
                                        <InputNumber
                                            min={0}
                                            max={5000}
                                            style={{ margin: '0 16px' }}
                                            value={jsonData['content-max-width']}
                                            onChange={(value) => {
                                                setJsonData({ ...jsonData, 'content-max-width': value });
                                            }}
                                        />
                                        <Text>px</Text>
                                    </Col>
                                </Form.Item>

                                <Form.Item label={geti18nText('design.settings.component.content_max_height')}>
                                    <Col hidden={!showInputProperty('content-max-height')} span={4}>
                                        <InputNumber
                                            min={0}
                                            max={5000}
                                            style={{ margin: '0 16px' }}
                                            value={jsonData['content-max-height']}
                                            onChange={(value) => {
                                                setJsonData({ ...jsonData, 'content-max-height': value });
                                            }}
                                        />
                                        <Text>px</Text>
                                    </Col>
                                </Form.Item>
                                <Form.Item label={geti18nText('design.settings.component.text_container_max_height')}>
                                    <Col hidden={!showInputProperty('text-container-max-height')} span={4}>
                                        <InputNumber
                                            min={0}
                                            max={5000}
                                            style={{ margin: '0 16px' }}
                                            value={jsonData['text-container-max-height']}
                                            onChange={(value) => {
                                                setJsonData({ ...jsonData, 'text-container-max-height': value });
                                            }}
                                        />
                                        <Text>px</Text>
                                    </Col>
                                </Form.Item>
                            </Row>
                            <Row hidden={!showInputProperty('header-component-max-width')}>
                                <Form.Item label={geti18nText('design.settings.component.header_max_width')}>
                                    <Col span={4}>
                                        <InputNumber
                                            min={0}
                                            max={5000}
                                            style={{ margin: '0 16px' }}
                                            value={headerWidthPx}
                                            onChange={(e) => onChangeHeaderWidth(e, 'px')}
                                        />
                                        <Text>px</Text>
                                    </Col>
                                    <Col span={4}>
                                        <InputNumber
                                            min={0}
                                            max={100}
                                            style={{ margin: '0 16px' }}
                                            value={headerWidthPercent}
                                            onChange={(e) => onChangeHeaderWidth(e, '%')}
                                        />
                                        <Text>%</Text>
                                    </Col>
                                </Form.Item>
                            </Row>
                            <Row hidden={!showInputProperty('component-alignment')}>
                                <Form.Item label={geti18nText('design.settings.component.content.position')}>
                                    <Col span={4}>
                                        <Select
                                            value={componentAlignment}
                                            onSelect={(value) => {
                                                let key = 'component-alignment';
                                                updateJsonDataValue(key, value);
                                                setComponentAlignment(value);
                                            }}
                                            style={{ width: '90%' }}
                                        >
                                            {['center', 'left', 'right'].map((value, index) => {
                                                return (
                                                    <Option key={index} value={value}>
                                                        {value}
                                                    </Option>
                                                );
                                            })}
                                        </Select>
                                    </Col>
                                </Form.Item>
                            </Row>
                        </Panel>
                        <Panel key={4} header={geti18nText('design.settings.component.style.section')}>
                            <Row gutter={20}>
                                <Col span={10} hidden={!showInputProperty('primary-color')}>
                                    <Form.Item label={geti18nText('design.settings.component.primary.color')}>
                                        <Col span={8}>
                                            <Input
                                                value={jsonData['primary-color']}
                                                onChange={(e) => {
                                                    setJsonData({ ...jsonData, 'primary-color': e.target.value });
                                                }}
                                            ></Input>
                                        </Col>

                                        <Col span={2}>
                                            <ColorPicker
                                                value={jsonData['primary-color']}
                                                onChange={(value) => {
                                                    setJsonData({ ...jsonData, 'primary-color': value });
                                                }}
                                            ></ColorPicker>
                                        </Col>
                                    </Form.Item>
                                </Col>
                                <Col span={10} hidden={!showInputProperty('secondary-color')}>
                                    <Form.Item label={geti18nText('design.settings.component.secondary.color')}>
                                        <Col span={8}>
                                            <Input
                                                value={jsonData['secondary-color']}
                                                onChange={(e) => {
                                                    setJsonData({ ...jsonData, 'secondary-color': e.target.value });
                                                }}
                                            ></Input>
                                        </Col>

                                        <Col span={2}>
                                            <ColorPicker
                                                value={jsonData['secondary-color']}
                                                onChange={(value) => {
                                                    setJsonData({ ...jsonData, 'secondary-color': value });
                                                }}
                                            ></ColorPicker>
                                        </Col>
                                    </Form.Item>
                                </Col>
                                <Col span={10} hidden={!showInputProperty('image-background-color')}>
                                    <Form.Item label={geti18nText('design.settings.component.image.color')}>
                                        <Col span={8}>
                                            <Input
                                                value={jsonData['image-background-color']}
                                                onChange={(e) => {
                                                    setJsonData({
                                                        ...jsonData,
                                                        'image-background-color': e.target.value,
                                                    });
                                                }}
                                            ></Input>
                                        </Col>

                                        <Col span={2}>
                                            <ColorPicker
                                                value={jsonData['image-background-color']}
                                                onChange={(value) => {
                                                    setJsonData({ ...jsonData, 'image-background-color': value });
                                                }}
                                            ></ColorPicker>
                                        </Col>
                                    </Form.Item>
                                </Col>
                                <Col span={10} hidden={!showInputProperty('selected-tab-font-color')}>
                                    <Form.Item label={geti18nText('design.settings.component.tab.primary.font.color')}>
                                        <Col span={8}>
                                            <Input
                                                value={jsonData['selected-tab-font-color']}
                                                onChange={(e) => {
                                                    setJsonData({
                                                        ...jsonData,
                                                        'selected-tab-font-color': e.target.value,
                                                    });
                                                }}
                                            ></Input>
                                        </Col>

                                        <Col span={2}>
                                            <ColorPicker
                                                value={jsonData['selected-tab-font-color']}
                                                onChange={(value) => {
                                                    setJsonData({ ...jsonData, 'selected-tab-font-color': value });
                                                }}
                                            ></ColorPicker>
                                        </Col>
                                    </Form.Item>
                                </Col>
                                <Col span={10} hidden={!showInputProperty('tab-font-color')}>
                                    <Form.Item
                                        label={geti18nText('design.settings.component.tab.secondary.font.color')}
                                    >
                                        <Col span={8}>
                                            <Input
                                                value={jsonData['tab-font-color']}
                                                onChange={(e) => {
                                                    setJsonData({
                                                        ...jsonData,
                                                        'tab-font-color': e.target.value,
                                                    });
                                                }}
                                            ></Input>
                                        </Col>

                                        <Col span={2}>
                                            <ColorPicker
                                                value={jsonData['tab-font-color']}
                                                onChange={(value) => {
                                                    setJsonData({ ...jsonData, 'tab-font-color': value });
                                                }}
                                            ></ColorPicker>
                                        </Col>
                                    </Form.Item>
                                </Col>
                                <Col span={10} hidden={!showInputProperty('hover-text-color')}>
                                    <Form.Item label={geti18nText('design.settings.component.secondary.hover')}>
                                        <Col span={8}>
                                            <Input
                                                value={jsonData['hover-text-color']}
                                                onChange={(e) => {
                                                    setJsonData({ ...jsonData, 'hover-text-color': e.target.value });
                                                }}
                                            ></Input>
                                        </Col>

                                        <Col span={2}>
                                            <ColorPicker
                                                value={jsonData['hover-text-color']}
                                                onChange={(value) => {
                                                    setJsonData({ ...jsonData, 'hover-text-color': value });
                                                }}
                                            ></ColorPicker>
                                        </Col>
                                    </Form.Item>
                                </Col>
                                <Col span={10} hidden={!showInputProperty('link-text-color')}>
                                    <Form.Item label={geti18nText('design.settings.component.link.text.color')}>
                                        <Col span={8}>
                                            <Input
                                                value={jsonData['link-text-color']}
                                                onChange={(e) => {
                                                    setJsonData({ ...jsonData, 'link-text-color': e.target.value });
                                                }}
                                            ></Input>
                                        </Col>

                                        <Col span={2}>
                                            <ColorPicker
                                                value={jsonData['link-text-color']}
                                                onChange={(value) => {
                                                    setJsonData({ ...jsonData, 'link-text-color': value });
                                                }}
                                            ></ColorPicker>
                                        </Col>
                                    </Form.Item>
                                </Col>
                                <Col span={10} hidden={!showInputProperty('border-color')}>
                                    <Form.Item label={geti18nText('design.settings.component.border.color')}>
                                        <Col span={8}>
                                            <Input
                                                value={jsonData['border-color']}
                                                onChange={(e) => {
                                                    setJsonData({ ...jsonData, 'border-color': e.target.value });
                                                }}
                                            ></Input>
                                        </Col>

                                        <Col span={2}>
                                            <ColorPicker
                                                value={jsonData['border-color']}
                                                onChange={(value) => {
                                                    setJsonData({ ...jsonData, 'border-color': value });
                                                }}
                                            ></ColorPicker>
                                        </Col>
                                    </Form.Item>
                                </Col>

                                <Col span={10} hidden={!showInputProperty('hover-border-color')}>
                                    <Form.Item label={geti18nText('design.settings.component.hover.border.color')}>
                                        <Col span={8}>
                                            <Input
                                                value={jsonData['hover-border-color']}
                                                onChange={(e) => {
                                                    setJsonData({ ...jsonData, 'hover-border-color': e.target.value });
                                                }}
                                            ></Input>
                                        </Col>

                                        <Col span={2}>
                                            <ColorPicker
                                                value={jsonData['hover-border-color']}
                                                onChange={(value) => {
                                                    setJsonData({ ...jsonData, 'hover-border-color': value });
                                                }}
                                            ></ColorPicker>
                                        </Col>
                                    </Form.Item>
                                </Col>
                                <Col span={10} hidden={!showInputProperty('items-in-row')}>
                                    <Form.Item label={geti18nText('design.settings.component.items.in.row')}>
                                        <Col span={8}>
                                            <InputNumber
                                                min={0}
                                                max={20}
                                                value={jsonData['items-in-row']}
                                                onChange={(n) => {
                                                    setJsonData({ ...jsonData, 'items-in-row': n });
                                                }}
                                            ></InputNumber>
                                        </Col>
                                    </Form.Item>
                                </Col>
                                <Col span={4} hidden={!showInputProperty('header-text-divider-centering')}>
                                    <Form.Item label={geti18nText('design.settings.component.title.position')}>
                                        <Select
                                            defaultValue={' '}
                                            value={
                                                getSelectedLanguage() === 'hr' &&
                                                jsonData['header-text-divider-centering']
                                                    ? titleOptionsHr.at(
                                                          titlePositionOptions.indexOf(
                                                              jsonData['header-text-divider-centering']
                                                          )
                                                      )
                                                    : titlePosition &&
                                                      titleOptionsEn.at(
                                                          titlePositionOptions.indexOf(
                                                              jsonData['header-text-divider-centering']
                                                          )
                                                      )
                                            }
                                            onSelect={(value, index) => {
                                                let key = 'header-text-divider-centering';
                                                updateJsonDataValue(key, titlePositionOptions[index.key]);
                                                setTitlePosition(titlePositionOptions[index.key]);
                                            }}
                                            style={{ width: '90%' }}
                                        >
                                            {getSelectedLanguage() === 'hr'
                                                ? titleOptionsHr.map((value, index) => {
                                                      return (
                                                          <Option key={index} value={value}>
                                                              {value}
                                                          </Option>
                                                      );
                                                  })
                                                : titleOptionsEn.map((value, index) => {
                                                      return (
                                                          <Option key={index} value={value}>
                                                              {value}
                                                          </Option>
                                                      );
                                                  })}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Panel>
                        <Panel
                            key={5}
                            hidden={!showInputProperty('search-btn-text')}
                            header={geti18nText('design.settings.search.style.section')}
                        >
                            <Row gutter={20}>
                                <Col span={10} hidden={!showInputProperty('search-btn-text')}>
                                    <Form.Item label={geti18nText('design.settings.search.style.btn_text')}>
                                        <Col span={8}>
                                            <Input
                                                value={jsonData['search-btn-text']}
                                                onChange={(e) => {
                                                    setJsonData({ ...jsonData, 'search-btn-text': e.target.value });
                                                }}
                                            ></Input>
                                        </Col>

                                        <Col span={2}>
                                            <ColorPicker
                                                value={jsonData['search-btn-text']}
                                                onChange={(value) => {
                                                    setJsonData({ ...jsonData, 'search-btn-text': value });
                                                }}
                                            ></ColorPicker>
                                        </Col>
                                    </Form.Item>
                                </Col>
                                <Col span={10} hidden={!showInputProperty('search-btn-text-hover')}>
                                    <Form.Item label={geti18nText('design.settings.search.style.btn_text_hover')}>
                                        <Col span={8}>
                                            <Input
                                                value={jsonData['search-btn-text-hover']}
                                                onChange={(e) => {
                                                    setJsonData({
                                                        ...jsonData,
                                                        'search-btn-text-hover': e.target.value,
                                                    });
                                                }}
                                            ></Input>
                                        </Col>

                                        <Col span={2}>
                                            <ColorPicker
                                                value={jsonData['search-btn-text-hover']}
                                                onChange={(value) => {
                                                    setJsonData({ ...jsonData, 'search-btn-text-hover': value });
                                                }}
                                            ></ColorPicker>
                                        </Col>
                                    </Form.Item>
                                </Col>
                                <Col span={10} hidden={!showInputProperty('search-color')}>
                                    <Form.Item label={geti18nText('design.settings.search.style.search_color')}>
                                        <Col span={8}>
                                            <Input
                                                value={jsonData['search-color']}
                                                onChange={(e) => {
                                                    setJsonData({ ...jsonData, 'search-color': e.target.value });
                                                }}
                                            ></Input>
                                        </Col>

                                        <Col span={2}>
                                            <ColorPicker
                                                value={jsonData['search-color']}
                                                onChange={(value) => {
                                                    setJsonData({ ...jsonData, 'search-color': value });
                                                }}
                                            ></ColorPicker>
                                        </Col>
                                    </Form.Item>
                                </Col>
                                <Col span={10} hidden={!showInputProperty('search-btn-color')}>
                                    <Form.Item label={geti18nText('design.settings.search.style.search_btn_color')}>
                                        <Col span={8}>
                                            <Input
                                                value={jsonData['search-btn-color']}
                                                onChange={(e) => {
                                                    setJsonData({ ...jsonData, 'search-btn-color': e.target.value });
                                                }}
                                            ></Input>
                                        </Col>

                                        <Col span={2}>
                                            <ColorPicker
                                                value={jsonData['search-btn-color']}
                                                onChange={(value) => {
                                                    setJsonData({ ...jsonData, 'search-btn-color': value });
                                                }}
                                            ></ColorPicker>
                                        </Col>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Panel>
                    </Collapse>
                </Row>

                <Row>
                    <Col span={24} style={{ textAlign: 'right', marginTop: '1rem' }}>
                        <Popconfirm
                            placement="topRight"
                            title={geti18nText('design.settings.app.default.button.reset.alert')}
                            okText={geti18nText('app.default.button.yes')}
                            cancelText={geti18nText('app.default.button.no')}
                            onConfirm={resetAllSettings}
                        >
                            <Button type="danger">{geti18nText('design.settings.app.default.button.reset')}</Button>
                        </Popconfirm>

                        <Button style={{ marginLeft: '5px' }} type="primary" onClick={(e) => save(e)}>
                            {geti18nText('app.default.button.save')}
                        </Button>
                    </Col>
                </Row>
            </Form>
        </React.Fragment>
    );
});

export default DeseignEditor;
