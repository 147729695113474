import React from "react";
import { Row, Col, Card } from "antd";
import { CONSTANTS_REQ } from "../utils/Constants";
import RLDD from "react-list-drag-and-drop/lib/RLDD";
import { getSelectedClientLanguage } from "../utils/Utils";
import { getTranslated } from "./InputByLanguage";
import { Session } from "../utils/Session";

/*
na komponenti koja se sortira potrebno je:
- dodati funkciju reorderImages
- dodati kod spremanja case za spremanje sortiranog arraya
- sakriti redove sa kontrolama, dodati ovu komponentu
- zbog prikaza item mora imati titleLangJson i rdlPath
*/
const SortImagesOrVideos = (props) => {
    const { Meta } = Card;
    const selLang = getSelectedClientLanguage();

    const itemRenderer = (item, index) => {
        return (
            <Card style={{ width: "100%", textAlign: "left" }}>
                <Row gutter={24}>
                    <Col span={5} style={{ paddingTop: "12%", backgroundPosition: "center", backgroundSize: "cover", backgroundPosition: "center center", backgroundRepeat: "no-repeat", backgroundImage: `url(${CONSTANTS_REQ.STATIC + Session.getProjectId() + encodeURI(item.rdlPath)})` }}></Col>
                    <Col span={1}></Col>
                    <Col span={16}>
                        <Meta title={getTranslated(item.titleLangJson, selLang)} description="" />
                    </Col>
                    <Col span={2}>
                        <Meta title="" description={index + 1 + "/" + Object.keys(props.images).length} />
                    </Col>
                </Row>
            </Card>
        );
    };
    const handleRLDDChange = (reorderedItems) => {
        let reordered = {};
        for (const [i, v] of reorderedItems.entries()) {
            reordered[v.id] = i;
        }
        props.reorderImages(reorderedItems);
    };

    return (
        <Row gutter={24} style={{ marginTop: "20px" }}>
            <Col span={24}>
                <RLDD items={props.images} itemRenderer={itemRenderer} onChange={handleRLDDChange} />
            </Col>
        </Row>
    );
};

export const ReorderImageKeys = (images) => {
    let arrImages = images.slice();
    let arrKeys = [];
    for (const key in arrImages) {
        if (arrImages.hasOwnProperty(key)) {
            arrKeys.push(arrImages[key].id);
        }
    }
    return arrKeys;
};

export default SortImagesOrVideos;
