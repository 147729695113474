import { Button, Col, Icon, Input, message, Modal, Row, Tree, Upload, Progress } from 'antd';
import React, { forwardRef, useState, useEffect } from 'react';
import { SelectFile } from '../../components//SelectFile';
import { Spinner } from '../../components//Spinner';
import { selectFolder } from '../../slices/fileUploadSlices';
import { CONSTANTS_FILE_TYPE, CONSTANTS_REQ, FONT_FORMATS } from '../../utils/Constants';
import { requestGet, requestPostFile, RESPONSE } from '../../utils/RequestResolver';
import { geti18nText, Session } from '../../utils/Session';
import { downloadFile } from '../../utils/Utils';
import { FilePreview } from '../filePreview/FilePreview';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { AddFolder } from '../AddFolder';
import { formatBytes } from '../../utils/Utils';
import ReactCrop from 'react-image-crop';
import { render } from 'react-dom';

const { Dragger } = Upload;

const initTreeDate = [];
const pixelRatio = window.devicePixelRatio || 1;

function updateTreeData(list, key, children) {
    return list.map((node) => {
        if (node.key === key) {
            if (children != undefined) {
                return {
                    ...node,
                    children,
                };
            } else {
                delete node.children;
                return {
                    ...node,
                };
            }
        } else if (node.children) {
            return {
                ...node,
                children: updateTreeData(node.children, key, children),
            };
        }
        return node;
    });
}

export const FileUpload = forwardRef((props, ref) => {
    const [uploadVisible, setUploadVisible] = useState(false);
    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewFile, setPreviewFile] = useState(undefined);
    const [uploading, setUploading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [treeData, setTreeData] = useState(initTreeDate);
    const [folderValue, setFolderValue] = useState(null);
    const [folderRootTreeValue, setFolderRootTreeValue] = useState(null);
    const [newFolderVisible, setNewFolderVisible] = useState(false);
    const [newFolderName, setNewFolderName] = useState(null);
    const [selectedNode, setSelectedNode] = useState(null);
    const { lastSelectedFolder } = useSelector((state) => state.fileUploadSlices);
    const [separator, setSeparator] = useState('/');
    const dispatch = useDispatch();
    const [treeDisabled, setTreeDisabled] = useState(false);
    const { map, onChange, menuUploadChange, value } = props;
    const [lastSelectedNode, setLastSelectedNode] = useState('');
    const [expandedKeys, setExpandedKeys] = useState([]);
    const [autoExpandParent, setAutoExpandParent] = useState(true);
    const [uploadingFiles, setUploadingFiles] = useState([]);
    const [uploadsWithErrorExists, setUploadsWithErrorExists] = useState(false);
    const [cropVisible, setCropVisible] = useState(false);
    const [src, setSrc] = useState(undefined);
    const [crop, setCrop] = useState({ width: 0, height: 0 });
    const [image, setImage] = useState(null);
    const [fileCrop, setFileCrop] = useState(null);
    const [isImage, setIsImage] = useState(false);

    /*
    if (props.addedData) {
        let data = props.addedData;
        console.log("data", data)
    }*/
    useEffect(() => {
        checkImage();
    }, [value]);

    useEffect(() => {
        setUploadVisible(props.visible);
        setTreeDisabled(props.disabled);
        setFolderValue(props.folderValue);
        setFolderRootTreeValue(props.folderRootTreeValue);
    }, [props.visible]);

    const showUploadModal = () => {
        setUploadVisible(true);
        setTreeDisabled(props.disabled);
        setFolderValue(props.folderValue);
        setFolderRootTreeValue(props.folderRootTreeValue);
        fetchTree();
    };

    function transformValue() {
        if (value instanceof Array && value.length > 0) {
            if (value[0].name) return value[0].name;
            else
                return value[0].file_path.substring(
                    value[0].file_path.lastIndexOf(separator) + 1,
                    value[0].file_path.length
                );
        }
        if (value instanceof Object) {
            if (value && value.name) {
                return value.name;
            } else return value.file_path.substring(value.file_path.lastIndexOf(separator) + 1, value.file_path.length);
        }

        return undefined;
    }

    function checkImage() {
        let image = transformValue();
        if (image) {
            let allowedImagesFormat = ['.png', '.jpg', '.svg', '.jpeg'];
            if (allowedImagesFormat.includes(image.toLowerCase().substring(image.lastIndexOf('.')))) {
                setIsImage(true);
            } else {
                setIsImage(false);
            }
        } else setIsImage(false);
    }
    const showCropModal = () => {
        const src =
            props.addedData.projectId == undefined
                ? CONSTANTS_REQ.STATIC_MEDIA + encodeURI(value.file_path)
                : CONSTANTS_REQ.STATIC + props.addedData.projectId + encodeURI(value.file_path);
        setSrc(src);
        setCropVisible(true);
    };

    const fetchTree = () => {
        const projectId =
            props.addedData.projectId == undefined || props.addedData.projectId == 0 ? 0 : Session.getProjectId();

        var params = {};
        if (folderRootTreeValue != null) {
            params = { directory: folderRootTreeValue };
        }

        requestGet(CONSTANTS_REQ.DIRECTORY.TREE + '/' + projectId, params).then((result) => {
            if (result.status === RESPONSE.OK) {
                if (Array.isArray(result.data)) {
                    let children = [];
                    for (const element of result.data) {
                        switch (element.type) {
                            case 'DIRECTORY':
                                children.push({
                                    title: generateNodeTitle(element.name, element.type),
                                    key: 'FOLDER-' + element.name,
                                });

                                break;
                        }
                    }

                    setTreeData(children);
                }
            }
            setLoading(false);
        });
    };

    const closeUploadModal = (force = true) => {
        if (!uploading || force) {
            setUploadVisible(false);
            if (treeDisabled) {
                menuVisibleChange();
                //setTreeDisabled(false);
            }
            setExpandedKeys([]);
            setNewFolderName(null);
            setFolderValue(null);
            setSelectedNode(null);
            setUploadingFiles([]);
        }
    };

    function showNewFolderModal(treeNode) {
        setNewFolderVisible(true);
    }

    const closeNewFolderModal = () => {
        setNewFolderVisible(false);
    };

    const showPreviewModal = () => {
        setPreviewVisible(true);

        let value = props.value;
        if (value instanceof Array && value.length > 0 && value[0]) {
            value = props.value instanceof Array && props.value.length > 0 ? props.value[0] : props.value;
        }

        const path = value.file_path ? value.file_path : undefined;
        const fileName = value.name ? value.name : undefined;
        const fileType = props.fileType;

        setPreviewFile({ path: path, fileType: fileType });

        /* requestGet(CONSTANTS_REQ.DISK_FILE.EDIT + "/" + fileId).then(result => {
            if (result.status === RESPONSE.OK && result.data) {
                setPreviewFile(result.data);
            }
            setLoading(false);
        }); */
    };

    const closePreviewModal = () => {
        setPreviewVisible(false);
        setPreviewFile(undefined);
    };

    const closeCropModal = () => {
        setCrop({ width: 0, height: 0 });
        setCropVisible(false);
    };

    const getAllowedFileTypes = () => {
        if (props.fileType == CONSTANTS_FILE_TYPE.IMAGE) {
            return 'image/*';
        } else if (props.fileType == CONSTANTS_FILE_TYPE.VIDEO) {
            return 'video/*';
        } else if (props.fileType == CONSTANTS_FILE_TYPE.APPLICATION) {
            return '.apk';
        } else if (props.fileType == CONSTANTS_FILE_TYPE.FONT) {
            return FONT_FORMATS;
        } else {
            // return '*';
            return '';
        }
    };

    const handleChange = (value) => {
        if (value) {
            if (map) {
                onChange([{ key: '' + value[map.id], label: value[map.label], value: value }]);
            } else {
                onChange([{ key: '' + value.id, label: value.text, value: value }]);
            }
        }
    };

    const menuVisibleChange = () => {
        menuUploadChange({ disabled: false });
    };

    const fileDownload = () => {
        let value = props.value;
        if (value instanceof Array && value.length > 0 && value[0]) {
            value = props.value instanceof Array && props.value.length > 0 ? props.value[0] : props.value;
        }
        var path = value.file_path ? value.file_path : undefined;
        const fileName = value.name
            ? value.name
            : value.file_path.substring(value.file_path.lastIndexOf(separator) + 1, value.file_path.length);

        setLoading(true);
        // const projectId = props.addedData.projectId == 0 ? 0 : Session.getProjectId();
        const projectId =
            props.addedData.projectId == undefined ? 0 : props.addedData.projectId == 0 ? 0 : Session.getProjectId();
        if (props.addedData.staticMedia == true) {
            path = value.file_path.substring(value.file_path.lastIndexOf(separator), value.file_path.length);
        }

        requestGet(
            CONSTANTS_REQ.DISK_FILE.DOWNLOAD,
            { projectId: projectId, relativePath: encodeURI(path) },
            false,
            true
        ).then((result) => {
            if (result.status === RESPONSE.OK && result.data) {
                result.data.filename = fileName;
                downloadFile(result);
            } else {
                message.destroy();
                message.error(`${geti18nText('upload.text.download.error')}`);
            }
            setLoading(false);
        });
    };

    function onLoadData(treeNode) {
        const key = treeNode.props.eventKey;
        return new Promise((resolve) => {
            const url = getUrlAndParamsForChildren(key + '');
            requestGet(url.url, url.params).then((result) => {
                if (result.status === RESPONSE.OK) {
                    if (Array.isArray(result.data)) {
                        let nodes = [];
                        for (const element of result.data) {
                            if (element.active == false) {
                                continue;
                            }
                            switch (element.type) {
                                case 'DIRECTORY':
                                    nodes.push({
                                        title: generateNodeTitle(element.name, 'FOLDER'),
                                        key: key + separator + element.name,
                                    });
                                    break;
                            }
                        }

                        setTreeData((origin) => updateTreeData(origin, key, nodes));
                    }
                }
                resolve();
            });
        });
    }

    function generateNodeTitle(title, type) {
        return (
            <div
                style={{
                    color:
                        type == 'NEW' ? '#1890ff' : type == 'FIRSTPAGE' || type == 'NOTINMENU' ? '#52c41a' : undefined,
                }}
            >
                {type == 'FOLDER' && <Icon type="folder" style={{ marginRight: '10px' }} />}
                {type == 'ROOT' && <Icon type="home" style={{ marginRight: '10px' }} />}
                {title}
            </div>
        );
    }

    function getUrlAndParamsForChildren(key) {
        if (key.startsWith('FILES-')) {
            return { url: CONSTANTS_REQ.DIRECTORY.TREE + '/' + getId(key) };
        } else if (key.startsWith('FOLDER-')) {
            const projectId = props.addedData.projectId == 0 ? 0 : Session.getProjectId();
            return {
                url: CONSTANTS_REQ.DIRECTORY.TREE + '/' + projectId,
                params: { directory: key.split('FOLDER-')[1] },
            };
        }

        return { url: CONSTANTS_REQ.DIRECTORY.TREE, params: {} };
    }

    function generateBranch(key, data, showInactive = false) {
        let nodes = [];
        for (const element of data) {
            if (!showInactive && element.active == false) {
                continue;
            }
            switch (element.type) {
                case 'DIRECTORY':
                    nodes.push({
                        title: generateNodeTitle(element.name, 'FOLDER'),
                        key: key + separator + element.name,
                    });
                    break;
            }
        }

        setTreeData((origin) => updateTreeData(origin, key, nodes));
    }

    function refreshNode(key, showInactive = false) {
        const url = getUrlAndParamsForChildren(key);
        requestGet(url.url, url.params).then((result) => {
            if (result.status === RESPONSE.OK) {
                if (Array.isArray(result.data)) {
                    generateBranch(key, result.data, showInactive);
                }
            }
        });
        let expanded = expandedKeys.slice();
        expanded.push(key);
        setExpandedKeys(expanded);
    }

    const getId = (key) => {
        const arr = (key + '').split('-');
        if (arr.length > 0) {
            return encodeURI(arr[1]);
        }
        return undefined;
    };

    const onSelect = (selectedKeys, info) => {
        if (selectedKeys.length == 0) {
            return;
        }

        const key = selectedKeys[0];
        setLastSelectedNode(key);
        const arr = key.split('FOLDER-');
        setFolderValue(arr[1]);
        setSelectedNode(info.node);

        let expanded = expandedKeys.slice();

        if (!expandedKeys.includes(key) && info.node.isLeaf() != true) {
            expanded.push(key);
            setExpandedKeys(expanded);
        }
    };

    function resizeImage(file) {
        return new Promise((resolve) => {
            const settings = Session.getAppValue('SETTINGS');
            const maxSize = Number(settings['upload.max-image-size']);
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                const image = document.createElement('img');
                image.src = reader.result;
                image.onload = () => {
                    const maxImageFileSize = Number(settings['upload.max-image-file-size']);
                    const fileSize = file.size / 1024 / 1024;
                    var canvas = document.createElement('canvas'),
                        max_size = maxSize,
                        width = image.width,
                        height = image.height;
                    if (image.width <= maxSize && image.height <= maxSize && fileSize < maxImageFileSize) {
                        return resolve(file);
                    }
                    if (width > height) {
                        if (width > max_size) {
                            height *= max_size / width;
                            width = max_size;
                        }
                    } else {
                        if (height > max_size) {
                            width *= max_size / height;
                            height = max_size;
                        }
                    }

                    canvas.width = width;
                    canvas.height = height;
                    const ctx = canvas.getContext('2d');
                    ctx.drawImage(image, 0, 0, width, height);
                    ctx.canvas.toBlob(
                        (blob) => {
                            message.info(geti18nText('disk.file.edit.image.resize.info'), 10);
                            resolve(
                                new File([blob], file.name, {
                                    lastModified: file.lastModified,
                                    uid: file.uid,
                                    webkitRelativePath: file.webkitRelativePath,
                                    type: 'image/jpeg',
                                })
                            );
                        },
                        'image/jpeg',
                        0.8
                    );
                };
            };
        });
    }
    function trasnsformFolderValue() {
        if (lastSelectedFolder != null) {
            setFolderValue(lastSelectedFolder);
        }
        return folderValue;
    }

    function onChangeFolderVisible(value) {
        setNewFolderVisible(value);
        //refresh nodes
        if (selectedNode) {
            refreshNode(selectedNode.props.eventKey);
        }
    }
    function getFile() {
        const projectId =
            props.addedData.projectId == undefined ? 0 : props.addedData.projectId == 0 ? 0 : Session.getProjectId();
        var path = value.file_path;
        if (props.addedData.staticMedia == true) {
            path = value.file_path.substring(value.file_path.lastIndexOf(separator), value.file_path.length);
        }

        requestGet(
            CONSTANTS_REQ.DISK_FILE.DOWNLOAD,
            { projectId: projectId, relativePath: encodeURI(path) },
            false,
            true
        ).then((result) => {
            if (result.status === RESPONSE.OK && result.data) {
                setFileCrop(result.data.file);
            }
        });
    }

    function saveCrop() {
        let valueFile = transformValue();
        const projectId =
            props.addedData.projectId == undefined ? 0 : props.addedData.projectId == 0 ? 0 : Session.getProjectId();
        croppedImg(image, crop, valueFile.substring(0, valueFile.lastIndexOf('.')) + ' crop.jpg')
            .then((blob) => {
                let form = new FormData();
                form.append('projectId', projectId);
                form.append('file', blob);
                form.append('userId', Session.getUser().id);
                form.append('folder', folderValue != null ? folderValue : '');
                requestPostFile(CONSTANTS_REQ.DISK_FILE.UPLOAD, null, form, false).then((result) => {
                    if (result && result.status === RESPONSE.CREATED) {
                        closeCropModal();
                        onChange({
                            key: folderValue
                                ? separator + folderValue + separator + result.data
                                : separator + result.data,
                            label: result.data,
                            file_path: folderValue
                                ? separator + folderValue + separator + result.data
                                : separator + result.data,
                            name: result.data,
                            file_type: props.fileType,
                        });
                    } else {
                        console.log('error');
                        closeCropModal();
                    }
                });
            })
            .catch((err) => console.log('There was an error:' + err));
    }

    const uploadFile = {
        name: 'file',
        accept: getAllowedFileTypes(),
        action: CONSTANTS_REQ.DISK_FILE.UPLOAD,
        showUploadList: false,
        multiple: props.multiple ? true : false,
        data: {
            projectId:
                props.addedData.projectId == 0 || props.addedData.projectId == undefined
                    ? 0
                    : props.addedData.projectId,
            folder: folderValue != null ? folderValue : '',
        },
        headers: {
            Authorization: 'Bearer ' + Session.getUserToken(),
        },
        beforeUpload: (file, files) => {
            if (!(file.type == 'image/jpeg' || file.type == 'image/png' || file.type == 'video/mp4')) {
                return true;
            }
            const settings = Session.getAppValue('SETTINGS');
            if (file.type == 'image/jpeg' || file.type == 'image/png') {
                const maxImageFileSize = Number(settings['upload.max-image-file-size']);
                if (file.size / 1024 / 1024 > maxImageFileSize) {
                    message.warning(
                        geti18nText('disk.file.edit.maxsize.exception') + String(maxImageFileSize) + ' MB!'
                    );
                    return true;
                } else {
                    return true;
                }
            }
            if (file.type === 'video/mp4') {
                const maxVideoFileSize = Number(settings['upload.max-video-file-size']);
                if (file.size / 1024 / 1024 > maxVideoFileSize) {
                    message.warning(
                        geti18nText('disk.file.edit.maxsize.exception') + String(maxVideoFileSize) + ' MB!'
                    );
                    return false;
                }

                var reader = new FileReader();
                var MP4Box = require('mp4box');
                var mp4box = MP4Box.createFile();
                mp4box.onError = function (e) {
                    console.log(e);
                };
                mp4box.onReady = function (info) {
                    //console.log(info);
                    if (info.tracks != null && info.tracks.length > 0) {
                        var track = info.tracks.find((x) => x.type.toLowerCase().includes('video'));
                        if (track == null) {
                            track = info.tracks[0];
                        }
                        const codec = track.codec;
                        if (codec != null && codec.substring(0, 4) != 'avc1') {
                            message.warning(geti18nText('disk.file.edit.video.codec.info'), 10);
                        }
                        if (track.timescale != null) {
                            const maxFR = Number(settings['upload.max-video-framerate']);
                            if (Number(track.timescale) > maxFR * 1000) {
                                message.warning(
                                    geti18nText('disk.file.edit.video.frameRate.info') + String(maxFR) + ' fps',
                                    10
                                );
                            }
                        }
                        if (track.video != null) {
                            const video = track.video;
                            const maxVideoSize = Number(settings['upload.max-video-size']);
                            if (video.width > maxVideoSize || video.height > maxVideoSize) {
                                message.warning(
                                    geti18nText('disk.file.edit.video.resolution.info') + String(maxVideoSize) + 'px',
                                    10
                                );
                            }
                        }
                    }
                };

                var filepos = 0;
                var append_data_to_mp4box = function (event) {
                    var arraybuffer = event.target.result;
                    if (arraybuffer) {
                        arraybuffer.fileStart = filepos; // adding fileStart property
                        var ret = mp4box.appendBuffer(arraybuffer);
                        filepos = event.loaded;
                    }
                };
                reader.onloadstart = append_data_to_mp4box;
                reader.onprogress = append_data_to_mp4box;
                reader.onloadend = append_data_to_mp4box;

                reader.readAsArrayBuffer(file);
            }
        },
        transformFile(file) {
            return new Promise((resolve) => {
                if (file.type == 'image/jpeg' || file.type == 'image/png') {
                    resizeImage(file).then((resized) => {
                        resolve(resized);
                    });
                } else {
                    resolve(file);
                }
            });
        },
        onChange: (info) => {
            setUploadingFiles(info.fileList.slice());

            if (info.fileList.some((f) => f.status === 'uploading')) {
                setUploading(true);
            } else {
                if (!info.fileList.some((f) => f.status === 'error')) {
                    setUploading(false);

                    dispatch(
                        selectFolder({
                            folderName: folderValue,
                        })
                    );
                    closeUploadModal(true);
                } else {
                    setUploadsWithErrorExists(true);
                    setUploading(false);
                }
            }

            if (info.file.status === 'done') {
                // message.destroy();
                message.success(
                    `${geti18nText('upload.text.file')} ${geti18nText('upload.text.file')} ${
                        info.file.name
                    } ${geti18nText('upload.text.done')}`
                );

                if (!props.multiple) {
                    onChange({
                        key: folderValue
                            ? separator + folderValue + separator + info.file.response
                            : separator + info.file.response,
                        label: info.file.response,
                        file_path: folderValue
                            ? separator + folderValue + separator + info.file.response
                            : separator + info.file.response,
                        name: info.file.response,
                        file_type: props.fileType,
                    });
                }
            } else if (info.file.status === 'error') {
                message.destroy();
                message.error(
                    `${geti18nText('upload.text.file')} ${info.file.name} ${geti18nText('upload.text.error')}`
                );
            }
        },
    };

    const onExpand = (expandedKeys, info) => {
        setSelectedNode(info.node);
        setExpandedKeys(expandedKeys);
        setAutoExpandParent(false);
        setFolderValue(info.node.props.eventKey.split('FOLDER-')[1]);
    };

    function croppedImg(image, crop, fileName) {
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(fileCrop);
            reader.onload = () => {
                const imageNew = document.createElement('img');
                imageNew.src = reader.result;
                imageNew.onload = () => {
                    const canvas = document.createElement('canvas');
                    const scaleX = image.naturalWidth / image.width;
                    const scaleY = image.naturalHeight / image.height;
                    canvas.width = crop.width * pixelRatio;
                    canvas.height = crop.height * pixelRatio;
                    const ctx = canvas.getContext('2d');
                    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
                    ctx.imageSmoothingQuality = 'high';
                    ctx.drawImage(
                        imageNew,
                        crop.x * scaleX,
                        crop.y * scaleY,
                        crop.width * scaleX,
                        crop.height * scaleY,
                        0,
                        0,
                        crop.width,
                        crop.height
                    );

                    ctx.canvas.toBlob(
                        (blob) => {
                            resolve(new File([blob], fileName, { type: 'image/jpeg' }));
                        },
                        'image/jpeg',
                        1
                    );
                };
            };
        });
    }
    function getButtons() {
        return [
            <Button key="cancel" onClick={(e) => closeCropModal()}>
                {geti18nText('app.default.button.cancel')}
            </Button>,
            <Button key="ok" type="primary" onClick={(e) => saveCrop()}>
                {geti18nText('app.default.button.save')}
            </Button>,
        ];
    }

    const onImageLoaded = (image) => {
        setImage(image);
        getFile();
    };

    const onCropChange = (crop, percentCrop) => {
        setCrop(crop);
    };

    //temp
    let draggerRef = React.createRef(); //useRef(undefined);
    return (
        <React.Fragment>
            {/* <SearchField url={CONSTANTS_REQ.DISK_FILE.SEARCH}  disabled={props.loading || uploading || loading} /> */}
            <SelectFile
                {...props}
                addedData={{
                    projectId:
                        props.addedData.projectId == undefined
                            ? undefined
                            : props.addedData.projectId == 0
                            ? 0
                            : Session.getProjectId(),

                    folderRootTreeValue: folderRootTreeValue,
                }}
                uploadProgress={UploadProgress}
                closeUploadModal={closeUploadModal}
            ></SelectFile>

            <Row gutter={24}>
                {!props.checkboxes && !props.fontSelect && (
                    <>
                        <Button
                            className="margin-left"
                            disabled={props.loading || uploading || loading || !props.value}
                            onClick={showPreviewModal}
                        >
                            {geti18nText('upload.preview')}
                        </Button>
                        <Button
                            className="margin-left"
                            disabled={props.loading || uploading || loading || !props.value}
                            onClick={fileDownload}
                        >
                            {geti18nText('upload.download')}
                        </Button>
                        <Button
                            className="margin-left"
                            type="primary"
                            disabled={props.loading || uploading || loading}
                            onClick={showUploadModal}
                        >
                            {geti18nText('upload.add')}
                        </Button>
                        <Button
                            className="margin-left"
                            type="primary"
                            disabled={props.loading || uploading || loading || !isImage}
                            onClick={showCropModal}
                        >
                            {geti18nText('upload.crop')}
                        </Button>
                    </>
                )}
                {props.fontSelect && (
                    <Row>
                        <Col hidden={props.disabled}>
                            <Button
                                style={{ marginLeft: '1.2rem', marginRight: '1.2rem' }}
                                className="margin-left"
                                icon="folder-open"
                                type="primary"
                                disabled={props.loading || uploading || loading}
                                onClick={
                                    /* (e) => */
                                    // setFilesTreeVisible(true);
                                    // fetchTree();

                                    showUploadModal
                                }
                            >
                                {geti18nText('design.settings.component.font_upload')}
                            </Button>
                            {transformValue()}
                        </Col>
                    </Row>
                )}
            </Row>

            <Modal
                title={geti18nText('upload.title')}
                visible={uploadVisible}
                onCancel={closeUploadModal}
                destroyOnClose={true}
                footer={null}
            >
                <Row hidden={props.disabled}>
                    <Row>
                        <Col span={3}>
                            <Button
                                className="margin-left"
                                icon="home"
                                onClick={(e) => {
                                    setFolderValue(null);
                                }}
                            ></Button>
                        </Col>
                        <Col span={17}>
                            <Input
                                placeholder="Select folder"
                                value={trasnsformFolderValue()}
                                prefix={<Icon type="folder" />}
                            />
                        </Col>
                        <Col span={4}>
                            <Button className="margin-left" icon="folder-add" onClick={showNewFolderModal}></Button>
                        </Col>
                    </Row>
                    <Tree
                        style={{
                            height: '30vh',
                            overflow: 'auto',
                        }}
                        treeData={treeData}
                        loadData={onLoadData}
                        onSelect={onSelect}
                        expandedKeys={expandedKeys}
                        autoExpandParent={autoExpandParent}
                        onExpand={onExpand}
                    />
                </Row>
                <Dragger
                    {...uploadFile}
                    ref={(ref) => {
                        if (ref != null) {
                            draggerRef = ref;
                        }
                    }}
                >
                    {uploading ? (
                        <React.Fragment>
                            <Spinner />
                            <p className="ant-upload-hint">{geti18nText('upload.text.uploading')}</p>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <p className="ant-upload-drag-icon">
                                <Icon type="inbox" />
                            </p>
                            <p className="ant-upload-hint">{geti18nText('upload.text')}</p>
                        </React.Fragment>
                    )}
                </Dragger>
                {(uploading || uploadsWithErrorExists) && <UploadProgress uploadingFiles={uploadingFiles} />}
            </Modal>
            <Modal
                width={600}
                title={geti18nText('upload.title.preview')}
                visible={previewVisible}
                onCancel={closePreviewModal}
                destroyOnClose={true}
                footer={null}
            >
                {!loading && (
                    <FilePreview
                        file={previewFile}
                        addedData={{
                            projectId:
                                props.addedData.projectId == undefined
                                    ? undefined
                                    : props.addedData.projectId == 0
                                    ? 0
                                    : Session.getProjectId(),
                        }}
                    />
                )}
            </Modal>
            <AddFolder
                onChange={onChangeFolderVisible}
                visible={newFolderVisible}
                folderValue={folderValue}
                projectId={
                    props.addedData.projectId == undefined
                        ? 0
                        : props.addedData.projectId == 0
                        ? 0
                        : Session.getProjectId()
                }
                fetchTree={fetchTree}
            ></AddFolder>
            <Modal
                width={600}
                title={geti18nText('upload.title.preview')}
                visible={cropVisible}
                onCancel={closeCropModal}
                destroyOnClose={true}
                footer={getButtons()}
            >
                {!loading && (
                    <ReactCrop
                        style={{ width: '100%' }}
                        imageStyle={{ width: '100%' }}
                        crop={crop}
                        src={src}
                        onImageLoaded={onImageLoaded}
                        onChange={onCropChange}
                    />
                )}
            </Modal>
        </React.Fragment>
    );
});

const UploadProgress = (props) => {
    function getHeight() {
        let height = 50;
        if (props.uploadingFiles.length > 3) {
            height = props.uploadingFiles.length * 20;
        }
        if (height > 250) height = 250;
        return height + 'px';
    }

    return (
        <div
            style={{
                height: { getHeight },
                maxHeight: '300px',
                overflow: 'auto',
                scrollbarWidth: 'thin',
                marginTop: '20px',
            }}
        >
            {props.uploadingFiles.map((file, index) => (
                <Row key={index}>
                    <Col span={7}>
                        <Progress
                            percent={Math.round(file.percent)}
                            size="small"
                            status={
                                file.status == 'uploading' ? 'active' : file.status == 'error' ? 'exception' : undefined
                            }
                        />
                    </Col>
                    <Col
                        span={17}
                        style={{
                            textAlign: 'left',
                            paddingLeft: '15px',
                            color: file.status === 'error' ? 'red' : undefined,
                        }}
                    >
                        {file.name}
                        &nbsp;
                        <span style={{ fontSize: '10px' }}>{formatBytes(file.size)}</span>
                    </Col>
                </Row>
            ))}
        </div>
    );
};
