import React from 'react';
import { Layout, Menu, Icon, Row, Col, Typography } from 'antd';
import { withRouter } from 'react-router';
import { geti18nText, Session } from '../../utils/Session';
import { LanguageSelector } from '../Language';
import { useSelector } from 'react-redux';

export const AdminMainHeader = withRouter((props) => {
    const { Header } = Layout;
    const { SubMenu } = Menu;
    const { Title } = Typography;
    const { projectName } = useSelector((state) => state.projectSlices);

    function headerSelect(value) {
        if (value.key === 'logout') {
            Session.logoutUser();
            props.history.push('/login');
        }
    }

    return (
        <Header className="header" style={{ zIndex: '1000001' }}>
            <Row type="flex" align="middle" className="height-100">
                <Col span={10}>
                    <div
                        style={{ fontSize: '18px', fontWeight: 'bold', marginLeft: '330px' }}
                        className={'main-header'}
                    >
                        {projectName}
                    </div>
                </Col>
                <Col span={14} align="right">
                    <Menu mode="horizontal" className="header-menu" onSelect={headerSelect}>
                        <Menu.Item key="version">
                            {geti18nText('login.build')}{' '}
                            <span className="version">{Session.getAppValue('VERSION')}</span>
                        </Menu.Item>
                        <SubMenu
                            className="ant-menu-item"
                            title={
                                <span className="submenu-title-wrapper">
                                    <Icon type="user" />
                                    {Session.getUser().fullName}
                                </span>
                            }
                        >
                            <Menu.Item key="logout">
                                <Icon type="logout" />
                                {geti18nText('navbar.user.logout')}
                            </Menu.Item>
                        </SubMenu>
                        <LanguageSelector />
                    </Menu>
                </Col>
            </Row>
        </Header>
    );
});
