import React, { useState, useEffect } from 'react';
import { Icon, Form, Row, Col, Input, InputNumber } from 'antd';
import { CONSTANTS_REQ } from '../../../utils/Constants';
import { DataEdit } from '../../../components/DataEdit';
import { Session, geti18nText, hasAnyRole } from '../../../utils/Session';
import { SearchField } from '../../../components/Search';
import { requestGet, RESPONSE } from '../../../utils/RequestResolver';
import { InputByLanguage, translationsToObject, getTranslated } from '../../../components/InputByLanguage';
const AccountEdit = Form.create({ name: 'UsersEdit' })((props) => {
    const { getFieldDecorator, setFieldsValue } = props.form;
    const [editHeader, setEditHeader] = useState(geti18nText('users.edit.new'));
    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState(null);
    const [role, setRole] = useState(null);
    const [selectedProject, setSelectedProject] = useState(null);
    const [contextTextLangJson, setContextTextLangJson] = useState(null);
    const [userProjects, setUserProjects] = useState([]);
    const [userFolders, setUserFolders] = useState([]);

    const roles = {
        ADMIN: 'ROLE_ADMIN',
        PROJECT_ADMIN: 'ROLE_PROJECT_ADMIN',
        UPLOAD_USER: 'ROLE_CONTENT_UPLOAD',
        LOCAL_USER: 'ROLE_LOCAL_USER',
        MOBILE_APP_DOWNLOAD: 'ROLE_MOBILE_APP_DOWNLOAD',
    };

    function setValues(user) {
        setEditHeader(user.id);
        setUser(user.id);
        delete user.active;
        delete user.enabled;
        delete user.accountExpired;
        delete user.accountLocked;
        delete user.passwordExpired;
        setRole(null);
        if (user.webPage != null) {
            setSelectedProject(user.webPage.project.id);
            user.userWebProjects = [{ id: user.webPage.project.id + '', text: user.webPage.project.name }];
            user.userWebPage = [{ id: user.webPage.id + '', text: user.webPage.name }];
        }
        if (user.roles != null && user.roles.length > 0) {
            user.userRoles = user.roles.map((pr) => {
                return { id: pr.id + '', text: pr.authority };
            });
            let _roles = user.roles.map((pr) => {
                return { key: pr.id + '', label: pr.authority };
            });
            setRole(_roles);
        }
        delete user.roles;
        if (user.projects) {
            user.userProjects = user.projects.map((pr) => {
                return { id: pr.id + '', text: pr.name };
            });
        }

        if (user.emailNotifyProjects) {
            user.emailNotifyUserProjects = user.emailNotifyProjects.map((pr) => {
                return { id: pr.id + '', text: pr.name };
            });
        }

        if (user.folders && user.folders !== '') {
            let folders = JSON.parse(user.folders);
            setUserFolders(folders);
            user.userFolders = folders.map((folder) => {
                return { id: folder.key + '', text: folder.label };
            });
        } else {
            setUserFolders([]);
        }
        delete user.folders;
        setUserProjects(user.userProjects);
        delete user.webPage;
        delete user.projects;
        setFieldsValue(user);
        setContextTextLangJson(null);
    }

    function isCreate() {
        return props.value == undefined;
    }

    function requiresCompareValidator() {
        const { form } = props;
        var password = form.getFieldValue('password');
        return isCreate() || (password != undefined && password.length > 0);
    }

    function compareToFirstPassword(rule, value, callback) {
        const { form } = props;
        if (value && value !== form.getFieldValue('password')) {
            callback(geti18nText('register.validation.password.equal'));
        } else {
            callback();
        }
    }
    function phoneNumberValidator(rule, value, callback) {
        const re = /^[+0-9\b]+$/;
        if (value === undefined || value === '' || re.test(value)) {
            callback();
        } else {
            callback(geti18nText('users.edit.validation.phone_number'));
        }
    }

    function validatePassword(rule, value, callback) {
        if (
            (isCreate() && (value === undefined || value.length < 6)) ||
            (!isCreate() && value != undefined && value.length < 6)
        ) {
            callback(geti18nText('register.validation.password.short'));
        } else {
            callback();
        }
    }
    function setRoles() {
        if (props.addedData.webPageId != null) {
            return ['ROLE_LOCAL_USER'];
        }
        return Object.values(roles);
    }
    function hasRole(selectedRole) {
        return role && role.map((x) => x.label).includes(selectedRole);
    }

    function onSelectRoleChange(value) {
        if (value) {
            setRole(value);
            setUserFolders([]);
        }
    }
    function onSelecWebProjectChange(value) {
        if (value) {
            setSelectedProject(parseInt(value.key));
        }
    }
    function onChangeUserFolders(value) {
        setUserFolders(value);
    }

    return (
        <>
            <DataEdit
                editHeader={editHeader}
                loading={loading}
                setLoading={setLoading}
                url={CONSTANTS_REQ.USER.EDIT}
                setValues={setValues}
                width={1200}
                {...props}
                hideSubmitButton={Session.getUser().username === 'demo@demo.com'}
                hideActivationButtons={Session.getUser().username === 'demo@demo.com'}
                normalize={(values) => {
                    values.webPage.id = values.userWebPage != null ? parseInt(values.userWebPage) : values.userWebPage;
                    values.userFolders = userFolders.length > 0 ? JSON.stringify(userFolders) : '';
                    return {
                        ...values,
                        roleId: values.role != null ? parseInt(values.role) : values.role,
                        companyId: Session.getUser().companyId,
                        projectId: Session.getProjectId(),
                        // webPage.id: values.userWebPage != null ? parseInt(values.userWebPage) : values.userWebPage,
                        userProjects:
                            values.userProjects != null
                                ? values.userProjects.constructor === String
                                    ? [parseInt(values.userProjects)]
                                    : values.userProjects instanceof Array
                                    ? values.userProjects.map((pr) => {
                                          return parseInt(pr);
                                      })
                                    : [values.userProjects.id]
                                : null,
                        emailNotifyUserProjects:
                            values.emailNotifyUserProjects != null
                                ? values.emailNotifyUserProjects.constructor === String
                                    ? [parseInt(values.emailNotifyUserProjects)]
                                    : values.emailNotifyUserProjects instanceof Array
                                    ? values.emailNotifyUserProjects.map((pr) => {
                                          return parseInt(pr);
                                      })
                                    : [values.emailNotifyUserProjects.id]
                                : null,
                        userRoles:
                            values.userRoles != null
                                ? values.userRoles.constructor === String
                                    ? [parseInt(values.userRoles)]
                                    : values.userRoles instanceof Array
                                    ? values.userRoles.map((pr) => {
                                          return parseInt(pr);
                                      })
                                    : [values.userRoles.id]
                                : null,
                        clientUrl: window.location.host + '/#/confirm',
                    };
                }}
            >
                <Row gutter={24}>
                    <Col span={24}>
                        <Row gutter={24}>
                            <Col span={12}>
                                <Form.Item hidden={true}>{getFieldDecorator('id')(<Input hidden={true} />)}</Form.Item>
                                <Form.Item label={geti18nText('users.edit.username')}>
                                    {getFieldDecorator('username', {
                                        rules: [{ required: true, message: geti18nText('app.default.required') }],
                                    })(<Input />)}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label={geti18nText('users.edit.email')}>
                                    {getFieldDecorator('email')(<Input />)}
                                </Form.Item>
                            </Col>
                        </Row>
                        {
                            <Form.Item hidden={false} label={geti18nText('user.edit.roles')}>
                                {getFieldDecorator('userRoles', {
                                    rules: [{ required: true, message: geti18nText('app.default.required') }],
                                })(
                                    <SearchField
                                        mode="multiple"
                                        url={CONSTANTS_REQ.ROLE.SEARCH}
                                        onChange={onSelectRoleChange}
                                        map={{ id: 'id', label: 'name' }}
                                        searchBy="name"
                                        translate={false}
                                        addedData={{
                                            authority: setRoles(),
                                        }}
                                    />
                                )}
                            </Form.Item>
                        }
                        {hasRole(roles.PROJECT_ADMIN) && (
                            <Form.Item label={geti18nText('users.table.edit.projects')} style={{ marginBottom: '0px' }}>
                                {getFieldDecorator('userProjects', {
                                    rules: [{ required: true, message: geti18nText('app.default.required') }],
                                })(
                                    <SearchField
                                        mode="multiple"
                                        url={CONSTANTS_REQ.PROJECT.SEARCH}
                                        map={{ id: 'id', label: 'name' }}
                                        searchBy="name"
                                        disabled={false}
                                        translate={false}
                                    />
                                )}
                            </Form.Item>
                        )}
                        {hasRole(roles.ADMIN) && (
                            <Form.Item
                                label={geti18nText('users.table.edit.projects.notification')}
                                style={{ marginBottom: '0px' }}
                            >
                                {getFieldDecorator('emailNotifyUserProjects', {
                                    rules: [],
                                })(
                                    <SearchField
                                        mode="multiple"
                                        url={CONSTANTS_REQ.PROJECT.SEARCH}
                                        map={{ id: 'id', label: 'name' }}
                                        searchBy="name"
                                        disabled={false}
                                        translate={false}
                                    />
                                )}
                            </Form.Item>
                        )}
                        {hasRole(roles.UPLOAD_USER) && (
                            <>
                                <Form.Item
                                    label={geti18nText('users.table.edit.uploadProjects')}
                                    style={{ marginBottom: '0px' }}
                                >
                                    {getFieldDecorator('userProjects', {
                                        rules: [{ required: true, message: geti18nText('app.default.required') }],
                                    })(
                                        <SearchField
                                            mode="multiple"
                                            url={CONSTANTS_REQ.PROJECT.SEARCH}
                                            map={{ id: 'id', label: 'name' }}
                                            searchBy="name"
                                            disabled={false}
                                            translate={false}
                                            onChange={(value) => {
                                                setUserProjects(value);
                                                setUserFolders([]);
                                            }}
                                        />
                                    )}
                                </Form.Item>
                                {userProjects !== undefined && userProjects.length === 1 && (
                                    <Form.Item
                                        label={geti18nText('users.table.edit.uploadFolders')}
                                        style={{ marginBottom: '0px' }}
                                    >
                                        {getFieldDecorator('userFolders')(
                                            <SearchField
                                                url={CONSTANTS_REQ.DIRECTORY.DIRECTORY_TREE}
                                                map={{ id: 'id', label: 'name' }}
                                                searchBy="name"
                                                disabled={false}
                                                mode="multiple"
                                                onChange={(value) => {
                                                    onChangeUserFolders(value);
                                                }}
                                                addedData={{
                                                    projectId:
                                                        userProjects[0].id !== undefined
                                                            ? userProjects[0].id
                                                            : userProjects[0].key,
                                                    directory: '/UPLOAD',
                                                    type: 'DIRECTORY',
                                                }}
                                            />
                                        )}
                                    </Form.Item>
                                )}
                            </>
                        )}
                        {hasRole(roles.LOCAL_USER) && (
                            <Row gutter={24}>
                                <Col span={12}>
                                    <Form.Item
                                        label={geti18nText('users.table.edit.project.filter')}
                                        style={{ marginBottom: '0px' }}
                                    >
                                        {getFieldDecorator('userWebProjects', {
                                            rules: [{ required: false, message: geti18nText('app.default.required') }],
                                        })(
                                            <SearchField
                                                url={CONSTANTS_REQ.PROJECT.SEARCH}
                                                onChange={onSelecWebProjectChange}
                                                map={{ id: 'id', label: 'name' }}
                                                searchBy="name"
                                                disabled={false}
                                                translate={false}
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label={geti18nText('users.table.edit.web_page')}
                                        style={{ marginBottom: '0px' }}
                                    >
                                        {getFieldDecorator('userWebPage', {
                                            rules: [{ required: true, message: geti18nText('app.default.required') }],
                                        })(
                                            <SearchField
                                                url={CONSTANTS_REQ.WEBPAGE.SEARCH}
                                                map={{ id: 'id', label: 'name' }}
                                                searchBy="name"
                                                disabled={false}
                                                translate={true}
                                                addedData={{ projectId: selectedProject }}
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                        )}
                        <Row gutter={24}>
                            <Col span={12}>
                                <Form.Item label={geti18nText('register.form.password')} hasFeedback>
                                    {getFieldDecorator('password', {
                                        rules: [
                                            {
                                                required: isCreate(),
                                                message: geti18nText('register.validation.password'),
                                            },
                                            {
                                                validator: validatePassword,
                                            },
                                        ],
                                    })(
                                        <Input.Password
                                            disabled={loading}
                                            prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label={geti18nText('register.form.password.confirm')} hasFeedback>
                                    {getFieldDecorator('confirm', {
                                        rules: [
                                            {
                                                required: requiresCompareValidator(),
                                                message: geti18nText('register.validation.password'),
                                            },
                                            {
                                                validator: compareToFirstPassword,
                                            },
                                        ],
                                    })(
                                        <Input.Password
                                            disabled={loading}
                                            prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={12}>
                                <Form.Item label={geti18nText('users.edit.last_name')}>
                                    {getFieldDecorator('lastName', {
                                        rules: [{ required: true, message: geti18nText('app.default.required') }],
                                    })(<Input />)}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label={geti18nText('users.edit.first_name')}>
                                    {getFieldDecorator('firstName', {
                                        rules: [{ required: true, message: geti18nText('app.default.required') }],
                                    })(<Input />)}
                                </Form.Item>
                            </Col>
                        </Row>
                        {hasRole(roles.LOCAL_USER) && (
                            <Row gutter={12}>
                                <Col span={12}>
                                    <Form.Item label={geti18nText('users.edit.phone_number')}>
                                        {getFieldDecorator('phoneNumber', {
                                            rules: [{ validator: phoneNumberValidator }],
                                        })(<Input />)}
                                    </Form.Item>
                                </Col>
                            </Row>
                        )}
                        <Form.Item hidden={true}>{getFieldDecorator('webPage.id')(<Input hidden={true} />)}</Form.Item>
                        <Form.Item hidden={true}>{getFieldDecorator('company')(<Input hidden={true} />)}</Form.Item>
                        <Form.Item hidden={true}>{getFieldDecorator('project')(<Input hidden={true} />)}</Form.Item>
                    </Col>
                </Row>
            </DataEdit>
        </>
    );
});

export default AccountEdit;
