import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import App from './views/App';
import { ConfigProvider } from 'antd';
import { getSelectedLocale } from './utils/Utils';
import 'antd/dist/antd.css';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import store, { persistor } from './store';
import 'leaflet/dist/leaflet.css';
import 'leaflet-gesture-handling/dist/leaflet-gesture-handling.css';
import 'video-react/dist/video-react.css';
import '@nybble/ny-tourist-components/build/styles/styles.scss';
import { Session } from './utils/Session';
import { PersistGate } from 'redux-persist/integration/react';


ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <ConfigProvider locale={getSelectedLocale()}>
                <App />
            </ConfigProvider>
        </PersistGate>
    </Provider>,
    document.getElementById('root')
);
