import React, { useState, useEffect, useRef, useImperativeHandle } from 'react';
import { Form, Row, Col, Button, Divider, Popconfirm, Tabs, InputNumber, Alert, Checkbox, Select } from 'antd';
import { Spinner } from '../Spinner';
import { Session, geti18nText } from '../../utils/Session';
import { normalizeComponents } from '../../utils/Utils';
import { InputByLanguage, getTranslated, translationsToObject } from '../InputByLanguage';
const { Option } = Select;
const ConfiguratorPackageEdit = Form.create({ name: 'ConfiguratorPackageEdit' })((props) => {
    const { TabPane } = Tabs;
    const { getFieldDecorator, setFieldsValue } = props.form;
    const [fetching, setFetch] = useState(false);
    const [groupItems, setGroupItems] = useState([]);
    const [seletedLang, setSeletedLang] = useState(Session.getProjectDefaultLanguage());
    const [packageItems, setPackageItems] = useState([]);
    const [translations, setTranslations] = useState([]);
    const [packagId, setPackageId] = useState([]);
    const [formValues, setFormValues] = useState(null);
    const [filterChecked, setFilterChecked] = useState(null);
    const [confirmVisible, setConfirmVisible] = useState(false);
    const [showError, setShowError] = useState(false);
    const [isEditItem, setisEditItem] = useState(false);

    useEffect(() => {
        props.form.resetFields();
        props.form.setFieldsValue(formValues);
        setFieldsValue(formValues);
    }, [formValues]);

    useEffect(() => {
        const translations = props.groupsRef.current.getTranslations();
        setTranslations(translations);
        const groupItemsKey = props.groupsRef.current.getGroupsItemsKey();
        setGroupItems(groupItemsKey);
    }, []);

    useEffect(() => {
        if (props.popupValue) {
            setValues(props.popupValue);
        }
    }, [props.popupValue]);

    function setValues(values) {
        var json = JSON.parse(JSON.stringify(values));
        json = translationsToObject(json, 'package_name', json['package_name']);
        json = translationsToObject(json, 'package_desc', json['package_desc']);
        delete json['package_desc'];
        delete json['package_name'];
        setPackageItems(json.packageConf);
        setPackageId(json.id);
        setFilterChecked(json.filterChecked);
        setFormValues(json);
        setisEditItem(true);
    }

    useEffect(() => {
        setShowError(false);

        const elements = [...props.dataSource];
        var currentElementIndex = elements.findIndex(
            (x) => x.id === packagId && x.filterChecked.includes(props.filter)
        );
        if (currentElementIndex !== -1 && (props.popupValue == null || props.popupValue.id != packagId)) {
            setShowError(true);
        }
    }, [packagId]);

    function save(e) {
        e.preventDefault();
        props.form.validateFieldsAndScroll((err, values) => {
            if (showError == false) {
                values = normalizeComponents(values);
                values.packageConf = packageItems;
                values.id = packagId;
                values.filterChecked = [parseInt(props.filter)];
                props.updateItemsFromModal(values);
            }
        });
    }

    function deleteItem() {
        props.deleteItem(packagId);
    }

    function onPriceChange(value) {}

    function onItemsSelect(group, item) {
        const elements = [...packageItems];
        var currentElementIndex = elements.findIndex((x) => x.group === group);

        var element = null;
        if (currentElementIndex === -1) {
            elements.push({ group: group, item: item });
        } else {
            element = elements[currentElementIndex];
            element.item = item;
        }

        setPackageItems(elements);
        setPackageId(generateId(elements));
    }

    function getDefaultSelectedItem(groupId) {
        const elements = [...packageItems];
        var currentElementIndex = elements.findIndex((x) => x.group === groupId);

        if (currentElementIndex != -1) {
            return elements[currentElementIndex].item;
        }

        return null;
    }

    function getGroupTranslation(groupId, index) {
        const elements = props.getGroupNames();
        var currentElementIndex = elements.findIndex((x) => x.id === groupId);

        var translation = geti18nText('component.configurator.header.group') + ' ' + (index + 1);
        if (currentElementIndex != -1) {
            if (elements[currentElementIndex].label) {
                translation = elements[currentElementIndex].label;
            }
        }
        return translation;
    }

    function getGroupItemTranslation(groupId, itemId) {
        const key = 'group_' + groupId + '_item_name_' + itemId;
        var translation = translations['translation-' + seletedLang + '-' + key];
        if (translation == null) {
            translation = getTranslated(translations[key]);
        }
        return translation;
    }

    function generateId(array) {
        var id = '';
        array.forEach((element) => {
            id += 'G-' + element.group + '-I' + element.item;
        });
        return id;
    }

    function onFilterChange(checkedValues) {
        console.log('checked = ', checkedValues);

        setFilterChecked(checkedValues);
    }

    function laodPackage() {
        setShowError(false);
        const elements = [...props.dataSource];
        var currentElementIndex = elements.findIndex((x) => x.id === packagId);
        if (currentElementIndex != -1) {
            const json = elements[currentElementIndex];
            setValues(json);
        }
    }

    if (fetching) {
        return <Spinner />;
    } else {
        return (
            <React.Fragment>
                <Divider></Divider>

                <Form key={props.index}>
                    <Row style={{ marginBottom: '5px' }} gutter={24}>
                        {groupItems.map((groupValue, groupIndex) => {
                            return (
                                <Col span={6}>
                                    <Form.Item hidden={false} label={getGroupTranslation(groupValue.id, groupIndex)}>
                                        <Select
                                            style={{ width: 200 }}
                                            onSelect={(value) => onItemsSelect(groupValue.id, value)}
                                            defaultValue={getDefaultSelectedItem(groupValue.id)}
                                            allowClear={true}
                                        >
                                            {groupValue.keys.map((value, index) => {
                                                return (
                                                    <Option key={index} value={value}>
                                                        {getGroupItemTranslation(groupValue.id, value)}
                                                    </Option>
                                                );
                                            })}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            );
                        })}
                    </Row>
                    {showError && (
                        <Row gutter={24} style={{ marginBottom: '5px' }}>
                            <Col span={6}>
                                <Alert
                                    message={geti18nText('component.configurator.table.add.package.error')}
                                    type="error"
                                />
                            </Col>
                            <Col span={6} style={{ marginTop: '0rem' }}>
                                <Button type="primary" onClick={(e) => laodPackage(e)}>
                                    {geti18nText('component.configurator.table.load.package')}
                                </Button>
                            </Col>
                        </Row>
                    )}

                    <Row>
                        <Col span={24}>
                            <InputByLanguage
                                notRequred
                                fieldName={'package_name'}
                                label={geti18nText('component.configurator.table.package.name')}
                                //  langJson={item.serviceJson}
                                notRequred={false}
                                {...props}
                            />
                        </Col>{' '}
                        <Col span={24}>
                            <InputByLanguage
                                notRequred
                                fieldName={'package_desc'}
                                label={geti18nText('component.configurator.table.package.description')}
                                //  langJson={item.descriptionJson}
                                notRequred={true}
                                type={'rteEditor'}
                                {...props}
                            />
                        </Col>{' '}
                        <Col span={24}>
                            <Form.Item hidden={false} label={geti18nText('component.configurator.table.package.price')}>
                                {getFieldDecorator('package_price')(
                                    <InputNumber min={0} precision={2} onChange={(value) => onPriceChange(value)} />
                                )}
                            </Form.Item>
                        </Col>
                    </Row>

                    {/* <Row>
                        {props.groupsRef.current && props.groupsRef.current.getFilterOptions() && (
                            <Col span={24}>
                                <Checkbox.Group
                                    value={filterChecked}
                                    options={props.groupsRef.current.getFilterOptions()}
                                    onChange={(values) => onFilterChange(values)}
                                />
                            </Col>
                        )}
                    </Row> */}
                    <Row>
                        <Col style={{ textAlign: 'right', marginTop: '1rem' }}>
                            {isEditItem == true && (
                                <Popconfirm
                                    placement="rightTop"
                                    title={geti18nText('app.default.button.delete')}
                                    visible={confirmVisible}
                                    okText={geti18nText('app.default.button.yes')}
                                    cancelText={geti18nText('app.default.button.no')}
                                    onCancel={() => {
                                        setConfirmVisible(false);
                                    }}
                                    onConfirm={deleteItem}
                                >
                                    <Button type="danger" key="delete" onClick={(e) => setConfirmVisible(true)}>
                                        {geti18nText('app.default.button.delete')}
                                    </Button>
                                </Popconfirm>
                            )}
                            <Button style={{ marginLeft: '20px' }} type="primary" onClick={(e) => save(e)}>
                                {geti18nText('component.configurator.done.btn')}
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </React.Fragment>
        );
    }
});

export default ConfiguratorPackageEdit;
