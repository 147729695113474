import React, { useState, useEffect, useRef, useImperativeHandle } from 'react';
import {
    Form,
    Row,
    Col,
    Button,
    Divider,
    Collapse,
    Tabs,
    InputNumber,
    Popconfirm,
    Checkbox,
    Select,
    message,
} from 'antd';
import { Spinner } from '../Spinner';
import { Session, geti18nText } from '../../utils/Session';
import { normalizeComponents } from '../../utils/Utils';
import { InputByLanguage, translationsToObject } from '../InputByLanguage';
import { getTranslated } from '../InputByLanguage';

const ConfiguratorGroupsItems = Form.create({ name: 'ConfiguratorGroupsItems' })((props) => {
    const { TabPane } = Tabs;
    const { Option } = Select;
    const { getFieldDecorator, setFieldsValue } = props.form;
    const [lastKey, setLastKey] = useState(0);
    const [slides, setSlides] = useState(null);
    const [activeTab, setActiveTab] = useState('0');
    const [slidesKeys, setSlidesKeys] = useState([0]);
    const [deletedSlides, setDeletedSlides] = useState([]);
    const [slideToDelete, setSlideToDelete] = useState(null);
    const [confirmVisible, setConfirmVisible] = useState(false);
    const [initState, setInitState] = useState(false);
    const [formValues, setFormValues] = useState(null);

    const [itemResValues, setItemResValues] = useState([]);
    const [filterChecked, setFilterChecked] = useState(null);
    const [refresh, setRefresh] = useState();
    const [fetching, setFetch] = useState(false);
    const [showGroup, setShowGroup] = useState([]);
    const [filterShowOnlyValue, setFilterShowOnlyValue] = useState([0]);
    const [filterShowOnlyGroupValue, setFilterShowOnlyGroupValue] = useState(undefined);

    useEffect(() => {
        if (props.groupItemsFilter) {
            const ind = props.groupItemsFilter?.findIndex((x) => x.group == props.index);
            if (ind > -1) {
                const items = props.groupItemsFilter[ind];
                if (items && items.values) {
                    let arrayItemShowOnly = [];
                    let arrayItemShowGroup = [];
                    let arrayItemShowGroupValues = [];
                    var arrayCheckedFilters = [];
                    var index = 0;
                    items.values.forEach((el) => {
                        arrayCheckedFilters[index] = el.checkedFilters ? el.checkedFilters : [];

                        if (el.filterShowOnly) {
                            if (el.filterShowOnly !== 0) arrayItemShowGroup[index] = true;
                            else arrayItemShowGroup[index] = false;
                            arrayItemShowOnly[index] = el.filterShowOnly;
                        } else arrayItemShowOnly[index] = 0;

                        if (el.filterShowOnlyGroup != undefined) {
                            arrayItemShowGroupValues[index] = el.filterShowOnlyGroup;
                        } else arrayItemShowGroupValues[index] = undefined;

                        updateResValues('checkedFilters', el.checkedFilters ? el.checkedFilters : [], el.id);
                        updateResValues('price', el.checkedFilters ? el.price : [], el.id);
                        updateResValues('filterShowOnly', el.filterShowOnly ? el.filterShowOnly : 0, el.id);
                        updateResValues(
                            'filterShowOnlyGroup',
                            el.filterShowOnlyGroup ? el.filterShowOnlyGroup : undefined,
                            el.id
                        );
                        index += 1;
                    });
                    setFilterShowOnlyValue(arrayItemShowOnly);
                    setFilterShowOnlyGroupValue(arrayItemShowGroupValues);
                    setShowGroup(arrayItemShowGroup);
                    setFilterChecked(arrayCheckedFilters);
                }
            }
        }
    }, [props.groupItemsFilter, initState]);

    function setValues(json) {
        setFetch(true);
        var vidArray = [];
        if (props.items) {
            for (const item of props.items) {
                if (item.keys) {
                    for (const key of item.keys) {
                        json = translationsToObject(
                            json,
                            'group_' + item.id + '_item_name_' + key,
                            json['group_' + item.id + '_item_name_' + key]
                        );
                        json = translationsToObject(
                            json,
                            'group_' + item.id + '_item_description_' + key,
                            json['group_' + item.id + '_item_description_' + key]
                        );
                        json = translationsToObject(
                            json,
                            'group_' + item.id + '_item_service_name_' + key,
                            json['group_' + item.id + '_item_service_name_' + key]
                        );

                        vidArray.push({
                            group: item.id,
                            index: key,
                            id: key,
                            nameJson: json['group_' + item.id + '_item_name_' + key],
                            descriptionJson: json['group_' + item.id + '_item_description_' + key],
                            serviceJson: json['group_' + item.id + '_item_service_name_' + key],
                        });
                        delete json['group_' + item.id + '_item_name_' + key];
                        delete json['group_' + item.id + '_item_service_name_' + key];
                        delete json['group_' + item.id + '_item_description_' + key];
                    }
                }
            }

            setFormValues(json);
        }
        setFetch(false);
    }

    useEffect(() => {
        if (props.groupItemsTranslationValues) {
            setValues(props.groupItemsTranslationValues);
        }
    }, [props.visible, refresh]);

    useEffect(() => {
        props.form.resetFields();
        props.form.setFieldsValue(formValues);
        setFieldsValue(formValues);
        console.log('restart');
    }, [formValues]);

    function save(e) {
        e.preventDefault();
        if (showGroup != undefined) {
            let res = [];
            showGroup.forEach((value, i) => {
                if (value == true && filterShowOnlyGroupValue[i] == undefined) {
                    res.push(geti18nText('component.configurator.header.item') + ' ' + (i + 1));
                }
            });
            if (res.length > 0) {
                message.destroy();
                message.error(geti18nText('component.configurator.filter.select.group.error') + ' (' + res + ')');
                return;
            }
        }
        props.form.validateFieldsAndScroll((err, values) => {
            if (showGroup[activeTab] == true && filterShowOnlyGroupValue[activeTab] == undefined) {
                return;
            }
            if (!err) {
                values = normalizeComponents(values);
                delete values['filterShowOnly'];
                delete values['filterShowOnlyGroup'];
                const res = {
                    formValues: values,
                    groupsItemsKey: slidesKeys,
                    itemResValues: [...itemResValues],
                    groupId: props.index,
                };
                console.log(values);
                props.updateItemsFromModal(res);
            }
        });
    }

    useEffect(() => {
        if (props.items == null) {
            setSlidesKeys([0]);
            setSlides([{ id: 0 }]);
            setItemResValues([{ id: 0 }]);
            setFilterChecked([[]]);
        } else {
            const itemsList = props.items ? props.items.filter((x) => x.id == props.index) : null;
            if (itemsList != null && itemsList[0]) {
                const items = itemsList[0].keys;
                let elementsRes = [...itemResValues];

                setSlidesKeys(items);
                setActiveTab('' + items[0]);
                var slidesArray = [];

                items.forEach((id) => {
                    slidesArray.push({ id: id });
                    let currentElementIndex = elementsRes.findIndex((x) => x.id === id);

                    if (currentElementIndex === -1) {
                        elementsRes.push({ id: id });
                    }
                });
                setSlides(slidesArray);
                let max_key = 0;
                for (const key of items) {
                    max_key = key > max_key ? key : max_key;
                }
                setLastKey(max_key);

                setInitState(true);
                setItemResValues(elementsRes);
                setRefresh(new Date().toString());
            } else {
                setSlidesKeys([0]);
                setSlides([{ id: 0 }]);
                setItemResValues([{ id: 0 }]);
                setFilterChecked([[]]);
            }
        }
    }, [props.items]);

    const onEdit = (targetKey, action) => {
        if (action == 'add') {
            props.form.validateFieldsAndScroll((err, values) => {
                if (!err) {
                    let newId = lastKey;
                    newId++;
                    let arrSlides = slides.slice();
                    let arrKeys = slidesKeys.slice();
                    arrSlides.push({ id: newId });
                    arrKeys.push(newId);
                    setSlidesKeys(arrKeys);
                    setSlides(arrSlides);
                    setActiveTab('' + newId);
                    setLastKey(newId);
                    let resElements = [...itemResValues];
                    resElements.push({ id: newId });
                    setItemResValues(resElements);
                }
            });
        }
        if (action == 'remove') {
            if (slidesKeys.length > 1) {
                setConfirmVisible(true);
                setSlideToDelete(targetKey);
            }
        }
    };

    const tabOnChange = (activeKey) => {
        props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                setActiveTab(activeKey);
            }
        });
    };
    const deleteSlide = () => {
        if (slideToDelete) {
            var newArrKeys = slidesKeys.filter((key) => key != parseInt(slideToDelete));
            var newArrVideos = slides.filter((video) => video.id != parseInt(slideToDelete));
            setSlides(newArrVideos);
            setSlidesKeys(newArrKeys);

            if (activeTab == slideToDelete) {
                setActiveTab('' + newArrKeys[0]);
            }
            setConfirmVisible(false);
            setSlideToDelete(null);
            let deleted = deletedSlides.slice();
            deleted.push(slideToDelete);
            setDeletedSlides(deleted);
        }
    };

    function onFilterChange(checkedValues, id) {
        updateResValues('checkedFilters', checkedValues, id);
        let elements = [...filterChecked];
        elements[id] = checkedValues;
        setFilterChecked(elements);
    }

    function onPriceChange(value, id) {
        updateResValues('price', value, id);
    }

    function updateResValues(key, value, id) {
        let elements = [...itemResValues];
        let currentElementIndex = elements.findIndex((x) => x.id === id);
        var item = null;
        if (currentElementIndex === -1) return;
        item = elements[currentElementIndex];
        item[key] = value;

        setItemResValues(elements);
    }

    const filterShowOnly = () => {
        let filter = [
            { value: 0, label: geti18nText('component.configurator.filter.show.only.empty') },
            { value: 1, label: geti18nText('component.configurator.filter.show.only.group.selected') },
            { value: 2, label: geti18nText('component.configurator.filter.show.only.group.not.selected') },
        ];
        return filter;
    };

    const onShowOnlyFilterSelect = (value, id, index) => {
        console.log('index ', index);
        updateResValues('filterShowOnly', value, id);
        updateResValues('filterShowOnlyGroup', undefined, id);
        let filterShowGroup = [...filterShowOnlyGroupValue];
        let filterShowValue = filterShowOnlyValue;
        filterShowValue[index] = value;
        setFilterShowOnlyValue(filterShowValue);
        console.log(filterShowGroup);
        if (filterShowGroup) {
            filterShowGroup[index] = undefined;
            setFilterShowOnlyGroupValue(filterShowGroup);
        }
        if (value !== 0) {
            let filterShowGroup = showGroup;
            filterShowGroup[index] = true;
            setShowGroup(filterShowGroup);
        } else {
            let filterShowGroup = showGroup;
            filterShowGroup[index] = false;
            setShowGroup(filterShowGroup);
        }
    };

    const groupsSelectItems = () => {
        let val = props.groupsValues && props.groupsValues.filter((group) => group.id !== props.index);
        return val;
    };

    const onSelectFilterShowGroup = (value, index) => {
        let filterShowGroup = filterShowOnlyGroupValue;
        filterShowGroup[index] = value;
        setFilterShowOnlyGroupValue(filterShowGroup);
    };

    if (fetching) {
        return <Spinner />;
    } else {
        return (
            <React.Fragment>
                <Divider></Divider>
                <Row>
                    <Col span={24}>
                        <Popconfirm
                            placement="rightTop"
                            title={geti18nText('app.default.button.delete')}
                            visible={confirmVisible}
                            okText={geti18nText('app.default.button.yes')}
                            cancelText={geti18nText('app.default.button.no')}
                            onCancel={() => {
                                setConfirmVisible(false);
                            }}
                            onConfirm={deleteSlide}
                        >
                            <span></span>
                        </Popconfirm>
                    </Col>
                </Row>
                <Form key={props.index}>
                    <Tabs type="editable-card" onEdit={onEdit} onChange={tabOnChange} activeKey={activeTab}>
                        {slides &&
                            slides.map((item, index) => (
                                <TabPane
                                    tab={geti18nText('component.configurator.header.item') + ' ' + (index + 1)}
                                    key={item.id}
                                    forceRender={true}
                                >
                                    <Row gutter={24}>
                                        <Col span={24}>
                                            <InputByLanguage
                                                notRequred
                                                fieldName={'group_' + props.index + '_item_name_' + item.id}
                                                label={geti18nText('component.configurator.item.name')}
                                                langJson={item.nameJson}
                                                notRequred={true}
                                                {...props}
                                            />
                                        </Col>
                                        {/* <Col span={24}>
                                            <InputByLanguage
                                                notRequred
                                                fieldName={'group_' + props.index + '_item_service_name_' + item.id}
                                                label={geti18nText('component.configurator.item.service.name')}
                                                //  langJson={item.serviceJson}
                                                notRequred={true}
                                                {...props}
                                            />
                                        </Col>{' '} */}
                                        {/* <Col span={24}>
                                            <InputByLanguage
                                                notRequred
                                                fieldName={'group_' + props.index + '_item_description_' + item.id}
                                                label={geti18nText('component.configurator.item.description')}
                                                //  langJson={item.descriptionJson}
                                                notRequred={true}
                                                type={'rteEditor'}
                                                {...props}
                                            />
                                        </Col>{' '} */}
                                        {/* <Col span={24}>
                                            <Form.Item
                                                hidden={false}
                                                label={geti18nText('component.configurator.item.price')}
                                            >
                                                {getFieldDecorator('group_' + props.index + '_item_price_' + item.id)(
                                                    <InputNumber
                                                        min={0}
                                                        precision={2}
                                                        onChange={(value) => onPriceChange(value, item.id)}
                                                    />
                                                )}
                                            </Form.Item>
                                        </Col> */}
                                    </Row>
                                    <Row>
                                        {props.filterOptions && (
                                            <Col span={24}>
                                                <Checkbox.Group
                                                    key={index}
                                                    value={filterChecked && filterChecked[item.id]}
                                                    options={props.filterOptions}
                                                    onChange={(values) => onFilterChange(values, item.id)}
                                                />
                                            </Col>
                                        )}
                                    </Row>
                                    <Row>
                                        {' '}
                                        <Col span={12}>
                                            <Form.Item
                                                style={{ marginRight: 10 }}
                                                label={geti18nText('component.configurator.filter.show.only.title')}
                                            >
                                                <Select
                                                    key={index}
                                                    style={{ width: 300 }}
                                                    defaultValue={filterShowOnlyValue[index]}
                                                    value={filterShowOnlyValue[index]}
                                                    onSelect={(value) => {
                                                        onShowOnlyFilterSelect(value, item.id, index);
                                                    }}
                                                >
                                                    {filterShowOnly().map((value, index) => {
                                                        return (
                                                            <Option key={index} value={value.value}>
                                                                {value.label}
                                                            </Option>
                                                        );
                                                    })}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        {showGroup[index] === true && groupsSelectItems() && (
                                            <Col span={12}>
                                                <Form.Item
                                                    style={{
                                                        marginRight: 10,
                                                    }}
                                                    label={geti18nText('component.configurator.filter.select.group')}
                                                    validateStatus={
                                                        filterShowOnlyGroupValue &&
                                                        filterShowOnlyGroupValue[index] == undefined
                                                            ? 'error'
                                                            : null
                                                    }
                                                    required={true}
                                                >
                                                    <Select
                                                        key={index}
                                                        style={{ width: 300 }}
                                                        allowClear
                                                        defaultValue={
                                                            filterShowOnlyGroupValue && filterShowOnlyGroupValue[index]
                                                        }
                                                        value={
                                                            filterShowOnlyGroupValue && filterShowOnlyGroupValue[index]
                                                        }
                                                        onSelect={(value) => {
                                                            onSelectFilterShowGroup(value, index);
                                                            updateResValues('filterShowOnlyGroup', value, item.id);
                                                        }}
                                                    >
                                                        {groupsSelectItems().map((value, index) => {
                                                            return (
                                                                <Option key={index} value={value.id}>
                                                                    {getTranslated(value.nameJson)}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                        )}
                                    </Row>
                                </TabPane>
                            ))}
                    </Tabs>
                    <Row>
                        <Col span={24} style={{ textAlign: 'right', marginTop: '1rem' }}>
                            <Button type="primary" onClick={(e) => save(e)}>
                                {geti18nText('component.configurator.done.btn')}
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </React.Fragment>
        );
    }
});

export default ConfiguratorGroupsItems;
