import React from "react";
import { Icon } from "antd";
import { DataTable, getColumnSearch, getColumnSearchOption } from "../../../components/DataTable";
import PackageEdit from "./PackageEdit";
import { CONSTANTS_REQ } from "../../../utils/Constants";
import { Session, ENUMS, geti18nText } from "../../../utils/Session";
import { getTranslated } from '../../../components/InputByLanguage';




const PackageList = (props) => {
    function setDefaultFilterValue() {
        return [{ field: "active", condition: "equals_bool", value: 1 }];
    }
    return (
        <DataTable
            headerTitle={geti18nText("package.table.header")}
            url={CONSTANTS_REQ.PACKAGE.LIST}
            addedData={{ projectId: Session.getProjectId() }}
            addNewButtonText={geti18nText("package.table.add")}
            showRecordModal={true}
            modalComponent={PackageEdit}
            setDefaultFilterValue={setDefaultFilterValue}
            columns={[
                {
                    title: geti18nText("blog.table.column.id"),
                    dataIndex: "id",
                    width: "10%",
                    sorter: (a, b) => {},
                    ...getColumnSearch("number"),
                },
                {
                    title: geti18nText('blog.table.column.title'),
                    dataIndex: 'name',
                    sorter: (a, b) => {},
                    ...getColumnSearch('string'),
                    render: (text, record) => {
                        return <div>{getTranslated(text)}</div>;
                    },
                    
                },
                {
                    title: geti18nText('package.edit.price'),
                    dataIndex: 'price',
                    sorter: (a, b) => {},
                    ...getColumnSearch('string'),
                    render: (text, record) => {
                        return <div>{getTranslated(text)}</div>;
                    },
                    
                },
                {
                    title: geti18nText("blog.table.column.active"),
                    dataIndex: "active",
                    width: "10%",
                    render: (text, record) => {
                        if (record.active) {
                            return <Icon type="check" style={{ color: "green" }} />;
                        } else {
                            return <Icon type="close" style={{ color: "red" }} />;
                        }
                    },
                    ...getColumnSearchOption(ENUMS.ACTIVE(), "equals_bool", setDefaultFilterValue()[0]["value"]),
                    defaultFilteredValue: setDefaultFilterValue(),
                },
            ]}
        />
    );
};

export default PackageList;
