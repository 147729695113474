import React, { useState, useEffect } from "react";
import { Icon, Form, Row, Col, Input, Radio,DatePicker } from "antd";
import { CONSTANTS_REQ,CONSTANTS_FILE_TYPE } from "../../../utils/Constants";
import { DataEdit } from "../../../components/DataEdit";
import { Session, geti18nText } from "../../../utils/Session";
import { requestGet, RESPONSE } from "../../../utils/RequestResolver";
import { setProjectLanguages } from "../../project/ProjectIndex";
import { SearchField, mapDataToSelect } from '../../../components/Search';
import { FileUpload } from '../../../components/fileUpload/FileUpload';
import moment from 'moment';
import { InputByLanguage, translationsToObject } from '../../../components/InputByLanguage';



const ShopCategoryEdit = Form.create({ name: "ShopCategoryEdit" })((props) => {
    const { getFieldDecorator, setFieldsValue } = props.form;
    const [editHeader, setEditHeader] = useState(geti18nText("shopCategory.edit.new"));
    const [loading, setLoading] = useState(false);
    const [contextTextLangJson, setContextTextLangJson] = useState(null);

    
    function setValues(values) {
        setEditHeader(values.id);
        values = translationsToObject(values, 'name', values.name);

        delete values.active;
        setFieldsValue(values);
    }


    function isCreate() {
        return props.value == undefined;
    }

    function onSave() {
        setProjectLanguages(Session.getProjectId());
        props.onSave();
    }

    function setDefaultValues() {
        
            setEditHeader(geti18nText("shopCategory.edit.new"));
         
    }

    return (
        <>
            <DataEdit
                editHeader={editHeader}
                loading={loading}
                setLoading={setLoading}
                url={CONSTANTS_REQ.CATEGORY.EDIT}
                setValues={setValues}
                setDefaultValues={setDefaultValues}
                width={1200}
                {...props}
                onSave={onSave}
                normalize={(values) => {
                    return { ...values, project: { id: Session.getProjectId() }, type: 1 };
                }}
            >
                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item hidden={true}>{getFieldDecorator("id")(<Input hidden={true} />)}</Form.Item>
                        <InputByLanguage
                            fieldName="name"
                            
                            label={geti18nText('faqCategory.table.column.name')}
                            {...props}
                        />
                        
                        
                    </Col>
                </Row>
            </DataEdit>
        </>
    );
});

export default ShopCategoryEdit;
