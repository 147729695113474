import React, { useState, forwardRef } from 'react';
import { Select } from 'antd';

import { requestGet, RESPONSE } from '../utils/RequestResolver';
import { geti18nText } from '../utils/Session';
import { getTranslated } from './InputByLanguage';

let timeout = null;

export const mapDataToSelect = (serverData, id = 'id', label = 'text', translate = false) => {
    return serverData.map((val) => {
        if (!translate) {
            return { key: '' + val[id], label: val[label] };
        } else {
            return { key: '' + val[id], label: getTranslated(val[label]) };
        }
    });
};

export const mapEnumToSelect = (enumArray, selectedValues = []) => {
    let enumValue;
    let enumSelected = [];
    for (enumValue of enumArray) {
        if (selectedValues.includes(enumValue.id)) {
            enumSelected.push({ key: '' + enumValue.id, label: enumValue.text });
        }
    }
    return enumSelected;
};

export const SearchField = forwardRef(
    (
        {
            value,
            onChange,
            mode = 'default',
            searchBy,
            url,
            map = undefined,
            addedData = undefined,
            options = undefined,
            activateFirstOption = false,
            disabled = false,
            translate = false,
            addedSearch = undefined,
            allowClear = true,
        },
        ref
    ) => {
        const [data, setData] = useState([]);
        const [loading, setLoading] = useState(false);

        function handleSearch(search) {
            setLoading(true);
            fetchData(search);
        }

        function fetchData(searchData, miliseconds = 1000) {
            if (url) {
                if (timeout) {
                    clearTimeout(timeout);
                    timeout = null;
                }
                timeout = setTimeout(() => {
                    requestGet(url, {
                        max: 100,
                        search: encodeURI(
                            addedSearch
                                ? JSON.stringify([
                                      { field: searchBy, condition: 'contains', value: searchData },
                                      addedSearch,
                                  ])
                                : JSON.stringify([{ field: searchBy, condition: 'contains', value: searchData }])
                        ),
                        ...addedData,
                    }).then((result) => {
                        setLoading(false);
                        if (result.status === RESPONSE.OK) {
                            setData(result.data);
                        }
                    });
                }, miliseconds);
            } else {
                setLoading(false);
                setData(options.filter((option) => option.text.toLowerCase().includes(searchData.toLowerCase())));
            }
        }

        function handleChange(changedValue) {
            onChange(changedValue);
        }

        function handleFocus() {
            setLoading(true);
            fetchData('', 1);
        }

        function transformValue(translate = false) {
            if (value) {
                if (value instanceof Array && value.length > 0 && value[0]['id']) {
                    return mapDataToSelect(value, 'id', 'text', translate);
                } else if (value instanceof Array && value.length > 0 && value[0]['key']) {
                    return value;
                }
                if (value instanceof Object && value['id']) {
                    if (map) {
                        return [
                            {
                                key: '' + value[map.id],
                                label: translate ? getTranslated(value[map.label]) : value[map.label],
                            },
                        ];
                    } else {
                        return [{ key: '' + value.id, label: translate ? getTranslated(value.text) : value.text }];
                    }
                } else if (value instanceof Object && value['key']) {
                    return value;
                }
            }
            return undefined;
        }

        return (
            <Select
                allowClear={allowClear}
                ref={ref}
                showSearch
                labelInValue={true}
                disabled={disabled}
                value={transformValue(translate)}
                loading={loading}
                mode={mode}
                defaultActiveFirstOption={activateFirstOption}
                filterOption={false}
                onSearch={handleSearch}
                notFoundContent={geti18nText('app.default.dropdown')}
                onChange={handleChange}
                onFocus={handleFocus}
                style={{ width: '100%' }}
            >
                {data.map((option) => (
                    <Select.Option key={option.id}>
                        {translate ? getTranslated(option.text) : option.text}
                    </Select.Option>
                ))}
            </Select>
        );
    }
);
