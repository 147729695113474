import React, { useState, useEffect } from 'react';
import { Form, Row, Col, Button, Divider, Input, InputNumber, Tag, notification } from 'antd';
import { geti18nText } from '../../utils/Session';
import { requestGet, requestPut, RESPONSE } from '../../utils/RequestResolver';
import { Spinner } from '../../components/Spinner';
import { CONSTANTS_REQ } from '../../utils/Constants';

const InstagramFeedEdit = Form.create({ name: 'InstagramFeedEdit' })((props) => {
    const { getFieldDecorator, setFieldsValue } = props.form;
    const [fetching, setFetching] = useState(false);
    const [formValues, setFormValues] = useState(null);
    const [accessValue, setAccessValue] = useState(null);
    const [accessValid, setAccessValid] = useState(null);
    const { TextArea } = Input;
    useEffect(() => {
        fetch();
    }, [props.visible]);

    useEffect(() => {
        props.form.resetFields();
        setFieldsValue(formValues);
    }, [formValues]);

    useEffect(() => {
        checkInstagramAccessToken();
    }, [accessValue]);

    function fetch() {
        setFetching(true);
        requestGet(CONSTANTS_REQ.WEBPAGECOMPONENT.EDIT + '/' + props.id).then((result) => {
            setFetching(false);
            if (result.status === RESPONSE.OK) {
                setValues(result.data);
            }
        });
    }

    function setValues(component_data) {
        var json;
        if (component_data.json) {
            json = JSON.parse(component_data.json);
            if (json.access_token) {
                setAccessValue(json.access_token);
            }
            setFormValues(json);
        }
    }

    function showNotification(title, message, duration, type) {
        notification[type]({
            message: title,
            description: message,
            duration: duration,
        });
    }

    function okNotification() {
        showNotification(geti18nText('app.default.save.ok'), geti18nText('app.default.save.ok.desc'), 3, 'success');
    }

    function error(message) {
        showNotification(geti18nText('app.default.save.nok'), message, 3, 'error');
    }

    function save(e, close) {
        e.preventDefault();
        props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                requestPut(CONSTANTS_REQ.WEBPAGECOMPONENT.UPDATEJSON + '/' + props.id, null, {
                    id: props.id,
                    json: JSON.stringify(values),
                }).then((result) => {
                    if (result && result.status === RESPONSE.CREATED) {
                        console.log('okNotification');
                        okNotification();
                        close && props.closePopup();
                    } else {
                        error();
                        if (result && result.status === RESPONSE.BAD_REQUEST) {
                            if (result.data && result.data.field) {
                                switch (result.data.field) {
                                    case 'mandatory_fields_required':
                                        console.log(geti18nText('app.default.mandatory_fields_required'));
                                        return;
                                    default:
                                        break;
                                }
                            }
                        }
                        if (result.data && result.data.error) {
                            console.log(JSON.stringify(result.data.error));
                        }
                    }
                });
            }
        });
    }

    function labelAccessToken() {
        return (
            <div style={{ display: 'inline' }}>
                {geti18nText('component.instagramfeed.edit.access.token')}
                {'  '}
                {accessValue && (
                    <Tag color={accessValid ? 'green' : '#ed1114'}>
                        {' '}
                        {accessValid
                            ? geti18nText('component.instagramfeed.edit.access.token.valid')
                            : geti18nText('component.instagramfeed.edit.access.token.invalid')}
                    </Tag>
                )}
            </div>
        );
    }

    async function checkInstagramAccessToken() {
        if (accessValue == null) {
            setAccessValid(false);
            return;
        }
        const resp = await requestGet(CONSTANTS_REQ.INSTAGRAM_FEED.ME, {
            fields: 'id,username',
            access_token: accessValue,
        });
        if (resp.status === RESPONSE.OK) {
            setAccessValid(true);
            return;
        }
        setAccessValid(false);
    }

    const onChange = (e) => {
        setAccessValue(e.target.value);
    };

    if (fetching) {
        return <Spinner />;
    } else {
        return (
            <React.Fragment>
                <Divider>{geti18nText('component.instagramfeed')}</Divider>
                <Form>
                    <Row style={{ marginTop: '20px ' }}>
                        <Col span={24}>
                            <Form.Item label={labelAccessToken()}>
                                {getFieldDecorator('access_token', {
                                    rules: [{ required: true, message: geti18nText('app.default.required') }],
                                })(<TextArea value={accessValue} onChange={onChange} rows={3}></TextArea>)}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '20px ' }}>
                        <Col span={10}>
                            <Form.Item label={geti18nText('component.instagramfeed.edit.access.items.numer.row')}>
                                {getFieldDecorator('number_items', {
                                    rules: [{ required: true, message: geti18nText('app.default.required') }],
                                })(<InputNumber min={0} max={8} />)}
                            </Form.Item>
                        </Col>
                        <Col span={10}>
                            <Form.Item label={geti18nText('component.instagramfeed.edit.access.items.numer.total')}>
                                {getFieldDecorator('number_items_total', {
                                    rules: [{ required: true, message: geti18nText('app.default.required') }],
                                })(<InputNumber min={0} max={50} />)}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24} style={{ textAlign: 'right', marginTop: '1rem' }}>
                            <Button style={{ marginRight: '1em' }} onClick={(e) => props.closePopup()}>
                                {geti18nText('app.default.button.cancel')}
                            </Button>
                            <Button style={{ marginRight: '1em' }} type="primary" onClick={(e) => save(e, false)}>
                                {geti18nText('app.default.button.save')}
                            </Button>
                            <Button
                                type="primary"
                                style={{ background: '#31b531', borderColor: '#31b531', textColor: 'white' }}
                                onClick={(e) => {
                                    save(e, true);
                                }}
                            >
                                {geti18nText('app.default.button.save.close')}
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </React.Fragment>
        );
    }
});

export default InstagramFeedEdit;
