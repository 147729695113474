import React from "react";
import { Icon } from "antd";
import { DataTable, getColumnSearch, getColumnSearchOption } from "../../../components/DataTable";
import LangEdit from "./LangEdit";
import { CONSTANTS_REQ } from "../../../utils/Constants";
import { Session, ENUMS, geti18nText } from "../../../utils/Session";

const LangList = (props) => {
    function setDefaultFilterValue() {
        return [{ field: "active", condition: "equals_bool", value: 1 }];
    }
    return (
        <DataTable
            headerTitle={geti18nText("lang.table.header")}
            url={CONSTANTS_REQ.LANG.LIST}
            addedData={{ projectId: Session.getProjectId() }}
            addNewButtonText={geti18nText("lang.table.add")}
            showRecordModal={true}
            modalComponent={LangEdit}
            setDefaultFilterValue={setDefaultFilterValue}
            columns={[
                {
                    title: geti18nText("lang.table.column.id"),
                    dataIndex: "id",
                    width: "10%",
                    sorter: (a, b) => {},
                    ...getColumnSearch("number"),
                },
                {
                    title: geti18nText("lang.table.column.abbreviation"),
                    dataIndex: "abbreviation",
                    sorter: (a, b) => {},
                    ...getColumnSearch("string"),
                },
                {
                    title: geti18nText("lang.table.column.name"),
                    dataIndex: "name",
                    sorter: (a, b) => {},
                    ...getColumnSearch("string"),
                },
                {
                    title: geti18nText("lang.table.column.isoCode"),
                    dataIndex: "isoCode",
                    sorter: (a, b) => {},
                    ...getColumnSearch("string"),
                },
                {
                    title: geti18nText("lang.table.column.active"),
                    dataIndex: "active",
                    width: "10%",
                    render: (text, record) => {
                        if (record.active) {
                            return <Icon type="check" style={{ color: "green" }} />;
                        } else {
                            return <Icon type="close" style={{ color: "red" }} />;
                        }
                    },
                    ...getColumnSearchOption(ENUMS.ACTIVE(), "equals_bool", setDefaultFilterValue()[0]["value"]),
                    defaultFilteredValue: setDefaultFilterValue(),
                },
            ]}
        />
    );
};

export default LangList;
