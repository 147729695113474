import React, { useState, useEffect, useRef } from 'react';
import { requestGet, RESPONSE, requestPost } from '../../../../utils/RequestResolver';
import { CONSTANTS_REQ, CONSTANTS_FILE_TYPE } from '../../../../utils/Constants';
import { Session, geti18nText } from '../../../../utils/Session';
import {
    Row,
    Divider,
    Form,
    Button,
    message,
    Col,
    Tabs,
    Popconfirm,
    InputNumber,
    Slider,
    notification,
    Collapse,
    Input,
    Modal,
    Checkbox,
    Select,
    Icon,
    Popover,
    Radio,
    List
} from 'antd';
import { FileUpload } from '../../../../components/fileUpload/FileUpload';
import { InputByLanguage, getTranslated, translationsToObject } from '../../../../components/InputByLanguage';
import { normalizeComponents } from '../../../../utils/Utils';
import DraggableTabs from './DraggableTabs';
import { SearchField } from '../../../../components/Search';
import MediaEditTab from './MediaEditTab';
import DeseignEditor from '../../../../components/deseign-editor';
import TouristFooterSettings from '../footer/Settings';
import FooterComponentEdit from '../../../../components/footercomponent/FooterComponentEdit';
import TextArea from 'antd/lib/input/TextArea';
import { ColorPicker } from '../../../../components/ColorPicker';
import { getSelectedClientLanguage } from '../../../../utils/Utils';
import { Spinner } from '../../../../components/Spinner';

const ButtonGroup = Button.Group;
const { TabPane } = Tabs;
const { Panel } = Collapse;
const { Option } = Select;

const NyTouristSettings = Form.create({ name: 'NyTouristSettings' })((props) => {
    const { getFieldDecorator, setFieldsValue, resetFields, getFieldValue } = props.form;
    const [selectedProjectId, setSelectedProjectId] = useState(0);
    const [pageSearchDisabled, setPageSearchDisabled] = useState(true);
    const [selectedProjectId2, setSelectedProjectId2] = useState(0);
    const [pageSearchDisabled2, setPageSearchDisabled2] = useState(true);
    const [selectedProjectId3, setSelectedProjectId3] = useState(0);
    const [pageSearchDisabled3, setPageSearchDisabled3] = useState(true);
    const [initNavLogoProject, setInitNavLogoProject] = useState({});
    const [designVisible, setDesignVisible] = useState(false);
    const [designComponentName, setDesignComponentName] = useState(null);
    const [componentId, setComponentId] = useState(null);
    const [navbarValue, setNavbarValue] = useState(undefined);
    const [componentNavbarName, setComponentNavbarName] = useState(null);
    const [footerValue, setFooterValue] = useState(undefined);
    const [componentFooterName, setComponentFooterName] = useState(null);
    const [touristFooterVisible, setTouristFooterVisible] = useState(false);
    const [formValues, setFormValues] = useState(undefined);
    const [footerComponentModalVisible, setFooterComponentModalVisible] = useState(false);
    const [footerComponentYlModalVisible, setFooterComponentYlModalVisible] = useState(false);
    const [footerModalVisible, setFooterModalVisible] = useState(false);
    const [modalListFooterItemVisible, setModalListFooterItemVisible] = useState(false);
    const [listTypeFooter, setListTypeFooter] = useState(undefined);
    const [showLogoPanel, setShowLogoPanel] = useState(true);
    const [blockCompany, setBlockCompany] = useState([]);
    const [links, setLinks] = useState([]);
    const [deletedLinks, setDeletedLinks] = useState([]);
    const [linksKeys, setLinksKeys] = useState([0]);
    const [deleteLinksVisible, setDeleteLinksVisible] = useState(false);
    const [linksToDelete, setLinksToDelete] = useState(null);
    const [lastKeyLinks, setLastKeyLinks] = useState(0);
    const [activeTabLinks, setActiveTabLinks] = useState('0');
    const [hoverColor, setHoverColor] = useState('#ff7f50');
    const [lastKey, setLastKey] = useState(0);
    const [companyKeys, setCompanyKeys] = useState([0]);
    const [activeTab, setActiveTab] = useState('0');
    const [deleteBlockCompanyVisible, setDeleteBlockCompanyVisible] = useState(false);
    const [companyToDelete, setCompanyToDelete] = useState(null);
    const [deletedCompanies, setDeletedCompanies] = useState([]);
    //   const [deletedCompanies, setDeletedCompanies] = useState([]);
    const [footerChangeConfirmVisible, setFooterChangeConfirmVisible] = useState(false);
    const [chosenFooterValue, setChosenFooterValue] = useState(undefined);
    const [chosenFormFooterComponentName, setChosenFormFooterComponentName] = useState(undefined);
    const [oldFooterValue, setOldFooterValue] = useState(undefined);
    const [dataListLeft, setDataListLeft] = useState([]);
    const [dataListRight, setDataListRight] = useState([]);
    const [selectedItemFooterList, setSelectedItemFooterList] = useState(undefined);
    const [selectedItemTopLink, setSelectedItemTopLink] = useState(undefined);
    const [footerEdit, setFooterEdit] = useState(false);
    const [emails, setEmails] = useState(null);
    const [modalListTopLinks, setModalListTopLinks] = useState(false);
    const [topLinkEdit, setTopLinkEdit] = useState(false);
    const [dataListTopLinks, setDataListTopLinks] = useState([]);
    const [fetching, setFetching] = useState(true);
    const [formValuesHeaderYl, setFormValuesHeaderYl] = useState(undefined);


    const LINK_TYPE = {
        LINK: 'LINK',
        WEBPAGE: 'WEBPAGE',
    };
    const [linkTypeTopList, setLinkTypeTopList] = useState(LINK_TYPE.WEBPAGE);

    const [footerText, setFooterText] = useState({
        settingKey: 'footer.text',
        settingValue: '',
        settingGroup: 'footer',
    });

    const [footerTextWeb, setFooterTextWeb] = useState({
        settingKey: 'footer.text.web',
        settingValue: {},
        settingGroup: 'footer',
    });

    const [footerTextKiosk, setFooterTextKiosk] = useState({
        settingKey: 'footer.text.kiosk',
        settingValue: {},
        settingGroup: 'footer',
    });

    const [footer, setFooter] = useState({
        settingKey: 'footer.id',
        settingValue: '',
        settingGroup: 'footer',
    });

    const [navLogo, setNavLogo] = useState({
        settingKey: 'nav.logo',
        settingValue: '',
        settingGroup: 'nav',
    });

    const [navSearch, setNavSearch] = useState({
        settingKey: 'nav.search',
        settingValue: false,
        settingGroup: 'nav',
    });

    const [langFlag, setLangFlag] = useState({
        settingKey: 'lang.flag',
        settingValue: false,
        settingGroup: 'lang',
    });

    const [footerLogo, setFooterLogo] = useState({
        settingKey: 'footer.logo',
        settingValue: '',
        settingGroup: 'footer',
    });

    const [mapZoomText, setMapZoomText] = useState({
        settingKey: 'map.zoomText',
        settingValue: {},
        settingGroup: 'map',
    });

    const [favoriteIcon, setFavoriteIcon] = useState({
        settingKey: 'favoriteIcon',
        settingValue: {},
        settingGroup: 'icon',
    });

    const [screensaverFiles, setScreensaverFiles] = useState({
        settingKey: 'screensaverFiles',
        settingValue: {},
        settingGroup: 'screensaver',
    });

    const [bannerFiles, setBannerFiles] = useState({
        settingKey: 'bannerFiles',
        settingValue: {},
        settingGroup: 'banner',
    });

    const [screensaverVericalFiles, setScreensaverVerticalFiles] = useState({
        settingKey: 'screensaverVerticalFiles',
        settingValue: {},
        settingGroup: 'screensaverVertical',
    });

    const [footerSettings, setFooterSettings] = useState({
        settingKey: 'footer.text',
        settingValue: {},
        settingGroup: 'footer',
    });

    const [headerYlSettings, setheaderYlSettings] = useState({
        settingKey: 'nav.headerYlSettings',
        settingValue: {},
        settingGroup: 'nav',
    });
    
    const [headerSubmenuLinksSettings, setheaderSubmenuLinksSettings] = useState({
        settingKey: 'nav.headerSubmenuLinksSettings',
        settingValue: {},
        settingGroup: 'nav',
    });

    const screensaverRef = useRef();
    const bannerRef = useRef();
    const screensaverVericalRef = useRef();

    const iconRef = useRef();

    const [zoomTextJson, setZoomTextJson] = useState({});

    const [iconFiles, setIconFiles] = useState({
        settingKey: 'footer.icons',
        settingValue: {},
        settingGroup: 'footer',
    });

    const [nav, setNav] = useState({
        settingKey: 'nav.id',
        settingValue: '',
        settingGroup: 'nav',
    });

    const [googleRecaptchaSettings, setGoogleRecaptchaSettings] = useState({
        settingKey: 'gRecaptcha',
        settingValue: {},
        settingGroup: 'google',
    });
    const [newsletterMails, setNewsLetterMails] = useState({
        settingKey: 'mails',
        settingValue: {},
        settingGroup: 'newsletter',
    });

    useEffect(() => {
        const init=async()=>{
            await getNavLogoAndMenu();
            setFetching(false);
            setInitNavLogoData();
            setBlockCompany([{ id: 0 }]);
            setLinks([{ id: 0 }]);
            setLinksKeys([0]);
            getNavSearch();
            
            getLangFlag();
    
            getFooterLogo();
            getMapZoomText();
            getFavoriteIcon();
            getFooterText();
            getFooterIcons();
    
            getFooter();
            getNavHeaderYlSettings();
            getNavHeaderSubmenuLinksSettings();
            getgCaptchaSettings();
            getNewsletterMails();
        }
        init()
        
    }, []);

    useEffect(() => {
        
        setFieldsValue(formValuesHeaderYl);
    }, [formValuesHeaderYl]);


    async function getNavLogoAndMenu() {
        await getNavbar();
        getNavLogo();
    }

    const onModalTopListCancel = () => {
        setSelectedItemTopLink(undefined);
        setTopLinkEdit(false)
        setModalListTopLinks(false);
    };

    const onTopListItemSave = () => {
        props.form.validateFieldsAndScroll((err, values) => {
            values= normalizeComponents(values);
            let json={};
            json.nameLinkTopItem=values.nameLinkTopItem 
            json.typeLinkTopItem=values.typeLinkTopItem
            json.link_top_list_text=values.link_top_list_text
            json.link_top_list=values.link_top_list
            if(!topLinkEdit) {
                json.id=Math.floor(Math.random() * (9999999999))
            }
            let list=dataListTopLinks
            if(!topLinkEdit) {
                list=list.concat(json)
            }
            else {
                list=list.map((item) =>{
                       return item.id === selectedItemTopLink.id ? { ...json, id: item.id } : item
                })
            }
    
         setDataListTopLinks(list)

        })
        resetFields(['typeLinkTopItem', 'link_top_list_text', 'link_top_list', 'nameLinkTopItem'])
        setTopLinkEdit(false);
        setModalListTopLinks(false);

    }

    async function getgCaptchaSettings() {
        const resp = await requestGet(CONSTANTS_REQ.SETTINGS.ADMIN, {
            group: 'google',
            key: 'gRecaptcha',
            projectId: Session.getProjectId(),
        });
        if (resp.status === RESPONSE.OK) {
            setGoogleRecaptchaSettings(resp.data);
            if (resp.data.settingValue != null) {
                let gCaptchaValues=JSON.parse(resp.data.settingValue);
                if(gCaptchaValues.siteKey) {
                    setFieldsValue({ recaptchaSiteKey: gCaptchaValues.siteKey })
                }
                if(gCaptchaValues.webKey) {
                    setFieldsValue({ recaptchaWebKey: gCaptchaValues.webKey})
                }
                

            }
        }
    }

    async function getNewsletterMails() {
        const resp = await requestGet(CONSTANTS_REQ.SETTINGS.ADMIN, {
            group: 'newsLetter',
            key: 'mails',
            projectId: Session.getProjectId(),
        });
        if (resp.status === RESPONSE.OK) {
            setNewsLetterMails(resp.data);
            if (resp.data.settingValue != null) {
                let gCaptchaValues=JSON.parse(resp.data.settingValue);
               
                if(Object.keys(gCaptchaValues).length>0){
                    setEmails(gCaptchaValues)
                    if(gCaptchaValues) {
                        setFieldsValue({ emails: gCaptchaValues })
                    }
                }
            }
        }
    }

    useEffect(() => {
        //  props.form.resetFields();
        setFieldsValue(formValues);
    }, [formValues]);

    function setInitNavLogoData() {
        const proId = Session.getProjectId();
        const proName = Session.getProjectInfo().name;
        setInitNavLogoProject({ key: proId, label: proName });
        setSelectedProjectId(proId);
        setPageSearchDisabled(false);
        setSelectedProjectId2(proId);
        setPageSearchDisabled2(false);
        setSelectedProjectId3(proId);
        setPageSearchDisabled3(false);
    }

    function onChangeMenu(e) {
        if (e != null) {
            let _navbar = componentValues('navbar').filter((value) => value.id == e.key);
            _navbar[0].name === 'touristMenu' ? setShowLogoPanel(true) : setShowLogoPanel(false);
            setNavbarValue(e);
            formNavbarComponentName(e.key);
        } else {
            setNavbarValue(undefined);
            formNavbarComponentName(undefined);
        }
    }

    function footerOnChange(e) {
        if (e != null) {
            setOldFooterValue(footerValue);
            setChosenFooterValue(e);
            setChosenFormFooterComponentName(e.key);
            footerValue && setFooterChangeConfirmVisible(true);
            setFooterValue(e);
            formFooterComponentName(e.key);
        } else {
            setFooterValue(undefined);
            formFooterComponentName(undefined);
        }
    }

    /* async function getFooterText() {
        const resp = await requestGet(CONSTANTS_REQ.SETTINGS.EDIT, {
            group: 'footer',
            key: 'footer.text',
            projectId: Session.getProjectId(),
        });
        if (resp.status === RESPONSE.OK) {
            setFooterText(resp.data);
        }
    } */

    const tabOnChange = (activeKey) => {
        props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                setActiveTab(activeKey);
            }
        });
    };

    const tabOnChangeLinks = (activeKey) => {
        props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                setActiveTabLinks(activeKey);
            }
        });
    };

    const deleteBlockCompany = () => {
        if (companyToDelete) {
            var newArrKeys = companyKeys.filter((key) => key != parseInt(companyToDelete));
            var newArrCompanies = blockCompany.filter((company) => company.id != parseInt(companyToDelete));
            setCompanyKeys(newArrKeys);
            setBlockCompany(newArrCompanies);

            if (activeTab == companyToDelete) {
                setActiveTab('' + newArrKeys[0]);
            }
            setDeleteBlockCompanyVisible(false);
            setCompanyToDelete(null);
            let deleted = deletedCompanies.slice();
            deleted.push(companyToDelete);
            setDeletedCompanies(deleted);
        }
    };

    async function getFooter() {
        const resp = await requestGet(CONSTANTS_REQ.SETTINGS.ADMIN, {
            group: 'footer',
            key: 'footer.id',
            projectId: Session.getProjectId(),
        });
        if (resp.status === RESPONSE.OK) {
            setFooter(resp.data);
            if (resp.data.settingValue != null) {
                let footer = JSON.parse(resp.data.settingValue);
                setFooterValue(footer);
                formFooterComponentName(footer.key);
            }
        }
    }

    async function getFooterSettings() {
        const resp = await requestGet(CONSTANTS_REQ.SETTINGS.ADMIN, {
            group: 'footer',
            key: 'footer.text',
            projectId: Session.getProjectId(),
        });
        if (resp.status === RESPONSE.OK) {
            setFooterSettings(resp.data);
            if (resp.data.settingValue != null) {
                let jsonData = JSON.parse(resp.data.settingValue);
                let json = {};
                json['title'] = jsonData.title;
                json['bottom_text'] = jsonData.bottom_text;
                json['facebook'] = jsonData.facebook;
                json['linkedin'] = jsonData.linkedin;
                json['instagram'] = jsonData.instagram;
                json['youtube'] = jsonData.youtube;
                let linksArrKeys = [];
                let linksArr = [];

                if (jsonData.links != null) {
                    jsonData.links.forEach((value) => {
                        linksArrKeys.push(value.id);
                        linksArr.push({ id: value.id, textJsonLink: value.title });
                        json = translationsToObject(jsonData, 'link_title' + value.id, value.title);
                        json['url_link' + value.id] = value.link;
                        setLastKeyLinks(value.id);
                    });
                    setLinksKeys(linksArrKeys);
                    setLinks(linksArr);
                    setActiveTabLinks('' + linksArrKeys[0]);
                }

                if (jsonData.hoverColor) {
                    setHoverColor(jsonData.hoverColor);
                    json['hoverColor'] = jsonData.hoverColor;
                } else {
                    json['hoverColor'] = hoverColor;
                }

                let companyBlockArrKeys = [];
                let companyBlockArr = [];
                if (jsonData.block_company != null) {
                    jsonData.block_company.forEach((value) => {
                        companyBlockArrKeys.push(value.id);
                        companyBlockArr.push({ id: value.id });
                        json['companyName' + value.id] = value.companyName;
                        json['company_link' + value.id] = value.company_link;
                        json['address' + value.id] = value.address;
                        json['postCode' + value.id] = value.postCode;
                        json['mail' + value.id] = value.mail;
                        json['phones' + value.id] = value.phones;
                        json['fax' + value.id] = value.fax;
                        setLastKey(value.id);
                    });
                    setCompanyKeys(companyBlockArrKeys);
                    setBlockCompany(companyBlockArr);
                    setActiveTab('' + companyBlockArrKeys[0]);
                }
                setFormValues(json);
            }
        }
    }
    async function getFooterYlSettings() {
        const resp = await requestGet(CONSTANTS_REQ.SETTINGS.ADMIN, {
            group: 'footer',
            key: 'footer.text',
            projectId: Session.getProjectId(),
        });
        if (resp.status === RESPONSE.OK) {
            setFooterSettings(resp.data);
            if (resp.data.settingValue != null) {
                let jsonData = JSON.parse(resp.data.settingValue);
                let json = {};
                if(jsonData.typePrivacy) {
                    setLinkTypePrivacy(jsonData.typePrivacy)
                }
                if(jsonData.footer_image) {
                    jsonData.footer_image= {
                        key: jsonData.footer_image,
                        file_path: jsonData.footer_image,
                    };
                    //setLinkTypeTerms(jsonData.typePrivacy)
                }
                if(jsonData.typeTerms) {
                    setLinkTypeTerms(jsonData.typeTerms)
                }
                if(jsonData.link_privacy) {
                    //jsonData.link_privacy={ id: parseInt(jsonData.link_privacy) }
                    setWepPageValue('link_privacy',parseInt(jsonData.link_privacy) )
                    
                }
                if(jsonData.link_terms) {
                    //jsonData.link_terms={ id: parseInt(jsonData.link_terms) }
                    setWepPageValue('link_terms',parseInt(jsonData.link_terms) )
                }       
                if(jsonData.dataListLeft) {
                    let list=JSON.parse(jsonData.dataListLeft)
                    setDataListLeft(list)
                } 
                if(jsonData.dataListRight) {
                    let list=JSON.parse(jsonData.dataListRight)
                    setDataListRight(list)
                    
                }         
                setFormValues(jsonData);
            }
        }
    }
    function setWepPageValue(key, id) {
        requestGet(CONSTANTS_REQ.WEBPAGE.EDIT + '/' + id).then((result) => {
            if (result.status === RESPONSE.OK) {
                let field = {};
                field[key] = { id: id, name: result.data.name };
                setFieldsValue(field);
            }
        });
    }

    function formFooterComponentName(componentKey) {
        let component = componentValues('footer').find((element) => element.id === parseInt(componentKey));
        if (component != null) {
            setComponentFooterName(component.name);
        }
    }

    const footerModalShow = () => {
        getFooterSettings();
        setFooterModalVisible(true);
    };

    function openFooterSettings() {
        let footerComponent = Session.getAppValue('COMPONENTS').find(
            (element) => element.id === parseInt(footerValue.key)
        );
        if (footerComponent) {
            switch (footerComponent.name) {
                case 'btcFooter':
                    footerModalShow();
                    break;
                case 'footercomponent':
                    setFooterComponentModalVisible(true);
                    break;
                case 'ylFooter':
                    getFooterYlSettings();
                    setFooterComponentYlModalVisible(true);
                    break;
                default:
                    getFooterTouristSettings();
                    setTouristFooterVisible(true);
            }
        } else {
            getFooterTouristSettings();
            setTouristFooterVisible(true);
        }
    }

    async function getFooterTouristSettings() {
        const resp = await requestGet(CONSTANTS_REQ.SETTINGS.ADMIN, {
            group: 'footer',
            key: 'footer.text.kiosk',
            projectId: Session.getProjectId(),
        });
        let json = {};
        if (resp.status === RESPONSE.OK) {
            setFooterTextKiosk(resp.data);
            translationsToObject(json, 'footTextKiosk', resp.data.settingValue);
        }

        const respFooterWebText = await requestGet(CONSTANTS_REQ.SETTINGS.ADMIN, {
            group: 'footer',
            key: 'footer.text.web',
            projectId: Session.getProjectId(),
        });

        if (respFooterWebText.status === RESPONSE.OK) {
            setFooterTextWeb(respFooterWebText.data);
            translationsToObject(json, 'footTextWeb', respFooterWebText.data.settingValue);
        }

        const respLogo = await requestGet(CONSTANTS_REQ.SETTINGS.ADMIN, {
            group: 'footer',
            key: 'footer.logo',
            projectId: Session.getProjectId(),
        });
        if (respLogo.status === RESPONSE.OK) {
            setFooterLogo(respLogo.data);
            if (respLogo.data.settingValue) {
                json.footLogo = JSON.parse(respLogo.data.settingValue);
            }
        }
        setFormValues(json);
    }

    function componentValues(property) {
        return Session.getAppValue('COMPONENTS')
            .filter((fObj) => {
                if (fObj.componentFooterMenu === property) {
                    return fObj;
                }
            })
            .map((obj) => {
                let rObj = {};
                rObj.id = obj.id;
                rObj.text = geti18nText('component.' + obj.name);
                rObj.name = obj.name;
                return rObj;
            });
    }

    function formNavbarComponentName(componentKey) {
        let component = componentValues('navbar').find((element) => element.id === parseInt(componentKey));
        if (component != null) {
            setComponentNavbarName(component.name);
        }
        return component;
    }
    const onEdit = (targetKey, action) => {
        if (action == 'add') {
            props.form.validateFieldsAndScroll((err, values) => {
                if (!err) {
                    let newId = lastKey;
                    newId++;
                    let arrBlockCompanies = blockCompany.slice();
                    let arrKeys = companyKeys.slice();
                    arrKeys.push(newId);
                    setCompanyKeys(arrKeys);
                    arrBlockCompanies.push({ id: newId });
                    setBlockCompany(arrBlockCompanies);
                    setActiveTab('' + newId);
                    setLastKey(newId);
                }
            });
        }
        if (action == 'remove') {
            if (companyKeys.length > 1) {
                setDeleteBlockCompanyVisible(true);
                setCompanyToDelete(targetKey);
            }
        }
    };

    const deleteLinks = () => {
        if (linksToDelete) {
            var newArrKeys = linksKeys.filter((key) => key != parseInt(linksToDelete));
            var newArrLinks = links.filter((link) => link.id != parseInt(linksToDelete));
            setLinksKeys(newArrKeys);
            setLinks(newArrLinks);

            if (activeTabLinks == linksToDelete) {
                setActiveTabLinks('' + newArrKeys[0]);
            }
            setDeleteLinksVisible(false);
            setLinksToDelete(null);
            let deleted = deletedLinks.slice();
            deleted.push(linksToDelete);
            setDeletedLinks(deleted);
        }
    };

    const onEditLinks = (targetKey, action) => {
        if (action == 'add') {
            props.form.validateFieldsAndScroll((err, values) => {
                if (!err) {
                    let newId = lastKeyLinks;
                    newId++;
                    let arrLinks = links.slice();
                    let arrKeys = linksKeys.slice();
                    arrKeys.push(newId);
                    setLinksKeys(arrKeys);
                    arrLinks.push({ id: newId });
                    setLinks(arrLinks);
                    setActiveTabLinks('' + newId);
                    setLastKeyLinks(newId);
                }
            });
        }
        if (action == 'remove') {
            if (linksKeys.length > 1) {
                setDeleteLinksVisible(true);
                setLinksToDelete(targetKey);
            }
        }
    };

    function getColor(object) {
        if (object && object.settingValue && object.settingValue != '') {
            let o = JSON.parse(object.settingValue);
            return o && o.color ? o.color : null;
        }
        return null;
    }

    async function getNavLogo() {
        const resp = await requestGet(CONSTANTS_REQ.SETTINGS.ADMIN, {
            group: 'nav',
            key: 'nav.logo',
            projectId: Session.getProjectId(),
        });
        if (resp.status === RESPONSE.OK) {
            setNavLogo(resp.data);
            if (resp.data.settingValue != null) {
                let json = JSON.parse(resp.data.settingValue);
                let logoFiles = {};
                let logoProjects = {};
                let logoPages = {};
                if (Array.isArray(json)) {
                    json.forEach((logo) => {
                        if (Object.entries(logo).length !== 0) {
                            switch (logo.key) {
                                case 1:
                                    if (logo.project.id !== undefined) {
                                        setSelectedProjectId(logo.project.id);
                                    }
                                    break;
                                case 2:
                                    if (logo.project.id !== undefined) {
                                        setSelectedProjectId2(logo.project.id);
                                    }
                                    break;
                                case 3:
                                    if (logo.project.id !== undefined) {
                                        setSelectedProjectId3(logo.project.id);
                                    }
                                    break;
                            }
                            logoFiles['navLogo_' + logo.key] = {
                                file_path: logo.file_path,
                                name: logo.name,
                                file_type: logo.file_type,
                            };
                            logoProjects['navLogoProject_' + logo.key] =
                                Object.entries(logo.project).length === 0
                                    ? {}
                                    : { key: logo.project.id, label: logo.project.name };
                            logoPages['navLogoPage_' + logo.key] =
                                Object.entries(logo.webPage).length === 0
                                    ? {}
                                    : { key: logo.webPage.id, label: logo.webPage.name };
                        }
                    });
                } else if ('file_path' in json) {
                    logoFiles['navLogo_1'] = json;
                    logoFiles['navLogo'] = json;
                }
                props.form.setFieldsValue(logoFiles);
                props.form.setFieldsValue(logoProjects);
                props.form.setFieldsValue(logoPages);
            }
        }
    }

    async function getFooterLogo() {
        const resp = await requestGet(CONSTANTS_REQ.SETTINGS.ADMIN, {
            group: 'footer',
            key: 'footer.logo',
            projectId: Session.getProjectId(),
        });
        if (resp.status === RESPONSE.OK) {
            setFooterLogo(resp.data);
            if (resp.data.settingValue != null) {
                setFieldsValue({ footLogo: JSON.parse(resp.data.settingValue) });
            }
        }
    }

    async function getNavSearch() {
        const resp = await requestGet(CONSTANTS_REQ.SETTINGS.ADMIN, {
            group: 'nav',
            key: 'nav.search',
            projectId: Session.getProjectId(),
        });
        if (resp.status === RESPONSE.OK) {
            setNavSearch(resp.data);
            if (resp.data.settingValue != null) {
                setFieldsValue({ searchNav: JSON.parse(resp.data.settingValue) });
            }
        }
    }
    async function getNavHeaderYlSettings() {
        const resp = await requestGet(CONSTANTS_REQ.SETTINGS.ADMIN, {
            group: 'nav',
            key: 'nav.headerYlSettings',
            projectId: Session.getProjectId(),
        });
        if (resp.status === RESPONSE.OK) {
            setheaderYlSettings(resp.data);
            if (resp.data.settingValue != null) {
                let headerYlValues=JSON.parse(resp.data.settingValue);
                
                if(headerYlValues.sideText) {
                    headerYlValues = translationsToObject(headerYlValues, 'sideText', headerYlValues.sideText);
        
                    //setFieldsValue(json)
                    delete headerYlValues.sideText
                }
                setFormValuesHeaderYl(headerYlValues)

            }
        }
    }
    
    async function getNavHeaderSubmenuLinksSettings() {
        const resp = await requestGet(CONSTANTS_REQ.SETTINGS.ADMIN, {
            group: 'nav',
            key: 'nav.headerSubmenuLinksSettings',
            projectId: Session.getProjectId(),
        });
        if (resp.status === RESPONSE.OK) {
            setheaderSubmenuLinksSettings(resp.data);
            if (resp.data.settingValue != null) {
                let headerYlValues=JSON.parse(resp.data.settingValue);
                if(headerYlValues.headerMenuSubLinksBtnText) {
                    let json = {};
                    json = translationsToObject(json, 'headerMenuSubLinksBtnText', headerYlValues.headerMenuSubLinksBtnText);
                    setFieldsValue(json);
                }
                if(headerYlValues.dataListTopLinks) {
                    let list=JSON.parse(headerYlValues.dataListTopLinks)
                    setDataListTopLinks(list)
                }
               
            }
        }
    }

    async function getNavbar() {
        const resp = await requestGet(CONSTANTS_REQ.SETTINGS.ADMIN, {
            group: 'nav',
            key: 'nav.id',
            projectId: Session.getProjectId(),
        });
        if (resp.status === RESPONSE.OK) {
            setNav(resp.data);
            if (resp.data.settingValue != null) {
                let navbar = JSON.parse(resp.data.settingValue);
                setNavbarValue(navbar);
                let component = formNavbarComponentName(navbar.key);
                setShowLogoPanel(false);
                if (component != null && component.name === 'touristMenu') {
                    setShowLogoPanel(true);
                }
            }
        }
    }

    async function getLangFlag() {
        const resp = await requestGet(CONSTANTS_REQ.SETTINGS.ADMIN, {
            group: 'lang',
            key: 'lang.flag',
            projectId: Session.getProjectId(),
        });
        if (resp.status === RESPONSE.OK) {
            setLangFlag(resp.data);
            if (resp.data.settingValue != null) {
                setFieldsValue({ langFlag: JSON.parse(resp.data.settingValue) });
            }
        }
    }

    async function getFavoriteIcon() {
        if (Session.getProjectId() != null) {
            const resp = await requestGet(CONSTANTS_REQ.SETTINGS.ADMIN, {
                group: 'icon',
                key: 'favoriteIcon',
                projectId: Session.getProjectId(),
            });
            if (resp.status === RESPONSE.OK) {
                setFavoriteIcon(resp.data);
                if (resp.data.settingValue != null) {
                    setFieldsValue({ favIcon: JSON.parse(resp.data.settingValue) });
                }
            }
        }
    }

    function normalizeFileUpload(field, newKey = null, key = null) {
        let file = props.form.getFieldsValue([field])[field];
        if (file instanceof Array) {
            file = file[0];
        }

        if (newKey !== null) {
            if (file.file_type === CONSTANTS_FILE_TYPE.IMAGE) {
                let time = props.form.getFieldValue('file-time_' + key);
                if (time === undefined) {
                    time = 30;
                }
                return file.id
                    ? file
                    : {
                          key: newKey,
                          file_path: file.file_path,
                          name: file.name,
                          file_type: file.file_type,
                          time: time,
                      };
            } else {
                return file.id
                    ? file
                    : { key: newKey, file_path: file.file_path, name: file.name, file_type: file.file_type };
            }
        } else {
            return file.id ? file : { file_path: file.file_path, name: file.name, file_type: file.file_type };
        }
    }

    function normalizeLogoFileUploadMulti(field, key) {
        let file = props.form.getFieldsValue([field])[field];
        if (file instanceof Array) {
            file = file[0];
        }
        let navLogoProject = props.form.getFieldValue('navLogoProject_' + key);
        let logoProject = navLogoProject === undefined ? {} : { id: navLogoProject.key, name: navLogoProject.label };

        let navLogoPage = props.form.getFieldValue('navLogoPage_' + key);
        let logoPage = navLogoPage === undefined ? {} : { id: navLogoPage.key, name: navLogoPage.label };

        return file === null || file === undefined
            ? {}
            : {
                  key: key,
                  file_path: file.file_path,
                  name: file.name,
                  file_type: file.file_type,
                  project: logoProject,
                  webPage: logoPage,
              };
    }

    function normalizeLogoFileUpload(field) {
        let file = props.form.getFieldsValue([field])[field];
        if (file instanceof Array) {
            file = file[0];
        }
        return file.id ? file : { key: 1, file_path: file.file_path, name: file.name };
    }

    async function getMapZoomText() {
        const resp = await requestGet(CONSTANTS_REQ.SETTINGS.ADMIN, {
            group: 'map',
            key: 'map.zoomText',
            projectId: Session.getProjectId(),
        });
        if (resp.status === RESPONSE.OK) {
            let json = {};
            json = translationsToObject(json, 'mapZoomText', resp.data.settingValue);
            setFieldsValue(json);

            setZoomTextJson(resp.data.settingValue);
            let mapZText = resp.data;
            mapZText.settingValue = {};
            if (resp.data.settingValue != null) {
                mapZText.settingValue = resp.data.settingValue;
            }
            setMapZoomText(resp.data);
        }
    }

    async function getFooterText() {
        const respWeb = await requestGet(CONSTANTS_REQ.SETTINGS.ADMIN, {
            group: 'footer',
            key: 'footer.text.web',
            projectId: Session.getProjectId(),
        });

        const respKiosk = await requestGet(CONSTANTS_REQ.SETTINGS.ADMIN, {
            group: 'footer',
            key: 'footer.text.kiosk',
            projectId: Session.getProjectId(),
        });

        let json = {};
        if (respWeb.status === RESPONSE.OK) {
            json = translationsToObject(json, 'footTextWeb', respWeb.data.settingValue);
            setFooterTextWeb(respWeb.data);
        }

        if (respKiosk.status === RESPONSE.OK) {
            json = translationsToObject(json, 'footTextKiosk', respKiosk.data.settingValue);
            setFooterTextKiosk(respKiosk.data);
        }
        setFieldsValue(json);
    }

    async function getFooterIcons() {
        const resp = await requestGet(CONSTANTS_REQ.SETTINGS.ADMIN, {
            group: 'footer',
            key: 'footer.icons',
            projectId: Session.getProjectId(),
        });
        if (resp.status === RESPONSE.OK) {
            setIconFiles(resp.data);
        }
    }

    const save = (e) => {
        e.preventDefault();

        let formValues = null;
        let nLogo = navLogo;
        let nSearch = navSearch;
        let lFlag = langFlag;
        let mapZoom = mapZoomText;
        let fIcon = favoriteIcon;
        let sFiles = screensaverFiles;
        let sVerticalFiles = screensaverVericalFiles;
        let bFiles = bannerFiles;
        let sNav = nav;
        let sFooter = footer;
        let tHeaderYlSettings = {};
        let tHeaderSubmenuLinksSettings = {};
        let gRecaptchaSettings = {};
        let tNewsLetterMails = {};

        props.form.validateFieldsAndScroll((err, values) => {
            formValues = normalizeComponents(values);
            let navLogoData = [];
            if (showLogoPanel == true) {
                var i;
                for (i = 1; i <= 3; i++) {
                    navLogoData.push(normalizeLogoFileUploadMulti('navLogo_' + i, i));
                }
                values.navLogo = {};
                if (navLogoData.length > 0) {
                    values.navLogo = JSON.stringify(navLogoData);
                }
            } else {
                if (values.navLogo) {
                    values.navLogo = JSON.stringify(normalizeLogoFileUpload('navLogo'));
                }
            }

            if (values.footLogo) {
                values.footLogo = JSON.stringify(normalizeFileUpload('footLogo'));
            }
            if (values.favIcon) {
                values.favIcon = JSON.stringify(normalizeFileUpload('favIcon'));
            }

            const settingsArray = [fIcon, nSearch, lFlag];
            if (sNav.settingValue != '') {
                settingsArray.push(sNav);
            }

            mapZoom.settingValue = formValues.mapZoomText;
            mapZoom.settingValue = JSON.stringify(mapZoom.settingValue);
            if (navbarValue) {
                let _navbar = componentValues('navbar').filter((value) => value.id == navbarValue.key);
                nLogo.settingValue = values.navLogo;
                settingsArray.push(nLogo);
            } else {
                nLogo.settingValue = values.navLogo;
                settingsArray.push(nLogo);
            }
            fIcon.settingValue = values.favIcon;
            sFiles.settingValue = values.screensaverFiles;
            bFiles.settingValue = values.bannerFiles;
            screensaverRef.current.exportValue();
            screensaverVericalRef.current.exportValue();
            nSearch.settingValue = formValues.searchNav;
            lFlag.settingValue = formValues.langFlag;
            sNav.settingValue = JSON.stringify(navbarValue);
            if (footerValue) {
                sFooter.settingValue = JSON.stringify(footerValue);
            }

            bannerRef.current.exportValue();
            iconRef.current && iconRef.current.exportValue();
            values=normalizeComponents(values);
            tHeaderYlSettings.phone=values.phone;
            tHeaderYlSettings.phoneSide=values.phoneSide;
            tHeaderYlSettings.mobile=values.mobile;
            tHeaderYlSettings.addressSide=values.addressSide;
            tHeaderYlSettings.mail=values.mail;
            tHeaderYlSettings.mailSide=values.mailSide;
            tHeaderYlSettings.sideText=values.sideText;
            tHeaderYlSettings.rightTextDetails=values.rightTextDetails;
            tHeaderYlSettings.headerYlFacebook=values.headerYlFacebook;
            tHeaderYlSettings.headerYlInstagram=values.headerYlInstagram;
            tHeaderYlSettings.headerYlLinkedin=values.headerYlLinkedin;
            tHeaderYlSettings.headerYlYoutube=values.headerYlYoutube;
            tHeaderYlSettings.address=values.address;
            tHeaderYlSettings.info=values.info;

            tHeaderSubmenuLinksSettings.headerMenuSubLinksBtnText=formValues.headerMenuSubLinksBtnText
            if(dataListTopLinks.length >0) {
                tHeaderSubmenuLinksSettings.dataListTopLinks=JSON.stringify(dataListTopLinks)

            }

            gRecaptchaSettings.siteKey=values.recaptchaSiteKey;
            gRecaptchaSettings.webKey=values.recaptchaWebKey;
            tNewsLetterMails=  emails != null && emails;
        });

        
        
        let headerYlSet = headerYlSettings;
        let headerSubLinksSet = headerSubmenuLinksSettings;
        let gCaptcha = googleRecaptchaSettings;
        let newsLetterMails = newsletterMails;
        headerYlSet.settingValue = JSON.stringify(tHeaderYlSettings);
        headerSubLinksSet.settingValue = JSON.stringify(tHeaderSubmenuLinksSettings);
        gCaptcha.settingValue = JSON.stringify(gRecaptchaSettings);
        newsLetterMails.settingValue=JSON.stringify(tNewsLetterMails);

        const settingsArray = [nLogo, nSearch, lFlag, mapZoom, fIcon, sFiles, bFiles, sVerticalFiles, sNav, headerYlSet,gCaptcha,newsLetterMails,headerSubLinksSet];

        if (sFooter.settingValue != '' && sFooter.settingValue != undefined) {
            settingsArray.push(sFooter);
        }

        requestPost(CONSTANTS_REQ.SETTINGS.SAVE_SETTINGS, null, { settings: settingsArray }).then((resp) => {
            if (resp.status == RESPONSE.OK) {
                showNotification(
                    geti18nText('app.default.save.ok'),
                    geti18nText('settings.save.success'),
                    3,
                    'success'
                );
            } else {
                showNotification(geti18nText('app.default.save.nok'), geti18nText('settings.save.not'), 0, 'error');
            }
        });
    };

    const onFooterComponentModalCancel = () => {
        setFooterComponentModalVisible(false);
    };
    
    const onFooterComponentModalYlCancel = () => {
        setFooterComponentYlModalVisible(false);
    };

    const onFooterModalCancel = () => {
        setFooterModalVisible(false);
    };
    
    const onFooterModalListItemCancel = () => {
        setSelectedItemFooterList(undefined)
        setFooterEdit(false);
        setModalListFooterItemVisible(false);
    };

    function closePopupFooterSetting() {
        setTouristFooterVisible(false);
    }

    const onTouristFooterSettingsModalCancel = () => {
        setTouristFooterVisible(false);
    };

    function onFooterTouristModalSave() {
        props.form.validateFieldsAndScroll((err, values) => {
            let formValues = null;
            let fTextWeb = footerTextWeb;
            let fTextKiosk = footerTextKiosk;

            formValues = normalizeComponents(values);
            if (JSON.stringify(formValues.footTextWeb) !== '{}') {
                fTextWeb.settingValue = JSON.stringify(formValues.footTextWeb);
            }
            if (JSON.stringify(formValues.footTextKiosk) !== '{}') {
                fTextKiosk.settingValue = JSON.stringify(formValues.footTextKiosk);
            }

            let fLogo = footerLogo;
            if (values.footLogo) {
                values.footLogo = JSON.stringify(normalizeFileUpload('footLogo'));
                fLogo.settingValue = values.footLogo;
            }

            const settingsArray = [];
            if (fLogo.settingValue != null) {
                settingsArray.push(fLogo);
            }

            if (fTextWeb.settingValue != null) {
                settingsArray.push(fTextWeb);
            }
            if (fTextKiosk.settingValue != null) {
                settingsArray.push(fTextKiosk);
            }

            let componentValue = Session.getAppValue('COMPONENTS').find(
                (element) => element.id === parseInt(footerValue.key)
            );

            if (componentValue != null && componentValue.name === 'touristFooter') {
                iconRef.current.exportValue();
                settingsArray.push(iconFiles);
            }
            requestPost(CONSTANTS_REQ.SETTINGS.SAVE_SETTINGS, null, { settings: settingsArray }).then((resp) => {
                if (resp.status == RESPONSE.OK) {
                    message.success(geti18nText('settings.save.success'));
                    setTouristFooterVisible(false);
                } else {
                    message.error(geti18nText('settings.save.not'));
                }
            });
        });
    }

    const onFooterModalSave = () => {
        setFooterModalVisible(false);
        props.form.validateFieldsAndScroll((err, values) => {
            values = normalizeComponents(values);
            let sendJson = {};
            sendJson.title = values.title;
            if (values.footer_image) sendJson.footer_image = normalizeFileUpload('footer_image');
            sendJson.bottom_text = values.bottom_text;
            sendJson.facebook = values.facebook;
            sendJson.linkedin = values.linkedin;
            sendJson.instagram = values.instagram;
            sendJson.youtube = values.youtube;
            sendJson.hoverColor = values.hoverColor;

            let companyBlockArr = [];
            blockCompany.forEach((value, index) => {
                companyBlockArr.push({
                    id: value.id,
                    companyName: values['companyName' + value.id],
                    company_link: values['company_link' + value.id],
                    address: values['address' + value.id],
                    postCode: values['postCode' + value.id],
                    mail: values['mail' + value.id],
                    phones: values['phones' + value.id],
                    fax: values['fax' + value.id],
                });
            });

            let linksArr = [];
            links.forEach((value, index) => {
                linksArr.push({
                    id: value.id,
                    title: values['link_title' + value.id],
                    link: values['url_link' + value.id],
                });
            });

            sendJson.links = linksArr;
            sendJson.block_company = companyBlockArr;
            let footerSet = footerSettings;
            footerSet.settingValue = JSON.stringify(sendJson);
            if (!footerSettings.project) {
                footerSet.project = { id: Session.getProjectId() };
            }
            const settingsArray = [footerSet];
            requestPost(CONSTANTS_REQ.SETTINGS.SAVE_SETTINGS, null, { settings: settingsArray }).then((resp) => {
                if (resp.status == RESPONSE.OK) {
                    message.success(geti18nText('settings.save.success'));
                } else {
                    message.error(geti18nText('settings.save.not'));
                }
            });
        });
    };

    const onFooterListItemlSave = () => {
        props.form.validateFieldsAndScroll((err, values) => {
            values= normalizeComponents(values);
            let json={};
            json.footerItemListText=values.footerItemListText
            json.typeLinkListItem=values.typeLinkListItem
            json.link_list_item_text=values.link_list_item_text
            json.link_list_item=values.link_list_item
            if(!footerEdit) {
                json.id=Math.floor(Math.random() * (9999999999))
            }
            let list=listTypeFooter==='RIGHT'?dataListRight:dataListLeft
            if(!footerEdit) {
                list=list.concat(json)
            }
            else {
                list=list.map((item) =>{
                       return item.id === selectedItemFooterList.id ? { ...json, id: item.id } : item
                })
            }
            
            
            if(listTypeFooter==='RIGHT') {
                setDataListRight(list)
            }
            else setDataListLeft(list)

        })
        resetFields(['footerItemListText', 'typeLinkListItem', 'link_list_item_text', 'link_list_item'])
        setModalListFooterItemVisible(false);

    }
    
    const onFooterModalYlSave = () => {
        setFooterComponentYlModalVisible(false);
        props.form.validateFieldsAndScroll((err, values) => {
            values = normalizeComponents(values);
            let sendJson = {};
            sendJson.description=values.description;
            sendJson.footer_image=values.footer_image;
            sendJson.contactMail=values.contactMail;
            sendJson.contactPhone=values.contactPhone;
            sendJson.address=values.address;
            sendJson.newsletterText=values.newsletterText;
            sendJson.newsletterMailText=values.newsletterMailText;
            sendJson.newsletterAgreeText=values.newsletterAgreeText;
            sendJson.headerYlFacebook=values.headerYlFacebook;
            sendJson.headerYlInstagram=values.headerYlInstagram;
            sendJson.headerYlLinkedin=values.headerYlLinkedin;
            sendJson.headerYlYoutube=values.headerYlYoutube;
            sendJson.rightsText=values.rightsText;
            sendJson.termsText=values.termsText;
            sendJson.typeTerms=values.typeTerms;
            sendJson.link_terms=values.link_terms && values.link_terms.id?values.link_terms.id:values.link_terms;
            sendJson.link_terms_text=values.link_terms_text;
            sendJson.privacyText=values.privacyText;
            sendJson.typePrivacy=values.typePrivacy;
            sendJson.link_privacy=values.link_privacy && values.link_privacy.id?values.link_privacy.id:values.link_privacy;
            sendJson.link_privacy_text=values.link_privacy_text;
            sendJson.leftListTitle=values.leftListTitle;
            sendJson.rightListTitle=values.rightListTitle;

            if(dataListLeft.length>0) {
                sendJson.dataListLeft= JSON.stringify(dataListLeft)
            }
            if(dataListRight.length>0) {
                sendJson.dataListRight= JSON.stringify(dataListRight)
            }
           
            
            let footerSet = footerSettings;
            footerSet.settingValue = JSON.stringify(sendJson);
            if (!footerSettings.project) {
                footerSet.project = { id: Session.getProjectId() };
            }
            const settingsArray = [footerSet];
            requestPost(CONSTANTS_REQ.SETTINGS.SAVE_SETTINGS, null, { settings: settingsArray }).then((resp) => {
                if (resp.status == RESPONSE.OK) {
                    message.success(geti18nText('settings.save.success'));
                } else {
                    message.error(geti18nText('settings.save.not'));
                }
            });
        });
    };

    function showNotification(title, message, duration, type) {
        notification[type]({
            message: title,
            description: message,
            duration: duration,
        });
    }

    function onSelectComponentProject(component) {
        props.form.setFieldsValue({ navLogoPage_1: {} });
        if (component) {
            setSelectedProjectId(parseInt(component.key));
            setPageSearchDisabled(false);
        } else {
            setSelectedProjectId(0);
            if (!pageSearchDisabled) setPageSearchDisabled(true);
        }
    }

    function onSelectComponentProject2(component) {
        props.form.setFieldsValue({ navLogoPage_2: {} });
        if (component) {
            setSelectedProjectId2(parseInt(component.key));
            setPageSearchDisabled2(false);
        } else {
            setSelectedProjectId2(0);
            if (!pageSearchDisabled) setPageSearchDisabled2(true);
        }
    }

    function onSelectComponentProject3(component) {
        props.form.setFieldsValue({ navLogoPage_3: {} });
        if (component) {
            setSelectedProjectId3(parseInt(component.key));
            setPageSearchDisabled3(false);
        } else {
            setSelectedProjectId3(0);
            if (!pageSearchDisabled) setPageSearchDisabled3(true);
        }
    }
    
    const [linkTypeTerms, setLinkTypeTerms] = useState(LINK_TYPE.WEBPAGE);
    const [linkTypePrivacy, setLinkTypePrivacy] = useState(LINK_TYPE.WEBPAGE);
    const [linkTypeListItem, setLinkTypeListItem] = useState(LINK_TYPE.WEBPAGE);

    useEffect(()=>{
        if(selectedItemFooterList) {
            setFieldsValue({'footerItemListText': selectedItemFooterList.footerItemListText})
            setFieldsValue({'typeLinkListItem': selectedItemFooterList.typeLinkListItem})
            
            
            setFieldsValue({'link_top_list_text': selectedItemFooterList.link_top_list_text})

            if(selectedItemFooterList.link_list_item) {  
                setWepPageValue('link_list_item',selectedItemFooterList.link_list_item)
            }
        }
    },[selectedItemFooterList])
    
    useEffect(()=>{
        if(selectedItemTopLink) {
            let json={}
            json = translationsToObject(json, 'nameLinkTopItem', selectedItemTopLink.nameLinkTopItem);
            setFieldsValue({'typeLinkTopItem': selectedItemTopLink.typeLinkTopItem})
            
            
            setFieldsValue({'link_top_list_text': selectedItemTopLink.link_top_list_text})

            if(selectedItemTopLink.link_top_list) {  
                setWepPageValue('link_top_list',selectedItemTopLink.link_top_list)
            }
            setFieldsValue(json);

        }
    },[selectedItemTopLink])

    const DesignModal = (props) => {
        function closePopup() {
            props.setVisible(false);
        }

        return (
            <>
                <Modal
                    zIndex={1100}
                    visible={props.visible}
                    onCancel={closePopup}
                    width={1200}
                    destroyOnClose={true}
                    footer={[]}
                >
                    <DeseignEditor
                        id={props.id}
                        pageComponentId={props.pageComponentId}
                        projectId={Session.getProjectId()}
                        type={'component'}
                        closePopup={closePopup}
                        webPageComponentId={props.webPageComponentId}
                        componentName={props.name}
                    ></DeseignEditor>
                </Modal>
            </>
        );
    };

    const onSelectListItem = (item) => {
        
    };
    
    const onEditFooterItem = (item, type) => {
        setFooterEdit(true);
        setListTypeFooter(type)
        setLinkTypeListItem(item.typeLinkListItem)
        setSelectedItemFooterList(item)
        setModalListFooterItemVisible(true)
    };
    
    const onEditTopLinkItem = (item) => {
        setTopLinkEdit(true);
        setLinkTypeTopList(item.typeLinkTopItem)
        setSelectedItemTopLink(item)
        setModalListTopLinks(true)
    };

    const onDeleteConfirm=(type,id)=>{
        let list=type==='RIGHT'?dataListRight:dataListLeft
            
            list=list.filter((item) =>{
                    return item.id !== id })
               
            if(type==='RIGHT') {
                setDataListRight(list)
            }
            else setDataListLeft(list)
    }
    
    const onDeletTopLinkItem=(id)=>{
        let list=dataListTopLinks  
        list=list.filter((item) =>{
                return item.id !== id })
            
        setDataListTopLinks(list)
    }

    const changeEmails = (value) => {
        setEmails(value);
    };

    return fetching ?
    <Spinner />:
        <React.Fragment>
            <Row type="flex" className="margin-bottom">
                <Divider orientation="left" style={{ fontSize: '18px' }}>
                    {geti18nText('settings.title')}
                </Divider>
            </Row>

            <Form>
                <Collapse style={{ marginBottom: '15px' }} accordion>
                    <Panel header={geti18nText('settings.footer.label')} key="1" forceRender={true}>
                        <Form.Item style={{ width: '30%' }} label={geti18nText('settings.footer.select')}>
                            {getFieldDecorator('footer')(
                                <Row gutter={12}>
                                    <Popconfirm
                                        placement="rightTop"
                                        title={geti18nText('app.default.button.delete.footer.settings')}
                                        visible={footerChangeConfirmVisible}
                                        okText={geti18nText('app.default.button.yes')}
                                        cancelText={geti18nText('app.default.button.no')}
                                        onCancel={() => {
                                            setFooterValue(oldFooterValue);
                                            setFooterChangeConfirmVisible(false);
                                        }}
                                        onConfirm={() => {
                                            requestPost(CONSTANTS_REQ.SETTINGS.DELETE_FOOTER, null, {
                                                projectId: Session.getProjectId(),
                                            }).then((resp) => {
                                                if (resp.status == RESPONSE.OK) {
                                                    message.success(geti18nText('settings.save.success'));
                                                } else {
                                                    message.error(geti18nText('settings.save.not'));
                                                }
                                            });
                                            setFooterValue(chosenFooterValue);
                                            formFooterComponentName(chosenFormFooterComponentName);
                                            setFooterChangeConfirmVisible(false);
                                        }}
                                    >
                                        <span></span>
                                    </Popconfirm>
                                    <Col span={12}>
                                        {/* <SearchField
                                            url={CONSTANTS_REQ.COMPONENT.SEARCH}
                                            map={{ id: 'id', label: 'name' }}
                                            searchBy="name"
                                            disabled={false}
                                            translate={true}
                                            addedData={{ type: 'footer' }}
                                            onChange={footerOnChange}
                                            value={footerValue}
                                        /> */}
                                        <SearchField
                                            disabled={footerChangeConfirmVisible}
                                            value={footerValue}
                                            options={componentValues('footer')}
                                            onChange={footerOnChange}
                                        />
                                    </Col>
                                    <Col span={2}>
                                        <Button
                                            onClick={(arg) => {
                                                openFooterSettings();
                                            }}
                                            icon="setting"
                                            type="primary"
                                            disabled={
                                                (footerValue === undefined ? true : false) || footerChangeConfirmVisible
                                            }
                                        ></Button>
                                    </Col>
                                    <Col span={4}>
                                        <Button
                                            onClick={(arg) => {
                                                setDesignVisible(true);
                                                setDesignComponentName(componentFooterName);
                                                setComponentId(parseInt(footerValue.key));
                                            }}
                                            icon="bg-colors"
                                            style={{
                                                backgroundColor: footerValue === undefined ? '#f5f5f5' : 'orange',
                                            }}
                                            disabled={footerValue === undefined ? true : false}
                                        ></Button>
                                    </Col>
                                </Row>
                            )}
                        </Form.Item>
                        {/*    <TouristFooterSettings
                            cssHiddenDesign={false}
                            iconFiles={iconFiles}
                            setIconFiles={setIconFiles}
                            ref={(ref) => (iconRef.current = ref)}
                            form={props.form}
                        ></TouristFooterSettings> */}
                        <Modal
                            zIndex={1100}
                            visible={touristFooterVisible}
                            onCancel={closePopupFooterSetting}
                            width={1200}
                            destroyOnClose={true}
                            title={geti18nText('settings.footer')}
                            footer={[
                                <Button key="back" onClick={(e) => onTouristFooterSettingsModalCancel()}>
                                    {geti18nText('app.default.button.cancel')}
                                </Button>,
                                <Button key="save" type="primary" onClick={(arg) => onFooterTouristModalSave()}>
                                    {geti18nText('app.default.button.save')}
                                </Button>,
                            ]}
                        >
                            <TouristFooterSettings
                                cssHiddenDesign={true}
                                iconFiles={iconFiles}
                                setIconFiles={setIconFiles}
                                ref={(ref) => (iconRef.current = ref)}
                                form={props.form}
                            />
                        </Modal>
                    </Panel>
                </Collapse>
                <Collapse style={{ marginBottom: '15px' }} accordion>
                    <Panel header={geti18nText('settings.menu.label')} key="2" forceRender={true}>
                        <Form.Item style={{ width: '30%' }} label={geti18nText('settings.menu.select')}>
                            {getFieldDecorator('navbar')(
                                <Row gutter={12}>
                                    <Col span={12}>
                                        <SearchField
                                            value={navbarValue}
                                            options={componentValues('navbar')}
                                            onChange={onChangeMenu}
                                        />
                                    </Col>
                                    <Col span={4}>
                                        <Button
                                            onClick={(arg) => {
                                                setDesignVisible(true);
                                                setDesignComponentName(componentNavbarName);
                                                setComponentId(parseInt(navbarValue.key));
                                            }}
                                            icon="bg-colors"
                                            style={{
                                                backgroundColor: navbarValue === undefined ? '#f5f5f5' : 'orange',
                                            }}
                                            disabled={navbarValue === undefined ? true : false}
                                        ></Button>
                                    </Col>
                                </Row>
                            )}
                        </Form.Item>
                        {showLogoPanel && <Divider>{geti18nText('settings.nav.logo')}</Divider>}
                        <Row gutter={16}>
                            {showLogoPanel && (
                                <Col span={24}>
                                    <Tabs defaultActiveKey="1">
                                        <TabPane tab={geti18nText('settings.nav.logo') + ' 1'} key="1">
                                            <Row gutter={16}>
                                                <Col span={8}>
                                                    <Form.Item label={geti18nText('settings.nav.logo')}>
                                                        {getFieldDecorator('navLogo_1')(
                                                            <FileUpload
                                                                fileType={CONSTANTS_FILE_TYPE.IMAGE}
                                                                map={{ id: 'id', label: 'name' }}
                                                                searchBy="name"
                                                                disabled={false}
                                                                addedData={{
                                                                    projectId: Session.getProjectId(),
                                                                    fileType: CONSTANTS_FILE_TYPE.IMAGE,
                                                                }}
                                                            />
                                                        )}
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Row gutter={16}>
                                                <Col span={8}>
                                                    <Form.Item label={geti18nText('webpage.project')}>
                                                        {getFieldDecorator('navLogoProject_1', {
                                                            initialValue: initNavLogoProject,
                                                            rules: [
                                                                {
                                                                    required: true,
                                                                    message: geti18nText('app.default.required'),
                                                                },
                                                            ],
                                                        })(
                                                            <SearchField
                                                                onChange={onSelectComponentProject}
                                                                url={CONSTANTS_REQ.PROJECT.SEARCH}
                                                                map={{ id: 'id', label: 'text' }}
                                                                searchBy="name"
                                                                translate={false}
                                                                allowClear={false}
                                                            />
                                                        )}
                                                    </Form.Item>
                                                </Col>
                                                <Col span={8}>
                                                    <Form.Item label={geti18nText('webpage.page')}>
                                                        {getFieldDecorator('navLogoPage_1')(
                                                            <SearchField
                                                                url={CONSTANTS_REQ.WEBPAGE.SEARCH}
                                                                map={{ id: 'id', label: 'name' }}
                                                                searchBy="name"
                                                                disabled={pageSearchDisabled}
                                                                translate={true}
                                                                addedData={{
                                                                    projectId: selectedProjectId,
                                                                    forPage: true,
                                                                }}
                                                            />
                                                        )}
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </TabPane>
                                        <TabPane tab={geti18nText('settings.nav.logo') + ' 2'} key="2">
                                            <Row gutter={16}>
                                                <Col span={8}>
                                                    <Form.Item label={geti18nText('settings.nav.logo')}>
                                                        {getFieldDecorator('navLogo_2')(
                                                            <FileUpload
                                                                fileType={CONSTANTS_FILE_TYPE.IMAGE}
                                                                map={{ id: 'id', label: 'name' }}
                                                                searchBy="name"
                                                                disabled={false}
                                                                addedData={{
                                                                    projectId: Session.getProjectId(),
                                                                    fileType: CONSTANTS_FILE_TYPE.IMAGE,
                                                                }}
                                                            />
                                                        )}
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Row gutter={16}>
                                                <Col span={8}>
                                                    <Form.Item label={geti18nText('webpage.project')}>
                                                        {getFieldDecorator('navLogoProject_2', {
                                                            initialValue: initNavLogoProject,
                                                            rules: [
                                                                {
                                                                    required: true,
                                                                    message: geti18nText('app.default.required'),
                                                                },
                                                            ],
                                                        })(
                                                            <SearchField
                                                                onChange={onSelectComponentProject2}
                                                                url={CONSTANTS_REQ.PROJECT.SEARCH}
                                                                map={{ id: 'id', label: 'text' }}
                                                                searchBy="name"
                                                                translate={false}
                                                                allowClear={false}
                                                            />
                                                        )}
                                                    </Form.Item>
                                                </Col>
                                                <Col span={8}>
                                                    <Form.Item label={geti18nText('webpage.page')}>
                                                        {getFieldDecorator('navLogoPage_2')(
                                                            <SearchField
                                                                url={CONSTANTS_REQ.WEBPAGE.SEARCH}
                                                                map={{ id: 'id', label: 'name' }}
                                                                searchBy="name"
                                                                //onChange={onSelectPage}
                                                                disabled={pageSearchDisabled2}
                                                                translate={true}
                                                                addedData={{
                                                                    projectId: selectedProjectId2,
                                                                    forPage: true,
                                                                }}
                                                            />
                                                        )}
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </TabPane>
                                        <TabPane tab={geti18nText('settings.nav.logo') + ' 3'} key="3">
                                            <Row gutter={16}>
                                                <Col span={8}>
                                                    <Form.Item label={geti18nText('settings.nav.logo')}>
                                                        {getFieldDecorator('navLogo_3')(
                                                            <FileUpload
                                                                fileType={CONSTANTS_FILE_TYPE.IMAGE}
                                                                map={{ id: 'id', label: 'name' }}
                                                                searchBy="name"
                                                                disabled={false}
                                                                addedData={{
                                                                    projectId: Session.getProjectId(),
                                                                    fileType: CONSTANTS_FILE_TYPE.IMAGE,
                                                                }}
                                                            />
                                                        )}
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Row gutter={16}>
                                                <Col span={8}>
                                                    <Form.Item label={geti18nText('webpage.project')}>
                                                        {getFieldDecorator('navLogoProject_3', {
                                                            initialValue: initNavLogoProject,
                                                            rules: [
                                                                {
                                                                    required: true,
                                                                    message: geti18nText('app.default.required'),
                                                                },
                                                            ],
                                                        })(
                                                            <SearchField
                                                                onChange={onSelectComponentProject3}
                                                                url={CONSTANTS_REQ.PROJECT.SEARCH}
                                                                map={{ id: 'id', label: 'text' }}
                                                                searchBy="name"
                                                                translate={false}
                                                                allowClear={false}
                                                            />
                                                        )}
                                                    </Form.Item>
                                                </Col>
                                                <Col span={8}>
                                                    <Form.Item label={geti18nText('webpage.page')}>
                                                        {getFieldDecorator('navLogoPage_3')(
                                                            <SearchField
                                                                url={CONSTANTS_REQ.WEBPAGE.SEARCH}
                                                                map={{ id: 'id', label: 'name' }}
                                                                searchBy="name"
                                                                //onChange={onSelectPage}
                                                                disabled={pageSearchDisabled3}
                                                                translate={true}
                                                                addedData={{
                                                                    projectId: selectedProjectId3,
                                                                    forPage: true,
                                                                }}
                                                            />
                                                        )}
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </TabPane>
                                    </Tabs>
                                </Col>
                            )}
                            {!showLogoPanel && (
                                <Form.Item style={{ width: '30%' }} label={geti18nText('settings.nav.logo')}>
                                    {getFieldDecorator('navLogo')(
                                        <FileUpload
                                            fileType={CONSTANTS_FILE_TYPE.IMAGE}
                                            map={{ id: 'id', label: 'name' }}
                                            searchBy="name"
                                            disabled={false}
                                            addedData={{
                                                projectId: Session.getProjectId(),
                                                fileType: CONSTANTS_FILE_TYPE.IMAGE,
                                            }}
                                        />
                                    )}
                                </Form.Item>
                            )}
                        </Row>
                        {componentNavbarName ==='headerMenuSubLinks' && <Row gutter={16}>
                            <Col span={12}>
                                
                                <InputByLanguage
                                    notRequred
                                    fieldName={'headerMenuSubLinksBtnText'}
                                    label={geti18nText('component.headerMenuSubLinks.textBtnDescription')}
                                    
                                    {...props}
                                />
                            </Col>
                            <Col span={24}>
                                <Divider>{geti18nText('component.headerMenuSubLinks.topLinks.text')}</Divider>
                                <Button key="save" type="primary" style={{marginBottom:10}} onClick={(arg) => {setModalListTopLinks(true);}}>
                                        {geti18nText('component.ylFooter.list.add.btn')}
                                </Button>
                                <List
                                    itemLayout="horizontal"
                                    dataSource={dataListTopLinks}
                                    bordered
                                   // header={<div>{geti18nText('component.ylFooter.list.header')}</div>}
                                    renderItem={(item) => {
                                        return <List.Item
                                        actions={[
                                            <Button
                                                
                                                style={{ marginRight: '15px' }}
                                                key="ok"
                                                type="default"
                                                onClick={(e) => onEditTopLinkItem(item)}
                                            >
                                                {geti18nText('component.ylFooter.list.item.edit.btn')}
                                            </Button>,
                                            <Popconfirm placement="top" title={geti18nText('component.calendar.delete.confirm')} onConfirm={()=>onDeletTopLinkItem(item.id)} okText={geti18nText('app.default.button.yes')} cancelText={geti18nText('app.default.button.no')}>
                                                <Button
                                                    
                                                    style={{ marginRight: '15px' }}
                                                    key="ok"
                                                    type="danger"
                                                    
                                                >
                                                    {geti18nText('component.ylFooter.list.item.delete.btn')}
                                                </Button>
                                        </Popconfirm>
                                        ]}
                                            onClick={(e) => onSelectListItem(item)}
                                        >
                                            <div>{getTranslated(item.nameLinkTopItem, getSelectedClientLanguage() )}</div>
                                        </List.Item>
                                    }}
                                />
                            </Col>  
                        </Row>}
                        {componentNavbarName ==='headerMenuYl' && <Row gutter={16}>
                            <Col span={16}>
                                <Form.Item label={geti18nText('headerMenuYl.address')}>
                                    {getFieldDecorator('address')(<Input></Input>)}
                                </Form.Item>
                                <Form.Item label={geti18nText('headerMenuYl.phone')}>
                                    {getFieldDecorator('phone')(<Input></Input>)}
                                </Form.Item>
                                <Form.Item label={geti18nText('headerMenuYl.mobile')}>
                                    {getFieldDecorator('mobile')(<Input></Input>)}
                                </Form.Item>
                                <Form.Item label={geti18nText('headerMenuYl.email')}>
                                    {getFieldDecorator('mail')(<Input></Input>)}
                                </Form.Item>
                                <Form.Item label={geti18nText('headerMenuYl.address.side')}>
                                    {getFieldDecorator('addressSide')(<Input></Input>)}
                                </Form.Item>
                                <Form.Item label={geti18nText('headerMenuYl.phone.side')}>
                                    {getFieldDecorator('phoneSide')(<Input></Input>)}
                                </Form.Item>
                                <Form.Item label={geti18nText('headerMenuYl.email.side')}>
                                    {getFieldDecorator('mailSide')(<Input></Input>)}
                                </Form.Item>
                                <InputByLanguage  
                                    fieldName="sideText"
                                    label={geti18nText('headerMenuYl.sideText')}
                                    
                                    type="rteEditor"
                                    {...props} 
                                    />
                                <Form.Item label={geti18nText('headerMenuYl.rightTextDetails')}>
                                    {getFieldDecorator('rightTextDetails')(<TextArea />)}
                                </Form.Item>
                                <Form.Item label={geti18nText('headerMenuYl.headerYlFacebook')}>
                                    {getFieldDecorator('headerYlFacebook')(<Input></Input>)}
                                </Form.Item>
                                <Form.Item label={geti18nText('headerMenuYl.headerYlInstagram')}>
                                    {getFieldDecorator('headerYlInstagram')(<Input></Input>)}
                                </Form.Item>
                                <Form.Item label={geti18nText('headerMenuYl.headerYlLinkedin')}>
                                    {getFieldDecorator('headerYlLinkedin')(<Input></Input>)}
                                </Form.Item>
                                <Form.Item label={geti18nText('headerMenuYl.headerYlYoutube')}>
                                    {getFieldDecorator('headerYlYoutube')(<Input></Input>)}
                                </Form.Item>
                            </Col>
                        </Row>}

                        <Row gutter={16}>
                            <Col span={8}>
                                <Form.Item>
                                    {getFieldDecorator('searchNav', {
                                        valuePropName: 'checked',
                                        initialValue: false,
                                    })(<Checkbox>{geti18nText('settings.nav.search')}</Checkbox>)}
                                </Form.Item>
                                <Form.Item>
                                    {getFieldDecorator('langFlag', {
                                        valuePropName: 'checked',
                                        initialValue: false,
                                    })(<Checkbox>{geti18nText('settings.menu.lang')}</Checkbox>)}
                                </Form.Item>
                            </Col>
                        </Row>

                        {/*   <Divider>{geti18nText('settings.deseign')}</Divider>
                        <Button
                            onClick={(arg) => {
                                setDesignVisible(true);
                                setDesignComponentName(designComponentName);
                                setComponentId(navbarValue.key);
                            }}
                            style={{ backgroundColor: 'orange' }}
                            icon="bg-colors"
                        ></Button> */}
                    </Panel>
                </Collapse>
                <DesignModal
                    pageComponentId={componentId}
                    visible={designVisible}
                    setVisible={setDesignVisible}
                    name={designComponentName}
                ></DesignModal>
                <Modal
                    visible={modalListTopLinks}
                    onCancel={onModalTopListCancel}
                    destroyOnClose={true}
                    forceRender
                    width={900}
                    title={geti18nText('component.ylFooter.list.add.item.title')}
                    footer={[
                        <Button key="back" onClick={(e) => onModalTopListCancel()}>
                            {geti18nText('app.default.button.cancel')}
                        </Button>,
                        <Button key="save" type="primary" onClick={(arg) => onTopListItemSave()}>
                            {geti18nText('app.default.button.save')}
                        </Button>,
                    ]}
                >
                    <Row gutter={24}>
                            <InputByLanguage
                                notRequred
                                fieldName={'nameLinkTopItem'}
                                label={geti18nText('component.headerMenuSubLinks.topLinks.name')}
                                {...props}
                            />
                            
                            <Form.Item>
                                {getFieldDecorator('typeLinkTopItem', { initialValue: LINK_TYPE.WEBPAGE })(
                                    <Radio.Group
                                        onChange={(e) => {
                                            setLinkTypeTopList(e.target.value);
                                        }}
                                    >
                                        <Radio value={LINK_TYPE.WEBPAGE}>
                                            {geti18nText('component.footercomponent.edit.radio_button.webPage')}
                                        </Radio>
                                        <Radio value={LINK_TYPE.LINK}>
                                            {geti18nText('component.footercomponent.edit.external_link')}
                                        </Radio>
                                    </Radio.Group>
                                )}
                            </Form.Item>
                    

                            {linkTypeTopList == LINK_TYPE.LINK ? (
                                <Row gutter={24} type="flex" justify="space-between" align="bottom">
                                    <Col span={16}>
                                        <Form.Item label={geti18nText('component.footercomponent.edit.text_link')}>
                                            {getFieldDecorator('link_top_list_text')(
                                                <Input defaultValue={props.externalLink} />
                                            )}
                                        </Form.Item>
                                    </Col>
                                </Row>
                            ) : (
                                <Form.Item label={geti18nText('component.footercomponent.edit.radio_button.webPage')}>
                                    {getFieldDecorator('link_top_list')(
                                        <SearchField
                                            url={CONSTANTS_REQ.WEBPAGE.SEARCH}
                                            map={{ id: 'id', label: 'name' }}
                                            searchBy="name"
                                            disabled={false}
                                            translate={true}
                                            addedData={{ projectId: Session.getProjectId(), forMap: true }}
                                        />
                                    )}
                                </Form.Item>
                            )}

                    </Row>


            </Modal>
                <Modal
                    visible={footerComponentModalVisible}
                    onCancel={onFooterComponentModalCancel}
                    // onSave={onFooterModalSave}
                    destroyOnClose={true}
                    width={900}
                    footer={[]}
                    // title={geti18nText('settings.footer')}
                >
                    <FooterComponentEdit closePopup={onFooterComponentModalCancel}></FooterComponentEdit>
                </Modal>
                <Modal
                    visible={footerComponentYlModalVisible}
                    onCancel={onFooterComponentModalYlCancel}
                    // onSave={onFooterModalSave}
                    destroyOnClose={true}
                    width={900}
                    forceRender
                    footer={[[
                        <Button key="back" onClick={(e) => onFooterComponentModalYlCancel()}>
                            {geti18nText('app.default.button.cancel')}
                        </Button>,
                        <Button key="save" type="primary" onClick={(arg) => onFooterModalYlSave()}>
                            {geti18nText('app.default.button.save')}
                        </Button>
                    ]]}
                    // title={geti18nText('settings.footer')}
                >
                    <Row gutter={24}>
                        <Row>
                            <Col span={24}>
                                <Form.Item label={geti18nText('settings.footer.edit.image')}>
                                    {getFieldDecorator(
                                        'footer_image',
                                        {}
                                    )(
                                        <FileUpload
                                            fileType={CONSTANTS_FILE_TYPE.IMAGE}
                                            map={{ id: 'id', label: 'name' }}
                                            searchBy="name"
                                            disabled={false}
                                            addedData={{
                                                projectId: Session.getProjectId(),
                                                fileType: CONSTANTS_FILE_TYPE.IMAGE,
                                            }}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={12}>
                                <Form.Item label={geti18nText('component.ylFooter.title')}>
                                    {getFieldDecorator('description')(<TextArea />)}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label={geti18nText('component.ylFooter.address')}>
                                    {getFieldDecorator('address', )(<TextArea />)}
                                </Form.Item>
                            </Col>
                            
                        </Row>
                        <Row gutter={24}>
                            <Col span={12}>
                                <Form.Item label={geti18nText('component.ylFooter.contact.mail')}>
                                    {getFieldDecorator('contactMail', )(<TextArea />)}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label={geti18nText('component.ylFooter.contact.phone')}>
                                    {getFieldDecorator('contactPhone', )(<TextArea />)}
                                </Form.Item>
                            </Col>
                        </Row>
                        
                       
                        <Row gutter={24}>
                            <Col span={12}>
                                <Form.Item label={geti18nText('component.ylFooter.newsletter.text')}>
                                    {getFieldDecorator('newsletterText', )(<TextArea />)}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label={geti18nText('component.ylFooter.newsletter.mail.enter.text')}>
                                    {getFieldDecorator('newsletterMailText', )(<TextArea />)}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={12}>
                                <Form.Item label={geti18nText('component.ylFooter.newsletter.agree.text')}>
                                    {getFieldDecorator('newsletterAgreeText', )(<TextArea />)}
                                </Form.Item>
                            </Col>
                            
                        </Row>
                        <Row gutter={24}>
                            <Col span={12}>
                                <Form.Item label={geti18nText('headerMenuYl.headerYlFacebook')}>
                                    {getFieldDecorator('headerYlFacebook')(<Input></Input>)}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label={geti18nText('headerMenuYl.headerYlInstagram')}>
                                    {getFieldDecorator('headerYlInstagram')(<Input></Input>)}
                                </Form.Item>
                            </Col>  
                            

                        </Row>
                        <Row gutter={24}>
                            <Col span={12}>  
                                <Form.Item label={geti18nText('headerMenuYl.headerYlLinkedin')}>
                                    {getFieldDecorator('headerYlLinkedin')(<Input></Input>)}
                                </Form.Item>
                            </Col>
                            <Col span={12}>  
                                <Form.Item label={geti18nText('headerMenuYl.headerYlYoutube')}>
                                    {getFieldDecorator('headerYlYoutube')(<Input></Input>)}
                                </Form.Item>
                            </Col>    
                        </Row>
                        <Row gutter={24}>
                            <Col span={12}>  
                                <Form.Item label={geti18nText('component.ylFooter.newsletter.rights.text')}>
                                    {getFieldDecorator('rightsText')(<TextArea />)}
                                </Form.Item>
                            </Col>
                            
                        </Row>
                        
                        <Col span={24}>
                            <Divider>{geti18nText('component.ylFooter.terms.title')}</Divider>
                            <Row>
                                <Col span={12}>  
                                        <Form.Item label={geti18nText('component.ylFooter.newsletter.terms.text')}>
                                            {getFieldDecorator('termsText')(<TextArea />)}
                                        </Form.Item>
                                    </Col>    
                            </Row>
                            <Form.Item>
                                {getFieldDecorator('typeTerms', { initialValue: LINK_TYPE.WEBPAGE })(
                                    <Radio.Group
                                        onChange={(e) => {
                                            setLinkTypeTerms(e.target.value);
                                        }}
                                    >
                                        <Radio value={LINK_TYPE.WEBPAGE}>
                                            {geti18nText('component.footercomponent.edit.radio_button.webPage')}
                                        </Radio>
                                        <Radio value={LINK_TYPE.LINK}>
                                            {geti18nText('component.footercomponent.edit.external_link')}
                                        </Radio>
                                    </Radio.Group>
                                )}
                            </Form.Item>
                    

                            {linkTypeTerms == LINK_TYPE.LINK ? (
                                <Row gutter={24} type="flex" justify="space-between" align="bottom">
                                    <Col span={16}>
                                        <Form.Item label={geti18nText('component.footercomponent.edit.text_link')}>
                                            {getFieldDecorator('link_terms_text')(
                                                <Input defaultValue={props.externalLink} />
                                            )}
                                        </Form.Item>
                                    </Col>
                                </Row>
                            ) : (
                                <Form.Item label={geti18nText('component.footercomponent.edit.radio_button.webPage')}>
                                    {getFieldDecorator('link_terms')(
                                        <SearchField
                                            url={CONSTANTS_REQ.WEBPAGE.SEARCH}
                                            map={{ id: 'id', label: 'name' }}
                                            searchBy="name"
                                            disabled={false}
                                            translate={true}
                                            addedData={{ projectId: Session.getProjectId(), forMap: true }}
                                        />
                                    )}
                                </Form.Item>
                            )}

                   
                        </Col>
                        <Col span={24}>
                            <Divider>{geti18nText('component.ylFooter.privacy.title')}</Divider>
                            <Row>
                                <Col span={12}>  
                                    <Form.Item label={geti18nText('component.ylFooter.newsletter.privacy.text')}>
                                        {getFieldDecorator('privacyText')(<TextArea />)}
                                    </Form.Item>
                                </Col>  
                            </Row>
                            <Form.Item>
                                {getFieldDecorator('typePrivacy', { initialValue: LINK_TYPE.WEBPAGE })(
                                    <Radio.Group
                                        onChange={(e) => {
                                            setLinkTypePrivacy(e.target.value);
                                        }}
                                    >
                                        <Radio value={LINK_TYPE.WEBPAGE}>
                                            {geti18nText('component.footercomponent.edit.radio_button.webPage')}
                                        </Radio>
                                        <Radio value={LINK_TYPE.LINK}>
                                            {geti18nText('component.footercomponent.edit.external_link')}
                                        </Radio>
                                    </Radio.Group>
                                )}
                            </Form.Item>
                    

                            {linkTypePrivacy == LINK_TYPE.LINK ? (
                                <Row gutter={24} type="flex" justify="space-between" align="bottom">
                                    <Col span={16}>
                                        <Form.Item label={geti18nText('component.footercomponent.edit.text_link')}>
                                            {getFieldDecorator('link_privacy_text')(
                                                <Input defaultValue={props.externalLink} />
                                            )}
                                        </Form.Item>
                                    </Col>
                                </Row>
                            ) : (
                                <Form.Item label={geti18nText('component.footercomponent.edit.radio_button.webPage')}>
                                    {getFieldDecorator('link_privacy')(
                                        <SearchField
                                            url={CONSTANTS_REQ.WEBPAGE.SEARCH}
                                            map={{ id: 'id', label: 'name' }}
                                            searchBy="name"
                                            disabled={false}
                                            translate={true}
                                            addedData={{ projectId: Session.getProjectId(), forMap: true }}
                                        />
                                    )}
                                </Form.Item>
                            )}

                   
                        </Col>
                        <Col span={24}>
                            <Divider>{geti18nText('component.ylFooter.list.left.title')}</Divider>
                            <Row>
                                <Col span={12}>  
                                    <Form.Item label={geti18nText('component.ylFooter.list.left.text')}>
                                        {getFieldDecorator('leftListTitle')(<TextArea />)}
                                    </Form.Item>
                                </Col>  
                            </Row>
                            <Button key="save" type="primary" style={{marginBottom:10}} onClick={(arg) => {setModalListFooterItemVisible(true); setListTypeFooter('LEFT')}}>
                                    {geti18nText('component.ylFooter.list.add.btn')}
                             </Button>
                            <List
                                itemLayout="horizontal"
                                dataSource={dataListLeft}
                                header={<div>{geti18nText('component.ylFooter.list.header')}</div>}
                                renderItem={(item) => (
                                    <List.Item
                                        actions={[
                                            <Button
                                                
                                                style={{ marginRight: '15px' }}
                                                key="ok"
                                                type="default"
                                                onClick={(e) => onEditFooterItem(item,'LEFT')}
                                            >
                                                {geti18nText('component.ylFooter.list.item.edit.btn')}
                                            </Button>,
                                            <Popconfirm placement="top" title={geti18nText('component.calendar.delete.confirm')} onConfirm={()=>onDeleteConfirm('LEFT', item.id)} okText={geti18nText('app.default.button.yes')} cancelText={geti18nText('app.default.button.no')}>
                                                <Button
                                                    
                                                    style={{ marginRight: '15px' }}
                                                    key="ok"
                                                    type="danger"
                                                    
                                                >
                                                    {geti18nText('component.ylFooter.list.item.delete.btn')}
                                                </Button>
                                          </Popconfirm>
                                        ]}
                                        
                                        onClick={(e) => onSelectListItem(item)}
                                    >
                                        <div  style={{width:'80%'}}>{item.footerItemListText}</div>
                                    </List.Item>
                                )}
                            />
                        </Col>   
                        
                        <Col span={24}>
                            <Divider>{geti18nText('component.ylFooter.list.right.title')}</Divider>
                            <Row>
                                <Col span={12}>  
                                    <Form.Item label={geti18nText('component.ylFooter.list.right.text')}>
                                        {getFieldDecorator('rightListTitle')(<TextArea />)}
                                    </Form.Item>
                                </Col>  
                            </Row>
                            <Button style={{marginBottom:10}} key="save" type="primary" onClick={(arg) => {setModalListFooterItemVisible(true); setListTypeFooter('RIGHT')}}>
                                    {geti18nText('component.ylFooter.list.add.btn')}
                             </Button>
                            <List
                                itemLayout="horizontal"
                                header={<div>{geti18nText('component.ylFooter.list.header')}</div>}
                                dataSource={dataListRight}
                                renderItem={(item) => (
                                    <List.Item
                                    actions={[
                                        <Button
                                                
                                                style={{ marginRight: '15px' }}
                                                key="ok"
                                                type="default"
                                                onClick={(e) => onEditFooterItem(item,'RIGHT')}
                                            >
                                                {geti18nText('component.ylFooter.list.item.edit.btn')}
                                            </Button>,
                                            <Popconfirm placement="top" title={geti18nText('component.calendar.delete.confirm')} onConfirm={()=>onDeleteConfirm('RIGHT', item.id)} okText={geti18nText('app.default.button.yes')} cancelText={geti18nText('app.default.button.no')}>
                                            <Button
                                                
                                                style={{ marginRight: '15px' }}
                                                key="ok"
                                                type="danger"
                                                
                                            >
                                                {geti18nText('component.ylFooter.list.item.delete.btn')}
                                            </Button>
                                          </Popconfirm>
                                               
                                    ]}
                                       
                                    >
                                        <div style={{width:'80%'}}>{item.footerItemListText}</div>
                                    </List.Item>
                                )}
                            />
                        </Col> 

                    </Row>
                </Modal>
                <Modal
                    visible={modalListFooterItemVisible}
                    onCancel={onFooterModalListItemCancel}
                    
                    width={900}
                    title={geti18nText('component.ylFooter.list.add.item.title')}
                    footer={[
                        <Button key="back" onClick={(e) => onFooterModalListItemCancel()}>
                            {geti18nText('app.default.button.cancel')}
                        </Button>,
                        <Button key="save" type="primary" onClick={(arg) => onFooterListItemlSave()}>
                            {geti18nText('app.default.button.save')}
                        </Button>,
                    ]}
                >
                    <Row gutter={24}>
                            <Row>
                                <Col span={12}>  
                                    <Form.Item label={geti18nText('component.ylFooter.list.add.item.text')}>
                                        {getFieldDecorator('footerItemListText')(<TextArea />)}
                                    </Form.Item>
                                </Col>  
                            </Row>
                            <Form.Item>
                                {getFieldDecorator('typeLinkListItem', { initialValue: LINK_TYPE.WEBPAGE })(
                                    <Radio.Group
                                        onChange={(e) => {
                                            setLinkTypeListItem(e.target.value);
                                        }}
                                    >
                                        <Radio value={LINK_TYPE.WEBPAGE}>
                                            {geti18nText('component.footercomponent.edit.radio_button.webPage')}
                                        </Radio>
                                        <Radio value={LINK_TYPE.LINK}>
                                            {geti18nText('component.footercomponent.edit.external_link')}
                                        </Radio>
                                    </Radio.Group>
                                )}
                            </Form.Item>
                    

                            {linkTypeListItem == LINK_TYPE.LINK ? (
                                <Row gutter={24} type="flex" justify="space-between" align="bottom">
                                    <Col span={16}>
                                        <Form.Item label={geti18nText('component.footercomponent.edit.text_link')}>
                                            {getFieldDecorator('link_list_item_text')(
                                                <Input  />
                                            )}
                                        </Form.Item>
                                    </Col>
                                </Row>
                            ) : (
                                <Form.Item label={geti18nText('component.footercomponent.edit.radio_button.webPage')}>
                                    {getFieldDecorator('link_list_item')(
                                        <SearchField
                                            url={CONSTANTS_REQ.WEBPAGE.SEARCH}
                                            map={{ id: 'id', label: 'name' }}
                                            searchBy="name"
                                            disabled={false}
                                            translate={true}
                                            addedData={{ projectId: Session.getProjectId(), forMap: true }}
                                        />
                                    )}
                                </Form.Item>
                            )}

                    </Row>


                </Modal>

                <Modal
                    visible={footerModalVisible}
                    onCancel={onFooterModalCancel}
                    onSave={onFooterModalSave}
                    destroyOnClose={true}
                    width={900}
                    title={geti18nText('settings.footer')}
                    footer={[
                        <Button key="back" onClick={(e) => onFooterModalCancel()}>
                            {geti18nText('app.default.button.cancel')}
                        </Button>,
                        <Button key="save" type="primary" onClick={(arg) => onFooterModalSave()}>
                            {geti18nText('app.default.button.save')}
                        </Button>,
                    ]}
                >
                    <Row gutter={24}>
                        <Row gutter={24}>
                            <Col span={12}>
                                <Form.Item label={geti18nText('settings.footer.edit.title')}>
                                    {getFieldDecorator('title', {
                                        rules: [{ required: false, message: geti18nText('app.default.required') }],
                                    })(<TextArea />)}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label={geti18nText('settings.footer.edit.bottom.text')}>
                                    {getFieldDecorator('bottom_text', {
                                        rules: [{ required: false, message: geti18nText('app.default.required') }],
                                    })(<TextArea />)}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Form.Item label={geti18nText('settings.footer.edit.image')}>
                                    {getFieldDecorator(
                                        'footer_image',
                                        {}
                                    )(
                                        <FileUpload
                                            fileType={CONSTANTS_FILE_TYPE.IMAGE}
                                            map={{ id: 'id', label: 'name' }}
                                            searchBy="name"
                                            disabled={false}
                                            addedData={{
                                                projectId: Session.getProjectId(),
                                                fileType: CONSTANTS_FILE_TYPE.IMAGE,
                                            }}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Collapse bordered={false} defaultActiveKey={['1', '2']}>
                            <Panel header={geti18nText('settings.footer.edit.contact')} key="1">
                                <Row>
                                    <Col span={24}>
                                        <Popconfirm
                                            placement="rightTop"
                                            title={geti18nText('app.default.button.delete')}
                                            visible={deleteBlockCompanyVisible}
                                            okText={geti18nText('app.default.button.yes')}
                                            cancelText={geti18nText('app.default.button.no')}
                                            onCancel={() => {
                                                setDeleteBlockCompanyVisible(false);
                                            }}
                                            onConfirm={deleteBlockCompany}
                                        >
                                            <span></span>
                                        </Popconfirm>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24}>
                                        <Tabs
                                            type="editable-card"
                                            onEdit={onEdit}
                                            onChange={tabOnChange}
                                            activeKey={activeTab}
                                        >
                                            {blockCompany &&
                                                blockCompany.map((val, index) => (
                                                    <TabPane
                                                        tab={
                                                            geti18nText('settings.footer.edit.contact') +
                                                            (' ' + (index + 1))
                                                        }
                                                        key={val.id}
                                                        forceRender={true}
                                                    >
                                                        <Row>
                                                            <Row gutter={24}>
                                                                <Col span={12}>
                                                                    <Form.Item
                                                                        label={geti18nText(
                                                                            'settings.footer.edit.company.name'
                                                                        )}
                                                                    >
                                                                        {getFieldDecorator('companyName' + val.id)(
                                                                            <TextArea />
                                                                        )}
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col span={12}>
                                                                    <Form.Item
                                                                        label={geti18nText(
                                                                            'settings.footer.edit.title.link'
                                                                        )}
                                                                    >
                                                                        {getFieldDecorator('company_link' + val.id)(
                                                                            <TextArea />
                                                                        )}
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>
                                                            <Row gutter={24}>
                                                                <Col span={12}>
                                                                    <Form.Item
                                                                        label={geti18nText(
                                                                            'settings.footer.edit.title.address'
                                                                        )}
                                                                    >
                                                                        {getFieldDecorator('address' + val.id)(
                                                                            <TextArea />
                                                                        )}
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col span={12}>
                                                                    <Form.Item
                                                                        label={geti18nText(
                                                                            'settings.footer.edit.title.postcode'
                                                                        )}
                                                                    >
                                                                        {getFieldDecorator('postCode' + val.id)(
                                                                            <TextArea />
                                                                        )}
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>
                                                            <Row gutter={24}>
                                                                <Col span={12}>
                                                                    <Form.Item
                                                                        label={geti18nText(
                                                                            'settings.footer.edit.title.phones'
                                                                        )}
                                                                    >
                                                                        {getFieldDecorator('phones' + val.id)(
                                                                            <TextArea />
                                                                        )}
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col span={12}>
                                                                    <Form.Item
                                                                        label={geti18nText(
                                                                            'settings.footer.edit.title.fax'
                                                                        )}
                                                                    >
                                                                        {getFieldDecorator('fax' + val.id)(
                                                                            <TextArea />
                                                                        )}
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>
                                                            <Row gutter={24}>
                                                                <Col span={12}>
                                                                    <Form.Item
                                                                        label={geti18nText(
                                                                            'settings.footer.edit.title.mail'
                                                                        )}
                                                                    >
                                                                        {getFieldDecorator('mail' + val.id)(<Input />)}
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>
                                                        </Row>
                                                    </TabPane>
                                                ))}
                                        </Tabs>
                                    </Col>
                                </Row>
                            </Panel>
                            <Panel header={geti18nText('settings.footer.edit.links')} key="2">
                                <Row>
                                    <Col span={24}>
                                        <Popconfirm
                                            placement="rightTop"
                                            title={geti18nText('app.default.button.delete')}
                                            visible={deleteLinksVisible}
                                            okText={geti18nText('app.default.button.yes')}
                                            cancelText={geti18nText('app.default.button.no')}
                                            onCancel={() => {
                                                setDeleteLinksVisible(false);
                                            }}
                                            onConfirm={deleteLinks}
                                        >
                                            <span></span>
                                        </Popconfirm>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24}>
                                        <Tabs
                                            type="editable-card"
                                            onEdit={onEditLinks}
                                            onChange={tabOnChangeLinks}
                                            activeKey={activeTabLinks}
                                        >
                                            {links &&
                                                links.map((val, index) => (
                                                    <TabPane
                                                        tab={
                                                            geti18nText('settings.footer.edit.title.link') +
                                                            (' ' + (index + 1))
                                                        }
                                                        key={val.id}
                                                        forceRender={true}
                                                    >
                                                        <Row gutter={24}>
                                                            <Col span={12}>
                                                                <InputByLanguage
                                                                    notRequred
                                                                    fieldName={'link_title' + val.id}
                                                                    label={geti18nText(
                                                                        'settings.footer.edit.links.title'
                                                                    )}
                                                                    langJson={val.textJsonLink}
                                                                    {...props}
                                                                />
                                                            </Col>
                                                        </Row>
                                                        <Row gutter={24}>
                                                            <Col span={12}>
                                                                <Form.Item
                                                                    label={geti18nText(
                                                                        'settings.footer.edit.links.link'
                                                                    )}
                                                                >
                                                                    {getFieldDecorator('url_link' + val.id)(
                                                                        <TextArea />
                                                                    )}
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                    </TabPane>
                                                ))}
                                        </Tabs>
                                    </Col>
                                </Row>
                            </Panel>
                            <Panel header={geti18nText('settings.footer.edit.social.network')} key="3">
                                <Row>
                                    <Row gutter={24}>
                                        <Col span={12}>
                                            <Form.Item
                                                label={geti18nText('settings.footer.edit.social.network.facebook')}
                                            >
                                                {getFieldDecorator('facebook')(<TextArea />)}
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item
                                                label={geti18nText('settings.footer.edit.social.network.linkedin')}
                                            >
                                                {getFieldDecorator('linkedin')(<TextArea />)}
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={24}>
                                        <Col span={12}>
                                            <Form.Item
                                                label={geti18nText('settings.footer.edit.social.network.instagram')}
                                            >
                                                {getFieldDecorator('instagram')(<TextArea />)}
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item
                                                label={geti18nText('settings.footer.edit.social.network.youtube')}
                                            >
                                                {getFieldDecorator('youtube')(<TextArea />)}
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Row>
                            </Panel>
                            <Panel header={geti18nText('settings.deseign')} key="4">
                                <Row gutter={8}>
                                    <Form.Item label={geti18nText('settings.deseign.hover.color')}>
                                        <Row gutter={8}>
                                            <Col span={4}>{getFieldDecorator('hoverColor')(<Input></Input>)}</Col>
                                            <Col span={2}>
                                                <ColorPicker
                                                    value={hoverColor}
                                                    onChange={(value) => {
                                                        props.form.setFieldsValue({ hoverColor: value });
                                                        setHoverColor(value);
                                                    }}
                                                ></ColorPicker>
                                            </Col>
                                        </Row>
                                    </Form.Item>
                                </Row>
                            </Panel>
                        </Collapse>
                    </Row>
                </Modal>
                <Form.Item style={{ width: '30%' }} label={geti18nText('settings.favorite.icon')}>
                    {getFieldDecorator('favIcon')(
                        <FileUpload
                            fileType={CONSTANTS_FILE_TYPE.IMAGE}
                            map={{ id: 'id', label: 'name' }}
                            searchBy="name"
                            disabled={false}
                            addedData={{ projectId: Session.getProjectId(), fileType: CONSTANTS_FILE_TYPE.IMAGE }}
                        />
                    )}
                </Form.Item>
                <InputByLanguage
                    notRequred
                    fieldName={'mapZoomText'}
                    label={geti18nText('settings.map.zoomText')}
                    langJson={zoomTextJson}
                    {...props}
                />
                <MediaEditTab
                    ref={(ref) => (screensaverRef.current = ref)}
                    group={screensaverFiles.settingGroup}
                    groupKey={screensaverFiles.settingKey}
                    files={screensaverFiles}
                    title={geti18nText('settings.screensaver')}
                    setFiles={setScreensaverFiles}
                    {...props}
                ></MediaEditTab>
                <MediaEditTab
                    ref={(ref) => (screensaverVericalRef.current = ref)}
                    group={screensaverVericalFiles.settingGroup}
                    groupKey={screensaverVericalFiles.settingKey}
                    files={screensaverVericalFiles}
                    title={geti18nText('settings.screensaver.vertical')}
                    setFiles={setScreensaverVerticalFiles}
                    {...props}
                ></MediaEditTab>
                <MediaEditTab
                    ref={(ref) => (bannerRef.current = ref)}
                    group={bannerFiles.settingGroup}
                    groupKey={bannerFiles.settingKey}
                    files={bannerFiles}
                    title={geti18nText('settings.banner')}
                    setFiles={setBannerFiles}
                    {...props}
                ></MediaEditTab>
                <Divider>{geti18nText('settings.default.google.recaptcha.title')}</Divider>
                <Row>
                    <Col span={12}>
                        <Form.Item label={geti18nText('settings.default.google.recaptcha.siteKey')}>
                                                    {getFieldDecorator('recaptchaSiteKey')(
                                                        <Input  />
                                                    )}
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={12}>
                        <Form.Item label={geti18nText('settings.default.google.recaptcha.webKey')}>
                                                    {getFieldDecorator('recaptchaWebKey')(
                                                        <Input  />
                                                    )}
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={18} >
                                <Form.Item label={geti18nText('component.btcnewsletter.edit.emails')}>
                                    {getFieldDecorator('emails')(
                                        <Select
                                            mode="tags"
                                            notFoundContent={null}
                                            style={{ width: '100%' }}
                                            onChange={changeEmails}
                                        />
                                    )}
                                </Form.Item>
                    </Col> 
                </Row>   
                <Divider></Divider>

                <Form.Item>
                    <Button
                        disabled={footerChangeConfirmVisible}
                        type="primary"
                        onClick={(e) => {
                            save(e);
                        }}
                    >
                        {geti18nText('projectManagement.edit.save')}
                    </Button>
                </Form.Item>
            </Form>
        </React.Fragment>
});

export default NyTouristSettings;
