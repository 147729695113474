import React from 'react';
import NyTouristSettings from '../../../themes/nytourist/components/settings/NyTouristSettings';
import BtcSettings from '../../../themes/btc/components/settings/BtcSettings';
import ClsmSettings from '../../../themes/clsm/components/settings/ClsmSettings';
import { useSelector } from 'react-redux';

const SettingsIndex = () => {
    const { projectId, themeShortName } = useSelector((state) => state.projectSlices);

    if (themeShortName != null && themeShortName === 'btc') {
        return <BtcSettings projectId={projectId} />;
    } else if (themeShortName != null && themeShortName === 'nytourist') {
        return <NyTouristSettings projectId={projectId} />;
    } else if (themeShortName != null && themeShortName === 'clsm') {
        return <ClsmSettings projectId={projectId} />;
    } else {
        return <NyTouristSettings projectId={projectId} />;
    }
};

export default SettingsIndex;
