import React from 'react';
import { Icon } from 'antd';
import { DataTable, getColumnSearch, getColumnSearchOption } from '../../../components/DataTable';
import TranslationEdit from './TranslationsEdit';
import { CONSTANTS_REQ } from '../../../utils/Constants';
import { ENUMS, geti18nText } from '../../../utils/Session';
import { getTranslated } from '../../../components/InputByLanguage';

const TranslationsList = (props) => {

    return (
        <DataTable
            headerTitle={ geti18nText('translations.table.header') }
            url={CONSTANTS_REQ.TRANSLATIONS.LIST}
            hideNewButton={true}
            showRecordModal={true}
            modalComponent={TranslationEdit}
            addedData={props.addedData}
            columns={[
                {
                    title: geti18nText('translations.table.column.id'),
                    dataIndex: 'id',
                    width: '10%',
                    sorter: (a, b) => {},
                    ...getColumnSearch('number'),
                },
                {
                    title: geti18nText('translations.table.column.key'),
                    dataIndex: 'key',
                    sorter: (a, b) => {},
                    ...getColumnSearch('string'),
                },
                {
                    title: geti18nText('translations.table.column.text'),
                    dataIndex: 'text',
                    sorter: (a, b) => {},
                    ...getColumnSearch('string'),
                },
            ]}
        />
    );
};
export default TranslationsList;