import React from 'react';
import { Spin, Row, Col } from 'antd';

export const Spinner = (props) => {
    return (
        <Row type="flex" justify="space-around" align="middle" className="height-100" style={props.style}>
            <Col className="animated fadeIn faster">
                <Row type="flex" justify="center" align="middle">
                    <Spin />
                </Row>
            </Col>
        </Row>
    );
};
