import React, { useEffect, useState } from 'react';
import { Icon } from 'antd';
import { DataTable, getColumnSearch, getColumnSearchOption } from '../../../components/DataTable';
import { Spinner } from '../../../components/Spinner';
import BulletinEdit from './BulletinEdit';
import { CONSTANTS_REQ } from '../../../utils/Constants';
import { ENUMS, geti18nText, Session } from '../../../utils/Session';
import { getTranslated } from '../../../components/InputByLanguage';
import { getSelectedLocale } from '../../../utils/Utils';
import { useSelector } from 'react-redux';

const BulletinList = (props) => {
    const [webPageId, setWebPageId] = useState(props.webPageId);
    const { projectId } = useSelector((state) => state.projectSlices);

    useEffect(() => {
        setWebPageId(
            Session.getProjectInfo() && Session.getProjectInfo().webPage
                ? Session.getProjectInfo().webPage.id
                : props.webPageId
                ? props.webPageId
                : undefined
        );
    }, [props.webPageId, projectId]);

    function setDefaultFilterValue() {
        return [{ field: 'active', condition: 'equals_bool', value: 1 }];
    }

    return webPageId == null ? (
        <Spinner />
    ) : (
        <DataTable
            headerTitle={geti18nText('bulletin.table.header')}
            url={CONSTANTS_REQ.BULLETIN.LIST}
            addNewButtonText={geti18nText('bulletin.table.add')}
            showRecordModal={true}
            modalComponent={BulletinEdit}
            setDefaultFilterValue={setDefaultFilterValue}
            addedData={{ webPageId: webPageId }}
            columns={[
                {
                    title: geti18nText('bulletin.table.column.id'),
                    dataIndex: 'id',
                    width: '10%',
                    sorter: (a, b) => {},
                    ...getColumnSearch('number'),
                },
                {
                    title: geti18nText('bulletin.table.column.name'),
                    dataIndex: 'name',
                    sorter: (a, b) => {},
                    ...getColumnSearch('string'),
                    render: (text, record) => {
                        return <div>{getTranslated(text)}</div>;
                    },
                },
                {
                    title: geti18nText('bulletin.table.column.date_from'),
                    dataIndex: 'dateFrom',
                    sorter: (a, b) => {},
                    ...getColumnSearch('date'),
                    render: (text, record) => {
                        if (record.dateFrom) {
                            return <div>{new Date(record.dateFrom).toLocaleString(getSelectedLocale())}</div>;
                        }
                    },
                },
                {
                    title: geti18nText('bulletin.table.column.date_to'),
                    dataIndex: 'dateTo',
                    sorter: (a, b) => {},
                    ...getColumnSearch('date'),
                    render: (text, record) => {
                        if (record.dateTo) {
                            return <div>{new Date(record.dateTo).toLocaleString(getSelectedLocale())}</div>;
                        }
                    },
                },
                {
                    title: geti18nText('bulletin.table.column.forfirstpage'),
                    dataIndex: 'forFirstPage',
                    width: '10%',
                    render: (text, record) => {
                        if (record.forFirstPage) {
                            return <Icon type="check" style={{ color: 'green' }} />;
                        } else {
                            return <Icon type="close" style={{ color: 'red' }} />;
                        }
                    },
                    ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', null),
                },
                {
                    title: geti18nText('bulletin.table.column.active'),
                    dataIndex: 'active',
                    width: '10%',
                    render: (text, record) => {
                        if (record.active) {
                            return <Icon type="check" style={{ color: 'green' }} />;
                        } else {
                            return <Icon type="close" style={{ color: 'red' }} />;
                        }
                    },
                    ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', setDefaultFilterValue()[0]['value']),
                    defaultFilteredValue: setDefaultFilterValue(),
                },
            ]}
        />
    );
};

export default BulletinList;
