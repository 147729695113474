import { Col, Divider, Form, Row, Tabs, notification,Button,Input,Radio } from 'antd';
import React, { useEffect, useState } from 'react';
import { normalizeJSONForComponent } from '../../utils/ComponentHelper';
import { CONSTANTS_FILE_TYPE, CONSTANTS_REQ } from '../../utils/Constants';
import { RESPONSE, requestGet, requestPut } from '../../utils/RequestResolver';
import { Session, geti18nText } from '../../utils/Session';
import { normalizeComponents } from '../../utils/Utils';
import { InputByLanguage, translationsToObject } from '../InputByLanguage';
import { Spinner } from '../Spinner';
import { FileUpload } from '../fileUpload/FileUpload';
import { SearchField } from '../../components/Search';


const AboutAreaYlEdit = Form.create({ name: 'AboutAreaYlEdit' })((props) => {
    const { getFieldDecorator, setFieldsValue } = props.form;
    const [fetching, setFetching] = useState(false);   
    const [formValues, setFormValues] = useState(null);
    
    useEffect(() => {
        fetch();
    }, [props.visible]);

    useEffect(() => {
        props.form.resetFields();
        setFieldsValue(formValues);
    }, [formValues]);

    function fetch() {
        setFetching(true);
        requestGet(CONSTANTS_REQ.WEBPAGECOMPONENT.EDIT + '/' + props.id).then((result) => {
            setFetching(false);
            if (result.status === RESPONSE.OK) {
                setValues(result.data);
            }
        });
    }
    
    function showNotification(title, message, duration, type) {
        notification[type]({
            message: title,
            description: message,
            duration: duration,
        });
    }

    function okNotification() {
        showNotification(geti18nText('app.default.save.ok'), geti18nText('app.default.save.ok.desc'), 3, 'success');
    }

    function error(message) {
        showNotification(geti18nText('app.default.save.nok'), message, 3, 'error');
    } 

    function setWepPageValue(key, id) {
        console.log('key',key,'id',id)
        requestGet(CONSTANTS_REQ.WEBPAGE.EDIT + '/' + id).then((result) => {
            if (result.status === RESPONSE.OK) {
                let field = {};
                field[key] = { id: id, name: result.data.name };
                setFieldsValue(field);
            }
        });
    }

    function setValues(component_data) {
        var json;
        if (component_data.json) {
            json = JSON.parse(component_data.json);
            json = translationsToObject(json, 'title', json.title);
            json = translationsToObject(json, 'subtitle', json.subtitle);
            json = translationsToObject(json, 'text', json.text);
            json = translationsToObject(json, 'textLeft', json.textLeft);
            json = translationsToObject(json, 'textRight', json.textRight);
            json = translationsToObject(json, 'textRightBelow', json.textRightBelow);
            json = translationsToObject(json, 'noteText1', json.noteText1);
            json = translationsToObject(json, 'noteText2', json.noteText2);
            json = translationsToObject(json, 'signatureText', json.signatureText);
            if(json.file_path1 && Object.keys(json.file_path3).length>0) {
                json['file_path1'] = {
                    key: json.file_path1,
                    file_path: json.file_path1,
                };
            }
            else {
                json['file_path1'] =undefined
            }
            if(json.file_path2 && Object.keys(json.file_path2).length>0) {
                json['file_path2'] = {
                    key: json.file_path2,
                    file_path: json.file_path2,
                };
            }
            else {
                json['file_path2'] =undefined
            }
            if(json.file_path3 && Object.keys(json.file_path3).length>0 ) {
                json['file_path3'] = {
                    key: json.file_path3,
                    file_path: json.file_path3,
                };
            }
            else {
                json['file_path3'] =undefined
            }
            
            setFormValues(json);
        }
    }

    function normalize(values, translationKeys) {
        let jsonFieldGroups = ['file_path1', 'file_path2', 'title', 'subtitle', 'text', 'textLeft', 'textRight', 'textRightBelow', 'noteText1', 'noteText2', 'file_path3', 'signatureText'];
        let jsonFieldKeys = {};
        return normalizeJSONForComponent(values, undefined, jsonFieldGroups, jsonFieldKeys, props);
    }

    function save(e, close) {
        e.preventDefault();
        props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                values = normalizeComponents(values);                
                values = normalize(values);
                requestPut(CONSTANTS_REQ.WEBPAGECOMPONENT.UPDATEJSON + '/' + props.id, null, {
                    id: props.id,
                    json: values.json,
                }).then((result) => {
                    if (result && result.status === RESPONSE.CREATED) {
                        console.log('okNotification');
                        okNotification();
                        close && props.closePopup();
                    } else {
                        error();
                        if (result && result.status === RESPONSE.BAD_REQUEST) {
                            if (result.data && result.data.field) {
                                switch (result.data.field) {
                                    case 'mandatory_fields_required':
                                        console.log(geti18nText('app.default.mandatory_fields_required'));
                                        return;
                                    default:
                                        break;
                                }
                            }
                        }
                        if (result.data && result.data.error) {
                            console.log(JSON.stringify(result.data.error));
                        }
                    }
                   
                });
            }
        });
    }

    
    if (fetching) {
        return <Spinner />
    } else {
        return (
            <React.Fragment>
                <Divider>{geti18nText('component.aboutAreaYl')}</Divider>
                <Form>
                    <Form.Item label={geti18nText('component.aboutAreaYl.image1')}>
                        {getFieldDecorator('file_path1', {
                                    rules: [{ required: true, message: geti18nText('app.default.required') }],
                                } )(
                            <FileUpload
                                fileType={CONSTANTS_FILE_TYPE.IMAGE}
                                map={{ id: 'id', label: 'name' }}
                                searchBy="name"
                                disabled={false}
                                addedData={{ projectId: Session.getProjectId() }}
                            />
                        )}
                    </Form.Item>
                     <Form.Item label={geti18nText('component.aboutAreaYl.image2')}>
                        {getFieldDecorator('file_path2', {
                                    rules: [{ required: true, message: geti18nText('app.default.required') }],
                                } )(
                            <FileUpload
                                fileType={CONSTANTS_FILE_TYPE.IMAGE}
                                map={{ id: 'id', label: 'name' }}
                                searchBy="name"
                                disabled={false}
                                addedData={{ projectId: Session.getProjectId() }}
                            />
                        )}
                    </Form.Item>
                    <InputByLanguage
                        notRequred
                        fieldName={'title'}
                        label={geti18nText('component.aboutAreaYl.title')}
                        langJson={props.titleJson}
                        {...props}
                    />
                    
                    <InputByLanguage
                        notRequred
                        fieldName={'subtitle'}
                        label={geti18nText('component.aboutAreaYl.subtitle')}
                        langJson={props.titleJson}
                        {...props}
                    />
                    
                    <InputByLanguage
                        notRequred
                        fieldName={'text'}
                        label={geti18nText('component.aboutAreaYl.text')}
                        langJson={props.titleJson}
                        type={'textArea'}
                        {...props}
                    />

                    <InputByLanguage
                        notRequred
                        fieldName={'textLeft'}
                        label={geti18nText('component.aboutAreaYl.textLeft')}
                        langJson={props.titleJson}
                        {...props}
                    />   
                    
                    <InputByLanguage
                        notRequred
                        fieldName={'textRight'}
                        label={geti18nText('component.aboutAreaYl.textRight')}
                        langJson={props.titleJson}
                        {...props}
                    />                

                    <InputByLanguage
                        notRequred
                        fieldName={'textRightBelow'}
                        label={geti18nText('component.aboutAreaYl.textRightBottom')}
                        langJson={props.subtitleJson}
                        {...props}
                    />
                    <InputByLanguage
                        notRequred
                        fieldName={'noteText1'}
                        label={geti18nText('component.aboutAreaYl.noteText1')}
                        langJson={props.subtitleJson}
                        {...props}
                    />
                    <InputByLanguage
                        notRequred
                        fieldName={'noteText2'}
                        label={geti18nText('component.aboutAreaYl.noteText2')}
                        langJson={props.subtitleJson}
                        {...props}
                    />
                    <Form.Item label={geti18nText('component.aboutAreaYl.image3')}>
                        {getFieldDecorator('file_path3' )(
                            <FileUpload
                                fileType={CONSTANTS_FILE_TYPE.IMAGE}
                                map={{ id: 'id', label: 'name' }}
                                searchBy="name"
                                disabled={false}
                                addedData={{ projectId: Session.getProjectId() }}
                            />
                        )}
                    </Form.Item>
                    <InputByLanguage
                        notRequred
                        fieldName={'signatureText'}
                        label={geti18nText('component.aboutAreaYl.text.signature')}
                        langJson={props.subtitleJson}
                        {...props}
                    />
                    
                </Form>
                <Row>
                        <Col span={24} style={{ textAlign: 'right', marginTop: '1rem' }}>
                            <Button style={{ marginRight: '1em' }} onClick={(e) => props.closePopup()}>
                                {geti18nText('app.default.button.cancel')}
                            </Button>
                            <Button style={{ marginRight: '1em' }} type="primary" onClick={(e) => save(e, false)}>
                                {geti18nText('app.default.button.save')}
                            </Button>
                            <Button
                                type="primary"
                                style={{ background: '#31b531', borderColor: '#31b531', textColor: 'white' }}
                                onClick={(e) => {
                                    save(e, true);
                                }}
                            >
                                {geti18nText('app.default.button.save.close')}
                            </Button>
                        </Col>
                    </Row>
            </React.Fragment>
        );
    }
});

export default AboutAreaYlEdit;
