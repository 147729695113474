import React, { useState } from "react";
import { Checkbox, Form, Row, Col, Input } from "antd";
import { CONSTANTS_REQ } from "../../../utils/Constants";
import { DataEdit } from "../../../components/DataEdit";
import { SearchField, mapDataToSelect } from "../../../components//Search";
import { Session, geti18nText } from "../../../utils/Session";
import { requestDelete, RESPONSE } from "../../../utils/RequestResolver";
import { showNotification, FormatFileSize } from "../../../utils/Utils"

const PageTypeEdit = Form.create({ name: "PageTypeEdit" })(props => {
    const { getFieldDecorator, setFieldsValue } = props.form;
    const [editHeader, setEditHeader] = useState(geti18nText("page.type.edit.new"));
    const [loading, setLoading] = useState(false);

    function setValues(diskFile) {
        setEditHeader(diskFile.id);
        delete diskFile.active;
        diskFile.formatedSize = FormatFileSize(diskFile.size);
        setFieldsValue(diskFile);
    }

    function deleteFile(e) {
        setLoading(true);
        let fileId = props.form.getFieldsValue(["id"]);
        requestDelete(CONSTANTS_REQ.DISK_FILE.DELETE + fileId["id"], undefined, {id: fileId}).then(result => {
            setLoading(false);
            if (result && result.status === RESPONSE.OK) {                
                showNotification(geti18nText("app.default.delete.ok"), geti18nText("app.default.delete.ok.desc"), 3, "success");
                props.setVisible(false);
                if (props.onSave) {
                    props.onSave();
                }
                props.form.resetFields();
            } else {
                if (result.data && result.data.error) {
                    var msg = JSON.stringify(result.data.error);
                    msg = msg.replace("null", "");
                    if (result.data.error.toString().startsWith("constraint_violation_exception")) {                        
                        msg = msg.replace("constraint_violation_exception", geti18nText("disk.file.constraint.violation.exception")).replace(":", "");
                    }
                    showNotification(geti18nText("app.default.delete.nok"), msg, 0, "error");
                }
            }            
        });
    }

    return (        
        <>
            <DataEdit
                editHeader={editHeader}
                loading={loading}
                setLoading={setLoading}
                url={CONSTANTS_REQ.DISK_FILE.EDIT}
                setValues={setValues}
                width={1200}
                {...props}                
                hideSubmitButton={true}
                customButtons={[{ text: geti18nText("app.default.button.delete"), loading: props.loading, style: { backgroundColor: "#1890ff", color: "white" }, className: "", onClick: deleteFile, addPopconfirm: true, popConfirmMsg: geti18nText("app.default.confirm.delete")}]}
                normalize={values => {
                    return {
                        ...values,
                        projectId: Session.getProjectId()
                    };
                }}
            >
                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item hidden={true}>{getFieldDecorator("id")(<Input hidden={true} />)}</Form.Item>
                        <Form.Item label={geti18nText("disk.file.edit.name")}>{getFieldDecorator("name")(<Input />)}</Form.Item>
                        <Form.Item label={geti18nText("disk.file.edit.path")}>{getFieldDecorator("path")(<Input />)}</Form.Item>
                        <Form.Item label={geti18nText("disk.file.edit.size")}>{getFieldDecorator("formatedSize")(<Input readOnly={true} />)}</Form.Item>
                        <Form.Item label={geti18nText("disk.file.edit.dateCreated")}>{getFieldDecorator("dateCreated")(<Input />)}</Form.Item>
                        <Form.Item label={geti18nText("disk.file.edit.createdBy")}>{getFieldDecorator("createdBy")(<SearchField url={CONSTANTS_REQ.USER.SEARCH} map={{ id: "id", label: "username" }} searchBy="username" disabled={false} />)}</Form.Item>
                        <Form.Item hidden={true} label={geti18nText("disk.file.edit.size")}>{getFieldDecorator("size")(<Input hidden={true} />)}</Form.Item>
                        <Form.Item hidden={true}>{getFieldDecorator("fileType")(<Input hidden={true} />)}</Form.Item>
                        <Form.Item hidden={true}>{getFieldDecorator("project")(<Input hidden={true} />)}</Form.Item>

                    </Col>
                </Row>
            </DataEdit>
        </>
    );
});

export default PageTypeEdit;
