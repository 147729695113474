import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { requestGet, RESPONSE, requestPost } from '../../../../utils/RequestResolver';
import { CONSTANTS_REQ, CONSTANTS_FILE_TYPE } from '../../../../utils/Constants';
import { Session, geti18nText } from '../../../../utils/Session';
import { Row, Divider, Form, Button, message, Col, Tabs, Popconfirm, InputNumber, Slider, notification } from 'antd';
import DraggableTabs from './DraggableTabs';
import { FileUpload } from '../../../../components/fileUpload/FileUpload';
const ButtonGroup = Button.Group;
const { TabPane } = Tabs;

const MediaEditTab = forwardRef((props, ref) => {
    const { getFieldDecorator } = props.form;
    const [fileToDelete, setFileToDelete] = useState(null);
    const [filesKeys, setFilesKeys] = useState([]);
    const [lastKey, setLastKey] = useState(0);
    const [files, setFiles] = useState([]);
    const [activeTab, setActiveTab] = useState('0');
    const [confirmVisible, setConfirmVisible] = useState(false);
    const timeMarks = { 30: '30', 60: '60', 120: '120', 180: '180', 240: '240', 300: '300' };

    useEffect(() => {
        getFiles();
    }, []);

    useEffect(() => {
        //exportValue();
    }, [files]);

    useImperativeHandle(ref, () => ({
        exportValue() {
            let _files = [];
            let newKey = 0;
            for (const key of filesKeys) {
                _files.push(
                    normalizeFileUpload('file-path_' + props.group + '_' + key, ++newKey, props.group + '_' + key)
                );
            }
            var value = null;
            if (_files.length > 0) {
                value = JSON.stringify(_files);
            }
            props.files.settingValue = value;
            props.setFiles(props.files);
        },
    }));
    async function getFiles() {
        if (Session.getProjectId() != null) {
            const resp = await requestGet(CONSTANTS_REQ.SETTINGS.ADMIN, {
                group: props.group,
                key: props.groupKey,
                projectId: Session.getProjectId(),
            });
            if (resp.status === RESPONSE.OK) {
                props.setFiles(resp.data);
                if (resp.data.settingValue != null) {
                    let json = JSON.parse(resp.data.settingValue);
                    let fileArray = [];

                    let key = 1;
                    let files = {};
                    let filesKeys = [];
                    let times = {};
                    json.forEach((file) => {
                        fileArray.push({ id: key, file_type: file.file_type, time: file.time });
                        files['file-path_' + props.group + '_' + key] = {
                            key: key,
                            file_path: file.file_path,
                            name: file.name,
                            file_type: file.file_type,
                        };
                        if (file.file_type === CONSTANTS_FILE_TYPE.IMAGE) {
                            times['file-time_' + props.group + '_' + key] = file.time;
                        }
                        setLastKey(key);
                        filesKeys.push(key);
                        key++;
                    });

                    setFiles(fileArray);
                    setFilesKeys(filesKeys);
                    if (filesKeys.length > 0) {
                        setActiveTab('' + filesKeys[0]);
                    }

                    props.form.setFieldsValue(files);
                    props.form.setFieldsValue(times);
                }
            }
        }
    }
    const addNewFileButtons = (
        <ButtonGroup>
            <Button
                type="default"
                icon="picture"
                onClick={() => {
                    addNewTab(CONSTANTS_FILE_TYPE.IMAGE);
                }}
            />
            <Button
                type="default"
                icon="play-square"
                onClick={() => {
                    addNewTab(CONSTANTS_FILE_TYPE.VIDEO);
                }}
            />
        </ButtonGroup>
    );
    const onEdit = (targetKey, action) => {
        if (action == 'add') {
            props.form.validateFieldsAndScroll((err, values) => {
                if (!err) {
                    let newId = lastKey;
                    newId++;

                    let arrVideos = files.slice();
                    let arrKeys = filesKeys.slice();
                    arrVideos.push({ id: newId });
                    arrKeys.push(newId);
                    setFilesKeys(arrKeys);
                    setFiles(arrVideos);
                    setActiveTab('' + newId);
                    setLastKey(newId);
                }
            });
        }
        if (action == 'remove') {
            if (filesKeys.length > 0) {
                setConfirmVisible(true);
                setFileToDelete(targetKey);
            }
        }
    };

    const tabOnChange = (activeKey) => {
        props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                setActiveTab(activeKey);
            }
        });
    };

    const addNewTab = (file_type) => {
        let key = lastKey + 1;
        setLastKey(key);

        let newArrFiles = [...files];
        newArrFiles.push({ id: key, file_type: file_type, time: 30 });
        setFiles(newArrFiles);

        let newArrKeys = [...filesKeys];
        newArrKeys.push(key);
        setFilesKeys(newArrKeys);

        setActiveTab('' + key);
    };

    const changeTabOrder = (newOrder) => {
        let newArrFiles = [...files];
        newArrFiles.sort((a, b) => {
            const orderA = newOrder.indexOf(a.id + '');
            const orderB = newOrder.indexOf(b.id + '');

            if (orderA !== -1 && orderB !== -1) {
                return orderA - orderB;
            }
            if (orderA !== -1) {
                return -1;
            }
            if (orderB !== -1) {
                return 1;
            }

            const ia = filesKeys.indexOf(a.id + '');
            const ib = filesKeys.indexOf(b.id + '');

            return ia - ib;
        });
        setFiles(newArrFiles);
        let newArrKeys = [];
        newArrFiles.forEach((file) => {
            newArrKeys.push(parseInt(file.id));
        });
        setFilesKeys(newArrKeys);
    };

    const deleteFile = () => {
        if (fileToDelete) {
            var newArrKeys = filesKeys.filter((key) => key != parseInt(fileToDelete));
            var newArrFiles = files.filter((file) => file.id != parseInt(fileToDelete));
            setFilesKeys(newArrKeys);
            setFiles(newArrFiles);

            if (newArrKeys.length > 0) {
                setActiveTab('' + newArrKeys[newArrKeys.length - 1]);
            }

            setConfirmVisible(false);
            setFileToDelete(null);
        }
    };

    function normalizeFileUpload(field, newKey = null, key = null) {
        let file = props.form.getFieldsValue([field])[field];
        if (file == null) return null;
        if (file instanceof Array) {
            file = file[0];
        }

        if (newKey !== null) {
            if (file.file_type === CONSTANTS_FILE_TYPE.IMAGE) {
                let time = props.form.getFieldValue('file-time_' + key);
                if (time === undefined) {
                    time = 30;
                }
                return file.id
                    ? file
                    : {
                          key: newKey,
                          file_path: file.file_path,
                          name: file.name,
                          file_type: file.file_type,
                          time: time,
                      };
            } else {
                return file.id
                    ? file
                    : { key: newKey, file_path: file.file_path, name: file.name, file_type: file.file_type };
            }
        } else {
            return file.id ? file : { file_path: file.file_path, name: file.name, file_type: file.file_type };
        }
    }

    return (
        <React.Fragment>
            <Divider>{props.title}</Divider>
            <Col span={24}>
                <Popconfirm
                    placement="rightTop"
                    title={geti18nText('app.default.button.delete')}
                    visible={confirmVisible}
                    okText={geti18nText('app.default.button.yes')}
                    cancelText={geti18nText('app.default.button.no')}
                    onCancel={() => {
                        setConfirmVisible(false);
                    }}
                    onConfirm={deleteFile}
                >
                    <span></span>
                </Popconfirm>
            </Col>
            <Row style={{ marginTop: '20px ' }}>
                <Col span={24}>
                    <DraggableTabs
                        type="editable-card"
                        key={'banner'}
                        onEdit={onEdit}
                        onChange={tabOnChange}
                        changeTabOrder={changeTabOrder}
                        activeKey={activeTab}
                        hideAdd={true}
                        tabBarExtraContent={addNewFileButtons}
                    >
                        {files.map((file, index) => (
                            <TabPane
                                tab={
                                    (file.file_type === CONSTANTS_FILE_TYPE.IMAGE
                                        ? geti18nText('settings.screensaver.image')
                                        : geti18nText('settings.screensaver.video')) +
                                    ' ' +
                                    (index + 1)
                                }
                                key={file.id}
                                forceRender={true}
                            >
                                <EditTab
                                    index={props.group + '_' + file.id}
                                    file_type={file.file_type}
                                    time={file.time}
                                    timeMarks={timeMarks}
                                    {...props}
                                />
                            </TabPane>
                        ))}
                    </DraggableTabs>
                </Col>
            </Row>
        </React.Fragment>
    );
});
const EditTab = (props) => {
    const { getFieldDecorator } = props.form;
    const [inputNumber, setInputNumber] = useState(props.time !== undefined ? props.time : 30);

    const inputNumberChange = (value, key) => {
        if (typeof value !== 'number') {
            value = 30;
        }
        let time = {};
        time['file-time_' + key] = value;
        props.form.setFieldsValue(time);
        setInputNumber(value);
    };

    return (
        <>
            <Row gutter={24}>
                <Col span={24}>
                    <Form.Item
                        label={
                            props.file_type === CONSTANTS_FILE_TYPE.IMAGE
                                ? geti18nText('settings.screensaver.image')
                                : geti18nText('settings.screensaver.video')
                        }
                    >
                        {getFieldDecorator('file-path_' + props.index, {
                            rules: [{ required: true, message: geti18nText('app.default.required') }],
                        })(
                            <FileUpload
                                fileType={props.file_type}
                                map={{ id: 'id', label: 'name' }}
                                searchBy="name"
                                disabled={false}
                                addedData={{ projectId: Session.getProjectId() }}
                            />
                        )}
                    </Form.Item>
                </Col>
                {props.file_type === CONSTANTS_FILE_TYPE.IMAGE && (
                    <>
                        <Col span={6}>
                            <Form.Item label={geti18nText('settings.screensaver.time')}>
                                {getFieldDecorator('file-time_' + props.index)(
                                    <Slider
                                        min={30}
                                        max={300}
                                        step={30}
                                        marks={props.timeMarks}
                                        onChange={(newValue) => setInputNumber(newValue)}
                                    />
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <InputNumber
                                min={30}
                                max={300}
                                step={30}
                                value={inputNumber}
                                onChange={(value) => inputNumberChange(value, props.index)}
                                style={{ marginLeft: '20px', marginTop: '41px' }}
                            />
                        </Col>
                    </>
                )}
            </Row>
        </>
    );
};

export default MediaEditTab;
