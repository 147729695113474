import { Button, Col, DatePicker, Divider, Form, Icon, Input, InputNumber, Row, Select, Tag,notification } from 'antd';
import moment from 'moment';
import React, { forwardRef, useEffect, useState } from 'react';
import { Spinner } from '../../components/Spinner';
import { CONSTANTS_FILE_TYPE, CONSTANTS_REQ } from '../../utils/Constants';
import { requestGet, requestPut, RESPONSE } from '../../utils/RequestResolver';
import { geti18nText, Session } from '../../utils/Session';
import { normalizeComponents } from '../../utils/Utils';
import { FileUpload } from '../fileUpload/FileUpload';
import { InputByLanguage, translationsToObject } from '../InputByLanguage';

const FacebookFeedEdit = Form.create({ name: 'FacebookFeedEdit' })((props) => {
    const { getFieldDecorator, getFieldValue, setFieldsValue } = props.form;
    const [fetching, setFetching] = useState(false);
    const [accessValid, setAccessValid] = useState(false);
    const [userId, setUserId] = useState(false);
    const [formValues, setFormValues] = useState(null);
    const [accessValue, setAccessValue] = useState(null);

    useEffect(() => {
        fetch();
    }, [props.visible]);

    useEffect(() => {
        props.form.resetFields();
        setFieldsValue(formValues);
    }, [formValues]);

    function fetch() {
        setFetching(true);
        requestGet(CONSTANTS_REQ.WEBPAGECOMPONENT.EDIT + '/' + props.id).then((result) => {
            setFetching(false);
            if (result.status === RESPONSE.OK) {
                setValues(result.data);
            }
        });
    }

    function setValues(component_data) {
        if (component_data.json) {
            let data = JSON.parse(component_data.json);
            if (data.accessToken) {
                setAccessValue(data.accessToken);
                checkToken(data.accessToken, data.appToken);
                delete data.userId;
                setFormValues(data);
            }
        }
    }

    function checkToken(token, appToken) {
        requestGet(CONSTANTS_REQ.FACEBOOK_FEED.CHECK_TOKEN, {
            input_token: token,
            access_token: appToken,
        }).then((result) => {
            if (result && result.status === RESPONSE.OK) {
                if (result.data && result.data.data && result.data.data.is_valid === true) {
                    setAccessValid(true);
                    if (result.data && result.data.data && result.data.data.type && result.data.data.type === 'USER') {
                        setUserId(result.data.data.user_id);
                    } else if (
                        result.data &&
                        result.data.data &&
                        result.data.data.type &&
                        result.data.data.type === 'PAGE'
                    ) {
                        setUserId(result.data.data.profile_id);
                    }
                } else {
                    setAccessValid(false);
                }
            } else setAccessValid(false);
        });
    }

    function showNotification(title, message, duration, type) {
        notification[type]({
            message: title,
            description: message,
            duration: duration,
        });
    }

    function okNotification() {
        showNotification(geti18nText('app.default.save.ok'), geti18nText('app.default.save.ok.desc'), 3, 'success');
    }

    function error(message) {
        showNotification(geti18nText('app.default.save.nok'), message, 3, 'error');
    } 

    function save(e, close) {
        e.preventDefault();
        props.form.validateFieldsAndScroll((err, values) => {
            values.userId = userId;
            requestPut(CONSTANTS_REQ.WEBPAGECOMPONENT.UPDATEJSON + '/' + props.id, null, {
                id: props.id,
                json: JSON.stringify(values),
            }).then((result) => {
                if (result && result.status === RESPONSE.CREATED) {
                    console.log('okNotification');
                    okNotification();
                    close && props.closePopup();
                } else {
                    error();
                    if (result && result.status === RESPONSE.BAD_REQUEST) {
                        if (result.data && result.data.field) {
                            switch (result.data.field) {
                                case 'mandatory_fields_required':
                                    console.log(geti18nText('app.default.mandatory_fields_required'));
                                    return;
                                default:
                                    break;
                            }
                        }
                    }
                    if (result.data && result.data.error) {
                        console.log(JSON.stringify(result.data.error));
                    }
                }
            });
        });
    }

    const onChange = (name) => {
        setAccessValue(name.target.value);
        checkToken(name.target.value, getFieldValue('appToken'));
    };
    const { TextArea } = Input;

    function labelAccessToken() {
        return (
            <div style={{ display: 'inline' }}>
                {geti18nText('facebook.rss.access.token')}
                {'  '}
                {accessValue && (
                    <Tag color={accessValid ? '#03800b' : '#ed1114'}>
                        {' '}
                        {accessValid
                            ? geti18nText('facebook.rss.access.token.valid')
                            : geti18nText('facebook.rss.access.token.invalid')}
                    </Tag>
                )}
            </div>
        );
    }

    if (fetching) {
        return <Spinner />;
    } else {
        return (
            <React.Fragment>
                <Divider>{geti18nText('component.facebookFeed')}</Divider>
                <Form>
                    <Row>
                        <Col span={24}>
                            <Form.Item label={geti18nText('facebook.rss.app.token')}>
                                {getFieldDecorator('appToken', {
                                    rules: [{ required: true, message: geti18nText('app.default.required') }],
                                })(<Input />)}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Form.Item hidden={true}>{getFieldDecorator('id')(<Input hidden={true} />)}</Form.Item>
                            <Form.Item label={labelAccessToken()}>
                                {getFieldDecorator('accessToken', {
                                    rules: [{ required: true, message: geti18nText('app.default.required') }],
                                })(<TextArea value={accessValue} onChange={onChange} rows={3}></TextArea>)}
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row>
                        <Col span={12}>
                            <Form.Item label={geti18nText('facebook.number.posts')}>
                                {getFieldDecorator('numberPosts')(
                                    <InputNumber min={1} max={100} style={{ width: '30%' }} />
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24} style={{ textAlign: 'right', marginTop: '1rem' }}>
                            <Button style={{ marginRight: '1em' }} onClick={(e) => props.closePopup()}>
                                {geti18nText('app.default.button.cancel')}
                            </Button>
                            <Button style={{ marginRight: '1em' }} type="primary" onClick={(e) => save(e, false)}>
                                {geti18nText('app.default.button.save')}
                            </Button>
                            <Button
                                type="primary"
                                style={{ background: '#31b531', borderColor: '#31b531', textColor: 'white' }}
                                onClick={(e) => {
                                    save(e, true);
                                }}
                            >
                                {geti18nText('app.default.button.save.close')}
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </React.Fragment>
        );
    }
});
export default FacebookFeedEdit;
