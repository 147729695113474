import React from 'react';
/*import TextWithTitleAndSubtitle from './textWithTitleAndSubtitle/TextWithTitleAndSubtitle';
import HeaderImageVideo from './headerImageVideo/HeaderImageVideo';
import ImageWithTitleAndDescription from './imageWithtitleAndDescription/ImageWithTitleAndDescription';
import DestinationMap from './destinationMap/DestinationMap';
import GlobalMap from './globalMap/GlobalMap';
import VideoBlock from './videoBlock/VideoBlock';
import ImageLinks from './imageLinks/ImageLinks';
import ImageLinksCarousel from './imageLinksCarousel/ImageLinksCarousel';
import VideoWithText from './videoWithText/VideoWithText';
import ImageGrid from './imageGrid/imageGrid';
import ImageWithText from './imageWithText/ImageWithText';
import ImagesCarouselWithText from './imagesCarouselWithText/ImagesCarouselWithText';
import ImageGallery from './imageGallery/ImageGallery';
import FlexImageVertical from './fleximagevertical/FlexImageVertical';
import BtcCircle from './BtcCircle/BtcCircle';
import BtcReference from './btcreference/BtcReference';
import BtcNewsletter from './btcNewsletter/BtcNewsletter';
import FlexTitleText from './flexTitleText/FlexTitleText';
import FlexImageHorizontal from './flexImageHorizontal/FlexImageHorizontal';
import BtcPartners from './btcPartners/BtcPartners';
import PdfPreview from './pdfPreview/PdfPreview';
import BtcContact from './btcContact/BtcContact';
import BtcCareer from './btccareer/BtcCareer';
import HeaderVideo from './headervideo/HeaderVideo';
import HeaderImage from './headerImage/HeaderImage';
import ClsmCircle from './clsmCircle/ClsmCircle';
import ClsmHeaderImage from './clsmHeaderImage/ClsmHeaderImage';*/

import { geti18nText, Session, getTranslation } from '../utils/Session';
import { CONSTANTS_REQ } from '../utils/Constants';
import {
    TextWithTitleAndSubtitle,
    HeaderImageVideo,
    ImageWithTitleAndDescription,
    DestinationMap,
    GlobalMap,
    ImageLinks,
    ImageLinksCarousel,
    ImageGrid,
    ImageWithText,
    ImagesCarouselWithText,
    ImageGallery,
    FlexImageVertical,
    BtcCircle,
    BtcReference,
    BtcNewsletter,
    FlexTitleText,
    FlexImageHorizontal,
    BtcPartners,
    PdfPreview,
    BtcContact,
    BtcCareer,
    HeaderVideo,
    HeaderImage,
    VideoBlock,
    VideoWithText,
    Facilities,
    Accommodation,
    Search,
    ClsmCircle,
    ClsmHeaderImage,
    VideoChat,
    Space,
    WysiwygComponent,
    TextSearch,
    FacebookFeed,
    InstagramFeed,
    SlideContent,
    LinkIcons,
    TitleLink,
    CardDescription,
    CardDescription2,
    ImageSlide,
    FlexContentHorizontal,
    MagicNewsletter,
    Configurator,
    Availability,
    ClsmComponentView,
    ImageGalleryWithDescription,
    CardImageText,
    Specification,
    DescriptionTabs,
    ImageCardLinks,
    CalendarComponent,
    TabsWithList,
    HeroYl,
    ProgressAreaYl,
    FeatureAreaYl,
    ContactAreaYl,
    BreadCrumbAreaYl,
    AboutAreaYl,
    BlogYl,
    FaqYl,
    ShopYl,
    ShopDetailsYl,
    PackageYl,
    ChosePackageYl,
    BrandAreaYl
} from '@nybble/ny-tourist-components';
import { getSelectedClientLanguage } from '../utils/Utils';
import { getTranslated } from './InputByLanguage';
import { useHistory } from 'react-router-dom';
import { Icon, Carousel, Select, Button, Form, Input, Switch, Col, Row, Spin, Statistic } from 'antd';
import '../themes/nytourist/assets/css/flexboxgrid.css';
import { requestGet, requestPost, requestPostFile, RESPONSE } from '../utils/RequestResolver';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import FullCalendar from '@fullcalendar/react';
//import CardDescription2 from './test/index';
const { Option } = Select;

const GenerateElement = (props, parentProps) => {
    const history = useHistory();

    const Components = {
        textwithtitleandsubtitle: TextWithTitleAndSubtitle,
        headerimagevideo: HeaderImageVideo,
        imagewithtitleanddescription: ImageWithTitleAndDescription,
        destinationmap: DestinationMap,
        globalmap: GlobalMap,
        videoblock: VideoBlock,
        imagelinks: ImageLinks,
        imagelinkscarousel: ImageLinksCarousel,
        videowithtext: VideoWithText,
        imagegrid: ImageGrid,
        imagewithtext: ImageWithText,
        videowithtext: VideoWithText,
        imagescarouselwithtext: ImagesCarouselWithText,
        imagegallery: ImageGallery,
        fleximagevertical: FlexImageVertical,
        btccircle: BtcCircle,
        btcreference: BtcReference,
        btcnewsletter: BtcNewsletter,
        flextitletext: FlexTitleText,
        fleximagehorizontal: FlexImageHorizontal,
        btcpartners: BtcPartners,
        pdfpreview: PdfPreview,
        btccontact: BtcContact,
        btccareer: BtcCareer,
        headervideo: HeaderVideo,
        headerimage: HeaderImage,
        clsmcircle: ClsmCircle,
        clsmheaderimage: ClsmHeaderImage,
        facilities: Facilities,
        accomodation: Accommodation,
        search: Search,
        videochat: VideoChat,
        space: Space,
        wysiwygcomponent: WysiwygComponent,
        textsearch: TextSearch,
        instagramfeed: InstagramFeed,
        facebookFeed: FacebookFeed,
        slidecontent: SlideContent,
        icons: LinkIcons,
        carddescription: CardDescription,
        carddescription2: CardDescription2,
        titlelink: TitleLink,
        imageslide: ImageSlide,
        flexcontenthorizontal: FlexContentHorizontal,
        magicnewsletter: MagicNewsletter,
        magicavailability: Availability,
        configurator: Configurator,
        imagegallerywithdescription: ImageGalleryWithDescription,
        clsmviewcomponent: ClsmComponentView,
        cardimagetext: CardImageText,
        specification: Specification,
        descriptiontabs: DescriptionTabs,
        imagecardlinks: ImageCardLinks,
        calendar: CalendarComponent,
        tabWithListAndDescription: TabsWithList,
        heroAreaYl: HeroYl,
        progressAreaYl: ProgressAreaYl,
        featureAreaYl: FeatureAreaYl,
        brandAreaYl: BrandAreaYl,
        contactAreaYl: ContactAreaYl,
        breadCrumbAreaYl: BreadCrumbAreaYl,
        aboutAreaYl: AboutAreaYl,
        blogYl: BlogYl,
        faqYl: FaqYl,
        shopYl:ShopYl,
        shopDetailsYl:ShopDetailsYl,
        packageYl: PackageYl,
        choosePackageYl: ChosePackageYl,
        checkAvailabilityYl: ChosePackageYl
    };

    const noContentStyle = {
        fontSize: '22px',
        paddingTop: '100px',
        paddingBottom: '100px',
        paddingLeft: '10%',
        paddingRight: '10%',
        backgroundColor: 'rgb(245, 247, 247)',
    };

    function setBackground(cssJson) {
        var element = document.getElementById('mainContent');
        if (element != null) {
            Object.keys(cssJson).forEach((key) => {
                if (key.startsWith('background-image')) {
                    let imagePath = CONSTANTS_REQ.STATIC + 0 + encodeURI(cssJson[key].file_path);
                    element.style.setProperty('--' + key, 'url(' + imagePath + ')');
                } else if (key.startsWith('background-')) {
                    element.style.setProperty('--' + key, cssJson[key]);
                }
            });
        }
    }

    function generateComponentDeseignCSS(projectCss, projectComponentCss, componentCss) {
        var resultCss = {};
        if (projectCss) {
            resultCss = JSON.parse(projectCss);
            if (resultCss['background-color'] != null || resultCss['background-image'] != null) {
                setBackground(JSON.parse(projectCss));
            }
        }
        if (projectComponentCss) {
            const temp = JSON.parse(projectComponentCss);
            Object.keys(temp).forEach((element) => {
                resultCss[element] = temp[element];
            });
        }
        if (componentCss) {
            const temp = JSON.parse(componentCss);
            Object.keys(temp).forEach((element) => {
                resultCss[element] = temp[element];
            });
        }
        return JSON.stringify(resultCss);
    }

    if (Components[props.component.name] !== null) {
        if (props.json == null) {
            return React.createElement(() => (
                <div style={noContentStyle}>
                    Unesite sadržaj komponente: {Session.getComponentName(props.component.id)}.
                </div>
            ));
        }
        //return React.createElement(Components[props.component.name], { ...parentProps, json_data: props.json });
        return React.createElement(Components[props.component.name], {
            json_data: props.json,
            getSelectedClientLanguage: getSelectedClientLanguage,
            getTranslated: getTranslated,
            getTranslation: getTranslation,
            history: history,
            Icon: Icon,
            projectId: props.webPage.project.id,
            projectInfo: Session.getProjectInfo(),
            Carousel: Carousel,
            CONSTANTS_REQ: CONSTANTS_REQ,
            webPageId: props.webPage.id,
            RESPONSE: RESPONSE,
            requestGet: requestGet,
            requestPost: requestPost,
            requestPostFile: requestPostFile,
            geti18nText: geti18nText,
            Button: Button,
            Option: Option,
            Select: Select,
            Form: Form,
            Input: Input,
            Switch: Switch,
            Row: Row,
            Col: Col,
            Spin: Spin,
            Statistic: Statistic,
            projectType: 'admin',
            emailsToNewsletter: Session.getProjectInfo().emailsToNewsletter,
            emailsToInquiry: Session.getProjectInfo().emailsToInquiry,
            css_json: generateComponentDeseignCSS(
                props.webPage.project?.jsonCss,
                props.designComponent?.jsonCss,
                props.jsonCss
            ),
            css_id: props.component.name + '_' + props.id,
            FullCalendar: FullCalendar,
            dayGridPlugin: dayGridPlugin,
            interactionPlugin: interactionPlugin,
            hrkConversionValue: Session.getSetting('ux.tecaj-konverzije').replace(',', '.'),
            conversionDate: Session.getSetting('ux.datum-prikaza-konverzije'),
        });
    }

    return React.createElement(() => <div>Komponenta {props.component.name} još nije napravljena.</div>);
};

export default GenerateElement;
