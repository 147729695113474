import React, { useState, useEffect } from 'react';
import { Form, Row, Col, Button, Divider, Input, Radio, notification } from 'antd';
import { Session, geti18nText } from '../../utils/Session';
import { normalizeComponents } from '../../utils/Utils';
import { requestGet, requestPut, RESPONSE } from '../../utils/RequestResolver';
import { InputByLanguage, translationsToObject } from '../InputByLanguage';
import { Spinner } from '../Spinner';
import { SearchField } from '../Search';
import { CONSTANTS_FILE_TYPE, CONSTANTS_REQ } from '../../utils/Constants';
import { FileUpload } from '../fileUpload/FileUpload';

const FlexContentHorizontalEdit = Form.create({ name: 'FlexContentHorizontalEdit' })((props) => {
    const LINK_TYPE = {
        LINK: 'LINK',
        WEBPAGE: 'WEBPAGE',
    };
    const [linkType, setLinkType] = useState(LINK_TYPE.LINK);
    const { getFieldDecorator, setFieldsValue } = props.form;
    const [fetching, setFetching] = useState(false);
    const [formValues, setFormValues] = useState(null);
    const [textJson, setTextJson] = useState(null);
    const [externalLink, setExternalLink] = useState('');
    const [imageLink, setImageLink] = useState({});
    const [readMoreTextLangJson, setReadMoreTextLangJson] = useState(undefined);

    useEffect(() => {
        fetch();
    }, [props.visible]);

    useEffect(() => {
        props.form.resetFields();
        setFieldsValue(formValues);
    }, [formValues]);

    function fetch() {
        setFetching(true);
        requestGet(CONSTANTS_REQ.WEBPAGECOMPONENT.EDIT + '/' + props.id).then((result) => {
            setFetching(false);
            if (result.status === RESPONSE.OK) {
                setValues(result.data);
            }
        });
    }

    function setValues(component_data) {
        var json;
        if (component_data.json) {
            json = JSON.parse(component_data.json);

            json = translationsToObject(json, 'text', json.text);
            setTextJson(json.text);
            delete json.text;
            json.align = types.find(({ id }) => id === json.align);
            json.image_align = typesImage.find(({ id }) => id === json.image_align);
            json.button_align = typesImage.find(({ id }) => id === json.button_align);
            json = translationsToObject(json, 'read-more-button', JSON.stringify(json['read-more-button']));
            setReadMoreTextLangJson(JSON.stringify(json['read-more-button']));

            if (json.type !== undefined && json.type === LINK_TYPE.LINK) {
                setLinkType(LINK_TYPE.LINK);
                if (json.externalLink !== '') {
                    setExternalLink(json.externalLink);
                } else {
                    setExternalLink('');
                }
            } else {
                setLinkType(LINK_TYPE.WEBPAGE);
                if (json.link !== undefined) {
                    setWepPageValue('link', parseInt(json.link));
                }
            }
            setFormValues(json);
        }
    }
    function setWepPageValue(key, id) {
        requestGet(CONSTANTS_REQ.WEBPAGE.EDIT + '/' + id).then((result) => {
            if (result.status === RESPONSE.OK) {
                setFieldsValue({ [key]: { id: id, name: result.data.name } });
                setImageLink({ id: id, name: result.data.name });
            }
        });
    }

    function showNotification(title, message, duration, type) {
        notification[type]({
            message: title,
            description: message,
            duration: duration,
        });
    }

    function okNotification() {
        showNotification(geti18nText('app.default.save.ok'), geti18nText('app.default.save.ok.desc'), 3, 'success');
    }

    function error(message) {
        showNotification(geti18nText('app.default.save.nok'), message, 3, 'error');
    } 

    function save(e, close) {
        e.preventDefault();
        props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                values = normalizeComponents(values);
                if (values.align)
                    values.align =
                        values.align != null && values.align.constructor === String ? values.align : values.align.id;

                if (values.image_align)
                    values.image_align =
                        values.image_align != null && values.image_align.constructor === String
                            ? values.image_align
                            : values.image_align.id;

                if (values.button_align)
                    values.button_align =
                        values.button_align != null && values.button_align.constructor === String
                            ? values.button_align
                            : values.button_align.id;
                values.type = linkType;

                if (values.image) values.image = normalizeFileUpload('image');

                requestPut(CONSTANTS_REQ.WEBPAGECOMPONENT.UPDATEJSON + '/' + props.id, null, {
                    id: props.id,
                    json: JSON.stringify(values),
                }).then((result) => {
                    if (result && result.status === RESPONSE.CREATED) {
                        console.log('okNotification');           
  		    	        okNotification();
                        close && props.closePopup();
                    } else {
                        error();
                        if (result && result.status === RESPONSE.BAD_REQUEST) {
                            if (result.data && result.data.field) {
                                switch (result.data.field) {
                                    case 'mandatory_fields_required':
                                        console.log(geti18nText('app.default.mandatory_fields_required'));
                                        return;
                                    default:
                                        break;
                                }
                            }
                        }
                        if (result.data && result.data.error) {
                            console.log(JSON.stringify(result.data.error));
                        }
                    }
                });
            }
        });
    }

    function normalizeFileUpload(field) {
        let file = props.form.getFieldsValue([field])[field];
        if (file instanceof Array) {
            file = file[0];
        }
        return file.id ? file : { file_path: file.file_path, name: file.name };
    }

    const types = [
        { id: 'top', text: geti18nText('component.flexcontenthorizontal.edit.align.top') },
        { id: 'bottom', text: geti18nText('component.flexcontenthorizontal.edit.align.bottom') },
        { id: 'center', text: geti18nText('component.flexcontenthorizontal.edit.align.center') },
    ];

    const typesImage = [
        { id: 'left', text: geti18nText('component.flexcontenthorizontal.edit.left') },
        { id: 'right', text: geti18nText('component.flexcontenthorizontal.edit.right') },
    ];

    if (fetching) {
        return <Spinner />;
    } else {
        return (
            <React.Fragment>
                <Divider>{geti18nText('component.flexcontenthorizontal')}</Divider>
                <Form>
                    <Row style={{ marginTop: '20px ' }}>
                        <Col span={24}>
                            <Form.Item label={geti18nText('component.flexcontenthorizontal.edit.image')}>
                                {getFieldDecorator('image', {
                                    rules: [{ required: true, message: geti18nText('app.default.required') }],
                                })(
                                    <FileUpload
                                        fileType={CONSTANTS_FILE_TYPE.IMAGE}
                                        map={{ id: 'id', label: 'name' }}
                                        searchBy="name"
                                        disabled={false}
                                        addedData={{
                                            projectId: Session.getProjectId(),
                                            fileType: CONSTANTS_FILE_TYPE.IMAGE,
                                        }}
                                    />
                                )}
                            </Form.Item>
                            <Form.Item label={geti18nText('component.flexcontenthorizontal.edit.image.align')}>
                                {getFieldDecorator('image_align')(<SearchField options={typesImage} />)}
                            </Form.Item>
                            <InputByLanguage
                                type="rteEditor"
                                notRequred
                                fieldName={'text'}
                                label={geti18nText('component.flexcontenthorizontal.edit.text')}
                                langJson={textJson}
                                {...props}
                            />
                            <Form.Item label={geti18nText('component.flexcontenthorizontal.edit.align')}>
                                {getFieldDecorator('align')(<SearchField options={types} />)}
                            </Form.Item>
                            <Form.Item>
                                {getFieldDecorator('type', { initialValue: LINK_TYPE.WEBPAGE })(
                                    <Radio.Group
                                        onChange={(e) => {
                                            setLinkType(e.target.value);
                                        }}
                                    >
                                        <Radio value={LINK_TYPE.WEBPAGE}>
                                            {geti18nText('component.slidecontent.edit.radio_button.webPage')}
                                        </Radio>
                                        <Radio value={LINK_TYPE.LINK}>
                                            {geti18nText('component.slidecontent.edit.radio_button.link')}
                                        </Radio>
                                    </Radio.Group>
                                )}
                            </Form.Item>

                            {linkType == LINK_TYPE.LINK ? (
                                <Row gutter={24} type="flex" justify="space-between" align="bottom">
                                    <Col span={16}>
                                        <Form.Item label={geti18nText('component.slidecontent.edit.radio_button.link')}>
                                            {getFieldDecorator('external-link')(<Input defaultValue={externalLink} />)}
                                        </Form.Item>
                                    </Col>
                                </Row>
                            ) : (
                                <Form.Item label={geti18nText('component.slidecontent.edit.webpage')}>
                                    {getFieldDecorator('link')(
                                        <SearchField
                                            url={CONSTANTS_REQ.WEBPAGE.SEARCH}
                                            map={{ id: 'id', label: 'name' }}
                                            searchBy="name"
                                            disabled={false}
                                            translate={true}
                                            addedData={{ projectId: Session.getProjectId(), forMap: true }}
                                        />
                                    )}
                                </Form.Item>
                            )}
                            <InputByLanguage
                                notRequred
                                fieldName="read-more-button"
                                label={geti18nText('app.default.button.read_more.text')}
                                notRequred={true}
                                langJson={readMoreTextLangJson}
                                {...props}
                            />
                            <Form.Item label={geti18nText('component.flexcontenthorizontal.edit.button.align')}>
                                {getFieldDecorator('button_align')(<SearchField options={typesImage} />)}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24} style={{ textAlign: 'right', marginTop: '1rem' }}>
                            <Button style={{ marginRight: '1em' }} onClick={(e) => props.closePopup()}>
                                {geti18nText('app.default.button.cancel')}
                            </Button>
                            <Button style={{ marginRight: '1em' }} type="primary" onClick={(e) => save(e, false)}>
                                {geti18nText('app.default.button.save')}
                            </Button>
                            <Button
                                type="primary"
                                style={{ background: '#31b531', borderColor: '#31b531', textColor: 'white' }}
                                onClick={(e) => {
                                    save(e, true);
                                }}
                            >
                                {geti18nText('app.default.button.save.close')}
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </React.Fragment>
        );
    }
});

export default FlexContentHorizontalEdit;
