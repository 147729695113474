import React, { useState, useEffect } from 'react';
import { Checkbox, Form, Row, Col, Input, Tabs, Divider, InputNumber, Rate, Collapse, Button } from 'antd';
import { CONSTANTS_REQ, CONSTANTS_FILE_TYPE, UPLAD_STYLE_FONT_FOLDER } from '../../../utils/Constants';
import { DataEdit } from '../../../components/DataEdit';
import { InputByLanguage, translationsToObject, getTranslated } from '../../../components/InputByLanguage';
import { requestPost, requestGet, RESPONSE,requestPut } from '../../../utils/RequestResolver';
import { SearchField } from '../../../components//Search';
import { Session, geti18nText, hasAnyRole } from '../../../utils/Session';
import Page from './WebPagePreview';
import { useDispatch, useSelector } from 'react-redux';
import { refreshMenu } from '../../../slices/menuSlice';
import { selectFirstPage, selectPageType } from '../../../slices/webPageSlice';
import BulletinList from '../bulletin/BulletinList';
import AccountList from '../account/AccountList';
import { FileUpload } from '../../../components/fileUpload/FileUpload';
import InputMarkerCoordinates from '../../../components/inputMarkerCoordinates/InputMarkerCoordinates';
import { useHistory } from 'react-router-dom';
import WebPageCopy from './WebPageCopy';
import WebPageComponentAdd from './WebPageComponentAdd';
import '../../../assets/css/kiosk-global.css';
import { useScript } from '../../../utils/Utils';
import WebPageLinks from './WebPageLinks';


const WebPageEdit = Form.create({ name: 'WebPageEdit' })((props) => {
    const { getFieldDecorator, setFieldsValue,getFieldsValue } = props.form;
    const [editHeader, setEditHeader] = useState(geti18nText('webpage.edit.new'));
    const [loading, setLoading] = useState(false);
    const [pageID, setPageID] = useState(0);
    const [showComponents, setShowComponents] = useState(false);
    const [closeWebPageModeal, setCloseWebPageModeal] = useState(false); // kod kreiranje novog page-a, prvi save ne zatvara popup - prikazuje komponente stranica
    const [nameLangJson, setNameLangJson] = useState(undefined);
    const [searchDescriptionJson, setSearchDescriptionJson] = useState(undefined);
    const [priceJson, setPriceJson] = useState(undefined);
    const [refreshPagePreview, setRefreshPagePreview] = useState(Date.now());
    const [scrollToComponent, setScrollToComponent] = useState(null);
    const [initPageType, setInitPageType] = useState(undefined);
    const [initFirstPage, setInitFirstPage] = useState(false);
    const [savedPageType, setSavedPageType] = useState(undefined);
    const [savedFirstPageValue, setSavedFirstPageValue] = useState(false);
    const [selectedSearchTypes, setSelectedSearchTypes] = useState([]);
    const { selectedPageType, selectedFirstPage } = useSelector((state) => state.webPageSlices);
    const [titleLangJson, setTitleLangJson] = useState(null);
    const [keywordsLangJson, setKeywordsLangJson] = useState(null);
    const [descriptionLangJson, setDescriptionLangJson] = useState(null);
    const [selectedTab, setSelectedTab] = useState('settings');
    const [copyModalVisible, setCopyModalVisible] = useState(false);
    const [addComponentModalVisible, setAddComponentModalVisible] = useState(false);
    const [seletedLang, setSeletedLang] = useState(Session.getProjectDefaultLanguage());
    const [title, setTitle] = useState(undefined);
    const [description, setDescritpion] = useState(undefined);


    const dispatch = useDispatch();
    const history = useHistory();
    const { TabPane } = Tabs;
    const { Panel } = Collapse;

    useEffect(() => {
        getFonts();
    }, []);

    function setValues(webPage) {
        webPage = translationsToObject(webPage, 'name', webPage.name);
        webPage = translationsToObject(webPage, 'searchDescription', webPage.searchDescription);
        webPage = translationsToObject(webPage, 'seoTitle', webPage.seoTitle);
        webPage = translationsToObject(webPage, 'seoKeywords', webPage.seoKeywords);
        webPage = translationsToObject(webPage, 'seoDescription', webPage.seoDescription);
        webPage = translationsToObject(webPage, 'price', webPage.price);
        setNameLangJson(webPage.name);
        setSearchDescriptionJson(webPage.searchDescription);
        setEditHeader(getTranslated(webPage.name));
        setTitleLangJson(webPage.seoTitle);
        setKeywordsLangJson(webPage.seoKeywords);
        setDescriptionLangJson(webPage.seoDescription);
        setPriceJson(webPage.price);

        delete webPage.name;
        delete webPage.searchDescription;
        delete webPage.seoTitle;
        delete webPage.seoKeywords;
        delete webPage.seoDescription;
        delete webPage.price;

        setInitFirstPage(webPage.firstPage);
        setInitPageType(webPage.pageType);

        setPageID(webPage.id);

        delete webPage.project;
        if (webPage.location) {
            webPage.locations = webPage.location.map((loc) => {
                return { id: loc.id + '', text: loc.name };
            });
            delete webPage.location;
        } else {
            setFieldsValue({ locations: undefined });
        }

        if(webPage.title) {
            setTitle(webPage.title)
        }
        
        if(webPage.description) {
            setDescritpion(webPage.description)
        }

        if (webPage.searchContentType) {
            webPage.searchType = webPage.searchContentType.map((loc) => {
                return { id: loc.id + '', text: loc.name };
            });
            delete webPage.searchContentType;
            setSelectedSearchTypes(webPage.searchType.map((loc) => loc.id));
        } else {
            setFieldsValue({ searchType: undefined });
            setSelectedSearchTypes([]);
        }

        if (webPage.pageType == undefined) {
            setFieldsValue({ pageType: undefined });
        }
        if (webPage.searchImage) {
            setFieldsValue({ searchImage: { key: webPage.searchImage, file_path: webPage.searchImage } });
            delete webPage.searchImage;
        }

        delete webPage.topLevel;
        setFieldsValue(webPage);

        setShowComponents(true);

        setCloseWebPageModeal(true);
        setRefreshPagePreview(Date.now());
    }

    function getFonts() {
        requestGet(CONSTANTS_REQ.DIRECTORY.TREE + '/' + 0, {
            directory: UPLAD_STYLE_FONT_FOLDER,
        }).then((result) => {
            if (result.status === RESPONSE.OK) {
                console.log(result);
                if (Array.isArray(result.data)) {
                    setFonts(result.data);
                }
            }
        });
    }
    function setFonts(data) {
        data.forEach((font) => {
            let n = font.name.split('.')[0];
            var junction_font = new FontFace(
                n,
                `url(${CONSTANTS_REQ.STATIC + 0 + '/' + UPLAD_STYLE_FONT_FOLDER + '/' + font.name})`
            );
            return junction_font
                .load()
                .then((loaded_face) => {
                    document.fonts.add(loaded_face);
                    return n;
                })
                .catch((error) => {
                    console.log(error);
                    return null;
                });
        });
    }

    function onSelectComponent(component) {
        let selectedComponent = Session.getAppValue('COMPONENTS').filter((fObj) => {
            if (fObj.id == parseInt(component.key)) {
                return fObj;
            }
        })[0];

        let values = {};
        values.id = null;
        values.webPage = { id: pageID };
        values.component = { id: parseInt(component.key) };
        values.componentOrder = 100;
        values.json = selectedComponent && selectedComponent.defaultJson ? selectedComponent.defaultJson : '';
        values.active = true;

        if (Session.getProjectDefaultLanguage() != 'hr') {
            values.json = '';
        }

        if (component.label === geti18nText('component.search')) {
            values.json = '{}';
        }
        if (component.label === geti18nText('component.textsearch')) {
            values.json = '{}';
        }

        requestPost(CONSTANTS_REQ.WEBPAGECOMPONENT.EDIT, undefined, values).then((result) => {
            if (result && result.status === RESPONSE.CREATED) {
                if (result.data) {
                    setScrollToComponent(result.data.id);
                }
                setRefreshPagePreview(Date.now());

                //refresh menu
                let refreshNodes = ['WEBPAGE-' + pageID];
                if (initFirstPage) {
                    refreshNodes = ['FIRSTPAGE-' + pageID];
                }
                dispatch(
                    refreshMenu({
                        refreshLastNode: false,
                        refreshProjectNode: false,
                        refreshNodes: refreshNodes,
                    })
                );
            }
        });
    }

    function pageSaved(id) {
        setCloseWebPageModeal(true);

        setPageID(id);
        setShowComponents(true);
        history.push(history.location.pathname.substr(0, history.location.pathname.lastIndexOf('/')) + '/' + id);
    }

    //refresh menu
    function onSave() {
        let refreshNodes = [];
        if (initFirstPage != savedFirstPageValue) {
            refreshNodes.push('PCONTENT-' + Session.getProjectId());
        }
        if (initPageType != undefined) {
            refreshNodes.push('TYPE-' + initPageType.id);
        } else {
            refreshNodes.push('NOTINMENUP-' + Session.getProjectId());
        }
        if (savedPageType != undefined) {
            if (!refreshNodes.includes('TYPE-' + savedPageType.id)) refreshNodes.push('TYPE-' + savedPageType.id);
            if (!refreshNodes.includes('NOTINMENUP-' + Session.getProjectId()))
                refreshNodes.push('NOTINMENUP-' + Session.getProjectId());
        } else {
            if (!refreshNodes.includes('NOTINMENUP-' + Session.getProjectId()))
                refreshNodes.push('NOTINMENUP-' + Session.getProjectId());
        }

        dispatch(
            refreshMenu({
                refreshLastNode: false,
                refreshProjectNode: false,
                refreshNodes: refreshNodes,
            })
        );
        setInitPageType(savedPageType);
        setInitFirstPage(savedFirstPageValue);
    }

    function setDefaultValues() {
        props.form.resetFields();
        setFieldsValue({ locations: undefined });
        setFieldsValue({ searchType: undefined });
        setSelectedSearchTypes([]);
        setRefreshPagePreview(Date.now());
        setEditHeader(geti18nText('webpage.edit.new'));
        setShowComponents(false);
        setPageID(0);
        setCloseWebPageModeal(false);
        setNameLangJson(undefined);
        setSearchDescriptionJson(undefined);

        setInitPageType(undefined);
        setInitFirstPage(undefined);

        //page type
        if (selectedPageType == 0) {
            setFieldsValue({ pageType: undefined });
        } else {
            requestGet(CONSTANTS_REQ.PAGETYPE.EDIT + '/' + selectedPageType).then((result) => {
                dispatch(
                    selectPageType({
                        selectedPageType: 0,
                    })
                );

                if (result.status === RESPONSE.OK) {
                    setFieldsValue({ pageType: result.data });
                }
            });
        }

        //select first page
        if (selectedFirstPage) {
            setFieldsValue({ firstPage: true });
            dispatch(
                selectFirstPage({
                    selectedFirstPage: false,
                })
            );
        }

        setSelectedTab('settings');
    }

    function shouldCloseModal() {
        return closeWebPageModeal;
    }

    function onTypeChanged(value) {
        if (value == undefined) {
            setSavedPageType(undefined);
        } else {
            setSavedPageType({ id: value.key });
        }
    }

    function onSearchTypeChanged(value) {
        setSelectedSearchTypes(value.map((item) => parseInt(item.key)));
    }

    function onFirstPageChanged(value) {
        setSavedFirstPageValue(value.target.checked);
    }

    function normalizeValues(values) {
        let searchTypes = [];
        let normalized = {
            ...values,
            pageType:
                values.pageType != null
                    ? values.pageType.constructor === String
                        ? { id: parseInt(values.pageType) }
                        : { id: values.pageType.id }
                    : null,
            locations:
                values.locations != null
                    ? values.locations.constructor === String
                        ? [parseInt(values.locations)]
                        : values.locations instanceof Array
                        ? values.locations.map((loc) => {
                              return parseInt(loc);
                          })
                        : [values.locations.id]
                    : null,
            project: { id: Session.getProjectId() },
            topLevel: values.pageType != null ? true : false,
            type: 'WEBPAGE',
        };

        //SEARCH TYPES
        var prop;
        for (prop in values) {
            if (hasOwnProperty.call(values, prop)) {
                if (prop.startsWith('searchType')) {
                    const selectedTypes =
                        values[prop] != null
                            ? values[prop].constructor === String
                                ? [parseInt(values[prop])]
                                : values[prop] instanceof Array
                                ? values[prop].map((loc) => {
                                      return parseInt(loc);
                                  })
                                : [values[prop].id]
                            : null;

                    if (selectedTypes != null) {
                        searchTypes = searchTypes.concat(selectedTypes);
                    }
                    delete normalized[prop];
                }
            }
        }
        if (searchTypes.length > 0) {
            normalized.searchTypes = searchTypes;
        } else {
            normalized.searchTypes = null;
        }

        return normalized;
    }

    function isCreate() {
        if (hasAnyRole(['ROLE_ADMIN', 'ROLE_PROJECT_ADMIN'])) {
            return props.match.params.id === 'create';
        } else {
            if (props.match.params.id === 'create') {
                history.push('/');
            } else {
                return false;
            }
        }
    }

    function getCustomButtons() {
        let buttons = [];
        if (!isCreate()) {
            if (hasAnyRole(['ROLE_ADMIN'])) {
                buttons.push({
                    text: geti18nText('webpage.copy'),
                    loading: loading,
                    icon: 'copy',
                    style: { marginRight: '1em' },
                    onClick: copyPage,
                });
            }
        }
        return buttons;
    }

    function copyPage() {
        setCopyModalVisible(true);
    }

    function onCopyModalCancel() {
        setCopyModalVisible(false);
    }

    function onAddComponentModalCancel() {
        setAddComponentModalVisible(false);
    }

    function openWebPreview(type) {
        //get code for preview
        setLoading(true);
        requestGet(CONSTANTS_REQ.PROJECT.PREVIEWCODE, undefined, false, false).then((result) => {
            setLoading(false);
            if (result.status == RESPONSE.OK) {
                const projectId = Session.getProjectId();
                let host = window.location.hostname;
                //if (host.startsWith('admin.')) host = host.substr(6);
                let port = window.location.port;
                if (port != '') port = ':' + port;
                let href = window.location.href;
                let root = href.split('/#');
                var testRoot = null;
                if (root.length > 0) {
                    if (root[0].includes(port)) {
                        testRoot = root[0];
                        testRoot = testRoot.replace(window.location.protocol + '//', '');
                    } else {
                        root = root[0].replace(port, '');
                        root = root.replace(window.location.protocol + '//', '');
                    }
                } else {
                    root = host;
                }
                if (process.env.NODE_ENV === 'development') {
                    console.log(
                        'http://localhost:3001/preview/' +
                            result.data +
                            '/#/' +
                            projectId +
                            (pageID ? '/' + pageID : '') +
                            (type != null ? '?type=' + type : '')
                    );
                    var win = window.open(
                        'http://localhost:3001/preview/' +
                            result.data +
                            '/#/' +
                            projectId +
                            (pageID ? '/' + pageID : '') +
                            (type != null ? '?type=' + type : ''),
                        '_blank'
                    );
                    win.focus();
                } else if (testRoot != null) {
                    var win = window.open(
                        window.location.protocol +
                            '//' +
                            testRoot +
                            '/preview/' +
                            result.data +
                            '/#/' +
                            projectId +
                            (pageID ? '/' + pageID : '') +
                            (type != null ? '?type=' + type : ''),
                        '_blank'
                    );
                } else {
                    var win = window.open(
                        window.location.protocol +
                            '//' +
                            root +
                            port +
                            '/preview/' +
                            result.data +
                            '/#/' +
                            projectId +
                            (pageID ? '/' + pageID : '') +
                            (type != null ? '?type=' + type : ''),
                        '_blank'
                    );
                    win.focus();
                }
            }
        });
    }

    const validateAliasName = (rule, value, callback) => {
        if (value) {
            if (value != '' && /^[a-zA-Z0-9-]*$/.test(value) && !value.endsWith('-')) {
                requestGet(
                    CONSTANTS_REQ.WEBPAGE.VALIDATEALIAS + '/' + Session.getProjectId() + '/' + pageID + '/' + value
                ).then((result) => {
                    if (result.status === RESPONSE.OK) {
                        if (result.data != 0) {
                            callback(geti18nText('webpage.edit.alias.unique'));
                        } else {
                            callback();
                        }
                    } else {
                        callback(geti18nText('webpage.edit.alias.unique'));
                    }
                });
            } else {
                callback(geti18nText('webpage.edit.alias.error'));
            }
        } else {
            callback();
        }
    };

   

    

    return (
        <Tabs type="card" activeKey={selectedTab} onChange={(activeKey) => setSelectedTab(activeKey)}>
            <TabPane tab={geti18nText('webpage.edit.tab.settings')} key="settings">
                <DataEdit
                    editHeader={geti18nText('webpage.edit.title')}
                    loading={loading}
                    setLoading={setLoading}
                    url={CONSTANTS_REQ.WEBPAGE.EDIT}
                    shouldCloseModal={shouldCloseModal}
                    setValues={setValues}
                    width={1200}
                    {...props}
                    onSaveAndGetID={pageSaved}
                    onSave={onSave}
                    setDefaultValues={setDefaultValues}
                    buttonsPosition="left"
                    hideCancelButton={true}
                    normalize={normalizeValues}
                    customButtons={getCustomButtons()}
                >
                    <Row gutter={24} type="flex" justify="space-between" align="bottom">
                        <Col span={12}>
                            <Form.Item hidden={true}>
                                {getFieldDecorator('active', { initialValue: 'true' })(<Input hidden={true} />)}
                            </Form.Item>
                            <Form.Item hidden={true}>{getFieldDecorator('id')(<Input hidden={true} />)}</Form.Item>
                            <InputByLanguage
                                fieldName="name"
                                langJson={nameLangJson}
                                label={geti18nText('webpage.edit.name')}
                                {...props}
                            />
                        </Col>
                        <Col span={12}>
                            <Form.Item label={geti18nText('webpage.table.column.alias')}>
                                {getFieldDecorator('aliasName', {
                                    validateTrigger: 'onBlur',
                                    rules: [
                                        {
                                            validator: validateAliasName,
                                        },
                                    ],
                                })(<Input />)}
                            </Form.Item>
                            <Form.Item hidden={true} label={geti18nText('webpage.table.column.order')}>
                                {getFieldDecorator('webPageOrder')(<InputNumber min={0} precision={0} />)}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24} type="flex" justify="space-between" align="bottom">
                        <Col span={12}>
                            <Form.Item label={geti18nText('webpage.edit.categoryType')}>
                                {getFieldDecorator('pageType')(
                                    <SearchField
                                        onChange={onTypeChanged}
                                        url={CONSTANTS_REQ.PAGETYPE.SEARCH}
                                        map={{ id: 'id', label: 'name' }}
                                        searchBy="name"
                                        disabled={false}
                                        translate={true}
                                        addedData={{ projectId: Session.getProjectId(), forPage: true }}
                                    />
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item>
                                {getFieldDecorator('firstPage', {
                                    valuePropName: 'checked',
                                    initialValue: false,
                                })(
                                    <Checkbox onChange={onFirstPageChanged}>
                                        {geti18nText('webpage.edit.isFirstPage')}
                                    </Checkbox>
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={6}></Col>
                    </Row>
                    <Row>
                        <Col span={6}>
                            <Form.Item>
                                {getFieldDecorator('menu', {
                                    valuePropName: 'checked',
                                    initialValue: true,
                                })(<Checkbox>{geti18nText('webpage.edit.showMenu')}</Checkbox>)}
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item>
                                {getFieldDecorator('footer', {
                                    valuePropName: 'checked',
                                    initialValue: true,
                                })(<Checkbox>{geti18nText('webpage.edit.showFooter')}</Checkbox>)}
                            </Form.Item>
                        </Col>
                        <Col span={6}></Col>
                    </Row>
                    <Collapse bordered={false} style={{ marginBottom: '15px' }}>
                        <Panel header={geti18nText('webpage.edit.search.title')} key="1" forceRender={true}>
                            <Row gutter={24}>
                                <Col span={24}>
                                    <Form.Item
                                        label={geti18nText('webpage.edit.search.types')}
                                        style={{ marginBottom: '0px' }}
                                    >
                                        {getFieldDecorator('searchType')(
                                            <SearchField
                                                onChange={onSearchTypeChanged}
                                                mode="multiple"
                                                url={CONSTANTS_REQ.SEARCH_TYPE.SEARCH}
                                                addedSearch={{ field: 'parentId', condition: 'is_null', value: '' }}
                                                map={{ id: 'id', label: 'name' }}
                                                searchBy="name"
                                                disabled={false}
                                                translate={true}
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <SearchTypeGroups form={props.form} pageId={pageID} types={selectedSearchTypes} />
                            <Row gutter={24} style={{ marginTop: '15px' }}>
                                <Col span={24}>
                                    <Form.Item label={geti18nText('webpage.edit.search.location')}>
                                        {getFieldDecorator('locations')(
                                            <SearchField
                                                mode="multiple"
                                                url={CONSTANTS_REQ.LOCATION.SEARCH}
                                                map={{ id: 'id', label: 'name' }}
                                                searchBy="name"
                                                disabled={false}
                                                addedData={{ forPage: true }}
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={24}>
                                    <Form.Item label={geti18nText('webpage.edit.search.stars')}>
                                        {getFieldDecorator('searchStarsNumber')(<Rate />)}
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Panel>
                        <Panel header={geti18nText('webpage.edit.search.page.divider')} key="2" forceRender={true}>
                            <Row gutter={24}>
                                <Col span={24}>
                                    <Form.Item label={geti18nText('webpage.edit.search.image')}>
                                        {getFieldDecorator('searchImage')(
                                            <FileUpload
                                                fileType={CONSTANTS_FILE_TYPE.IMAGE}
                                                map={{ id: 'id', label: 'name' }}
                                                searchBy="name"
                                                disabled={false}
                                                addedData={{ projectId: Session.getProjectId() }}
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={24}>
                                    <InputByLanguage
                                        notRequred
                                        fieldName="searchDescription"
                                        langJson={searchDescriptionJson}
                                        label={geti18nText('webpage.edit.search.description')}
                                        {...props}
                                    />
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={24}>
                                    <Form.Item label={geti18nText('webpage.edit.search.external.link')}>
                                        {getFieldDecorator('searchLink')(<Input />)}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={24}>
                                    <Form.Item label={geti18nText('webpage.edit.search.location.map')}>
                                        <InputMarkerCoordinates required={false} {...props} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={24}>
                                    <InputByLanguage
                                        notRequred
                                        fieldName="price"
                                        langJson={priceJson}
                                        label={geti18nText('webpage.edit.search.price')}
                                        {...props}
                                    />
                                </Col>
                            </Row>
                        </Panel>
                        <Panel header={geti18nText('webpage.edit.SEO.divider')} key="3" forceRender={true}>
                            <Row gutter={24} style={{ marginTop: '15px' }}>
                                <Col span={24}>
                                    <InputByLanguage
                                        fieldName="seoTitle"
                                        langJson={titleLangJson}
                                        label={geti18nText('webpage.edit.SEO.title')}
                                        notRequred
                                        {...props}
                                    />
                                </Col>
                                <Col span={24}>
                                    <InputByLanguage
                                        fieldName="seoKeywords"
                                        langJson={keywordsLangJson}
                                        label={geti18nText('webpage.edit.SEO.keywords')}
                                        notRequred
                                        {...props}
                                    />
                                </Col>
                                <Col span={24}>
                                    <InputByLanguage
                                        fieldName="seoDescription"
                                        langJson={descriptionLangJson}
                                        label={geti18nText('webpage.edit.SEO.description')}
                                        type="textArea"
                                        notRequred
                                        {...props}
                                    />
                                </Col>
                            </Row>
                        </Panel>
                    </Collapse>
                </DataEdit>
                {hasAnyRole(['ROLE_ADMIN']) && (
                    <WebPageCopy visible={copyModalVisible} closeModal={onCopyModalCancel} oldWebPageId={pageID} />
                )}
            </TabPane>
            <TabPane tab={geti18nText('webpage.edit.tab.preview')} key="preview" disabled={!showComponents}>
                <>
                    <Row>
                        <Col span={24}>
                            <Divider orientation="left" style={{ fontSize: '16px' }}>
                                {geti18nText('webpage.edit.content.title')}
                            </Divider>
                        </Col>
                    </Row>
                    <Row gutter={24} style={{ marginBottom: '15px' }}>
                        <Col style={{ textAlign: 'right' }} span={4}>
                            <Button
                                icon="file-add"
                                style={{ marginRight: '15px' }}
                                key="ok"
                                type="primary"
                                onClick={(e) => setAddComponentModalVisible(true)}
                            >
                                {geti18nText('projectManagement.add_new.component')}
                            </Button>
                        </Col>
                        <Col style={{ textAlign: 'right' }} span={4}>
                            <Button
                                icon="cloud"
                                style={{ marginRight: '15px' }}
                                key="ok"
                                type="default"
                                onClick={(e) => openWebPreview('web')}
                            >
                                {Session.getProjectInfo().forKiosk && Session.getProjectInfo().forKiosk === true
                                    ? geti18nText('projectManagement.preview.web')
                                    : geti18nText('projectManagement.preview')}
                            </Button>
                        </Col>
                        {Session.getProjectInfo().forKiosk && Session.getProjectInfo().forKiosk === true && (
                            <Col style={{ textAlign: 'right' }} span={4}>
                                <Button
                                    icon="cloud"
                                    style={{ marginRight: '15px' }}
                                    key="ok"
                                    type="default"
                                    onClick={(e) => openWebPreview()}
                                >
                                    {geti18nText('projectManagement.preview.kiosk')}
                                </Button>
                            </Col>
                        )}
                    </Row>
                    <Row style={{ padding: '15px 15px 15px 0px' }}>
                        <Col span={24} style={{ boxShadow: '5px 11px 17px 2px rgba(0,0,0,0.55)' }}>
                            <Page
                                id={pageID}
                                refresh={refreshPagePreview}
                                firstPage={initFirstPage}
                                scrollToComponent={scrollToComponent}
                            />
                        </Col>
                    </Row>
                </>

                <WebPageComponentAdd
                    visible={addComponentModalVisible}
                    closeModal={onAddComponentModalCancel}
                    webPageId={pageID}
                    onSelectComponent={onSelectComponent}
                />
            </TabPane>
            <TabPane tab={geti18nText('webpage.edit.tab.local.users')} key="users" disabled={!showComponents}>
                <AccountList webPageId={pageID}></AccountList>
            </TabPane>
            <TabPane tab={geti18nText('webpage.edit.tab.news')} key="news" disabled={!showComponents}>
                <BulletinList webPageId={pageID}></BulletinList>
            </TabPane>
            <TabPane tab={geti18nText('webpage.edit.tab.page.list')} key="listLinks" disabled={!showComponents}>
                <WebPageLinks description={description} title={title} pageID={pageID} {...props} />
            </TabPane>
        </Tabs>
    );
});

const SearchTypeGroups = (props) => {
    const { getFieldDecorator, setFieldsValue } = props.form;
    const [groups, setGroups] = useState([]);
    useEffect(() => {
        if (props.types && props.types.length > 0) {
            requestGet(CONSTANTS_REQ.SEARCH_TYPE.CHILDREN + '?parents=' + props.types.join(',')).then((result) => {
                if (result.status === RESPONSE.OK) {
                    setGroups(result.data);
                    loadData(result.data);
                } else {
                    setGroups([]);
                }
            });
        } else {
            setGroups([]);
        }
    }, [props.types, props.pageId]);

    function loadData(groups) {
        for (const group of groups) {
            requestGet(CONSTANTS_REQ.SEARCH_TYPE.FORPAGEBYGROUP + '/' + props.pageId + '/' + group.id).then(
                (result) => {
                    if (result.status === RESPONSE.OK) {
                        setFieldsValue({
                            ['searchType-' + group.id]: result.data.map((loc) => {
                                return { id: loc.id + '', text: loc.name };
                            }),
                        });
                    }
                }
            );
        }
    }

    if (groups.length == 0) {
        return <></>;
    } else {
        return groups.map((stype, index) => (
            <Row gutter={24} key={index}>
                <Col span={23} offset={1}>
                    <Form.Item label={getTranslated(stype.name)} style={{ marginBottom: '0px' }}>
                        {getFieldDecorator('searchType-' + stype.id)(
                            <SearchField
                                mode="multiple"
                                url={CONSTANTS_REQ.SEARCH_TYPE.SEARCH}
                                addedSearch={{ field: 'parentId', condition: 'equals', value: stype.id }}
                                map={{ id: 'id', label: 'name' }}
                                searchBy="name"
                                disabled={false}
                                translate={true}
                            />
                        )}
                    </Form.Item>
                </Col>
            </Row>
        ));
    }
};

export default WebPageEdit;
