import React from 'react';
import { Icon } from 'antd';
import { DataTable, getColumnSearch, getColumnSearchOption } from '../../../components/DataTable';
import PageTypeEdit from './PageTypeEdit';
import { CONSTANTS_REQ } from '../../../utils/Constants';
import { Session, ENUMS, geti18nText } from '../../../utils/Session';
import { getTranslated } from '../../../components/InputByLanguage';

const PageTypeList = (props) => {
    function setDefaultFilterValue() {
        return [
            { field: 'active', condition: 'equals_bool', value: 1 },
            { field: 'forMap', condition: 'equals_bool', value: 1 },
        ];
    }
    return (
        <DataTable
            headerTitle={geti18nText('page.type.table.header')}
            url={CONSTANTS_REQ.PAGETYPE.LIST}
            addNewButtonText={geti18nText('page.type.table.add')}
            showRecordModal={true}
            modalComponent={PageTypeEdit}
            setDefaultFilterValue={setDefaultFilterValue}
            addedData={{ projectId: Session.getProjectId() }}
            additionalValues="onlyMap"
            columns={[
                {
                    title: geti18nText('page.type.table.column.id'),
                    dataIndex: 'id',
                    width: '10%',
                    sorter: (a, b) => {},
                    ...getColumnSearch('number'),
                },
                {
                    title: geti18nText('page.type.table.column.name'),
                    dataIndex: 'name',
                    sorter: (a, b) => {},
                    ...getColumnSearch('string'),
                    render: (text, record) => {
                        return <div>{getTranslated(text)}</div>;
                    },
                },
                /*{
                    title: geti18nText('page.type.table.column.forPage'),
                    dataIndex: 'forPage',
                    sorter: (a, b) => {},
                    render: (text, record) => {
                        if (record.forPage) {
                            return <Icon type="check" style={{ color: 'green' }} />;
                        } else {
                            return <span></span>;
                        }
                    },
                },
                {
                    title: geti18nText('page.type.table.column.forMap'),
                    dataIndex: 'forMap',
                    sorter: (a, b) => {},
                    render: (text, record) => {
                        if (record.forMap) {
                            return <Icon type="check" style={{ color: 'green' }} />;
                        } else {
                            return <span></span>;
                        }
                    },
                },*/
                {
                    title: geti18nText('page.type.table.column.typeOrder'),
                    dataIndex: 'typeOrder',
                    sorter: (a, b) => {},
                    ...getColumnSearch('string'),
                },
                {
                    title: geti18nText('page.type.table.column.active'),
                    dataIndex: 'active',
                    width: '10%',
                    render: (text, record) => {
                        if (record.active) {
                            return <Icon type="check" style={{ color: 'green' }} />;
                        } else {
                            return <Icon type="close" style={{ color: 'red' }} />;
                        }
                    },
                    ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', setDefaultFilterValue()[0]['value']),
                    defaultFilteredValue: setDefaultFilterValue(),
                },
            ]}
        />
    );
};

export default PageTypeList;
