import React from "react";
import { Icon } from "antd";
import { DataTable, getColumnSearch, getColumnSearchOption } from "../../../components/DataTable";
import FaqCategoryEdit from "./FaqCategoryEdit";
import { CONSTANTS_REQ } from "../../../utils/Constants";
import { Session, ENUMS, geti18nText } from "../../../utils/Session";
import { getTranslated } from '../../../components/InputByLanguage';




const FaqCategoryList = (props) => {
    function setDefaultFilterValue() {
        return [{ field: "active", condition: "equals_bool", value: 1 },{ field: "type", condition: "equals", value: "0" }];
    }
    return (
        <DataTable
            headerTitle={geti18nText("faqCategory.table.header")}
            url={CONSTANTS_REQ.CATEGORY.LIST}
            addedData={{ projectId: Session.getProjectId() }}
            addNewButtonText={geti18nText("faqCategory.table.add")}
            showRecordModal={true}
            modalComponent={FaqCategoryEdit}
            setDefaultFilterValue={setDefaultFilterValue}
            columns={[
                {
                    title: geti18nText("faqCategory.table.column.id"),
                    dataIndex: "id",
                    width: "10%",
                    sorter: (a, b) => {},
                    ...getColumnSearch("number"),
                },
                {
                    title: geti18nText('faqCategory.table.column.name'),
                    dataIndex: 'name',
                    sorter: (a, b) => {},
                    ...getColumnSearch('string'),
                    render: (text, record) => {
                        return <div>{getTranslated(text)}</div>;
                    },
                    
                },
                
                
                {
                    title: geti18nText("faqCategory.table.column.active"),
                    dataIndex: "active",
                    width: "10%",
                    render: (text, record) => {
                        if (record.active) {
                            return <Icon type="check" style={{ color: "green" }} />;
                        } else {
                            return <Icon type="close" style={{ color: "red" }} />;
                        }
                    },
                    ...getColumnSearchOption(ENUMS.ACTIVE(), "equals_bool", setDefaultFilterValue()[0]["value"]),
                    defaultFilteredValue: setDefaultFilterValue(),
                },
            ]}
        />
    );
};

export default FaqCategoryList;
