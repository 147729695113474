import React, { useState } from 'react';
import { Form, Row, Col, Input, InputNumber, Radio } from 'antd';
import { geti18nText } from '../../../utils/Session';
import { InputByLanguage, translationsToObject } from '../../../components/InputByLanguage';
import { DataEdit } from '../../../components/DataEdit';
import { CONSTANTS_REQ } from '../../../utils/Constants';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { refreshMenu } from '../../../slices/menuSlice';

const SearchTypeEdit = Form.create({ name: 'SearchTypeEdit' })((props) => {
    const [editHeader, setEditHeader] = useState(geti18nText('search.type.edit.header'));
    const { getFieldDecorator, setFieldsValue } = props.form;
    const [contextTextLangJson, setContextTextLangJson] = useState(null);
    const [loading, setLoading] = useState(false);
    let history = useHistory();
    const dispatch = useDispatch();

    function setDefaultValues() {
        if (props.match.params.id == 'create') setEditHeader(geti18nText('search.type.edit.new'));
        props.form.resetFields();
        setContextTextLangJson(null);
    }

    function setValues(type) {
        type = translationsToObject(type, 'name', type.name);
        setContextTextLangJson(type.name);
        delete type.active;
        delete type.name;
        delete type.main;
        setFieldsValue(type);
    }

    function onSave() {
        dispatch(
            refreshMenu({
                refreshLastNode: true,
                refreshProjectNode: false,
                refreshNodes: [],
            })
        );
    }

    function onSaveAndGetId(id) {
        history.push(history.location.pathname.substr(0, history.location.pathname.lastIndexOf('/')) + '/' + id);
    }

    return (
        <DataEdit
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            setDefaultValues={setDefaultValues}
            setValues={setValues}
            url={CONSTANTS_REQ.SEARCH_TYPE.EDIT}
            onSaveAndGetID={onSaveAndGetId}
            onSave={onSave}
            buttonsPosition="left"
            hideCancelButton={true}
            {...props}
            normalize={(values) => {
                return {
                    ...values,
                    parentId: props.match.params.parentId == 0 ? null : props.match.params.parentId,
                };
            }}
        >
            <Row gutter={24}>
                <Col span={24} style={{ width: '100%' }}>
                    <Form.Item hidden={true}>{getFieldDecorator('id')(<Input hidden={true} />)}</Form.Item>
                    <Form.Item hidden={true}>{getFieldDecorator('parentId')(<Input hidden={true} />)}</Form.Item>
                    <InputByLanguage
                        fieldName="name"
                        label={geti18nText('search.type.edit.name')}
                        langJson={contextTextLangJson}
                        {...props}
                    />
                    <Form.Item label={geti18nText('search.type.edit.searchAs')}>{getFieldDecorator('searchAs', { initialValue: 'or' })(
                                                                                    <Radio.Group>
                                                                                        <Radio value={"or"}>{geti18nText('search.type.edit.or')}</Radio>
                                                                                        <Radio value={"and"}>{geti18nText('search.type.edit.and')}</Radio>
                                                                                    </Radio.Group>
                                                                                )}
                    </Form.Item>
                    <Form.Item hidden={true} label={geti18nText('search.type.table.column.order')}>
                        {getFieldDecorator('typeOrder')(<InputNumber min={0} precision={0} />)}
                    </Form.Item>
                </Col>
            </Row>
        </DataEdit>
    );
    //}
});

export default SearchTypeEdit;
