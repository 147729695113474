import React from "react";
import { Route, Redirect } from "react-router-dom";

import { Session } from "./Session";

export const ProtectedRoute = ({ component: Component, ...args }) => {
    return (
        <Route
            {...args}
            render={props => {
                if (Session.isUserAuthenticated()) {
                    return <Component {...props} />;
                } else {
                    return <Redirect to={{ pathname: "/login", state: { from: props.location } }} />;
                }
            }}
        />
    );
};
