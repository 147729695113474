import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Form, Modal, Button, message, Typography, Icon, List, Avatar, Skeletonn, Input, Radio } from 'antd';
import { CONSTANTS_REQ } from '../../../utils/Constants';
import { Session, geti18nText } from '../../../utils/Session';
import { RESPONSE, requestGet } from '../../../utils/RequestResolver';
import WebPageComponentPreview from './WebPageComponentPreview';

const { Text } = Typography;
const { Search } = Input;
const WebPageComponentAdd = Form.create({ name: 'WebPageComponentAdd' })((props) => {
    const [data, setData] = useState(Session.getAppValue('COMPONENTS'));
    const [searchTextComponentText, setSearchComponentText] = useState(null);
    const COMPTYPES = Session.getAppValue('COMPTYPES').concat(['all']);
    const [defaultComptype, setDefaultComptype] = useState('all');
    const [componentPreviewModalVisible, setComponentModalVisible] = useState(false);
    const [selectedComponentPreview, setSelectedComponentPreview] = useState(null);
    const searchRef = useRef();

    useEffect(() => {
        setSearchComponentText(null);
        setDefaultComptype('all');
        if (searchRef.current != null) {
            searchRef.current.focus();
        }
    }, [props.visible]);

    useEffect(() => {
        var selectedComponents = Session.getAppValue('COMPONENTS').filter(async (fObj) => {
            const name = geti18nText('component.' + fObj.name);
            fObj.textName = name;
            fObj.types = fObj.type ? fObj.type.split(';') : [];
            return fObj;
        });

        selectedComponents = selectedComponents.filter(
            (e) =>
                (searchTextComponentText == null ||
                    searchTextComponentText == '' ||
                    e.textName?.toLowerCase().includes(searchTextComponentText.toLowerCase())) &&
                !e.componentFooterMenu
        );

        if (defaultComptype != 'all') {
            selectedComponents = selectedComponents.filter((e) => e.types.includes(defaultComptype));
        }

        setData(selectedComponents);
    }, [searchTextComponentText, defaultComptype]);

    const onSelectListItem = (item) => {
        item.key = item.id;
        item.label = item.textName;
        props.onSelectComponent(item);
        closeModal();
    };

    const onSearchTextChange = (e) => {
        const value = e.target.value;
        setSearchComponentText(value);
    };

    const onChangeFilter = (e) => {
        if (defaultComptype == e.target.value) {
            setDefaultComptype('all');
            return;
        }
        setDefaultComptype(e.target.value);
    };

    const onComponentPreview = (e, item) => {
        e.stopPropagation();
        setComponentModalVisible(true);
        setSelectedComponentPreview(item);
    };

    const onPreviewComponentModalCancel = (e) => {
        setComponentModalVisible(false);
        setTimeout(() => {
            onSelectListItem(selectedComponentPreview);
            setSelectedComponentPreview(null);
        }, 500);
    };

    const closeModal = () => {
        props.closeModal();
        setSearchComponentText(null);
        setDefaultComptype('all');
    };

    return (
        <>
            <Modal
                visible={props.visible}
                onCancel={closeModal}
                width={1000}
                title={geti18nText('webpage.edit.component.add')}
                footer={[<Button onClick={(e) => closeModal()}>{geti18nText('app.default.button.cancel')}</Button>]}
            >
                {' '}
                <Row gutter={24} style={{ marginBottom: '15px' }}>
                    <Col span={10}>
                        <Search
                            style={{ marginLeft: 8, marginRight: 4 }}
                            placeholder={geti18nText('webpage.edit.search.components')}
                            onChange={onSearchTextChange}
                            value={searchTextComponentText}
                            autoFocus={true}
                            ref={searchRef}
                        />
                    </Col>
                    <Col span={14}>
                        <Radio.Group defaultValue={defaultComptype} value={defaultComptype} onChange={onChangeFilter}>
                            {COMPTYPES.map((element, index) => {
                                return (
                                    <Radio.Button key={index} value={element} onClick={onChangeFilter}>
                                        {geti18nText(`component.type.${element}`)}
                                    </Radio.Button>
                                );
                            })}
                        </Radio.Group>
                    </Col>
                </Row>
                <Row>
                    <List
                        itemLayout="horizontal"
                        dataSource={data}
                        renderItem={(item) => (
                            <List.Item
                                actions={[
                                    <Button
                                        icon="search"
                                        style={{ marginRight: '15px' }}
                                        key="ok"
                                        type="default"
                                        onClick={(e) => onComponentPreview(e, item)}
                                    >
                                        {geti18nText('webpage.edit.search.components.details')}
                                    </Button>,
                                ]}
                                onClick={(e) => onSelectListItem(item)}
                            >
                                <ComponentItemWithImage item={item}></ComponentItemWithImage>
                            </List.Item>
                        )}
                    />
                </Row>
            </Modal>
            <WebPageComponentPreview
                visible={componentPreviewModalVisible}
                closeModal={() => setComponentModalVisible(false)}
                component={selectedComponentPreview}
                selectComponent={onPreviewComponentModalCancel}
            ></WebPageComponentPreview>
        </>
    );
});

const ComponentItemWithImage = (props) => {
    const [image, setImage] = useState(null);

    useEffect(() => {
        if (props != null && props.item != null) {
            if (props.item.image1 != null) {
                setImage(props.item.image1);
            } else {
                const result = requestGet(
                    CONSTANTS_REQ.COMPONENT.DOWNLOAD + props.item.id + '/' + 1,
                    null,
                    false,
                    true
                ).then((result) => {
                    props.item.image1 =
                        result.status === RESPONSE.OK && result.data && result.data.file.size != 0
                            ? window.URL.createObjectURL(result.data.file)
                            : null;
                    setImage(props.item.image1);
                });
            }
        }
    }, [props.item]);

    return (
        <>
            <List.Item.Meta
                avatar={
                    image ? (
                        <img style={{ maxHeight: 'auto', width: '200px' }} src={image} />
                    ) : (
                        <div style={{ height: 'auto', width: '200px' }}></div>
                    )
                }
                title={props.item.textName}
                description={geti18nText('component.' + props.item.name + '.desc')}
            />
        </>
    );
};
export default WebPageComponentAdd;
