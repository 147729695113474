import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    previewType: 'details',
};

const directoryPreviewSlices = createSlice({
    name: 'directoryPreviewSlices',
    initialState,
    reducers: {
        selectPreviewType(state, action) {
            const { previewType } = action.payload;
            state.previewType = previewType;
        },
    },
});

export const { selectPreviewType } = directoryPreviewSlices.actions;

export default directoryPreviewSlices.reducer;
