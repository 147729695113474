import { Col, Form, Row, Input, Radio } from 'antd';
import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { SearchField } from '../../../components//Search';
import { DataEdit } from '../../../components/DataEdit';
import { refreshMenu } from '../../../slices/menuSlice';
import { CONSTANTS_REQ } from '../../../utils/Constants';
import { geti18nText, Session } from '../../../utils/Session';
import { InputByLanguage, translationsToObject } from '../../../components/InputByLanguage';
import { requestGet, RESPONSE } from '../../../utils/RequestResolver';
import { selectPageType } from '../../../slices/webPageSlice';

const WebPageExternalAdd = Form.create({ name: 'WebPageEdit' })((props) => {
    const LINK_TYPE = {
        LINK: 'LINK',
        PROJECT: 'LINK-PROJECT',
    };
    const { getFieldDecorator, setFieldsValue } = props.form;
    const [loading, setLoading] = useState(false);
    const [closeWebPageModeal, setCloseWebPageModeal] = useState(false); // kod kreiranje novog page-a, prvi save ne zatvara popup - prikazuje komponente stranica
    const [pageID, setPageID] = useState(0);
    const { selectedPageType } = useSelector((state) => state.webPageSlices);
    const [menuPageType, setMenuPageType] = useState(0);
    const [nameLangJson, setNameLangJson] = useState(undefined);
    const [linkType, setLinkType] = useState(LINK_TYPE.LINK);
    const [linkedProject, setLinkedProject] = useState(null);
    const [selectedProjectId, setSelectedProjectId] = useState(0);
    const [projectSearchDisabled, setProjectSearchDisabled] = useState(false);
    const [pageSearchDisabled, setPageSearchDisabled] = useState(true);
    const dispatch = useDispatch();
    let history = useHistory();
    const projectRef = useRef();
    const { themeShortName } = useSelector((state) => state.projectSlices);
    function shouldCloseModal() {
        return closeWebPageModeal;
    }

    function pageSaved(page) {
        setFieldsValue(page);
    }

    function setValues(webPage) {
        var a = themeShortName;
        webPage = translationsToObject(webPage, 'name', webPage.name);
        setNameLangJson(webPage.name);
        delete webPage.name;
        setMenuPageType(webPage.pageType.id);
        setPageID(webPage.id);
        if (webPage.type == LINK_TYPE.PROJECT) {
            setLinkType(LINK_TYPE.PROJECT);
            let obj = JSON.parse(webPage.searchLink);
            setSelectedProjectId(obj.projectId);
            setProject(obj.projectId);
            setLinkedProject(obj.pr);
            if (obj.webPageId) {
                setPageSearchDisabled(false);
                setWebPage(obj.webPageId);
            }
            delete webPage.searchLink;
            delete webPage.page;
        } else {
        }
        delete webPage.firstPage;
        delete webPage.topLevel;
        delete webPage.project;
        delete webPage.pageType;
        console.log(webPage);
        setFieldsValue(webPage);
    }

    function setDefaultValues() {
        setLinkedProject(null);
        setLinkType(LINK_TYPE.LINK);
        props.form.resetFields();
        setNameLangJson(undefined);
        setMenuPageType(parseInt(selectedPageType));
        dispatch(
            selectPageType({
                selectedPageType: 0,
            })
        );
    }

    function normalizeValues(values) {
        let normalized = null;
        normalized = {
            ...values,
            pageType: { id: parseInt(menuPageType) },
            project: { id: Session.getProjectId() },
            // type: 'LINK',
            locations: null,
            searchTypes: null,
        };
        if (normalized.type == LINK_TYPE.PROJECT && normalized.projectLink != undefined) {
            let obj = {
                projectId:
                    normalized.projectLink instanceof Object ? normalized.projectLink.id : normalized.projectLink,
                webPageId:
                    normalized.firstPageId != undefined
                        ? normalized.firstPageId instanceof Object
                            ? normalized.firstPageId.id
                            : normalized.firstPageId
                        : null,
            };
            normalized.searchLink = JSON.stringify(obj);
        } else {
            normalized.type = LINK_TYPE.LINK;
        }
        delete normalized.firstPageId;
        delete normalized.projectLink;

        return normalized;
    }

    function setProject(projectId) {
        requestGet(CONSTANTS_REQ.PROJECT.EDIT + '/' + projectId).then((result) => {
            if (result.status === RESPONSE.OK) {
                setFieldsValue({ projectLink: { id: projectId, text: result.data.name } });
            }
        });
    }
    function setWebPage(webPageId) {
        requestGet(CONSTANTS_REQ.WEBPAGE.EDIT + '/' + webPageId).then((result) => {
            if (result.status === RESPONSE.OK) {
                setFieldsValue({ firstPageId: { id: webPageId, name: result.data.name } });
            }
        });
    }

    function onSaveGetId(id) {
        setPageID(id);
        history.push('/webPage/externalLink/' + id);
    }

    function onSave() {
        let refreshNodes = [];
        refreshNodes.push('TYPE-' + menuPageType);
        dispatch(
            refreshMenu({
                refreshLastNode: false,
                refreshProjectNode: false,
                refreshNodes: refreshNodes,
            })
        );
    }
    function onSelectComponentProject(component) {
        if (component) {
            setFieldsValue({ firstPageId: undefined });
            setPageSearchDisabled(false);
            setSelectedProjectId(parseInt(component.key));
        } else {
            setFieldsValue({ firstPageId: undefined });
            if (!pageSearchDisabled) setPageSearchDisabled(true);
        }
    }
    function onSelectPage(page) {
        if (!page) {
            page = null;
        }
    }

    return (
        <DataEdit
            editHeader={geti18nText('webpage.page.external.link.header')}
            loading={loading}
            setLoading={setLoading}
            url={CONSTANTS_REQ.WEBPAGE.EDIT}
            shouldCloseModal={shouldCloseModal}
            setValues={setValues}
            setDefaultValues={setDefaultValues}
            width={1200}
            {...props}
            onSaveAndGetData={pageSaved}
            onSaveAndGetID={onSaveGetId}
            buttonsPosition="left"
            hideCancelButton={true}
            onSave={onSave}
            normalize={normalizeValues}
        >
            <Row gutter={24} type="flex" justify="space-between" align="bottom">
                <Col span={12}>
                    <Form.Item hidden={true}>
                        {getFieldDecorator('active', { initialValue: 'true' })(<Input hidden={true} />)}
                    </Form.Item>
                    <Form.Item hidden={true}>{getFieldDecorator('id')(<Input hidden={true} />)}</Form.Item>
                    <InputByLanguage
                        fieldName="name"
                        langJson={nameLangJson}
                        label={geti18nText('webpage.edit.name')}
                        {...props}
                    />
                </Col>
            </Row>
            {themeShortName === 'nytourist' && linkedProject == null && (
                <Row gutter={24} type="flex" justify="space-between" align="bottom">
                    <Col span={12}>
                        <Form.Item>
                            {getFieldDecorator('type', { initialValue: LINK_TYPE.LINK })(
                                <Radio.Group
                                    onChange={(e) => {
                                        setLinkType(e.target.value);
                                    }}
                                >
                                    <Radio value={LINK_TYPE.LINK}>
                                        {geti18nText('webpage.edit.search.external.radio_button.link')}
                                    </Radio>
                                    <Radio value={LINK_TYPE.PROJECT}>
                                        {geti18nText('webpage.edit.search.external.radio_button.project')}
                                    </Radio>
                                </Radio.Group>
                            )}
                        </Form.Item>
                    </Col>
                </Row>
            )}

            {linkType == LINK_TYPE.LINK ? (
                <Row gutter={24} type="flex" justify="space-between" align="bottom">
                    <Col span={12}>
                        <Form.Item label={geti18nText('webpage.edit.search.external.link')}>
                            {getFieldDecorator('searchLink')(<Input />)}
                        </Form.Item>
                    </Col>
                </Row>
            ) : (
                <Row gutter={24} type="flex" justify="space-between" align="bottom">
                    <Col span={12}>
                        <Form.Item label={geti18nText('webpage.page.linked.project')}>
                            {getFieldDecorator('projectLink', {
                                rules: [{ required: true, message: geti18nText('app.default.required') }],
                            })(
                                <SearchField
                                    onChange={onSelectComponentProject}
                                    url={CONSTANTS_REQ.PROJECT.SEARCH}
                                    map={{ id: 'id', label: 'text' }}
                                    searchBy="name"
                                    disabled={projectSearchDisabled}
                                    translate={false}
                                />
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label={geti18nText('webpage.page.linked.project.first_page')}>
                            {getFieldDecorator('firstPageId')(
                                <SearchField
                                    url={CONSTANTS_REQ.WEBPAGE.SEARCH}
                                    map={{ id: 'id', label: 'name' }}
                                    searchBy="name"
                                    onChange={onSelectPage}
                                    disabled={pageSearchDisabled}
                                    translate={true}
                                    addedData={{ projectId: selectedProjectId, forPage: true }}
                                />
                            )}
                        </Form.Item>
                    </Col>
                </Row>
            )}
        </DataEdit>
    );
});

export default WebPageExternalAdd;
