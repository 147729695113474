import React from 'react';
import { Layout } from 'antd';
import { Redirect, Switch, Route } from 'react-router-dom';

import { Session, geti18nText, hasAnyRole } from '../../utils/Session';
import { AdminMainMenu } from './AdminMainMenu';
import { AdminMainHeader } from './AdminMainHeader';
import { adminMainRoutes } from './AdminMainRoutes';
import { NotFound } from '../../views/error/Error';
import { LocalUserMenu } from './LocalUserMenu';
import './main.css';

export const AdminMain = (props) => {
    const { Content } = Layout;

    function generateRoutes(routes) {
        let retValue = [];
        let route;
        for (route of routes) {
            if (hasAnyRole(route.role)) {
                retValue.push(
                    <Route
                        key={route.path}
                        path={route.path}
                        exact={route.exact}
                        strict={route.strict}
                        component={route.component}
                        {...props}
                    />
                );
            }
        }
        return retValue;
    }

    if (!Session.isUserAuthenticated()) {
        return <Redirect to="/login" />;
    } else {
        return (
            <Layout>
                <AdminMainHeader />
                <Layout>
                    {hasAnyRole(['ROLE_ADMIN', 'ROLE_PROJECT_ADMIN']) && <AdminMainMenu />}
                    {!hasAnyRole(['ROLE_ADMIN']) && hasAnyRole(['ROLE_LOCAL_USER', 'ROLE_CONTENT_UPLOAD']) && (
                        <LocalUserMenu />
                    )}
                    <Layout
                        className="main-layout"
                        style={{
                            marginLeft: hasAnyRole([
                                'ROLE_ADMIN',
                                'ROLE_CUSTOMER',
                                'ROLE_LOCAL_USER',
                                'ROLE_CONTENT_UPLOAD',
                                'ROLE_PROJECT_ADMIN',
                                'ROLE_MOBILE_APP_DOWNLOAD',
                            ])
                                ? ''
                                : '0px',
                        }}
                    >
                        <Content className="main-content thin-scrollbar">
                            <Switch>
                                {generateRoutes(adminMainRoutes)}
                                <Route
                                    path="*"
                                    render={(props) => (
                                        <NotFound {...props} errorMessage={geti18nText('app.default.401')} />
                                    )}
                                />
                            </Switch>
                        </Content>
                    </Layout>
                </Layout>
            </Layout>
        );
    }
};
