import React, { useState, useEffect } from 'react';
import { Form, Row, Col, Button, Divider, Input, notification } from 'antd';
import { Session, geti18nText } from '../../utils/Session';
import { normalizeComponents } from '../../utils/Utils';
import { requestGet, requestPut, RESPONSE } from '../../utils/RequestResolver';
import { InputByLanguage, translationsToObject } from '../InputByLanguage';
import { Spinner } from '../../components/Spinner';
import { CONSTANTS_FILE_TYPE, CONSTANTS_REQ } from '../../utils/Constants';
import { FileUpload } from '../fileUpload/FileUpload';
import { SearchField } from '../../components/Search';
import { ColorPicker } from '../../components/ColorPicker';
const PdfPreviewEdit = Form.create({ name: 'VideoBlockEdit' })((props) => {
    const { getFieldDecorator, setFieldsValue } = props.form;
    const [fetching, setFetching] = useState(false);
    const [formValues, setFormValues] = useState(null);
    const [textJson, setTextJson] = useState(null);
    const [linkTextJson, setLinkTextJson] = useState(null);
    const [type, setType] = useState('pdf');
    useEffect(() => {
        fetch();
    }, [props.visible]);

    useEffect(() => {
        props.form.resetFields();
        setFieldsValue(formValues);
    }, [formValues]);

    function fetch() {
        setFetching(true);
        requestGet(CONSTANTS_REQ.WEBPAGECOMPONENT.EDIT + '/' + props.id).then((result) => {
            setFetching(false);
            if (result.status === RESPONSE.OK) {
                setValues(result.data);
            }
        });
    }

    function setValues(component_data) {
        var json;
        if (component_data.json) {
            json = JSON.parse(component_data.json);

            json = translationsToObject(json, 'title', json.title);
            setTextJson(json.title);
            delete json.title;
            json = translationsToObject(json, 'linkText', json.linkText);
            setLinkTextJson(json.linkText);
            delete json.linkText;
            setType(json.type);
            json.type = types.find(({ id }) => id === json.type);
            setFormValues(json);
        }
    }

    function showNotification(title, message, duration, type) {
        notification[type]({
            message: title,
            description: message,
            duration: duration,
        });
    }

    function okNotification() {
        showNotification(geti18nText('app.default.save.ok'), geti18nText('app.default.save.ok.desc'), 3, 'success');
    }

    function error(message) {
        showNotification(geti18nText('app.default.save.nok'), message, 3, 'error');
    } 


    function save(e, close) {
        e.preventDefault();
        props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                values = normalizeComponents(values);
                if (values.pdf) values.pdf = normalizeFileUpload('pdf');
                values.type = values.type != null && values.type.constructor === String ? values.type : values.type.id;

                requestPut(CONSTANTS_REQ.WEBPAGECOMPONENT.UPDATEJSON + '/' + props.id, null, {
                    id: props.id,
                    json: JSON.stringify(values),
                }).then((result) => {
                    if (result && result.status === RESPONSE.CREATED) {
                        console.log('okNotification');
                        okNotification();
                        close && props.closePopup();
                    } else {
                        error();
                        if (result && result.status === RESPONSE.BAD_REQUEST) {
                            if (result.data && result.data.field) {
                                switch (result.data.field) {
                                    case 'mandatory_fields_required':
                                        console.log(geti18nText('app.default.mandatory_fields_required'));
                                        return;
                                    default:
                                        break;
                                }
                            }
                        }
                        if (result.data && result.data.error) {
                            console.log(JSON.stringify(result.data.error));
                        }
                    }
                });
            }
        });
    }

    function normalizeFileUpload(field) {
        let file = props.form.getFieldsValue([field])[field];
        if (file instanceof Array) {
            file = file[0];
        }
        return file.id ? file : { file_path: file.file_path, name: file.name };
    }

    function onSelectTypeChange(value) {
        if (value) {
            setType(value.key);
        }
    }

    const types = [
        { id: 'pdf', text: geti18nText('component.pdfpreview.type.pdf') },
        { id: 'video', text: geti18nText('component.pdfpreview.type.video') },
    ];

    if (fetching) {
        return <Spinner />;
    } else {
        return (
            <React.Fragment>
                <Divider>{geti18nText('component.pdfpreview')}</Divider>
                <Form>
                    <Row style={{ marginTop: '20px ' }}>
                        <Col span={24}>
                            <InputByLanguage
                                fieldName={'title'}
                                label={geti18nText('component.pdfpreview.edit.title')}
                                langJson={textJson}
                                {...props}
                            />
                            <InputByLanguage
                                notRequred
                                fieldName={'linkText'}
                                label={geti18nText('component.pdfpreview.edit.linktitle')}
                                langJson={linkTextJson}
                                {...props}
                            />
                            <Form.Item label={geti18nText('component.pdfpreview.edit.link')}>
                                {getFieldDecorator('link')(<Input />)}
                            </Form.Item>
                            <Form.Item label={geti18nText('component.pdfpreview.edit.type')}>
                                {getFieldDecorator('type', {
                                    rules: [{ required: true, message: geti18nText('app.default.required') }],
                                    initialValue: { id: 'pdf', text: geti18nText('component.pdfpreview.type.pdf') },
                                })(<SearchField options={types} onChange={onSelectTypeChange} />)}
                            </Form.Item>
                            {type == 'pdf' && (
                                <Form.Item label={geti18nText('component.pdfpreview.edit.pdf')}>
                                    {getFieldDecorator('pdf', {
                                        rules: [{ required: true, message: geti18nText('app.default.required') }],
                                    })(
                                        <FileUpload
                                            fileType={CONSTANTS_FILE_TYPE.OTHER}
                                            map={{ id: 'id', label: 'name' }}
                                            searchBy="name"
                                            disabled={false}
                                            addedData={{
                                                projectId: Session.getProjectId(),
                                                fileType: CONSTANTS_FILE_TYPE.OTHER,
                                            }}
                                        />
                                    )}
                                </Form.Item>
                            )}
                            {type == 'video' && (
                                <Form.Item label={geti18nText('component.pdfpreview.edit.videoLink')}>
                                    {getFieldDecorator('videoLink')(<Input />)}
                                </Form.Item>
                            )}
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24} style={{ textAlign: 'right', marginTop: '1rem' }}>
                            <Button style={{ marginRight: '1em' }} onClick={(e) => props.closePopup()}>
                                {geti18nText('app.default.button.cancel')}
                            </Button>
                            <Button style={{ marginRight: '1em' }} type="primary" onClick={(e) => save(e, false)}>
                                {geti18nText('app.default.button.save')}
                            </Button>
                            <Button
                                type="primary"
                                style={{ background: '#31b531', borderColor: '#31b531', textColor: 'white' }}
                                onClick={(e) => {
                                    save(e, true);
                                }}
                            >
                                {geti18nText('app.default.button.save.close')}
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </React.Fragment>
        );
    }
});

export default PdfPreviewEdit;
