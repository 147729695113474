import React from 'react';
import { Icon } from 'antd';
import { DataTable, getColumnSearch, getColumnSearchOption } from '../../../components/DataTable';

import { CONSTANTS_REQ } from '../../../utils/Constants';
import { Session, ENUMS, geti18nText } from '../../../utils/Session';
import ReportedproblemEdit from './ReportedProblemEdit';

const ReportedProblemList = (props) => {
    function setDefaultFilterValue() {
        return [{ field: 'project_id', condition: 'equals', value: Session.getProjectId() }];
    }

    return (
        <DataTable
            headerTitle={geti18nText('reported.problem.header')}
            url={CONSTANTS_REQ.REPORTED_PROBLEM.LIST}
            hideButtons={true}
            showRecordModal={true}
            modalComponent={ReportedproblemEdit}
            setDefaultFilterValue={setDefaultFilterValue}
            columns={[
                {
                    title: geti18nText('reported.problem.table.column.id'),
                    dataIndex: 'id',
                    width: '10%',
                    sorter: (a, b) => {},
                    ...getColumnSearch('number'),
                },
                {
                    title: geti18nText('reported.problem.table.column.sender.name'),
                    dataIndex: 'senderName',
                    sorter: (a, b) => {},
                    ...getColumnSearch('string'),
                },
                {
                    title: geti18nText('reported.problem.table.column.sender.mail'),
                    dataIndex: 'senderMail',
                    sorter: (a, b) => {},
                    ...getColumnSearch('string'),
                },
                {
                    title: geti18nText('reported.problem.table.column.type'),
                    dataIndex: 'reportType',
                    sorter: (a, b) => {},
                    ...getColumnSearch('string'),
                },
                {
                    title: geti18nText('reported.problem.table.column.description'),
                    dataIndex: 'problemDescription',
                    sorter: (a, b) => {},
                    ...getColumnSearch('string'),
                },

                {
                    title: geti18nText('reported.problem.edit.address'),
                    dataIndex: 'address',
                    sorter: (a, b) => {},
                    ...getColumnSearch('string'),
                },
            ]}
        />
    );
};

export default ReportedProblemList;
