import React from "react";
import { Icon } from "antd";
import { DataTable, getColumnSearch, getColumnSearchOption } from "../../../components/DataTable";
import DiskFileEdit from "./DiskFileEdit";
import { CONSTANTS_REQ } from "../../../utils/Constants";
import { Session, ENUMS, geti18nText } from "../../../utils/Session";
import moment from "moment";
import DiskFileUpload from "./DiskFileUpload";
import { FormatFileSize } from "../../../utils/Utils";

const DiskFileList = (props) => {
    function setDefaultFilterValue() {
        return [{ field: "active", condition: "equals_bool", value: 1 }];
    }

    return (
        <DataTable
            headerTitle={geti18nText("disk.file.table.header")}
            url={CONSTANTS_REQ.DISK_FILE.LIST}
            addNewButtonText={geti18nText("disk.file.table.add")}
            addedButtons={DiskFileUpload}
            hideNewButton={true}
            showRecordModal={true}
            modalComponent={DiskFileEdit}
            setDefaultFilterValue={setDefaultFilterValue}
            addedData={{ projectId: Session.getProjectId() }}
            columns={[
                {
                    title: geti18nText("disk.file.table.column.id"),
                    dataIndex: "id",
                    width: "10%",
                    sorter: (a, b) => {},
                    ...getColumnSearch("number"),
                },
                {
                    title: geti18nText("disk.file.table.column.name"),
                    dataIndex: "name",
                    sorter: (a, b) => {},
                    ...getColumnSearch("string"),
                },
                {
                    title: geti18nText("disk.file.table.column.size"),
                    dataIndex: "size",
                    render: (text, record) => {
                        return FormatFileSize(record.size);
                    },
                    ...getColumnSearch("string"),
                },
                {
                    title: geti18nText("disk.file.table.column.dateCreated"),
                    dataIndex: "dateCreated",
                    sorter: (a, b) => {},
                    ...getColumnSearch("string"),
                    render: (text, record) => {
                        if (record.dateCreated) {
                            return moment(record.dateCreated).format("DD.MM.YYYY HH:mm:ss");
                        }
                    },
                },
                {
                    title: geti18nText("disk.file.table.column.createdBy"),
                    dataIndex: "createdBy.username",
                    sorter: (a, b) => {},
                    ...getColumnSearch("string"),
                },
                {
                    title: geti18nText("disk.file.table.column.active"),
                    dataIndex: "active",
                    width: "10%",
                    render: (text, record) => {
                        if (record.active) {
                            return <Icon type="check" style={{ color: "green" }} />;
                        } else {
                            return <Icon type="close" style={{ color: "red" }} />;
                        }
                    },
                    ...getColumnSearchOption(ENUMS.ACTIVE(), "equals_bool", setDefaultFilterValue()[0]["value"]),
                    defaultFilteredValue: setDefaultFilterValue(),
                },
            ]}
        />
    );
};

export default DiskFileList;
