import React, { useState, useEffect } from 'react';
import { Form, Row, Col, Input, Button, Divider, notification } from 'antd';
import { Session, geti18nText } from '../../utils/Session';
import { requestGet, requestPut, RESPONSE } from '../../utils/RequestResolver';
import { CONSTANTS_FILE_TYPE, CONSTANTS_REQ } from '../../utils/Constants';
import { FileUpload } from '../fileUpload/FileUpload';
import { InputByLanguage, translationsToObject } from '../InputByLanguage';
import { normalizeJSONForComponent } from '../../utils/ComponentHelper';
import { normalizeComponents } from '../../utils/Utils';

const ImageWithTitleAndDescriptionEdit = Form.create({ name: 'ImageWithTitleAndDescriptionEdit' })((props) => {
    const [fetching, setFetching] = useState(false);
    const { getFieldDecorator, setFieldsValue } = props.form;
    const [savedData, setSavedData] = useState({});
    const [titleLangJson, setTitleLangJson] = useState(undefined);
    const [contentBeforeLangJson, setContentBeforeLangJson] = useState(undefined);
    const [contentAfterLangJson, setContentAfterLangJson] = useState(undefined);
    const [headerDividerLangJson, setHeaderDividerLangJson] = useState(undefined);
    const [pathLangJson, setPathLangJson] = useState(undefined);
    const [descriptionLangJson, setDescriptionLangJson] = useState(undefined);

    useEffect(() => {
        fetch();
    }, [props.visible]);

    function fetch() {
        setFetching(true);
        requestGet(CONSTANTS_REQ.WEBPAGECOMPONENT.EDIT + '/' + props.id).then((result) => {
            setFetching(false);
            if (result.status === RESPONSE.OK) {
                setValues(result.data);
            } else {
                console.log('response failed');
            }
        });
    }

    function setValues(component_data) {
        var addedData = component_data;
        var json;
        if (component_data.json) {
            json = JSON.parse(component_data.json);
            delete addedData.json;
        }
        delete addedData.active;
        setSavedData(addedData);
        var populate_data = {};
        var diskFiles;
        if (json) {
            //image
            populate_data = translationsToObject(json, 'image-description', JSON.stringify(json.image.description));
            setDescriptionLangJson(JSON.stringify(json.image.description));
            diskFiles = json.image.path ? json.image.path : null;
            populate_data['image-file_path'] = { key: json.image.file_path, file_path: json.image.file_path };
            delete populate_data.image;
            //other data
            populate_data = translationsToObject(json, 'title', JSON.stringify(json.title));
            populate_data = translationsToObject(json, 'content_before', JSON.stringify(json.content_before));
            populate_data = translationsToObject(json, 'content_after', JSON.stringify(json.content_after));
            setTitleLangJson(JSON.stringify(json.title));
            setContentBeforeLangJson(JSON.stringify(json.content_before));
            setContentAfterLangJson(JSON.stringify(json.content_after));
            delete populate_data.title;
            delete populate_data.content_before;
            delete populate_data.content_after;
            delete populate_data.show_divider;
        }
        populate_data.id = component_data.id;
        setFieldsValue(populate_data);

        if (diskFiles) {
            requestGet(CONSTANTS_REQ.DISK_FILE.SEARCH, {
                search: encodeURI(
                    JSON.stringify([{ field: 'id', condition: 'IN_PARSE_LONG', value: diskFiles.toString() }])
                ),
            }).then((result) => {
                setFetching(false);
                if (result.status === RESPONSE.OK) {
                    if (result.data instanceof Array && result.data.length > 0) {
                        var imageAndVideoData = {};
                        imageAndVideoData['image-file_path'] = { id: result.data[0].id, name: result.data[0].text };
                        props.form.setFieldsValue(imageAndVideoData);
                    }
                } else {
                    console.log('response failed');
                }
            });
        }
    }

    function showNotification(title, message, duration, type) {
        notification[type]({
            message: title,
            description: message,
            duration: duration,
        });
    }

    function okNotification() {
        showNotification(geti18nText('app.default.save.ok'), geti18nText('app.default.save.ok.desc'), 3, 'success');
    }

    function error(message) {
        showNotification(geti18nText('app.default.save.nok'), message, 3, 'error');
    } 

    function save(e, close) {
        e.preventDefault();
        props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                values = normalize(values);
                requestPut(CONSTANTS_REQ.WEBPAGECOMPONENT.UPDATEJSON + '/' + props.id, null, {
                    id: props.id,
                    json: values.json,
                }).then((result) => {
                    if (result && result.status === RESPONSE.CREATED) {
                        console.log('okNotification');
                        okNotification();
                        close && props.closePopup();
                    } else {
                        error();
                        if (result && result.status === RESPONSE.BAD_REQUEST) {
                            if (result.data && result.data.field) {
                                switch (result.data.field) {
                                    case 'mandatory_fields_required':
                                        console.log(geti18nText('app.default.mandatory_fields_required'));
                                        return;
                                    default:
                                        break;
                                }
                            }
                        }
                        if (result.data && result.data.error) {
                            console.log(JSON.stringify(result.data.error));
                        }
                    }
                });
            }
        });
    }

    function normalize(values) {
        let jsonFieldGroups = ['image', 'title', 'content_before', 'content_after', 'header_divider'];
        let jsonFieldKeys = { image: ['file_path', 'description'] };
        values = normalizeComponents(values);
        return normalizeJSONForComponent(values, undefined, jsonFieldGroups, jsonFieldKeys, props);
    }

    return (
        <React.Fragment>
            <Divider>{geti18nText('component.imagewithtitleanddescription')}</Divider>
            <Form>
                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item hidden={true}>{getFieldDecorator('id')(<Input hidden={true} />)}</Form.Item>
                        <InputByLanguage
                            fieldName="title"
                            label={geti18nText('component.imagewithtitleandescription.edit.title')}
                            langJson={titleLangJson}
                            {...props}
                        />
                        <InputByLanguage
                            fieldName="image-description"
                            label={geti18nText('component.imagewithtitleandescription.edit.description')}
                            notRequred={true}
                            langJson={descriptionLangJson}
                            {...props}
                        />
                        <Form.Item label={geti18nText('component.imagewithtitleandescription.edit.image')}>
                            {getFieldDecorator('image-file_path', {
                                rules: [{ required: true, message: geti18nText('app.default.required') }],
                            })(
                                <FileUpload
                                    fileType={CONSTANTS_FILE_TYPE.IMAGE}
                                    map={{ id: 'id', label: 'name' }}
                                    searchBy="name"
                                    disabled={false}
                                    addedData={{ projectId: Session.getProjectId() }}
                                />
                            )}
                        </Form.Item>
                        <InputByLanguage
                            fieldName="content_before"
                            label={geti18nText('component.imagewithtitleandescription.edit.content_before')}
                            notRequred={true}
                            langJson={contentBeforeLangJson}
                            minHeight={'250px'}
                            {...props}
                            type="rteEditor"
                        />
                        <InputByLanguage
                            fieldName="content_after"
                            label={geti18nText('component.imagewithtitleandescription.edit.content_after')}
                            notRequred={true}
                            langJson={contentAfterLangJson}
                            minHeight={'250px'}
                            {...props}
                            type="rteEditor"
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={24} style={{ textAlign: 'right', marginTop: '1rem' }}>
                        <Button style={{ marginRight: '1em' }} onClick={(e) => props.closePopup()}>
                            {geti18nText('app.default.button.cancel')}
                        </Button>
                        <Button style={{ marginRight: '1em' }} type="primary" onClick={(e) => save(e, false)}>
                            {geti18nText('app.default.button.save')}
                        </Button>
                        <Button
                            type="primary"
                            style={{ background: '#31b531', borderColor: '#31b531', textColor: 'white' }}
                            onClick={(e) => {
                                save(e, true);
                            }}
                        >
                            {geti18nText('app.default.button.save.close')}
                        </Button>
                    </Col>
                </Row>
            </Form>
        </React.Fragment>
    );
});

export default ImageWithTitleAndDescriptionEdit;
