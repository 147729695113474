import React, { useEffect, useState } from 'react';
import { Icon } from 'antd';
import { DataTable, getColumnSearch, getColumnSearchOption } from '../../../components/DataTable';
import { CONSTANTS_REQ } from '../../../utils/Constants';
import { ENUMS, geti18nText } from '../../../utils/Session';
import DomainEdit from './DomainEdit';
const DomainList = (props) => {
    function setDefaultFilterValue() {
        return [{ field: 'active', condition: 'equals_bool', value: 1 }];
    }

    useEffect(() => {}, []);

    return (
        <DataTable
            headerTitle={geti18nText('domain.table.header')}
            url={CONSTANTS_REQ.DOMAIN.LIST}
            addNewButtonText={geti18nText('domain.table.add')}
            // slaje koji modal se otvara
            showRecordModal={true}
            modalComponent={DomainEdit}
            setDefaultFilterValue={setDefaultFilterValue}
            columns={[
                {
                    title: geti18nText('domain.table.column.id'),
                    dataIndex: 'id',
                    width: '10%',
                    sorter: (a, b) => {},
                    ...getColumnSearch('number'),
                },
                {
                    title: geti18nText('domain.table.column.name'),
                    dataIndex: 'name',
                    sorter: (a, b) => {},
                    ...getColumnSearch('string'),
                },
                {
                    title: geti18nText('domain.table.column.certificatePath'),
                    dataIndex: 'certificatePath',
                    sorter: (a, b) => {},
                    ...getColumnSearch('string'),
                },
                {
                    title: geti18nText('domain.table.column.privateKeyPath'),
                    dataIndex: 'privateKeyPath',
                    sorter: (a, b) => {},
                    ...getColumnSearch('string'),
                },
                {
                    title: geti18nText('domain.table.column.active'),
                    dataIndex: 'active',
                    width: '10%',
                    render: (text, record) => {
                        if (record.active) {
                            return <Icon type="check" style={{ color: 'green' }} />;
                        } else {
                            return <Icon type="close" style={{ color: 'red' }} />;
                        }
                    },
                    ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', setDefaultFilterValue()[0]['value']),
                    defaultFilteredValue: setDefaultFilterValue(),
                },
            ]}
        />
    );
};

export default DomainList;
