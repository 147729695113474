import React, { useState, useEffect } from 'react';
import { Form, Row, Col, Input, Button, Divider, Radio, notification } from 'antd';
import { Session, geti18nText } from '../../utils/Session';
import { requestGet, requestPut, RESPONSE } from '../../utils/RequestResolver';
import { CONSTANTS_FILE_TYPE, CONSTANTS_REQ } from '../../utils/Constants';
import { FileUpload } from '../fileUpload/FileUpload';
import { InputByLanguage, translationsToObject } from '../InputByLanguage';
import { SearchField, mapDataToSelect } from '../../components/Search';
import { normalizeJSONForComponent, getDiskFiles } from '../../utils/ComponentHelper';
import { normalizeComponents } from '../../utils/Utils';
import QRCode from 'qrcode.react';

const ImageWithTextEdit = Form.create({ name: 'ImageWithTextEdit' })((props) => {
    const LINK_TYPE = {
        LINK: 'LINK',
        WEBPAGE: 'WEBPAGE',
    };
    const [fetching, setFetching] = useState(false);
    const { getFieldDecorator, setFieldsValue } = props.form;
    const [savedData, setSavedData] = useState({});
    const [titleLangJson, setTitleLangJson] = useState(undefined);
    const [descriptionLangJson, setDescriptionLangJson] = useState(undefined);
    const [imageDescriptionLangJson, setImageDescriptionLangJson] = useState(undefined);
    const [readMoreTextLangJson, setReadMoreTextLangJson] = useState(undefined);
    const [imageTitleLangJson, setImageTitleLangJson] = useState(undefined);
    const [imagePathLangJson, setImagePathLangJson] = useState(undefined);
    const [imageLinkLangJson, setLinkPathLangJson] = useState(undefined);
    const [linkType, setLinkType] = useState(LINK_TYPE.WEBPAGE);
    const [qrCodeValue, setQrCodeValue] = useState('');
    const [showQRCode, setShowQRCode] = useState(false);
    const [externalLink, setExternalLink] = useState('');
    const [imageLink, setImageLink] = useState({});

    useEffect(() => {
        fetch();
    }, [props.visible]);

    useEffect(() => {
        if (externalLink !== undefined && externalLink !== '') {
            setQrCodeValue(externalLink);
            setShowQRCode(true);
        } else {
            setQrCodeValue('');
            setShowQRCode(false);
        }
    }, [externalLink]);

    function fetch() {
        setFetching(true);
        requestGet(CONSTANTS_REQ.WEBPAGECOMPONENT.EDIT + '/' + props.id).then((result) => {
            setFetching(false);
            if (result.status === RESPONSE.OK) {
                setValues(result.data);
            } else {
                console.log('response failed');
            }
        });
    }

    function setValues(component_data) {
        var addedData = component_data;
        var json;
        if (component_data.json) {
            json = JSON.parse(component_data.json);
            delete addedData.json;
        }
        delete addedData.active;
        setSavedData(addedData);
        var populate_data = {};
        var diskFiles = {};
        var webPages = {};
        if (json) {
            //image
            populate_data = translationsToObject(json, 'image-description', JSON.stringify(json.image.description));
            setImageDescriptionLangJson(JSON.stringify(json.image.description));
            diskFiles['image-file_path'] = json.image.path ? json.image.path : null;
            populate_data['image-file_path'] = json.image.file_path
                ? { key: json['image'].file_path, file_path: json['image'].file_path }
                : null;
            //webPages["image-link"] = json.image.link ? json.image.link : null;
            console.log(json);
            if (json.type !== undefined && json.type === LINK_TYPE.LINK) {
                setLinkType(LINK_TYPE.LINK);
                if (json.externalLink !== '') {
                    setExternalLink(json.externalLink);
                } else {
                    setExternalLink('');
                }
            } else {
                setLinkType(LINK_TYPE.WEBPAGE);
                if (json.image.link !== undefined) {
                    setWepPageValue('image-link', parseInt(json.image.link));
                }
            }

            delete populate_data.image;
            //other data
            populate_data = translationsToObject(json, 'main-title', JSON.stringify(json['main-title']));
            setTitleLangJson(JSON.stringify(json['main-title']));
            populate_data = translationsToObject(json, 'title', JSON.stringify(json.title));
            setImageTitleLangJson(JSON.stringify(json.title));
            populate_data = translationsToObject(json, 'description', JSON.stringify(json.description));
            setDescriptionLangJson(JSON.stringify(json.description));
            populate_data = translationsToObject(json, 'read-more-button', JSON.stringify(json['read-more-button']));
            setReadMoreTextLangJson(JSON.stringify(json['read-more-button']));
            populate_data.align = types.find(({ id }) => id === json.align);
            delete populate_data['main-title'];
            delete populate_data.title;
            delete populate_data.description;
        }
        populate_data.id = component_data.id;
        setFieldsValue(populate_data);
        // getDiskFiles(CONSTANTS_REQ.DISK_FILE.LIST, diskFiles, props);
        // getDiskFiles(CONSTANTS_REQ.WEBPAGE.LIST, webPages, props);
    }

    function setWepPageValue(key, id) {
        requestGet(CONSTANTS_REQ.WEBPAGE.EDIT + '/' + id).then((result) => {
            if (result.status === RESPONSE.OK) {
                setFieldsValue({ [key]: { id: id, name: result.data.name } });
                setImageLink({ id: id, name: result.data.name });
            }
        });
    }

    function showNotification(title, message, duration, type) {
        notification[type]({
            message: title,
            description: message,
            duration: duration,
        });
    }

    function okNotification() {
        showNotification(geti18nText('app.default.save.ok'), geti18nText('app.default.save.ok.desc'), 3, 'success');
    }

    function error(message) {
        showNotification(geti18nText('app.default.save.nok'), message, 3, 'error');
    } 

    function save(e, close) {
        e.preventDefault();
        props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                if (values.align)
                    values.align =
                        values.align != null && values.align.constructor === String
                            ? values.align
                            : values.align.id
                            ? values.align.id
                            : values.align.key;
                values.externalLink = externalLink;
                values = normalize(values);

                requestPut(CONSTANTS_REQ.WEBPAGECOMPONENT.UPDATEJSON + '/' + props.id, null, {
                    id: props.id,
                    json: values.json,
                }).then((result) => {
                    if (result && result.status === RESPONSE.CREATED) {
                        console.log('okNotification');
                        okNotification();
                        close && props.closePopup();
                    } else {
                        error();
                        if (result && result.status === RESPONSE.BAD_REQUEST) {
                            if (result.data && result.data.field) {
                                switch (result.data.field) {
                                    case 'mandatory_fields_required':
                                        console.log(geti18nText('app.default.mandatory_fields_required'));
                                        return;
                                    default:
                                        break;
                                }
                            }
                        }
                        if (result.data && result.data.error) {
                            console.log(JSON.stringify(result.data.error));
                        }
                    }
                });
            }
        });
    }

    function normalize(values) {
        let jsonFieldGroups = [
            'image',
            'main-title',
            'title',
            'description',
            'read-more-button',
            'align',
            'type',
            'externalLink',
        ];
        let jsonFieldKeys = { image: ['file_path', 'description', 'link'] };
        values = normalizeComponents(values);
        return normalizeJSONForComponent(values, undefined, jsonFieldGroups, jsonFieldKeys, props);
    }

    const types = [
        { id: 'bottom', text: geti18nText('component.imagewithtext.edit.align.bottom') },
        { id: 'center', text: geti18nText('component.imagewithtext.edit.align.center') },
        { id: 'top', text: geti18nText('component.imagewithtext.edit.align.top') },
    ];

    return (
        <React.Fragment>
            <Divider>{geti18nText('component.imagewithtext')}</Divider>
            <Form>
                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item hidden={true}>{getFieldDecorator('id')(<Input hidden={true} />)}</Form.Item>
                        <InputByLanguage
                            notRequred
                            fieldName="main-title"
                            label={geti18nText('component.imagewithtext.edit.main-title')}
                            langJson={titleLangJson}
                            {...props}
                        />
                        <InputByLanguage
                            notRequred
                            fieldName="title"
                            label={geti18nText('component.imagewithtext.edit.title')}
                            langJson={imageTitleLangJson}
                            {...props}
                        />
                        <InputByLanguage
                            fieldName="description"
                            label={geti18nText('component.imagewithtext.edit.description')}
                            notRequred={true}
                            langJson={descriptionLangJson}
                            minHeight={'250px'}
                            {...props}
                            type="rteEditor"
                        />
                        <InputByLanguage
                            notRequred
                            fieldName="image-description"
                            label={geti18nText('component.imagewithtext.edit.image-description')}
                            notRequred={true}
                            langJson={imageDescriptionLangJson}
                            {...props}
                        />
                        <Form.Item label={geti18nText('component.imagewithtext.edit.image')}>
                            {' '}
                            {getFieldDecorator('image-file_path', {
                                rules: [{ required: true, message: geti18nText('app.default.required') }],
                            })(
                                <FileUpload
                                    fileType={CONSTANTS_FILE_TYPE.IMAGE}
                                    map={{ id: 'id', label: 'name' }}
                                    searchBy="name"
                                    disabled={false}
                                    addedData={{ projectId: Session.getProjectId() }}
                                />
                            )}
                        </Form.Item>
                        <Form.Item label={geti18nText('component.imagewithtext.edit.align')}>
                            {getFieldDecorator('align')(<SearchField options={types} />)}
                        </Form.Item>
                        <Form.Item>
                            {getFieldDecorator('type', { initialValue: LINK_TYPE.WEBPAGE })(
                                <Radio.Group
                                    onChange={(e) => {
                                        setLinkType(e.target.value);
                                    }}
                                >
                                    <Radio value={LINK_TYPE.WEBPAGE}>
                                        {geti18nText('component.imagewithtext.edit.radio_button.webPage')}
                                    </Radio>
                                    <Radio value={LINK_TYPE.LINK}>
                                        {geti18nText('component.imagewithtext.edit.radio_button.link')}
                                    </Radio>
                                </Radio.Group>
                            )}
                        </Form.Item>
                        {linkType == LINK_TYPE.LINK ? (
                            <Row gutter={24} type="flex" justify="space-between" align="bottom">
                                <Col span={16}>
                                    <Form.Item label={geti18nText('webpage.edit.search.external.link')}>
                                        <Input
                                            value={externalLink}
                                            onChange={(event) => {
                                                setExternalLink(event.target.value);
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                                {showQRCode && (
                                    <Col xs={{ span: 4 }} md={{ span: 4 }} style={{ margin: 'auto' }}>
                                        <QRCode size={128} value={qrCodeValue} />
                                    </Col>
                                )}
                            </Row>
                        ) : (
                            <Form.Item label={geti18nText('component.imagewithtext.edit.webpage')}>
                                {getFieldDecorator('image-link', { initialValue: imageLink })(
                                    <SearchField
                                        url={CONSTANTS_REQ.WEBPAGE.SEARCH}
                                        map={{ id: 'id', label: 'name' }}
                                        searchBy="name"
                                        disabled={false}
                                        translate={true}
                                        addedData={{ projectId: Session.getProjectId(), forMap: true }}
                                        onChange={(value) => {
                                            setImageLink(value);
                                        }}
                                    />
                                )}
                            </Form.Item>
                        )}
                        <InputByLanguage
                            notRequred
                            fieldName="read-more-button"
                            label={geti18nText('app.default.button.read_more.text')}
                            notRequred={true}
                            langJson={readMoreTextLangJson}
                            {...props}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={24} style={{ textAlign: 'right', marginTop: '1rem' }}>
                        <Button style={{ marginRight: '1em' }} onClick={(e) => props.closePopup()}>
                            {geti18nText('app.default.button.cancel')}
                        </Button>
                        <Button style={{ marginRight: '1em' }} type="primary" onClick={(e) => save(e, false)}>
                            {geti18nText('app.default.button.save')}
                        </Button>
                        <Button
                            type="primary"
                            style={{ background: '#31b531', borderColor: '#31b531', textColor: 'white' }}
                            onClick={(e) => {
                                save(e, true);
                            }}
                        >
                            {geti18nText('app.default.button.save.close')}
                        </Button>
                    </Col>
                </Row>
            </Form>
        </React.Fragment>
    );
});

export default ImageWithTextEdit;
