import React, { useState } from 'react';
import { Form, Row, Col, Input, InputNumber, Tabs } from 'antd';
import { CONSTANTS_REQ, CONSTANTS_FILE_TYPE } from '../../../utils/Constants';
import { DataEdit } from '../../../components/DataEdit';
import { InputByLanguage, translationsToObject } from '../../../components/InputByLanguage';
import { geti18nText } from '../../../utils/Session';
import { SearchField } from '../../../components/Search';
import FacilityList from './FacilityList';
import { refreshMenu } from '../../../slices/menuSlice';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FileUpload } from '../../../components/fileUpload/FileUpload';

const { TabPane } = Tabs;
const FacilityGroupEdit = Form.create({ name: 'FacilityGroupEdit' })((props) => {
    const { getFieldDecorator, setFieldsValue } = props.form;
    const [editHeader, setEditHeader] = useState(geti18nText('facility.group.edit.new'));
    const [loading, setLoading] = useState(false);
    const [nameJson, setNameJson] = useState(null);
    const [formValues, setFormValues] = useState(null);
    const [mainFacility, setMainFacility] = useState(false);
    let history = useHistory();
    const dispatch = useDispatch();

    function isFacility() {
        return (
            props.location.addedData && props.location.addedData.parentId && props.location.addedData.parentId != null
        );
    }

    function setDefaultValues() {
        if (props.match.params.id == 'create') {
            setEditHeader(geti18nText('facility.group.edit.new'));
        }
        props.form.resetFields();
        setNameJson(null);
        setMainFacility(false);
    }

    function setValues(data) {
        data = translationsToObject(data, 'name', data.name);
        setNameJson(data.name);
        delete data.name;
        delete data.active;

        if (data.icon != null) {
            const imagePath = {
                file_path: data.icon,
                name: data.icon.substring(data.icon.lastIndexOf('\\') + 1, data.icon.length),
            };
            data.icon = imagePath;
        }
        else {
            delete data.icon;
        }

        if (data.searchContentType) {
            data.searchType = data.searchContentType.map((sct) => {
                return { id: sct.id + '', text: sct.name };
            });
            delete data.searchContentType;
        } else {
            setFieldsValue({ searchType: undefined });
        }

        setMainFacility((data.main != null && data.main == true) ? true : false);
        delete data.main;

        setFormValues(data);
        setFieldsValue(data);
    }

    function onSave() {
        dispatch(
            refreshMenu({
                refreshLastNode: true,
                refreshProjectNode: false,
                refreshNodes: ['FACILITYGROUPS', 'FACILITYGROUP', 'FACILITY'],
            })
        );
    }

    function onSaveAndGetId(id) {
        let pathName = history.location.pathname.substr(0, history.location.pathname.lastIndexOf('/')) + '/' + id;
        history.push(
            isFacility() ? { pathname: pathName, addedData: { parentId: props.location.addedData.parentId } } : pathName
        );
    }

    return (
        <>
            {mainFacility === true && (
                <DataEdit
                    editHeader={editHeader}
                    loading={loading}
                    setLoading={setLoading}
                    url={CONSTANTS_REQ.FACILITY.EDIT}
                    setValues={setValues}
                    width={1200}
                    {...props}
                    onSave={onSave}
                    onSaveAndGetID={onSaveAndGetId}
                    buttonsPosition="left"
                    hideCancelButton={true}
                    normalize={(values) => {
                        const mediaPath = '/media/0';
                        return {
                            ...values,
                            main: true,
                            icon:
                                values.icon != null && values.icon instanceof Object
                                    ? ((values.icon.file_path.includes('media_client') || values.icon.file_path.includes(mediaPath)) ? values.icon.file_path : mediaPath + values.icon.file_path)
                                    : ((values.icon.includes('media_client') || values.icon.includes(mediaPath)) ? values.icon : mediaPath + values.icon),
                            parent: 
                                values.parent != null && values.parent instanceof Object
                                    ? values.parent
                                    : values.parent != null ? { id: values.parent } 
                                    : null,
                        };
                    }}
                >
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item hidden={true}>{getFieldDecorator('id')(<Input hidden={true} />)}</Form.Item>
                            <Form.Item label={geti18nText('facility.edit.name')}>
                                <InputByLanguage
                                    disabled={loading}
                                    fieldName="name"
                                    langJson={nameJson}
                                    {...props}
                                />
                            </Form.Item>
                            <Form.Item label={geti18nText('facility.edit.image')}>
                                {' '}
                                {getFieldDecorator('icon', {
                                    rules: [{ required: true, message: geti18nText('app.default.required') }],
                                })(
                                    <FileUpload
                                        fileType={CONSTANTS_FILE_TYPE.IMAGE}
                                        map={{ id: 'id', label: 'name' }}
                                        searchBy="name"
                                        disabled={false}
                                        addedData={{ projectId: undefined, staticMedia: true }}
                                    />
                                )}
                            </Form.Item>
                            <Form.Item label={geti18nText('facility.group.edit.new')}>
                                {getFieldDecorator('parent')(
                                    <SearchField
                                        url={CONSTANTS_REQ.FACILITY.GROUPS_SEARCH}
                                        map={{ id: 'id', label: 'name' }}
                                        searchBy="name"
                                        disabled={false}
                                        addedData={{ active: true }}
                                        translate={true}
                                    />
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                </DataEdit>
            )}
            {mainFacility !== true && (
                <DataEdit
                    editHeader={editHeader}
                    loading={loading}
                    setLoading={setLoading}
                    setDefaultValues={setDefaultValues}
                    url={CONSTANTS_REQ.FACILITY.EDIT}
                    setValues={setValues}
                    width={1200}
                    {...props}
                    onSave={onSave}
                    onSaveAndGetID={onSaveAndGetId}
                    buttonsPosition="left"
                    hideCancelButton={true}
                    normalize={(values) => {
                        return {
                            ...values,
                            main: false,
                            searchTypes:
                                values.searchType != null
                                    ? values.searchType.constructor === String
                                        ? [parseInt(values.searchType)]
                                        : values.searchType instanceof Array
                                        ? values.searchType.map((st) => {
                                            return parseInt(st);
                                        })
                                        : [values.searchType.id]
                                    : null,
                            parent: isFacility() ? { id: props.location.addedData.parentId } : null,
                        };
                    }}
                >
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item hidden={true}>{getFieldDecorator('id')(<Input hidden={true} />)}</Form.Item>
                            <Form.Item label={geti18nText('facility.group.edit.name')}>
                                <InputByLanguage
                                    disabled={loading}
                                    fieldName="name"
                                    label={geti18nText('facility.group.edit.name')}
                                    langJson={nameJson}
                                    {...props}
                                />
                            </Form.Item>
                            <Form.Item label={geti18nText('facility.group.edit.search.types')} hidden={isFacility()}>
                                {getFieldDecorator('searchType', {
                                    rules: [
                                        {
                                            required: isFacility() ? false : true,
                                            message: geti18nText('app.default.required'),
                                        },
                                    ],
                                })(
                                    <SearchField
                                        mode="multiple"
                                        url={CONSTANTS_REQ.SEARCH_TYPE.SEARCH}
                                        map={{ id: 'id', label: 'name' }}
                                        addedSearch={{ field: 'parentId', condition: 'is_null', value: '' }}
                                        searchBy="name"
                                        disabled={false}
                                        translate={true}
                                    />
                                )}
                            </Form.Item>
                            <Form.Item hidden={true} label={geti18nText('facility.group.edit.order')}>
                                {getFieldDecorator('facilityOrder')(<InputNumber min={0} precision={0} />)}
                            </Form.Item>
                        </Col>
                    </Row>
                </DataEdit>
            )}
        </>
    );
});

export default FacilityGroupEdit;
