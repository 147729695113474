import { Button, Col, DatePicker, Divider, Form, Input, Modal, notification, Radio, Row, Table, Icon,Popconfirm } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { SearchField } from '../../components/Search';
import { Spinner } from '../../components/Spinner';
import { CONSTANTS_REQ } from '../../utils/Constants';
import { requestGet, requestPut, RESPONSE } from '../../utils/RequestResolver';
import { geti18nText, Session } from '../../utils/Session';
import { getSelectedLanguage } from '../../utils/Utils';
import { getTranslated, InputByLanguage, translationsToObject } from '../InputByLanguage';

const CalendarEdit = Form.create({ name: 'CalendarEdit' })((props) => {
    const LINK_TYPE = {
        LINK: 'LINK',
        WEBPAGE: 'WEBPAGE',
    };
    const { getFieldDecorator, getFieldValue, setFieldsValue } = props.form;
    const [fetching, setFetching] = useState(true);
    const [formValues, setFormValues] = useState(null);
    const [addNew, setAddNew] = useState(false);
    const [linkType, setLinkType] = useState(props.type ? props.type : LINK_TYPE.WEBPAGE);
    const [isEdit, setIsEdit] = useState(false);
    const [selectedRowId, setSelectedRowId] = useState(undefined);
    const [dateFrom, setDateFrom] = useState(null);
    const [dateTo, setDateTo] = useState(null);
    const [selectedProjectId, setSelectedProjectId] = useState(props.project ? props.project : Session.getProjectId());
    const [descriptionJson, setDescriptionJson] = useState(null);
    const [nameJson, setNameJson] = useState(null);
    const [events, setEvents] = useState([]);
      const columns = [
        {
          title: geti18nText('component.calendar.column.name'),
          dataIndex: 'name',
          render: (text, record) => {
            if (record.name) {
                return <div>{getTranslated(record.name, getSelectedLanguage()).replace(
                    /(<? *script)/gi,
                    'illegalscript'
                )}</div>;
            }
          },
        },
        {
          title: geti18nText('component.calendar.column.dateFrom'),
          dataIndex: 'dateFrom',
          render: (text, record) => {
            if (record.dateFrom) {
                return moment(record.dateFrom).format(
                    'DD.MM.YYYY'
                );
            }
          },
          
        },
        {
          title: geti18nText('component.calendar.column.dateTo'),
          dataIndex: 'dateTo',
          render: (text, record) => {
            if (record.dateTo) {
                return moment(record.dateTo).format(
                    'DD.MM.YYYY'
                );
            }
          },
          
        },
        {
          title: geti18nText('component.calendar.startTime'),
          dataIndex: 'startTime',
          
          
        },
        {
            title: '',
            dataIndex: '',
            key: 'x',
            render: (text, record) => 
            <Popconfirm
                            key={'delete'}
                            placement="top"
                            title={geti18nText('component.calendar.delete.confirm')}
                            onConfirm={(e) => {
                                deleteEvent(record)
                            }}
                            okText={geti18nText('app.default.button.yes')}
                            cancelText={geti18nText('app.default.button.no')}
                        >
            <Button><Icon  style={{fontSize:'15px', color: 'red'}} type="delete" /></Button>
            </Popconfirm> ,
          },
      ];


    useEffect(() => {
        fetch();
    }, [props.visible]);

    useEffect(() => {
        props.form.resetFields();
        if(formValues){
            let json=formValues;
            if(json.dateFrom) {
                let dateFromTmp=moment(json.dateFrom)
                json.dateFrom=dateFromTmp;
                setDateFrom(dateFromTmp)
            }
            if(json.dateTo) {
                let dateTmpTo=moment(json.dateTo)
                json.dateTo=dateTmpTo;
                setDateTo(dateTmpTo);
            }
            if(json.linkType) {
                setLinkType(json.linkType)
            }
            
            if(json.description){
                json = translationsToObject(json, 'description', json.description);
                setDescriptionJson(json.description)
            }
            if(json.name){
                json = translationsToObject(json, 'name', json.name);
                setNameJson(json.name)
            }
            setFieldsValue(json);
            setAddNew(true); 
        }
    }, [formValues]);

    const deleteEvent=(event)=>{
        let tmpEvents=events.filter((e=>{return e.id !== event.id}));
        setEvents(tmpEvents)
        saveComponent(false,false,tmpEvents);
    }

    function fetch() {
        setFetching(true);
        requestGet(CONSTANTS_REQ.WEBPAGECOMPONENT.EDIT + '/' + props.id).then((result) => {
            setFetching(false);
            if (result.status === RESPONSE.OK) {
                setValues(result.data);
            }
        });
    }

    function setValues(component_data) {
        if (component_data.json) {
            let data = JSON.parse(component_data.json);
            setEvents(data);
        }
    }

    

    function showNotification(title, message, duration, type) {
        notification[type]({
            message: title,
            description: message,
            duration: duration,
        });
    }

    function okNotification() {
        showNotification(geti18nText('app.default.save.ok'), geti18nText('app.default.save.ok.desc'), 3, 'success');
    }

    function error(message) {
        showNotification(geti18nText('app.default.save.nok'), message, 3, 'error');
    } 


    function saveEvent(){
        props.form.validateFieldsAndScroll((err, values) => {
            if (!err) { 
                values=normalizeComponents(values);
                if(!isEdit){
                    values.dateFrom=moment(values.dateFrom).format('YYYY-MM-DD')
                    values.dateTo=moment(values.dateTo).format('YYYY-MM-DD')
                    let tmpEvents=[...events, {...values, id: Math.floor(Math.random() * (9999999999))}]
                    setEvents(tmpEvents);
                    saveComponent(false, false, tmpEvents);
                }
                else {
                    values.dateFrom=moment(values.dateFrom).format('YYYY-MM-DD')
                    values.dateTo=moment(values.dateTo).format('YYYY-MM-DD')
                    
                    let tmpEvents=events.map((e=>{return e.id === selectedRowId ? {...values, id: e.id} : e }))
                    setEvents(tmpEvents)
                    saveComponent(false, false, tmpEvents);
                }
                setAddNew(false);
                setSelectedRowId(undefined);
                setLinkType(LINK_TYPE.WEBPAGE);
            
            }
            
        });
    }

    const normalizeComponents = (values) => {
        let normalizedValues = {};
        let translationKeys = {};
        for (var key in values) {
            if (values.hasOwnProperty(key)) {
                    // translations - to json
                    if (key.startsWith('translation-')) {
                        const fieldKey = key.substr(15);
                        const fieldLanguage = key.substr(12, 2);
                        if (normalizedValues[fieldKey] == undefined) {
                            normalizedValues[fieldKey] = {};
                        }
                        normalizedValues[fieldKey][fieldLanguage] = values[key];
                        if (!translationKeys[fieldKey]) {
                            translationKeys[fieldKey] = fieldKey;
                        }
                    } else {
                        if (values[key] === undefined) {
                            normalizedValues[key] = null;
                        } else {
                            normalizedValues[key] = values[key];
                        }
                    }
                
            }
        }
        return normalizedValues;
    };

    function saveComponent(close, notification, events){
            requestPut(CONSTANTS_REQ.WEBPAGECOMPONENT.UPDATEJSON + '/' + props.id, null, {
                id: props.id,
                json: JSON.stringify(events),
            }).then((result) => {
                if (result && result.status === RESPONSE.CREATED) {
                    notification && okNotification();
                    close && props.closePopup();
                } else {
                    error();
                    if (result && result.status === RESPONSE.BAD_REQUEST) {
                        if (result.data && result.data.field) {
                            switch (result.data.field) {
                                case 'mandatory_fields_required':
                                    console.log(geti18nText('app.default.mandatory_fields_required'));
                                    return;
                                default:
                                    break;
                            }
                        }
                    }
                    if (result.data && result.data.error) {
                        console.log(JSON.stringify(result.data.error));
                    }
                }
            });
    }

    function save(e, close) {
            e.preventDefault();
            if(events && events.length > 0) {
                saveComponent(close, true, events)
            }
            else {
                close && close && props.closePopup();
            }

    }

    function onSelectComponentProject(component, index) {
        props.form.resetFields(['projectWebLink']);
        if (component) {
            setSelectedProjectId(parseInt(component.key));
        } else {
            setSelectedProjectId(Session.getProjectId());
        }
    }

    function onChange(pagination, filters, sorter, extra) {
        console.log('params', pagination, filters, sorter, extra);
    }
    function closePopup(isSave = false) {
        setFormValues(undefined);
        setAddNew(false);
        setIsEdit(false);
        setSelectedRowId(undefined);
        setLinkType(LINK_TYPE.WEBPAGE);
    }

    function onEventSelect(event){
        setIsEdit(true);
        if(event.linkType) {
            setLinkType(event.linkType)
        }
        setFormValues(event);
        setSelectedRowId(event.id);
    }

    function onChangeFrom(date){
        setDateFrom(date);
        if(date.isAfter(dateTo)) {
            props.form.resetFields(['dateTo']);
        }
    }
    function onChangeTo(date){
        setDateTo(date);
    }

    function disabledDate(current) {
        return current && current < dateFrom;
      }

    
    if (fetching) {
        return <Spinner />;
    } else {
        return (
            <React.Fragment>
                <Form>
                <Divider>{geti18nText('component.calendar')}</Divider>
                
                <Button type="primary" style={{marginBottom:16, background: '#31b531', borderColor: '#31b531', textColor: 'white'}} onClick={()=>{props.form.resetFields();setAddNew(!addNew)}}>Dodaj događaj</Button>
                        
        
                <Table onRow={(record, rowIndex) => {
                    return {
                        onDoubleClick: (event) => {
                            onEventSelect(record);
                        },
                    };
                }} rowKey={(record) => record.id} columns={columns} dataSource={events} onChange={onChange} />
                <Modal
                    visible={addNew}
                    title={geti18nText('component.calendar.new.event')}
                    width={800}
                    okText={geti18nText('app.default.button.save')}
                    onCancel={closePopup}
                    onOk={saveEvent}
                    forceRender={true}
                   
                >
               
                   <Row>
                        <InputByLanguage
                                fieldName={'name'}
                                label={geti18nText('component.calendar.name')}
                                langJson={nameJson}
                                minHeight={'250px'}
                               
                                {...props}
                            />
                        <InputByLanguage
                                notRequred
                                fieldName={'description'}
                                label={geti18nText('component.calendar.description')}
                                langJson={descriptionJson}
                                minHeight={'250px'}
                                type="rteEditor"
                                {...props}
                            />
                   </Row>
                   <Row>
                       <Col span={12}>
                           <Form.Item label={geti18nText('component.calendar.dateFrom')}>
                               {getFieldDecorator('dateFrom', {
                                        rules: [{ required: true, message: geti18nText("app.default.required") }],
                                    })(
                                   <DatePicker onChange={onChangeFrom} format="DD.MM.YYYY"  />
                               )}
                           </Form.Item>
                          
                       </Col>
                       <Col span={12}>
                           <Form.Item label={geti18nText('component.calendar.dateTo')}>
                               {getFieldDecorator('dateTo', {
                                        rules: [{ required: true, message: geti18nText("app.default.required") }],
                                    })(
                                   <DatePicker onChange={onChangeTo} disabledDate={disabledDate} format="DD.MM.YYYY"  />
                               )}
                           </Form.Item>
                          
                       </Col>
                   </Row>
                   <Row>
                       <Col span={4}>
                           <Form.Item label={geti18nText('component.calendar.startTime')}>
                                   {getFieldDecorator('startTime')(
                                       <Input />
                                   )}
                               </Form.Item>
                       </Col>
                   </Row>
                   <Row>
                       <Form.Item>
                       {getFieldDecorator('linkType', { initialValue: linkType })(
                           <Radio.Group
                               onChange={(e) => {
                                   setLinkType(e.target.value);
                               }}
                           >
                               <Radio value={LINK_TYPE.WEBPAGE}>
                                   {geti18nText('component.calendar.link')}
                               </Radio>
                               <Radio value={LINK_TYPE.LINK}>
                                   {geti18nText('component.calendar.link.outside')}
                               </Radio>
                           </Radio.Group>
                       )}
                       </Form.Item>
                       {linkType == LINK_TYPE.LINK ? (
                       
                       <Row>
                       <Col span={12}>
                           <Form.Item label={geti18nText('component.imagelinks.edit.external_link')}>
                                   {getFieldDecorator('externalLink')(
                                       <Input />
                                   )}
                               </Form.Item>
                       </Col>
                    </Row>
                   ) : (
                       <Row>
                           <Col span={8}>
                               <Form.Item label={geti18nText('component.calendar.link.project')}>
                                   {getFieldDecorator('linkProject')(
                                       <SearchField
                                           onChange={(com) => onSelectComponentProject(com, props.index)}
                                           url={CONSTANTS_REQ.PROJECT.SEARCH}
                                           map={{ id: 'id', label: 'name' }}
                                           searchBy="name"
                                           translate={false}
                                           allowClear={false}
                                       />
                                   )}
                               </Form.Item>
                           </Col>
                           <Col span={8} offset={1}>
                               <Form.Item label={geti18nText('component.calendar.link.project.link')}>
                                   {getFieldDecorator('projectWebLink')(

                                       <SearchField
                                           url={CONSTANTS_REQ.WEBPAGE.SEARCH}
                                           map={{ id: 'id', label: 'name' }}
                                           searchBy="name"
                                           //disabled={!getFieldValue('linkProject')}
                                           translate={true}
                                           addedData={{ projectId: selectedProjectId, forMap: true }}
                                       />
                                   )}
                               </Form.Item>
                           </Col>
                       </Row>
                   )}
                   </Row>
                
                </Modal>
                <Row>
                    <Col span={24} style={{ textAlign: 'right', marginTop: '1rem' }}>
                        <Button style={{ marginRight: '1em' }} onClick={(e) => props.closePopup()}>
                            {geti18nText('app.default.button.cancel')}
                        </Button>
                        <Button style={{ marginRight: '1em' }} type="primary" onClick={(e) => save(e, false)}>
                            {geti18nText('app.default.button.save')}
                        </Button>
                        <Button
                            type="primary"
                            style={{ background: '#31b531', borderColor: '#31b531', textColor: 'white' }}
                            onClick={(e) => {
                                save(e, true);
                            }}
                        >
                            {geti18nText('app.default.button.save.close')}
                        </Button>
                    </Col>
                    
                </Row>
                </Form>
            </React.Fragment>
        );
    }
});
export default CalendarEdit;
