import React, { useEffect, useState } from 'react';
import {
    Col,
    Row,
    Card,
    List,
    Icon,
    Button,
    Popconfirm,
    message,
    Divider,
    Tooltip,
} from 'antd';
import { geti18nText, Session, hasAnyRole } from '../../../utils/Session';
import { useDispatch, useSelector } from 'react-redux';
import { requestGet, requestPost, RESPONSE } from '../../../utils/RequestResolver';
import { CONSTANTS_REQ, UPLAD_MOBILEAPP_FOLDER, CONSTANTS_FILE_TYPE } from '../../../utils/Constants';
import { formatBytes, dateFormat, downloadFile } from '../../../utils/Utils';
import { FileUpload } from '../../../components/fileUpload/FileUpload';
import { setRefreshFolder } from '../../../slices/filesSlices';

const MobileApp = () => {
    const { folderPath, refreshFolder } = useSelector((state) => state.filesSlices);
    const [visibleUploadModal, setVisibleUploadModal] = useState(false);
    const [data, setData] = useState([]);
    const projectId = 0;
    const dispatch = useDispatch();

    useEffect(() => {
        if (folderPath) {
            requestGet(CONSTANTS_REQ.DIRECTORY.TREE + '/' + projectId, {
                directory: UPLAD_MOBILEAPP_FOLDER,
            }).then((result) => {
                if (result.status === RESPONSE.OK) {
                    console.log(result);
                    if (Array.isArray(result.data)) {
                        setData(result.data);
                    }
                } else {
                    setData([]);
                }
            });
        }
    }, [folderPath, refreshFolder]);

    function onVisibleUploadChange(value) {
        dispatch(setRefreshFolder());
        setVisibleUploadModal(value.disabled);
    }

    const deleteFile = (path) => {
        requestGet(
            CONSTANTS_REQ.DIRECTORY.CHECK_DELETE,
            { projectId: projectId, path: path },
            false,
            true
        ).then((result) => {
            if (result.status === RESPONSE.OK) {
                requestPost(CONSTANTS_REQ.DIRECTORY.DELETE, undefined, {
                    projectId: projectId,
                    path: path,
                }).then((result) => {
                    if (result.status === RESPONSE.OK) {
                        message.info(`${geti18nText('menu.delete.file.success')}`);
                        dispatch(setRefreshFolder());
                    } else {
                        message.destroy();
                        message.error(`${geti18nText('menu.delete.file.error')}`);
                    }
                });
            } else {
                message.destroy();
                message.error(`${geti18nText('menu.delete.file.error')}`);
            }
        });
    };

    const fileDownload = (fileName) => {
        requestGet(
            CONSTANTS_REQ.MOBILE_APPLICATION.DOWNLOAD,
            { projectId: projectId, folder: UPLAD_MOBILEAPP_FOLDER, fileName: fileName },
            false,
            true
        ).then((result) => {
            if (result.status === RESPONSE.OK && result.data) {
                result.data.filename = fileName;
                downloadFile(result);
            } else {
                message.destroy();
                message.error(`${geti18nText('upload.text.download.error')}`);
            }
        });
    };

    return (
        <>
            <Row type="flex" className="margin-bottom">
                <Divider orientation="left" style={{ fontSize: '18px' }}>
                    {geti18nText('mobileApp.title')}
                </Divider>
            </Row>
            {hasAnyRole(['ROLE_ADMIN']) && (
                <Row>
                    <Col span={24}>
                        <Button
                            onClick={() => setVisibleUploadModal(true)}
                            type="primary"
                            icon="upload"
                            style={{ float: "right" }}
                        >
                            {geti18nText('mobileApp.upload')}
                        </Button>
                    </Col>
                </Row>
            )}
            <Row>
                <Col style={{ marginTop: '25px' }}>
                    <List
                        grid={{
                            gutter: 12,
                            xs: 1,
                            sm: 1,
                            md: 1,
                            lg: 1,
                            xl: 2,
                            xxl: 3,
                        }}
                        dataSource={data}
                        renderItem={(item, index) => (
                            <List.Item key={index}>
                                <Card
                                    id="draggable"
                                >
                                    <Row>
                                        {item.type === 'FILE' && (
                                            <>
                                                <Col span={11}>
                                                    <span
                                                        onClick={() => fileDownload(item.name)}
                                                        style={{ cursor: 'pointer', wordWrap: 'break-word' }}
                                                    >
                                                        {item.name}
                                                    </span>
                                                </Col>
                                                <Col span={3} style={{ textAlign: 'right' }}>
                                                    <span
                                                        style={{
                                                            textAlign: 'right',
                                                            color: 'rgb(0 0 0 / 45%)',
                                                            fontSize: '12px',
                                                        }}
                                                    >
                                                        {formatBytes(item.size)}
                                                    </span>
                                                </Col>
                                                <Col span={6} style={{ textAlign: 'right' }}>
                                                    {item.dateCreated !== undefined && (
                                                        <span style={{ color: 'rgb(0 0 0 / 45%)', fontSize: '12px' }}>
                                                            {dateFormat(item.dateCreated, true)}
                                                        </span>
                                                    )}
                                                </Col>
                                                <Col span={4} style={{ textAlign: 'right' }}>
                                                    <Tooltip placement="top" title={geti18nText('mobileApp.download')} arrowPointAtCenter>
                                                        <Icon
                                                            style={{ marginRight: '10px', fontSize: '20px' }}
                                                            onClick={() => fileDownload(item.name)}
                                                            type="download"
                                                        />
                                                    </Tooltip>
                                                    {hasAnyRole(['ROLE_ADMIN']) && (
                                                        <Popconfirm
                                                            title={geti18nText('menu.delete.file.confirm')}
                                                            onConfirm={() => deleteFile(item.relativePath)}
                                                            okText={geti18nText('app.default.button.yes')}
                                                            cancelText={geti18nText('app.default.button.no')}
                                                        >
                                                            <Tooltip placement="top" title={geti18nText('mobileApp.delete')} arrowPointAtCenter>
                                                                <Icon
                                                                    style={{ fontSize: '20px', color: 'red' }}
                                                                    type="delete"
                                                                />
                                                            </Tooltip>
                                                        </Popconfirm>
                                                    )}
                                                </Col>
                                            </>
                                        )}
                                    </Row>
                                </Card>
                            </List.Item>
                        )}
                    />
                </Col>
            </Row>
            <Row hidden={true}>
                <FileUpload
                    folderValue={UPLAD_MOBILEAPP_FOLDER}
                    visible={visibleUploadModal}
                    disabled={true}
                    multiple={true}
                    menuUploadChange={onVisibleUploadChange}
                    addedData={{ projectId: projectId }}
                    fileType={CONSTANTS_FILE_TYPE.APPLICATION}
                ></FileUpload>
            </Row>
        </>
    );
};

export default MobileApp;