import React, { useState, useEffect } from 'react';
import { Form, Row, Col, Input, Button, Divider, Tabs, Modal,notification } from 'antd';
import { Session, geti18nText } from '../../utils/Session';
import { normalizeComponents } from '../../utils/Utils';
import { requestGet, requestPut, RESPONSE } from '../../utils/RequestResolver';
import { CONSTANTS_FILE_TYPE, CONSTANTS_REQ } from '../../utils/Constants';
import { FileUpload } from '../fileUpload/FileUpload';
import { InputByLanguage, translationsToObject } from '../InputByLanguage';
import { Spinner } from '../../components/Spinner';

import { getTranslated } from '../../components/InputByLanguage';
import { SearchField } from '../../components/Search';
import { ColorPicker } from '../../components/ColorPicker';
const BtcReferenceEdit = Form.create({ name: 'VideoBlockEdit' })((props) => {
    const { getFieldDecorator, setFieldsValue } = props.form;
    const [fetching, setFetching] = useState(false);
    const [formValues, setFormValues] = useState(null);
    const [types, setTypes] = useState([]);
    const [selectedType, setSelectedType] = useState(null);
    const [typeId, setTypeId] = useState(null);
    const [editTypeVisible, setEditTypeVisible] = useState(null);
    const [comments, setComments] = useState([]);
    const [selectedComment, setSelectedComment] = useState(null);
    const [commentId, setCommentId] = useState(null);
    const [editCommentVisible, setEditCommentVisible] = useState(null);
    const [logos, setLogos] = useState([]);
    const [selectedLogo, setSelectedLogo] = useState(null);
    const [logoId, setLogoId] = useState(null);
    const [editLogoVisible, setEditLogoVisible] = useState(null);
    const { TabPane } = Tabs;

    useEffect(() => {
        fetch();
    }, [props.visible]);

    useEffect(() => {
        props.form.resetFields();
        setFieldsValue(formValues);
    }, [formValues]);

    function fetch() {
        setFetching(true);
        requestGet(CONSTANTS_REQ.WEBPAGECOMPONENT.EDIT + '/' + props.id).then((result) => {
            setFetching(false);
            if (result.status === RESPONSE.OK) {
                setValues(result.data);
            }
        });
    }

    function setValues(component_data) {
        var json;
        if (component_data.json) {
            json = JSON.parse(component_data.json);
            if (json.types) setTypes(json.types);
            if (json.comments) setComments(json.comments);
            if (json.logos) setLogos(json.logos);
            setFieldsValue(json);
        }
    }
    function showNotification(title, message, duration, type) {
        notification[type]({
            message: title,
            description: message,
            duration: duration,
        });
    }

    function okNotification() {
        showNotification(geti18nText('app.default.save.ok'), geti18nText('app.default.save.ok.desc'), 3, 'success');
    }

    function error(message) {
        showNotification(geti18nText('app.default.save.nok'), message, 3, 'error');
    } 

    function save(e, close) {
        e.preventDefault();
        let values = {};
        values.types = types;
        values.comments = comments;
        if (logos.length > 0) {
            logos.forEach((logo) => {
                logo.type = logo.type != null && typeof logo.type === 'object' ? logo.type.id : logo.type;
            });
        }
        values.logos = logos;

        requestPut(CONSTANTS_REQ.WEBPAGECOMPONENT.UPDATEJSON + '/' + props.id, null, {
            id: props.id,
            json: JSON.stringify(values),
        }).then((result) => {
            if (result && result.status === RESPONSE.CREATED) {
                console.log('okNotification');
                okNotification();
                close && props.closePopup();
            } else {
                error();
                if (result && result.status === RESPONSE.BAD_REQUEST) {
                    if (result.data && result.data.field) {
                        switch (result.data.field) {
                            case 'mandatory_fields_required':
                                console.log(geti18nText('app.default.mandatory_fields_required'));
                                return;
                            default:
                                break;
                        }
                    }
                }
                if (result.data && result.data.error) {
                    console.log(JSON.stringify(result.data.error));
                }
            }
        });
    }
    //Types
    function editSelectedType(id) {
        setTypeId(id);
        setSelectedType(types[id]);
        setEditTypeVisible(true);
    }

    function addType() {
        props.form.resetFields();
        setTypeId(-1);
        setSelectedType(null);
        setEditTypeVisible(true);
    }

    function saveTypeData(typ) {
        let t = types.slice();
        if (typeId == -1) {
            t.push(typ);
        } else {
            t[typeId] = typ;
        }
        setTypes(t);
        setEditTypeVisible(false);
    }

    function deleteType() {
        let t = types.slice();
        t.splice(typeId, 1);
        setTypes(t);
        setEditTypeVisible(false);
    }
    //Comments
    function editSelectedComment(id) {
        setCommentId(id);
        setSelectedComment(comments[id]);
        setEditCommentVisible(true);
    }

    function addComment() {
        props.form.resetFields();
        setCommentId(-1);
        setSelectedComment(null);
        setEditCommentVisible(true);
    }

    function saveCommentData(comm) {
        let t = comments.slice();
        if (commentId == -1) {
            t.push(comm);
        } else {
            t[commentId] = comm;
        }
        setComments(t);
        setEditCommentVisible(false);
    }

    function deleteComment() {
        let t = comments.slice();
        t.splice(commentId, 1);
        setComments(t);
        setEditCommentVisible(false);
    }
    //Logos
    function editSelectedLogo(id) {
        setLogoId(id);
        setSelectedLogo(logos[id]);
        setEditLogoVisible(true);
    }

    function addLogo() {
        props.form.resetFields();
        setLogoId(-1);
        setSelectedLogo(null);
        setEditLogoVisible(true);
    }

    function saveLogoData(logo) {
        let t = logos.slice();
        if (logoId == -1) {
            t.push(logo);
        } else {
            t[logoId] = logo;
        }
        setLogos(t);
        setEditLogoVisible(false);
    }

    function deleteLogo() {
        let t = logos.slice();
        t.splice(logoId, 1);
        setLogos(t);
        setEditLogoVisible(false);
    }

    if (fetching) {
        return <Spinner />;
    } else {
        return (
            <React.Fragment>
                <Divider>{geti18nText('component.btcreference')}</Divider>
                <Form>
                    <Row style={{ marginTop: '20px ' }}>
                        <Col span={24}>
                            <Tabs>
                                <TabPane
                                    tab={geti18nText('component.btcreference.tab.types')}
                                    key="types"
                                    forceRender={true}
                                >
                                    <Button shape="round" icon="plus" onClick={(e) => addType()} />
                                    {types.map((typ, index) => (
                                        <div
                                            style={{ marginTop: '10px' }}
                                            key={index}
                                            onClick={() => {
                                                editSelectedType(index);
                                            }}
                                        >
                                            <a>{getTranslated(typ.title)}</a>
                                        </div>
                                    ))}
                                </TabPane>
                                <TabPane
                                    tab={geti18nText('component.btcreference.tab.logos')}
                                    key="logo"
                                    forceRender={true}
                                    disabled={types.length == 0}
                                >
                                    <Button shape="round" icon="plus" onClick={(e) => addLogo()} />
                                    <br />
                                    {logos.map((cmm, index) => (
                                        <div
                                            style={{ margin: '10px', float: 'left', cursor: 'pointer' }}
                                            key={index}
                                            onClick={() => {
                                                editSelectedLogo(index);
                                            }}
                                        >
                                            {cmm.path && (
                                                <img
                                                    src={
                                                        CONSTANTS_REQ.STATIC +
                                                        Session.getProjectId() +
                                                        encodeURI(cmm.path.file_path)
                                                    }
                                                    style={{ maxHeight: '100px', maxWidth: '150px' }}
                                                />
                                            )}
                                        </div>
                                    ))}
                                </TabPane>
                                <TabPane
                                    tab={geti18nText('component.btcreference.tab.comments')}
                                    key="comment"
                                    forceRender={true}
                                >
                                    <Button shape="round" icon="plus" onClick={(e) => addComment()} />
                                    {comments.map((cmm, index) => (
                                        <div
                                            style={{ marginTop: '10px' }}
                                            key={index}
                                            onClick={() => {
                                                editSelectedComment(index);
                                            }}
                                        >
                                            <a>{getTranslated(cmm.client)}</a>
                                        </div>
                                    ))}
                                </TabPane>
                            </Tabs>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24} style={{ textAlign: 'right', marginTop: '1rem' }}>
                            <Button style={{ marginRight: '1em' }} onClick={(e) => props.closePopup()}>
                                {geti18nText('app.default.button.cancel')}
                            </Button>
                            <Button style={{ marginRight: '1em' }} type="primary" onClick={(e) => save(e, false)}>
                                {geti18nText('app.default.button.save')}
                            </Button>
                            <Button
                                type="primary"
                                style={{ background: '#31b531', borderColor: '#31b531', textColor: 'white' }}
                                onClick={(e) => {
                                    save(e, true);
                                }}
                            >
                                {geti18nText('app.default.button.save.close')}
                            </Button>
                        </Col>
                    </Row>
                    <EditType
                        visible={editTypeVisible}
                        setVisible={setEditTypeVisible}
                        onSave={saveTypeData}
                        onDelete={deleteType}
                        typeId={typeId}
                        type={selectedType}
                        {...props}
                    />
                    <EditLogo
                        visible={editLogoVisible}
                        setVisible={setEditLogoVisible}
                        onSave={saveLogoData}
                        onDelete={deleteLogo}
                        logoId={logoId}
                        logo={selectedLogo}
                        types={types}
                        {...props}
                    />
                    <EditComment
                        visible={editCommentVisible}
                        setVisible={setEditCommentVisible}
                        onSave={saveCommentData}
                        onDelete={deleteComment}
                        commentId={commentId}
                        comment={selectedComment}
                        nrComments={comments.length}
                        {...props}
                    />
                </Form>
            </React.Fragment>
        );
    }
});

export const EditType = (props) => {
    const { getFieldDecorator, setFieldsValue } = props.form;
    const [titleLangJson, setTitleLangJson] = useState(undefined);

    useEffect(() => {
        if (props.type) {
            setTitleLangJson(props.type.title);
        }
    }, [props.type]);

    useEffect(() => {
        setValues();
    }, [titleLangJson && props.typeId]);

    function setValues() {
        if (titleLangJson) {
            Session.getProjectInfo().languages.map((value, index) => {
                props.form.resetFields(['translation-' + Object.keys(value)[0] + '-title']);
            });
        }

        if (props.type) {
            for (var key in props.type.title) {
                const newKey = 'translation-' + key + '-title';
                let v = {};
                v[newKey] = props.type.title[key];
                setFieldsValue(v);
            }
        } else {
            setTitleLangJson(undefined);
        }
    }

    function closePopup() {
        props.setVisible(false);
    }

    function deleteType() {
        props.onDelete();
    }

    function save() {
        props.form.validateFieldsAndScroll((err, values) => {
            if (!err || !err.hasOwnProperty('title')) {
                values = normalizeComponents(values);
                let t = {};
                t.title = values.title;
                props.onSave(t);
            }
        });
    }

    function getButtons() {
        return [
            <Button type="danger" key="delete" onClick={(e) => deleteType()}>
                {geti18nText('app.default.button.delete')}
            </Button>,
            <Button key="back" onClick={(e) => closePopup()}>
                {geti18nText('app.default.button.cancel')}
            </Button>,
            <Button key="submit" type="primary" onClick={(e) => save(e)}>
                {geti18nText('app.default.button.save')}
            </Button>,
        ];
    }

    return (
        <>
            <Modal
                visible={props.visible}
                onCancel={closePopup}
                width={500}
                title={geti18nText('component.btcreference.edit.type')}
                footer={getButtons()}
            >
                <Row gutter={24}>
                    <Col span={24} style={{ width: '100%' }}>
                        <InputByLanguage
                            fieldName="title"
                            label={geti18nText('component.btcreference.edit.type.title')}
                            langJson={titleLangJson}
                            {...props}
                        />
                    </Col>
                </Row>
            </Modal>
        </>
    );
};

export const EditComment = (props) => {
    const { getFieldDecorator, setFieldsValue } = props.form;
    const [clientLangJson, setClientLangJson] = useState(undefined);
    const [textLangJson, setTextLangJson] = useState(undefined);

    useEffect(() => {
        if (props.comment) {
            setClientLangJson(props.comment.client);
            setTextLangJson(props.comment.text);
        }
    }, [props.comment, props.nrComments]);

    useEffect(() => {
        setValues();
    }, [clientLangJson && props.commentId, clientLangJson]);

    function setValues() {
        if (clientLangJson) {
            Session.getProjectInfo().languages.map((value, index) => {
                props.form.resetFields(['translation-' + Object.keys(value)[0] + '-client']);
                props.form.resetFields(['translation-' + Object.keys(value)[0] + '-text']);
            });
        }

        if (props.comment) {
            for (var key in props.comment.client) {
                const newKey = 'translation-' + key + '-client';
                let v = {};
                v[newKey] = props.comment.client[key];
                setFieldsValue(v);
            }
            for (var key in props.comment.text) {
                const newKey = 'translation-' + key + '-text';
                let v = {};
                v[newKey] = props.comment.text[key];
                setFieldsValue(v);
            }
        } else {
            setClientLangJson(undefined);
            setTextLangJson(undefined);
        }
    }

    function closePopup() {
        props.setVisible(false);
    }

    function deleteItem() {
        props.onDelete();
    }

    function save() {
        props.form.validateFieldsAndScroll((err, values) => {
            let c = {};
            values = normalizeComponents(values);
            c.client = values.client;
            c.text = values.text;
            props.onSave(c);
        });
    }

    function getButtons() {
        return [
            <Button type="danger" key="delete" onClick={(e) => deleteItem()}>
                {geti18nText('app.default.button.delete')}
            </Button>,
            <Button key="back" onClick={(e) => closePopup()}>
                {geti18nText('app.default.button.cancel')}
            </Button>,
            <Button key="submit" type="primary" onClick={(e) => save(e)}>
                {geti18nText('app.default.button.save')}
            </Button>,
        ];
    }

    return (
        <>
            <Modal
                visible={props.visible}
                onCancel={closePopup}
                width={800}
                title={geti18nText('component.btcreference.edit.comment')}
                footer={getButtons()}
            >
                <Row gutter={24}>
                    <Col span={24} style={{ width: '100%' }}>
                        <InputByLanguage
                            notRequred
                            fieldName="client"
                            label={geti18nText('component.btcreference.edit.comment.client')}
                            langJson={clientLangJson}
                            {...props}
                        />
                        <InputByLanguage
                            notRequred
                            fieldName="text"
                            type="textArea"
                            label={geti18nText('component.btcreference.edit.comment.text')}
                            langJson={textLangJson}
                            {...props}
                        />
                    </Col>
                </Row>
            </Modal>
        </>
    );
};

export const EditLogo = (props) => {
    const { getFieldDecorator, setFieldsValue } = props.form;

    useEffect(() => {
        setValues();
    }, [props.logo]);

    function setValues() {
        props.form.resetFields();

        if (props.logo) {
            let values = props.logo;
            props.logo.type = typeof props.logo.type === 'object' ? props.logo.type.id : props.logo.type;
            if (props.types.length - 1 >= parseInt(props.logo.type)) {
                values.type = { id: props.logo.type, text: getTranslated(props.types[props.logo.type].title) };
            } else {
                values.type = { id: 0, text: getTranslated(props.types[0].title) };
            }

            setFieldsValue(values);
        }
    }

    function closePopup() {
        props.setVisible(false);
    }

    function deleteItem() {
        props.onDelete();
    }

    function save() {
        props.form.validateFieldsAndScroll((err, values) => {
            if (!err || (!err.hasOwnProperty('path') && !err.hasOwnProperty('type'))) {
                let c = {};
                c.webpage = values.webpage;

                values = normalizeComponents(values);
                c.path = normalizeFileUpload('path');
                c.type = values.type != null && typeof values.type === 'object' ? values.type.id : values.type;
                c.link = values.link;
                props.onSave(c);
            }
        });
    }

    function getButtons() {
        return [
            <Button type="danger" key="delete" onClick={(e) => deleteItem()}>
                {geti18nText('app.default.button.delete')}
            </Button>,
            <Button key="back" onClick={(e) => closePopup()}>
                {geti18nText('app.default.button.cancel')}
            </Button>,
            <Button key="submit" type="primary" onClick={(e) => save(e)}>
                {geti18nText('app.default.button.save')}
            </Button>,
        ];
    }

    function normalizeFileUpload(field) {
        let file = props.form.getFieldsValue([field])[field];
        if (file instanceof Array) {
            file = file[0];
        }
        return file.id ? file : { file_path: file.file_path, name: file.name };
    }

    return (
        <>
            <Modal
                visible={props.visible}
                onCancel={closePopup}
                width={800}
                title={geti18nText('component.btcreference.edit.logo')}
                footer={getButtons()}
            >
                <Row gutter={24}>
                    <Col span={24} style={{ width: '100%' }}>
                        <Form.Item label={geti18nText('component.btcreference.edit.logo.image')}>
                            {getFieldDecorator('path', {
                                rules: [{ required: true, message: geti18nText('app.default.required') }],
                            })(
                                <FileUpload
                                    fileType={CONSTANTS_FILE_TYPE.IMAGE}
                                    map={{ id: 'id', label: 'name' }}
                                    searchBy="name"
                                    disabled={false}
                                    addedData={{
                                        projectId: Session.getProjectId(),
                                        fileType: CONSTANTS_FILE_TYPE.IMAGE,
                                    }}
                                />
                            )}
                        </Form.Item>
                        <Form.Item label={geti18nText('component.btcreference.edit.logo.type')}>
                            {getFieldDecorator('type', {
                                rules: [{ required: true, message: geti18nText('app.default.required') }],
                            })(
                                <SearchField
                                    options={props.types.map((type, index) => {
                                        return { id: index, text: getTranslated(type.title) };
                                    })}
                                />
                            )}
                        </Form.Item>
                        <Form.Item label={geti18nText('component.btcreference.edit.logo.link')}>
                            {getFieldDecorator('link')(<Input />)}
                        </Form.Item>
                        <Form.Item label={geti18nText('component.btcreference.edit.webpage')}>
                            {getFieldDecorator('webpage')(
                                <SearchField
                                    url={CONSTANTS_REQ.WEBPAGE.SEARCH}
                                    map={{ id: 'id', label: 'name' }}
                                    searchBy="name"
                                    disabled={false}
                                    translate={true}
                                    addedData={{ projectId: Session.getProjectId(), forPage: true }}
                                />
                            )}
                        </Form.Item>
                    </Col>
                </Row>
            </Modal>
        </>
    );
};

export default BtcReferenceEdit;
