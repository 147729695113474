import React, { useState, useEffect } from 'react';
import { Form, Row, Col, Input, Button, Divider, Tabs, Popconfirm, Radio, notification } from 'antd';
import { Session, geti18nText } from '../../utils/Session';
import { requestGet, requestPut, RESPONSE } from '../../utils/RequestResolver';
import { CONSTANTS_FILE_TYPE, CONSTANTS_REQ } from '../../utils/Constants';
import { normalizeComponents } from '../../utils/Utils';
import { FileUpload } from '../fileUpload/FileUpload';
import { InputByLanguage, translationsToObject } from '../InputByLanguage';
import { SearchField } from '../../components/Search';
import { Spinner } from '../../components/Spinner';
import { normalizeJSONForComponent, getDiskFiles } from '../../utils/ComponentHelper';
import SortImagesOrVideos, { ReorderImageKeys } from '../SortImagesOrVideos';
import { ColorPicker } from '../../components/ColorPicker';

const ImageLinksEdit = Form.create({ name: 'ImageLinksEdit' })((props) => {
    const [fetching, setFetching] = useState(false);
    const { getFieldDecorator, setFieldsValue } = props.form;
    const [mainTitleLangJson, setMainTitleLangJson] = useState(undefined);
    const [formValues, setFormValues] = useState(undefined);
    const [imagesKeys, setImagesKeys] = useState([0]);
    const [lastKey, setLastKey] = useState(0);
    const [images, setImages] = useState([]);
    const [deleteConfirmVisible, setDeleteConfirmVisible] = useState(false);
    const [imageToDelete, setImageToDelete] = useState(null);
    const [deletedImages, setDeletedImages] = useState([]);
    const [activeTab, setActiveTab] = useState('0');
    const [isDisabled, setIsDisabled] = useState(true);
    const { TabPane } = Tabs;


    useEffect(() => {
        fetch();
    }, [props.visible]);

    useEffect(() => {
        props.form.resetFields();
        setFieldsValue(formValues);
    }, [formValues]);
    

    

    function fetch() {
        setFetching(true);
        requestGet(CONSTANTS_REQ.WEBPAGECOMPONENT.EDIT + '/' + props.id).then((result) => {
            setFetching(false);
            if (result.status === RESPONSE.OK) {
                setValues(result.data);
            }
        });
    }

    function setValues(component_data) {
        let imgArray = [];
        var json;
        if (component_data.json) {
            var imagePaths = {};
            var webPages = {};
            json = JSON.parse(component_data.json);

            let max_key = 0;
            for (const key of json.image_keys) {
                imgArray.push({
                    id: key,
                    titleLangJson: json['image_' + key].title,
                    textLangJson: json['text_' + key],
                    rdlPath: json['image_' + key].file_path,
                    externalLink: json['image_' + key]['external-link'],
                    type: json['image_' + key].type,
                    project: parseInt(json['image_' + key].project),
                });
                json = translationsToObject(json, 'image_' + key + '-title', json['image_' + key].title);
                imagePaths['image_' + key + '-file_path'] = json['image_' + key].path;
                json['image_' + key + '-file_path'] = {
                    key: json['image_' + key].file_path,
                    file_path: json['image_' + key].file_path,
                };
                setWepPageValue('image_' + key + '-link', parseInt(json['image_' + key].link));
                setProjectValue('image_' + key + '-project', parseInt(json['image_' + key].project));
                delete json['image_' + key];
                json = translationsToObject(json, 'text_' + key, json['text_' + key]);
                delete json['text_' + key];
                max_key = key > max_key ? key : max_key;
            }

            setLastKey(max_key);
            json = translationsToObject(json, 'title', json['title']);
            setMainTitleLangJson(json['title']);
            setImages(imgArray);
            setImagesKeys(json.image_keys);
            setActiveTab('' + json.image_keys[0]);
            delete json.totalImages;
            delete json.image_keys;
            delete json['title'];

            setFormValues(json);
        } else {
            setImagesKeys([0]);
            setImages([{ id: 0 }]);
        }
    }

    function setWepPageValue(key, id) {
        CounterResponse.increment();
        requestGet(CONSTANTS_REQ.WEBPAGE.EDIT + '/' + id).then((result) => {
            if (result.status === RESPONSE.OK) {
                setFieldsValue({ [key]: { id: id, name: result.data.name } });
            }
            CounterResponse.decrement();
        });
    }

    

    function setProjectValue(key, id) {
        CounterResponse.increment();
        requestGet(CONSTANTS_REQ.PROJECT.EDIT + '/' + id).then((result) => {
            if (result.status === RESPONSE.OK) {
                setFieldsValue({ [key]: { id: id, name: result.data.name } });
            }
            CounterResponse.decrement();

        });
    }

    function showNotification(title, message, duration, type) {
        notification[type]({
            message: title,
            description: message,
            duration: duration,
        });
    }

    function okNotification() {
        showNotification(geti18nText('app.default.save.ok'), geti18nText('app.default.save.ok.desc'), 3, 'success');
    }

    function error(message) {
        showNotification(geti18nText('app.default.save.nok'), message, 3, 'error');
    } 

    function save(e, close) {
        e.preventDefault();
        props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                values = normalizeComponents(values);
                values.image_keys = imagesKeys;
                for (const key of imagesKeys) {
                    values['image_' + key + '-link'] =
                        values['image_' + key + '-link'] != null &&
                        values['image_' + key + '-link'] instanceof Object &&
                        values['image_' + key + '-link'].id != null
                            ? values['image_' + key + '-link'].id
                            : values['image_' + key + '-link'];

                    values['image_' + key + '-project'] =
                        values['image_' + key + '-project'] != null &&
                        values['image_' + key + '-project'] instanceof Object
                            ? values['image_' + key + '-project'].id
                            : values['image_' + key + '-project'];
                }
                if (props.showReorder) {
                    values.image_keys = ReorderImageKeys(images);
                }
                for (const deleted of deletedImages) {
                    if (values['image_' + deleted + '-file_path']) {
                        delete values['image_' + deleted + '-file_path_'];
                    }
                }
                values = normalize(values);
                requestPut(CONSTANTS_REQ.WEBPAGECOMPONENT.UPDATEJSON + '/' + props.id, null, {
                    id: props.id,
                    json: values.json,
                }).then((result) => {
                    if (result && result.status === RESPONSE.CREATED) {
                        console.log('okNotification');
                        okNotification();
                        close && props.closePopup();
                    } else {
                        error();
                        if (result && result.status === RESPONSE.BAD_REQUEST) {
                            if (result.data && result.data.field) {
                                switch (result.data.field) {
                                    case 'mandatory_fields_required':
                                        console.log(geti18nText('app.default.mandatory_fields_required'));
                                        return;
                                    default:
                                        break;
                                }
                            }
                        }
                        if (result.data && result.data.error) {
                            console.log(JSON.stringify(result.data.error));
                        }
                    }
                });
            }
        });
    }

    function normalize(values, translationKeys) {
        let jsonFieldGroups = ['title', 'image_keys'];
        let jsonFieldKeys = {};
        let imageJsonArr = ['file_path', 'title', 'link', 'project', 'type', 'external-link'];
        imagesKeys.forEach((imageIndex) => {
            jsonFieldGroups.push('image_' + imageIndex.toString());
            jsonFieldKeys['image_' + imageIndex.toString()] = imageJsonArr;
        });
        return normalizeJSONForComponent(values, undefined, jsonFieldGroups, jsonFieldKeys, props);
    }

    const onEdit = (targetKey, action) => {
        if (action == 'add') {
            props.form.validateFieldsAndScroll((err, values) => {
                if (!err) {
                    let newId = lastKey;
                    newId++;

                    let arrImages = images.slice();
                    let arrKeys = imagesKeys.slice();
                    arrImages.push({ id: newId });
                    arrKeys.push(newId);
                    setImagesKeys(arrKeys);
                    setImages(arrImages);
                    setActiveTab('' + newId);
                    setLastKey(newId);
                }
            });
        }
        if (action == 'remove') {
            if (imagesKeys.length > 1) {
                setDeleteConfirmVisible(true);
                setImageToDelete(targetKey);
            }
        }
    };

    const tabOnChange = (activeKey) => {
        props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                setActiveTab(activeKey);
            }
        });
    };

    useEffect(()=>{
        if(CounterResponse.getValue() === 1) {
            setIsDisabled(false);
        }
        else setIsDisabled(true);
    },[CounterResponse.getValue()])



   

    const deleteImage = () => {
        if (imageToDelete) {
            var newArrKeys = imagesKeys.filter((key) => key != parseInt(imageToDelete));
            var newArrImages = images.filter((image) => image.id != parseInt(imageToDelete));
            setImagesKeys(newArrKeys);
            setImages(newArrImages);

            if (activeTab == imageToDelete) {
                setActiveTab('' + newArrKeys[0]);
            }
            setDeleteConfirmVisible(false);
            setImageToDelete(null);
            let deleted = deletedImages.slice();
            deleted.push(imageToDelete);
            setDeletedImages(deleted);
        }
    };

    const reorderImages = (reordered) => {
        setImages(reordered);
    };

    if (fetching) {
        return <Spinner />;
    } else {
        return (
            <React.Fragment>
                <Divider>
                    {props.userComponent == 'ImageLinksCarousel'
                        ? geti18nText('component.imagelinkscarousel')
                        : geti18nText('component.imagelinks')}
                </Divider>
                <Form>
                    {props.showReorder && <SortImagesOrVideos images={images} reorderImages={reorderImages} />}
                    <Row hidden={props.showReorder ? true : false}>
                        <Col span={24}>
                            <Form.Item hidden={true}>{getFieldDecorator('id')(<Input hidden={true} />)}</Form.Item>
                            <InputByLanguage
                                notRequred
                                fieldName="title"
                                label={geti18nText('component.imagelinks.edit.title')}
                                langJson={mainTitleLangJson}
                                {...props}
                            />
                        </Col>
                    </Row>
                    <Row hidden={props.showReorder ? true : false}>
                        <Col span={24}>
                            <Popconfirm
                                placement="rightTop"
                                title={geti18nText('app.default.button.delete')}
                                visible={deleteConfirmVisible}
                                okText={geti18nText('app.default.button.yes')}
                                cancelText={geti18nText('app.default.button.no')}
                                onCancel={() => {
                                    setDeleteConfirmVisible(false);
                                }}
                                onConfirm={deleteImage}
                            >
                                <span></span>
                            </Popconfirm>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '20px ' }} hidden={props.showReorder ? true : false}>
                        <Col span={24}>
                            <Tabs type="editable-card" onEdit={onEdit} onChange={tabOnChange} activeKey={activeTab}>
                                {images.map((image, index) => (
                                    <TabPane tab={'Image ' + (index + 1)} key={image.id} forceRender={true}>
                                        <ImageTab
                                            index={image.id}
                                            tileLangJson={image.titleLangJson}
                                            textLangJson={image.textLangJson}
                                            externalLink={image.externalLink}
                                            type={image.type}
                                            project={image.project}
                                            {...props}
                                        />
                                    </TabPane>
                                ))}
                            </Tabs>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24} style={{ textAlign: 'right', marginTop: '1rem' }}>
                            <Button style={{ marginRight: '1em' }} onClick={(e) => props.closePopup()}>
                                {geti18nText('app.default.button.cancel')}
                            </Button>
                            <Button disabled={isDisabled} style={{ marginRight: '1em' }} type="primary" onClick={(e) => save(e, false)}>
                                {geti18nText('app.default.button.save')}
                            </Button>
                            <Button
                                type="primary"
                                style={{ background: '#31b531', borderColor: '#31b531', textColor: 'white' }}
                                disabled={isDisabled}
                                onClick={(e) => {
                                    save(e, true);
                                }}
                            >
                                {geti18nText('app.default.button.save.close')}
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </React.Fragment>
        );
    }
});

const CounterResponse = (function () {
    let counterResponse = 1;
    return {
        increment: () => {
            return counterResponse= ++counterResponse;
        },
        decrement:()=> {
             counterResponse= --counterResponse;

        },
        getValue: ()  => {
            return counterResponse;
        }
}})();

const ImageTab = (props) => {
    const LINK_TYPE = {
        LINK: 'LINK',
        WEBPAGE: 'WEBPAGE',
    };

    const { getFieldDecorator, setFieldsValue } = props.form;
    const [linkType, setLinkType] = useState(props.type ? props.type : LINK_TYPE.WEBPAGE);
    const [selectedProjectId, setSelectedProjectId] = useState(props.project ? props.project : Session.getProjectId());
    const [initSelectedProjectId, setInitSelectedProjectId] = useState();
    const [externalLink, setExternalLink] = useState(props.externalLink);

    useEffect(() => {
        setInitProject();
    }, []);

    function onSelectComponentProject(component, index) {
        const key = 'image_' + index + '-link';
        const obj = {};
        obj[key] = {};
        props.form.setFieldsValue(obj);
        if (component) {
            setSelectedProjectId(parseInt(component.key));
        } else {
            setSelectedProjectId(Session.getProjectId());
        }
    }

    function setInitProject() {
        const proId = Session.getProjectId();
        const proName = Session.getProjectInfo().name;
        setInitSelectedProjectId({ id: proId, name: proName });
    }

    return (
        <>
            <Row gutter={24}>
                <Col span={24}>
                    <InputByLanguage
                        fieldName={'image_' + props.index + '-title'}
                        label={geti18nText('component.imagelinks.edit.image_title')}
                        langJson={props.tileLangJson}
                        notRequred={true}
                        {...props}
                    />

                    <Form.Item label={geti18nText('component.imagelinks.edit.image')}>
                        {getFieldDecorator('image_' + props.index + '-file_path', {
                            rules: [{ required: true, message: geti18nText('app.default.required') }],
                        })(
                            <FileUpload
                                fileType={CONSTANTS_FILE_TYPE.IMAGE}
                                map={{ id: 'id', label: 'name' }}
                                searchBy="name"
                                disabled={false}
                                addedData={{ projectId: Session.getProjectId() }}
                            />
                        )}
                    </Form.Item>

                    <Form.Item>
                        {getFieldDecorator('image_' + props.index + '-type', { initialValue: linkType })(
                            <Radio.Group
                                onChange={(e) => {
                                    setLinkType(e.target.value);
                                }}
                            >
                                <Radio value={LINK_TYPE.WEBPAGE}>
                                    {geti18nText('component.imagelinks.edit.webpage')}
                                </Radio>
                                <Radio value={LINK_TYPE.LINK}>
                                    {geti18nText('component.imagelinks.edit.external_link')}
                                </Radio>
                            </Radio.Group>
                        )}
                    </Form.Item>

                    {linkType == LINK_TYPE.LINK ? (
                        <Row gutter={24} type="flex" justify="space-between" align="bottom">
                            <Col span={16}>
                                <Form.Item label={geti18nText('component.imagelinks.edit.external_link')}>
                                    {getFieldDecorator('image_' + props.index + '-external-link', {
                                        initialValue: externalLink,
                                    })(<Input defaultValue={externalLink} />)}
                                </Form.Item>
                            </Col>
                        </Row>
                    ) : (
                        <Row>
                            <Col span={8}>
                                <Form.Item label={geti18nText('component.imagelinks.edit.project')}>
                                    {getFieldDecorator('image_' + props.index + '-project', {
                                        initialValue: initSelectedProjectId,
                                    })(
                                        <SearchField
                                            onChange={(com) => onSelectComponentProject(com, props.index)}
                                            url={CONSTANTS_REQ.PROJECT.SEARCH}
                                            map={{ id: 'id', label: 'name' }}
                                            searchBy="name"
                                            translate={false}
                                            allowClear={false}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={8} offset={1}>
                                <Form.Item label={geti18nText('component.imagelinks.edit.webpage')}>
                                    {getFieldDecorator('image_' + props.index + '-link')(
                                        <SearchField
                                            url={CONSTANTS_REQ.WEBPAGE.SEARCH}
                                            map={{ id: 'id', label: 'name' }}
                                            searchBy="name"
                                            disabled={false}
                                            translate={true}
                                            addedData={{ projectId: selectedProjectId, forMap: true }}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                    )}
                </Col>
            </Row>
        </>
    );
};



export default ImageLinksEdit;
