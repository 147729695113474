import React, { useState, useEffect } from "react";
import { Row, Col, Form, Modal, Button, message, Typography, Icon } from "antd";
import { CONSTANTS_REQ } from "../../../utils/Constants";
import { Session, geti18nText } from "../../../utils/Session";
import { useDispatch } from 'react-redux';
import { refreshMenu } from '../../../slices/menuSlice';
import { SearchField } from '../../../components//Search';
import { RESPONSE, requestGet, requestPost } from '../../../utils/RequestResolver';

const { Text } = Typography;

const WebPageCopy = Form.create({ name: 'WebPageCopy' })((props) => {

    const dispatch = useDispatch();
    const { getFieldDecorator, setFieldsValue } = props.form;
    const [selectedProjectId, setSelectedProjectId] = useState(Session.getProjectId);
    const [selectedPageType, setSelectedPageType] = useState(undefined);
    const [project, setProject] = useState(Session.getProjectInfo);
    const [hasLink, setHasLink] = useState(false);

    useEffect(() => {
        props.form.setFieldsValue({newProjectId: {key: project.id, label: project.name}});
    }, [project]);

    useEffect(() => {
        requestGet(CONSTANTS_REQ.WEBPAGE.HAS_LINK + "/" + props.oldWebPageId)
        .then((result) => {
            if (result.status == RESPONSE.OK && result.data != undefined) {
                setHasLink(result.data);
            }
        });
    }, [props.visible]);

    function refresh() {
        dispatch(
            refreshMenu({
                refreshLastNode: false,
                refreshProjectNode: true,
                refreshNodes: [],
            })
        );
    }

    function onTypeChanged(value) {
        if (value == undefined) {
            setSelectedPageType(undefined);
        } else {
            setSelectedPageType(value.key);
        }
    }

    function onCopyModalSave() {
        props.form.validateFields().then((values) => {
            requestPost(CONSTANTS_REQ.WEBPAGE.COPY, undefined, {
                oldWebPageId: props.oldWebPageId,
                newProjectId: selectedProjectId,
                idPageType: selectedPageType,
            }).then((result) => {
                if (result && result !== null) {
                    message.success(`${geti18nText('webpage.copy.ok')} `);
                    props.closeModal();
                    refresh();
                } else {
                    message.error(`${geti18nText('webpage.copy.error')} `);
                }
            });
        });
    }

    function onSelectProject(component) {
        if(component){
            setSelectedProjectId(parseInt(component.key));
        }
        else {
            setSelectedProjectId(undefined);
        }

    }
    
    return (
        <>
            <Modal
                    visible={props.visible}
                    onCancel={props.closeModal}
                    width={500}
                    title={geti18nText('webpage.copy')}
                    footer={[
                        <Button onClick={(e) => props.closeModal()}>{geti18nText('app.default.button.cancel')}</Button>,
                        <Button type="primary" htmlType="submit" onClick={(arg) => onCopyModalSave()}>
                            {geti18nText('app.default.button.save')}
                        </Button>,
                    ]}
                >
                    <Form 
                        form={props.form}
                        layout="horizontal" 
                        labelCol={{ span: 4 }}
                    >
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item label={geti18nText('webpage.project')} >
                                    {getFieldDecorator('newProjectId', {
                                        rules: [{ required: true, message: geti18nText("app.default.required") }],
                                    })(
                                        <SearchField
                                            onChange={onSelectProject}
                                            url={CONSTANTS_REQ.PROJECT.SEARCH}
                                            map={{ id: 'id', label: 'text' }}
                                            searchBy="name"
                                            translate={false}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item label={geti18nText('webpage.edit.categoryType')}>
                                    {getFieldDecorator('idPageType')(
                                        <SearchField
                                            onChange={onTypeChanged}
                                            url={CONSTANTS_REQ.PAGETYPE.SEARCH}
                                            map={{ id: 'id', label: 'name' }}
                                            searchBy="name"
                                            disabled={false}
                                            translate={true}
                                            addedData={{ projectId: selectedProjectId, forPage: true }}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                    {hasLink && project.id !== selectedProjectId && <Text type="warning"><Icon type="warning" /> {geti18nText('webpage.copy.warning')}</Text>}
            </Modal>
        </>
    );
});

export default WebPageCopy;
