import React, { useState, useEffect } from 'react';
import { Form, Row, Col, Input, Button, Divider, Tabs, Popconfirm, notification } from 'antd';
import { Session, geti18nText } from '../../utils/Session';
import { normalizeComponents } from '../../utils/Utils';
import { requestGet, requestPut, RESPONSE } from '../../utils/RequestResolver';
import { CONSTANTS_FILE_TYPE, CONSTANTS_REQ } from '../../utils/Constants';
import { FileUpload } from '../fileUpload/FileUpload';
import { InputByLanguage, translationsToObject } from '../InputByLanguage';
import { Spinner } from '../../components/Spinner';
import { SearchField } from '../../components/Search';
import { ColorPicker } from '../../components/ColorPicker';
import SortImagesOrVideos, { ReorderImageKeys } from '../SortImagesOrVideos';

const FlexImageVerticalEdit = Form.create({ name: 'VideoBlockEdit' })((props) => {
    const { getFieldDecorator, setFieldsValue } = props.form;
    const [fetching, setFetching] = useState(false);
    const [linkTextJson, setLinkTextJson] = useState(undefined);
    const [imagesKeys, setImageKeys] = useState([0]);
    const [lastKey, setLastKey] = useState(0);
    const [images, setImages] = useState([]);
    const [formValues, setFormValues] = useState(null);
    const [deletedImages, setDeletedImages] = useState([]);
    const [activeTab, setActiveTab] = useState('0');
    const [confirmVisible, setConfirmVisible] = useState(false);
    const [imageToDelete, setImageToDelete] = useState(null);
    const [type, setType] = useState('link');
    const [tabsKey, setTabsKey] = useState(Date.now());
    const [isDisabled, setIsDisabled] = useState(true);
    const { TabPane } = Tabs;

    useEffect(() => {
        fetch();
    }, [props.visible]);

    useEffect(() => {
        props.form.resetFields();

        for (const key of imagesKeys) {
            if (formValues && formValues['webPage_' + key]) {
                setWepPageValue(key, formValues['webPage_' + key].id);
                delete formValues['webPage_' + key];
            }
        }
        setFieldsValue(formValues);
    }, [formValues]);


     useEffect(()=>{
        if(CounterResponse.getValue() === 1) {
            setIsDisabled(false);
        }
        else {
            setIsDisabled(true);
        }
    },[CounterResponse.getValue(),CounterResponse.decrement,CounterResponse.increment]);
 

    useEffect(()=>{
  
    },[isDisabled]); 


    function fetch() {
        setFetching(true);
        requestGet(CONSTANTS_REQ.WEBPAGECOMPONENT.EDIT + '/' + props.id).then((result) => {
            setFetching(false);
            if (result.status === RESPONSE.OK) {
                setValues(result.data);
            }
        });
    }

    function setValues(component_data) {
        let vidArray = [];
        var json;
        if (component_data.json) {
            json = JSON.parse(component_data.json);

            let max_key = 0;
            for (const key of json.imagesKeys) {
                vidArray.push({
                    id: key,
                    titleLangJson: json['title_' + key],
                    rdlPath: json['image-path_' + key]['file_path'],
                    textLangJson: json['text_' + key],
                });
                setWepPageValue(key, json['image-path_' + key]['file_path']);
                json = translationsToObject(json, 'title_' + key, json['title_' + key]);
                delete json['title_' + key];
                json = translationsToObject(json, 'text_' + key, json['text_' + key]);
                delete json['text_' + key];
                max_key = key > max_key ? key : max_key;
            }

            setLastKey(max_key);
            json = translationsToObject(json, 'linkText', json['linkText']);
            setLinkTextJson(json['linkText']);
            setImages(vidArray);
            setImageKeys(json.imagesKeys);
            setActiveTab('' + json.imagesKeys[0]);
            setType(json.type);
            json.type = types.find(({ id }) => id === json.type);

            delete json.totalVideos;
            delete json.linkText;
            delete json.imagesKeys;
            delete json['title'];

           setFormValues(json);
        } else {
            setImageKeys([0]);
            setImages([{ id: 0 }]);
        }
    }

    function setWepPageValue(key, id) {
       CounterResponse.increment();
        requestGet(CONSTANTS_REQ.WEBPAGE.EDIT + '/' + id).then((result) => {
            if (result.status === RESPONSE.OK) {
                let field = {};
                field['webPage_' + key] = { id: id, name: result.data.name };
                setFieldsValue(field);
            } 
            CounterResponse.decrement();

            // useEffect se zbog nekog razloga ne poziva kak spada dok je high latency, s ovim se disabled pravilno postavlja
            CounterResponse.getValue()===1? setIsDisabled(false):setIsDisabled(true);
        });
    }

    function showNotification(title, message, duration, type) {
        notification[type]({
            message: title,
            description: message,
            duration: duration,
        });
    }

    function okNotification() {
        showNotification(geti18nText('app.default.save.ok'), geti18nText('app.default.save.ok.desc'), 3, 'success');
    }

    function error(message) {
        showNotification(geti18nText('app.default.save.nok'), message, 3, 'error');
    } 

    function save(e, close) {
        e.preventDefault();
        props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                values = normalizeComponents(values);
                values.imagesKeys = imagesKeys;
                values.type = values.type != null && values.type.constructor === String ? values.type : values.type.id;
                for (const key of imagesKeys) {
                    values['image-path_' + key] = normalizeFileUpload('image-path_' + key);
                    values['webPage_' + key] =
                        values['webPage_' + key] != null && values['webPage_' + key].constructor === String
                            ? { id: parseInt(values['webPage_' + key]) }
                            : values['webPage_' + key];
                }

                for (const deleted of deletedImages) {
                    if (values['image-path_' + deleted]) {
                        delete values['image-path_' + deleted];
                    }
                }
                if (props.showReorder) {
                    values.imagesKeys = ReorderImageKeys(images);
                }

                requestPut(CONSTANTS_REQ.WEBPAGECOMPONENT.UPDATEJSON + '/' + props.id, null, {
                    id: props.id,
                    json: JSON.stringify(values),
                }).then((result) => {
                    if (result && result.status === RESPONSE.CREATED) {
                        console.log('okNotification');
                        okNotification();
                        close && props.closePopup();
                    } else {
                        error();
                        if (result && result.status === RESPONSE.BAD_REQUEST) {
                            if (result.data && result.data.field) {
                                switch (result.data.field) {
                                    case 'mandatory_fields_required':
                                        console.log(geti18nText('app.default.mandatory_fields_required'));
                                        return;
                                    default:
                                        break;
                                }
                            }
                        }
                        if (result.data && result.data.error) {
                            console.log(JSON.stringify(result.data.error));
                        }
                    }
                });
            }
        });
    }

    function normalizeFileUpload(field) {
        let file = props.form.getFieldsValue([field])[field];
        if (file instanceof Array) {
            file = file[0];
        }
        return file.id ? file : { file_path: file.file_path, name: file.name };
    }

    const onEdit = (targetKey, action) => {
        if (action == 'add') {
            props.form.validateFieldsAndScroll((err, values) => {
                if (!err) {
                    let newId = lastKey;
                    newId++;

                    let arrVideos = images.slice();
                    let arrKeys = imagesKeys.slice();
                    arrVideos.push({ id: newId });
                    arrKeys.push(newId);
                    setImageKeys(arrKeys);
                    setImages(arrVideos);
                    setActiveTab('' + newId);
                    setLastKey(newId);
                }
            });
        }
        if (action == 'remove') {
            if (imagesKeys.length > 1) {
                setConfirmVisible(true);
                setImageToDelete(targetKey);
            }
        }
    };

    const deleteImage = () => {
        if (imageToDelete) {
            var newArrKeys = imagesKeys.filter((key) => key != parseInt(imageToDelete));
            var newArrVideos = images.filter((video) => video.id != parseInt(imageToDelete));
            setImages(newArrVideos);
            setImageKeys(newArrKeys);

            if (activeTab == imageToDelete) {
                setActiveTab('' + newArrKeys[0]);
            }
            setConfirmVisible(false);
            setImageToDelete(null);
            let deleted = deletedImages.slice();
            deleted.push(imageToDelete);
            setDeletedImages(deleted);
        }
    };

    const tabOnChange = (activeKey) => {
        props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                setActiveTab(activeKey);
            }
        });
    };

    const types = [
        { id: 'link', text: geti18nText('component.fleximagevertical.type.link') },
        { id: 'linkWithDesctiption', text: geti18nText('component.fleximagevertical.type.linkWithDesctiption') },
        { id: 'desctiption', text: geti18nText('component.fleximagevertical.type.desctiption') },
        { id: 'blog', text: geti18nText('component.fleximagevertical.type.blog') },
    ];

    function onSelectTypeChange(value) {
        if (value) {
            setType(value.key);
            setTabsKey(Date.now());
        }
    }

    const reorderImages = (reordered) => {
        setImages(reordered);
    };

    if (fetching) {
        return <Spinner />;
    } else {
        return (
            <React.Fragment>
                <Divider>{geti18nText('component.fleximagevertical')}</Divider>
                <Form>
                    {props.showReorder && <SortImagesOrVideos images={images} reorderImages={reorderImages} />}
                    <Row hidden={props.showReorder ? true : false}>
                        <Col span={24}>
                            <Form.Item label={geti18nText('component.fleximagevertical.edit.type')}>
                                {getFieldDecorator('type', {
                                    rules: [{ required: true, message: geti18nText('app.default.required') }],
                                    initialValue: {
                                        id: 'link',
                                        text: geti18nText('component.fleximagevertical.type.link'),
                                    },
                                })(<SearchField options={types} onChange={onSelectTypeChange} />)}
                            </Form.Item>
                        </Col>
                    </Row>
                    {(type == 'linkWithDesctiption' || type == 'link') && (
                        <Row hidden={props.showReorder ? true : false}>
                            <Col span={24}>
                                <InputByLanguage
                                    notRequred
                                    fieldName={'linkText'}
                                    label={geti18nText('component.fleximagevertical.edit.linkText')}
                                    langJson={linkTextJson}
                                    {...props}
                                />
                            </Col>
                        </Row>
                    )}
                    <Row hidden={props.showReorder ? true : false}>
                        <Col span={24}>
                            <Popconfirm
                                placement="rightTop"
                                title={geti18nText('app.default.button.delete')}
                                visible={confirmVisible}
                                okText={geti18nText('app.default.button.yes')}
                                cancelText={geti18nText('app.default.button.no')}
                                onCancel={() => {
                                    setConfirmVisible(false);
                                }}
                                onConfirm={deleteImage}
                            >
                                <span></span>
                            </Popconfirm>
                        </Col>
                    </Row>
                    <Row hidden={props.showReorder ? true : false} style={{ marginTop: '20px ' }} key={tabsKey}>
                        <Col span={24}>
                            <Tabs type="editable-card" onEdit={onEdit} onChange={tabOnChange} activeKey={activeTab}>
                                {images.map((video, index) => (
                                    <TabPane tab={'Slika ' + (index + 1)} key={video.id} forceRender={true}>
                                        <EditTab
                                            index={video.id}
                                            tileLangJson={video.titleLangJson}
                                            textLangJson={video.textLangJson}
                                            type={type}
                                            {...props}
                                        />
                                    </TabPane>
                                ))}
                            </Tabs>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24} style={{ textAlign: 'right', marginTop: '1rem' }}>
                            <Button style={{ marginRight: '1em' }} onClick={(e) => props.closePopup()}>
                                {geti18nText('app.default.button.cancel')}
                            </Button>
                            <Button disabled={isDisabled} style={{ marginRight: '1em' }} type="primary" onClick={(e) => save(e, false)}>
                                {geti18nText('app.default.button.save')}
                            </Button>
                            <Button
                            disabled={isDisabled}
                                type="primary"
                                style={{ background: '#31b531', borderColor: '#31b531', textColor: 'white' }}
                                onClick={(e) => {
                                    save(e, true);
                                }}
                            >
                                {geti18nText('app.default.button.save.close')}
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </React.Fragment>
        );
    }
});

const CounterResponse = (function () {
    let counterResponse = 1;
    return {
        increment: () => {
            return counterResponse= ++counterResponse;
        },
        decrement:()=> {
             counterResponse= --counterResponse;

        },
        getValue: ()  => {
            return counterResponse;
        }
}})();


const EditTab = (props) => {
    const { getFieldDecorator, setFieldsValue } = props.form;

    return (
        <>
            <Row gutter={24}>
                <Col span={24}>
                    <InputByLanguage
                        notRequred
                        fieldName={'title_' + props.index}
                        label={geti18nText('component.fleximagevertical.edit.title')}
                        langJson={props.tileLangJson}
                        {...props}
                    />
                    {(props.type == 'linkWithDesctiption' || props.type == 'desctiption') && (
                        <InputByLanguage
                            notRequred
                            fieldName={'text_' + props.index}
                            type={'textArea'}
                            label={geti18nText('component.fleximagevertical.edit.description')}
                            langJson={props.textLangJson}
                            {...props}
                        />
                    )}
                    <Form.Item label={geti18nText('component.fleximagevertical.edit.image')}>
                        {getFieldDecorator('image-path_' + props.index, {
                            rules: [{ required: true, message: geti18nText('app.default.required') }],
                        })(
                            <FileUpload
                                fileType={CONSTANTS_FILE_TYPE.IMAGE}
                                map={{ id: 'id', label: 'name' }}
                                searchBy="name"
                                disabled={false}
                                addedData={{ projectId: Session.getProjectId(), fileType: CONSTANTS_FILE_TYPE.IMAGE }}
                            />
                        )}
                    </Form.Item>
                    {(props.type == 'linkWithDesctiption' || props.type == 'link' || props.type == 'blog') && (
                        <>
                            <Form.Item label={geti18nText('component.fleximagevertical.edit.link')}>
                                {getFieldDecorator('webPage_' + props.index)(
                                    <SearchField
                                        url={CONSTANTS_REQ.WEBPAGE.SEARCH}
                                        map={{ id: 'id', label: 'name' }}
                                        searchBy="name"
                                        disabled={false}
                                        translate={true}
                                        addedData={{ projectId: Session.getProjectId() }}
                                    />
                                )}
                            </Form.Item>
                            <Form.Item label={geti18nText('component.fleximagevertical.edit.link.outside')}>
                                {getFieldDecorator('link_' + props.index)(<Input />)}
                            </Form.Item>
                        </>
                    )}
                    {props.type == 'blog' && (
                        <Form.Item label={geti18nText('component.fleximagevertical.edit.date')}>
                            {getFieldDecorator('date_' + props.index)(<Input />)}
                        </Form.Item>
                    )}
                </Col>
            </Row>
        </>
    );
};

export default FlexImageVerticalEdit;
