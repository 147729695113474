import React, { forwardRef, useState, useRef } from "react";
import { Input, Form, Button, Modal, Row } from "antd";
import { geti18nText } from "../../utils/Session";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { Map, TileLayer } from "react-leaflet";

const InputMapCoordinates = (props) => {
    const [modalVisible, setModalVisible] = useState(false);

    const { getFieldDecorator, setFieldsValue } = props.form;
    const mapRef = useRef(null);

    const getGlobalCoordinates = () => {
        setFieldsValue({
            latitude: mapRef.current.leafletElement.getCenter().lat,
            longitude: mapRef.current.leafletElement.getCenter().lng,
            zoom: mapRef.current.leafletElement.getZoom(),
        });
        setModalVisible(false); 
    };

    return (
        <React.Fragment>
            <Form.Item label={geti18nText("inputmapcoordinates.lat")}>{getFieldDecorator("latitude", {
                rules: [{ required: props.requred, message: geti18nText("app.default.required") }]
            })(<Input />)}</Form.Item>
            <Form.Item label={geti18nText("inputmapcoordinates.lng")}>{getFieldDecorator("longitude", {
                rules: [{ required: props.requred, message: geti18nText("app.default.required") }]
            })(<Input />)}</Form.Item>
            <Form.Item label={geti18nText("inputmapcoordinates.zoom")}>{getFieldDecorator("zoom", {
                rules: [{ required: props.requred, message: geti18nText("app.default.required") }]
            })(<Input />)}</Form.Item>
            <Form.Item>
                <Button type="primary" onClick={() => setModalVisible(true)}>
                    {geti18nText("inputmapcoordinates.button")}
                </Button>
            </Form.Item>

            <Modal title={geti18nText("inputmapcoordinates.modalTitle")} visible={modalVisible} onCancel={() => setModalVisible(false)} onOk={getGlobalCoordinates} destroyOnClose={true} okText={geti18nText("inputmapcoordinates.modalOkText")} width={"800px"}>
                <Map
                    center={props.form.getFieldValue("latitude") != null && props.form.getFieldValue("longitude") != null ? [props.form.getFieldValue("latitude"), props.form.getFieldValue("longitude")] : [0, 0]}
                    zoom={props.form.getFieldValue("zoom") != null ? props.form.getFieldValue("zoom") : 3}
                    style={{ height: "500px", width: "100%" }}
                    ref={mapRef}
                >
                    <TileLayer attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors' url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                </Map>
            </Modal>
        </React.Fragment>
    );
};

export default InputMapCoordinates;
