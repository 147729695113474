import React, { useState, useEffect } from 'react';
import { Form, Row, Col, Button, Divider, Select, notification } from 'antd';
import { Session, geti18nText } from '../../utils/Session';
import { normalizeComponents } from '../../utils/Utils';
import { requestGet, requestPut, RESPONSE } from '../../utils/RequestResolver';
import { InputByLanguage, translationsToObject } from '../InputByLanguage';
import { Spinner } from '../../components/Spinner';
import { SearchField } from '../../components/Search';
import { CONSTANTS_REQ, CONSTANTS_FILE_TYPE } from '../../utils/Constants';
import { FileUpload } from '../fileUpload/FileUpload';

const BtcNewsletterEdit = Form.create({ name: 'VideoBlockEdit' })((props) => {
    const { getFieldDecorator, setFieldsValue } = props.form;
    const [fetching, setFetching] = useState(false);
    const [formValues, setFormValues] = useState(null);
    const [textJson, setTextJson] = useState(null);
    const [buttonJson, setButtonJsons] = useState(null);
    const [emailErrorJson, setEmailErrorJson] = useState(null);
    const [termsJson, setTermsJson] = useState(null);
    const [buttonSendJson, setButtonSendJson] = useState(null);
    const [onErrorJson, setOnErrorJson] = useState(null);
    const [onSuccessJson, setOnSuccessJson] = useState(null);
    const [termsLinkTextJson, setTermsLinkTextJson] = useState(null);
    const [emails, setEmails] = useState(null);

    const [nameJson, setNameJson] = useState(null);
    const [companyJson, setCompanyJson] = useState(null);
    const [messageJson, setMessageJson] = useState(null);
    const [type, setType] = useState(undefined);
    const typeEnums = [
        { id: 'newsletter', text: 'Newsletter' },
        { id: 'inquiry', text: 'Inquiry' },
    ];

    useEffect(() => {
        fetch();
    }, [props.visible]);

    useEffect(() => {
        props.form.resetFields();

        if (formValues && formValues['webPageTerms']) {
            setWepPageValue('webPageTerms', formValues['webPageTerms'].id);
            delete formValues['webPageTerms'];
        }
        setFieldsValue(formValues);
    }, [formValues]);

    function fetch() {
        setFetching(true);
        requestGet(CONSTANTS_REQ.WEBPAGECOMPONENT.EDIT + '/' + props.id).then((result) => {
            setFetching(false);
            if (result.status === RESPONSE.OK) {
                setValues(result.data);
            }
        });
    }

    function setValues(component_data) {
        var json;
        if (component_data.json) {
            json = JSON.parse(component_data.json);

            json = translationsToObject(json, 'text', json.text);
            json = translationsToObject(json, 'button', json.button);
            json = translationsToObject(json, 'emailError', json.emailError);
            json = translationsToObject(json, 'terms', json.terms);
            json = translationsToObject(json, 'buttonSend', json.buttonSend);
            json = translationsToObject(json, 'termsLinkText', json.termsLinkText);
            json = translationsToObject(json, 'onError', json.onError);
            json = translationsToObject(json, 'onSuccess', json.onSuccess);
            json = translationsToObject(json, 'message', json.message);
            json = translationsToObject(json, 'company', json.company);
            json = translationsToObject(json, 'name', json.name);
            setTextJson(json.text);
            setButtonJsons(json.button);
            setEmailErrorJson(json.emailError);
            setTermsJson(json.terms);
            setButtonSendJson(json.buttonSend);
            setOnErrorJson(json.onError);
            setOnSuccessJson(json.onSuccess);
            setTermsLinkTextJson(json.termsLinkText);
            setNameJson(json.name);
            setMessageJson(json.message);
            setCompanyJson(json.company);
            setEmails(json.emails);
            delete json.text;
            delete json.button;
            delete json.emailError;
            delete json.terms;
            delete json.buttonSend;
            delete json.onError;
            delete json.onSuccess;
            delete json.termsLinkText;

            json.type = typeEnums.find((val) => val.id == json.type);
            setFormValues(json);
        }
    }

    function setWepPageValue(key, id) {
        requestGet(CONSTANTS_REQ.WEBPAGE.EDIT + '/' + id).then((result) => {
            if (result.status === RESPONSE.OK) {
                let field = {};
                field[key] = { id: id, name: result.data.name };
                setFieldsValue(field);
            }
        });
    }

    function showNotification(title, message, duration, type) {
        notification[type]({
            message: title,
            description: message,
            duration: duration,
        });
    }

    function okNotification() {
        showNotification(geti18nText('app.default.save.ok'), geti18nText('app.default.save.ok.desc'), 3, 'success');
    }

    function error(message) {
        showNotification(geti18nText('app.default.save.nok'), message, 3, 'error');
    } 

    function save(e, close) {
        e.preventDefault();
        props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                values = normalizeComponents(values);
                values.webPageTerms =
                    values.webPageTerms != null && values.webPageTerms.constructor === String
                        ? { id: parseInt(values.webPageTerms) }
                        : values.webPageTerms;
                values.type =
                    values.type != null ? (values.type.constructor === String ? values.type : values.type.id) : null;

                values.emails = emails != null && emails;

                requestPut(CONSTANTS_REQ.WEBPAGECOMPONENT.UPDATEJSON + '/' + props.id, null, {
                    id: props.id,
                    json: JSON.stringify(values),
                }).then((result) => {
                    if (result && result.status === RESPONSE.CREATED) {
                        console.log('okNotification');
                        okNotification();
                        close && props.closePopup();
                    } else {
                        error();
                        if (result && result.status === RESPONSE.BAD_REQUEST) {
                            if (result.data && result.data.field) {
                                switch (result.data.field) {
                                    case 'mandatory_fields_required':
                                        console.log(geti18nText('app.default.mandatory_fields_required'));
                                        return;
                                    default:
                                        break;
                                }
                            }
                        }
                        if (result.data && result.data.error) {
                            console.log(JSON.stringify(result.data.error));
                        }
                    }
                });
            }
        });
    }

    function normalizeFileUpload(field) {
        let file = props.form.getFieldsValue([field])[field];
        if (file instanceof Array) {
            file = file[0];
        }
        return file.id ? file : { file_path: file.file_path, name: file.name };
    }

    const changeEmails = (value) => {
        setEmails(value);
    };

    if (fetching) {
        return <Spinner />;
    } else {
        return (
            <React.Fragment>
                <Divider>{geti18nText('component.btcnewsletter')}</Divider>
                <Form>
                    <Row style={{ marginTop: '20px ' }}>
                        <Col span={6}>
                            <Form.Item label={geti18nText('component.btcnewsletter.edit.type')}>
                                {getFieldDecorator('type', {
                                    initialValue: { id: 'newsletter', text: 'Newsletter' },
                                    rules: [{ required: true, message: geti18nText('app.default.required') }],
                                })(
                                    <SearchField
                                        options={typeEnums}
                                        map={{ id: 'id', label: 'text' }}
                                        searchBy="text"
                                    />
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={17} offset={1}>
                            <Form.Item label={geti18nText('component.btcnewsletter.edit.emails')}>
                                {getFieldDecorator('emails')(
                                    <Select
                                        mode="tags"
                                        notFoundContent={null}
                                        style={{ width: '100%' }}
                                        onChange={changeEmails}
                                    />
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <InputByLanguage
                                notRequred
                                fieldName={'text'}
                                label={geti18nText('component.btcnewsletter.edit.text')}
                                langJson={textJson}
                                {...props}
                            />
                            <InputByLanguage
                                notRequred
                                fieldName={'button'}
                                label={geti18nText('component.btcnewsletter.edit.button')}
                                langJson={buttonJson}
                                {...props}
                            />
                            <InputByLanguage
                                notRequred
                                fieldName={'emailError'}
                                label={geti18nText('component.btcnewsletter.edit.emailError')}
                                langJson={emailErrorJson}
                                {...props}
                            />
                            <InputByLanguage
                                notRequred
                                fieldName={'terms'}
                                label={geti18nText('component.btcnewsletter.edit.terms')}
                                langJson={termsJson}
                                {...props}
                            />
                            <InputByLanguage
                                notRequred
                                fieldName={'termsLinkText'}
                                label={geti18nText('component.btcnewsletter.edit.termsLinkText')}
                                langJson={termsLinkTextJson}
                                {...props}
                            />
                            <Form.Item label={geti18nText('component.btcnewsletter.edit.link')}>
                                {getFieldDecorator('webPageTerms', {
                                    rules: [{ required: true, message: geti18nText('app.default.required') }],
                                })(
                                    <SearchField
                                        url={CONSTANTS_REQ.WEBPAGE.SEARCH}
                                        map={{ id: 'id', label: 'name' }}
                                        searchBy="name"
                                        disabled={false}
                                        translate={true}
                                        addedData={{ projectId: Session.getProjectId() }}
                                    />
                                )}
                            </Form.Item>
                            <InputByLanguage
                                notRequred
                                fieldName={'buttonSend'}
                                label={geti18nText('component.btcnewsletter.edit.buttonSend')}
                                langJson={buttonSendJson}
                                {...props}
                            />
                            <InputByLanguage
                                notRequred
                                fieldName={'onSuccess'}
                                label={geti18nText('component.btcnewsletter.edit.onSuccess')}
                                langJson={onSuccessJson}
                                {...props}
                            />
                            <InputByLanguage
                                notRequred
                                fieldName={'onError'}
                                label={geti18nText('component.btcnewsletter.edit.onError')}
                                langJson={onErrorJson}
                                {...props}
                            />

                            <InputByLanguage
                                notRequred
                                fieldName={'company'}
                                label={geti18nText('component.btcnewsletter.edit.company')}
                                langJson={companyJson}
                                {...props}
                            />
                            <InputByLanguage
                                notRequred
                                fieldName={'name'}
                                label={geti18nText('component.btcnewsletter.edit.name')}
                                langJson={nameJson}
                                {...props}
                            />
                            <InputByLanguage
                                notRequred
                                fieldName={'message'}
                                label={geti18nText('component.btcnewsletter.edit.message')}
                                langJson={messageJson}
                                {...props}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24} style={{ textAlign: 'right', marginTop: '1rem' }}>
                            <Button style={{ marginRight: '1em' }} onClick={(e) => props.closePopup()}>
                                {geti18nText('app.default.button.cancel')}
                            </Button>
                            <Button style={{ marginRight: '1em' }} type="primary" onClick={(e) => save(e, false)}>
                                {geti18nText('app.default.button.save')}
                            </Button>
                            <Button
                                type="primary"
                                style={{ background: '#31b531', borderColor: '#31b531', textColor: 'white' }}
                                onClick={(e) => {
                                    save(e, true);
                                }}
                            >
                                {geti18nText('app.default.button.save.close')}
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </React.Fragment>
        );
    }
});

export default BtcNewsletterEdit;
