import React, { useState, useEffect } from 'react';
import { Session, geti18nText, hasAnyRole } from '../../utils/Session';
import { Redirect } from 'react-router-dom';
import { Layout, Button, List, Spin, Switch, Row, Col } from 'antd';
import { AdminMainHeader } from '../../components/adminLayout/AdminMainHeader';
import { requestGet, RESPONSE, requestPost } from '../../utils/RequestResolver';
import { CONSTANTS_REQ } from '../../utils/Constants';
import { ProjectEdit } from './ProjectEdit';
import ButtonGroup from 'antd/lib/button/button-group';
import { getTranslated } from '../../components/InputByLanguage';
import Item from 'antd/lib/list/Item';

const { Content } = Layout;

const ProjectIndex = (props) => {
    const [projectList, setProjectList] = useState([]);
    const [popupVisible, setPopupVisible] = useState(false);
    const [loading, setLoading] = useState(true);
    const [checked, setChecked] = useState(true);

    useEffect(() => {
        getProjectList();
    }, []);

    useEffect(() => {
        getProjectList();
    }, [checked]);

    async function getProjectList() {
        const resp = await requestGet(CONSTANTS_REQ.PROJECT.LIST, { active: checked });
        if (resp.status === RESPONSE.OK) {
            setProjectList(resp.data.content);
            setLoading(false);
        } else {
            setLoading(false);
        }
    }

    const getIndexPage = (e, item) => {
        e.preventDefault();
        item.languages = [];
        Session.setProject(item);

        setProjectLanguages(item.id);
        setProjectPageTypes(item.id);
        props.history.push(`/${item.id}`);
    };

    const getAdminPage = (e, item) => {
        e.preventDefault();
        item.languages = [];
        Session.setProject(item);

        setProjectLanguages(item.id);
        setProjectPageTypes(item.id, props.history, '/admin/webPage');
    };

    const showModal = () => {
        setPopupVisible(true);
    };

    const onPopupSave = () => {
        getProjectList();
    };

    function onChange(checked) {
        setChecked(checked);
    }

    if (!Session.isUserAuthenticated()) {
        return <Redirect to="/login" />;
    } else {
        return (
            <React.Fragment>
                <Layout>
                    <AdminMainHeader />
                    <Layout>
                        <Layout className="main-layout" style={{ marginLeft: '0px' }}>
                            <Content className="main-content thin-scrollbar">
                                <div style={{ textAlign: 'center' }}>
                                    {!loading ? (
                                        <React.Fragment>
                                            <div className="margin-bottom">
                                                {hasAnyRole(['ROLE_ADMIN', 'ROLE_PROJECT_ADMIN']) && (
                                                    <Switch
                                                        onChange={onChange}
                                                        checkedChildren={geti18nText('projects.active')}
                                                        unCheckedChildren={geti18nText('projects.deactive')}
                                                        defaultChecked
                                                    />
                                                )}
                                            </div>
                                            <List
                                                grid={{ gutter: 16, column: 1 }}
                                                dataSource={projectList}
                                                renderItem={(item) => (
                                                    <List.Item key={Item.id}>
                                                        <ButtonGroup>
                                                            <Button
                                                                size="large"
                                                                style={{ minWidth: '200px' }}
                                                                onClick={(e) => getIndexPage(e, item)}
                                                            >
                                                                {item.name}
                                                            </Button>
                                                            <Button
                                                                size="large"
                                                                icon="lock"
                                                                onClick={(e) => getAdminPage(e, item)}
                                                            >
                                                                Admin
                                                            </Button>
                                                        </ButtonGroup>
                                                    </List.Item>
                                                )}
                                            />
                                            {hasAnyRole(['ROLE_ADMIN', 'ROLE_PROJECT_ADMIN']) && (
                                                <Button
                                                    onClick={showModal}
                                                    type="primary"
                                                    size="large"
                                                    style={{ width: '300px' }}
                                                >
                                                    {geti18nText('project.table.add')}
                                                </Button>
                                            )}
                                        </React.Fragment>
                                    ) : (
                                        <div className="spin-container">
                                            <Spin size="large" />
                                        </div>
                                    )}
                                </div>
                            </Content>
                        </Layout>
                    </Layout>
                </Layout>
                <ProjectEdit
                    isModal="true"
                    visible={popupVisible}
                    setVisible={setPopupVisible}
                    onSave={onPopupSave}
                    onSaveAndGetID={props.onSaveAndGetID}
                    addedData={props.addedData}
                />
            </React.Fragment>
        );
    }
};

export async function setProjectLanguages(id) {
    const resp = await requestGet(CONSTANTS_REQ.LANG.FORPROJECT + '/' + id);
    if (resp.status === RESPONSE.OK) {
        let project = Session.getProjectInfo();
        project.languages = resp.data;
        if (resp.data && resp.data.length > 0) {
            project.defaultLanguage = Object.keys(resp.data[0])[0];
        }
        Session.setProject(project);
        setProjectMapZoomText(id);
    }
}

export async function setProjectMapZoomText(id) {
    const resp = await requestGet(CONSTANTS_REQ.SETTINGS.ADMIN, { group: 'map', key: 'map.zoomText', projectId: id });
    if (resp.status === RESPONSE.OK) {
        if (resp.data.settingValue != null) {
            let project = Session.getProjectInfo();
            project.mapZoomText = resp.data.settingValue;
            Session.setProject(project);
        }
    }
}

export async function setProjectPageTypes(id, history = null, path = null) {
    const resp = await requestGet(CONSTANTS_REQ.PAGETYPE.SEARCH, { projectId: id, forPage: true });
    if (resp.status === RESPONSE.OK) {
        let pTypes = resp.data.map((obj) => {
            let rObj = {};
            rObj['id'] = '"' + getTranslated(obj.text, 'hr') + '"';
            rObj['text'] = getTranslated(obj.text, 'hr');
            return rObj;
        });

        Session.setProjectPageTypes(pTypes);
        if (path) {
            history.push(path);
        }
    }
}

export default ProjectIndex;
