import React, { useState, useEffect, useRef } from 'react';
import { Form, Row, Col, Input, Button, Divider, Radio, Tabs, Popconfirm } from 'antd';
import { Session, geti18nText } from '../../utils/Session';
import { requestGet, requestPut, RESPONSE } from '../../utils/RequestResolver';
import { CONSTANTS_FILE_TYPE, CONSTANTS_REQ } from '../../utils/Constants';
import { FileUpload } from '../fileUpload/FileUpload';
import { InputByLanguage, translationsToObject } from '../InputByLanguage';
import { SearchField, mapDataToSelect } from '../../components/Search';
import { normalizeJSONForComponent, getDiskFiles } from '../../utils/ComponentHelper';
import { normalizeComponents } from '../../utils/Utils';
import QRCode from 'qrcode.react';
import JoditEditor from 'jodit-react';
import { Spinner } from '../Spinner';
import { notification, Icon } from 'antd';

const ImageCardLinksEdit = Form.create({ name: 'ImageCardLinksEdit' })((props) => {
    const [fetching, setFetching] = useState(false);
    const { getFieldDecorator, setFieldsValue } = props.form;
    const { TabPane } = Tabs;
    const [lastKey, setLastKey] = useState(0);
    const [slides, setSlides] = useState([]);
    const [activeTab, setActiveTab] = useState('0');
    const [slidesKeys, setSlidesKeys] = useState([0]);
    const [deletedSlides, setDeletedSlides] = useState([]);
    const [slideToDelete, setSlideToDelete] = useState(null);
    const [formValues, setFormValues] = useState(null);
    const [confirmVisible, setConfirmVisible] = useState(false);
    const [showSpinner, setShowSpinner] = useState(true);
    const [numberOfCards, setNumberOfCards] = useState(1);

    const LINK_TYPE = {
        LINK: 'LINK',
        WEBPAGE: 'WEBPAGE',
    };
    const [linkType, setLinkType] = useState(props['type_button'] ? props['type_button'] : LINK_TYPE.WEBPAGE);

    useEffect(() => {
        fetch();
    }, [props.visible]);

    useEffect(() => {
        props.form.resetFields();

        if (slidesKeys) {
            for (const key of slidesKeys) {
                if (formValues && formValues['link_' + key]) {
                    setWepPageValue(key, formValues['link_' + key].id);
                    delete formValues['link_' + key];
                }
            }
        }

        setFieldsValue(formValues);
    }, [formValues, showSpinner]);

    useEffect(() => {}, [numberOfCards]);

    function fetch() {
        setShowSpinner(true);
        setFetching(true);

        requestGet(CONSTANTS_REQ.WEBPAGECOMPONENT.EDIT + '/' + props.id).then((result) => {
            setFetching(false);
            if (result.status === RESPONSE.OK) {
                setValues(result.data);
                setShowSpinner(false);
            } else {
                setShowSpinner(true);
                console.log('response failed');
            }
        });
    }

    /*    function setJoditArray(data) {
        // data.preventDefault();
        data && setJoditDataArray({ ...data });
    } */

    function setValues(component_data) {
        let vidArray = [];
        var json;
        if (component_data.json) {
            json = JSON.parse(component_data.json);
            json = translationsToObject(json, 'title_main', json['title_main']);
            json = translationsToObject(json, 'read-more-button_link-0', json['read-more-button_link-0']);
            let max_key = 0;
            if (json.slidesKeys) {
                for (const key of json.slidesKeys) {
                    vidArray.push({
                        id: key,
                        titleLangJson: json['title_' + key],
                        //    buttonTitleJson: json['button_title_' + key],
                        advancedEditor: json['advanced-editor_' + key],
                        textLangJson: json['description_' + key],
                        linkTextJson: json['read-more-button_' + key],
                        externalLink: json['external-link_' + key],
                        type: json['type_' + key],
                    });

                    json = translationsToObject(json, 'title_' + key, json['title_' + key]);
                    delete json['title_' + key];
                    json = translationsToObject(json, 'description_' + key, json['description_' + key]);
                    delete json['description_' + key];

                    json = translationsToObject(json, 'read-more-button_' + key, json['read-more-button_' + key]);
                    delete json['read-more-button_' + key];
                    max_key = key > max_key ? key : max_key;
                }
            }
            json.type_button = json['type_button'];

            setLinkType(json['type_button']);
            setLastKey(max_key);
            setSlides(vidArray);
            setSlidesKeys(json.slidesKeys);
            setNumberOfCards(json.slidesKeys.length);
            setActiveTab('' + json.slidesKeys[0]);
            delete json.slidesKeys;
            delete json['title'];
            setFormValues(json);
        } else {
            setSlidesKeys([0]);
            setSlides([{ id: 0 }]);
        }
    }

    function setWepPageValue(key, id) {
        requestGet(CONSTANTS_REQ.WEBPAGE.EDIT + '/' + id).then((result) => {
            if (result.status === RESPONSE.OK) {
                let field = {};
                field['link_' + key] = { id: id, name: result.data.name };
                setFieldsValue(field);
            }
        });
    }

    function showNotification(title, message, duration, type) {
        notification[type]({
            message: title,
            description: message,
            duration: duration,
        });
    }

    function okNotification() {
        showNotification(geti18nText('app.default.save.ok'), geti18nText('app.default.save.ok.desc'), 3, 'success');
    }

    function error(message) {
        showNotification(geti18nText('app.default.save.nok'), message, 3, 'error');
    } 


    function save(e, close) {
        e.preventDefault();
        props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                let buttonVal = values['link_button-0'];
                values = normalizeComponents(values);
                values.slidesKeys = slidesKeys;
                for (const key of slidesKeys) {
                    values['image-path_' + key] = normalizeFileUpload('image-path_' + key);
                    values['link_' + key] =
                        values['link_' + key] != null && values['link_' + key].constructor === String
                            ? { id: parseInt(values['link_' + key]) }
                            : values['link_' + key];
                }

                for (const deleted of deletedSlides) {
                    if (values['image-path_' + deleted]) {
                        delete values['image-path_' + deleted];
                    }
                }
                values['link_button-0'] = buttonVal;
                requestPut(CONSTANTS_REQ.WEBPAGECOMPONENT.UPDATEJSON + '/' + props.id, null, {
                    id: props.id,
                    json: JSON.stringify(values),
                }).then((result) => {
                    if (result && result.status === RESPONSE.CREATED) {
                        console.log('okNotification');
                        okNotification();
                        close && props.closePopup();
                    } else {
                        error();
                        if (result && result.status === RESPONSE.BAD_REQUEST) {
                            if (result.data && result.data.field) {
                                switch (result.data.field) {
                                    case 'mandatory_fields_required':
                                        console.log(geti18nText('app.default.mandatory_fields_required'));
                                        return;
                                    default:
                                        break;
                                }
                            }
                        }
                        if (result.data && result.data.error) {
                            console.log(JSON.stringify(result.data.error));
                        }
                    }
                });
            }
        });
    }

    function normalizeFileUpload(field) {
        let file = props.form.getFieldsValue([field])[field];
        if (!file) {
            return;
        }
        if (file instanceof Array) {
            file = file[0];
        }
        return file.id ? file : { file_path: file.file_path, name: file.name };
    }

    const onEdit = (targetKey, action) => {
        if (action == 'add' && numberOfCards < 5) {
            props.form.validateFieldsAndScroll((err, values) => {
                if (!err) {
                    let newId = lastKey;
                    newId++;
                    let arrSlides = slides.slice();
                    let arrKeys = slidesKeys.slice();
                    arrSlides.push({ id: newId });
                    arrKeys.push(newId);
                    setSlidesKeys(arrKeys);
                    setSlides(arrSlides);
                    setActiveTab('' + newId);
                    setLastKey(newId);
                    setNumberOfCards(numberOfCards + 1);
                }
            });
        }
        if (action == 'remove') {
            if (slidesKeys.length > 1) {
                setConfirmVisible(true);
                setSlideToDelete(targetKey);
                setNumberOfCards(numberOfCards - 1);
            }
        }
    };

    const tabOnChange = (activeKey) => {
        props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                setActiveTab(activeKey);
            }
        });
    };

    const deleteSlide = () => {
        if (slideToDelete) {
            var newArrKeys = slidesKeys.filter((key) => key != parseInt(slideToDelete));
            var newArrVideos = slides.filter((video) => video.id != parseInt(slideToDelete));
            setSlides(newArrVideos);
            setSlidesKeys(newArrKeys);

            if (activeTab == slideToDelete) {
                setActiveTab('' + newArrKeys[0]);
            }
            setConfirmVisible(false);
            setSlideToDelete(null);
            let deleted = deletedSlides.slice();
            deleted.push(slideToDelete);
            setDeletedSlides(deleted);
        }
    };

    return (
        <React.Fragment>
            <Divider>{geti18nText('component.imagecardlinks')}</Divider>
            {showSpinner ? (
                <Spinner />
            ) : (
                <Form>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item hidden={true}>{getFieldDecorator('id')(<Input hidden={true} />)}</Form.Item>
                            <Row>
                                <Col span={24}>
                                    <Popconfirm
                                        placement="rightTop"
                                        title={geti18nText('app.default.button.delete')}
                                        visible={confirmVisible}
                                        okText={geti18nText('app.default.button.yes')}
                                        cancelText={geti18nText('app.default.button.no')}
                                        onCancel={() => {
                                            setConfirmVisible(false);
                                        }}
                                        onConfirm={deleteSlide}
                                    >
                                        <span></span>
                                    </Popconfirm>
                                </Col>
                            </Row>
                            <InputByLanguage
                                notRequred
                                fieldName={'title_main'}
                                label={geti18nText('component.descriptiontabs.title')}
                                langJson={props.tileLangJson}
                                {...props}
                            />
                            <Row style={{ marginTop: '20px ' }}>
                                <Col span={24}>
                                    <Tabs
                                        type="editable-card"
                                        onEdit={onEdit}
                                        onChange={tabOnChange}
                                        activeKey={activeTab}
                                        hideAdd={numberOfCards === 5 ? true : false}
                                    >
                                        {slides.map((image, index) => (
                                            <TabPane tab={'Slide ' + (index + 1)} key={image.id} forceRender={true}>
                                                <SlideTab
                                                    index={image.id}
                                                    tileLangJson={image.titleLangJson}
                                                    textLangJson={image.textLangJson}
                                                    linkTextJson={image.linkTextJson}
                                                    externalLink={image.externalLink}
                                                    type={image.type}
                                                    {...props}
                                                    // setJoditArray={setJoditArray}
                                                />
                                            </TabPane>
                                        ))}
                                    </Tabs>
                                </Col>
                                {/* <Divider>{geti18nText('component.imagecardlinks.button.link')}</Divider>
                                <Form.Item>
                                    {getFieldDecorator('type_button', { initialValue: LINK_TYPE.WEBPAGE })(
                                        <Radio.Group
                                            onChange={(e) => {
                                                setLinkType(e.target.value);
                                            }}
                                        >
                                            <Radio value={LINK_TYPE.WEBPAGE}>
                                                {geti18nText('component.imagecardlinks.edit.radio_button.webPage')}
                                            </Radio>
                                            <Radio value={LINK_TYPE.LINK}>
                                                {geti18nText('component.imagecardlinks.edit.radio_button.link')}
                                            </Radio>
                                        </Radio.Group>
                                    )}
                                </Form.Item> 
                                <Row
                                    gutter={24}
                                    type="flex"
                                    hidden={linkType != LINK_TYPE.LINK}
                                    justify="space-between"
                                    align="bottom"
                                >
                                    <Col span={16}>
                                        <Form.Item
                                            label={geti18nText('component.descriptiontabs.edit.radio_button.link')}
                                        >
                                            {getFieldDecorator('external-link_button-0')(
                                                <Input />
                                            )}
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Form.Item
                                    hidden={linkType == LINK_TYPE.LINK}
                                    label={geti18nText('component.descriptiontabs.edit.webpage.button')}
                                >
                                    {getFieldDecorator('link_button-0')(
                                        <SearchField
                                            url={CONSTANTS_REQ.WEBPAGE.SEARCH}
                                            map={{ id: 'id', label: 'name' }}
                                            searchBy="name"
                                            disabled={false}
                                            translate={true}
                                            addedData={{ projectId: Session.getProjectId(), forMap: true }}
                                        />
                                    )}
                                </Form.Item>
                                <InputByLanguage
                                    notRequred
                                    fieldName={'read-more-button_link-0'}
                                    label={geti18nText('component.imagecardlinks.edit.text_link')}
                                    // notRequred={true}
                                    langJson={props.linkTextJson}
                                    {...props}
                                /> */}
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24} style={{ textAlign: 'right', marginTop: '1rem' }}>
                            <Button style={{ marginRight: '1em' }} onClick={(e) => props.closePopup()}>
                                {geti18nText('app.default.button.cancel')}
                            </Button>
                            <Button style={{ marginRight: '1em' }} type="primary" onClick={(e) => save(e, false)}>
                                {geti18nText('app.default.button.save')}
                            </Button>
                            <Button
                                type="primary"
                                style={{ background: '#31b531', borderColor: '#31b531', textColor: 'white' }}
                                onClick={(e) => {
                                    save(e, true);
                                }}
                            >
                                {geti18nText('app.default.button.save.close')}
                            </Button>
                        </Col>
                    </Row>
                </Form>
            )}
        </React.Fragment>
    );
});

const SlideTab = (props) => {
    const LINK_TYPE = {
        LINK: 'LINK',
        WEBPAGE: 'WEBPAGE',
    };
    const [showSpinner, setShowSpinner] = useState(true);
    const { getFieldDecorator, setFieldsValue, getFieldsValue } = props.form;
    const [linkType, setLinkType] = useState(props.type ? props.type : LINK_TYPE.WEBPAGE);
    const [joditValue, setJoditValue] = useState('<p>   </p>');
    const [seletedLang, setSeletedLang] = useState(Session.getProjectDefaultLanguage());
    const editor = useRef(null);

    return (
        <>
            <Row gutter={24}>
                <Col span={24}>
                    {/*   <Form.Item label={geti18nText('component.descriptiontabs.product.title')}></Form.Item> */}
                    {/*    <InputByLanguage
                        fieldName={'description_' + props.index}
                        label={geti18nText('component.descriptiontabs.edit.description')}
                        notRequred={true}
                        langJson={props.textLangJson}
                        minHeight={'250px'}
                        {...props}
                        type="rteEditor"
                    /> */}

                    <Form.Item label={geti18nText('component.imagecardlinks.edit.image')}>
                        {getFieldDecorator('image-path_' + props.index, {
                            rules: [{ required: true, message: geti18nText('app.default.required') }],
                        })(
                            <FileUpload
                                fileType={CONSTANTS_FILE_TYPE.IMAGE}
                                map={{ id: 'id', label: 'name' }}
                                searchBy="name"
                                disabled={false}
                                addedData={{ projectId: Session.getProjectId() }}
                            />
                        )}
                    </Form.Item>
                    <Divider />
                    <InputByLanguage
                        notRequred
                        fieldName={'title_' + props.index}
                        label={geti18nText('component.imagecardlinks.image.title')}
                        langJson={props.tileLangJson}
                        {...props}
                    />

                    <Form.Item>
                        {getFieldDecorator('type_' + props.index, { initialValue: LINK_TYPE.WEBPAGE })(
                            <Radio.Group
                                onChange={(e) => {
                                    setLinkType(e.target.value);
                                }}
                            >
                                <Radio value={LINK_TYPE.WEBPAGE}>
                                    {geti18nText('component.imagecardlinks.edit.radio_button.webPage')}
                                </Radio>
                                <Radio value={LINK_TYPE.LINK}>
                                    {geti18nText('component.imagecardlinks.edit.radio_button.link')}
                                </Radio>
                            </Radio.Group>
                        )}
                    </Form.Item>

                    {linkType == LINK_TYPE.LINK ? (
                        <Row gutter={24} type="flex" justify="space-between" align="bottom">
                            <Col span={16}>
                                <Form.Item label={geti18nText('component.descriptiontabs.edit.radio_button.link')}>
                                    {getFieldDecorator('external-link_' + props.index)(
                                        <Input defaultValue={props.externalLink} />
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                    ) : (
                        <Form.Item label={geti18nText('component.descriptiontabs.edit.webpage')}>
                            {getFieldDecorator('link_' + props.index)(
                                <SearchField
                                    url={CONSTANTS_REQ.WEBPAGE.SEARCH}
                                    map={{ id: 'id', label: 'name' }}
                                    searchBy="name"
                                    disabled={false}
                                    translate={true}
                                    addedData={{ projectId: Session.getProjectId(), forMap: true }}
                                />
                            )}
                        </Form.Item>
                    )}

                    {/*    <InputByLanguage
                        notRequred
                        fieldName={'read-more-button_' + props.index}
                        label={geti18nText('component.imagecardlinks.edit.text_link')}
                        // notRequred={true}
                        langJson={props.linkTextJson}
                        {...props}
                    /> */}
                </Col>
            </Row>
        </>
    );
};

export default ImageCardLinksEdit;
