import React, { useState } from 'react';
import { Button, Form, Row, Col, Input } from 'antd';
import { CONSTANTS_REQ, CONSTANTS_FILE_TYPE } from '../../../utils/Constants';
import { DataEdit } from '../../../components/DataEdit';
import { Session, geti18nText } from '../../../utils/Session';
import { FileUpload } from '../../../components/fileUpload/FileUpload';
import { SearchField, mapDataToSelect } from '../../../components//Search';
import { InputByLanguage, translationsToObject } from '../../../components/InputByLanguage';
import PageTypeEdit from '../page_type/PageTypeEdit';
import LocationEdit from '../location/LocationEdit';
import { requestGet, RESPONSE } from '../../../utils/RequestResolver';
import InputMarkerCoordinates from '../../../components/inputMarkerCoordinates/InputMarkerCoordinates';
import { getTranslated } from '../../../components/InputByLanguage';

const MapPinEdit = Form.create({ name: 'MapPinEdit' })((props) => {
    const { getFieldDecorator, setFieldsValue } = props.form;
    const [editHeader, setEditHeader] = useState(geti18nText('map.pin.edit.new'));
    const [loading, setLoading] = useState(false);
    const [mapPin, setMapPin] = useState(null);
    const [nameLangJson, setNameLangJson] = useState(null);
    const [contextTextLangJson, setContextTextLangJson] = useState(null);
    const [ptPopupVisible, setPtPopupVisible] = useState(false);
    const [lokPopupVisible, setLokPopupVisible] = useState(false);

    function setValues(mapPin) {
        mapPin = translationsToObject(mapPin, 'name', mapPin.name);
        mapPin = translationsToObject(mapPin, 'contentText', mapPin.contentText);
        setNameLangJson(mapPin.name);
        setContextTextLangJson(mapPin.contentText);
        setEditHeader(mapPin.id);
        setMapPin(mapPin.id);

        if (mapPin.imagePath != null) {
            const imagePath = {
                file_path: mapPin.imagePath,
                name: mapPin.imagePath.substring(mapPin.imagePath.lastIndexOf('/') + 1, mapPin.imagePath.length),
            };
            mapPin.imagePath = imagePath;
        }

        if(mapPin.categoryTypes){
            mapPin.categoryType = mapPin.categoryTypes.map((cat) => {
                return { key: cat.id + '', label: getTranslated(cat.name) };
            });
            delete mapPin.categoryTypes;
        }else {
            setFieldsValue({ categoryType: [] });
        }

        delete mapPin.name;
        delete mapPin.mapPin;
        delete mapPin.contentText;
        delete mapPin.active;
        setFieldsValue(mapPin);
    }

    function isCreate() {
        return props.value == undefined;
    }

    function setDefaultValues() {
        setNameLangJson(null);
        setContextTextLangJson(null);
    }

    function onAddNewType(id) {
        requestGet(CONSTANTS_REQ.PAGETYPE.EDIT + '/' + id).then((result) => {
            if (result.status === RESPONSE.OK) {
                let categoryTypes = props.form.getFieldsValue()['categoryType'];
                console.log(categoryTypes)
                if(categoryTypes == undefined){
                    categoryTypes = [];
                }
                categoryTypes.push({key: result.data.id + '', label: getTranslated(result.data.name)});
                props.form.setFieldsValue({ categoryType: categoryTypes });
            }
        });
    }

    function onAddNewLocation(id) {
        requestGet(CONSTANTS_REQ.LOCATION.EDIT + '/' + id).then((result) => {
            if (result.status === RESPONSE.OK) {
                props.form.setFieldsValue({ location: result.data });
            }
        });
    }

    return (
        <>
            <DataEdit
                editHeader={editHeader}
                loading={loading}
                setLoading={setLoading}
                setDefaultValues={setDefaultValues}
                url={CONSTANTS_REQ.MAP_PIN.EDIT}
                setValues={setValues}
                width={1200}
                {...props}
                normalize={(values) => {
                    return {
                        ...values,
                        categoryType:
                            values.categoryType != null
                                ? values.categoryType.constructor === String
                                    ? [parseInt(values.categoryType)]
                                    : values.categoryType instanceof Array
                                    ? values.categoryType.map(function(item) { return parseInt(item); })
                                    : [values.categoryType]
                                : null,
                        location:
                            values.location != null && values.location.constructor === String
                                ? { id: parseInt(values.location) }
                                : values.location,
                        imagePath:
                            values.imagePath != null && values.imagePath instanceof Object
                                ? values.imagePath.file_path
                                : values.imagePath,
                        projectId: Session.getProjectId(),
                        webPage:
                            values.webPage != null && values.webPage.constructor === String
                                ? { id: parseInt(values.webPage) }
                                : values.webPage,
                    };
                }}
            >
                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item hidden={true}>{getFieldDecorator('id')(<Input hidden={true} />)}</Form.Item>
                        <InputByLanguage
                            fieldName="name"
                            langJson={nameLangJson}
                            notRequred={false}
                            label={geti18nText('map.pin.edit.name')}
                            {...props}
                        />
                        <InputMarkerCoordinates {...props} />
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={18}>
                        <Form.Item label={geti18nText('map.pin.edit.categoryType')}>
                            {getFieldDecorator('categoryType')(
                                <SearchField
                                    url={CONSTANTS_REQ.PAGETYPE.SEARCH}
                                    map={{ id: 'id', label: 'name' }}
                                    searchBy="name"
                                    mode="multiple"
                                    disabled={false}
                                    translate={true}
                                    addedData={{ projectId: Session.getProjectId(), forMap: true }}
                                />
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Button
                            style={{ marginTop: '43px' }}
                            onClick={() => {
                                setPtPopupVisible(true);
                            }}
                        >
                            {geti18nText('map.pin.edit.add.pageType')}
                        </Button>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={18}>
                        <Form.Item label={geti18nText('map.pin.edit.location')}>
                            {getFieldDecorator('location', {
                                rules: [{ required: true, message: geti18nText('app.default.required') }],
                            })(
                                <SearchField
                                    url={CONSTANTS_REQ.LOCATION.SEARCH}
                                    map={{ id: 'id', label: 'name' }}
                                    searchBy="name"
                                    disabled={false}
                                />
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Button
                            style={{ marginTop: '43px' }}
                            onClick={() => {
                                setLokPopupVisible(true);
                            }}
                        >
                            {geti18nText('map.pin.edit.add.location')}
                        </Button>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={24}>
                        <InputByLanguage
                            fieldName="contentText"
                            type="rteEditor"
                            langJson={contextTextLangJson}
                            notRequred={true}
                            label={geti18nText('map.pin.edit.contentText')}
                            {...props}
                        />
                        <Form.Item label={geti18nText('map.pin.edit.image')}>
                            {getFieldDecorator('imagePath')(
                                <FileUpload
                                    fileType={CONSTANTS_FILE_TYPE.IMAGE}
                                    map={{ id: 'id', label: 'name' }}
                                    searchBy="name"
                                    disabled={false}
                                    addedData={{ projectId: Session.getProjectId() }}
                                />
                            )}
                        </Form.Item>
                        <Form.Item label={geti18nText('map.pin.edit.webPage')}>
                            {getFieldDecorator('webPage', {
                                rules: [{ required: true, message: geti18nText('app.default.required') }],
                            })(
                                <SearchField
                                    url={CONSTANTS_REQ.WEBPAGE.SEARCH}
                                    map={{ id: 'id', label: 'name' }}
                                    searchBy="name"
                                    disabled={false}
                                    translate={true}
                                    addedData={{ projectId: Session.getProjectId(), forMap: true }}
                                />
                            )}
                        </Form.Item>
                        <Form.Item hidden={true} label={geti18nText('map.pin.edit.linkUrl')}>
                            {getFieldDecorator('linkUrl')(<Input />)}
                        </Form.Item>
                        <Form.Item hidden={true}>{getFieldDecorator('project')(<Input hidden={true} />)}</Form.Item>
                    </Col>
                </Row>
            </DataEdit>
            <PageTypeEdit
                isModal="true"
                onlyMap="true"
                onlyPage="false"
                visible={ptPopupVisible}
                setVisible={setPtPopupVisible}
                onSaveAndGetID={onAddNewType}
            />
            <LocationEdit
                isModal="true"
                visible={lokPopupVisible}
                setVisible={setLokPopupVisible}
                onSaveAndGetID={onAddNewLocation}
            />
        </>
    );
});

export default MapPinEdit;
