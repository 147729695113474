import React from 'react';
import { Icon } from 'antd';
import { DataTable, getColumnSearch, getColumnSearchOption } from '../../../components/DataTable';
import FacilityEdit from './FacilityEdit';
import FacilityGroupEdit from './FacilityGroupEdit';
import { CONSTANTS_REQ } from '../../../utils/Constants';
import { ENUMS, geti18nText } from '../../../utils/Session';
import { getTranslated } from '../../../components/InputByLanguage';

const FacilityList = (props) => {
    function setDefaultFilterValue() {
        var defValue = [
            { field: 'active', condition: 'equals_bool', value: 1 },
            { field: 'main', condition: 'equals_bool', value: isMainFacility() ? 1 : 0 },
        ];
        if (!isMainFacility()) {
            if (isFacility()) {
                defValue.push({ field: 'parentId', condition: 'equals', value: props.addedData.parentId });
            } else {
                defValue.push({ field: 'parentId', condition: 'is_null', value: '' });
            }
        }
        return defValue;
    }

    function isMainFacility() {
        return props.addedData && props.addedData.mainFacility && props.addedData.mainFacility == true;
    }

    function isFacility() {
        return props.addedData && props.addedData.parentId && props.addedData.parentId != null;
    }

    function columns() {
        var cols = [
            {
                title: geti18nText('facility.table.column.id'),
                dataIndex: 'id',
                width: '10%',
                sorter: (a, b) => {},
                ...getColumnSearch('number'),
            },
            {
                title: geti18nText('facility.table.column.name'),
                dataIndex: 'name',
                sorter: (a, b) => {},
                ...getColumnSearch('string'),
                render: (text) => {
                    return <div>{getTranslated(text)}</div>;
                },
            },
        ];
        if (isMainFacility()) {
            cols.push({
                title: geti18nText('facility.table.column.preview'),
                dataIndex: 'icon',
                render: (text) => {
                    const path = CONSTANTS_REQ.STATIC_MEDIA + text;
                    return (
                        <div>
                            <img src={path}></img>
                        </div>
                    );
                },
            });
            cols.push({
                title: geti18nText('facility.table.column.icon'),
                dataIndex: 'icon',
                key: 'icon-p',
                sorter: (a, b) => {},
                ...getColumnSearch('string'),
                render: (text) => {
                    return <div>{text}</div>;
                },
            });
        } else {
            cols.push({
                title: geti18nText('facility.table.column.facility.order'),
                dataIndex: 'facilityOrder',
                sorter: (a, b) => {},
                ...getColumnSearch('number'),
            });
        }
        cols.push({
            title: geti18nText('facility.table.column.active'),
            dataIndex: 'active',
            width: '10%',
            render: (text, record) => {
                if (record.active) {
                    return <Icon type="check" style={{ color: 'green' }} />;
                } else {
                    return <Icon type="close" style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', setDefaultFilterValue()[0]['value']),
            defaultFilteredValue: setDefaultFilterValue(),
        });
        return cols;
    }

    function facilityTableHeader() {
        if (isMainFacility()) {
            return geti18nText('facility.main.table.header');
        } else if (!isFacility()) {
            return geti18nText('facility.group.table.header');
        }
        return geti18nText('facility.table.header');
    }

    return (
        <DataTable
            headerTitle={facilityTableHeader()}
            url={CONSTANTS_REQ.FACILITY.LIST}
            addNewButtonText={geti18nText('facility.table.add')}
            showRecordModal={true}
            modalComponent={isMainFacility() ? FacilityEdit : isFacility() ? FacilityGroupEdit : FacilityGroupEdit}
            setDefaultFilterValue={setDefaultFilterValue}
            addedData={props.addedData}
            columns={columns()}
        />
    );
};
export default FacilityList;
