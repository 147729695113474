import React, { useState, useEffect, useRef, useImperativeHandle } from 'react';
import { Form, Row, Col, Button, Divider, Collapse, Tabs, InputNumber, Popconfirm, Checkbox } from 'antd';
import { Spinner } from '../Spinner';
import { Session, geti18nText } from '../../utils/Session';
import { normalizeComponents } from '../../utils/Utils';
import { InputByLanguage, translationsToObject } from '../InputByLanguage';

const ConfiguratorGroupOptionsEdit = Form.create({ name: 'ConfiguratorGroupOptionsEdit' })((props) => {
    const [confirmVisible, setConfirmVisible] = useState(false);
    const { getFieldDecorator, setFieldsValue } = props.form;
    const [fetching, setFetch] = useState(false);
    const [showError, setShowError] = useState(false);
    const [isEditItem, setisEditItem] = useState(false);
    const [optionId, setOptionId] = useState();
    const [formValues, setFormValues] = useState(null);
    const [optionItems, setOptionItems] = useState([]);

    useEffect(() => {
        props.form.resetFields();
        props.form.setFieldsValue(formValues);
        setFieldsValue(formValues);
    }, [formValues]);

    useEffect(() => {
        if (props.popupValue) {
            setValues(props.popupValue);
        }
    }, [props.popupValue]);

    function setValues(values) {
        var json = JSON.parse(JSON.stringify(values));
        console.log('-', json);
        json = translationsToObject(json, 'option_name', json['option_name']);
        json = translationsToObject(json, 'option_desc', json['option_desc']);
        delete json['option_name'];
        delete json['option_desc'];
        setOptionItems(json.optionItems);
        setOptionId(json.id);
        setFormValues(json);
        setisEditItem(true);
    }

    function generateId() {
        const id = new Date().toJSON();
        return id;
    }

    function deleteItem() {
        props.deleteItem(optionId);
    }

    function save(e) {
        e.preventDefault();
        props.form.validateFieldsAndScroll((err, values) => {
            if (showError == false) {
                values = normalizeComponents(values);
                values.id = optionId != undefined ? optionId : generateId();
                props.updateItemsFromModal(values);
            }
        });
    }

    function onPriceChange(value) {}

    if (fetching) {
        return <Spinner />;
    } else {
        return (
            <React.Fragment>
                <Row gutter={24}>
                    <Col span={24}>
                        <InputByLanguage
                            fieldName={'option_name'}
                            label={geti18nText('component.configurator.option.name')}
                            // langJson={item.nameJson}
                            notRequred={false}
                            {...props}
                        />
                    </Col>
                </Row>
                <Row>
                    <InputByLanguage
                        notRequred
                        fieldName={'option_desc'}
                        label={geti18nText('component.configurator.option.description')}
                        //  langJson={item.descriptionJson}
                        notRequred={true}
                        type={'rteEditor'}
                        {...props}
                    />
                </Row>
                <Row>
                    <Form.Item hidden={false} label={geti18nText('component.configurator.option.price')}>
                        {getFieldDecorator('option_price', {
                            rules: [{ required: true, message: geti18nText('app.default.required') }],
                        })(<InputNumber min={0} precision={2} onChange={(value) => onPriceChange(value)} />)}
                        {' €'}
                    </Form.Item>
                </Row>

                <Row>
                    <Col style={{ textAlign: 'right', marginTop: '1rem' }}>
                        {isEditItem == true && (
                            <Popconfirm
                                placement="rightTop"
                                title={geti18nText('app.default.button.delete')}
                                visible={confirmVisible}
                                okText={geti18nText('app.default.button.yes')}
                                cancelText={geti18nText('app.default.button.no')}
                                onCancel={() => {
                                    setConfirmVisible(false);
                                }}
                                onConfirm={deleteItem}
                            >
                                <Button type="danger" key="delete" onClick={(e) => setConfirmVisible(true)}>
                                    {geti18nText('app.default.button.delete')}
                                </Button>
                            </Popconfirm>
                        )}
                        <Button style={{ marginLeft: '20px' }} type="primary" onClick={(e) => save(e)}>
                            {geti18nText('component.configurator.done.btn')}
                        </Button>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
});

export default ConfiguratorGroupOptionsEdit;
