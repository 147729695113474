import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import { Table, Icon, Col, Row, Input, Radio, Button, Tooltip, Divider } from 'antd';
import { requestGet, RESPONSE } from '../utils/RequestResolver';
import { geti18nText } from '../utils/Session';

export const DataTable = withRouter(({ modalComponent: ModalComponent, ...props }) => {
    const [pagination, setPagination] = useState({
        showSizeChanger: true,
        pageSizeOptions: ['10', '50', '100'],
        locale: {
            items_per_page: '',
        },
        total: 0,
        showTotal: (total) => {
            return `${geti18nText('app.default.total')} ${total} ${geti18nText('app.default.records')}`;
        },
    });
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [popupVisible, setPopupVisible] = useState(false);
    const [popupValue, setPopupValue] = useState(undefined);
    const [scroll, setScroll] = useState(getScrollParams());
    //6123 dobiti nezad podate nakon senda
    const [sessionTableData, setSessionTableData] = useState();

    useEffect(() => {
        let x = window.matchMedia('(max-width: 768px)');
        x.addEventListener('change', windowsScreenChange);
        if (props.tableData) {
            setData(props.tableData, props.compTypesEnum);
            //   console.log('postavljeni su podaci iz props.tableData' + JSON.stringify(props.tableData));
        } else {
            fetch(getDefaultFilterValue());
        }
        return () => {
            x.removeEventListener('change', windowsScreenChange);
        };
    }, []);

    function windowsScreenChange() {
        setScroll(getScrollParams());
    }

    function getScrollParams() {
        let isMobile = window.matchMedia('only screen and (max-width: 768px)').matches;
        let parScroll = { x: false, y: false };
        if (isMobile) {
            parScroll = { x: true };
        }

        return parScroll;
    }
    // 6123 testiranje vrijednosti
    function getDefaultFilterValue() {
        let sSearch = {};
        if (props.setDefaultFilterValue) {
            sSearch = encodeURI(JSON.stringify(props.setDefaultFilterValue()));
            if (sSearch.length > 0) {
                sSearch = { search: sSearch };
            }
        }
        return sSearch;
    }

    function onPopupSave() {
        const params = getDefaultFilterValue();
        params.size = pagination.pageSize;
        params.max = pagination.pageSize;
        params.offset = pagination.offset;
        //onPopupSave promjena
        if (!props.tableData) fetch(params);
    }

    function handleTableChange(pagination, filters, sorter) {
        let params = {
            max: pagination.pageSize,
            offset: pagination.current - 1,
            ...getSort(sorter),
            ...getFilters(filters),
        };
        if (!props.tableData) fetch(params);
    }

    function getFilters(filters) {
        const transformedFilters = [];
        if (filters) {
            for (var key in filters) {
                if (filters.hasOwnProperty(key) && filters[key].length > 0) {
                    transformedFilters.push({ field: key, ...filters[key][0] });
                }
            }
            if (props.setDefaultFilterValue) {
                const defaultFilter = props.setDefaultFilterValue();
                for (let dFilter of defaultFilter) {
                    if (transformedFilters.find(({ field }) => field == dFilter['field']) === undefined) {
                        transformedFilters.push(dFilter);
                    }
                }
            }
            return { search: encodeURI(JSON.stringify(transformedFilters)) };
        } else if (props.setDefaultFilterValue) {
            const defaultFilter = props.setDefaultFilterValue();
            for (let dFilter of defaultFilter) {
                transformedFilters.push(dFilter);
            }
            return { search: encodeURI(JSON.stringify(transformedFilters)) };
        }
        return undefined;
    }

    function getSort(sorter) {
        if (sorter.field) {
            const sort = { order: undefined, orderType: undefined };
            sort.order = sorter.field;
            sort.orderType = sorter.order === 'ascend' ? 'asc' : 'desc';
            return sort;
        } else {
            return undefined;
        }
    }

    function fetch(params = {}) {
        setLoading(true);
        requestGet(props.url, { ...params, ...props.addedData }).then((response) => {
            if (response.status === RESPONSE.OK) {
                const pageable = { ...pagination };
                const responseData = response.data;
                pageable.pageSize = responseData.size;
                pageable.offset = responseData.pageNumber;
                pageable.total = responseData.totalSize;
                setLoading(false);
                setData(responseData.content);
                setPagination(pageable);
            } else {
                setData([]);
                setLoading(false);
            }
        });
    }

    function onNewItemHandler() {
        if (props.onNewItem) {
            props.onNewItem();
        } else {
            if (props.showRecordModal) {
                setPopupValue(undefined);
                setPopupVisible(true);
            } else {
                props.history.push(props.location.pathname + '/create');
            }
        }
    }

    function onRowSelectHandler(record) {
        if (props.readonly === true) {
            return;
        }

        if (props.onRowSelect) {
            props.onRowSelect(record);
        } else {
            if (record && record.id) {
                if (props.showRecordModal) {
                    setPopupValue(record);
                    setPopupVisible(true);
                } else {
                    props.history.push(props.location.pathname + '/' + record.id);
                }
            } else {
                console.log('Record has no id field, no handler set');
            }
        }
    }

    return (
        <>
            {props.headerTitle && (
                <Row type="flex" className="margin-bottom">
                    <Divider orientation="left" style={{ fontSize: '18px' }}>
                        {props.headerTitle}
                    </Divider>
                </Row>
            )}
            <Table
                className="noselect"
                size="small"
                columns={props.columns}
                rowKey={(record) => record.id}
                pagination={props.hidePagination ? false : pagination}
                dataSource={data}
                loading={loading}
                scroll={scroll}
                onChange={handleTableChange}
                onRow={(record, rowIndex) => {
                    return {
                        onClick: (event) => {},
                        onDoubleClick: (event) => {
                            onRowSelectHandler(record);
                        },
                        onContextMenu: (event) => {},
                        onMouseEnter: (event) => {
                            return props.onRowMouseEnterHandler ? props.onRowMouseEnterHandler(record) : undefined;
                        },
                        onMouseLeave: (event) => {
                            return props.onRowMouseLeaveHandler ? props.onRowMouseLeaveHandler(record) : undefined;
                        },
                    };
                }}
            />
            {/* //6122 */}
            {props.readonly !== true && props.hideButtons !== true && !props.tableData && (
                <Row type="flex" className="height-100 margin-bottom margin-top">
                    <Col span={24}>
                        {props.hideNewButton !== true && (
                            <Button type="primary" onClick={(e) => onNewItemHandler()}>
                                {props.addNewButtonText}
                            </Button>
                        )}
                        {props.addedButtons && props.addedButtons({ onPopupSave })}
                    </Col>
                </Row>
            )}
            {props.showRecordModal ? (
                <ModalComponent
                    isModal="true"
                    visible={popupVisible}
                    setVisible={setPopupVisible}
                    value={popupValue}
                    onSave={onPopupSave}
                    onSaveAndGetID={props.onSaveAndGetID}
                    addedData={props.addedData}
                    additionalValues={props.additionalValues}
                />
            ) : null}
        </>
    );
});

let searchInput = null;

export const getColumnSearch = (type) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
        <div style={{ padding: 8 }}>
            <Input
                ref={(node) => {
                    searchInput = node;
                }}
                placeholder={`${geti18nText('app.default.button.search')}`}
                value={getValue(selectedKeys)}
                onChange={(e) =>
                    setSelectedKeys(
                        e.target.value ? [{ condition: getCondition(selectedKeys, type), value: e.target.value }] : []
                    )
                }
                onPressEnter={() => handleSearch(confirm)}
                style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Radio.Group
                value={getCondition(selectedKeys, type)}
                onChange={(e) => setSelectedKeys([{ condition: e.target.value, value: getValue(selectedKeys) }])}
            >
                {type === 'string' && (
                    <>
                        <Tooltip placement="bottom" title={geti18nText('app.default.filter.contains')}>
                            <Radio.Button value="contains" hidden={type !== 'string'} size="small">
                                ~
                            </Radio.Button>
                        </Tooltip>
                        <Tooltip placement="bottom" title={geti18nText('app.default.filter.starts.with')}>
                            <Radio.Button value="starts_with" hidden={type !== 'string'} size="small">
                                ~S
                            </Radio.Button>
                        </Tooltip>
                        <Tooltip placement="bottom" title={geti18nText('app.default.filter.ends.with')}>
                            <Radio.Button value="ends_with" hidden={type !== 'string'} size="small">
                                S~
                            </Radio.Button>
                        </Tooltip>
                    </>
                )}
                <Tooltip placement="bottom" title={geti18nText('app.default.filter.equals')}>
                    <Radio.Button value="equals" size="small">
                        =
                    </Radio.Button>
                </Tooltip>
                <Tooltip placement="bottom" title={geti18nText('app.default.filter.not.equals')}>
                    <Radio.Button value="not_equals" size="small">
                        /=
                    </Radio.Button>
                </Tooltip>
            </Radio.Group>
        </div>
    ),
    filterIcon: (filtered) => <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
            setTimeout(() => searchInput.select());
        }
    },
});

export const getColumnSearchOption = (options, condition = 'equals', defaultValue = undefined) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
        <div style={{ padding: 8 }}>
            <Radio.Group
                value={getValue(selectedKeys, defaultValue)}
                onChange={(e) => {
                    setSelectedKeys(
                        e.target.value !== undefined ? [{ condition: condition, value: e.target.value }] : []
                    );
                    handleSearch(confirm);
                }}
            >
                {options.map((option, index) => (
                    <Radio
                        style={{ display: 'block', height: '30px', lineHeight: '30px' }}
                        value={option.id}
                        key={index}
                    >
                        {option.text}
                    </Radio>
                ))}
            </Radio.Group>
            <Button
                style={{ display: 'block', marginTop: '2px' }}
                size="small"
                onClick={() => {
                    setSelectedKeys(defaultValue ? [{ condition: condition, value: defaultValue }] : []);
                    handleSearch(confirm);
                }}
            >
                {geti18nText('app.default.clear')}
            </Button>
        </div>
    ),
});

function getValue(selectedKeys, defaultValue) {
    return selectedKeys && selectedKeys[0] ? selectedKeys[0].value : defaultValue ? defaultValue : undefined;
}

function getCondition(selectedKeys, type, defaultValue) {
    return selectedKeys && selectedKeys[0]
        ? selectedKeys[0].condition
        : defaultValue
        ? defaultValue
        : type === 'string'
        ? 'contains'
        : 'equals';
}

function handleSearch(confirm) {
    confirm();
}
