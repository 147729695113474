import { Button, Col, Divider, Form, InputNumber, Row, notification,Radio,Input } from 'antd';
import React, { useEffect, useState } from 'react';
import { normalizeJSONForComponent } from '../../utils/ComponentHelper';
import { CONSTANTS_REQ } from '../../utils/Constants';
import { RESPONSE, requestGet, requestPut } from '../../utils/RequestResolver';
import { normalizeComponents } from '../../utils/Utils';
import { Spinner } from '../Spinner';
import { Session, geti18nText } from '../../utils/Session';
import { SearchField } from '../../components/Search';
import { InputByLanguage, translationsToObject } from '../InputByLanguage';




const YlShopEdit = Form.create({ name: 'YlShopEdit' })((props) => {
    const { getFieldDecorator, setFieldsValue } = props.form;
    const [fetching, setFetching] = useState(false);
    const [formValues, setFormValues] = useState(null);
    const LINK_TYPE = {
        LINK: 'LINK',
        WEBPAGE: 'WEBPAGE',
    };
    const [linkTypeTerms, setLinkTypeTerms] = useState(LINK_TYPE.WEBPAGE);
    const [externalLink, setExternalLink] = useState(undefined);
    
    useEffect(() => {
        fetch();
    }, [props.visible]);

    useEffect(() => {
        props.form.resetFields();
        setFieldsValue(formValues);
    }, [formValues]);

    function fetch() {
        setFetching(true);
        requestGet(CONSTANTS_REQ.WEBPAGECOMPONENT.EDIT + '/' + props.id).then((result) => {
            setFetching(false);
            if (result.status === RESPONSE.OK) {
                setValues(result.data);
            }
        });
    }
    
    function showNotification(title, message, duration, type) {
        notification[type]({
            message: title,
            description: message,
            duration: duration,
        });
    }

    function okNotification() {
        showNotification(geti18nText('app.default.save.ok'), geti18nText('app.default.save.ok.desc'), 3, 'success');
    }

    function error(message) {
        showNotification(geti18nText('app.default.save.nok'), message, 3, 'error');
    } 

    function setValues(component_data) {
        var json;
        if (component_data.json) {
            json = JSON.parse(component_data.json);
            if(json.link) {
                setWepPageValue('link',parseInt(json.link) )
            }
        }
        else {
            json={};
            json.numberToShow=8
        }
        setFormValues(json);
        
    }
    function setWepPageValue(key, id) {
        console.log('key',key,'id',id)
        requestGet(CONSTANTS_REQ.WEBPAGE.EDIT + '/' + id).then((result) => {
            if (result.status === RESPONSE.OK) {
                let field = {};
                field[key] = { id: id, name: result.data.name };
                setFieldsValue(field);
            }
        });
    }

    function normalize(values, translationKeys) {
        let jsonFieldGroups = ['numberToShow','link'];
        let jsonFieldKeys = {};
        return normalizeJSONForComponent(values, undefined, jsonFieldGroups, jsonFieldKeys, props);
    }

    function save(e, close) {
        e.preventDefault();
        props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                values = normalizeComponents(values);                
                values = normalize(values);
                requestPut(CONSTANTS_REQ.WEBPAGECOMPONENT.UPDATEJSON + '/' + props.id, null, {
                    id: props.id,
                    json: values.json,
                }).then((result) => {
                    if (result && result.status === RESPONSE.CREATED) {
                        console.log('okNotification');
                        okNotification();
                        close && props.closePopup();
                    } else {
                        error();
                        if (result && result.status === RESPONSE.BAD_REQUEST) {
                            if (result.data && result.data.field) {
                                switch (result.data.field) {
                                    case 'mandatory_fields_required':
                                        console.log(geti18nText('app.default.mandatory_fields_required'));
                                        return;
                                    default:
                                        break;
                                }
                            }
                        }
                        if (result.data && result.data.error) {
                            console.log(JSON.stringify(result.data.error));
                        }
                    }
                   
                });
            }
        });
    }

    if (fetching) {
        return <Spinner />;
    } else {
        return (
            <React.Fragment>
                <Divider>{geti18nText('component.shopYl')}</Divider>
                <Form>
                    
                    <Form.Item label={geti18nText('shopYl.numberItem.show')}>
                        {getFieldDecorator('numberToShow', {
                                    rules: [{ required: true, message: geti18nText('app.default.required') }],
                                } )(
                            <InputNumber min={1} />
                        )}
                        
                    </Form.Item>
                    
                    <Form.Item label={geti18nText('shopYl.web.page')}>
                        {getFieldDecorator('link')(
                            <SearchField
                                url={CONSTANTS_REQ.WEBPAGE.SEARCH}
                                map={{ id: 'id', label: 'name' }}
                                searchBy="name"
                                disabled={false}
                                translate={true}
                                addedData={{ projectId: Session.getProjectId(), forMap: true }}
                            />
                        )}
                    </Form.Item>
                </Form>
                <Row>
                        <Col span={24} style={{ textAlign: 'right', marginTop: '1rem' }}>
                            <Button style={{ marginRight: '1em' }} onClick={(e) => props.closePopup()}>
                                {geti18nText('app.default.button.cancel')}
                            </Button>
                            <Button style={{ marginRight: '1em' }} type="primary" onClick={(e) => save(e, false)}>
                                {geti18nText('app.default.button.save')}
                            </Button>
                            <Button
                                type="primary"
                                style={{ background: '#31b531', borderColor: '#31b531', textColor: 'white' }}
                                onClick={(e) => {
                                    save(e, true);
                                }}
                            >
                                {geti18nText('app.default.button.save.close')}
                            </Button>
                        </Col>
                    </Row>
            </React.Fragment>
        );
    }
});

export default YlShopEdit;
