import { Col, Form, Input, InputNumber, Row } from 'antd';
import React, { useState } from 'react';
import { DataEdit } from '../../../components/DataEdit';
import { FileUpload } from '../../../components/fileUpload/FileUpload';
import { InputByLanguage, translationsToObject } from '../../../components/InputByLanguage';
import InputMapCoordinates from '../../../components/inputMapCoordinates/InputMapCoordinates';
import { SearchField } from '../../../components/Search';
import { CONSTANTS_FILE_TYPE, CONSTANTS_REQ } from '../../../utils/Constants';
import { geti18nText, Session } from '../../../utils/Session';
import { refreshMenu } from '../../../slices/menuSlice';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

const LocationEdit = Form.create({ name: 'LocationEdit' })((props) => {
    const { getFieldDecorator, setFieldsValue } = props.form;
    const [editHeader, setEditHeader] = useState(geti18nText('location.edit.new'));
    const [loading, setLoading] = useState(false);
    const [location, setLocation] = useState(null);
    const [projectId, setProjectId] = useState(0);
    const [contextTextLangJson, setContextTextLangJson] = useState(null);
    let history = useHistory();
    const dispatch = useDispatch();

    function setValues(location) {
        location = translationsToObject(location, 'contentText', location.contentText);
        if (location.headerImagePath != null) {
            const imageHeaderPath = {
                file_path: location.headerImagePath,
                name: location.headerImagePath.substring(
                    location.headerImagePath.lastIndexOf('/') + 1,
                    location.headerImagePath.length
                ),
            };
            location.headerImagePath = imageHeaderPath;
        }

        if (location.firstImagePath != null) {
            const imageFirstPath = {
                file_path: location.firstImagePath,
                name: location.firstImagePath.substring(
                    location.firstImagePath.lastIndexOf('/') + 1,
                    location.firstImagePath.length
                ),
            };
            location.firstImagePath = imageFirstPath;
        }

        if (location.secondImagePath != null) {
            const imageSecondPath = {
                file_path: location.secondImagePath,
                name: location.secondImagePath.substring(
                    location.secondImagePath.lastIndexOf('/') + 1,
                    location.secondImagePath.length
                ),
            };
            location.secondImagePath = imageSecondPath;
        }

        setContextTextLangJson(location.contentText);

        setEditHeader(location.id);
        setLocation(location.id);
        delete location.active;
        delete location.contentText;

        setFieldsValue(location);
    }

    function isCreate() {
        return props.value == undefined;
    }

    function onSave() {
        if (props.onSave) {
            props.onSave();
        }
    }

    function onSaveAndGetId(id) {
        console.log('save and get id', id);
        if (props.onSaveAndGetID) {
            props.onSaveAndGetID(id);
        }
    }

    function setDefaultValues() {
        setContextTextLangJson(null);
    }

    return (
        <>
            <DataEdit
                editHeader={editHeader}
                loading={loading}
                setLoading={setLoading}
                setDefaultValues={setDefaultValues}
                url={CONSTANTS_REQ.LOCATION.EDIT}
                setValues={setValues}
                width={1200}
                {...props}
                normalize={(values) => {
                    return {
                        ...values,
                        // projectId: projectId,// Session.getProjectId(),
                        headerImagePath:
                            values.headerImagePath != null && values.headerImagePath instanceof Object
                                ? values.headerImagePath.file_path
                                : values.headerImagePath,
                        firstImagePath:
                            values.firstImagePath != null && values.firstImagePath instanceof Object
                                ? values.firstImagePath.file_path
                                : values.firstImagePath,
                        secondImagePath:
                            values.secondImagePath != null && values.secondImagePath instanceof Object
                                ? values.secondImagePath.file_path
                                : values.secondImagePath,
                        webPage:
                            values.webPage != null && values.webPage.constructor === String
                                ? { id: parseInt(values.webPage) }
                                : values.webPage,
                    };
                }}
                onSave={onSave}
                onSaveAndGetID={onSaveAndGetId}
            >
                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item hidden={true}>{getFieldDecorator('id')(<Input hidden={true} />)}</Form.Item>
                        <Form.Item label={geti18nText('location.edit.name')}>
                            {getFieldDecorator('name', {
                                rules: [{ required: true, message: geti18nText('app.default.required') }],
                            })(<Input />)}
                        </Form.Item>
                        <InputMapCoordinates requred {...props} />
                        <InputByLanguage
                            fieldName="contentText"
                            notRequred
                            langJson={contextTextLangJson}
                            label={geti18nText('location.edit.contentText')}
                            {...props}
                            type="textArea"
                        />
                        <Form.Item label={geti18nText('location.edit.headerImage')}>
                            {getFieldDecorator('headerImagePath')(
                                <FileUpload
                                    fileType={CONSTANTS_FILE_TYPE.IMAGE}
                                    map={{ id: 'id', label: 'name' }}
                                    searchBy="name"
                                    disabled={false}
                                    addedData={{ projectId: projectId }}
                                />
                            )}
                        </Form.Item>
                        <Form.Item label={geti18nText('location.edit.firstImage')}>
                            {getFieldDecorator('firstImagePath')(
                                <FileUpload
                                    fileType={CONSTANTS_FILE_TYPE.IMAGE}
                                    map={{ id: 'id', label: 'name' }}
                                    searchBy="name"
                                    disabled={false}
                                    addedData={{ projectId: projectId }}
                                />
                            )}
                        </Form.Item>
                        <Form.Item label={geti18nText('location.edit.secondImage')}>
                            {getFieldDecorator('secondImagePath')(
                                <FileUpload
                                    fileType={CONSTANTS_FILE_TYPE.IMAGE}
                                    map={{ id: 'id', label: 'name' }}
                                    searchBy="name"
                                    disabled={false}
                                    addedData={{ projectId: projectId }}
                                />
                            )}
                        </Form.Item>
                        <Form.Item label={geti18nText('location.edit.webPage')}>
                            {getFieldDecorator('webPage')(
                                <SearchField
                                    url={CONSTANTS_REQ.WEBPAGE.SEARCH}
                                    map={{ id: 'id', label: 'name' }}
                                    searchBy="name"
                                    disabled={false}
                                    translate={true}
                                    addedData={{ forMap: true }}
                                />
                            )}
                        </Form.Item>
                        <Form.Item label={geti18nText('location.edit.linkUrl')}>
                            {getFieldDecorator('linkUrl')(<Input />)}
                        </Form.Item>
                        <Form.Item hidden={true} label={geti18nText('location.edit.column.order')}>
                            {getFieldDecorator('locationOrder')(<InputNumber min={0} precision={0} />)}
                        </Form.Item>
                        <Form.Item hidden={true}>{getFieldDecorator('project')(<Input hidden={true} />)}</Form.Item>
                    </Col>
                </Row>
            </DataEdit>
        </>
    );
});

export default LocationEdit;
