import React from 'react';
import { Row, Col } from 'antd';
import { Link } from 'react-router-dom';
import { Session } from '../../utils/Session';

export const NotFound = (props) => {
    return (
        <Row type="flex" justify="space-around" align="middle" className="height-100">
            <Col className="animated fadeIn faster">
                <Row type="flex" justify="center" align="middle">
                    <h1>{Session.getSetting("ux.title")}</h1>
                </Row>
                <Row type="flex" justify="center" align="middle">
                    <h1>{props.errorMessage}</h1>
                </Row>
                <Row type="flex" justify="center" align="middle">
                    <Link to="/admin">Home</Link>
                </Row>
            </Col>
        </Row>
    );
};
