import { Button, Col, DatePicker, Divider, Form, Icon, Input, Row, Select, notification } from 'antd';
import moment from 'moment';
import React, { forwardRef, useEffect, useState } from 'react';
import { Spinner } from '../../components/Spinner';
import { CONSTANTS_FILE_TYPE, CONSTANTS_REQ } from '../../utils/Constants';
import { requestGet, requestPut, RESPONSE } from '../../utils/RequestResolver';
import { geti18nText, Session } from '../../utils/Session';
import { normalizeComponents } from '../../utils/Utils';
import { FileUpload } from '../fileUpload/FileUpload';
import { InputByLanguage, translationsToObject } from '../InputByLanguage';

const AccomodationEdit = Form.create({ name: 'VideoBlockEdit' })((props) => {
    const { getFieldDecorator, getFieldValue, setFieldsValue } = props.form;
    const [fetching, setFetching] = useState(false);
    const [formValues, setFormValues] = useState(null);
    const [titleLangJson, setTitleLangJson] = useState(undefined);
    const [contentLangJson, setContentLangJson] = useState(undefined);
    const [contactLangJson, setContactLangJson] = useState(undefined);
    const [openingLangJson, setOpeningLangJson] = useState(undefined);
    const [priceLangJson, setPriceLangJson] = useState(undefined);
    const [lastKey, setLastKey] = useState(0);
    const [pricesKeys, setPricesKeys] = useState([]);
    const [webPage, setWebPage] = useState(null);
    const { RangePicker } = DatePicker;

    useEffect(() => {
        fetch();
    }, [props.visible]);

    useEffect(() => {
        props.form.resetFields();
        setFieldsValue(formValues);
    }, [formValues]);

    function fetch() {
        setFetching(true);
        requestGet(CONSTANTS_REQ.WEBPAGECOMPONENT.EDIT + '/' + props.id).then((result) => {
            setFetching(false);
            if (result.status === RESPONSE.OK) {
                setWebPage(result.data.webPage);
                setValues(result.data);
            }
        });
    }

     function showNotification(title, message, duration, type) {
        notification[type]({
            message: title,
            description: message,
            duration: duration,
        });
    }

    function okNotification() {
        showNotification(geti18nText('app.default.save.ok'), geti18nText('app.default.save.ok.desc'), 3, 'success');
    }

    function error(message) {
        showNotification(geti18nText('app.default.save.nok'), message, 3, 'error');
    } 


    function setValues(component_data) {
        var json;
        if (component_data.json) {
            let setJson = {};
            json = JSON.parse(component_data.json);
            setJson = translationsToObject(json, 'content', JSON.stringify(json.image.description));
            setJson = translationsToObject(json, 'title', JSON.stringify(json.title));
            setJson = translationsToObject(json, 'contact', JSON.stringify(json.contact));
            setJson = translationsToObject(json, 'opening', JSON.stringify(json.opening));
            setJson = translationsToObject(json, 'price', JSON.stringify(json.price));

            setTitleLangJson(JSON.stringify(json.title));
            setContentLangJson(json.image.description);
            setContactLangJson(JSON.stringify(json.contact));
            setOpeningLangJson(JSON.stringify(json.opening));
            setPriceLangJson(JSON.stringify(json.price));

            delete json.title;
            delete json.image.description;
            delete json.contact;
            delete json.opening;
            delete json.price;

            setJson['image-file_path'] = json.image.file_path
                ? { key: json['image'].file_path, file_path: json['image'].file_path }
                : null;

            setFormValues(setJson);
        }
    }

    function save(e, close) {
        e.preventDefault();
        props.form.validateFieldsAndScroll((err, values) => {
            let minPrice = {};
            if (!err) {
                let valuesToSave = {};
                let normalizedValues = normalizeComponents(values);

                valuesToSave['title'] = normalizedValues['title'];
                valuesToSave['image'] = {
                    file_path: normalizedValues['image-file_path'],
                    description: normalizedValues['content'],
                };
                valuesToSave['contact'] = normalizedValues['contact'];
                valuesToSave['opening'] = normalizedValues['opening'];
                valuesToSave['price'] = normalizedValues['price'];

                requestPut(CONSTANTS_REQ.WEBPAGECOMPONENT.UPDATEJSON + '/' + props.id, null, {
                    id: props.id,
                    json: JSON.stringify(valuesToSave),
                }).then((result) => {
                    if (result && result.status === RESPONSE.CREATED) {
                        console.log('okNotification');
                        okNotification();
                        close && props.closePopup();
                    } else {
                        error();
                        if (result && result.status === RESPONSE.BAD_REQUEST) {                          
                            if (result.data && result.data.field) {
                                switch (result.data.field) {
                                    case 'mandatory_fields_required':
                                        console.log(geti18nText('app.default.mandatory_fields_required'));
                                        error(geti18nText('app.default.mandatory_fields_required'));
                                        return;
                                    default:
                                        error();
                                        break;
                                }
                            }
                        }
                        if (result.data && result.data.error) {
                            console.log(JSON.stringify(result.data.error));
                            error(JSON.stringify(result.data.error));
                        }
                    }
                   
                });
            }
        });
    }

    function add() {
        let newId = lastKey;
        newId++;
        pricesKeys.map((value) => {
            if (value === newId) {
                newId++;
            }
        });
        let arrKeys = pricesKeys.slice();
        arrKeys.push(newId);
        setPricesKeys(arrKeys);
        setLastKey(newId);
    }

    function remove(index) {
        if (pricesKeys.length > 1) {
            var newArrKeys = pricesKeys.filter((key) => key != parseInt(index));
            setPricesKeys(newArrKeys);
            props.form.resetFields('price_' + index);
        }
    }

    if (fetching) {
        return <Spinner />;
    } else {
        return (
            <React.Fragment>
                <Divider></Divider>
                <Form>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item hidden={true}>{getFieldDecorator('id')(<Input hidden={true} />)}</Form.Item>
                            <Form.Item label={geti18nText('component.imagewithtitleandescription.edit.image')}>
                                {getFieldDecorator('image-file_path', {
                                    rules: [{ required: true, message: geti18nText('app.default.required') }],
                                })(
                                    <FileUpload
                                        fileType={CONSTANTS_FILE_TYPE.IMAGE}
                                        map={{ id: 'id', label: 'name' }}
                                        searchBy="name"
                                        disabled={false}
                                        addedData={{ projectId: Session.getProjectId() }}
                                    />
                                )}
                            </Form.Item>
                            <InputByLanguage
                                fieldName="title"
                                label={geti18nText('component.imagewithtitleandescription.edit.title')}
                                langJson={titleLangJson}
                                {...props}
                            />
                            <InputByLanguage
                                fieldName="content"
                                notRequred
                                label={geti18nText('component.imagewithtitleandescription.edit.description')}
                                langJson={contentLangJson}
                                minHeight={'250px'}
                                {...props}
                                type="rteEditor"
                            />
                            <InputByLanguage
                                fieldName="contact"
                                notRequred
                                label={geti18nText('component.accomodation.contact')}
                                langJson={contactLangJson}
                                minHeight={'150px'}
                                {...props}
                                type="rteEditor"
                            />
                            <InputByLanguage
                                fieldName="opening"
                                notRequred
                                label={geti18nText('component.accomodation.opening.time')}
                                langJson={openingLangJson}
                                minHeight={'150px'}
                                {...props}
                                type="rteEditor"
                            />
                            <InputByLanguage
                                fieldName="price"
                                notRequred
                                label={geti18nText('component.accomodation.price')}
                                langJson={priceLangJson}
                                minHeight={'150px'}
                                {...props}
                                type="rteEditor"
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24} style={{ textAlign: 'right', marginTop: '1rem' }}>
                            <Button style={{ marginRight: '1em' }} onClick={(e) => props.closePopup()}>
                                {geti18nText('app.default.button.cancel')}
                            </Button>
                            <Button style={{ marginRight: '1em' }} type="primary" onClick={(e) => save(e, false)}>
                                {geti18nText('app.default.button.save')}
                            </Button>
                            <Button
                                type="primary"
                                style={{ background: '#31b531', borderColor: '#31b531', textColor: 'white' }}
                                onClick={(e) => {
                                    save(e, true);
                                }}
                            >
                                {geti18nText('app.default.button.save.close')}
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </React.Fragment>
        );
    }
});

const PriceInput = forwardRef(({ value = {}, onChange }, ref) => {
    const [number, setNumber] = useState(0);
    const [currency, setCurrency] = useState('HRK');
    const { Option } = Select;
    const [currencyOption, setCurrencyOption] = useState([]);
    const currencyDesignationMap = [
        { id: 'HRK', designation: 'HRK' },
        { id: 'EUR', designation: 'EUR' },
        { id: 'USD', designation: 'USD' },
        { id: 'GBP', designation: 'GBP' },
    ];

    useEffect(() => {
        setCurrencyOptions();
    }, []);

    function setCurrencyOptions() {
        let currency = [];
        currencyDesignationMap.map((value, index) => {
            currency.push(<Option key={value.id}>{value.designation}</Option>);
        });
        setCurrencyOption(currency);
    }

    const triggerChange = (changedValue) => {
        if (onChange) {
            onChange({
                number,
                currency,
                ...value,
                ...changedValue,
            });
        }
    };

    const onNumberChange = (e) => {
        const newNumber = parseInt(e.target.value || 0, 10);

        if (Number.isNaN(number)) {
            return;
        }

        if (!('number' in value)) {
            setNumber(newNumber);
        }

        triggerChange({
            number: newNumber,
        });
    };

    const onCurrencyChange = (newCurrency) => {
        if (!('currency' in value)) {
            setCurrency(newCurrency);
        }

        triggerChange({
            currency: newCurrency,
        });
    };

    return (
        <span>
            <Row gutter={4}>
                <Col span={12}>
                    <Form.Item>
                        <Input
                            placeholder={geti18nText('component.accomodation.price.value')}
                            type="text"
                            value={value.number || number}
                            onChange={onNumberChange}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item>
                        <Select
                            value={value.currency || currency}
                            style={{
                                width: 80,
                                margin: '0 8px',
                            }}
                            onChange={onCurrencyChange}
                        >
                            {currencyOption}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
        </span>
    );
});

export default AccomodationEdit;
