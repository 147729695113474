import React, { useState, useEffect } from 'react';
import {
    Form,
    Row,
    Col,
    Input,
    Checkbox,
    InputNumber,
    Upload,
    Icon,
    Button,
    message,
    Modal,
    Select,
    notification,
} from 'antd';
import { geti18nText, Session } from '../../../utils/Session';
import { InputByLanguage, translationsToObject, getTranslated } from '../../../components/InputByLanguage';
import { DataEdit } from '../../../components/DataEdit';
import { CONSTANTS_REQ } from '../../../utils/Constants';
import { requestGet, requestPut, RESPONSE } from '../../../utils/RequestResolver';
import { downloadFile } from '../../../utils/Utils';
const ComponentEdit = Form.create({ name: 'ComponentEdit' })((props) => {
    const [editHeader, setEditHeader] = useState(geti18nText('search.type.edit.new'));
    const [loading, setLoading] = useState(false);
    const { getFieldDecorator, setFieldsValue } = props.form;
    const [contextTextLangJson, setContextTextLangJson] = useState(null);
    const [selectedTypes, setSelectedTypes] = useState([]);
    const [componentId, setComponentId] = useState();
    const [showImageBool, setShowImageBool] = useState();
    const [visible, setVisible] = useState(false);
    const [image1, setImage1] = useState(undefined);
    const [image2, setImage2] = useState(undefined);
    const [image3, setImage3] = useState(undefined);
    // dodani id za sliku koja se salje
    const [selectedImage, setSelectedImage] = useState('1');
    const [defaultJson, setDefaultJson] = useState(undefined);

    const { Option } = Select;
    function setValues(type) {
        setEditHeader(geti18nText(`component.${type.name}`));
        setComponentId(undefined);
        setComponentId(type.id);
        setSelectedTypes([]);
        showImages(type.id);
        if (type.type) {
            Session.getAppValue('COMPTYPES').forEach(
                (element) => (type[element] = type.type.split(';').includes(element))
            );
            setSelectedTypes(type.type.split(';'));
        }
        {
            geti18nText('component.image.image1');
        }
        setSelectedImage('1');
        setFieldsValue(type);
        setDefaultJson(type.defaultJson);
    }

    function showImages(id) {
        requestGet(CONSTANTS_REQ.COMPONENT.DOWNLOAD + id + '/' + '1', { time: Date.now() }, false, true).then(
            (result) => {
                if (result.status === RESPONSE.OK && result.data.file.size != 0) {
                    setImage1(window.URL.createObjectURL(result.data.file));
                } else {
                    setImage1(undefined);
                }
            }
        );
        requestGet(CONSTANTS_REQ.COMPONENT.DOWNLOAD + id + '/' + '2', { time: Date.now() }, false, true).then(
            (result) => {
                if (result.status === RESPONSE.OK && result.data.file.size != 0) {
                    setImage2(window.URL.createObjectURL(result.data.file));
                } else {
                    setImage2(undefined);
                }
            }
        );
        requestGet(CONSTANTS_REQ.COMPONENT.DOWNLOAD + id + '/' + '3', { time: Date.now() }, false, true).then(
            (result) => {
                if (result.status === RESPONSE.OK && result.data.file.size != 0) {
                    setImage3(window.URL.createObjectURL(result.data.file));
                } else {
                    setImage3(undefined);
                }
            }
        );
    }

    function showImage(id, imageId) {
        requestGet(CONSTANTS_REQ.COMPONENT.DOWNLOAD + id + '/' + imageId, { time: Date.now() }, false, true).then(
            (result) => {
                if (result.status === RESPONSE.OK && result.data.file.size != 0) {
                    switch (imageId) {
                        case '1':
                            setImage1(window.URL.createObjectURL(result.data.file));
                            break;
                        case '2':
                            setImage2(window.URL.createObjectURL(result.data.file));
                            break;
                        case '3':
                            setImage3(window.URL.createObjectURL(result.data.file));
                            break;
                        default:
                            return;
                    }
                } else {
                    switch (imageId) {
                        case '1':
                            setImage1(undefined);
                            break;
                        case '2':
                            setImage2(undefined);
                            break;
                        case '3':
                            setImage3(undefined);
                            break;
                        default:
                            return;
                    }
                }
            }
        );
    }

    function setSelectChange(value) {
        setSelectedImage(value);
    }

    function deleteImage(value) {
        requestPut(CONSTANTS_REQ.COMPONENT.DELETE + componentId + '/' + value).then((response) => {
            if (response.status === RESPONSE.OK) {
                notification.open({
                    message:
                        geti18nText('component.image.image1').slice(0, -1) +
                        value +
                        ' ' +
                        geti18nText('component.image.isDeleted'),
                    icon: <Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a" />,
                });
                showImage(componentId, value);
            } else {
                notification.open({
                    message: geti18nText('app.default.delete.nok'),
                    icon: <Icon type="exclamation-circle" theme="twoTone" twoToneColor="#ff0000" />,
                });
            }
        });
    }

    function setDefaultValues() {
        setContextTextLangJson(null);
        setEditHeader(geti18nText('design.settings.component.general'));
    }

    function onChecked(obj) {
        if (obj.target.checked) {
            let types = selectedTypes.slice();
            types.push(obj.target.name);
            setSelectedTypes(types);
        } else if (!obj.target.checked) {
            let types = selectedTypes.slice();
            types = types.filter((type) => type != obj.target.name);
            setSelectedTypes(types);
        }
    }

    const upload = {
        name: 'file',
        accept: '.jpg,.jpeg,.png',
        action: CONSTANTS_REQ.COMPONENT.IMAGE + componentId + '/' + selectedImage,
        showUploadList: false,
        multiple: false,
        headers: {
            Authorization: 'Bearer ' + Session.getUserToken(),
        },
        onChange(info, event) {
            if (info.file.status === 'uploading') {
                setLoading(true);
                message.destroy();
                message.loading(`${info.file.name} ${geti18nText('import.text.uploading')}`);
            }
            if (info.file.status === 'done') {
                setLoading(false);
                message.destroy();
                message.success(`${info.file.name} ${geti18nText('import.text.done')}`);
                if (info.file.response) {
                    showImage(componentId, selectedImage);
                }
            } else if (info.file.status === 'error') {
                setLoading(false);
                message.destroy();
                message.error(`${info.file.name} ${geti18nText('import.text.error')}`);
            }
        },
    };

    return (
        <DataEdit
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            setDefaultValues={setDefaultValues}
            setValues={setValues}
            url={CONSTANTS_REQ.COMPONENT.EDIT}
            width={1200}
            {...props}
            normalize={(values) => {
                let types = selectedTypes.join(';');
                delete values.header;
                delete values.image;
                delete values.video;
                delete values.text;
                delete values.special;
                delete values.map;

                values.type = types;
                values.defaultJson = defaultJson;

                return {
                    ...values,
                };
            }}
        >
            <Row gutter={24}>
                <Col span={24} style={{ width: '100%' }}>
                    <Form.Item
                        hidden={true}
                        label={geti18nText('design.settings.component.text_option.component_title')}
                    >
                        {getFieldDecorator('text')(<Input hidden={true} />)}
                    </Form.Item>
                    {/* <Form.Item label={geti18nText('component.description')}>
                        {getFieldDecorator('description')(<Input />)}
                        </Form.Item> */}
                    <Form.Item
                        label={geti18nText('design.settings.component.menu.element')}
                        style={{ marginBottom: '-5px' }}
                    />
                    <div className="flexbox-container">
                        {Session.getAppValue('COMPTYPES').map((obj) => {
                            return (
                                <Form.Item key={obj}>
                                    {getFieldDecorator(obj, {
                                        valuePropName: 'checked',
                                        initialValue: false,
                                    })(
                                        <Checkbox key={obj} name={obj} onChange={onChecked}>
                                            {geti18nText(`component.type.${obj}`)}
                                        </Checkbox>
                                    )}
                                </Form.Item>
                            );
                        })}{' '}
                    </div>
                    <Form.Item label={geti18nText('page.type.table.column.typeOrder')}>
                        {getFieldDecorator('order')(<InputNumber />)}
                    </Form.Item>
                    <div className="flexbox-container"></div>
                    <div className="flexbox-container">
                        {image1 ? (
                            <div style={{ alignItems: 'end', display: 'grid' }}>
                                <img
                                    style={{
                                        width: '240px',
                                        height: 'auto',
                                        margin: '20px',
                                        alignItems: 'center',
                                    }}
                                    src={image1}
                                    alt="Image 1 is empty"
                                    onError={(event) => (event.target.style.display = 'none')}
                                />
                                <div style={{ alignItems: 'top', justifyContent: 'center', display: 'flex' }}>
                                    <Upload {...upload}>
                                        <Button onClick={() => setSelectChange('1')}>
                                            <Icon type="upload" /> {geti18nText('component.image.upload')}
                                        </Button>
                                    </Upload>
                                    <Button style={{ marginLeft: '20px' }} onClick={() => deleteImage('1')}>
                                        <Icon type="delete" /> {geti18nText('component.image.delete')}
                                    </Button>
                                </div>
                            </div>
                        ) : (
                            <div style={{ alignItems: 'end', display: 'grid', width: '240px' }}>
                                <div style={{ textAlign: 'center', marginTop: '125px', marginBottom: '90px' }}>
                                    {' '}
                                    {geti18nText('component.image.empty')}
                                </div>
                                {/*   <Icon type="file-image" style={{ fontSize: '70px', margin: '20px' }} />
                                 */}{' '}
                                <Upload {...upload}>
                                    <Button style={{ marginLeft: '40px' }} onClick={() => setSelectChange('1')}>
                                        <Icon type="upload" /> {geti18nText('component.image.upload')}
                                    </Button>
                                </Upload>
                            </div>
                        )}
                        {image2 ? (
                            <div style={{ alignItems: 'end', display: 'grid' }}>
                                <img
                                    style={{ width: '240px', height: 'auto', margin: '20px', alignItems: 'center' }}
                                    src={image2}
                                    alt="Image 1 is empty"
                                    onError={(event) => (event.target.style.display = 'none')}
                                />
                                <div style={{ alignItems: 'top', justifyContent: 'center', display: 'flex' }}>
                                    <Upload {...upload}>
                                        <Button onClick={() => setSelectChange('2')}>
                                            <Icon type="upload" /> {geti18nText('component.image.upload')}
                                        </Button>
                                    </Upload>
                                    <Button style={{ marginLeft: '20px' }} onClick={() => deleteImage('2')}>
                                        <Icon type="delete" /> {geti18nText('component.image.delete')}
                                    </Button>
                                </div>
                            </div>
                        ) : (
                            <div style={{ alignItems: 'end', display: 'grid', width: '240px' }}>
                                <div style={{ textAlign: 'center', marginTop: '125px', marginBottom: '90px' }}>
                                    {geti18nText('component.image.empty')}
                                </div>
                                {/*   <Icon type="file-image" style={{ fontSize: '70px', margin: '20px' }} />
                                 */}{' '}
                                <Upload {...upload}>
                                    <Button style={{ marginLeft: '40px' }} onClick={() => setSelectChange('2')}>
                                        <Icon type="upload" /> {geti18nText('component.image.upload')}
                                    </Button>
                                </Upload>
                            </div>
                        )}
                        {image3 ? (
                            <div style={{ alignItems: 'end', display: 'grid' }}>
                                <img
                                    style={{ width: '240px', height: 'auto', margin: '20px', alignItems: 'center' }}
                                    src={image3}
                                    alt="Image 1 is empty"
                                    onError={(event) => (event.target.style.display = 'none')}
                                />
                                <div style={{ alignItems: 'top', justifyContent: 'center', display: 'flex' }}>
                                    <Upload {...upload}>
                                        <Button onClick={() => setSelectChange('3')}>
                                            <Icon type="upload" /> {geti18nText('component.image.upload')}
                                        </Button>
                                    </Upload>
                                    <Button style={{ marginLeft: '20px' }} onClick={() => deleteImage('3')}>
                                        <Icon type="delete" /> {geti18nText('component.image.delete')}
                                    </Button>
                                </div>
                            </div>
                        ) : (
                            <div style={{ alignItems: 'end', display: 'grid', width: '240px' }}>
                                <div style={{ textAlign: 'center', marginTop: '125px', marginBottom: '90px' }}>
                                    {' '}
                                    {geti18nText('component.image.empty')}
                                </div>
                                {/*  <Icon type="file-image" style={{ fontSize: '70px', margin: '20px' }} />
                                 */}{' '}
                                <Upload {...upload}>
                                    <Button style={{ marginLeft: '40px' }} onClick={() => setSelectChange('3')}>
                                        <Icon type="upload" /> {geti18nText('component.image.upload')}
                                    </Button>
                                </Upload>
                            </div>
                        )}
                    </div>

                    <Form.Item hidden={true} label={geti18nText('component.id')}>
                        {getFieldDecorator('id')(<Input hidden={true} />)}
                    </Form.Item>
                    <Form.Item hidden={true}>{getFieldDecorator('name')(<Input hidden={true} />)}</Form.Item>
                    <Form.Item hidden={true}>{getFieldDecorator('active')(<Input hidden={true} />)}</Form.Item>
                    <Form.Item hidden={true}>{getFieldDecorator('adminComponent')(<Input hidden={true} />)}</Form.Item>
                    <Form.Item hidden={true}>{getFieldDecorator('userComponent')(<Input hidden={true} />)}</Form.Item>
                    <Form.Item>{<Input hidden={true} />}</Form.Item>
                </Col>
            </Row>
        </DataEdit>
    );
});

export default ComponentEdit;
