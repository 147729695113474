import React, { useState } from 'react';
import { Form, Modal, notification, Input } from 'antd';
import LangList from '../admin/lang/LangList';
import { Session, geti18nText } from '../../utils/Session';
import { CONSTANTS_REQ } from '../../utils/Constants';
import { requestPost, RESPONSE } from '../../utils/RequestResolver';
import { SearchField } from '../../components/Search';

export const ProjectEdit = Form.create({ name: 'ProjectEdit' })((props) => {
    const { getFieldDecorator, setFieldsValue } = props.form;

    const [editHeader, setEditHeader] = useState(geti18nText('projects.edit.new'));
    const [loading, setLoading] = useState(false);
    const [project, setProject] = useState(null);

    function save(e) {
        e.preventDefault();

        if (project) {
            closeModal();
            return;
        }

        props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                setLoading(true);
                let themeValue;
                themeValue =
                    values.theme != null
                        ? values.theme.constructor === String
                            ? { id: parseInt(values.theme) }
                            : { id: values.theme.key }
                        : null;

                requestPost(CONSTANTS_REQ.PROJECT.EDIT, undefined, {
                    id: null,
                    name: values['name'],
                    theme: themeValue,
                }).then((result) => {
                    setLoading(false);
                    if (result && result.status === RESPONSE.CREATED) {
                        setLoading(false);
                        okNotification();
                        if (result.data && result.data.id) {
                            Session.setProject({
                                id: result.data.id,
                                name: values['name'],
                                theme: themeValue,
                                active: true,
                            });
                            setProject(result.data.id);
                            props.onSave();
                        }
                    } else {
                        if (result.data && result.data.error) {
                            error(JSON.stringify(result.data.error));
                        } else {
                            error();
                        }
                    }
                });
            }
        });
    }

    function closeModal() {
        setProject(null);
        props.setVisible(false);
    }

    function showNotification(title, message, duration, type) {
        notification[type]({
            message: title,
            description: message,
            duration: duration,
        });
    }

    function okNotification() {
        showNotification(geti18nText('app.default.save.ok'), geti18nText('app.default.save.ok.desc'), 3, 'success');
    }

    function error(message) {
        showNotification(geti18nText('app.default.save.nok'), message, 0, 'error');
    }

    return (
        <>
            <Modal
                visible={props.visible}
                title={editHeader}
                width={1200}
                okText={geti18nText('app.default.button.save')}
                onCancel={closeModal}
                onOk={save}
            >
                {!project && (
                    <Form>
                        <Form.Item hidden={true}>{getFieldDecorator('id')(<Input hidden={true} />)}</Form.Item>
                        <Form.Item label={geti18nText('projects.edit.projectName')}>
                            {getFieldDecorator('name', {
                                rules: [
                                    {
                                        required: true,
                                        message: geti18nText('projects.validation.projectName'),
                                    },
                                ],
                            })(<Input />)}
                            <Form.Item label={geti18nText('projects.edit.theme')}>
                                {getFieldDecorator('theme')(
                                    <SearchField
                                        url={CONSTANTS_REQ.THEME.SEARCH}
                                        map={{ id: 'id', label: 'name' }}
                                        searchBy="name"
                                        disabled={false}
                                        addedData={{ active: true }}
                                    />
                                )}
                            </Form.Item>
                        </Form.Item>
                    </Form>
                )}
                {project && <LangList />}
            </Modal>
        </>
    );
});
