import React, { useEffect, useState } from 'react';
import { useRef } from 'react';
import { useHistory } from 'react-router-dom';
import {
    Row,
    Form,
    Input,
    Button,
    Col,
    Modal,
    notification,
    Checkbox,
    Tag,
    Popover,
    Typography,
    Icon,
    Tabs,
    Popconfirm,
    Collapse,
    message,
    Select,
    Divider,
} from 'antd';
import { geti18nText, Session, ENUMS, hasAnyRole } from '../../../utils/Session';
import { CONSTANTS_REQ, CONSTANTS_FILE_TYPE, UPLAD_CONTENT_FOLDER } from '../../../utils/Constants';
import { setProjectLanguages } from '../../../utils/Utils';
import { SearchField } from '../../../components/Search';
import { DataEdit } from '../../../components/DataEdit';
import { selectProject } from '../../../slices/projectSlices';
import { refreshMenu } from '../../../slices/menuSlice';
import { useDispatch, useSelector } from 'react-redux';
import { requestGet, RESPONSE, requestPost } from '../../../utils/RequestResolver';
import InputMarkerCoordinates from '../../../components/inputMarkerCoordinates/InputMarkerCoordinates';
import { FileUpload } from '../../../components/fileUpload/FileUpload';
import TextArea from 'antd/lib/input/TextArea';
import { DataTable, getColumnSearch, getColumnSearchOption } from '../../../components/DataTable';
import moment from 'moment';
import packageJson from './../../../../package.json';
import DeseignEditor from '../../../components/deseign-editor';

const ProjectManagementIndex = Form.create({ name: 'ProjectManagementIndex' })((props) => {
    const { Text } = Typography;
    const { Panel } = Collapse;
    const { TabPane } = Tabs;
    let history = useHistory();
    const { getFieldDecorator, setFieldsValue, getFieldValue } = props.form;
    const [loading, setLoading] = useState(false);
    const [projectId, setProjectId] = useState(undefined);
    const [inputNamePopupVisible, setNamePopupVisible] = useState(false);
    const [inputShowMapVisible, setInputShowMapVisible] = useState(true);
    const [inputShowForecastVisible, setInputShowForecastVisible] = useState(true);
    const [inputShowWeatherStateData, setInputShowWeatherStateData] = useState(false);
    const [hiddenForWeb, setHiddenForWeb] = useState(true);
    const hiddenForWebRef = useRef(true);
    const [hiddenForKiosk, setHiddenForKiosk] = useState(true);
    const [requiredLocation, setRequiredLocation] = useState(true);
    const projectCopyName = useRef(null);
    const dispatch = useDispatch();
    const [nextJsLogRefresh, setNextJsLogRefresh] = useState(false);
    const [nextJsLogVisible, setNextJsLogVisible] = useState(false);
    const [nextJsLogStatusVisible, setNextJsLogStatusVisible] = useState(false);
    const [nextJsLogStatus, setNextJsLogStatus] = useState(null);
    const [domainSubDomainOnChangeSaved, setDomainSubDomainOnChangeSaved] = useState(true);
    const [selectedTab, setSelectedTab] = useState('settings');
    const [showComponents, setShowComponents] = useState(false);
    const [problemReportShow, setProblemReportShow] = useState(false);
    const [facebookChatShow, setFacebookChatShow] = useState(false);
    const [facebookVerificationShow, setFacebookVerificationShow] = useState(false);
    const [emailsToNewsletter, setEmailsToNewsletter] = useState(undefined);
    const [emailsToInquiry, setEmailsToInquiry] = useState(undefined);
    const [emailsToReport, setEmailsToReport] = useState(undefined);
    const [projectTheme, setProjectTheme] = useState({ id: 2 });
    const [hiddenKioskTime, setHiddenKioskTime] = useState(true);
    const [designVisible, setDesignVisible] = useState(false);
    const [designComponentName, setDesignComponentName] = useState(null);
    const [componentId, setComponentId] = useState(null);
    const [showScreenSaver, setShowScreenSaver] = useState(false);

    function setDefaultValues() {
        props.form.resetFields();
        setNextJsLogStatusVisible(false);
        setNextJsLogStatus(null);
        setProjectId(undefined);
        setHiddenForWeb(true);
        setInputShowMapVisible(true);
        setInputShowForecastVisible(true);
        setShowScreenSaver(false);
        setFieldsValue({ theme: undefined });
        locationValidator();
        setShowComponents(false);
        setSelectedTab('settings');
        setProblemReportShow(false);
        setEmailsToReport(undefined);
        setProjectTheme({ id: 2 });
        setFacebookChatShow(false);
        setFacebookVerificationShow(false);
        setHiddenKioskTime(true);
        setEmailsToInquiry(undefined);
        setEmailsToNewsletter(undefined);
        setEmailsToReport(undefined);
        setInputShowWeatherStateData(false)
    }

    function onSaveAndGetData(data) {
        Session.setProject(data);
        setShowComponents(true);
        if (isCreate()) {
            createNewUploadFolder(data.id);
        }
        dispatch(
            selectProject({
                projectId: data.id,
                projectName: data.name,
                themeShortName: data.theme && data.theme.shortName ? data.theme.shortName : '',
            })
        );
        setProjectLanguages(data.id);

        dispatch(
            refreshMenu({
                refreshLastNode: false,
                refreshProjectNode: true,
                refreshNodes: [],
            })
        );

        if (isCreate()) {
            history.push('/lang/' + data.id);
        }

        /* if (data && data.theme && data.theme.id && data.theme.id === 2) {
            let defaultFooter = Session.getAppValue('COMPONENTS').filter((fObj) => {
                if (fObj.name == 'touristFooter') {
                    return fObj;
                }
            })[0];
            let defaultNabar = Session.getAppValue('COMPONENTS').filter((fObj) => {
                if (fObj.name == 'touristMenu') {
                    return fObj;
                }
            })[0];
            requestPost(CONSTANTS_REQ.SETTINGS.SAVE_SETTINGS, undefined, {
                settings: [
                    {
                        settingKey: 'footer.id',
                        settingGroup: 'footer',
                        settingValue: JSON.stringify({
                            key: defaultFooter.id.toString(),
                            label: geti18nText('component.' + defaultFooter.name),
                        }),
                        project: { id: Session.getProjectId() },
                    },
                    {
                        settingKey: 'nav.id',
                        settingGroup: 'nav',
                        settingValue: JSON.stringify({
                            key: defaultNabar.id.toString(),
                            label: geti18nText('component.' + defaultNabar.name),
                        }),
                        project: { id: Session.getProjectId() },
                    },
                ],
            }).then((result) => {
                if (result && result.status === RESPONSE.OK) {
                    console.log('defaultSettingFooternavSaved');
                } else {
                    console.log('error saving defaultFotterNav settings');
                }
            });
        } else {
            requestGet(CONSTANTS_REQ.SETTINGS.REMOVE_DEFAULTS, { projectId: Session.getProjectId() }).then((res) => {
                if (res && res.status === RESPONSE.OK) {
                    console.log('defaultSettingFooternavRemoved');
                } else {
                    console.log('error remoce defaultFotterNav settings');
                }
            });
        } */

        window.location.reload();
    }

    const DesignModal = (props) => {
        function closePopup() {
            props.setVisible(false);
        }

        return (
            <>
                <Modal
                    zIndex={1100}
                    visible={props.visible}
                    onCancel={closePopup}
                    width={1200}
                    destroyOnClose={true}
                    footer={[]}
                >
                    <DeseignEditor
                        id={props.id}
                        pageComponentId={props.pageComponentId}
                        projectId={Session.getProjectId()}
                        type={'component'}
                        closePopup={closePopup}
                        webPageComponentId={props.webPageComponentId}
                        componentName={props.name}
                    ></DeseignEditor>
                </Modal>
            </>
        );
    };

    const changeEmailsNewsletter = (value) => {
        setEmailsToNewsletter(value);
    };

    const changeEmailsInquiry = (value) => {
        setEmailsToInquiry(value);
    };

    const changeEmailsReport = (value) => {
        setEmailsToReport(value);
    };

    useEffect(() => {
        hiddenForWebRef.current = hiddenForWeb;
    }, [hiddenForWeb]);

    function createNewUploadFolder(projectId) {
        requestPost(CONSTANTS_REQ.DIRECTORY.TREE, undefined, {
            id: projectId,
            name: UPLAD_CONTENT_FOLDER,
        }).then((result) => {
            if (result.data && result.data.error) {
                // error(JSON.stringify(result.data.error));
            }
        });
    }

    function onSave(data) {
        setDomainSubDomainOnChangeSaved(true);
        dispatch(
            refreshMenu({
                refreshLastNode: false,
                refreshProjectNode: false,
                refreshNodes: [],
            })
        );
    }

    function onSaveDomain() {
        props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                requestPost(CONSTANTS_REQ.PROJECT.SETDOMAIN, undefined, {
                    projectId: Session.getProjectId(),
                    subdomain: getFieldValue('subdomain'),
                    domain: getFieldValue('domain').id
                        ? parseInt(getFieldValue('domain').id)
                        : parseInt(getFieldValue('domain').key),
                }).then((projectResult) => {
                    if (projectResult && projectResult.status === RESPONSE.OK) {
                        setDomainSubDomainOnChangeSaved(true);
                        dispatch(
                            refreshMenu({
                                refreshLastNode: false,
                                refreshProjectNode: true,
                                refreshNodes: [],
                            })
                        );
                    }
                });
            }
        });
    }

    const onNamePopupCancel = () => {
        setNamePopupVisible(false);
    };

    const onNextJsLogCancel = () => {
        setNextJsLogVisible(false);
    };

    const onNextJsLogVisible = () => {
        setProjectId(Session.getProjectId());
        setNextJsLogVisible(true);
    };

    function domainOnChange(value) {
        setDomainSubDomainOnChangeSaved(false);
        // props.form.resetFields('subdomain');
        setFieldsValue({ subdomain: '@' });
    }
    const onNamePopupSave = () => {
        if (projectCopyName.current.value == '') {
            return;
        }
        requestPost(CONSTANTS_REQ.PROJECT.COPY, undefined, {
            id: Session.getProjectId(),
            name: projectCopyName.current.value,
        })
            .then((projectResult) => {
                if (projectResult && projectResult.status === RESPONSE.OK) {
                    dispatch(
                        refreshMenu({
                            refreshLastNode: false,
                            refreshProjectNode: true,
                            refreshNodes: [],
                        })
                    );
                    //dispatch(
                    notification['success']({
                        message: geti18nText('project.table.copy'),
                        description: geti18nText('project.edit.copy.ok'),
                        duration: 3,
                    });
                }
            })
            .catch((e) => {
                if (e != null) {
                    notification['error']({
                        message: geti18nText('project.table.copy'),
                        description: geti18nText('project.edit.copy.error'),
                        duration: 3,
                    });
                }
                console.log(e);
            });
        setNamePopupVisible(false);
    };

    function copyProject() {
        setNamePopupVisible(true);
    }

    function setValues(data) {
        props.form.resetFields();
        setNextJsLogStatusVisible(false);
        refreshStatus();
        delete data.active;
        if (data.connectedProjects != null && data.connectedProjects.length > 0) {
            data.projects = data.connectedProjects.map((pr) => {
                return { id: pr.id + '', text: pr.name };
            });
        }
        delete data.connectedProjects;
        setProjectId(data.id);

        // if (data.forWeb) {
        setInputShowMapVisible(!data.mapShow);
        //}

        
        setInputShowForecastVisible(!data.forecastShow);
        if(data.weatherStateApiKey) {
            setInputShowWeatherStateData(true)
            data.weatherStateApiKeyShow=true;
        }

        if (data.problemReportShow != null) {
            setProblemReportShow(data.problemReportShow);
        }

        if (data.forWeb) {
            setDomainSubDomainOnChangeSaved(true);
        }
        setHiddenForWeb(!data.forWeb);

        setHiddenForKiosk(!data.forKiosk);

        // setHiddenKioskTime(!data.theme.id)

        setFieldsValue(data);
        if (data.imagePath) {
            setFieldsValue({ imagePath: { key: data.imagePath, file_path: data.imagePath } });
        }
        if (data.theme && data.theme.id == 1) {
            setFieldsValue({ theme: true });
            setProjectTheme({ id: 1 });
            setShowScreenSaver(true);
            setHiddenKioskTime(false);
        } else {
            setFieldsValue({ theme: false });
            setProjectTheme({ id: 2 });
            setShowScreenSaver(false);
            setHiddenKioskTime(true);
        }
        /* if (data.themeTime) {
            setKioskTime(true);
        } */
        if (data.domain == undefined) {
            setDefaultDomain();
            setDomainSubDomainOnChangeSaved(false);
        }

        if (data.subdomain == undefined) {
            setDomainSubDomainOnChangeSaved(false);
            setFieldsValue({ subdomain: '@' });
        }
        if (data.emailsToNewsletter) {
            setEmailsToNewsletter(data.emailsToNewsletter.split(','));
            setFieldsValue({ emailsToNewsletter: data.emailsToNewsletter.split(',') });
        }
        if (data.emailsToInquiry) {
            setEmailsToInquiry(data.emailsToInquiry.split(','));
            setFieldsValue({ emailsToInquiry: data.emailsToInquiry.split(',') });
        }
        if (data.emailsToReport) {
            setEmailsToReport(data.emailsToReport.split(','));
            setFieldsValue({ emailsToReport: data.emailsToReport.split(',') });
        }

        if (data.facebookPageId) {
            setFacebookChatShow(true);
            setFieldsValue({ facebookChatShow: true });
        }
        if (data.facebookVerificationDomainId) {
            setFacebookVerificationShow(true);
            setFieldsValue({ facebookVerificationShow: true });
        }

        if (data.landingPage != null) {
            data.projectLandingPage = [{ id: data.landingPage.id + '', text: data.landingPage.name }];
            setFieldsValue({ projectLandingPage: data.projectLandingPage });
        }
        locationValidator();
        setShowComponents(true);
    }

    function setDefaultDomain(resetSubdomain = false) {
        requestGet(CONSTANTS_REQ.PROJECT.GETDEFAULTDOMAIN, {
            projectId: Session.getProjectId(),
        }).then((result) => {
            setLoading(false);
            if (result.status == RESPONSE.OK && result.data != undefined) {
                setFieldsValue({ domain: { id: result.data.id + '', name: result.data.name } });
                if (resetSubdomain) {
                    setFieldsValue({ subdomain: '@' });
                }
            }
        });
    }

    function isDomainRequired() {
        const res = isCreate() ? false : hiddenForWebRef.current == false ? true : false;
        return res;
    }

    const validateDomain = (rule, value, callback) => {
        if (isDomainRequired == true) {
            if (getFieldValue('domain')) {
                if (!value) {
                    return callback(geti18nText('app.default.required'));
                }
            }
        }
        callback();
    };

    const validateSubdomain = (rule, value, callback) => {
        // const hidden = hiddenForWeb;
        if (hiddenForWebRef.current == false && isCreate() == false) {
            setDomainSubDomainOnChangeSaved(false);
            if (getFieldValue('domain')) {
                if (!value) {
                    callback(geti18nText('app.default.required'));
                }
                if ((!/^[a-zA-Z0-9-]*$/.test(value) || value.endsWith('-')) && !/^[@]*$/.test(value)) {
                    callback(geti18nText('projectManagement.validate.subdomain.error'));
                } else {
                    setLoading(true);
                    requestGet(CONSTANTS_REQ.PROJECT.SUBDOMAIN + '/' + value, {
                        domain: getFieldValue('domain').id
                            ? parseInt(getFieldValue('domain').id)
                            : parseInt(getFieldValue('domain').key),
                    }).then((result) => {
                        setLoading(false);
                        if (result.status == RESPONSE.OK) {
                            if (isCreate() && result.data.id === null) {
                                callback(geti18nText('projectManagement.validate.subdomain.unique'));
                            } else if (result.data.id == projectId) {
                                setDomainSubDomainOnChangeSaved(true);
                                callback();
                            } else if (result.data.id != null) {
                                if (result.data.subdomain != '@') {
                                    callback(geti18nText('projectManagement.validate.subdomain.unique'));
                                } else {
                                    if (
                                        result.data.domain.id === getFieldValue('domain').id
                                            ? parseInt(getFieldValue('domain').id)
                                            : parseInt(getFieldValue('domain').key)
                                    ) {
                                        callback(geti18nText('projectManagement.edit.domain.subdomain.exist'));
                                    } else {
                                        callback();
                                    }
                                }
                            } else {
                                callback();
                            }
                        } else if (result.status == RESPONSE.INTERNAL_SERVER_ERROR) {
                            callback(geti18nText('projectManagement.validate.subdomain.reserved'));
                        } else {
                            callback(geti18nText('projectManagement.validate.subdomain.unique'));
                        }
                    });
                }
            } else {
                callback(geti18nText('projectManagement.edit.domain.select'));
            }
        } else {
            callback();
        }
    };

    function isCreate() {
        if (hasAnyRole(['ROLE_ADMIN'])) {
            return props.match.params.id === 'create';
        } else {
            if (props.match.params.id === 'create') {
                history.push('/');
            } else {
                return false;
            }
        }
    }

    const formItemLayout = {
        labelCol: {
            md: { span: 24 },
            xl: { span: 3 },
        },
        wrapperCol: {
            md: { span: 24 },
            xl: { span: 8 },
        },
    };

    function onChangeMapShow(checked) {
        if (checked.target.checked) {
            // props.form.resetFields('imagePath');
            // props.form.resetFields('latitude');
            // props.form.resetFields('longitude');
            // props.form.resetFields('region');
            locationValidator();
        }
        setInputShowMapVisible(!inputShowMapVisible);
    }

    function onChangeReportShow(checked) {
        if (checked.target.checked === false) {
            setEmailsToReport(null);
        }
        setProblemReportShow(!problemReportShow);
    }
    function onChangeFacebookChatShow(checked) {
        if (checked.target.checked === false) {
            props.form.resetFields('facebookPageId');
        }
        setFacebookChatShow(!facebookChatShow);
    }
    function onChangeFacebookVerificationShow(checked) {
        if (checked.target.checked === false) {
            props.form.resetFields('facebookVerificationDomainId');
        }
        setFacebookVerificationShow(!facebookVerificationShow);
    }

    function onChangeSmartTourist(checked) {
        setShowScreenSaver(false);
        if (checked.target.checked === true) {
            setProjectTheme({ id: 1 });
            setShowScreenSaver(true);
        } else if (checked.target.checked === false) {
            setProjectTheme({ id: 2 });
        }
        setHiddenKioskTime(!hiddenKioskTime);
    }

    function onChangeKioskTime(checked) {
        console.log('Kiosk time:');
        console.log(checked);
        //-2  props.form.resetFields('themeTime');
        //  setKioskTime(!kioskTime);
    }

    function onChangeForecastShow(checked) {
        if (checked.target.checked) {
            // props.form.resetFields('imagePath');
            // props.form.resetFields('latitude');
            // props.form.resetFields('longitude');
            // props.form.resetFields('region');
            locationValidator();
        }
        setInputShowForecastVisible(!inputShowForecastVisible);
    }
    
    function onChangeWeatherStateDataShow(checked) {
        setInputShowWeatherStateData(!inputShowWeatherStateData);
    }

    function onChangeForWeb(checked) {
        setDomainSubDomainOnChangeSaved(false);
        if (!checked.target.checked) {
            props.form.resetFields('domain');
            props.form.resetFields('subdomain');
            setFieldsValue({ mapShow: false });
            setInputShowMapVisible(true);
            setHiddenForWeb(true);
        } else if (checked.target.checked) {
            props.form.resetFields('domain');
            props.form.resetFields('subdomain');
            setDefaultDomain(true);
            setDomainSubDomainOnChangeSaved(false);
            setHiddenForWeb(false);
        }
        // setHiddenForWeb(!hiddenForWeb);
    }

    function onChangeForKiosk(checked) {
        setHiddenForKiosk(!hiddenForKiosk);
    }

    function isDomainRequired() {
        const res = isCreate() ? false : hiddenForWebRef.current == false ? true : false;
        return res;
    }

    function getCustomButtons() {
        let buttons = [];
        if (!isCreate()) {
            if (hasAnyRole(['ROLE_ADMIN'])) {
                buttons.push({
                    text: geti18nText('projectManagement.copy'),
                    loading: loading,
                    icon: 'copy',
                    style: { marginRight: '1em' },
                    onClick: copyProject,
                });
            }
            buttons.push({
                text: !hiddenForKiosk
                    ? geti18nText('projectManagement.preview.web')
                    : geti18nText('projectManagement.preview'),
                loading: loading,
                icon: 'cloud',
                style: { marginRight: '1em' },
                onClick: () => openPreview('web'),
            });
        }
        if (!hiddenForKiosk) {
            buttons.push({
                text: geti18nText('projectManagement.preview.kiosk'),
                loading: loading,
                icon: 'cloud',
                style: { marginRight: '1em' },
                onClick: () => openPreview(),
            });
        }
        return buttons;
        /*   if (!isCreate() && !hiddenForWeb && domainSubDomainOnChangeSaved) {
            buttons.push({
                text: geti18nText('projectManagement.edit.publish'),
                loading: loading,
                icon: 'cloud-upload',
                style: { marginRight: '1em' },
                onClick: publish,
                addPopconfirm: true,
            });
        }
      */
    }
    function locationValidator(rule, value, callback) {
        const { form } = props;
        let lat = form.getFieldValue('latitude');
        let long = form.getFieldValue('longitude');
        let region = form.getFieldValue('region');
        if (
            (lat === undefined || lat == '' || long === undefined || long == '') &&
            (region == undefined || region == '')
        ) {
            setRequiredLocation(true);
            if (callback) callback();
        } else {
            setRequiredLocation(false);
            if (callback) {
                callback();
            }
        }
    }
    function onMapCoordinatesChange() {
        locationValidator();
    }

    const setTagColor = (status = null) => {
        var status = status ? status : nextJsLogStatusVisible ? nextJsLogStatus.status : undefined;
        if (status) {
            switch (status) {
                case 0:
                    return 'green';
                    break;
                case 1:
                    return 'red';
                    break;
                case 2:
                    return 'orange';
                    break;
            }
        }
    };

    const refreshStatus = () => {
        requestGet(CONSTANTS_REQ.NEXTJSLOG.STATUS, {
            projectId: Session.getProjectId(),
        }).then((result) => {
            if (result.status == RESPONSE.OK) {
                setNextJsLogRefresh(null);
                setNextJsLogRefresh(true);
                setNextJsLogStatus(result.data);
                setNextJsLogStatusVisible(true);
            } else if (result.status == RESPONSE.NOTFOUND) {
                setNextJsLogStatusVisible(false);
            }
        });
    };

    function openPreview(type) {
        //get code for preview
        setLoading(true);
        requestGet(CONSTANTS_REQ.PROJECT.PREVIEWCODE, undefined, false, false).then((result) => {
            setLoading(false);
            if (result.status == RESPONSE.OK) {
                let host = window.location.hostname;
                //if (host.startsWith('admin.')) host = host.substr(6);
                let port = window.location.port;
                if (port != '') port = ':' + port;
                let href = window.location.href;
                let root = href.split('/#');
                var testRoot = null;
                if (root.length > 0) {
                    if (root[0].includes(port)) {
                        testRoot = root[0];
                        testRoot = testRoot.replace(window.location.protocol + '//', '');
                    } else {
                        root = root[0].replace(port, '');
                        root = root.replace(window.location.protocol + '//', '');
                    }
                } else {
                    root = host;
                }
                if (process.env.NODE_ENV === 'development') {
                    console.log('http://localhost:3001/preview/' + result.data + '/#/' + projectId);
                    var win = window.open(
                        'http://localhost:3001/preview/' +
                            result.data +
                            '/#/' +
                            projectId +
                            (type != null ? '?type=' + type : ''),
                        '_blank'
                    );
                    win.focus();
                } else if (testRoot != null) {
                    var win = window.open(
                        window.location.protocol +
                            '//' +
                            testRoot +
                            '/preview/' +
                            result.data +
                            '/#/' +
                            projectId +
                            (type != null ? '?type=' + type : ''),
                        '_blank'
                    );
                } else {
                    var win = window.open(
                        window.location.protocol +
                            '//' +
                            root +
                            port +
                            '/preview/' +
                            result.data +
                            '/#/' +
                            projectId +
                            (type != null ? '?type=' + type : ''),
                        '_blank'
                    );
                    win.focus();
                }
            }
        });
    }

    function publish() {
        let nyTouristVersion = packageJson.dependencies['@nybble/ny-tourist-components'].replace('^', '');
        requestPost(CONSTANTS_REQ.PROJECT.PUBLISH, undefined, {
            projectId: Session.getProjectId(),
            domainId: getFieldValue('domain').id
                ? parseInt(getFieldValue('domain').id)
                : parseInt(getFieldValue('domain').key),
            subdomain: getFieldValue('subdomain'),
            componentVersion: nyTouristVersion,
        }).then((result) => {
            if (result && result.status === RESPONSE.OK) {
                message.success(`${geti18nText('projectManagement.edit.publish.ok')} `);
                refreshStatus();
            } else {
                message.error(`${geti18nText('projectManagement.edit.publish.error')} `);
                refreshStatus();
            }
        });
    }
    return (
        <React.Fragment>
            <Tabs type="card" activeKey={selectedTab} onChange={(activeKey) => setSelectedTab(activeKey)}>
                <TabPane tab={geti18nText('projectManagement.edit.tab.project')} key="settings">
                    <Col span={24}>
                        <DataEdit
                            editHeader={
                                isCreate()
                                    ? geti18nText('projectManagement.new')
                                    : geti18nText('projectManagement.title')
                            }
                            layout="horizontal"
                            buttonsPosition="left"
                            loading={loading}
                            setLoading={setLoading}
                            setDefaultValues={setDefaultValues}
                            url={CONSTANTS_REQ.PROJECT.EDIT}
                            setValues={setValues}
                            onSaveAndGetData={onSaveAndGetData}
                            onSave={onSave}
                            hideCancelButton={true}
                            customButtons={getCustomButtons()}
                            {...props}
                            normalize={(values) => {
                                console.log('valu',values)
                                values.theme = projectTheme;
                                if(inputShowWeatherStateData===false) {
                                    values.weatherStateApiKey=undefined
                                } 
                                if (
                                    values.facebookVerificationDomainId &&
                                    values.facebookVerificationDomainId.slice(0, 6) != '<meta '
                                ) {
                                    values.facebookVerificationDomainId = undefined;
                                    notification['error']({
                                        message: geti18nText('projectManagement.facebook.domain.err'),
                                        description: geti18nText('projectManagement.facebook.domain.err.desc'),
                                        duration: 3,
                                    });
                                    return;
                                }

                                values.landingPage.id =
                                    values.projectLandingPage != null
                                        ? parseInt(values.projectLandingPage)
                                        : values.projectLandingPage;

                                return {
                                    ...values,
                                    theme: projectTheme,
                                    projects:
                                        values.projects != null
                                            ? values.projects.constructor === String
                                                ? [parseInt(values.projects)]
                                                : values.projects instanceof Array
                                                ? values.projects.map((pr) => {
                                                      return parseInt(pr);
                                                  })
                                                : [values.projects.id]
                                            : null,
                                    domain: isCreate()
                                        ? null
                                        : values.domain != null && values.domain.constructor === String
                                        ? { id: parseInt(values.domain) }
                                        : values.domain,
                                    subdomain: isCreate() ? null : values.subdomain,
                                    emailsToNewsletter:
                                        emailsToNewsletter != undefined ||
                                        (emailsToNewsletter instanceof Array && emailsToNewsletter.length > 0)
                                            ? emailsToNewsletter.toString()
                                            : null,
                                    emailsToInquiry:
                                        emailsToInquiry != undefined ||
                                        (emailsToInquiry instanceof Array && emailsToInquiry.length > 0)
                                            ? emailsToInquiry.toString()
                                            : null,
                                    emailsToReport:
                                        emailsToReport != undefined ||
                                        (emailsToReport instanceof Array &&
                                            emailsToReport.length > 0 &&
                                            problemReportShow === true)
                                            ? emailsToReport.toString()
                                            : null,
                                };
                            }}
                        >
                            <Row gutter={24}>
                                <Col span={8}>
                                    <Form.Item label={geti18nText('projectManagement.edit.name')}>
                                        {getFieldDecorator('name', {
                                            errorTitle: geti18nText('projectManagement.edit.name'),
                                            rules: [{ required: true, message: geti18nText('app.default.required') }],
                                        })(<Input />)}
                                    </Form.Item>
                                </Col>
                                <Col span={4} style={{ marginTop: '40px' }}>
                                    <Form.Item>
                                        {getFieldDecorator('theme', {
                                            valuePropName: 'checked',
                                            initialValue: false,
                                        })(
                                            <Checkbox onChange={onChangeSmartTourist}>
                                                {geti18nText('projectManagment.edit.smartTourist')}
                                            </Checkbox>
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col span={6} style={{ marginTop: '40px' }} hidden={hiddenKioskTime}>
                                    <Row>
                                        <div style={{ display: 'flex' }}>
                                            <Col>
                                                <Form.Item>
                                                    {getFieldDecorator('themeTime', {
                                                        valuePropName: 'checked',
                                                        initialValue: false,
                                                    })(
                                                        <Checkbox onChange={onChangeKioskTime}>
                                                            {geti18nText('projectManagment.edit.time')}
                                                        </Checkbox>
                                                    )}
                                                </Form.Item>
                                            </Col>
                                            <Col style={{ marginTop: '6px', marginLeft: '6px' }}>
                                                <Button
                                                    onClick={(arg) => {
                                                        setDesignVisible(true);
                                                        setDesignComponentName('kioskTime');
                                                        setComponentId(
                                                            Session.getAppValue('COMPONENTS').filter(
                                                                (v) => v.name === 'kioskTime'
                                                            )[0].id
                                                        );
                                                    }}
                                                    style={{ backgroundColor: 'orange' }}
                                                    icon="bg-colors"
                                                ></Button>
                                            </Col>
                                        </div>
                                    </Row>
                                    <DesignModal
                                        pageComponentId={componentId}
                                        visible={designVisible}
                                        setVisible={setDesignVisible}
                                        name={designComponentName}
                                    ></DesignModal>
                                </Col>
                                <Col span={6} style={{ marginTop: '40px' }} hidden={!showScreenSaver}>
                                    <Form.Item>
                                        {getFieldDecorator('screensaver', {
                                            valuePropName: 'checked',
                                            initialValue: true,
                                        })(
                                            <Checkbox onChange={onChangeKioskTime}>
                                                {geti18nText('projectManagment.edit.screensaver')}
                                            </Checkbox>
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={24}>
                                <Col span={16}>
                                    <Form.Item label={geti18nText('projectManagement.edit.multiselect.projects')}>
                                        {getFieldDecorator('projects')(
                                            <SearchField
                                                mode="multiple"
                                                url={CONSTANTS_REQ.PROJECT.SEARCH}
                                                addedSearch={{
                                                    field: 'id',
                                                    condition: 'not_equals',
                                                    value: Session.getProjectId(),
                                                }}
                                                map={{ id: 'id', label: 'name' }}
                                                searchBy="name"
                                                disabled={false}
                                                addedData={{ forPage: true }}
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={24}>
                                <Col span={8}>
                                    <Form.Item
                                        label={geti18nText('component.btcnewsletter.edit.emails') + ' - Newsletter'}
                                    >
                                        {getFieldDecorator('emailsToNewsletter')(
                                            <Select
                                                mode="tags"
                                                notFoundContent={null}
                                                style={{ width: '100%' }}
                                                onChange={changeEmailsNewsletter}
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        label={geti18nText('component.btcnewsletter.edit.emails') + ' - Inquiry'}
                                    >
                                        {getFieldDecorator('emailsToInquiry')(
                                            <Select
                                                mode="tags"
                                                notFoundContent={null}
                                                style={{ width: '100%' }}
                                                onChange={changeEmailsInquiry}
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Divider />

                            <Row gutter={10}>
                                <Col span={5} style={{ marginTop: '40px' }}>
                                    <Form.Item>
                                        {getFieldDecorator('forKiosk', {
                                            valuePropName: 'checked',
                                            initialValue: false,
                                        })(
                                            <Checkbox onChange={onChangeForKiosk}>
                                                {geti18nText('projectManagement.for.kiosk')}
                                            </Checkbox>
                                        )}
                                    </Form.Item>
                                </Col>

                                <Col span={5} style={{ marginTop: '40px' }} hidden={hiddenForKiosk}>
                                    <Form.Item>
                                        {getFieldDecorator('forecastShow', {
                                            valuePropName: 'checked',
                                            initialValue: false,
                                        })(
                                            <Checkbox onChange={onChangeForecastShow}>
                                                {geti18nText('projectManagment.edit.forecastShow')}
                                            </Checkbox>
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col hidden={inputShowForecastVisible || hiddenForKiosk} span={14}>
                                    <Row gutter={12}>
                                        <Col span={12}>
                                            <Form.Item label={geti18nText('projectManagment.edit.locationName')}>
                                                {getFieldDecorator('locationName')(<Input />)}
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row gutter={10}>
                                <Col span={5} style={{ marginTop: '40px' }} hidden={hiddenForKiosk}>
                                    <Form.Item>
                                        {getFieldDecorator('weatherStateApiKeyShow', {
                                            valuePropName: 'checked',
                                            initialValue: false,
                                        })(
                                            <Checkbox onChange={onChangeWeatherStateDataShow}>
                                                {geti18nText('projectManagment.edit.weatherstate.show')}
                                            </Checkbox>
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col span={1} style={{ marginTop: '40px'}}>
                                                <Button
                                                    onClick={(arg) => {
                                                        setDesignVisible(true);
                                                        setDesignComponentName('chameleonWeatherState');
                                                        setComponentId(
                                                            Session.getAppValue('COMPONENTS').filter(
                                                                (v) => v.name === 'chameleonWeatherState'
                                                            )[0].id
                                                        );
                                                    }}
                                                    style={{ backgroundColor: 'orange' }}
                                                    icon="bg-colors"
                                                ></Button>
                                            </Col>
                                
                                <Col hidden={!inputShowWeatherStateData || hiddenForKiosk} span={17}>
                                    <Row gutter={12}>
                                        <Col span={12}>
                                            <Form.Item label={geti18nText('projectManagment.edit.weatherstate.api.key')}>
                                                {getFieldDecorator('weatherStateApiKey')(<Input />)}
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                                
                                
                            </Row>
                            <Row>
                                <Col span={6} hidden={hiddenForKiosk}>
                                    <Form.Item
                                        label={geti18nText('projectManagment.edit.landing_page')}
                                        style={{ marginBottom: '0px' }}
                                    >
                                        {getFieldDecorator('projectLandingPage', {
                                            rules: [
                                                {
                                                    required: false,
                                                    message: geti18nText('app.default.required'),
                                                },
                                            ],
                                        })(
                                            <SearchField
                                                url={CONSTANTS_REQ.WEBPAGE.SEARCH}
                                                map={{ id: 'id', label: 'name' }}
                                                searchBy="name"
                                                disabled={false}
                                                translate={true}
                                                addedData={{ projectId: Session.getProjectId() }}
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                                <Form.Item hidden={true}>
                                    {getFieldDecorator('landingPage.id')(<Input hidden={true} />)}
                                </Form.Item>
                            </Row>
                            <Row hidden={hiddenForKiosk}>
                                <Divider></Divider>
                            </Row>

                            <Row gutter={10}>
                                <Col span={5} style={{ marginTop: '40px' }}>
                                    <Form.Item>
                                        {getFieldDecorator('forWeb', {
                                            valuePropName: 'checked',
                                            initialValue: false,
                                        })(
                                            <Checkbox onChange={onChangeForWeb}>
                                                {geti18nText('projectManagement.for.web')}
                                            </Checkbox>
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col span={5} style={{ marginTop: '40px' }} hidden={hiddenForWeb}>
                                    <Form.Item>
                                        {getFieldDecorator('accessibilityShow', {
                                            valuePropName: 'checked',
                                            initialValue: false,
                                        })(
                                            <Checkbox>
                                                {geti18nText('projectManagment.edit.accessibilityShow')}
                                            </Checkbox>
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col span={14}>
                                    <Row gutter={12}>
                                        <Col hidden={hiddenForWeb} span={12}>
                                            <Form.Item label={geti18nText('projectManagement.edit.gaCode')}>
                                                {getFieldDecorator('gaCode')(<Input />)}
                                            </Form.Item>
                                        </Col>
                                        <Col hidden={hiddenForWeb} span={12}>
                                            <Form.Item label={geti18nText('projectManagement.edit.fbCode')}>
                                                {getFieldDecorator('fbCode')(<Input />)}
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                            <Row gutter={24}>
                                <Col span={5} hidden={hiddenForWeb}>
                                    <Form.Item>
                                        {getFieldDecorator('mapShow', {
                                            valuePropName: 'checked',
                                            initialValue: false,
                                        })(
                                            <Checkbox onChange={onChangeMapShow}>
                                                {geti18nText('projectManagement.map.show')}
                                            </Checkbox>
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col span={16} hidden={inputShowMapVisible}>
                                    <Form.Item label={geti18nText('projeprojectManagement.image')}>
                                        {getFieldDecorator('imagePath')(
                                            <FileUpload
                                                fileType={CONSTANTS_FILE_TYPE.IMAGE}
                                                map={{ id: 'id', label: 'name' }}
                                                searchBy="name"
                                                disabled={false}
                                                addedData={{ projectId: Session.getProjectId() }}
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>

                            <div
                                style={{
                                    display:
                                        (!inputShowForecastVisible && !hiddenForKiosk) ||
                                        (!inputShowMapVisible && !hiddenForWeb)
                                            ? ''
                                            : 'none',
                                }}
                            >
                                <Row gutter={24}>
                                    <Col offset={5} span={19}>
                                        <InputMarkerCoordinates
                                            onMapCoordinatesChange={onMapCoordinatesChange}
                                            required={requiredLocation}
                                            {...props}
                                        />
                                    </Col>
                                </Row>
                            </div>

                            <Row gutter={24}>
                                <Col offset={5} span={14} hidden={inputShowMapVisible}>
                                    <Form.Item label={geti18nText('projectManagement.edit.region')}>
                                        {getFieldDecorator('region', {
                                            rules: [
                                                {
                                                    required: requiredLocation,
                                                    validator: locationValidator,
                                                },
                                            ],
                                        })(<TextArea />)}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Collapse bordered={false} style={{ marginBottom: '15px' }}>
                                <Panel header={geti18nText('menu.project.settings.extra')} key="1" forceRender={true}>
                                    <Row gutter={10}>
                                        <Col span={5} style={{ marginTop: '40px' }}>
                                            <Form.Item>
                                                {getFieldDecorator('problemReportShow', {
                                                    valuePropName: 'checked',
                                                    initialValue: false,
                                                })(
                                                    <Checkbox onChange={onChangeReportShow}>
                                                        {geti18nText('projectManagement.problem.report')}
                                                    </Checkbox>
                                                )}
                                            </Form.Item>
                                        </Col>

                                        <Col hidden={problemReportShow == false ? true : false} span={11}>
                                            <Form.Item
                                                label={geti18nText('projectManagement.problem.report.mail.recieve')}
                                            >
                                                {getFieldDecorator('emailsToReport')(
                                                    <Select
                                                        mode="tags"
                                                        notFoundContent={null}
                                                        style={{ width: '100%' }}
                                                        onChange={changeEmailsReport}
                                                    />
                                                )}
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={10}>
                                        <Col span={5} style={{ marginTop: '40px' }}>
                                            <Form.Item>
                                                {getFieldDecorator('facebookChatShow', {
                                                    valuePropName: 'checked',
                                                    initialValue: false,
                                                })(
                                                    <Checkbox onChange={onChangeFacebookChatShow}>
                                                        {geti18nText('projectManagement.facebook.chat')}
                                                    </Checkbox>
                                                )}
                                            </Form.Item>
                                        </Col>

                                        <Col hidden={!facebookChatShow} span={11}>
                                            <Form.Item label={geti18nText('projectManagement.facebook.chat.page.id')}>
                                                {getFieldDecorator('facebookPageId')(<Input />)}
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={10}>
                                        <Col span={5} style={{ marginTop: '40px' }}>
                                            <Form.Item>
                                                {getFieldDecorator('facebookVerificationShow', {
                                                    valuePropName: 'checked',
                                                    initialValue: false,
                                                })(
                                                    <Checkbox onChange={onChangeFacebookVerificationShow}>
                                                        {geti18nText('projectManagement.facebook.domain.verification')}
                                                    </Checkbox>
                                                )}
                                            </Form.Item>
                                        </Col>

                                        <Col hidden={!facebookVerificationShow} span={11}>
                                            <Form.Item label={geti18nText('projectManagement.facebook.domain.text')}>
                                                {getFieldDecorator('facebookVerificationDomainId')(<Input />)}
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Panel>
                            </Collapse>
                            <Form.Item hidden={true}>{getFieldDecorator('id')(<Input />)}</Form.Item>
                            <Form.Item hidden={true}>{getFieldDecorator('jsonCss')(<Input />)}</Form.Item>
                        </DataEdit>
                    </Col>
                </TabPane>
                {!hiddenForWeb && (
                    <TabPane
                        tab={geti18nText('projectManagement.edit.tab.publish')}
                        key="publish"
                        disabled={!showComponents}
                    >
                        {selectedTab && (
                            <Col span={24}>
                                <Row hidden={hiddenForWeb} gutter={24}>
                                    <Col span={12}>
                                        <Form.Item label={geti18nText('projectManagement.edit.domain')}>
                                            {getFieldDecorator('domain', {
                                                errorTitle: geti18nText('projectManagement.edit.domain'),
                                                rules: [
                                                    {
                                                        required: isDomainRequired(),
                                                        message: geti18nText('app.default.required'),
                                                        validator: validateDomain,
                                                    },
                                                ],
                                            })(
                                                <SearchField
                                                    url={CONSTANTS_REQ.DOMAIN.SEARCH}
                                                    map={{ id: 'id', label: 'name' }}
                                                    searchBy="name"
                                                    disabled={false}
                                                    addedData={{ forPage: true }}
                                                    onChange={domainOnChange}
                                                />
                                            )}
                                        </Form.Item>
                                    </Col>
                                    <Col span={9}>
                                        <Form.Item label={geti18nText('projectManagement.edit.subdomain')}>
                                            {getFieldDecorator('subdomain', {
                                                validateTrigger: 'onBlur',
                                                errorTitle: geti18nText('projectManagement.edit.subdomain'),
                                                rules: [
                                                    {
                                                        required: isCreate() ? false : !hiddenForWeb ? true : false,
                                                        validator: validateSubdomain,
                                                    },
                                                ],
                                            })(<Input />)}
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col hidden={!nextJsLogStatusVisible} style={{ padding: '40px' }} span={12}>
                                        <Tag
                                            onClick={(e) => onNextJsLogVisible()}
                                            style={{ padding: '15px', cursor: 'pointer' }}
                                            color={setTagColor()}
                                        >
                                            {nextJsLogStatusVisible ? nextJsLogStatus.created : null} {'-'}
                                            {nextJsLogStatusVisible
                                                ? geti18nText('nextjs.log.status.' + nextJsLogStatus.status)
                                                : ''}
                                        </Tag>
                                        {nextJsLogStatusVisible && nextJsLogStatus.status === 2 ? (
                                            <Icon
                                                onClick={(e) => refreshStatus()}
                                                style={{ fontSize: '24px' }}
                                                type="reload"
                                            />
                                        ) : null}
                                    </Col>
                                </Row>
                                <Row>
                                    {!isCreate() && domainSubDomainOnChangeSaved && (
                                        <Popconfirm
                                            placement="top"
                                            title={geti18nText('projectManagement.edit.publish')}
                                            onConfirm={(e) => {
                                                publish();
                                            }}
                                            okText={geti18nText('app.default.button.yes')}
                                            cancelText={geti18nText('app.default.button.no')}
                                        >
                                            <Button loading={loading} style={{ marginRight: '1em' }}>
                                                {<Icon type="cloud-upload" />}
                                                {geti18nText('projectManagement.edit.publish')}
                                            </Button>
                                        </Popconfirm>
                                    )}
                                    {(isCreate() || !domainSubDomainOnChangeSaved) && (
                                        <Button type="primary" onClick={(e) => onSaveDomain(e)}>
                                            {geti18nText('app.default.button.save')}
                                        </Button>
                                    )}
                                </Row>
                                <Collapse bordered={false} style={{ marginBottom: '15px' }}>
                                    <Panel header={geti18nText('nextjs.log.table.header')} key="1" forceRender={true}>
                                        <Row>
                                            {nextJsLogRefresh != null && (
                                                <DataTable
                                                    headerTitle={geti18nText('nextjs.log.table.header')}
                                                    url={CONSTANTS_REQ.NEXTJSLOG.LIST}
                                                    hideNewButton={true}
                                                    showRecordModal={false}
                                                    readonly={true}
                                                    addedData={{ projectId: projectId }}
                                                    columns={[
                                                        {
                                                            title: geti18nText('nextjs.log.table.column.id'),
                                                            dataIndex: 'id',
                                                            width: '20%',
                                                            sorter: (a, b) => {},
                                                            ...getColumnSearch('number'),
                                                        },
                                                        {
                                                            title: geti18nText('nextjs.log.table.column.status'),
                                                            dataIndex: 'status',
                                                            sorter: (a, b) => {},
                                                            ...getColumnSearch('string'),
                                                            render: (text, record) => {
                                                                if (record.status != null) {
                                                                    return {
                                                                        props: {
                                                                            style: {
                                                                                color: setTagColor(record.status),
                                                                            },
                                                                        },
                                                                        children: (
                                                                            <div>
                                                                                {geti18nText(
                                                                                    'nextjs.log.status.' + record.status
                                                                                )}
                                                                            </div>
                                                                        ),
                                                                    };
                                                                    // return geti18nText('nextjs.log.status.' + record.status);
                                                                }
                                                            },
                                                        },
                                                        {
                                                            title: geti18nText('nextjs.log.table.column.created'),
                                                            dataIndex: 'created',
                                                            sorter: (a, b) => {},
                                                            ...getColumnSearch('string'),
                                                            render: (text, record) => {
                                                                if (record.created) {
                                                                    return moment(record.created).format(
                                                                        'DD.MM.YYYY HH:mm:ss'
                                                                    );
                                                                }
                                                            },
                                                        },
                                                        {
                                                            title: geti18nText('nextjs.log.table.column.log'),
                                                            dataIndex: 'log',
                                                            sorter: (a, b) => {},
                                                            ...getColumnSearch('string'),
                                                            render: (text, record) => {
                                                                return (
                                                                    <>
                                                                        {text && text.length > 100 && (
                                                                            <Popover
                                                                                placement="left"
                                                                                overlayStyle={{
                                                                                    whiteSpace: 'pre-line',
                                                                                }}
                                                                                content={text}
                                                                            >
                                                                                <Text mark>
                                                                                    {text.substring(0, 50)}...
                                                                                </Text>
                                                                            </Popover>
                                                                        )}
                                                                        {(text == null || text.length <= 50) && (
                                                                            <Text mark>{text}</Text>
                                                                        )}
                                                                    </>
                                                                );
                                                            },
                                                        },
                                                    ]}
                                                />
                                            )}
                                        </Row>
                                    </Panel>
                                </Collapse>
                            </Col>
                        )}
                    </TabPane>
                )}
            </Tabs>
            <Modal
                visible={inputNamePopupVisible}
                onCancel={onNamePopupCancel}
                onSave={onNamePopupSave}
                width={500}
                title={geti18nText('project.table.copy')}
                footer={[
                    <Button onClick={(e) => onNamePopupCancel()}>{geti18nText('app.default.button.cancel')}</Button>,
                    <Button type="primary" onClick={(arg) => onNamePopupSave()}>
                        {geti18nText('app.default.button.save')}
                    </Button>,
                ]}
            >
                <Row gutter={24}>
                    <Col span={24} style={{ width: '100%' }}>
                        <label style={{ width: '100%' }}>{geti18nText('projects.edit.projectName')}</label>
                        <div></div>
                        <input
                            ref={projectCopyName}
                            style={{ width: '100%' }}
                            id="projectCopyName"
                            name="projectCopyName"
                            type="text"
                            required
                        />
                    </Col>
                </Row>
            </Modal>
            <Modal
                visible={nextJsLogVisible}
                onCancel={onNextJsLogCancel}
                width={800}
                title={geti18nText('nextjs.log.history')}
                footer={null}
                destroyOnClose={true}
            >
                <DataTable
                    headerTitle={geti18nText('nextjs.log.table.header')}
                    url={CONSTANTS_REQ.NEXTJSLOG.LIST}
                    hideNewButton={true}
                    showRecordModal={false}
                    readonly={true}
                    addedData={{ projectId: projectId }}
                    columns={[
                        {
                            title: geti18nText('nextjs.log.table.column.id'),
                            dataIndex: 'id',
                            width: '20%',
                            sorter: (a, b) => {},
                            ...getColumnSearch('number'),
                        },
                        {
                            title: geti18nText('nextjs.log.table.column.status'),
                            dataIndex: 'status',
                            sorter: (a, b) => {},
                            ...getColumnSearch('string'),
                            render: (text, record) => {
                                if (record.status != null) {
                                    return geti18nText('nextjs.log.status.' + record.status);
                                }
                            },
                        },
                        {
                            title: geti18nText('nextjs.log.table.column.created'),
                            dataIndex: 'created',
                            sorter: (a, b) => {},
                            ...getColumnSearch('string'),
                            render: (text, record) => {
                                if (record.created) {
                                    return moment(record.created).format('DD.MM.YYYY HH:mm:ss');
                                }
                            },
                        },
                        {
                            title: geti18nText('nextjs.log.table.column.log'),
                            dataIndex: 'log',
                            sorter: (a, b) => {},
                            ...getColumnSearch('string'),
                            render: (text, record) => {
                                return (
                                    <>
                                        {text && text.length > 100 && (
                                            <Popover content={text} overlayStyle={{ whiteSpace: 'pre-line' }}>
                                                <Text mark>{text.substring(0, 50)}...</Text>
                                            </Popover>
                                        )}
                                        {(text == null || text.length <= 50) && <Text mark>{text}</Text>}
                                    </>
                                );
                            },
                        },
                    ]}
                />
            </Modal>
        </React.Fragment>
    );
});

export default ProjectManagementIndex;
