import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    folderPath: '/',
    refreshFolder: 0,
    file: '/',
};

const filesSlices = createSlice({
    name: 'filesSlices',
    initialState,
    reducers: {
        selectFolder(state, action) {
            const { folderPath } = action.payload;
            state.folderPath = folderPath;
        },

        setRefreshFolder(state) {
            state.refreshFolder = Date.now();
        },

        selectFile(state, action) {
            const { file } = action.payload;
            state.file = file;
        },
    },
});

export const { selectFolder, selectFile, setRefreshFolder } = filesSlices.actions;

export default filesSlices.reducer;
