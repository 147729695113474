import { Dropdown, Icon, Input, Layout, Menu, message, Modal, Row, Tree, Popover } from 'antd';
import { cloneDeep } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
import logoSmTourBlack from '../../assets/images/sm_logo_black.png';
import logoSmTourColor from '../../assets/images/sm_logo_color.png';
import { refreshMenu } from '../../slices/menuSlice';
import { selectProject } from '../../slices/projectSlices';
import { refreshPagePreview, selectFirstPage, selectPageType, selectWebPageComponentToScroll } from '../../slices/webPageSlice';
import { selectFolder, selectFile, selectFileType, setRefreshFolder } from '../../slices/filesSlices';
import { CONSTANTS_FILE_TYPE, CONSTANTS_REQ, IMAGE_FORMATS, VIDEO_FORMATS } from '../../utils/Constants';
import { requestGet, requestPost, requestPut, RESPONSE } from '../../utils/RequestResolver';
import { geti18nText, Session, hasAnyRole } from '../../utils/Session';
import { downloadFile, setProjectLanguages } from '../../utils/Utils';
import { AddFolder } from '../AddFolder';
import { FilePreview } from '../filePreview/FilePreview';
import { FileUpload } from '../fileUpload/FileUpload';
import { getTranslated } from '../InputByLanguage';
import NyBadge from '../../components/badge/index';
const { Search } = Input;

function updateTreeData(list, key, children) {
    return list.map((node) => {
        if (node.key === key) {
            if (children != undefined) {
                return {
                    ...node,
                    children,
                };
            } else {
                delete node.children;
                return {
                    ...node,
                };
            }
        } else if (node.children) {
            return {
                ...node,
                children: updateTreeData(node.children, key, children),
            };
        }
        return node;
    });
}

const getSiblings = (list, key, children) => {
    let childrenList = [];
    list.forEach((node) => {
        if (node.key === key) {
            if (children != undefined) {
                childrenList = children;
            }
        } else if (node.children && childrenList.length == 0) {
            childrenList = getSiblings(node.children, key, node.children);
        }
    });
    return childrenList;
};

const findParent = (list, key, parent) => {
    let myParent = '';
    list.forEach((node) => {
        if (node.key === key) {
            if (parent != undefined) {
                myParent = parent;
            }
        } else if (node.children && myParent.length == 0) {
            myParent = findParent(node.children, key, node.key);
        }
    });
    return myParent;
};

export const AdminMainMenu = withRouter((props) => {
    let history = useHistory();
    const { Sider } = Layout;
    const { Search } = Input;
    const [loading, setLoading] = useState(false);
    const [treeData, setTreeData] = useState([]);
    const [expandedKeys, setExpandedKeys] = useState([]);
    const [loadedKeys, setLoadedKeys] = useState([]);
    const [autoExpandParent, setAutoExpandParent] = useState(true);
    const [nodeForRefresh, setNodeForRefresh] = useState(undefined);
    const [lastSelectedNode, setLastSelectedNode] = useState('');
    const { refreshLastNode, refreshProjectNode, refreshNodes } = useSelector((state) => state.menuSlices);
    const dispatch = useDispatch();
    const { TreeNode } = Tree;
    const [logo, setLogo] = useState(undefined);
    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewFile, setPreviewFile] = useState(undefined);
    const [newFolderVisible, setNewFolderVisible] = useState(false);
    const [folderValue, setFolderValue] = useState(null);
    const [visibleUploadModal, setVisibleUploadModal] = useState(null);
    const [disabledTree, setDisabledTree] = useState(null);
    const [selectedNodeForFolderRefresh, setSelectedNodeForFolderRefresh] = useState(undefined);
    const [collapsed, setCollapsed] = useState(false);
    const [searchProjectName, setSearchProjectName] = useState(null);
    const [filterWebProject, setFilterWebProject] = useState(null);
    const [filterKioskProject, setFilteKioskroject] = useState(null);
    const { confirm } = Modal;

    useEffect(() => {
        setTree();
        if (Session.getProjectId()) {
            dispatch(
                selectProject({
                    projectId: Session.getProjectId(),
                    projectName: Session.getProjectInfo().name,
                    themeShortName:
                        Session.getProjectInfo().theme && Session.getProjectInfo().theme.shortName
                            ? Session.getProjectInfo().theme.shortName
                            : '',
                })
            );
        }
        setLogo(applicationLogo(logo));
    }, []);

    useEffect(() => {
        setTree();
        dispatch(
            refreshMenu({
                refreshLastNode: false,
                refreshProjectNode: false,
                refreshNodes: [],
            })
        );
    }, [searchProjectName, filterWebProject, filterKioskProject]);

    useEffect(() => {
        if (refreshLastNode && nodeForRefresh != undefined) {
            refreshNode(nodeForRefresh);
            dispatch(
                refreshMenu({
                    refreshLastNode: false,
                    refreshProjectNode: false,
                    refreshNodes: [],
                })
            );
        }
    }, [refreshLastNode]);

    useEffect(() => {
        if (refreshProjectNode) {
            setTree();
            dispatch(
                refreshMenu({
                    refreshLastNode: false,
                    refreshProjectNode: false,
                    refreshNodes: [],
                })
            );
        }
    }, [refreshProjectNode]);

    useEffect(() => {
        if (refreshNodes.length > 0) {
            for (const node of refreshNodes) {
                refreshNode(node);
            }
            dispatch(
                refreshMenu({
                    refreshLastNode: false,
                    refreshProjectNode: false,
                    refreshNodes: [],
                })
            );
        }
    }, [refreshNodes]);

    function TreeParams() {
        var params = {};
        if (searchProjectName != null) {
            params.searchText = searchProjectName;
        }
        if (filterKioskProject != null && filterKioskProject != false) {
            params.kiosk = filterKioskProject;
        }
        if (filterWebProject != null && filterWebProject != false) {
            params.web = filterWebProject;
        }

        return params;
    }

    function setTree() {
        setLoading(true);
        setLoadedKeys([]);

        requestGet(CONSTANTS_REQ.PROJECT.TREE, TreeParams()).then((result) => {
            setLoading(false);
            if (result.status === RESPONSE.OK) {
                if (Array.isArray(result.data)) {
                    let projects = [];
                    for (const element of result.data) {
                        if (element.active == false) {
                            continue;
                        }
                        projects.push({
                            title: generateNodeTitle(element.name, 'PROJECT', 'PROJECT-' + element.id),
                            key: 'PROJECT-' + element.id,
                            theme: element.theme,
                            reportProblem: element.problemReportShow,
                        });
                    }
                    if (hasAnyRole(['ROLE_ADMIN'])) {
                        projects.push({
                            title: generateNodeTitle(geti18nText('menu.add.project'), 'NEW', 'NEWPROJECT'),
                            key: 'NEWPROJECT',
                            isLeaf: true,
                        });
                    }
                    let treeData = [
                        {
                            title: generateNodeTitle(geti18nText('menu.projects'), 'PROJECTS', 'INDEX'),
                            key: 'INDEX',
                            children: projects,
                        },
                    ];

                    if (hasAnyRole(['ROLE_ADMIN'])) {
                        treeData.push(appSettingsMenu);
                        treeData.push({
                            title: generateNodeTitle(geti18nText('menu.accounts'), 'ACCOUNTS', 'ACCOUNTS'),
                            key: 'ACCOUNTS',
                            isLeaf: true,
                        });
                        treeData.push({
                            title: generateNodeTitle(geti18nText('menu.domains'), 'DOMAINS', 'DOMAINS'),
                            key: 'DOMAINS',
                            isLeaf: true,
                        });
                    }
                    if (hasAnyRole(['ROLE_ADMIN', 'ROLE_MOBILE_APP_DOWNLOAD'])) {
                        treeData.push({
                            title: generateNodeTitle(geti18nText('menu.mobileApp'), 'MOBILEAPP', 'MOBILEAPP'),
                            key: 'MOBILEAPP',
                            isLeaf: true,
                        });
                    } //6120task
                    if (hasAnyRole(['ROLE_ADMIN'])) {
                        treeData.push({
                            title: generateNodeTitle(
                                geti18nText('design.settings.component.menu.name'),
                                'COMPEDIT',
                                'COMPEDIT'
                            ),
                            key: 'COMPEDIT',
                            isLeaf: true,
                        });
                    }
                    if (Session.getSetting('ux.docs-url') && Session.getSetting('ux.docs-url').length > 0) {
                        treeData.push({
                            title: generateNodeTitle(geti18nText('menu.documentation'), 'DOCS', 'DOCS'),
                            key: 'DOCS',
                            isLeaf: true,
                        });
                    }
                    if (
                        hasAnyRole(['ROLE_ADMIN']) &&
                        Session.getSetting('ux.change-log-url') &&
                        Session.getSetting('ux.change-log-url').length > 0
                    ) {
                        treeData.push({
                            title: generateNodeTitle(geti18nText('menu.change.log'), 'CHANGELOG', 'CHANGELOG'),
                            key: 'CHANGELOG',
                            isLeaf: true,
                        });
                    }

                    setTreeData(treeData);
                    if (!refreshProjectNode) {
                        setExpandedKeys(['INDEX']);
                    } else {
                        var keys = expandedKeys;
                        keys.forEach((el) => {
                            if (el.startsWith('PROJECT-')) {
                                setNotificationBadgeDirectory(null);
                                getDirectoryNotification(getId(el));
                            }
                        });
                    }
                }
            }
        });
    }

    function getUrlAndParamsForChildren(key) {
        if (key.startsWith('PCONTENT-')) {
            return { url: CONSTANTS_REQ.PAGETYPE.TREE, params: { projectId: getId(key) } };
        } else if (key.startsWith('TYPE-')) {
            return { url: CONSTANTS_REQ.WEBPAGE.TREE, params: { pageTypeId: getId(key) } };
        } else if (key.startsWith('INDEX')) {
            return { url: CONSTANTS_REQ.PROJECT.TREE, params: {} };
        } else if (key.startsWith('WEBPAGE-')) {
            return { url: CONSTANTS_REQ.WEBPAGECOMPONENT.TREE, params: { webPageId: getId(key) } };
        } else if (key.startsWith('FIRSTPAGE-')) {
            return { url: CONSTANTS_REQ.WEBPAGECOMPONENT.TREE, params: { webPageId: getId(key) } };
        } else if (key.startsWith('NOTINMENUP-')) {
            return { url: CONSTANTS_REQ.WEBPAGE.TREE, params: { pageTypeId: 0, projectId: getId(key) } };
        } else if (key.startsWith('ROOT_DIRECTORY-')) {
            return { url: CONSTANTS_REQ.DIRECTORY.TREE + '/' + getId(key), params: {} };
        } else if (key.startsWith('FOLDER-')) {
            const folder = key.split['-'];
            return {
                url: CONSTANTS_REQ.DIRECTORY.TREE + '/' + Session.getProjectId(),
                params: { directory: key.split('FOLDER-')[1].replace('' + Session.getProjectId(), '') },
            };
        } else if (key.startsWith('SEARCHTYPESMAIN-')) {
            return { url: CONSTANTS_REQ.SEARCH_TYPE.TREE, params: { parentId: getId(key), parentType: 'MAIN' } };
        } else if (key.startsWith('SEARCHTYPESGROUP-')) {
            return { url: CONSTANTS_REQ.SEARCH_TYPE.TREE, params: { parentId: getId(key), parentType: 'GROUP' } };
        } else if (key.startsWith('SEARCHTYPESTOP')) {
            return { url: CONSTANTS_REQ.SEARCH_TYPE.TREE, params: { parentId: 0, parentType: 'TOP' } };
        } else if (key.startsWith('LOCATIONS-')) {
            return { url: CONSTANTS_REQ.LOCATION.TREE, params: { projectId: getId(key) } };
        } else if (key.startsWith('FACILITYGROUPS')) {
            return { url: CONSTANTS_REQ.FACILITY.TREE, params: { parentId: 0, parentType: 'TOP' } };
        } else if (key.startsWith('FACILITYGROUP-')) {
            return { url: CONSTANTS_REQ.FACILITY.TREE, params: { parentId: getId(key), parentType: 'GROUP' } };
        }

        return { url: CONSTANTS_REQ.PROJECT.TREE, params: {} };
    }

    function setSelectedProject(projectId) {
        if (projectId) {
            requestGet(CONSTANTS_REQ.PROJECT.EDIT + '/' + projectId).then((result) => {
                if (result.status === RESPONSE.OK) {
                    if (typeof result.data === 'object') {
                        Session.setProject(result.data);
                        setExpandedKeys(['INDEX', 'PROJECT-' + projectId]);
                        dispatch(
                            selectProject({
                                projectId: result.data.id,
                                projectName: result.data.name,
                                themeShortName:
                                    result.data.theme && result.data.theme.shortName ? result.data.theme.shortName : '',
                            })
                        );
                        setProjectLanguages(result.data.id);
                    }
                }
            });
        }
    }

    const onExpand = (expandedKeys, info) => {
        // if not set autoExpandParent to false, if children expanded, parent can not collapse.
        // or, you can remove all expanded children keys.
        setExpandedKeys(expandedKeys);
        if (info.expanded) {
            onExpandSetSession(info.node.props.eventKey + '');
        }

        setAutoExpandParent(false);
    };

    const [notificationBadgeDirectory, setNotificationBadgeDirectory] = useState(null);
    function onExpandSetSession(key) {
        if (key.startsWith('PROJECT-')) {
            setSelectedProject(getId(key));
            history.push('/');
            setNotificationBadgeDirectory(null);
            getDirectoryNotification(getId(key));
        } else if (key.startsWith('APPSETTINGS')) {
            Session.setAppLanguages();
            dispatch(
                selectProject({
                    projectId: 0,
                    projectName: '',
                    themeShortName: '',
                })
            );
            setExpandedKeys(['APPSETTINGS']);
        } else {
            setSelectedNodeForFolderRefresh(key);
        }
    }

    async function getDirectoryNotification(projectId) {
        if (projectId) {
            /*var x = document.querySelectorAll('.badge');
            x.forEach((el) => {
                el.remove();
            });*/
            await requestGet(CONSTANTS_REQ.DIRECTORY.ROOT_NOTIFICATION, {
                projectId: projectId,
            }).then((result) => {
                if (result.status === RESPONSE.OK) {
                    setNotificationBadgeDirectory(result.data);
                    var x = document.querySelectorAll('.notification-badge');
                    x.forEach((el) => {
                        el.innerHTML = result.data;
                        el.style.visibility = result.data > 0 ? 'visible' : 'hidden';
                    });
                }
            });
        }
    }

    async function onLoadData(treeNode) {
        let key = treeNode.props.eventKey;
        let theme = 'theme' in treeNode.props ? treeNode.props.theme : null;

        setLoadedKeys((origin) => {
            let arr = [...origin];
            arr.push(key);
            return arr;
        });

        return new Promise(async (resolve) => {
            if (key == 'ADMINISTRATION' || key == 'APPSETTINGS') {
                resolve();
                return;
            } else if ((key + '').startsWith('PROJECT-')) {
                let nodes = [];

                nodes.push({
                    title: generateNodeTitle(geti18nText('menu.content'), '', 'PCONTENT-' + getId(key)),
                    key: 'PCONTENT-' + getId(key),
                });
                if (treeNode.props.reportProblem && treeNode.props.reportProblem === true) {
                    nodes.push({
                        title: geti18nText('menu.report.problem'),
                        key: 'REPORT_PROBLEM-' + getId(key),
                        isLeaf: true,
                    });
                }

                nodes.push({
                    title: generateNodeTitle(
                        geti18nText('menu.files'),
                        'ROOT_DIRECTORY',
                        'ROOT_DIRECTORY-' + getId(key),
                        true
                    ),
                    key: 'ROOT_DIRECTORY-' + getId(key),
                });
                nodes.push({ title: geti18nText('menu.data'), key: 'DATA-' + getId(key) });
                nodes.push({ title: geti18nText('menu.project.settings'), key: 'SETTINGS-' + getId(key) });
                setTreeData((origin) => updateTreeData(origin, key, nodes));
                resolve();
                return;
            } else if ((key + '').startsWith('SETTINGS-')) {
                let nodes = [];
                nodes.push({ title: geti18nText('menu.settings'), key: 'PROJECTSETTINGS-' + getId(key), isLeaf: true });
                nodes.push({
                    title: geti18nText('menu.designSettings'),
                    key: 'PROJECTDESIGNSETTINGS-' + getId(key),
                    isLeaf: true,
                });
                nodes.push({ title: geti18nText('menu.mapPins'), key: 'MAPPINS-' + getId(key), isLeaf: true });
                nodes.push({ title: geti18nText('menu.webPageTypes'), key: 'PAGETYPES-' + getId(key), isLeaf: true });
                nodes.push({ title: geti18nText('menu.lang'), key: 'LANGUAGES-' + getId(key), isLeaf: true });
                setTreeData((origin) => updateTreeData(origin, key, nodes));
                resolve();
                return;
            }
            else if ((key + '').startsWith('DATA-')) {
                let nodes = [];
                nodes.push({ title: geti18nText('menu.blog'), key: 'BLOG-' + getId(key), isLeaf: true });
                nodes.push({ title: geti18nText('menu.faq.category'), key: 'FAQCATEGORY-' + getId(key), isLeaf: true });
                nodes.push({ title: geti18nText('menu.faq.question.answer'), key: 'FAQQUESTIONANSWER-' + getId(key), isLeaf: true });
                nodes.push({ title: geti18nText('menu.shop.category'), key: 'SHOPCATEGORY-' + getId(key), isLeaf: true });
                nodes.push({ title: geti18nText('menu.shop'), key: 'SHOP-' + getId(key), isLeaf: true });
                nodes.push({ title: geti18nText('menu.package'), key: 'PACKAGE-' + getId(key), isLeaf: true });

                
                setTreeData((origin) => updateTreeData(origin, key, nodes));
                resolve();
                return;
            }
            const url = getUrlAndParamsForChildren(key + '');
            requestGet(url.url, url.params).then((result) => {
                if (result.status === RESPONSE.OK) {
                    if (Array.isArray(result.data)) {
                        generateBranch(key, result.data, false, theme);
                    }
                }
                resolve();
            });
        });
    }

    function generateBranch(key, data, showInactive = false, theme = null) {
        let nodes = [];
        let nodeFirstPage = [];
        
        for (const [index, element] of data.entries()) {
            if (!showInactive && element.active == false) {
                continue;
            }
            switch (element.type) {
                case 'PROJECT':
                    nodes.push({
                        title: generateNodeTitle(element.name, 'PROJECT', 'PROJECT-' + element.id, element.active),
                        key: 'PROJECT-' + element.id,
                        reportProblem: element.problemReportShow,
                    });
                    break;
                case 'TYPE':
                    nodes.push({
                        title: generateNodeTitle(
                            getTranslated(element.name),
                            'MENU',
                            'TYPE-' + element.id,
                            element.active
                        ),
                        key: 'TYPE-' + element.id,
                        theme: element.theme,
                    });
                    break;
                case 'LINK':
                    nodes.push({
                        title: generateNodeTitle(
                            getTranslated(element.name),
                            'WEBPAGEEXLINK',
                            'WEBPAGEEXLINK-' + element.id,
                            element.active
                        ),
                        key: 'WEBPAGEEXLINK-' + element.id,
                        isLeaf: true,
                    });
                    break;
                case 'LINK-PROJECT':
                    nodes.push({
                        title: generateNodeTitle(
                            getTranslated(element.name),
                            'WEBPAGEEXLINK',
                            'WEBPAGEEXLINK-' + element.id,
                            element.active
                        ),
                        key: 'WEBPAGEEXLINK-' + element.id,
                        isLeaf: true,
                    });
                    break;
                case 'WEBPAGE':
                    if (element.projectId == 0 && element.id < 0) {
                        const parent = key.split('-');
                        const parentId = parent[1] != null ? parseInt(parent[1]) : 0;
                        nodes.push({
                            title: generateNodeTitle(
                                getTranslated(element.name),
                                'WEBPAGEBREAK',
                                'WEBPAGEBREAK-' + element.id,
                                element.active
                            ),
                            key: 'WEBPAGEBREAK-' + element.id + '+' + parentId,
                            isLeaf: true,
                        });
                        break;
                    }

                    if (element.projectId == Session.getProjectId()) {
                        nodes.push({
                            title: generateNodeTitle(
                                getTranslated(element.name),
                                'WEBPAGE',
                                'WEBPAGE-' + element.id,
                                element.active
                            ),
                            key: 'WEBPAGE-' + element.id,
                        });
                        break;
                    } else {
                        nodes.push({
                            title: generateNodeTitle(
                                getTranslated(element.name),
                                'WEBPAGEEX',
                                'WEBPAGEEX-' + element.id,
                                element.active
                            ),
                            key: 'WEBPAGEEX-' + element.id,
                            isLeaf: true,
                        });
                        break;
                    }

                case 'FIRSTPAGE':
                    nodeFirstPage.push({
                        title: generateNodeTitle(getTranslated(element.name), 'FIRSTPAGE', 'FIRSTPAGE-' + element.id),
                        key: 'FIRSTPAGE-' + element.id,
                    });
                    break;
                case 'FIRSTPAGEMENU':
                    nodeFirstPage.push({
                        title: generateNodeTitle(
                            getTranslated(element.name),
                            'FIRSTPAGE',
                            'FIRSTPAGEMENU-' + element.id
                        ),
                        key: 'FIRSTPAGEMENU-' + element.id,
                    });
                    break;
                case 'COMPONENT':
                    nodes.push({
                        title: geti18nText('component.' + element.name) + ' ('+(index+1)  + ')',
                        key: 'COMPONENT-' + element.id,
                        isLeaf: true,
                        index: (index+1)
                    });
                    break;
                case 'DIRECTORY':
                    nodes.push({
                        title: generateNodeTitle(
                            element.name,
                            'FOLDER',
                            'FOLDER-' + element.relativePath + '',
                            true,
                            element.notification
                        ),
                        key: 'FOLDER-' + element.relativePath + '' + Session.getProjectId(),
                    });
                    break;

                case 'FILE':
                    nodes.push({
                        title: generateNodeTitle(element.name, 'FILE', 'FILE-' + element.relativePath),
                        key: 'FILE-' + element.relativePath,
                        isLeaf: true,
                        size: element.size,
                        dateCreated: element.dateCreated,
                    });
                    break;
                case 'SEARCHTYPES-MAIN':
                    nodes.push({
                        title: generateNodeTitle(
                            getTranslated(element.name),
                            'SEARCHTYPESMAIN',
                            'SEARCHTYPESMAIN-' + element.id,
                            element.active
                        ),
                        key: 'SEARCHTYPESMAIN-' + element.id,
                    });
                    break;
                case 'SEARCHTYPES-GROUP':
                    nodes.push({
                        title: generateNodeTitle(
                            getTranslated(element.name),
                            'SEARCHTYPESGROUP',
                            'SEARCHTYPESGROUP-' + element.id,
                            element.active
                        ),
                        key: 'SEARCHTYPESGROUP-' + element.id,
                    });
                    break;
                case 'SEARCHTYPES-VALUE':
                    nodes.push({
                        title: generateNodeTitle(
                            getTranslated(element.name),
                            'SEARCHTYPESVALUE',
                            'SEARCHTYPESVALUE-' + element.id,
                            element.active
                        ),
                        key: 'SEARCHTYPESVALUE-' + element.id,
                        isLeaf: true,
                    });
                    break;
                case 'LOCATIONS':
                    nodes.push({
                        title: generateNodeTitle(element.name, 'LOCATION', 'LOCATION-' + element.id, element.active),
                        key: 'LOCATION-' + element.id,
                        isLeaf: true,
                    });
                    break;
                case 'FACILITYGROUPS':
                    nodes.push({
                        title: generateNodeTitle(
                            getTranslated(element.name),
                            'FACILITYGROUP',
                            'FACILITYGROUP-' + element.id,
                            element.active
                        ),
                        key: 'FACILITYGROUP-' + element.id,
                    });
                    break;
                case 'FACILITYGROUP':
                    nodes.push({
                        title: generateNodeTitle(
                            getTranslated(element.name),
                            'FACILITYGROUP',
                            'FACILITYGROUP-' + element.id,
                            element.active
                        ),
                        key: 'FACILITYGROUP-' + element.id,
                    });
                    break;
                case 'FACILITY':
                    nodes.push({
                        title: generateNodeTitle(
                            getTranslated(element.name),
                            'FACILITY',
                            'FACILITY-' + element.id,
                            element.active
                        ),
                        key: 'FACILITY-' + element.id,
                        isLeaf: true,
                    });
                    break;
                default:
                    nodes.push({ title: element.name, key: element.type + element.id });
            }
        }
        if ((key + '').startsWith('INDEX')) {
            nodes.push({
                title: generateNodeTitle(geti18nText('menu.add.project'), 'NEW', 'NEWPROJECT'),
                key: 'NEWPROJECT',
                isLeaf: true,
            });
        }
        if ((key + '').startsWith('PCONTENT-')) {
            if (nodeFirstPage.length == 0) {
                nodes.unshift({
                    title: generateNodeTitle(geti18nText('menu.add.first.page'), 'NEW', 'NEWFIRSTPAGE-' + getId(key)),
                    key: 'NEWFIRSTPAGE-' + getId(key),
                    isLeaf: true,
                });
            } else {
                nodes.unshift(nodeFirstPage[0]);
            }
            nodes.push({
                title: generateNodeTitle(
                    geti18nText('menu.pages.outside.menu'),
                    'NOTINMENU',
                    'NOTINMENUP-' + getId(key)
                ),
                key: 'NOTINMENUP-' + getId(key),
            });
            nodes.push({
                title: generateNodeTitle(geti18nText('menu.add.menu'), 'NEW', 'NEWMENU-' + getId(key)),
                key: 'NEWMENU-' + getId(key),
                isLeaf: true,
            });
        }
        if ((key + '').startsWith('TYPE-')) {
            if (nodeFirstPage.length > 0) {
                nodes.unshift(nodeFirstPage[0]);
            }
            nodes.push({
                title: generateNodeTitle(geti18nText('menu.add.page'), 'NEW', 'NEWWEBPAGE-' + getId(key)),
                key: 'NEWWEBPAGE-' + getId(key),
                isLeaf: true,
            });
            nodes.push({
                title: generateNodeTitle(
                    geti18nText('menu.add.external.page'),
                    'NEW',
                    'NEWEXTERNALWEBPAGE-' + getId(key)
                ),
                key: 'NEWEXTERNALWEBPAGE-' + getId(key),
                isLeaf: true,
            });
            // if(theme !== null && theme !== "NyTourist Theme"){
            nodes.push({
                title: generateNodeTitle(geti18nText('menu.add.external.link'), 'NEW', 'NEWEXTERNALLINK-' + getId(key)),
                key: 'NEWEXTERNALLINK-' + getId(key),
                isLeaf: true,
            });
            //}
        }
        if ((key + '').startsWith('NOTINMENUP-')) {
            nodes.push({
                title: generateNodeTitle(geti18nText('menu.add.page'), 'NEW', 'NEWWEBPAGENOTMENU-' + getId(key)),
                key: 'NEWWEBPAGENOTMENU-' + getId(key),
                isLeaf: true,
            });
        }
        if ((key + '').startsWith('SEARCHTYPESTOP')) {
            nodes.push({
                title: generateNodeTitle(geti18nText('menu.add.search.type'), 'NEW', 'NEWSEARCHTYPESTOP'),
                key: 'NEWSEARCHTYPESTOP',
                isLeaf: true,
            });
        }
        if ((key + '').startsWith('SEARCHTYPESMAIN-')) {
            nodes.push({
                title: generateNodeTitle(
                    geti18nText('menu.add.search.group'),
                    'NEW',
                    'NEWSEARCHTYPESGROUP-' + getId(key)
                ),
                key: 'NEWSEARCHTYPESGROUP-' + getId(key),
                isLeaf: true,
            });
        }
        if ((key + '').startsWith('SEARCHTYPESGROUP-')) {
            nodes.push({
                title: generateNodeTitle(
                    geti18nText('menu.add.search.value'),
                    'NEW',
                    'NEWSEARCHTYPESVALUE-' + getId(key)
                ),
                key: 'NEWSEARCHTYPESVALUE-' + getId(key),
                isLeaf: true,
            });
        }
        if ((key + '').startsWith('LOCATIONS-')) {
            nodes.push({
                title: generateNodeTitle(geti18nText('menu.add.location'), 'NEW', 'NEWLOCATION-' + getId(key)),
                key: 'NEWLOCATION-' + getId(key),
                isLeaf: true,
            });
        }
        if ((key + '').startsWith('FACILITYGROUPS')) {
            nodes.push({
                title: generateNodeTitle(geti18nText('menu.add.facility.group'), 'NEW', 'NEWFACILITYGROUP'),
                key: 'NEWFACILITYGROUP',
                isLeaf: true,
            });
        }
        if ((key + '').startsWith('FACILITYGROUP-')) {
            nodes.push({
                title: generateNodeTitle(geti18nText('menu.add.facility'), 'NEW', 'NEWFACILITY-' + getId(key)),
                key: 'NEWFACILITY-' + getId(key),
                isLeaf: true,
            });
        }

        let removeFromLoadedKeys = [];
        for (const node of nodes) {
            if (loadedKeys.includes(node.key)) {
                removeFromLoadedKeys.push(node.key);
            }
        }

        if (removeFromLoadedKeys.length > 0) {
            setLoadedKeys(loadedKeys.filter((key) => !removeFromLoadedKeys.includes(key)));
        }
        setTreeData((origin) => updateTreeData(origin, key, nodes));
    }

    function refreshNode(key, showInactive = false) {
        setLoadedKeys([]);
        const url = getUrlAndParamsForChildren(key);
        requestGet(url.url, url.params).then((result) => {
            if (result.status === RESPONSE.OK) {
                if (Array.isArray(result.data)) {
                    generateBranch(key, result.data, showInactive);
                }
            }
        });
    }

    const closePreviewModal = () => {
        setPreviewVisible(false);
    };

    const checkDeleteFile = (value) => {
        //check is delete allowed
        setLoading(true);
        requestGet(
            CONSTANTS_REQ.DIRECTORY.CHECK_DELETE,
            { projectId: Session.getProjectId(), path: encodeURI(value.split('FILE-')[1]) },
            false,
            true
        ).then((result) => {
            if (result.status === RESPONSE.OK) {
                //confirm
                showDeleteConfirm(value.split('FILE-')[1]);
            } else {
                message.destroy();
                message.error(`${geti18nText('menu.delete.file.error')}`);
            }
            setLoading(false);
        });
    };

    const showAddUpload = (key, value) => {
        switch (key) {
            case 'upload':
                setSelectedNodeForFolderRefresh(value);
                setVisibleUploadModal(true);
                setFolderValue(value.split('FOLDER-')[1]);
                setDisabledTree(true);
                break;

            case 'add':
                setSelectedNodeForFolderRefresh(value);
                setFolderValue(value.split('FOLDER-')[1]);
                setNewFolderVisible(true);
                break;
        }
    };

    const contextMenu = (nodeKey) => {
        let menuFolder = [
            { key: 'upload', name: 'Upload' },
            { key: 'add', name: 'Dodaj folder' },
        ];
        return nodeKey.startsWith('FILE') ? (
            deleteMenu(nodeKey)
        ) : (
            <Menu>
                <Menu.Item
                    key="1"
                    onClick={() => {
                        refreshNode(nodeKey + '', false);
                    }}
                >
                    {geti18nText('menu.context.refresh')}
                </Menu.Item>
                {(nodeKey.startsWith('TYPE-') ||
                    nodeKey.startsWith('PCONTENT-') ||
                    nodeKey.startsWith('INDEX') ||
                    nodeKey.startsWith('SEARCHTYPESTOP') ||
                    nodeKey.startsWith('SEARCHTYPESMAIN') ||
                    nodeKey.startsWith('SEARCHTYPESGROUP') ||
                    nodeKey.startsWith('LOCATIONS-') ||
                    nodeKey.startsWith('FACILITYGROUPS') ||
                    nodeKey.startsWith('FACILITYGROUP-') ||
                    nodeKey.startsWith('NOTINMENUP')) && (
                    <Menu.Item
                        key="2"
                        onClick={() => {
                            refreshNode(nodeKey, true);
                        }}
                    >
                        {geti18nText('menu.context.show.inactive')}
                    </Menu.Item>
                )}
                {nodeKey.startsWith('FOLDER-') &&
                    menuFolder.map((key) => {
                        return (
                            <Menu.Item
                                key={key.key}
                                onClick={() => {
                                    showAddUpload(key.key, nodeKey);
                                }}
                            >
                                {key.name}
                            </Menu.Item>
                        );
                    })}
                {nodeKey.startsWith('ROOT_DIRECTORY-') &&
                    menuFolder.map((key) => {
                        return (
                            <Menu.Item
                                key={key.key}
                                onClick={() => {
                                    showAddUpload(key.key, nodeKey);
                                }}
                            >
                                {key.name}
                            </Menu.Item>
                        );
                    })}
            </Menu>
        );
    };

    const deleteMenu = (nodeKey) => {
        return (
            <Menu style={{ width: '100px' }}>
                <Menu.Item
                    key="1"
                    onClick={() => {
                        checkDeleteFile(nodeKey);
                    }}
                >
                    {geti18nText('menu.delete.file')}
                    <Icon style={{ marginLeft: '10px', fontSize: '16px', color: 'red' }} type="delete" />
                </Menu.Item>
            </Menu>
        );
    };

    const deleteFile = (value, nodeFolder = null, moveFile = false) => {
        setLoading(true);
        requestPost(CONSTANTS_REQ.DIRECTORY.DELETE, undefined, {
            projectId: Session.getProjectId(),
            path: value,
        }).then((result) => {
            if (result.status === RESPONSE.OK) {
                if (moveFile == true) {
                    message.info(`${geti18nText('menu.delete.file.moved')}`);
                } else {
                    message.info(`${geti18nText('menu.delete.file.success')}`);
                }

                refreshNode(selectedNodeForFolderRefresh + '', false);
                if (nodeFolder != null) {
                    reloadUploadNode(nodeFolder, false);
                }
            } else {
                message.destroy();
                message.error(`${geti18nText('menu.delete.file.error')}`);
            }
            setLoading(false);
        });
    };

    function showDeleteConfirm(value) {
        confirm({
            title: geti18nText('menu.delete.file.confirm'),
            okText: geti18nText('app.default.button.yes'),
            okType: 'danger',
            cancelText: geti18nText('app.default.button.no'),
            onOk() {
                deleteFile(value);
            },
        });
    }
    // dodan 'COMPONENT'
    function generateNodeTitle(title, type, key, active = true, notification = 0) {
        const notShowSubMenu = [
            'FIRSTPAGE',
            'NEW',
            'PROJECT',
            'APPSETTINGS',
            'MAINFACILITIES',
            'FACILITIES',
            'ACCOUNTS',
            'DOMAINS',
            'MOBILEAPP',
            'LOCATION',
            'FACILITY',
            'DOCS',
            'CHANGELOG',
            'COMPEDIT',
        ];

        return (
            <Dropdown
                disabled={notShowSubMenu.includes(type) ? true : false}
                overlay={contextMenu(key)}
                trigger={['contextMenu']}
            >
                <div
                    style={{
                        color:
                            type == 'NEW'
                                ? '#1890ff'
                                : type == 'FIRSTPAGE' || type == 'NOTINMENU'
                                ? '#52c41a'
                                : type == 'WEBPAGEEX'
                                ? '#ffa500'
                                : type == 'WEBPAGEEXLINK'
                                ? '#1100ff'
                                : type == 'WEBPAGEBREAK'
                                ? '#8A2BE2'
                                : undefined,
                    }}
                >
                    {(type == 'MENU' || type == 'NOTINMENU') && <Icon type="menu" style={{ marginRight: '10px' }} />}
                    {type == 'WEBPAGE' && <Icon type="file" style={{ marginRight: '10px' }} />}
                    {type == 'WEBPAGEEXLINK' && <Icon type="link" style={{ marginRight: '10px' }} />}
                    {type == 'WEBPAGEEX' && <Icon type="file" style={{ marginRight: '10px' }} />}
                    {type == 'FIRSTPAGE' && <Icon type="file" style={{ marginRight: '10px' }} />}
                    {type == 'PROJECT' && <Icon type="home" style={{ marginRight: '10px' }} />}
                    {type == 'FILE' && <Icon type="file" style={{ marginRight: '10px' }} />}
                    {type == 'FOLDER' && <Icon type="folder" style={{ marginRight: '10px' }} />}
                    {type == 'PROJECTS' && <Icon type="compass" style={{ marginRight: '10px' }} />}
                    {type == 'APPSETTINGS' && <Icon type="control" style={{ marginRight: '10px' }} />}
                    {type == 'SEARCHTYPES' && <Icon type="zoom-in" style={{ marginRight: '10px' }} />}
                    {type == 'MAINFACILITIES' && <Icon type="carry-out" style={{ marginRight: '10px' }} />}
                    {type == 'FACILITIES' && <Icon type="funnel-plot" style={{ marginRight: '10px' }} />}
                    {type == 'ACCOUNTS' && <Icon type="usergroup-delete" style={{ marginRight: '10px' }} />}
                    {type == 'DOMAINS' && <Icon type="global" style={{ marginRight: '10px' }} />}
                    {type == 'MOBILEAPP' && <Icon type="mobile" style={{ marginRight: '10px' }} />}
                    {type == 'LOCATION' && <Icon type="shop" style={{ marginRight: '10px' }} />}
                    {type == 'DOCS' && <Icon type="snippets" style={{ marginRight: '10px' }} />}
                    {type == 'CHANGELOG' && <Icon type="clock-circle" style={{ marginRight: '10px' }} />}
                    {type == 'COMPEDIT' && <Icon type="layout" style={{ marginRight: '10px' }} />}
                    {/*6120 dodana ikona*/}
                    <span style={{ color: !active ? '#ff0000' : undefined }}>{title}</span>
                    {/* {notification != null && notification > 0 && hasAnyRole(['ROLE_ADMIN', 'ROLE_PROJECT_ADMIN']) && (
                        <Badge count={notification} size="small" offset={[5, 0]} />
                    )} */}

                    {type == 'ROOT_DIRECTORY' && hasAnyRole(['ROLE_ADMIN', 'ROLE_PROJECT_ADMIN']) && (
                        <NyBadge minWidth={'20px'} minHeight={'20px'}></NyBadge>
                    )}
                </div>
            </Dropdown>
        );
    }

    function reloadUploadNode(folder, reloadFolder) {
        /* if (reloadFolder == true) {
            dispatch(
                selectFolder({
                    folderPath: folder,
                })
            );
        }*/

        dispatch(
            refreshMenu({
                refreshLastNode: false,
                refreshProjectNode: false,
                refreshNodes: ['FOLDER-' + folder + '' + Session.getProjectId()],
            })
        );
        dispatch(setRefreshFolder());
    }
    const onDrop = (info) => {
        if (
            info.event.dataTransfer.getData('drag-item') &&
            (info.node.props.eventKey.startsWith('FOLDER-') || info.node.props.eventKey.startsWith('FILE-'))
        ) {
            var key = info.node.props.eventKey.replace('' + Session.getProjectId(), '');
            if (key.startsWith('FILE-')) {
                const parent = findParent(treeData, key);
                key = parent;
            }
            let des = key.split('-');
            let destination = des[1];
            let item = JSON.parse(info.event.dataTransfer.getData('drag-item'));
            if (item.folderPath == destination) {
                return;
            }
            if (item != null) {
                requestGet(
                    CONSTANTS_REQ.DIRECTORY.CHECK_DELETE,
                    { projectId: Session.getProjectId(), path: item.relativePath },
                    false,
                    true
                ).then((result) => {
                    if (result.status === RESPONSE.OK) {
                        requestGet(CONSTANTS_REQ.DISK_FILE.COPY, {
                            source: '/' + Session.getProjectId() + item.relativePath,
                            destination: '/' + +Session.getProjectId() + destination + '/' + item.name,
                        }).then((res) => {
                            if (res.status === RESPONSE.OK) {
                                reloadUploadNode(destination, true);
                                deleteFile(item.relativePath, item.folderPath, true);
                            }
                        });
                    } else {
                        message.destroy();
                        message.error(`${geti18nText('menu.delete.file.error')}`);
                    }
                });
            }

            return;
        }
        if (
            nodeCanBeDragged(info.node.props.eventKey) &&
            info.dragNode &&
            nodeCanBeDragged(info.dragNode.props.eventKey)
        ) {
            if (onlyOrderChanged(info.dragNode.props.pos, info.node.props.pos)) {
                let siblings = [];
                const menuCopy = cloneDeep(treeData);
                siblings = getSiblings(menuCopy, info.node.props.eventKey);
                const parent = findParent(treeData, info.node.props.eventKey);
                const movedNode = siblings[siblings.findIndex((x) => x.key === info.dragNode.props.eventKey)];

                siblings.splice(
                    siblings.findIndex((x) => x.key === info.dragNode.props.eventKey),
                    1
                );

                siblings.splice(
                    siblings.findIndex((x) => x.key === info.node.props.eventKey),
                    0,
                    movedNode
                );
                setTreeData((origin) => updateTreeData(origin, parent, siblings));

                //update order
                let reordered = {};
                let i = 0;

                for (const node of siblings) {
                    if (
                        node.key.startsWith('TYPE-') ||
                        node.key.startsWith('WEBPAGE-') ||
                        node.key.startsWith('WEBPAGEEX-') ||
                        node.key.startsWith('WEBPAGEEXLINK-') ||
                        node.key.startsWith('WEBPAGEBREAK-') ||
                        node.key.startsWith('COMPONENT-') ||
                        node.key.startsWith('SEARCHTYPESMAIN-') ||
                        node.key.startsWith('SEARCHTYPESGROUP-') ||
                        node.key.startsWith('SEARCHTYPESVALUE-') ||
                        node.key.startsWith('LOCATION-') ||
                        node.key.startsWith('FACILITYGROUP-') ||
                        node.key.startsWith('FACILITY-')
                    ) {
                        const nodeId = node.key.startsWith('WEBPAGEBREAK-')
                            ? getIdWithNegativePrefix(node.key)
                            : getId(node.key);
                        if (nodeId != undefined && parseInt(nodeId) != NaN) {
                            reordered[parseInt(nodeId)] = i;
                            i++;
                        }
                    }
                }
                if (
                    info.dragNode.props.eventKey.startsWith('WEBPAGE-') ||
                    info.dragNode.props.eventKey.startsWith('WEBPAGEEX-') ||
                    info.dragNode.props.eventKey.startsWith('WEBPAGEEXLINK-') ||
                    info.dragNode.props.eventKey.startsWith('WEBPAGEBREAK-')
                ) {
                    const pageType = getId(parent);
                    reordered = {
                        orderSet: reordered,
                        pageType: pageType ? parseInt(pageType) : undefined,
                    };
                }
                requestPut(
                    info.dragNode.props.eventKey.startsWith('COMPONENT-')
                        ? CONSTANTS_REQ.WEBPAGECOMPONENT.ORDER
                        : info.dragNode.props.eventKey.startsWith('WEBPAGE-') ||
                          info.dragNode.props.eventKey.startsWith('WEBPAGEEX-') ||
                          info.dragNode.props.eventKey.startsWith('WEBPAGEEXLINK-') ||
                          info.dragNode.props.eventKey.startsWith('WEBPAGEBREAK-')
                        ? CONSTANTS_REQ.WEBPAGE.ORDER_WEB_TYPE
                        : info.dragNode.props.eventKey.startsWith('TYPE-')
                        ? CONSTANTS_REQ.PAGETYPE.ORDER
                        : info.dragNode.props.eventKey.startsWith('LOCATION-')
                        ? CONSTANTS_REQ.LOCATION.ORDER
                        : info.dragNode.props.eventKey.startsWith('FACILITYGROUP-') ||
                          info.dragNode.props.eventKey.startsWith('FACILITY-')
                        ? CONSTANTS_REQ.FACILITY.ORDER
                        : CONSTANTS_REQ.SEARCH_TYPE.ORDER,
                    undefined,
                    reordered,
                    false
                ).then((result, key = info.dragNode.props.eventKey) => {
                    if (result && result.status === RESPONSE.OK) {
                        //refresh page preview
                        if (key.startsWith('COMPONENT-')) {
                            dispatch(refreshPagePreview({ page: getId(parent) }));
                            refreshNode(findParent(treeData, info.node.props.eventKey));
                        }
                    }
                });

            }
        }
    };

    const getId = (key) => {
        const arr = (key + '').split('-');
        if (arr.length > 0) {
            return arr[1];
        }
        return undefined;
    };

    const getIdWithNegativePrefix = (key) => {
        var i = (key + '').indexOf('-');
        if (i > 0) {
            var j = (key + '').indexOf('+');

            return key.substring(i + 1, j > 0 ? j : key.length);
        } else return undefined;
    };

    const nodeCanBeDragged = (key) => {
        return (
            key.startsWith('TYPE-') ||
            key.startsWith('WEBPAGE-') ||
            key.startsWith('WEBPAGEEX-') ||
            key.startsWith('WEBPAGEEXLINK-') ||
            key.startsWith('WEBPAGEBREAK-') ||
            key.startsWith('COMPONENT-') ||
            key.startsWith('SEARCHTYPESMAIN-') ||
            key.startsWith('SEARCHTYPESGROUP-') ||
            key.startsWith('SEARCHTYPESVALUE-') ||
            key.startsWith('LOCATION-') ||
            key.startsWith('FACILITYGROUP-') ||
            key.startsWith('FACILITY-')
        );
    };

    const onlyOrderChanged = (pos1, pos2) => {
        const arr1 = pos1.split('-');
        const arr2 = pos2.split('-');
        if (arr1.length != arr2.length) {
            return false;
        }
        for (let i = 0; i < arr1.length - 1; i++) {
            if (arr1[i] != arr2[i]) {
                return false;
            }
        }
        return true;
    };

    const onSelect = (selectedKeys, info) => {
        if (selectedKeys.length == 0) {
            if (expandedKeys.includes(lastSelectedNode)) {
                let expanded = expandedKeys.slice();
                const index = expandedKeys.findIndex((key) => key === lastSelectedNode);
                expanded.splice(index, 1);
                setExpandedKeys(expanded);
            }
            return;
        }

        const key = selectedKeys[0];
        setLastSelectedNode(key);
        const arr = key.split('-');

        let expanded = expandedKeys.slice();
        if (!expandedKeys.includes(key) && info.node.isLeaf() != true) {
            expanded.push(key);
            setExpandedKeys(expanded);
            onExpandSetSession(key);
        }

        setNodeForRefresh(undefined);
        if (refreshLastNode === true) {
            dispatch(
                refreshMenu({
                    refreshLastNode: false,
                    refreshProjectNode: false,
                    refreshNodes: [],
                })
            );
        }
        switch (arr[0]) {
            case 'PROJECTSETTINGS':
                history.push('/settings/' + getId(key));
                break;
            case 'LOCATION':
                setNodeForRefresh('LOCATIONS-' + Session.getProjectId());
                history.push('/location/' + getId(key));
                break;
            case 'MAPPINS':
                history.push('/mapPins/' + getId(key));
                break;
            case 'PAGETYPES':
                history.push('/pageTypes/' + getId(key));
                break;
            case 'LANGUAGES':
                history.push('/lang/' + getId(key));
                break;
            case 'BLOG':
                history.push('/blog/' + getId(key));
                break;
            case 'FAQCATEGORY':
                history.push('/faqCategory/' + getId(key));
                break;
            case 'PACKAGE':
                history.push('/package/' + getId(key));
                break;
            case 'SHOPCATEGORY':
                history.push('/shopCategory/' + getId(key));
                break;
            case 'SHOP':
                history.push('/shop/' + getId(key));
                break;
            case 'FAQQUESTIONANSWER':
                history.push('/faqQuestionAnswer/' + getId(key));
                break;
            case 'ACCOUNTS':
                history.push('/accounts');
                break;
            case 'DOMAINS':
                history.push('/domains');
                break;
            // 6120 dodan component
            case 'COMPEDIT':
                history.push('/compEdit');
                break;
            case 'TYPE':
                setNodeForRefresh('PCONTENT-' + Session.getProjectId());
                history.push('/webPageTypes/' + getId(key));
                break;
            case 'NEWMENU':
                setNodeForRefresh('PCONTENT-' + Session.getProjectId());
                history.push('/webPageTypes/create');
                break;
            case 'NEWWEBPAGE':
                const parentNode = findParent(treeData, key);
                dispatch(
                    selectPageType({
                        selectedPageType: getId(parentNode),
                    })
                );
                setNodeForRefresh(parentNode);
                history.push('/webPage/create');
                break;
            case 'NEWEXTERNALWEBPAGE':
                const parentNodeExt = findParent(treeData, key);
                dispatch(
                    selectPageType({
                        selectedPageType: getId(parentNodeExt),
                    })
                );
                setNodeForRefresh(parentNodeExt);
                history.push('/webPage/external/create');
                break;
            case 'NEWEXTERNALLINK':
                const parentNodeExtLink = findParent(treeData, key);
                dispatch(
                    selectPageType({
                        selectedPageType: getId(parentNodeExtLink),
                    })
                );
                setNodeForRefresh(parentNodeExtLink);
                history.push('/webPage/externalLink/create');
                break;
            case 'NEWFIRSTPAGE':
                dispatch(
                    selectFirstPage({
                        selectedFirstPage: true,
                    })
                );
                history.push('/webPage/create');
                break;
            case 'NEWWEBPAGENOTMENU':
                setNodeForRefresh(findParent(treeData, key));
                dispatch(
                    selectPageType({
                        selectedPageType: 0,
                    })
                );
                history.push('/webPage/create');
                break;
            case 'FIRSTPAGE':
                history.push('/webPage/' + getId(key));
                break;
            case 'WEBPAGE':
                setNodeForRefresh(findParent(treeData, key));
                history.push('/webPage/' + getId(key));
                break;
            case 'WEBPAGEEXLINK':
                const __parent = findParent(treeData, key);
                setNodeForRefresh(__parent);
                history.push('/webPage/externalLink/' + getId(key));
                break;
            case 'WEBPAGEEX':
                const _parent = findParent(treeData, key);
                setNodeForRefresh(_parent);
                history.push('/webPage/external/' + getId(key) + '-' + getId(_parent));
                break;
            case 'PROJECT':
                setNodeForRefresh(findParent(treeData, key));
                history.push('/projectManagement/' + getId(key));
                break;
            case 'NEWPROJECT':
                setNodeForRefresh(findParent(treeData, key));
                history.push('/projectManagement/create');
                break;
            case 'MAINFACILITIES':
                history.push('/mainfacilities');
                break;
            case 'FACILITIES':
                history.push('/facilities');
                break;
            case 'SEARCHTYPESMAIN':
                setNodeForRefresh(findParent(treeData, key));
                history.push('/searchtypes/0/' + getId(key));
                break;
            case 'SEARCHTYPESGROUP':
            case 'SEARCHTYPESVALUE':
                setNodeForRefresh(findParent(treeData, key));
                const parent = findParent(treeData, key);
                history.push('/searchtypes/' + getId(parent) + '/' + getId(key));
                break;
            case 'NEWSEARCHTYPESTOP':
                setNodeForRefresh(findParent(treeData, key));
                history.push('/searchtypes/0/create');
                break;
            case 'NEWSEARCHTYPESGROUP':
            case 'NEWSEARCHTYPESVALUE':
                setNodeForRefresh(findParent(treeData, key));
                const parentt = findParent(treeData, key);
                history.push('/searchtypes/' + getId(parentt) + '/create');
                break;

            case 'FILE':
                if (info.nativeEvent.target.tagName != 'LI') {
                    let fileType = null;
                    if (
                        IMAGE_FORMATS.includes(
                            key.split('FILE-')[1].toLowerCase().substring(key.split('FILE-')[1].lastIndexOf('.'))
                        )
                    ) {
                        fileType = CONSTANTS_FILE_TYPE.IMAGE;
                    } else if (
                        VIDEO_FORMATS.includes(
                            key.split('FILE-')[1].toLowerCase().substring(key.split('FILE-')[1].lastIndexOf('.'))
                        )
                    ) {
                        fileType = CONSTANTS_FILE_TYPE.VIDEO;
                    }
                    dispatch(
                        selectFile({
                            file: {
                                path: key.split('FILE-')[1],
                                fileType: fileType,
                                size: info.node.props.size,
                                dateCreated: info.node.props.dateCreated,
                            },
                        })
                    );

                    dispatch(
                        selectFolder({
                            folderPath: key.split('FILE-')[1].substring(0, key.split('FILE-')[1].lastIndexOf('/')),
                        })
                    );
                    history.push('/file');
                }

                break;
            case 'NEWLOCATION':
                setNodeForRefresh(findParent(treeData, key));
                history.push('/location/create');
                break;

            case 'FACILITYGROUP':
                setNodeForRefresh(findParent(treeData, key));
                history.push('/facilitygroup/' + getId(key));
                break;

            case 'FACILITY':
                setNodeForRefresh(findParent(treeData, key));
                const parentf = findParent(treeData, key).split('FACILITYGROUP-')[1];
                history.push({ pathname: '/facilitygroup/' + getId(key), addedData: { parentId: parentf } });
                break;

            case 'TRANSLATIONS':
                setNodeForRefresh(findParent(treeData, key));
                history.push('/translations');
                break;

            case 'NEWFACILITYGROUP':
                setNodeForRefresh(findParent(treeData, key));
                history.push('/facilitygroup/create');
                break;

            case 'NEWFACILITY':
                setNodeForRefresh(findParent(treeData, key));
                history.push({
                    pathname: '/facilitygroup/' + getId(key) + '/create',
                    addedData: { parentId: getId(key) },
                });
                break;
            case 'ROOT_DIRECTORY':
                setSelectedNodeForFolderRefresh(selectedKeys[0]);
                dispatch(
                    selectFolder({
                        folderPath: '/',
                    })
                );
                history.push('/directory');
                break;
            case 'FOLDER':
                setSelectedNodeForFolderRefresh(selectedKeys[0]);
                refreshNode(key);
                let folderPath = key.replace('' + Session.getProjectId(), '');
                dispatch(
                    selectFolder({
                        folderPath: getId(folderPath),
                    })
                );
                history.push('/directory');
                break;
            case 'DOCS':
                openInNewTab(Session.getSetting('ux.docs-url'));
            case 'CHANGELOG':
                openInNewTab(Session.getSetting('ux.change-log-url'));
            case 'LOCATIONS':
                history.push('/locations');
                break;
            case 'MOBILEAPP':
                history.push('/mobileApp');
                break;
            case 'REPORT_PROBLEM':
                history.push('/problemReport/' + getId(key));
                break;
            case 'PROJECTDESIGNSETTINGS':
                history.push('/designSettings/' + getId(key));
                break;
            case 'COMPONENT':
                dispatch(
                    selectWebPageComponentToScroll({
                        webPageComponentToScroll: getId(key),
                    })
                    );

                    
                break;    
        }
    };

    const openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
        if (newWindow) newWindow.opener = null;
    };

    function renderTreeNodes(data) {
        data.map((item) => {
            if (item.children) {
                return (
                    <TreeNode title={item.title} key={item.key} dataRef={item}>
                        {renderTreeNodes(item.children)}
                    </TreeNode>
                );
            }
            return <TreeNode key={item.key} {...item} dataRef={item}></TreeNode>;
        });
    }

    function onChangeFolderVisible(value) {
        let folder = selectedNodeForFolderRefresh + +'' + Session.getProjectId();
        refreshNode(folder, false);
        setNewFolderVisible(value);
    }

    function onVisibleUploadChange(value) {
        let folder = selectedNodeForFolderRefresh + '' + Session.getProjectId();
        refreshNode(folder, false);
        setVisibleUploadModal(value.disabled);
    }

    const fileDownload = (value) => {
        const path = value.file_path ? value.file_path : undefined;
        const fileName = value.name
            ? value.name
            : value.file_path.substring(value.file_path.lastIndexOf('/') + 1, value.file_path.length);

        setLoading(true);

        requestGet(
            CONSTANTS_REQ.DISK_FILE.DOWNLOAD,
            { projectId: Session.getProjectId(), relativePath: encodeURI(path) },
            false,
            true
        ).then((result) => {
            if (result.status === RESPONSE.OK && result.data) {
                result.data.filename = fileName;
                downloadFile(result);
            } else {
                message.destroy();
                message.error(`${geti18nText('upload.text.download.error')}`);
            }
            setLoading(false);
        });
    };

    const appSettingsMenu = {
        title: generateNodeTitle(geti18nText('menu.codebooks'), 'APPSETTINGS', 'APPSETTINGS'),
        key: 'APPSETTINGS',
        children: [
            {
                title: generateNodeTitle(geti18nText('menu.codebook.search.type'), 'SEARCHTYPES', 'SEARCHTYPESTOP'),
                key: 'SEARCHTYPESTOP',
            },
            {
                title: generateNodeTitle(
                    geti18nText('menu.codebooks.main.facilities'),
                    'MAINFACILITIES',
                    'MAINFACILITIES'
                ),
                key: 'MAINFACILITIES',
                isLeaf: true,
            },
            {
                title: generateNodeTitle(geti18nText('menu.codebooks.facilities'), 'FACILITYGROUPS', 'FACILITYGROUPS'),
                key: 'FACILITYGROUPS',
            },
            {
                title: generateNodeTitle(geti18nText('menu.codebooks.translations'), 'TRANSLATIONS', 'TRANSLATIONS'),
                key: 'TRANSLATIONS',
                isLeaf: true,
            },
            {
                title: generateNodeTitle(geti18nText('webpage.edit.search.location'), 'LOCATIONS', 'LOCATIONS'),
                key: 'LOCATIONS',
                isLeaf: true,
            },
        ],
    };

    const onSearchTextChange = (e) => {
        const value = e.target.value;
        setSearchProjectName(value);
    };

    return (
        <React.Fragment>
            {!collapsed && <LogoRow logo={logo} />}

            <Sider
                width={330}
                className="sider"
                breakpoint="md"
                collapsedWidth="0"
                onCollapse={(collapsed) => {
                    setCollapsed(collapsed);
                }}
                theme={'dark'}
                style={{
                    height: logo ? 'calc(100vh - 89px)' : '100vh',
                    position: 'fixed',
                    left: 0,
                    marginTop: logo && '89px',
                    paddingTop: logo && '0px',
                    zIndex: 99999,
                }}
            >
                <div
                    style={{
                        overflow: 'auto',
                        height: logo ? 'calc(100vh - 89px)' : 'calc(100vh - 89px)',
                    }}
                    className={'thin-scrollbar'}
                >
                    <div style={{ marginTop: 5, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <Search
                            style={{ marginLeft: 8, marginRight: 4 }}
                            placeholder={geti18nText('menu.search.projects')}
                            onChange={onSearchTextChange}
                        />
                        <Popover placement="bottomLeft" content={geti18nText('menu.filter.web.projects')}>
                            <Icon
                                style={
                                    filterWebProject
                                        ? { color: 'blue', marginLeft: '5px', marginRight: '5px', fontSize: '17px' }
                                        : { marginLeft: '5px', marginRight: '5px', fontSize: '16px' }
                                }
                                type="global"
                                onClick={(e) => {
                                    if (filterWebProject == true) {
                                        setFilteKioskroject(null);
                                        setFilterWebProject(null);
                                    } else {
                                        setFilteKioskroject(false);
                                        setFilterWebProject(true);
                                    }
                                }}
                            />
                        </Popover>
                        <Popover placement="bottomRight" content={geti18nText('menu.filter.kiosk.projects')}>
                            <Icon
                                style={
                                    filterKioskProject
                                        ? { color: 'blue', marginLeft: '5px', marginRight: '5px', fontSize: '17px' }
                                        : { marginLeft: '5px', marginRight: '5px', fontSize: '16px' }
                                }
                                type="mobile"
                                onClick={(e) => {
                                    setFilteKioskroject(!filterKioskProject);
                                    if (filterKioskProject == true) {
                                        setFilteKioskroject(null);
                                        setFilterWebProject(null);
                                    } else {
                                        setFilterWebProject(false);
                                        setFilteKioskroject(true);
                                    }
                                }}
                            />
                        </Popover>
                    </div>

                    <Tree
                        treeData={treeData}
                        loadData={onLoadData}
                        expandedKeys={expandedKeys}
                        autoExpandParent={autoExpandParent}
                        onExpand={onExpand}
                        draggable
                        onDrop={onDrop}
                        onSelect={onSelect}
                        loadedKeys={loadedKeys}
                        style={logo !== undefined ? { marginTop: '30px' } : {}}
                    >
                        {renderTreeNodes(treeData)}
                    </Tree>
                </div>
            </Sider>

            <Modal
                title={geti18nText('menu.preview')}
                visible={previewVisible}
                onCancel={closePreviewModal}
                destroyOnClose={true}
                footer={null}
            >
                <FilePreview addedData={{ projectId: Session.getProjectId() }} file={previewFile} />
            </Modal>

            <AddFolder
                onChange={onChangeFolderVisible}
                visible={newFolderVisible}
                folderValue={folderValue}
                projectId={Session.getProjectId()}
            ></AddFolder>
            <Row hidden={true}>
                <FileUpload
                    folderValue={folderValue}
                    visible={visibleUploadModal}
                    disabled={disabledTree}
                    menuUploadChange={onVisibleUploadChange}
                    multiple={true}
                    addedData={{ projectId: Session.getProjectId() }}
                ></FileUpload>
            </Row>
        </React.Fragment>
    );
});

export const applicationLogo = (logo) => {
    if (logo === undefined) {
        if (Session.getSetting('ux.logo')) {
            switch (Session.getSetting('ux.logo')) {
                case 'none':
                    return;
                case 'sm_tour_black':
                    return logoSmTourBlack;
                default:
                    return logoSmTourColor;
            }
        } else {
            return logoSmTourColor;
        }
    }
};

export const LogoRow = (props) => {
    let history = useHistory();
    function navigateToDashboard() {
        history.push('/');
    }

    return (
        <React.Fragment>
            {props !== undefined && props.logo !== undefined && (
                <Row
                    type="flex"
                    style={{
                        zIndex: 1000002,
                        align: 'top',
                        width: '330px',
                        display: 'flex',
                        position: 'fixed',
                        borderRight: '1px solid rgba(160, 160, 160, 0.3)',
                        backgroundColor: '#fff',
                        height: '89px',
                        cursor: 'pointer',
                    }}
                >
                    <img
                        src={props.logo}
                        alt=""
                        height={90}
                        style={{ paddingLeft: '8px' }}
                        onClick={navigateToDashboard}
                    />
                    <div
                        style={{
                            textAlign: 'center',
                            verticalAlign: 'center',
                            maxWidth: '230px',
                            minWidth: '230px',
                            fontSize: '32px',
                            paddingTop: '15px',
                        }}
                        onClick={navigateToDashboard}
                    >
                        {Session.getSetting('ux.logo-text') ? Session.getSetting('ux.logo-text') : 'Smart Tourist'}
                    </div>
                </Row>
            )}
            {props == undefined ||
                (props.logo == undefined && (
                    <Row
                        type="flex"
                        style={{
                            zIndex: 1000002,
                            align: 'top',
                            width: '330px',
                            display: 'flex',
                            position: 'fixed',
                            borderRight: '1px solid rgba(160, 160, 160, 0.3)',
                            backgroundColor: '#fff',
                            height: '63px',
                            cursor: 'pointer',
                        }}
                    >
                        <div
                            style={{
                                textAlign: 'center',
                                verticalAlign: 'center',
                                maxWidth: '230px',
                                minWidth: '230px',
                                fontSize: '32px',
                                paddingTop: '10px',
                            }}
                            onClick={navigateToDashboard}
                        >
                            {Session.getSetting('ux.title') ? Session.getSetting('ux.title') : 'Smart Tourist'}
                        </div>
                    </Row>
                ))}
        </React.Fragment>
    );
};
