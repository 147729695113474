import React, { useState, useEffect } from 'react';
import { Form, Row, Col, Modal, Button, Divider, notification } from 'antd';
import { Session, geti18nText } from '../../utils/Session';
import { normalizeComponents } from '../../utils/Utils';
import { requestGet, requestPut, RESPONSE } from '../../utils/RequestResolver';
import { CONSTANTS_FILE_TYPE, CONSTANTS_REQ } from '../../utils/Constants';
import { FileUpload } from '../fileUpload/FileUpload';
import { InputByLanguage, translationsToObject } from '../InputByLanguage';
import { Spinner } from '../../components/Spinner';
import { gridMap, ImageGridRender } from '@nybble/ny-tourist-components';
import { SearchField } from '../../components/Search';

const ImageGridEdit = Form.create({ name: 'VideoBlockEdit' })((props) => {
    const { getFieldDecorator, setFieldsValue } = props.form;
    const [fetching, setFetching] = useState(false);
    const [mainTitleLangJson, setMainTitleLangJson] = useState(undefined);
    const [gridSize, setGridSize] = useState(8);
    const [formValues, setFormValues] = useState(null);
    const [images, setImages] = useState({});
    const [imageId, setImageId] = useState(null);
    const [selectedImage, setSelectedImage] = useState(null);
    const [editImageVisible, setEditImageVisible] = useState(false);

    useEffect(() => {
        fetch();
    }, [props.visible]);

    useEffect(() => {
        props.form.resetFields();
        setFieldsValue(formValues);
    }, [formValues]);

    function fetch() {
        setFetching(true);
        requestGet(CONSTANTS_REQ.WEBPAGECOMPONENT.EDIT + '/' + props.id).then((result) => {
            setFetching(false);
            if (result.status === RESPONSE.OK) {
                setValues(result.data);
            }
        });
    }

    function setValues(component_data) {
        var json;
        if (component_data.json) {
            json = JSON.parse(component_data.json);

            for (var key in json.headTitle) {
                const newKey = 'translation-' + key + '-headTitle';
                let v = {};
                v[newKey] = json.headTitle[key];
                setFieldsValue(v);
            }
            setFieldsValue({ gridSize: json.gridSize });
            setMainTitleLangJson(json.headTitle);
            setImages(json.images);
            setGridSize(json.gridSize.id);
        }
    }
    console.log(images);

    function showNotification(title, message, duration, type) {
        notification[type]({
            message: title,
            description: message,
            duration: duration,
        });
    }

    function okNotification() {
        showNotification(geti18nText('app.default.save.ok'), geti18nText('app.default.save.ok.desc'), 3, 'success');
    }

    function error(message) {
        showNotification(geti18nText('app.default.save.nok'), message, 3, 'error');
    } 

    function save(e, close) {
        e.preventDefault();
        props.form.validateFieldsAndScroll((err, values) => {
            if (!err || !err.hasOwnProperty('gridSize')) {
                let valuesToSave = {};
                values = normalizeComponents(values);
                valuesToSave.headTitle = values.headTitle;
                valuesToSave.gridSize =
                    values.gridSize != null && values.gridSize.constructor === String
                        ? { id: parseInt(values.gridSize) }
                        : { id: parseInt(values.gridSize.id) };
                valuesToSave.images = images;

                requestPut(CONSTANTS_REQ.WEBPAGECOMPONENT.UPDATEJSON + '/' + props.id, null, {
                    id: props.id,
                    json: JSON.stringify(valuesToSave),
                }).then((result) => {
                    if (result && result.status === RESPONSE.CREATED) {
                        console.log('okNotification');
                        okNotification();
                        close && props.closePopup();
                    } else {
                        error();
                        if (result && result.status === RESPONSE.BAD_REQUEST) {
                            if (result.data && result.data.field) {
                                switch (result.data.field) {
                                    case 'mandatory_fields_required':
                                        console.log(geti18nText('app.default.mandatory_fields_required'));
                                        return;
                                    default:
                                        break;
                                }
                            }
                        }
                        if (result.data && result.data.error) {
                            console.log(JSON.stringify(result.data.error));
                        }
                    }
                });
            }
        });
    }

    function getGridSizes() {
        var array = new Array(6, 7, 8, 9, 10, 11);
        return array.map((nr) => {
            let rObj = {};
            rObj.id = nr;
            rObj.text = '' + nr;
            return rObj;
        });
    }

    function onSelectGridSizeChange(value) {
        if (value) {
            setGridSize(parseInt(value.key));
        }
    }

    function editSelectedImage(id, image) {
        setImageId(id);
        setSelectedImage(images['image_' + id]);
        setEditImageVisible(true);
    }

    function saveImageData(image) {
        let imgs = images;
        image.path = { file_path: image.file_path, name: image.name };
        delete image.file_path;
        delete image.name;

        imgs['image_' + imageId] = image;
        setImages(imgs);
        setEditImageVisible(false);
        props.form.resetFields(['file_path', 'webPage']);
        Session.getProjectInfo().languages.map((value, index) => {
            props.form.resetFields(['translation-' + Object.keys(value)[0] + '-title']);
            props.form.resetFields(['translation-' + Object.keys(value)[0] + '-subtitle']);
        });
    }

    if (fetching) {
        return <Spinner />;
    } else {
        return (
            <React.Fragment>
                <Divider>{geti18nText('component.imagegrid')}</Divider>
                <Form>
                    <Row>
                        <Col span={24}>
                            <InputByLanguage
                                notRequred
                                fieldName="headTitle"
                                label={geti18nText('component.imagegrid.edit.title')}
                                langJson={mainTitleLangJson}
                                {...props}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Form.Item label={geti18nText('component.imagegrid.edit.gridSize')}>
                                {getFieldDecorator('gridSize', {
                                    rules: [{ required: true, message: geti18nText('app.default.required') }],
                                    initialValue: { id: gridSize, text: gridSize + '' },
                                })(<SearchField options={getGridSizes()} onChange={onSelectGridSizeChange} />)}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24} style={{ marginTop: '20px', marginBottom: '20px' }}>
                        <Col span={24}>
                            <div style={{ width: '900px', height: '450px' }}>
                                <ImageGridRender
                                    editImageProp={editSelectedImage}
                                    gridMap={gridMap(gridSize)}
                                    projectId={Session.getProjectId()}
                                    images={images}
                                    CONSTANTS_REQ={CONSTANTS_REQ}
                                    admin
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24} style={{ textAlign: 'right', marginTop: '1rem' }}>
                            <Button style={{ marginRight: '1em' }} onClick={(e) => props.closePopup()}>
                                {geti18nText('app.default.button.cancel')}
                            </Button>
                            <Button style={{ marginRight: '1em' }} type="primary" onClick={(e) => save(e, false)}>
                                {geti18nText('app.default.button.save')}
                            </Button>
                            <Button
                                type="primary"
                                style={{ background: '#31b531', borderColor: '#31b531', textColor: 'white' }}
                                onClick={(e) => {
                                    save(e, true);
                                }}
                            >
                                {geti18nText('app.default.button.save.close')}
                            </Button>
                        </Col>
                    </Row>
                    <EditImage
                        visible={editImageVisible}
                        setVisible={setEditImageVisible}
                        onSave={saveImageData}
                        imageId={imageId}
                        image={selectedImage}
                        {...props}
                    />
                </Form>
            </React.Fragment>
        );
    }
});

export const EditImage = (props) => {
    const { getFieldDecorator, setFieldsValue } = props.form;
    const [imageTitleLangJson, setImageTitleLangJson] = useState(undefined);
    const [imageSubtitleLangJson, setImageSubtitleLangJson] = useState(undefined);

    useEffect(() => {
        if (props.image) {
            setImageTitleLangJson(props.image.title);
            setImageSubtitleLangJson(props.image.subtitle);
        } else {
            setImageTitleLangJson(undefined);
            setImageSubtitleLangJson(undefined);
            props.form.resetFields(['image-file_path', 'webPage']);
        }
    }, [props.image]);

    useEffect(() => {
        setValues();
    }, [imageTitleLangJson]);

    useEffect(() => {
        if (!props.image) {
            props.form.resetFields();
        }
    }, [props.visible]);

    function setValues() {
        props.form.resetFields(['image-file_path', 'webPage']);
        Session.getProjectInfo().languages.map((value, index) => {
            props.form.resetFields(['translation-' + Object.keys(value)[0] + '-title']);
            props.form.resetFields(['translation-' + Object.keys(value)[0] + '-subtitle']);
        });

        if (props.image) {
            setFieldsValue({
                'image-file_path': { key: props.image.path.file_path, file_path: props.image.path.file_path },
            });
            if (props.image.webPage) {
                setWepPageValue(props.image.webPage.id);
            }

            for (var key in props.image.title) {
                const newKey = 'translation-' + key + '-title';
                let v = {};
                v[newKey] = props.image.title[key];
                setFieldsValue(v);
            }
            for (var key in props.image.subtitle) {
                const newKey = 'translation-' + key + '-subtitle';
                let v = {};
                v[newKey] = props.image.subtitle[key];
                setFieldsValue(v);
            }
        } else {
            setImageTitleLangJson(undefined);
            setImageSubtitleLangJson(undefined);
            props.form.resetFields(['image-file_path', 'webPage']);
        }
    }

    function setWepPageValue(id) {
        requestGet(CONSTANTS_REQ.WEBPAGE.EDIT + '/' + id).then((result) => {
            if (result.status === RESPONSE.OK) {
                setFieldsValue({ webPage: { id: id, name: result.data.name } });
            }
        });
    }

    function closePopup() {
        props.setVisible(false);
    }

    function normalizeFileUpload(field) {
        let file = props.form.getFieldsValue([field])[field];
        if (file instanceof Array) {
            file = file[0];
        }
        return file.id ? file : { file_path: file.key, name: file.label };
    }

    function save() {
        let image = {};

        props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                image.file_path = values['image-file_path'].file_path;
                image.name = values['image-file_path'].name;
                values = normalizeComponents(values);
                image.title = values.title;
                image.subtitle = values.subtitle;
                image.webPage =
                    values.webPage != null && values.webPage.constructor === String
                        ? { id: parseInt(values.webPage) }
                        : values.webPage;
                props.onSave(image);
            }
        });
    }

    return (
        <>
            <Modal
                visible={props.visible}
                onCancel={closePopup}
                width={1000}
                title={geti18nText('component.imagegrid.edit.image')}
                okText={geti18nText('app.default.button.save')}
                cancelText={geti18nText('app.default.button.cancel')}
                onOk={save}
                destroyOnClose={true}
            >
                <Row gutter={24}>
                    <Col span={24} style={{ width: '100%' }}>
                        <InputByLanguage
                            notRequred
                            fieldName="title"
                            label={geti18nText('component.imagegrid.edit.image.title')}
                            langJson={imageTitleLangJson}
                            {...props}
                        />
                        <InputByLanguage
                            notRequred
                            fieldName="subtitle"
                            label={geti18nText('component.imagegrid.edit.image.subtitle')}
                            langJson={imageSubtitleLangJson}
                            {...props}
                        />
                        <Form.Item label={geti18nText('component.imagegrid.edit.image.image')}>
                            {getFieldDecorator('image-file_path', {
                                rules: [{ required: true, message: geti18nText('app.default.required') }],
                            })(
                                <FileUpload
                                    fileType={CONSTANTS_FILE_TYPE.IMAGE}
                                    map={{ id: 'id', label: 'name' }}
                                    searchBy="name"
                                    disabled={false}
                                    addedData={{
                                        projectId: Session.getProjectId(),
                                        fileType: CONSTANTS_FILE_TYPE.IMAGE,
                                    }}
                                />
                            )}
                        </Form.Item>
                        <Form.Item label={geti18nText('component.imagegrid.edit.image.webpage')}>
                            {getFieldDecorator('webPage')(
                                <SearchField
                                    url={CONSTANTS_REQ.WEBPAGE.SEARCH}
                                    map={{ id: 'id', label: 'name' }}
                                    searchBy="name"
                                    disabled={false}
                                    translate={true}
                                    addedData={{ projectId: Session.getProjectId() }}
                                />
                            )}
                        </Form.Item>
                    </Col>
                </Row>
            </Modal>
        </>
    );
};

export default ImageGridEdit;
