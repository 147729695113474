import { Col, Checkbox, DatePicker, Form, Input, Row, Popconfirm, Modal } from 'antd';
import React, { useState } from 'react';
import { DataEdit } from '../../../components/DataEdit';
import { FileUpload } from '../../../components/fileUpload/FileUpload';
import { InputByLanguage, translationsToObject } from '../../../components/InputByLanguage';
import { CONSTANTS_FILE_TYPE, CONSTANTS_REQ } from '../../../utils/Constants';
import { geti18nText, Session, hasAnyRole } from '../../../utils/Session';
import moment from 'moment';
import { requestGet, RESPONSE, requestPost } from '../../../utils/RequestResolver';

const BulletinEdit = Form.create({ name: 'BulletinEdit' })((props) => {
    const { getFieldDecorator,getFieldValue, setFieldsValue } = props.form;
    const [editHeader, setEditHeader] = useState(geti18nText('bulletin.edit.new'));
    const [loading, setLoading] = useState(false);
    const [disabledTree, setDisabledTree] = useState(true);
    const [bulletin, setBulletin] = useState(null);
    const [contextTextLangJson, setContextTextLangJson] = useState(null);
    const [existForFirstPage, setExistForFirstPage] = useState(false);

    const { confirm } = Modal;

    function setValues(bulletin) {
        bulletin = translationsToObject(bulletin, 'contentText', bulletin.contentText);
        bulletin = translationsToObject(bulletin, 'name', bulletin.name);
        if (bulletin.imagePath != null) {
            const imagePath = {
                file_path: bulletin.imagePath,
                name: bulletin.imagePath.substring(bulletin.imagePath.lastIndexOf('/') + 1, bulletin.imagePath.length),
            };
            bulletin.imagePath = imagePath;
        }
        setContextTextLangJson(bulletin.name);
        setContextTextLangJson(bulletin.contentText);
        bulletin.dateFrom = moment(bulletin.dateFrom);
        if (bulletin.dateTo) {
            bulletin.dateTo = moment(bulletin.dateTo);
        }
        setEditHeader(bulletin.id);
        setBulletin(bulletin.id);
        delete bulletin.active;
        delete bulletin.contentText;
        delete bulletin.name;
        delete bulletin.created;

        setFieldsValue(bulletin);
    }

    function disabledDateFrom(value) {
        const { form } = props;
        var dateTo = form.getFieldValue('dateTo') ? moment(form.getFieldValue('dateTo')).valueOf() : null;
        if (dateTo == null) {
            return false;
        }
        return value > dateTo;
    }

    function disabledDateTo(value) {
        const { form } = props;
        var dateFrom = form.getFieldValue('dateFrom') ? moment(form.getFieldValue('dateFrom')).valueOf() : null;
        if (dateFrom == null) {
            return false;
        }
        return value < dateFrom;
    }

    function setDefaultValues() {
        setContextTextLangJson(null);
    }

    function dummy(value) {
    }

    function onDateFromChange(date, dateString) {
        if(getFieldValue('forFirstPage')===true) {
            let dateFrom=date.clone();
            setFieldsValue({'dateTo':dateFrom.add(30, 'days')});
        }
    }

    function onChangeForFirstPage(e){
        if(e.target.checked) {
            setLoading(true);
            requestGet(CONSTANTS_REQ.BULLETIN.FIRSTPAGE+ '/' +parseInt(props.addedData.webPageId), ).then((result) => {
                setLoading(false);
                if (result.status == RESPONSE.OK) {
                    showConfirm();
                }
            });
            
        }
    }

    function onClickConfirm () {
        setExistForFirstPage(false);
    }

    function onCancel() {
        setFieldsValue({forFirstPage:false});
        setExistForFirstPage(false);
    }

    function showConfirm() {
        confirm({
          title: geti18nText('bullettin.edit.first.page.exist'),
          onOk() {
            onClickConfirm();
          },
          onCancel() {
            onCancel();
          },
        });
      }

    return (
        <>
            <DataEdit
                editHeader={editHeader}
                loading={loading}
                setLoading={setLoading}
                setDefaultValues={setDefaultValues}
                url={CONSTANTS_REQ.BULLETIN.EDIT}
                setValues={setValues}
                width={1200}
                {...props}
                normalize={(values) => {
                    return {
                        ...values,
                        imagePath:
                            values.imagePath != null && values.imagePath instanceof Object
                                ? values.imagePath.file_path
                                : values.imagePath,
                        webPage: { id: parseInt(props.addedData.webPageId) },
                    };
                }}
            >
                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item hidden={true}>{getFieldDecorator('id')(<Input hidden={true} />)}</Form.Item>
                        <InputByLanguage
                            fieldName="name"
                            langJson={contextTextLangJson}
                            label={geti18nText('bulletin.edit.name')}
                            {...props}
                        />
                        <InputByLanguage
                            fieldName="contentText"
                            notRequred
                            langJson={contextTextLangJson}
                            label={geti18nText('bulletin.edit.content_text')}
                            {...props}
                            type="textArea"
                        />
                        <Form.Item hidden={true}>{getFieldDecorator('webPage')(<Input hidden={true} />)}</Form.Item>
                    </Col>
                    <Col span={9}>
                        <Form.Item label={geti18nText('bulletin.edit.date_from')}>
                            {getFieldDecorator('dateFrom', {
                                initialValue: moment(),
                                rules: [{ required: true, message: geti18nText('app.default.required') }],
                            })(<DatePicker format="DD.MM.YYYY" disabledDate={disabledDateFrom} onChange={(date, dateString) => onDateFromChange(date, dateString)} />)}
                        </Form.Item>
                    </Col>
                    <Col span={9}>
                        <Form.Item label={geti18nText('bulletin.edit.date_to')}>
                            {getFieldDecorator('dateTo', { initialValue: moment().add(30, 'days') })(
                                <DatePicker format="DD.MM.YYYY" disabledDate={disabledDateTo} />
                            )}
                        </Form.Item>
                    </Col>                    
                    <Col span={6} style={{paddingTop:"35px"}}>
                            
                                <Form.Item>
                            {getFieldDecorator('forFirstPage', {
                                valuePropName: 'checked',
                                initialValue: false ,
                            })(
                            <Checkbox onChange={onChangeForFirstPage}>{geti18nText('bulletin.edit.forfirstpage')}</Checkbox>
                            )}
                        </Form.Item>
                           
                        
                    </Col>
                    <Col span={18}>
                        <Form.Item label={geti18nText('bulletin.edit.image')}>
                            {getFieldDecorator('imagePath')(
                                <FileUpload
                                    fileType={CONSTANTS_FILE_TYPE.IMAGE}
                                    map={{ id: 'id', label: 'name' }}
                                    searchBy="name"
                                    disabled={hasAnyRole(['ROLE_LOCAL_USER'])?disabledTree : false}
                                    menuUploadChange={dummy}
                                    addedData={{ projectId: Session.getProjectId() }}
                                />
                            )}
                        </Form.Item>
                    </Col>
                </Row>
            </DataEdit>
        </>
    );
});

export default BulletinEdit;
