import {
    Form,Button, Tabs,Popconfirm, Input,Col,Row,Modal
} from 'antd';
import React, { useEffect, useState, useImperativeHandle, forwardRef } from 'react';
import { FileUpload } from '../../../../components/fileUpload/FileUpload';
import { InputByLanguage } from '../../../../components/InputByLanguage';
import { CONSTANTS_FILE_TYPE } from '../../../../utils/Constants';
import { geti18nText, Session } from '../../../../utils/Session';
import DeseignEditor from '../../../../components/deseign-editor';

const TouristFooterSettings = forwardRef((props,ref) => {

    const [mapZoomText, setMapZoomText] = useState({
        settingKey: 'map.zoomText',
        settingValue: {},
        settingGroup: 'map',
    });
    const { getFieldDecorator, setFieldsValue, getFieldValue, getFieldsValue } = props.form;
    const [activeKey, setActiveKey] = useState(null);
    const [panes, setPanes] = useState([]);
    const [newTabIndex, setNewTabIndex] = useState(0);
    const [confirmVisible, setConfirmVisible] = useState(false);
    const [targetDelKey, setTargetDelKey] = useState(undefined);
    const [formValues, setFormValues] = useState(undefined);
    const [designVisible, setDesignVisible] = useState(false);

    const { TabPane } = Tabs;

    useEffect(()=>{
        if(props.iconFiles && props.iconFiles.settingValue){
            if(Object.keys( props.iconFiles.settingValue).length >0) {
                let icons=JSON.parse(props.iconFiles.settingValue);
                let values={};
                
                let tmpPanes  = panes;
                icons.forEach(function(item, index) {
                    if(item.image || item.url || item.text) {
                        //addTabs();
                        index++;
                        let tmpIndex=index;
                        
                        if(item.image) {
                            values["iconLogo_"+`icon${tmpIndex}`]=item.image;
                        }
                        if(item.url) {
                            values["iconUrl_"+`icon${tmpIndex}`]=item.url;
                        }
                        if(item.text) {
                            values["iconHoverText_"+`icon${tmpIndex}`]=item.text;
                        }
                        const active = `icon${tmpIndex}`;
                        tmpPanes.push({ title: geti18nText('settings.footer.add.icon.label')+' '+tmpIndex, key: active });
                        setNewTabIndex(tmpIndex);
                        
                    }
                    
                });
                setActiveKey(`icon1`);
                setPanes(tmpPanes);
                setFormValues(values);
            }
        }
    },[props.iconFiles])

    useEffect(() => {
        if(formValues) {
            //props.form.resetFields();
            props.form.setFieldsValue(formValues);
        }
    }, [formValues]);

    
    const onChange = activeKey => {
        setActiveKey(activeKey);
      };
    
    const  onEdit = (targetKey, action) => {
        setTargetDelKey(targetKey)
        if (action == 'remove') {
            setConfirmVisible(true);
            
        }
      };

      const deleteFile = () => {
            remove(targetDelKey);
            setConfirmVisible(false);
            
    }

    const DesignModal = (props) => {
        function closePopup() {
            props.setVisible(false);
        }

        return (
            <>
                <Modal
                    zIndex={1100}
                    visible={props.visible}
                    onCancel={closePopup}
                    width={1200}
                    destroyOnClose={true}
                    footer={[]}
                >
                    <DeseignEditor
                        id={props.id}
                        pageComponentId={props.pageComponentId}
                        projectId={Session.getProjectId()}
                        type={'component'}
                        closePopup={closePopup}
                        componentName={props.name}
                    ></DeseignEditor>
                </Modal>
            </>
        );
    };

    useImperativeHandle(ref, () => ({
        exportValue() {
            let files= [];
            let values=getFieldsValue();
            for (const icon of panes) {
                let tmpIcon={};
                if(values["iconLogo_"+icon.key]){
                    tmpIcon.image=normalizeImage(values["iconLogo_"+icon.key])
                }
                if(values["iconUrl_"+icon.key]){
                    tmpIcon.url=values["iconUrl_"+icon.key]
                }
                if(values["iconHoverText_"+icon.key]){
                    tmpIcon.text=values["iconHoverText_"+icon.key]
                }
                if(Object.keys (tmpIcon).length > 0 ){
                    files.push(tmpIcon);
                }
            }
            if(files.length>0) {
                props.iconFiles.settingValue = JSON.stringify(files);
                props.setIconFiles(props.iconFiles);
            } else {
                props.iconFiles.settingValue = null;
                props.setIconFiles(props.iconFiles);
            }
        },
    }));


    function normalizeImage(value) {
        let normalized={};
        if(value["file_path"]) {
            normalized["file_path"]=value["file_path"];
        }
        if(value["name"]) {
            normalized["name"]=value["name"];
        }
        return normalized;

    }
    
    const  add = () => {
        let tmpPanes  = panes;
        let tmpIndex=newTabIndex;
        tmpIndex++;
        setNewTabIndex(tmpIndex);
        const active = `icon${tmpIndex}`;
        tmpPanes.push({ title: geti18nText('settings.footer.add.icon.label')+' '+tmpIndex, key: active });
        
        setPanes(tmpPanes);
        setActiveKey(active);
      };
    
    const  remove = targetKey => {
        let tmpActiveKey = activeKey;
        let lastIndex;
        panes.forEach((pane, i) => {
          if (pane.key === targetKey) {
            lastIndex = i - 1;
          }
        });
        const tmpPanes = panes.filter(pane => pane.key !== targetKey);
        if (tmpPanes.length && tmpActiveKey === targetKey) {
          if (lastIndex >= 0) {
            tmpActiveKey = tmpPanes[lastIndex].key;
          } else {
            tmpActiveKey = tmpPanes[0].key;
          }
        }

        tmpPanes.forEach((pane, i) => {
            i++;
           pane.title=geti18nText('settings.footer.add.icon.label')+' '+i;
          });
        setPanes(tmpPanes);
        setActiveKey(tmpActiveKey);
    };
    

return (
            <React.Fragment>
                
                     
                        <Form.Item style={{ width: '50%' }} label={geti18nText('settings.footer.logo')}>
                            {getFieldDecorator('footLogo')(
                                <FileUpload
                                    fileType={CONSTANTS_FILE_TYPE.IMAGE}
                                    map={{ id: 'id', label: 'name' }}
                                    searchBy="name"
                                    disabled={false}
                                    addedData={{
                                        projectId: Session.getProjectId(),
                                        fileType: CONSTANTS_FILE_TYPE.IMAGE,
                                    }}
                                />
                            )}
                        </Form.Item>
                        {   <Form.Item style={{ width: "50%" }}>
                                <InputByLanguage
                                    fieldName={'footTextWeb'}
                                    label={geti18nText('settings.footer.text.title.web')}
                                    notRequred
                                    {...props}
                                />
                            </Form.Item>
                        }
                        {   <Form.Item style={{ width: "50%" }}>
                                <InputByLanguage
                                    fieldName={'footTextKiosk'}
                                    label={geti18nText('settings.footer.text.title.kiosk')}
                                    notRequred
                                    {...props}
                                />
                            </Form.Item>
                        }
                     <div>
                    
                        <div style={{ marginBottom: 16 }}>
                            <Row>
                                <Col span={2}>
                                    <Button type="primary" onClick={add}>{geti18nText('settings.footer.add.icon')}</Button>
                                </Col>
                                <Col hidden={props.cssHiddenDesign === true} span={2}>
                                    <Button
                                        onClick={(arg) => {
                                            setDesignVisible(true);
                                        }}
                                        icon="bg-colors"
                                        style={{backgroundColor:'orange'}}
                                    ></Button>
                                </Col>
                            </Row>
                        </div>
                        <Popconfirm
                            placement="rightTop"
                            title={geti18nText('app.default.button.delete')}
                            visible={confirmVisible}
                            okText={geti18nText('app.default.button.yes')}
                            cancelText={geti18nText('app.default.button.no')}
                            onCancel={() => {
                                setConfirmVisible(false);
                            }}
                            onConfirm={deleteFile}
                        >
                            <span></span>
                        </Popconfirm>
                        <Tabs
                            hideAdd
                            onChange={onChange}
                            activeKey={activeKey}
                            type="editable-card"
                            onEdit={onEdit}
                        >
                             
                        {panes.map(pane => (
                            <TabPane tab={pane.title} key={pane.key} forceRender={true}>
                                <TabContent index={pane.key} {...props}/>
                            </TabPane>
                        ))}
                        </Tabs>
                    </div>
                   
                
                <DesignModal
                    pageComponentId={Session.getAppValue('COMPONENTS').filter(v=>v.name === "touristFooter")[0].id}
                    visible={designVisible}
                    setVisible={setDesignVisible}
                    name={'touristFooter'}
                ></DesignModal>
                    
            </React.Fragment>
)

});

const TabContent = (props) => {


    const { getFieldDecorator, setFieldsValue } = props.form;
        return(
            <div>
                <Form.Item style={{ width: '50%' }} label={geti18nText('settings.footer.add.icon.label.image')}>
                        {getFieldDecorator('iconLogo_'+props.index)(
                            <FileUpload
                                fileType={CONSTANTS_FILE_TYPE.IMAGE}
                                map={{ id: 'id', label: 'name' }}
                                searchBy="name"
                                disabled={false}
                                addedData={{
                                    projectId: Session.getProjectId(),
                                    fileType: CONSTANTS_FILE_TYPE.IMAGE,
                                }}
                            />
                        )}
                </Form.Item>
                <Form.Item style={{ width: '50%' }} label={geti18nText('settings.footer.add.icon.label.url')}>
                    {getFieldDecorator('iconUrl_'+props.index)(
                        <Input/>
                    )}
                </Form.Item>
                <Form.Item style={{ width: '50%' }} label={geti18nText('settings.footer.add.icon.label.hover.text')}>
                    {getFieldDecorator('iconHoverText_'+props.index)(
                        <Input/>
                    )}
                </Form.Item>
            </div>
        )
  }

export default TouristFooterSettings;