import React, { useState, useEffect } from 'react';
import { requestGet, RESPONSE, requestPost } from '../../../../utils/RequestResolver';
import { CONSTANTS_REQ, CONSTANTS_FILE_TYPE } from '../../../../utils/Constants';
import { Session, geti18nText } from '../../../../utils/Session';
import { message, Row, Divider, Button, Form, Modal, Input, Col, Tabs, Popconfirm, Collapse } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { FileUpload } from '../../../../components/fileUpload/FileUpload';
import { normalizeComponents } from '../../../../utils/Utils';
import { InputByLanguage, translationsToObject } from '../../../../components/InputByLanguage';
import { ColorPicker } from '../../../../components/ColorPicker';
const ClsmSettings = Form.create({ name: 'Settings' })((props) => {
    const { getFieldDecorator, setFieldsValue } = props.form;
    const [footerModalVisible, setFooterModalVisible] = useState(false);
    const [textJsonLink, setTextJsonLink] = useState(null);
    const [activeTab, setActiveTab] = useState('0');
    const [activeTabLinks, setActiveTabLinks] = useState('0');
    const [lastKey, setLastKey] = useState(0);
    const [lastKeyLinks, setLastKeyLinks] = useState(0);
    const [blockCompany, setBlockCompany] = useState([]);
    const [links, setLinks] = useState([]);
    const [deleteBlockCompanyVisible, setDeleteBlockCompanyVisible] = useState(false);
    const [deleteLinksVisible, setDeleteLinksVisible] = useState(false);
    const [companyToDelete, setCompanyToDelete] = useState(null);
    const [linksToDelete, setLinksToDelete] = useState(null);
    const [deletedCompanies, setDeletedCompanies] = useState([]);
    const [deletedLinks, setDeletedLinks] = useState([]);
    const [linksKeys, setLinksKeys] = useState([0]);
    const [companyKeys, setCompanyKeys] = useState([0]);
    const [formValues, setFormValues] = useState(undefined);
    const [hoverColor, setHoverColor] = useState('#ff7f50');

    const { TabPane } = Tabs;
    const { Panel } = Collapse;

    const [navLogo, setNavLogo] = useState({
        settingKey: 'nav.logo',
        settingValue: '',
        settingGroup: 'nav',
    });

    const [favoriteIcon, setFavoriteIcon] = useState({
        settingKey: 'favoriteIcon',
        settingValue: {},
        settingGroup: 'icon',
    });

    const [footerSettings, setFooterSettings] = useState({
        settingKey: 'footer.text',
        settingValue: {},
        settingGroup: 'footer',
    });

    useEffect(() => {
        getNavLogo();
        getFavoriteIcon();
        setBlockCompany([{ id: 0 }]);
        setCompanyKeys([0]);
        setLinks([{ id: 0 }]);
        setLinksKeys([0]);
    }, []);

    useEffect(() => {
        //props.form.resetFields();
        setFieldsValue(formValues);
    }, [formValues]);

    async function getNavLogo() {
        const resp = await requestGet(CONSTANTS_REQ.SETTINGS.ADMIN, {
            group: 'nav',
            key: 'nav.logo',
            projectId: Session.getProjectId(),
        });
        if (resp.status === RESPONSE.OK) {
            setNavLogo(resp.data);
            if (resp.data.settingValue != null) {
                props.form.setFieldsValue({ navLogo: JSON.parse(resp.data.settingValue) });
            }
        }
    }

    async function getFavoriteIcon() {
        const resp = await requestGet(CONSTANTS_REQ.SETTINGS.ADMIN, {
            group: 'icon',
            key: 'favoriteIcon',
            projectId: Session.getProjectId(),
        });
        if (resp.status === RESPONSE.OK) {
            setFavoriteIcon(resp.data);
            if (resp.data.settingValue != null) {
                props.form.setFieldsValue({ favIcon: JSON.parse(resp.data.settingValue) });
            }
        }
    }

    async function getFooterSettings() {
        const resp = await requestGet(CONSTANTS_REQ.SETTINGS.ADMIN, {
            group: 'footer',
            key: 'footer.text',
            projectId: Session.getProjectId(),
        });
        if (resp.status === RESPONSE.OK) {
            setFooterSettings(resp.data);
            if (resp.data.settingValue != null) {
                let jsonData = JSON.parse(resp.data.settingValue);
                let json = {};
                json['title'] = jsonData.title;
                json['bottom_text'] = jsonData.bottom_text;
                json['facebook'] = jsonData.facebook;
                json['linkedin'] = jsonData.linkedin;
                json['instagram'] = jsonData.instagram;
                json['youtube'] = jsonData.youtube;
                let linksArrKeys = [];
                let linksArr = [];

                jsonData.links.forEach((value) => {
                    linksArrKeys.push(value.id);
                    linksArr.push({ id: value.id, textJsonLink: value.title });
                    json = translationsToObject(jsonData, 'link_title' + value.id, value.title);
                    json['url_link' + value.id] = value.link;
                    setLastKeyLinks(value.id);
                });
                setLinksKeys(linksArrKeys);
                setLinks(linksArr);
                setActiveTabLinks('' + linksArrKeys[0]);

                if (jsonData.hoverColor) {
                    setHoverColor(jsonData.hoverColor);
                    json['hoverColor'] = jsonData.hoverColor;
                } else {
                    json['hoverColor'] = hoverColor;
                }

                let companyBlockArrKeys = [];
                let companyBlockArr = [];
                jsonData.block_company.forEach((value) => {
                    companyBlockArrKeys.push(value.id);
                    companyBlockArr.push({ id: value.id });
                    json['companyName' + value.id] = value.companyName;
                    json['company_link' + value.id] = value.company_link;
                    json['address' + value.id] = value.address;
                    json['postCode' + value.id] = value.postCode;
                    json['mail' + value.id] = value.mail;
                    json['phones' + value.id] = value.phones;
                    json['fax' + value.id] = value.fax;
                    setLastKey(value.id);
                });
                setCompanyKeys(companyBlockArrKeys);
                setBlockCompany(companyBlockArr);
                setActiveTab('' + companyBlockArrKeys[0]);
                setFormValues(json);
            }
        }
    }

    function normalizeFileUpload(field) {
        let file = props.form.getFieldsValue([field])[field];
        if (file instanceof Array) {
            file = file[0];
        }
        return file.id ? file : { file_path: file.file_path, name: file.name };
    }

    const save = (e) => {
        e.preventDefault();
        let formValues = null;
        let nLogo = navLogo;
        let fIcon = favoriteIcon;

        props.form.validateFieldsAndScroll((err, values) => {
            formValues = normalizeComponents(values);
            if (values.navLogo) {
                values.navLogo = JSON.stringify(normalizeFileUpload('navLogo'));
            }
            if (values.favIcon) {
                values.favIcon = JSON.stringify(normalizeFileUpload('favIcon'));
            }

            nLogo.settingValue = values.navLogo;
            fIcon.settingValue = values.favIcon;
        });

        const settingsArray = [nLogo, fIcon];

        requestPost(CONSTANTS_REQ.SETTINGS.SAVE_SETTINGS, null, { settings: settingsArray }).then((resp) => {
            if (resp.status == RESPONSE.OK) {
                message.success(geti18nText('settings.save.success'));
            } else {
                message.error(geti18nText('settings.save.not'));
            }
        });
    };

    const onFooterModalCancel = () => {
        setFooterModalVisible(false);
    };

    const footerModalShow = () => {
        getFooterSettings();
        setFooterModalVisible(true);
    };

    const onFooterModalSave = () => {
        setFooterModalVisible(false);
        props.form.validateFieldsAndScroll((err, values) => {
            values = normalizeComponents(values);
            let sendJson = {};
            sendJson.title = values.title;
            if (values.footer_image) sendJson.footer_image = normalizeFileUpload('footer_image');
            sendJson.bottom_text = values.bottom_text;
            sendJson.facebook = values.facebook;
            sendJson.linkedin = values.linkedin;
            sendJson.instagram = values.instagram;
            sendJson.youtube = values.youtube;
            sendJson.hoverColor = values.hoverColor;

            let companyBlockArr = [];
            blockCompany.forEach((value, index) => {
                companyBlockArr.push({
                    id: value.id,
                    companyName: values['companyName' + value.id],
                    company_link: values['company_link' + value.id],
                    address: values['address' + value.id],
                    postCode: values['postCode' + value.id],
                    mail: values['mail' + value.id],
                    phones: values['phones' + value.id],
                    fax: values['fax' + value.id],
                });
            });

            let linksArr = [];
            links.forEach((value, index) => {
                linksArr.push({
                    id: value.id,
                    title: values['link_title' + value.id],
                    link: values['url_link' + value.id],
                });
            });

            sendJson.links = linksArr;
            sendJson.block_company = companyBlockArr;
            let footerSet = footerSettings;
            footerSet.settingValue = JSON.stringify(sendJson);
            if (!footerSettings.project) {
                footerSet.project = { id: Session.getProjectId() };
            }
            const settingsArray = [footerSet];
            requestPost(CONSTANTS_REQ.SETTINGS.SAVE_SETTINGS, null, { settings: settingsArray }).then((resp) => {
                if (resp.status == RESPONSE.OK) {
                    message.success(geti18nText('settings.save.success'));
                } else {
                    message.error(geti18nText('settings.save.not'));
                }
            });
        });
    };

    const tabOnChange = (activeKey) => {
        props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                setActiveTab(activeKey);
            }
        });
    };

    const tabOnChangeLinks = (activeKey) => {
        props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                setActiveTabLinks(activeKey);
            }
        });
    };

    const onEdit = (targetKey, action) => {
        if (action == 'add') {
            props.form.validateFieldsAndScroll((err, values) => {
                if (!err) {
                    let newId = lastKey;
                    newId++;
                    let arrBlockCompanies = blockCompany.slice();
                    let arrKeys = companyKeys.slice();
                    arrKeys.push(newId);
                    setCompanyKeys(arrKeys);
                    arrBlockCompanies.push({ id: newId });
                    setBlockCompany(arrBlockCompanies);
                    setActiveTab('' + newId);
                    setLastKey(newId);
                }
            });
        }
        if (action == 'remove') {
            if (companyKeys.length > 1) {
                setDeleteBlockCompanyVisible(true);
                setCompanyToDelete(targetKey);
            }
        }
    };

    const onEditLinks = (targetKey, action) => {
        if (action == 'add') {
            props.form.validateFieldsAndScroll((err, values) => {
                if (!err) {
                    let newId = lastKeyLinks;
                    newId++;
                    let arrLinks = links.slice();
                    let arrKeys = linksKeys.slice();
                    arrKeys.push(newId);
                    setLinksKeys(arrKeys);
                    arrLinks.push({ id: newId });
                    setLinks(arrLinks);
                    setActiveTabLinks('' + newId);
                    setLastKeyLinks(newId);
                }
            });
        }
        if (action == 'remove') {
            if (linksKeys.length > 1) {
                setDeleteLinksVisible(true);
                setLinksToDelete(targetKey);
            }
        }
    };

    const deleteBlockCompany = () => {
        if (companyToDelete) {
            var newArrKeys = companyKeys.filter((key) => key != parseInt(companyToDelete));
            var newArrCompanies = blockCompany.filter((company) => company.id != parseInt(companyToDelete));
            setCompanyKeys(newArrKeys);
            setBlockCompany(newArrCompanies);

            if (activeTab == companyToDelete) {
                setActiveTab('' + newArrKeys[0]);
            }
            setDeleteBlockCompanyVisible(false);
            setCompanyToDelete(null);
            let deleted = deletedCompanies.slice();
            deleted.push(companyToDelete);
            setDeletedCompanies(deleted);
        }
    };

    const deleteLinks = () => {
        if (linksToDelete) {
            var newArrKeys = linksKeys.filter((key) => key != parseInt(linksToDelete));
            var newArrLinks = links.filter((link) => link.id != parseInt(linksToDelete));
            setLinksKeys(newArrKeys);
            setLinks(newArrLinks);

            if (activeTabLinks == linksToDelete) {
                setActiveTabLinks('' + newArrKeys[0]);
            }
            setDeleteLinksVisible(false);
            setLinksToDelete(null);
            let deleted = deletedLinks.slice();
            deleted.push(linksToDelete);
            setDeletedLinks(deleted);
        }
    };

    function getColor(object) {
        if (object && object.settingValue && object.settingValue != '') {
            let o = JSON.parse(object.settingValue);
            return o && o.color ? o.color : null;
        }
        return null;
    }

    return (
        <React.Fragment>
            <Row type="flex" className="margin-bottom">
                <Divider orientation="left" style={{ fontSize: '18px' }}>
                    {geti18nText('settings.title')}
                </Divider>
            </Row>

            <Form>
                <Form.Item style={{ display: 'flex' }} label={geti18nText('settings.footer')}>
                    {getFieldDecorator('footer')(
                        <Button onClick={(arg) => footerModalShow()} icon="setting" type="primary"></Button>
                    )}
                </Form.Item>
                <Form.Item style={{ width: '30%' }} label={geti18nText('settings.nav.logo')}>
                    {getFieldDecorator('navLogo')(
                        <FileUpload
                            fileType={CONSTANTS_FILE_TYPE.IMAGE}
                            map={{ id: 'id', label: 'name' }}
                            searchBy="name"
                            disabled={false}
                            addedData={{ projectId: Session.getProjectId(), fileType: CONSTANTS_FILE_TYPE.IMAGE }}
                        />
                    )}
                </Form.Item>
                <Form.Item style={{ width: '30%' }} label={geti18nText('settings.favorite.icon')}>
                    {getFieldDecorator('favIcon')(
                        <FileUpload
                            fileType={CONSTANTS_FILE_TYPE.IMAGE}
                            map={{ id: 'id', label: 'name' }}
                            searchBy="name"
                            disabled={false}
                            addedData={{ projectId: Session.getProjectId(), fileType: CONSTANTS_FILE_TYPE.IMAGE }}
                        />
                    )}
                </Form.Item>
                <Form.Item>
                    <Button
                        type="primary"
                        onClick={(e) => {
                            save(e);
                        }}
                    >
                        {geti18nText('projectManagement.edit.save')}
                    </Button>
                </Form.Item>
            </Form>
            <Modal
                visible={footerModalVisible}
                onCancel={onFooterModalCancel}
                onSave={onFooterModalSave}
                destroyOnClose={true}
                width={900}
                title={geti18nText('settings.footer')}
                footer={[
                    <Button key="back" onClick={(e) => onFooterModalCancel()}>
                        {geti18nText('app.default.button.cancel')}
                    </Button>,
                    <Button key="save" type="primary" onClick={(arg) => onFooterModalSave()}>
                        {geti18nText('app.default.button.save')}
                    </Button>,
                ]}
            >
                <Row gutter={24}>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item label={geti18nText('settings.footer.edit.title')}>
                                {getFieldDecorator('title', {
                                    rules: [{ required: true, message: geti18nText('app.default.required') }],
                                })(<TextArea />)}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label={geti18nText('settings.footer.edit.bottom.text')}>
                                {getFieldDecorator('bottom_text', {
                                    rules: [{ required: true, message: geti18nText('app.default.required') }],
                                })(<TextArea />)}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Form.Item label={geti18nText('settings.footer.edit.image')}>
                                {getFieldDecorator(
                                    'footer_image',
                                    {}
                                )(
                                    <FileUpload
                                        fileType={CONSTANTS_FILE_TYPE.IMAGE}
                                        map={{ id: 'id', label: 'name' }}
                                        searchBy="name"
                                        disabled={false}
                                        addedData={{
                                            projectId: Session.getProjectId(),
                                            fileType: CONSTANTS_FILE_TYPE.IMAGE,
                                        }}
                                    />
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Collapse bordered={false} defaultActiveKey={['1', '2']}>
                        <Panel header={geti18nText('settings.footer.edit.contact')} key="1">
                            <Row>
                                <Col span={24}>
                                    <Popconfirm
                                        placement="rightTop"
                                        title={geti18nText('app.default.button.delete')}
                                        visible={deleteBlockCompanyVisible}
                                        okText={geti18nText('app.default.button.yes')}
                                        cancelText={geti18nText('app.default.button.no')}
                                        onCancel={() => {
                                            setDeleteBlockCompanyVisible(false);
                                        }}
                                        onConfirm={deleteBlockCompany}
                                    >
                                        <span></span>
                                    </Popconfirm>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <Tabs
                                        type="editable-card"
                                        onEdit={onEdit}
                                        onChange={tabOnChange}
                                        activeKey={activeTab}
                                    >
                                        {blockCompany &&
                                            blockCompany.map((val, index) => (
                                                <TabPane
                                                    tab={
                                                        geti18nText('settings.footer.edit.contact') +
                                                        (' ' + (index + 1))
                                                    }
                                                    key={val.id}
                                                    forceRender={true}
                                                >
                                                    <Row>
                                                        <Row gutter={24}>
                                                            <Col span={12}>
                                                                <Form.Item
                                                                    label={geti18nText(
                                                                        'settings.footer.edit.company.name'
                                                                    )}
                                                                >
                                                                    {getFieldDecorator('companyName' + val.id)(
                                                                        <TextArea />
                                                                    )}
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={12}>
                                                                <Form.Item
                                                                    label={geti18nText(
                                                                        'settings.footer.edit.title.link'
                                                                    )}
                                                                >
                                                                    {getFieldDecorator('company_link' + val.id)(
                                                                        <TextArea />
                                                                    )}
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                        <Row gutter={24}>
                                                            <Col span={12}>
                                                                <Form.Item
                                                                    label={geti18nText(
                                                                        'settings.footer.edit.title.address'
                                                                    )}
                                                                >
                                                                    {getFieldDecorator('address' + val.id)(
                                                                        <TextArea />
                                                                    )}
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={12}>
                                                                <Form.Item
                                                                    label={geti18nText(
                                                                        'settings.footer.edit.title.postcode'
                                                                    )}
                                                                >
                                                                    {getFieldDecorator('postCode' + val.id)(
                                                                        <TextArea />
                                                                    )}
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                        <Row gutter={24}>
                                                            <Col span={12}>
                                                                <Form.Item
                                                                    label={geti18nText(
                                                                        'settings.footer.edit.title.phones'
                                                                    )}
                                                                >
                                                                    {getFieldDecorator('phones' + val.id)(<TextArea />)}
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={12}>
                                                                <Form.Item
                                                                    label={geti18nText(
                                                                        'settings.footer.edit.title.fax'
                                                                    )}
                                                                >
                                                                    {getFieldDecorator('fax' + val.id)(<TextArea />)}
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                        <Row gutter={24}>
                                                            <Col span={12}>
                                                                <Form.Item
                                                                    label={geti18nText(
                                                                        'settings.footer.edit.title.mail'
                                                                    )}
                                                                >
                                                                    {getFieldDecorator('mail' + val.id)(<Input />)}
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                    </Row>
                                                </TabPane>
                                            ))}
                                    </Tabs>
                                </Col>
                            </Row>
                        </Panel>
                        <Panel header={geti18nText('settings.footer.edit.links')} key="2">
                            <Row>
                                <Col span={24}>
                                    <Popconfirm
                                        placement="rightTop"
                                        title={geti18nText('app.default.button.delete')}
                                        visible={deleteLinksVisible}
                                        okText={geti18nText('app.default.button.yes')}
                                        cancelText={geti18nText('app.default.button.no')}
                                        onCancel={() => {
                                            setDeleteLinksVisible(false);
                                        }}
                                        onConfirm={deleteLinks}
                                    >
                                        <span></span>
                                    </Popconfirm>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <Tabs
                                        type="editable-card"
                                        onEdit={onEditLinks}
                                        onChange={tabOnChangeLinks}
                                        activeKey={activeTabLinks}
                                    >
                                        {links &&
                                            links.map((val, index) => (
                                                <TabPane
                                                    tab={
                                                        geti18nText('settings.footer.edit.title.link') +
                                                        (' ' + (index + 1))
                                                    }
                                                    key={val.id}
                                                    forceRender={true}
                                                >
                                                    <Row gutter={24}>
                                                        <Col span={12}>
                                                            <InputByLanguage
                                                                notRequred
                                                                fieldName={'link_title' + val.id}
                                                                label={geti18nText('settings.footer.edit.links.title')}
                                                                langJson={val.textJsonLink}
                                                                {...props}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <Row gutter={24}>
                                                        <Col span={12}>
                                                            <Form.Item
                                                                label={geti18nText('settings.footer.edit.links.link')}
                                                            >
                                                                {getFieldDecorator('url_link' + val.id)(<TextArea />)}
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>
                                                </TabPane>
                                            ))}
                                    </Tabs>
                                </Col>
                            </Row>
                        </Panel>
                        <Panel header={geti18nText('settings.footer.edit.social.network')} key="3">
                            <Row>
                                <Row gutter={24}>
                                    <Col span={12}>
                                        <Form.Item label={geti18nText('settings.footer.edit.social.network.facebook')}>
                                            {getFieldDecorator('facebook')(<TextArea />)}
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item label={geti18nText('settings.footer.edit.social.network.linkedin')}>
                                            {getFieldDecorator('linkedin')(<TextArea />)}
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={24}>
                                    <Col span={12}>
                                        <Form.Item label={geti18nText('settings.footer.edit.social.network.instagram')}>
                                            {getFieldDecorator('instagram')(<TextArea />)}
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item label={geti18nText('settings.footer.edit.social.network.youtube')}>
                                            {getFieldDecorator('youtube')(<TextArea />)}
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Row>
                        </Panel>
                        <Panel header={geti18nText('settings.deseign')} key="4">
                            <Row gutter={8}>
                                <Form.Item label={geti18nText('settings.deseign.hover.color')}>
                                    <Row gutter={8}>
                                        <Col span={4}>{getFieldDecorator('hoverColor')(<Input></Input>)}</Col>
                                        <Col span={2}>
                                            <ColorPicker
                                                value={hoverColor}
                                                onChange={(value) => {
                                                    props.form.setFieldsValue({ hoverColor: value });
                                                    setHoverColor(value);
                                                }}
                                            ></ColorPicker>
                                        </Col>
                                    </Row>
                                </Form.Item>
                            </Row>
                        </Panel>
                    </Collapse>
                </Row>
            </Modal>
        </React.Fragment>
    );
});

export default ClsmSettings;
