import React, { useEffect, useState } from 'react';
import { Form, Button, Input, Modal, Row, Col } from 'antd';
import { geti18nText } from '../../utils/Session';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import { Map, TileLayer, Marker } from 'react-leaflet';

const blueIcon = new L.Icon({
    iconUrl: 'https://cdn.rawgit.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-blue.png',
    shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
});

const InputMarkerCoordinates = (props) => {
    const [modalVisible, setModalVisible] = useState(false);
    const [lat, setLat] = useState(undefined);
    const [lng, setLng] = useState(undefined);

    const { getFieldDecorator, setFieldsValue } = props.form;

    useEffect(() => {
        setLat(props.form.getFieldValue('latitude'));
        setLng(props.form.getFieldValue('longitude'));
    }, [modalVisible]);

    const MyMarker = (props) => {
        const initMarker = (ref) => {
            if (ref) {
                ref.leafletElement.openPopup();
            }
        };

        return <Marker icon={blueIcon} ref={initMarker} {...props} />;
    };

    function onMapInputsChange(rule, value, callback) {
        if (props.onMapCoordinatesChange) {
            props.onMapCoordinatesChange();
        }
        callback();
    }

    const onMapClick = (e) => {
        setLat(e.latlng.lat);
        setLng(e.latlng.lng);
    };

    const getMarkerCoordinates = () => {
        setFieldsValue({
            latitude: lat,
            longitude: lng,
        });
        setModalVisible(false);
        if (props.onMapCoordinatesChange) {
            props.onMapCoordinatesChange();
        }
    };

    return (
        <React.Fragment>
            <Row>
                <Col span={14}>
                    <Row>
                        <Col span={12}>
                            <Form.Item label={geti18nText('map.pin.edit.latitude')}>
                                {getFieldDecorator('latitude', {
                                    rules: [
                                        {
                                            required: props.required != null ? props.required : true,
                                            message: geti18nText('app.default.required'),
                                            validator: onMapInputsChange,
                                        },
                                    ],
                                })(<Input style={{ width: '60%' }} />)}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label={geti18nText('map.pin.edit.longitude')}>
                                {getFieldDecorator('longitude', {
                                    rules: [
                                        {
                                            required: props.required != null ? props.required : true,
                                            message: geti18nText('app.default.required'),
                                            validator: onMapInputsChange,
                                        },
                                    ],
                                })(<Input style={{ width: '60%' }} />)}
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>

                <Col span={10}>
                    <Form.Item>
                        <Col>
                            <Button style={{ marginTop: '43px' }} type="primary" onClick={() => setModalVisible(true)}>
                                {geti18nText('inputmarkercoordinates.button')}
                            </Button>
                        </Col>
                    </Form.Item>
                </Col>
            </Row>
            <Modal
                title={geti18nText('inputmarkercoordinates.button')}
                visible={modalVisible}
                onCancel={() => setModalVisible(false)}
                destroyOnClose={true}
                onOk={getMarkerCoordinates}
                okText={geti18nText('inputmapcoordinates.modalOkText')}
                width={'800px'}
                okButtonProps={{ disabled: lat == null && lng == null }}
            >
                <Map
                    center={
                        props.form.getFieldValue('latitude') != null && props.form.getFieldValue('longitude') != null
                            ? [props.form.getFieldValue('latitude'), props.form.getFieldValue('longitude')]
                            : [45.75219336063106, 15.952148437500002]
                    }
                    zoom={7}
                    style={{ height: '500px', width: '100%' }}
                    onclick={onMapClick}
                >
                    <TileLayer
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    {lat != null && lng != null && <MyMarker position={[lat, lng]}></MyMarker>}
                </Map>
            </Modal>
        </React.Fragment>
    );
};

export default InputMarkerCoordinates;
