import { Col, Form, Input, Row, Divider, Button, Select, notification } from 'antd';
import React, { useState, useEffect } from 'react';
import { CONSTANTS_REQ } from '../../utils/Constants';
import { requestGet, requestPut, RESPONSE } from '../../utils/RequestResolver';
import { geti18nText } from '../../utils/Session';
import { normalizeJSONForComponent } from '../../utils/ComponentHelper';
import { normalizeComponents } from '../../utils/Utils';
import { Spinner } from '../../components/Spinner';

const Option = Select.Option;

const SpaceEdit = Form.create({ name: 'SpaceEdit' })((props) => {
    const [fetching, setFetching] = useState(false);
    const { getFieldDecorator, setFieldsValue } = props.form;

    const [heightSuffix, setHeightSuffix] = useState('px');
    const [mobileHeightSuffix, setMobileHeightSuffix] = useState('px');

    const selectHeightSuffixAfter = (
        <Select
            value={heightSuffix}
            onChange={(value) => {
                setHeightSuffix(value);
            }}
            className="select-after"
            size={'small'}
        >
            <Option value="px">px</Option>
            <Option value="vh">vh</Option>
        </Select>
    );

    const selectMobileHeightSuffixAfter = (
        <Select
            value={mobileHeightSuffix}
            onChange={(value) => {
                setMobileHeightSuffix(value);
            }}
            className="select-after"
            size={'small'}
        >
            <Option value="px">px</Option>
            <Option value="vh">vh</Option>
        </Select>
    );

    useEffect(() => {
        fetch();
    }, [props.visible]);

    async function fetch() {
        setFetching(true);
        requestGet(CONSTANTS_REQ.WEBPAGECOMPONENT.EDIT + '/' + props.id).then((result) => {
            setFetching(false);
            if (result.status === RESPONSE.OK) {
                setValues(result.data);
            } else {
                console.log('response failed');
            }
        });
    }
    function setValues(component_data) {
        var addedData = component_data;
        var json;
        if (component_data.json) {
            json = JSON.parse(component_data.json);
            delete addedData.json;
        }
        if (json) {
            if (json.height !== null && json.height !== undefined) {
                addedData.height = json.height.slice(0, -2);
                setHeightSuffix(json.height.slice(-2));
            }
            if (json.mobileHeight !== null && json.mobileHeight !== undefined) {
                addedData.mobileHeight = json.mobileHeight.slice(0, -2);
                setMobileHeightSuffix(json.mobileHeight.slice(-2));
            }
        }
        delete addedData.active;

        setFieldsValue(addedData);
    }

    function showNotification(title, message, duration, type) {
        notification[type]({
            message: title,
            description: message,
            duration: duration,
        });
    }

    function okNotification() {
        showNotification(geti18nText('app.default.save.ok'), geti18nText('app.default.save.ok.desc'), 3, 'success');
    }

    function error(message) {
        showNotification(geti18nText('app.default.save.nok'), message, 3, 'error');
    } 

    function save(e, close) {
        e.preventDefault();
        props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                values = normalizeComponents(values);
                if (values.height) {
                    values.height += heightSuffix;
                }
                if (values.mobileHeight) {
                    values.mobileHeight += mobileHeightSuffix;
                }
                delete values.id;
                let json = '';
                if (values instanceof Object) {
                    json = JSON.stringify(values);
                }

                requestPut(CONSTANTS_REQ.WEBPAGECOMPONENT.UPDATEJSON + '/' + props.id, null, {
                    id: props.id,
                    json: json,
                }).then((result) => {
                    if (result && result.status === RESPONSE.CREATED) {
                        console.log('okNotification');
                        okNotification();
                        close && props.closePopup();
                    } else {
                        error();
                        if (result && result.status === RESPONSE.BAD_REQUEST) {
                            if (result.data && result.data.field) {
                                switch (result.data.field) {
                                    case 'mandatory_fields_required':
                                        console.log(geti18nText('app.default.mandatory_fields_required'));
                                        return;
                                    default:
                                        break;
                                }
                            }
                        }
                        if (result.data && result.data.error) {
                            console.log(JSON.stringify(result.data.error));
                        }
                    }
                });
            }
        });
    }

    if (fetching) {
        return <Spinner />;
    } else {
        return (
            <React.Fragment>
                <Divider>{geti18nText('component.space')}</Divider>
                <Form>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item hidden={true}>{getFieldDecorator('id')(<Input hidden={true} />)}</Form.Item>
                            <Form.Item label={geti18nText('component.space.height')}>
                                {getFieldDecorator('height', {
                                    rules: [{ required: true, message: geti18nText('app.default.required') }],
                                })(<Input addonAfter={selectHeightSuffixAfter} />)}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item label={geti18nText('component.space.mobileHeight')}>
                                {getFieldDecorator(
                                    'mobileHeight',
                                    {}
                                )(<Input addonAfter={selectMobileHeightSuffixAfter} />)}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24} style={{ textAlign: 'right', marginTop: '1rem' }}>
                            <Button style={{ marginRight: '1em' }} onClick={(e) => props.closePopup()}>
                                {geti18nText('app.default.button.cancel')}
                            </Button>
                            <Button style={{ marginRight: '1em' }} type="primary" onClick={(e) => save(e, false)}>
                                {geti18nText('app.default.button.save')}
                            </Button>
                            <Button
                                type="primary"
                                style={{ background: '#31b531', borderColor: '#31b531', textColor: 'white' }}
                                onClick={(e) => {
                                    save(e, true);
                                }}
                            >
                                {geti18nText('app.default.button.save.close')}
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </React.Fragment>
        );
    }
});

export default SpaceEdit;
