import React, { useState, useEffect } from 'react';
import { Form, Row, Col, Input, InputNumber } from 'antd';
import { geti18nText, Session } from '../../../utils/Session';
import { InputByLanguage, translationsToObject, getTranslated } from '../../../components/InputByLanguage';
import { DataEdit } from '../../../components/DataEdit';
import { CONSTANTS_REQ } from '../../../utils/Constants';
const DomainEdit = Form.create({ name: 'DomainEdit' })((props) => {
    const [editHeader, setEditHeader] = useState(geti18nText('search.type.edit.new'));
    const [loading, setLoading] = useState(false);
    const { getFieldDecorator, setFieldsValue } = props.form;
    const [contextTextLangJson, setContextTextLangJson] = useState(null);

    function setValues(type) {
        setEditHeader(type.name);
        setFieldsValue(type);
        delete type.active;
        delete type.name;
    }

    function setDefaultValues() {
        setContextTextLangJson(null);
        setEditHeader(geti18nText('domain.edit.new'));
    }
    return (
        <DataEdit
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            setDefaultValues={setDefaultValues}
            setValues={setValues}
            url={CONSTANTS_REQ.DOMAIN.EDIT}
            width={1200}
            {...props}
            normalize={(values) => {
                console.log(values);
                return {
                    ...values,
                };
            }}
        >
            <Row gutter={24}>
                <Col span={24} style={{ width: '100%' }}>
                    <Form.Item hidden={true}>{getFieldDecorator('id')(<Input hidden={true} />)}</Form.Item>
                    <Form.Item label={geti18nText('domain.edit.name')}>
                        {getFieldDecorator('name')(<Input />)}
                    </Form.Item>
                    <Form.Item label={geti18nText('domain.edit.certificatePath')}>
                        {getFieldDecorator('certificatePath')(<Input />)}
                    </Form.Item>
                    <Form.Item label={geti18nText('domain.edit.privateKeyPath')}>
                        {getFieldDecorator('privateKeyPath')(<Input />)}
                    </Form.Item>
                </Col>
            </Row>
        </DataEdit>
    );
});
export default DomainEdit;
