import React, { Component } from 'react';
import { Spin } from 'antd';
import { HashRouter, Route, Switch } from 'react-router-dom';
import { Session } from '../utils/Session';
import { LoginIndex } from './login/LoginIndex';
import { ProtectedRoute } from '../utils/ProtectedRoute';
import { AdminMain } from '../components/adminLayout/AdminMain';
import './app.css';
import ReactGA from 'react-ga';
import 'react-image-crop/lib/ReactCrop.scss';
import 'react-leaflet-markercluster/dist/styles.min.css';


class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
        };
    }

    async componentDidMount() {
        this.setState({ loading: true });
        await Session.init();
        if (Session.getSetting('ux.ga-code') == true) {
            ReactGA.initialize(Session.getSetting('ux.ga-code'));
            ReactGA.pageview(window.location.pathname + window.location.search);
        }
        if (Session.getSetting('ux.title')) {
            document.title = Session.getSetting('ux.title');
        }

        if (Session.getSetting('ux.favicon')) {
            var link = document.querySelector("link[rel*='icon']") || document.createElement('link');
            link.type = 'image/x-icon';
            link.rel = 'shortcut icon';
            link.href = '/' + Session.getSetting('ux.favicon');
            document.getElementsByTagName('head')[0].appendChild(link);
        }

        this.setState({ loading: false });
    }

    render() {
        if (this.state.loading) {
            return (
                <div className="spin-container">
                    <Spin size="large" />
                </div>
            );
        } else {
            return (
                <HashRouter>
                    <Switch>
                        <Route exact path="/login" component={LoginIndex} />
                        <ProtectedRoute path="/" component={AdminMain} />
                    </Switch>
                </HashRouter>
            );
        }
    }
}

export default App;
