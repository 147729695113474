import {
    Col,
    Collapse,
    TimePicker,
    DatePicker,
    Form,
    Input,
    Row,
    Popconfirm,
    Modal,
    Divider,
    Button,
    Select,
    Tabs,
    notification,
} from 'antd';
import React, { useState, useEffect, useRef } from 'react';
import { DataEdit } from '../DataEdit';
import { CONSTANTS_FILE_TYPE, CONSTANTS_REQ } from '../../utils/Constants';
import { requestGet, requestPut, RESPONSE } from '../../utils/RequestResolver';
import { geti18nText, Session } from '../../utils/Session';
import { normalizeJSONForComponent } from '../../utils/ComponentHelper';
import { normalizeComponents, getTimeValue } from '../../utils/Utils';
import { InputByLanguage, translationsToObject } from '../InputByLanguage';
import { Spinner } from '../../components/Spinner';
import { SearchField, mapDataToSelect } from '../Search';
import moment from 'moment';
const { Option } = Select;
const { TabPane } = Tabs;
const { Panel } = Collapse;
const VideoChatEdit = Form.create({ name: 'VideoCallEdit' })((props) => {
    const [fetching, setFetching] = useState(false);
    const { getFieldDecorator, setFieldsValue } = props.form;
    const [deleteConfirmVisible, setDeleteConfirmVisible] = useState(false);
    const [titleLangJson, setTitleLangJson] = useState(undefined);
    const users = useRef([]);
    const [activeTab, setActiveTab] = useState('0');
    const [lastKey, setLastKey] = useState(0);
    const [exceptions, setExceptionDates] = useState([]);
    const [exceptionsKeys, setExceptionsKeys] = useState([]);
    const [dateToDelete, setDateToDelete] = useState(null);
    const [deletedDates, setDeletedDates] = useState([]);
    const days = [
        { id: 1, text: 'Mon.' },
        { id: 2, text: 'Tue.' },
        { id: 3, text: 'Wed.' },
        { id: 4, text: 'Thu.' },
        { id: 5, text: 'Fri.' },
        { id: 6, text: 'Sat.' },
        { id: 0, text: 'Sun.' },
    ];

    useEffect(() => {
        fetch();
    }, [props.visible]);

    async function fetch() {
        setFetching(true);
        await requestGet(CONSTANTS_REQ.VIDEO_CHAT.USERS).then((result) => {
            if (result.status === RESPONSE.OK) {
                users.current = result.data;
            }
        });
        requestGet(CONSTANTS_REQ.WEBPAGECOMPONENT.EDIT + '/' + props.id).then((result) => {
            setFetching(false);
            if (result.status === RESPONSE.OK) {
                setValues(result.data);
            } else {
                console.log('response failed');
            }
        });
    }
    function setValues(component_data) {
        var addedData = component_data;
        var json;
        if (component_data.json) {
            json = JSON.parse(component_data.json);
            delete addedData.json;
        }
        delete addedData.active;
        var populate_data = {};
        populate_data.days = days;
        var exceptionDates = [];
        if (json) {
            populate_data['call-title'] = json['call-title']; //translationsToObject(json, 'call-title', JSON.stringify(json['call-title']));
            setTitleLangJson(JSON.stringify(json['call-title']));

            let max_key = 0;
            for (const key of json.exception_dates) {
                exceptionDates.push({
                    id: key,
                });
                populate_data = translationsToObject(json, 'description_' + key, json['date_' + key].description);
                populate_data['dateFrom_' + key] = moment(json['date_' + key].dateFrom);
                populate_data['dateTo_' + key] = moment(json['date_' + key].dateTo);
                delete json['date_' + key];
                max_key = key > max_key ? key : max_key;
            }

            setLastKey(max_key);
            populate_data['time-from'] = moment(json['time-from'], 'HH:mm');
            populate_data['time-to'] = moment(json['time-to'], 'HH:mm');
            populate_data.user = users.current.find((x) => x.id + '' === json.user);
            populate_data.days =
                json.days != null && json.days instanceof Array ? json.days : json.days !== '' ? [json.days] : [];

            var _days = [];
            populate_data.days.forEach((value) => {
                if (value.length == 1) {
                    let d = days.find((x) => x.id + '' == value);
                    _days.push(d);
                } else {
                    let d = days.find((x) => x.id == parseInt(value.id));
                    _days.push(d);
                }
            });
            populate_data.days = _days;
        } else {
            //   setExceptionsKeys([0]);
            //  setExceptionDates([{ id: 0 }]);
        }

        populate_data.id = component_data.id;

        if (json && json.exception_dates != null) {
            setExceptionDates(exceptionDates);
            setExceptionsKeys(json.exception_dates);
        }
        setFieldsValue(populate_data);
    }

    function showNotification(title, message, duration, type) {
        notification[type]({
            message: title,
            description: message,
            duration: duration,
        });
    }

    function okNotification() {
        showNotification(geti18nText('app.default.save.ok'), geti18nText('app.default.save.ok.desc'), 3, 'success');
    }

    function error(message) {
        showNotification(geti18nText('app.default.save.nok'), message, 3, 'error');
    } 

    function save(e, close) {
        e.preventDefault();
        props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                if (values.user) {
                    values.user =
                        values.user != null && values.user.constructor === String
                            ? values.user
                            : values.user.key != null
                            ? values.user.key
                            : values.user.id + '';
                }
                if (values.days instanceof Array && values.days.length > 0 && values.days[0]['id']) {
                    values.days = mapDataToSelect(values.days, 'id', 'text', false);
                }

                /* if (values.days) {
                    values.days =
                        values.days != null
                            ? values.days.constructor === String
                                ? [parseInt(values.days)]
                                : values.days instanceof Array
                                ? values.days.map((pr) => {
                                      return pr.key;
                                  })
                                : [values.days.id]
                            : null;
                }*/
                values = normalizeComponents(values);
                values.exception_dates = exceptionsKeys;
                /* for (const deleted of deletedDates) {
                    if (values['date_' + deleted + '-file_path']) {
                        delete values['image_' + deleted + '-file_path_'];
                    }
                }*/
                values = normalize(values);

                requestPut(CONSTANTS_REQ.WEBPAGECOMPONENT.UPDATEJSON + '/' + props.id, null, {
                    id: props.id,
                    json: values.json,
                }).then((result) => {
                    if (result && result.status === RESPONSE.CREATED) {
                        console.log('okNotification');
                        okNotification();
                        close && props.closePopup();
                    } else {
                        error();
                        if (result && result.status === RESPONSE.BAD_REQUEST) {
                            if (result.data && result.data.field) {
                                switch (result.data.field) {
                                    case 'mandatory_fields_required':
                                        console.log(geti18nText('app.default.mandatory_fields_required'));
                                        return;
                                    default:
                                        break;
                                }
                            }
                        }
                        if (result.data && result.data.error) {
                            console.log(JSON.stringify(result.data.error));
                        }
                    }
                });
            }
        });
    }

    function normalize(values) {
        let jsonFieldGroups = ['call-title', 'user', 'time-from', 'time-to', 'days', 'exception_dates'];
        let jsonFieldKeys = {};
        values['time-from'] = moment(values[['time-from']]).format('HH:mm');
        values['time-to'] = moment(values[['time-to']]).format('HH:mm');
        let dateJsonArr = ['description', 'dateFrom', 'dateTo'];
        let arr = exceptionsKeys.slice();
        arr.forEach((imageIndex) => {
            jsonFieldGroups.push('date_' + imageIndex.toString());
            let _json = {
                description: values['description_' + imageIndex.toString()],
                dateFrom: values['dateFrom_' + imageIndex.toString()],
                dateTo: values['dateTo_' + imageIndex.toString()],
            };
            values['date_' + imageIndex.toString()] = _json;
        });

        return normalizeJSONForComponent(values, undefined, jsonFieldGroups, jsonFieldKeys, props);
    }
    const onEdit = (targetKey, action) => {
        if (action == 'add') {
            props.form.validateFieldsAndScroll((err, values) => {
                if (!err) {
                    let newId = lastKey;
                    newId++;

                    let arrDates = exceptions.slice();
                    let arrKeys = exceptionsKeys.slice();
                    arrDates.push({ id: newId });
                    arrKeys.push(newId);
                    setExceptionsKeys(arrKeys);
                    setExceptionDates(arrDates);
                    setActiveTab('' + newId);
                    setLastKey(newId);
                }
            });
        }
        if (action == 'remove') {
            if (exceptionsKeys.slice().length > 0) {
                setDeleteConfirmVisible(true);
                setDateToDelete(targetKey);
            }
        }
    };
    const tabOnChange = (activeKey) => {
        props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                setActiveTab(activeKey);
            }
        });
    };
    const deleteDate = () => {
        if (dateToDelete) {
            var newArrKeys = exceptionsKeys.filter((key) => key != parseInt(dateToDelete));
            var newArrImages = exceptions.filter((image) => image.id != parseInt(dateToDelete));
            setExceptionsKeys(newArrKeys);
            setExceptionDates(newArrImages);

            if (activeTab == dateToDelete) {
                setActiveTab('' + newArrKeys[0]);
            }
            setDeleteConfirmVisible(false);
            setDateToDelete(null);
            //let deleted = deletedDates.slice();
            //deleted.push(dateToDelete);
            //setDeletedDates(deleted);
        }
    };

    if (fetching) {
        return <Spinner />;
    } else {
        return (
            <React.Fragment>
                <Divider>{geti18nText('component.videochat')}</Divider>
                <Form>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item hidden={true}>{getFieldDecorator('id')(<Input hidden={true} />)}</Form.Item>
                            <Form.Item label={geti18nText('component.videochat.notification.title')}>
                                {getFieldDecorator('call-title', {
                                    initialValue: 'KIOSK ' + Session.getProjectInfo().name,
                                })(<Input />)}
                            </Form.Item>
                            {/* <InputByLanguage
                                notRequred
                                fieldName="call-title"
                                label={geti18nText('component.videochat.notification.title')}
                                langJson={titleLangJson}
                                {...props}
                            /> */}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Item label={geti18nText('component.videochat.user')}>
                                {getFieldDecorator('user', {
                                    rules: [{ required: true, message: geti18nText('app.default.required') }],
                                })(<SearchField options={users.current} />)}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={6}>
                            <Form.Item label={geti18nText('component.videochat.call.time.from')}>
                                {getFieldDecorator('time-from', {
                                    initialValue: moment('08:00', 'HH:mm'),
                                    rules: [{ required: true, message: geti18nText('app.default.required') }],
                                })(<TimePicker format="HH:mm" />)}
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label={geti18nText('component.videochat.call.time.to')}>
                                {getFieldDecorator('time-to', {
                                    initialValue: moment('16:00', 'HH:mm'),
                                    rules: [{ required: true, message: geti18nText('app.default.required') }],
                                })(<TimePicker format="HH:mm" />)}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label={geti18nText('component.videochat.work_days')}>
                                {getFieldDecorator('days', {
                                    rules: [{ required: true, message: geti18nText('app.default.required') }],
                                })(<SearchField mode="multiple" options={days} />)}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Popconfirm
                                placement="rightTop"
                                title={geti18nText('app.default.button.delete')}
                                visible={deleteConfirmVisible}
                                okText={geti18nText('app.default.button.yes')}
                                cancelText={geti18nText('app.default.button.no')}
                                onCancel={() => {
                                    setDeleteConfirmVisible(false);
                                }}
                                onConfirm={deleteDate}
                            >
                                <span></span>
                            </Popconfirm>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '20px ' }}>
                        <Col span={24}>
                            <Collapse bordered={false} style={{ marginBottom: '15px' }}>
                                <Panel
                                    header={geti18nText('component.videochat.edit.title')}
                                    key="1"
                                    forceRender={true}
                                >
                                    <Tabs
                                        type="editable-card"
                                        onEdit={onEdit}
                                        onChange={tabOnChange}
                                        activeKey={activeTab}
                                    >
                                        {exceptions.map((el, index) => (
                                            <TabPane tab={'Date ' + (index + 1)} key={el.id} forceRender={true}>
                                                <VideoChatTab
                                                    index={el.id}
                                                    description={el.description}
                                                    dateFrom={el.dateFrom}
                                                    dateTo={el.dateTo}
                                                    {...props}
                                                />
                                            </TabPane>
                                        ))}
                                    </Tabs>
                                </Panel>
                            </Collapse>
                        </Col>
                    </Row>

                    <Row>
                        <Col span={24} style={{ textAlign: 'right', marginTop: '1rem' }}>
                            <Button style={{ marginRight: '1em' }} onClick={(e) => props.closePopup()}>
                                {geti18nText('app.default.button.cancel')}
                            </Button>
                            <Button style={{ marginRight: '1em' }} type="primary" onClick={(e) => save(e, false)}>
                                {geti18nText('app.default.button.save')}
                            </Button>
                            <Button
                                type="primary"
                                style={{ background: '#31b531', borderColor: '#31b531', textColor: 'white' }}
                                onClick={(e) => {
                                    save(e, true);
                                }}
                            >
                                {geti18nText('app.default.button.save.close')}
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </React.Fragment>
        );
    }
});

const VideoChatTab = (props) => {
    const { getFieldDecorator, setFieldsValue } = props.form;

    return (
        <>
            <Row gutter={24}>
                <Col span={24}>
                    <InputByLanguage
                        fieldName={'description_' + props.index}
                        type={'rteEditor'}
                        label={geti18nText('component.videochat.edit.description')}
                        langJson={props.description}
                        {...props}
                    />
                </Col>
                <Col span={9}>
                    <Form.Item label={geti18nText('component.videochat.edit.date_from')}>
                        {getFieldDecorator('dateFrom_' + props.index, {
                            initialValue: moment(),
                            rules: [{ required: false, message: geti18nText('app.default.required') }],
                        })(<DatePicker format="DD.MM.YYYY" />)}
                    </Form.Item>
                </Col>
                <Col span={9}>
                    <Form.Item label={geti18nText('component.videochat.edit.date_to')}>
                        {getFieldDecorator('dateTo_' + props.index, {
                            initialValue: moment().add(1, 'days'),
                        })(<DatePicker format="DD.MM.YYYY" />)}
                    </Form.Item>
                </Col>
            </Row>
        </>
    );
};

export default VideoChatEdit;
