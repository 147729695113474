import React, { useEffect, useState } from 'react';
import {
    Breadcrumb,
    Col,
    Row,
    Card,
    List,
    Icon,
    Typography,
    Modal,
    Popconfirm,
    message,
    Button,
    Select,
    Badge,
    Radio,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
    IMAGE_FORMATS,
    CONSTANTS_REQ,
    CONSTANTS_FILE_TYPE,
    VIDEO_FORMATS,
    PDF_FORMATS,
} from '../../../utils/Constants';
import { requestGet, requestPost, RESPONSE } from '../../../utils/RequestResolver';
import { geti18nText, Session, hasAnyRole } from '../../../utils/Session';
import { selectFolder, selectFile, setRefreshFolder } from '../../../slices/filesSlices';
import { selectPreviewType } from '../../../slices/directoryPreviewSlices';
import { refreshMenu } from '../../../slices/menuSlice';
import { formatBytes, dateFormat } from '../../../utils/Utils';
import { FilePreview } from '../../../components/filePreview/FilePreview';
import { FileUpload } from '../../../components/fileUpload/FileUpload';
import NyBadge from '../../../components/badge/index';
import { useHistory } from 'react-router-dom';
import { AddFolder } from '../../../components/AddFolder';
import InfiniteScroll from 'react-infinite-scroll-component';
const { Option } = Select;
const { Meta } = Card;

const DirectoryContent = (props) => {
    const { previewType } = useSelector((state) => state.directoryPreviewSlices);
    const { folderPath, refreshFolder } = useSelector((state) => state.filesSlices);
    const [project, setProject] = useState(Session.getProjectId());
    const [data, setData] = useState([]);
    const [defaultData, setDefaultData] = useState([]);
    const [unsortedData, setUnsortedData] = useState([]);
    const [breadcrumbData, setBreadcrumbData] = useState([]);
    const [folderName, setFolderName] = useState('');
    const [nrFiles, setNrFiles] = useState(0);
    const [nrFolders, setNrFolders] = useState(0);
    const [filesSize, setFilesSize] = useState(0);
    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewFile, setPreviewFile] = useState('');
    const [visibleUploadModal, setVisibleUploadModal] = useState(false);
    const [visibleNewFolder, setVisibleNewFolder] = useState(false);
    const [folderValue, setFolderValue] = useState(null);
    const [showButton, setShowButton] = useState(true);
    const [uploadFolderPermission, setUploadFolderPermission] = useState(true);
    const dispatch = useDispatch();
    const { Title } = Typography;
    let history = useHistory();
    const [numberOfElement, setNumberOfElement] = useState(0);
    const [hasMoreData, setHasMoreData] = useState(true);
    const [sortType, setSortType] = useState(undefined);
    const [dataSize, setDataSize] = useState(0);
    let loadElement = 24;

    useEffect(() => {
        requestGet(CONSTANTS_REQ.DIRECTORY.CHECK_UPLOAD_FOLDER_PERMISSION, {
            projectId: project,
        }).then((result) => {
            if (result.status === RESPONSE.OK) {
                setUploadFolderPermission(result.data);
            }
        });
    }, []);

    useEffect(() => {
        if (numberOfElement === 0 && dataSize > 0) {
            setHasMoreData(true);
            setData([]);
            renderData();
        }
    }, [numberOfElement]);

    useEffect(() => {
        dataSort(sortType);
    }, [unsortedData]);

    const renderData = () => {
        if (dataSize > 0) {
            setTimeout(() => {
                let allData = [...defaultData];
                let elementNumber = numberOfElement + loadElement;
                setNumberOfElement(elementNumber);

                if (elementNumber >= dataSize && dataSize > 0) {
                    setHasMoreData(false);
                } else {
                    setHasMoreData(true);
                }

                let currData = allData.slice(0, elementNumber);
                setUnsortedData(currData);
            }, 500);
        }
    };

    useEffect(() => {
        if (folderPath) {
            requestGet(CONSTANTS_REQ.DIRECTORY.TREE + '/' + Session.getProjectId(), {
                directory: folderPath,
            }).then((result) => {
                if (result.status === RESPONSE.OK) {
                    console.log(result);
                    if (Array.isArray(result.data)) {
                        setData(result.data.slice(0, loadElement));
                        setUnsortedData(result.data.slice(0, loadElement));
                        setDefaultData(result.data);
                        let sizeData = result.data.length;
                        setDataSize(sizeData);
                        setNumberOfElement(loadElement);

                        if (loadElement >= sizeData) {
                            setHasMoreData(false);
                        } else {
                            setHasMoreData(true);
                        }

                        setNrFolders(
                            result.data.reduce(
                                (accumulator, currentValue) =>
                                    accumulator + (currentValue.type === 'DIRECTORY' ? 1 : 0),
                                0
                            )
                        );
                        setNrFiles(
                            result.data.reduce(
                                (accumulator, currentValue) => accumulator + (currentValue.type === 'FILE' ? 1 : 0),
                                0
                            )
                        );
                        setFilesSize(
                            result.data.reduce((accumulator, currentValue) => accumulator + currentValue.size, 0)
                        );
                    }
                } else {
                    setData([]);
                    setNrFiles(0);
                    setNrFolders(0);
                    setFilesSize(0);
                }
            });
            setLastSeen();

            setBreadcrumbData(getBreadcrumbItems());
            setFolderName(getFolderName());
        }
    }, [folderPath, refreshFolder, uploadFolderPermission]);

    function setLastSeen() {
        if (hasAnyRole(['ROLE_ADMIN', 'ROLE_PROJECT_ADMIN'])) {
            let params = {
                folder: folderPath,
                projectId: Session.getProjectId(),
            };
            requestPost(CONSTANTS_REQ.DIRECTORY.LAST_SEEN, null, params).then((resp) => {
                getDirectoryNotification(Session.getProjectId());
            });
        }
    }
    function getDirectoryNotification(projectId) {
        if (projectId) {
            requestGet(CONSTANTS_REQ.DIRECTORY.ROOT_NOTIFICATION, {
                projectId: projectId,
            }).then((result) => {
                if (result.status === RESPONSE.OK) {
                    var x = document.querySelectorAll('.notification-badge');
                    x.forEach((el) => {
                        el.innerHTML = result.data;
                        el.style.visibility = result.data > 0 ? 'visible' : 'hidden';
                    });
                }
            });
        }
    }

    function breadcrumbClick(route) {
        dispatch(
            selectFolder({
                folderPath: route,
            })
        );
    }

    function getFolderName() {
        let fName = geti18nText('menu.files');
        if (folderPath != '/') {
            fName = folderPath.substring(folderPath.lastIndexOf('/') + 1);
        }
        return fName;
    }

    function getBreadcrumbItems() {
        let items = [];
        if (hasAnyRole(['ROLE_ADMIN', 'ROLE_PROJECT_ADMIN'])) {
            items.push(
                <Breadcrumb.Item key={0} style={{ cursor: 'pointer' }} onClick={() => breadcrumbClick('/')}>
                    {geti18nText('menu.files')}
                </Breadcrumb.Item>
            );
        }

        setShowButton(
            hasAnyRole(['ROLE_CONTENT_UPLOAD']) && folderPath === '/UPLOAD' && !uploadFolderPermission ? false : true
        );

        if (folderPath != '/') {
            const fPath = folderPath.substring(1);
            let linkPath = '';
            let i = 0;
            for (const folder of fPath.split('/')) {
                linkPath = linkPath + '/' + folder;
                const p = linkPath;
                i++;
                items.push(
                    <Breadcrumb.Item key={i} style={{ cursor: 'pointer' }} onClick={() => breadcrumbClick(p)}>
                        {folder}
                    </Breadcrumb.Item>
                );
            }
        }

        return items;
    }

    function openLink(item) {
        if (item.type === 'DIRECTORY') {
            dispatch(
                selectFolder({
                    folderPath: item.relativePath,
                })
            );
        }
        //TODO
        if (item.type === 'FILE') {
            let fileType = null;
            if (IMAGE_FORMATS.includes(item.relativePath.toLowerCase().substring(item.relativePath.lastIndexOf('.')))) {
                fileType = CONSTANTS_FILE_TYPE.IMAGE;
            } else if (
                VIDEO_FORMATS.includes(item.relativePath.toLowerCase().substring(item.relativePath.lastIndexOf('.')))
            ) {
                fileType = CONSTANTS_FILE_TYPE.VIDEO;
            }
            dispatch(
                selectFile({
                    file: {
                        path: item.relativePath,
                        fileType: fileType,
                        size: item.size,
                        dateCreated: item.dateCreated,
                    },
                })
            );
            history.push('/file');
        }
    }

    function isImage(path) {
        return IMAGE_FORMATS.includes(path.toLowerCase().substring(path.lastIndexOf('.')));
    }

    function isVideo(path) {
        return VIDEO_FORMATS.includes(path.toLowerCase().substring(path.lastIndexOf('.')));
    }

    function isPdf(path) {
        return PDF_FORMATS.includes(path.toLowerCase().substring(path.lastIndexOf('.')));
    }

    function previewImage(path) {
        const file = { path: path, fileType: CONSTANTS_FILE_TYPE.IMAGE };
        setPreviewFile(file);
        setPreviewVisible(true);
    }

    const deleteFile = (path) => {
        //setLoading(true);
        requestGet(
            CONSTANTS_REQ.DIRECTORY.CHECK_DELETE,
            { projectId: Session.getProjectId(), path: path },
            false,
            true
        ).then((result) => {
            if (result.status === RESPONSE.OK) {
                requestPost(CONSTANTS_REQ.DIRECTORY.DELETE, undefined, {
                    projectId: Session.getProjectId(),
                    path: path,
                }).then((result) => {
                    if (result.status === RESPONSE.OK) {
                        message.info(`${geti18nText('menu.delete.file.success')}`);
                        dispatch(setRefreshFolder());
                        dispatch(
                            refreshMenu({
                                refreshLastNode: false,
                                refreshProjectNode: false,
                                refreshNodes: ['FOLDER-' + folderPath + '' + Session.getProjectId()],
                            })
                        );
                    } else {
                        message.destroy();
                        message.error(`${geti18nText('menu.delete.file.error')}`);
                    }
                    //setLoading(false);
                });
            } else {
                message.destroy();
                message.error(`${geti18nText('menu.delete.file.error')}`);
            }
            //setLoading(false);
        });
    };

    function onVisibleUploadChange(value) {
        //refreshNode(selectedNodeForFolderRefresh, false);
        dispatch(setRefreshFolder());
        dispatch(
            refreshMenu({
                refreshLastNode: false,
                refreshProjectNode: false,
                refreshNodes: ['FOLDER-' + folderPath + '' + Session.getProjectId()],
            })
        );
        setVisibleUploadModal(value.disabled);
    }

    function onChangeFolderVisible(value) {
        dispatch(setRefreshFolder());
        setVisibleNewFolder(value);
    }

    function openNewFolder() {
        setFolderValue(folderPath);
        setVisibleNewFolder(true);
    }
    function startDrag(ev, item) {
        item['folderPath'] = folderPath;
        ev.dataTransfer.setData('drag-item', JSON.stringify(item));
    }

    const onSortClick = (value) => {
        setSortType(value);
        dataSort(value);
    };

    const dataSort = (value) => {
        switch (value) {
            case 'name_asc':
                var sortData = [...unsortedData];
                sortData.sort(function (a, b) {
                    var nameA = a.name.toUpperCase();
                    var nameB = b.name.toUpperCase();
                    if (nameA < nameB) {
                        return -1;
                    }
                    if (nameA > nameB) {
                        return 1;
                    }
                    return 0;
                });
                setData(sortData);
                break;
            case 'name_desc':
                var sortData = [...unsortedData];
                sortData.sort(function (a, b) {
                    var nameA = a.name.toUpperCase();
                    var nameB = b.name.toUpperCase();
                    if (nameA < nameB) {
                        return 1;
                    }
                    if (nameA > nameB) {
                        return -1;
                    }
                    return 0;
                });
                setData(sortData);
                break;
            case 'date_asc':
                var sortData = [...unsortedData];
                sortData.sort(function (a, b) {
                    var nameA = a.dateCreated != undefined ? a.dateCreated : 0;
                    var nameB = b.dateCreated != undefined ? b.dateCreated : 0;
                    if (nameA < nameB) {
                        return -1;
                    }
                    if (nameA > nameB) {
                        return 1;
                    }
                    return 0;
                });
                setData(sortData);
                break;
            case 'date_desc':
                var sortData = [...unsortedData];
                sortData.sort(function (a, b) {
                    var nameA = a.dateCreated != undefined ? a.dateCreated : 0;
                    var nameB = b.dateCreated != undefined ? b.dateCreated : 0;
                    if (nameA < nameB) {
                        return 1;
                    }
                    if (nameA > nameB) {
                        return -1;
                    }
                    return 0;
                });
                setData(sortData);
                break;
            default:
                setData(unsortedData);
        }
    };

    return (
        <React.Fragment>
            <Row>
                <Col span={24}>
                    <Breadcrumb>{breadcrumbData}</Breadcrumb>
                </Col>
            </Row>
            <Row>
                <Col span={16} style={{ marginTop: '15px' }}>
                    <Title level={3}>
                        <Icon type="folder-open" style={{ marginRight: '15px' }} />
                        {folderName}
                    </Title>
                </Col>
                {showButton && (
                    <>
                        <Col span={4} style={{ textAlign: 'right' }}>
                            <Button
                                disabled={Session.getProjectId() == undefined}
                                onClick={() => setVisibleUploadModal(true)}
                                type="primary"
                                icon="upload"
                            >
                                {geti18nText('directory.files.upload')}
                            </Button>
                        </Col>
                        <Col span={4} style={{ textAlign: 'center' }}>
                            <Button
                                disabled={Session.getProjectId() == undefined}
                                onClick={() => openNewFolder()}
                                type="primary"
                                icon="folder-add"
                            >
                                {geti18nText('directory.create.folder')}
                            </Button>
                        </Col>
                    </>
                )}
            </Row>
            <Row>
                <Col span={16} style={{ paddingTop: '5px' }}>
                    {geti18nText('directory.number.folders')}: <b>{nrFolders}</b>
                    <span style={{ paddingLeft: '25px' }}>
                        {geti18nText('directory.number.files')}: <b>{nrFiles}</b>
                    </span>
                    <span style={{ paddingLeft: '25px' }}>
                        {geti18nText('directory.files.size')}: <b>{formatBytes(filesSize)}</b>
                    </span>
                </Col>
                <Col span={6} style={{ textAlign: 'right' }}>
                    <span style={{ paddingRight: '10px' }}>{geti18nText('directory.sortBy')}:</span>
                    <Select style={{ width: 160 }} allowClear onChange={onSortClick}>
                        <Option value="name_asc">
                            {geti18nText('directory.sortBy.name')}{' '}
                            <Icon
                                type="sort-ascending"
                                style={{ float: 'right', fontSize: '18px', paddingTop: '5px', paddingLeft: '15px' }}
                            />
                        </Option>
                        <Option value="name_desc">
                            {geti18nText('directory.sortBy.name')}{' '}
                            <Icon
                                type="sort-descending"
                                style={{ float: 'right', fontSize: '18px', paddingTop: '5px', paddingLeft: '15px' }}
                            />
                        </Option>
                        <Option value="date_asc">
                            {geti18nText('directory.sortBy.date')}{' '}
                            <Icon
                                type="sort-ascending"
                                style={{ float: 'right', fontSize: '18px', paddingTop: '5px', paddingLeft: '15px' }}
                            />
                        </Option>
                        <Option value="date_desc">
                            {geti18nText('directory.sortBy.date')}{' '}
                            <Icon
                                type="sort-descending"
                                style={{ float: 'right', fontSize: '18px', paddingTop: '5px', paddingLeft: '15px' }}
                            />
                        </Option>
                    </Select>
                </Col>
                <Col span={2} style={{ textAlign: 'right' }}>
                    <Radio.Group
                        value={previewType}
                        onChange={(event) => {
                            dispatch(selectPreviewType({ previewType: event.target.value }));
                            setNumberOfElement(0);
                        }}
                    >
                        <Radio.Button value="details">
                            <Icon type="bars" />
                        </Radio.Button>
                        <Radio.Button value="thumbnail">
                            <Icon type="appstore" />
                        </Radio.Button>
                    </Radio.Group>
                </Col>
            </Row>
            <Row>
                <Col style={{ marginTop: '25px' }}>
                    {previewType === 'details' && (
                        <InfiniteScroll
                            dataLength={data.length}
                            next={renderData}
                            hasMore={hasMoreData}
                            loader={
                                <div className="loading-spinner">
                                    <center>
                                        <Icon style={{ fontSize: 18, marginTop: '10px' }} type="loading" />
                                    </center>
                                </div>
                            }
                            style={{ overflow: 'none' }}
                        >
                            <List
                                grid={{
                                    gutter: 12,
                                    xs: 1,
                                    sm: 1,
                                    md: 1,
                                    lg: 1,
                                    xl: 2,
                                    xxl: 3,
                                }}
                                dataSource={data}
                                renderItem={(item, index) => (
                                    <List.Item key={index}>
                                        <Card
                                            id="draggable"
                                            onDragStart={(e) => startDrag(e, item)}
                                            draggable={item.type === 'FILE'}
                                            style={{ minHeight: '92px' }}
                                        >
                                            <Row>
                                                {item.type === 'DIRECTORY' && (
                                                    <>
                                                        <Col span={12}>
                                                            <span
                                                                onClick={() => openLink(item)}
                                                                style={{ cursor: 'pointer' }}
                                                            >
                                                                <Icon
                                                                    type="folder"
                                                                    style={{ marginRight: '10px', fontSize: '20px' }}
                                                                />
                                                                {item.name}
                                                            </span>
                                                        </Col>
                                                        <Col span={12} style={{ textAlign: 'right' }}>
                                                            {item.dateCreated !== undefined && (
                                                                <span
                                                                    style={{
                                                                        marginLeft: '20px',
                                                                        color: 'rgb(0 0 0 / 45%)',
                                                                        fontSize: '12px',
                                                                    }}
                                                                >
                                                                    {geti18nText('directory.folder.date')}:{' '}
                                                                    {dateFormat(item.dateCreated, false)}
                                                                </span>
                                                            )}
                                                            {item.notification != undefined &&
                                                                parseInt(item.notification) > 0 &&
                                                                hasAnyRole(['ROLE_ADMIN', 'ROLE_PROJECT_ADMIN']) && (
                                                                    <NyBadge count={item.notification}></NyBadge>
                                                                )}
                                                        </Col>
                                                    </>
                                                )}
                                                {item.type === 'FILE' && (
                                                    <>
                                                        <Col span={11}>
                                                            <span
                                                                onClick={() => openLink(item)}
                                                                style={{ cursor: 'pointer', wordWrap: 'break-word' }}
                                                            >
                                                                {item.name}
                                                            </span>
                                                        </Col>
                                                        <Col span={3} style={{ textAlign: 'right' }}>
                                                            <span
                                                                style={{
                                                                    textAlign: 'right',
                                                                    color: 'rgb(0 0 0 / 45%)',
                                                                    fontSize: '12px',
                                                                }}
                                                            >
                                                                {formatBytes(item.size)}
                                                            </span>
                                                        </Col>
                                                        <Col span={6} style={{ textAlign: 'right' }}>
                                                            {item.dateCreated !== undefined && (
                                                                <span
                                                                    style={{
                                                                        color: 'rgb(0 0 0 / 45%)',
                                                                        fontSize: '12px',
                                                                    }}
                                                                >
                                                                    {dateFormat(item.dateCreated, true)}
                                                                </span>
                                                            )}
                                                        </Col>
                                                        <Col span={4} style={{ textAlign: 'right' }}>
                                                            {isImage(item.relativePath) && (
                                                                <Icon
                                                                    onClick={() => previewImage(item.relativePath)}
                                                                    style={{ paddingRight: '10px', fontSize: '20px' }}
                                                                    type="file-jpg"
                                                                />
                                                            )}
                                                            <Popconfirm
                                                                title={geti18nText('menu.delete.file.confirm')}
                                                                onConfirm={() => deleteFile(item.relativePath)}
                                                                okText={geti18nText('app.default.button.yes')}
                                                                cancelText={geti18nText('app.default.button.no')}
                                                            >
                                                                <Icon
                                                                    style={{ fontSize: '20px', color: 'red' }}
                                                                    type="delete"
                                                                />
                                                            </Popconfirm>
                                                        </Col>
                                                    </>
                                                )}
                                            </Row>
                                        </Card>
                                    </List.Item>
                                )}
                            />
                        </InfiniteScroll>
                    )}
                    {previewType === 'thumbnail' && (
                        <InfiniteScroll
                            dataLength={data.length}
                            next={renderData}
                            hasMore={hasMoreData}
                            loader={
                                <div className="loading-spinner">
                                    <center>
                                        <Icon style={{ fontSize: 18, marginTop: '10px' }} type="loading" />
                                    </center>
                                </div>
                            }
                            style={{ overflow: 'none' }}
                        >
                            <List
                                grid={{
                                    gutter: 12,
                                    xs: 1,
                                    sm: 1,
                                    md: 1,
                                    lg: 2,
                                    xl: 3,
                                    xxl: 4,
                                }}
                                dataSource={data}
                                renderItem={(item, index) => (
                                    <List.Item key={index}>
                                        {item.type === 'DIRECTORY' && (
                                            <Card
                                                cover={
                                                    <div style={{ height: '168px' }}>
                                                        <svg
                                                            viewBox="64 64 896 896"
                                                            focusable="false"
                                                            class=""
                                                            data-icon="file"
                                                            width="100%"
                                                            height="100%"
                                                            fill="currentColor"
                                                            aria-hidden="true"
                                                            style={{ paddingTop: '20px' }}
                                                        >
                                                            <path d="M928 444H820V330.4c0-17.7-14.3-32-32-32H473L355.7 186.2a8.15 8.15 0 0 0-5.5-2.2H96c-17.7 0-32 14.3-32 32v592c0 17.7 14.3 32 32 32h698c13 0 24.8-7.9 29.7-20l134-332c1.5-3.8 2.3-7.9 2.3-12 0-17.7-14.3-32-32-32zM136 256h188.5l119.6 114.4H748V444H238c-13 0-24.8 7.9-29.7 20L136 643.2V256zm635.3 512H159l103.3-256h612.4L771.3 768z"></path>
                                                        </svg>
                                                    </div>
                                                }
                                                onClick={() => openLink(item)}
                                                style={{ cursor: 'pointer', width: '300px', height: '311.5px' }}
                                            >
                                                <Meta
                                                    title={item.name}
                                                    description={
                                                        <>
                                                            {' '}
                                                            {item.dateCreated !== undefined && (
                                                                <span
                                                                    style={{
                                                                        color: 'rgb(0 0 0 / 45%)',
                                                                        fontSize: '12px',
                                                                    }}
                                                                >
                                                                    {geti18nText('directory.folder.date')}:{' '}
                                                                    {dateFormat(item.dateCreated, false)}
                                                                </span>
                                                            )}
                                                            {item.notification != undefined &&
                                                                parseInt(item.notification) > 0 &&
                                                                hasAnyRole(['ROLE_ADMIN', 'ROLE_PROJECT_ADMIN']) && (
                                                                    <NyBadge count={item.notification}></NyBadge>
                                                                )}
                                                        </>
                                                    }
                                                />
                                            </Card>
                                        )}
                                        {item.type === 'FILE' && (
                                            <Card
                                                style={{ cursor: 'pointer', width: '300px' }}
                                                cover={
                                                    <>
                                                        {isImage(item.relativePath) && (
                                                            <img
                                                                src={
                                                                    CONSTANTS_REQ.STATIC +
                                                                    project +
                                                                    encodeURI(item.relativePath)
                                                                }
                                                                style={{ height: '168px', objectFit: 'scale-down' }}
                                                                onClick={() => previewImage(item.relativePath)}
                                                            />
                                                        )}
                                                        {isVideo(item.relativePath) && (
                                                            <div
                                                                style={{ height: '168px' }}
                                                                onClick={() => openLink(item)}
                                                            >
                                                                <svg
                                                                    viewBox="64 64 896 896"
                                                                    focusable="false"
                                                                    class=""
                                                                    data-icon="file"
                                                                    width="100%"
                                                                    height="100%"
                                                                    fill="currentColor"
                                                                    aria-hidden="true"
                                                                    style={{ paddingTop: '20px' }}
                                                                >
                                                                    <path d="M442.3 677.6l199.4-156.7a11.3 11.3 0 000-17.7L442.3 346.4c-7.4-5.8-18.3-.6-18.3 8.8v313.5c0 9.4 10.9 14.7 18.3 8.9z"></path>
                                                                    <path d="M880 112H144c-17.7 0-32 14.3-32 32v736c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V144c0-17.7-14.3-32-32-32zm-40 728H184V184h656v656z"></path>
                                                                </svg>
                                                            </div>
                                                        )}
                                                        {isPdf(item.relativePath) && (
                                                            <div
                                                                style={{ height: '168px' }}
                                                                onClick={() => openLink(item)}
                                                            >
                                                                <svg
                                                                    viewBox="64 64 896 896"
                                                                    focusable="false"
                                                                    class=""
                                                                    data-icon="file"
                                                                    width="100%"
                                                                    height="100%"
                                                                    fill="currentColor"
                                                                    aria-hidden="true"
                                                                    style={{ paddingTop: '20px' }}
                                                                >
                                                                    <path d="M531.3 574.4l.3-1.4c5.8-23.9 13.1-53.7 7.4-80.7-3.8-21.3-19.5-29.6-32.9-30.2-15.8-.7-29.9 8.3-33.4 21.4-6.6 24-.7 56.8 10.1 98.6-13.6 32.4-35.3 79.5-51.2 107.5-29.6 15.3-69.3 38.9-75.2 68.7-1.2 5.5.2 12.5 3.5 18.8 3.7 7 9.6 12.4 16.5 15 3 1.1 6.6 2 10.8 2 17.6 0 46.1-14.2 84.1-79.4 5.8-1.9 11.8-3.9 17.6-5.9 27.2-9.2 55.4-18.8 80.9-23.1 28.2 15.1 60.3 24.8 82.1 24.8 21.6 0 30.1-12.8 33.3-20.5 5.6-13.5 2.9-30.5-6.2-39.6-13.2-13-45.3-16.4-95.3-10.2-24.6-15-40.7-35.4-52.4-65.8zM421.6 726.3c-13.9 20.2-24.4 30.3-30.1 34.7 6.7-12.3 19.8-25.3 30.1-34.7zm87.6-235.5c5.2 8.9 4.5 35.8.5 49.4-4.9-19.9-5.6-48.1-2.7-51.4.8.1 1.5.7 2.2 2zm-1.6 120.5c10.7 18.5 24.2 34.4 39.1 46.2-21.6 4.9-41.3 13-58.9 20.2-4.2 1.7-8.3 3.4-12.3 5 13.3-24.1 24.4-51.4 32.1-71.4zm155.6 65.5c.1.2.2.5-.4.9h-.2l-.2.3c-.8.5-9 5.3-44.3-8.6 40.6-1.9 45 7.3 45.1 7.4zm191.4-388.2L639.4 73.4c-6-6-14.1-9.4-22.6-9.4H192c-17.7 0-32 14.3-32 32v832c0 17.7 14.3 32 32 32h640c17.7 0 32-14.3 32-32V311.3c0-8.5-3.4-16.7-9.4-22.7zM790.2 326H602V137.8L790.2 326zm1.8 562H232V136h302v216a42 42 0 0 0 42 42h216v494z"></path>
                                                                </svg>
                                                            </div>
                                                        )}
                                                        {!isImage(item.relativePath) &&
                                                            !isVideo(item.relativePath) &&
                                                            !isPdf(item.relativePath) && (
                                                                <div
                                                                    style={{ height: '168px' }}
                                                                    onClick={() => openLink(item)}
                                                                >
                                                                    <svg
                                                                        viewBox="64 64 896 896"
                                                                        focusable="false"
                                                                        class=""
                                                                        data-icon="file"
                                                                        width="100%"
                                                                        height="100%"
                                                                        fill="currentColor"
                                                                        aria-hidden="true"
                                                                        style={{ paddingTop: '20px' }}
                                                                    >
                                                                        <path d="M854.6 288.6L639.4 73.4c-6-6-14.1-9.4-22.6-9.4H192c-17.7 0-32 14.3-32 32v832c0 17.7 14.3 32 32 32h640c17.7 0 32-14.3 32-32V311.3c0-8.5-3.4-16.7-9.4-22.7zM790.2 326H602V137.8L790.2 326zm1.8 562H232V136h302v216a42 42 0 0 0 42 42h216v494z"></path>
                                                                    </svg>
                                                                </div>
                                                            )}
                                                    </>
                                                }
                                                actions={[
                                                    <>
                                                        {isImage(item.relativePath) && (
                                                            <Icon
                                                                onClick={() => previewImage(item.relativePath)}
                                                                style={{ paddingRight: '10px', fontSize: '20px' }}
                                                                type="file-jpg"
                                                            />
                                                        )}
                                                    </>,
                                                    <Popconfirm
                                                        title={geti18nText('menu.delete.file.confirm')}
                                                        onConfirm={() => deleteFile(item.relativePath)}
                                                        okText={geti18nText('app.default.button.yes')}
                                                        cancelText={geti18nText('app.default.button.no')}
                                                    >
                                                        <Icon
                                                            style={{ fontSize: '20px', color: 'red' }}
                                                            type="delete"
                                                        />
                                                    </Popconfirm>,
                                                ]}
                                            >
                                                <Meta
                                                    onClick={() => openLink(item)}
                                                    title={item.name}
                                                    description={
                                                        <>
                                                            {' '}
                                                            {item.dateCreated !== undefined && (
                                                                <span
                                                                    style={{
                                                                        color: 'rgb(0 0 0 / 45%)',
                                                                        fontSize: '12px',
                                                                    }}
                                                                >
                                                                    {dateFormat(item.dateCreated, true)}
                                                                </span>
                                                            )}
                                                            <span
                                                                style={{
                                                                    textAlign: 'right',
                                                                    color: 'rgb(0 0 0 / 45%)',
                                                                    fontSize: '12px',
                                                                    float: 'right',
                                                                }}
                                                            >
                                                                {formatBytes(item.size)}
                                                            </span>
                                                        </>
                                                    }
                                                />
                                            </Card>
                                        )}
                                    </List.Item>
                                )}
                            />
                        </InfiniteScroll>
                    )}
                </Col>
            </Row>
            {/* <Row>
                <Col offset={10} span={4}>
                    <Button
                        style={{ width: '100%' }}
                        type="primary"
                        loading={loading}
                        disabled={disableLoadMore}
                        onClick={() => {
                            setLoading(true);
                            let num = numberOfElement + loadElement;
                            setNumberOfElement(num);
                        }}
                    >
                        {geti18nText('app.default.load.more')}
                    </Button>
                </Col>
            </Row> */}
            <Row hidden={true}>
                <FileUpload
                    folderValue={folderPath}
                    visible={visibleUploadModal}
                    disabled={true}
                    menuUploadChange={onVisibleUploadChange}
                    multiple={true}
                    addedData={{ projectId: Session.getProjectId() }}
                ></FileUpload>
            </Row>
            <Modal
                title={geti18nText('menu.preview')}
                visible={previewVisible}
                onCancel={() => setPreviewVisible(false)}
                destroyOnClose={true}
                footer={null}
                width={1000}
            >
                <FilePreview addedData={{ projectId: Session.getProjectId() }} file={previewFile} />
            </Modal>
            <AddFolder
                onChange={onChangeFolderVisible}
                visible={visibleNewFolder}
                folderValue={folderValue}
                projectId={Session.getProjectId()}
            ></AddFolder>
        </React.Fragment>
    );
};

export default DirectoryContent;
