import { Col, Row, Button, message, Icon, Typography, Breadcrumb, Input, Tooltip } from 'antd';
import React, { useState, useEffect } from 'react';
import { FilePreview } from '../../../components/filePreview/FilePreview';
import { geti18nText, Session, hasAnyRole } from '../../../utils/Session';
import { useDispatch, useSelector } from 'react-redux';
import { downloadFile } from '../../../utils/Utils';
import { requestGet, RESPONSE } from '../../../utils/RequestResolver';
import { CONSTANTS_REQ } from '../../../utils/Constants';
import { selectFolder } from '../../../slices/filesSlices';
import { useHistory } from 'react-router-dom';
import { formatBytes, dateFormat } from '../../../utils/Utils';

const File = (props) => {
    const dispatch = useDispatch();
    const { file, folderPath } = useSelector((state) => state.filesSlices);
    const [loading, setLoading] = useState(false);
    const { Title } = Typography;
    const [breadcrumbData, setBreadcrumbData] = useState([]);
    const [folderName, setFolderName] = useState('');
    const [fileSize, setFileSize] = useState(0);
    const [dateCreated, setDateCreated] = useState(undefined);
    const [pathValue, setPathValue] = useState('');
    let history = useHistory();

    useEffect(() => {
        setBreadcrumbData(getBreadcrumbItems());
        setFileSize(file.size);
        setFolderName(getFolderName());
        setDateCreated(file.dateCreated);
        const path = file.path ? file.path : undefined;
        setPathValue('/api/media/' + Session.getProjectId() + path);
    }, [file]);

    const fileDownload = () => {
        const path = file.path ? file.path : undefined;
        const fileName = file.path.substring(file.path.lastIndexOf('/') + 1, file.path.length);

        setLoading(true);

        requestGet(
            CONSTANTS_REQ.DISK_FILE.DOWNLOAD,
            { projectId: Session.getProjectId(), relativePath: encodeURI(path) },
            false,
            true
        ).then((result) => {
            if (result.status === RESPONSE.OK && result.data) {
                result.data.filename = fileName;
                downloadFile(result);
            } else {
                message.destroy();
                message.error(`${geti18nText('upload.text.download.error')}`);
            }
            setLoading(false);
        });
    };

    function getFolderName() {
        let fName = geti18nText('menu.files');
        if (folderPath != '/') {
            fName = folderPath.substring(folderPath.lastIndexOf('/') + 1);
        }
        return fName;
    }

    function getBreadcrumbItems() {
        let items = [];
        if (hasAnyRole(['ROLE_ADMIN', 'ROLE_PROJECT_ADMIN'])) {
            items.push(
                <Breadcrumb.Item key={0} style={{ cursor: 'pointer' }} onClick={() => breadcrumbClick('/')}>
                    {geti18nText('menu.files')}
                </Breadcrumb.Item>
            );
        }

        if (folderPath != '/') {
            const fPath = folderPath.substring(1);
            let linkPath = '';
            let i = 0;
            for (const folder of fPath.split('/')) {
                linkPath = linkPath + '/' + folder;
                const p = linkPath;
                i++;
                items.push(
                    <Breadcrumb.Item key={i} style={{ cursor: 'pointer' }} onClick={() => breadcrumbClick(p)}>
                        {folder}
                    </Breadcrumb.Item>
                );
            }
        }

        return items;
    }

    function breadcrumbClick(route) {
        dispatch(
            selectFolder({
                folderPath: route,
            })
        );
        history.push('/directory');
    }

    return file.path ? (
        <React.Fragment>
            <Row>
                <Col span={24}>
                    <Breadcrumb>{breadcrumbData}</Breadcrumb>
                </Col>
            </Row>
            <Row>
                <Col span={10}>
                    <Title level={3}>
                        <Icon type="file" style={{ marginRight: '15px', paddingTop: '15px' }} />
                        {file.path ? file.path.substring(file.path.lastIndexOf('/') + 1) : null}
                    </Title>
                </Col>
                <Col span={2}>
                    <Button
                        onClick={fileDownload}
                        loading={loading}
                        icon="download"
                        type="primary"
                        key="cancelNewFolder"
                    >
                        {geti18nText('upload.download')}
                    </Button>
                </Col>
                <Col span={4}>
                    <Input
                        addonAfter={
                            <Tooltip title={geti18nText('app.default.copyToClipBoard')}>
                                <Icon
                                    type="copy"
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                        navigator.clipboard.writeText(pathValue).then((r) => {
                                            message.success(geti18nText('app.default.copied'));
                                        });
                                    }}
                                />
                            </Tooltip>
                        }
                        value={pathValue}
                    />
                </Col>
            </Row>
            <Row>
                <Col span={4}>
                    <span style={{ paddingLeft: '25px' }}>
                        {geti18nText('file.size')}: <b>{formatBytes(fileSize)}</b>
                    </span>
                </Col>
                <Col span={10}>
                    <span>
                        {geti18nText('file.dateCreated')}:{' '}
                        <b>{dateCreated != undefined ? dateFormat(dateCreated, true) : ''}</b>
                    </span>
                </Col>
            </Row>
            <Row style={{ paddingTop: '15px' }}>
                <FilePreview
                    style={{ position: 'absolute' }}
                    addedData={{ projectId: Session.getProjectId() }}
                    file={file}
                />
            </Row>
        </React.Fragment>
    ) : null;
};

export default File;
