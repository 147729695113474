import React, { useState, useEffect } from 'react';
import { Form, Row, Col, Input, Button, Divider, Radio, Tabs, Popconfirm, Collapse, Select, message } from 'antd';
import { Session, geti18nText } from '../../utils/Session';
import { requestGet, requestPut, RESPONSE, requestPost } from '../../utils/RequestResolver';
import { CONSTANTS_FILE_TYPE, CONSTANTS_REQ } from '../../utils/Constants';
import { FileUpload } from '../fileUpload/FileUpload';
import { InputByLanguage, translationsToObject } from '../InputByLanguage';
import { SearchField, mapDataToSelect } from '../Search';
import { normalizeComponents } from '../../utils/Utils';

const FooterComponentEdit = Form.create({ name: 'FooterComponentEdit' })((props) => {
    const [fetching, setFetching] = useState(false);
    const { getFieldDecorator, setFieldsValue } = props.form;
    const [footerTextPosition, setFooterTextPosition] = useState();
    const [footerText, setFooterText] = useState();
    // const [imageLink, setImageLink] = useState({});
    const { TabPane } = Tabs;
    const { Panel } = Collapse;
    const Option = Select.Option;

    const [textContent, setTextContent] = useState(null);
    const [linkLastKey, setLinkLastKey] = useState(0);
    const [links, setLinks] = useState([]);
    const [activeTabLink, setActiveTabLink] = useState('0');
    const [linksKeys, setLinksKeys] = useState([0]);
    const [deletedLinks, setDeletedLinks] = useState([]);
    const [linkToDelete, setLinkToDelete] = useState(null);
    const [formValues, setFormValues] = useState(null);
    const [confirmVisible, setConfirmVisible] = useState(false);

    const [titleLeftIconJson, setTitleLeftIconJson] = useState(null);
    const [iconLeftLastKey, setIconLeftLastKey] = useState(0);
    const [leftIcons, setLeftIcons] = useState([]);
    const [activeTabIconLeft, setActiveTabIconLeft] = useState('0');
    const [leftIconsKeys, setLeftIconsKeys] = useState([0]);
    const [deletedLeftIcons, setDeletedLeftIcons] = useState([]);
    const [leftIconToDelete, setLeftIconToDelete] = useState(null);
    const [confirmLeftIconVisible, setLConfirmLeftIconVisible] = useState(false);

    const [titleRightIconJson, setTitleRightIconJson] = useState(null);
    const [iconRightLastKey, setIconRightLastKey] = useState(0);
    const [rightIcons, setRightIcons] = useState([]);
    const [activeTabIconRight, setActiveTabIconRight] = useState('0');
    const [rightIconsKeys, setRightIconsKeys] = useState([0]);
    const [deletedRightIcons, setDeletedRightIcons] = useState([]);
    const [rightIconToDelete, setRightIconToDelete] = useState(null);
    const [confirmRightIconVisible, setLConfirmRightIconVisible] = useState(false);

    const [footerSettings, setFooterSettings] = useState({
        settingKey: 'footer.text',
        settingValue: {},
        settingGroup: 'footer',
    });

    useEffect(() => {
        fetch();
    }, [props.visible]);

    useEffect(() => {
        props.form.resetFields();

        if (linksKeys) {
            for (const key of linksKeys) {
                if (formValues && formValues['link_left_' + key]) {
                    setWepPageValue('link_left_' + key, formValues['link_left_' + key].id);
                    delete formValues['link_left_' + key];
                }
            }
        }
        if (leftIconsKeys) {
            for (const key of leftIconsKeys) {
                if (formValues && formValues['link_icon_left_' + key]) {
                    setWepPageValue('link_icon_left_' + key, formValues['link_icon_left_' + key].id);
                    delete formValues['link_icon_left_' + key];
                }
            }
        }

        if (rightIconsKeys) {
            for (const key of rightIconsKeys) {
                if (formValues && formValues['link_icon_right_' + key]) {
                    setWepPageValue('link_icon_right_' + key, formValues['link_icon_right_' + key].id);
                    delete formValues['link_icon_right_' + key];
                }
            }
        }

        setFieldsValue(formValues);
    }, [formValues]);

    function fetch() {
        setFetching(true);
        requestGet(CONSTANTS_REQ.SETTINGS.ADMIN, {
            group: 'footer',
            key: 'footer.text',
            projectId: Session.getProjectId(),
        }).then((result) => {
            setFetching(false);
            if (result.status === RESPONSE.OK) {
                setValues(result.data);
            } else {
                console.log('response failed');
            }
        });
    }

    function setValues(component_data) {
        let vidArray = [];
        var json;
        if (component_data.settingValue) {
            json = JSON.parse(component_data.settingValue);
            //linkovi
            var max_key = 0;
            const linkesKeys = json.linksKeys ? json.linksKeys : [];
            for (const key of linkesKeys) {
                vidArray.push({
                    id: key,
                    linkTextJson: json['link_text_left_' + key],
                    externalLink: json['external-link_left_' + key],
                    type: json['type_' + key],
                });
                json = translationsToObject(json, 'link_text_left_' + key, json['link_text_left_' + key]);
                delete json['link_text_left_' + key];
                max_key = key > max_key ? key : max_key;
            }

            setLinkLastKey(max_key);
            setLinks(vidArray);
            setLinksKeys(json.linksKeys);
            if (linkesKeys.length > 0) {
                setActiveTabLink('' + json.linksKeys[0]);
            }

            delete json.linksKeys;

            //ikone lijevo
            max_key = 0;
            let leftIconArray = [];
            const leftIconsKeys = json.leftIconsKeys ? json.leftIconsKeys : [];
            for (const key of leftIconsKeys) {
                leftIconArray.push({
                    id: key,
                    externalLink: json['external-icon-link_left_' + key],
                    type: json['type_icon_left_' + key],
                });

                max_key = key > max_key ? key : max_key;
            }
            json = translationsToObject(json, 'title_left_icons', json['title_left_icons']);
            setIconLeftLastKey(max_key);
            setLeftIcons(leftIconArray);
            setLeftIconsKeys(json.leftIconsKeys);
            if (leftIconsKeys.length > 0) {
                setActiveTabIconLeft('' + json.leftIconsKeys[0]);
            }

            setTitleLeftIconJson(json['title_left_icons']);

            delete json.leftIconsKeys;
            delete json['title_left_icons'];
            //ikone desno
            max_key = 0;
            let rightIconArray = [];
            const rightIconsKeys = json.rightIconsKeys ? json.rightIconsKeys : [];
            for (const key of rightIconsKeys) {
                rightIconArray.push({
                    id: key,
                    externalLink: json['external-icon-link_right_' + key],
                    type: json['type_icon_right_' + key],
                });
                max_key = key > max_key ? key : max_key;
            }
            json = translationsToObject(json, 'title_right_icons', json['title_right_icons']);

            setIconRightLastKey(max_key);
            setRightIcons(rightIconArray);
            setRightIconsKeys(json.rightIconsKeys);
            if (rightIconsKeys.length > 0) {
                setActiveTabIconRight('' + json.rightIconsKeys[0]);
            }
            setTitleRightIconJson(json['title_right_icons']);

            delete json.rightIconsKeys;
            delete json['title_right_icons'];

            json = translationsToObject(json, 'text', json['text']);
            setTextContent(json.text);
            json = translationsToObject(json, 'footer_text', json['footer_text']);
            setFooterText(json['footer_text']);
            setFooterTextPosition(json['footer_text_position']);
            delete json['footer_text_position'];
            delete json['footer_text'];
            delete json['text'];
            setFormValues(json);
            if (linkesKeys.length == 0) {
                setLinksKeys([0]);
                setLinks([{ id: 0 }]);
            }

            if (leftIconsKeys.length == 0) {
                setLeftIconsKeys([0]);
                setLeftIcons([{ id: 0 }]);
            }

            if (rightIconsKeys.length == 0) {
                setRightIconsKeys([0]);
                setRightIcons([{ id: 0 }]);
            }
        } else {
            setLinksKeys([0]);
            setLinks([{ id: 0 }]);
            setLeftIconsKeys([0]);
            setLeftIcons([{ id: 0 }]);
            setRightIconsKeys([0]);
            setRightIcons([{ id: 0 }]);
        }
    }

    function setWepPageValue(key, id) {
        requestGet(CONSTANTS_REQ.WEBPAGE.EDIT + '/' + id).then((result) => {
            if (result.status === RESPONSE.OK) {
                let field = {};
                field[key] = { id: id, name: result.data.name };
                setFieldsValue(field);
            }
        });
    }

    function save(e, close) {
        e.preventDefault();
        props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                values = normalizeComponents(values);
                values.linksKeys = linksKeys;
                for (const key of linksKeys) {
                    values['link_left_' + key] =
                        values['link_left_' + key] != null && values['link_left_' + key].constructor === String
                            ? { id: parseInt(values['link_left_' + key]) }
                            : values['link_left_' + key];
                }

                //icons left
                if (leftIconsKeys != null) {
                    values.leftIconsKeys = leftIconsKeys;
                    for (const key of leftIconsKeys) {
                        const normalize = normalizeFileUpload('icon-left-path_' + key);
                        if (normalize != null) {
                            values['icon-left-path_' + key] = normalize;
                        }

                        values['link_icon_left_' + key] =
                            values['link_icon_left_' + key] != null &&
                            values['link_icon_left_' + key].constructor === String
                                ? { id: parseInt(values['link_icon_left_' + key]) }
                                : values['link_icon_left_' + key];
                    }

                    for (const deleted of deletedLeftIcons) {
                        if (values['icon-left-path_' + deleted]) {
                            delete values['icon-left-path_' + deleted];
                        }
                    }
                }

                //right icons
                if (rightIconsKeys != null) {
                    values.rightIconsKeys = rightIconsKeys;
                    for (const key of rightIconsKeys) {
                        let normalize = normalizeFileUpload('icon-right-path_' + key);
                        if (normalize != null) {
                            values['icon-right-path_' + key] = normalize;
                        }

                        values['link_icon_right_' + key] =
                            values['link_icon_right_' + key] != null &&
                            values['link_icon_right_' + key].constructor === String
                                ? { id: parseInt(values['link_icon_right_' + key]) }
                                : values['link_icon_right_' + key];
                    }

                    for (const deleted of deletedRightIcons) {
                        if (values['icon-right-path_' + deleted]) {
                            delete values['icon-right-path_' + deleted];
                        }
                    }
                }

                values['footer_text_position'] = footerTextPosition;

                let footerSet = footerSettings;
                footerSet.settingValue = JSON.stringify(values);
                if (!footerSettings.project) {
                    footerSet.project = { id: Session.getProjectId() };
                }
                const settingsArray = [footerSet];
                requestPost(CONSTANTS_REQ.SETTINGS.SAVE_SETTINGS, null, { settings: settingsArray }).then((resp) => {
                    if (resp.status == RESPONSE.OK) {
                        message.success(geti18nText('settings.save.success'));
                    } else {
                        message.error(geti18nText('settings.save.not'));
                    }
                    close && props.closePopup();
                });
            }
        });
    }

    function normalizeFileUpload(field) {
        let file = props.form.getFieldsValue([field])[field];
        if (file instanceof Array) {
            file = file[0];
        }
        if (file == null) {
            return null;
        }
        return file.id ? file : { file_path: file.file_path, name: file.name };
    }

    const onEditLeftIcons = (targetKey, action) => {
        if (action == 'add') {
            props.form.validateFieldsAndScroll((err, values) => {
                if (!err) {
                    let newId = iconLeftLastKey;
                    newId++;
                    let arrSlides = leftIcons.slice();
                    let arrKeys = leftIconsKeys.slice();
                    arrSlides.push({ id: newId });
                    arrKeys.push(newId);
                    setLeftIconsKeys(arrKeys);
                    setLeftIcons(arrSlides);
                    setActiveTabIconLeft('' + newId);
                    setIconLeftLastKey(newId);
                }
            });
        }
        if (action == 'remove') {
            if (leftIconsKeys.length > 1) {
                setLConfirmLeftIconVisible(true);
                setLeftIconToDelete(targetKey);
            }
        }
    };

    const deleteLeftIcon = () => {
        if (leftIconToDelete) {
            var newArrKeys = leftIconsKeys.filter((key) => key != parseInt(leftIconToDelete));
            var newArrVideos = leftIcons.filter((video) => video.id != parseInt(leftIconToDelete));
            setLeftIcons(newArrVideos);
            setLeftIconsKeys(newArrKeys);

            if (activeTabIconLeft == leftIconToDelete) {
                setActiveTabIconLeft('' + newArrKeys[0]);
            }
            setLConfirmLeftIconVisible(false);
            setLeftIconToDelete(null);
            let deleted = deletedLeftIcons.slice();
            deleted.push(leftIconToDelete);
            setDeletedLeftIcons(deleted);
        }
    };

    const tabLeftIconsOnChange = (activeKey) => {
        props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                setActiveTabIconLeft(activeKey);
            }
        });
    };
    // ikone desno

    const onEditRightIcons = (targetKey, action) => {
        if (action == 'add') {
            props.form.validateFieldsAndScroll((err, values) => {
                if (!err) {
                    let newId = iconRightLastKey;
                    newId++;
                    let arrSlides = rightIcons.slice();
                    let arrKeys = rightIconsKeys.slice();
                    arrSlides.push({ id: newId });
                    arrKeys.push(newId);
                    setRightIconsKeys(arrKeys);
                    setRightIcons(arrSlides);
                    setActiveTabIconRight('' + newId);
                    setIconRightLastKey(newId);
                }
            });
        }
        if (action == 'remove') {
            if (rightIconsKeys.length > 1) {
                setLConfirmRightIconVisible(true);
                setRightIconToDelete(targetKey);
            }
        }
    };

    const deleteRightIcon = () => {
        if (rightIconToDelete) {
            var newArrKeys = rightIconsKeys.filter((key) => key != parseInt(rightIconToDelete));
            var newArrVideos = rightIcons.filter((video) => video.id != parseInt(rightIconToDelete));
            setRightIcons(newArrVideos);
            setRightIconsKeys(newArrKeys);

            if (activeTabIconRight == rightIconToDelete) {
                setActiveTabIconLeft('' + newArrKeys[0]);
            }
            setLConfirmRightIconVisible(false);
            setRightIconToDelete(null);
            let deleted = deletedRightIcons.slice();
            deleted.push(rightIconToDelete);
            setDeletedRightIcons(deleted);
        }
    };

    const tabRightIconsOnChange = (activeKey) => {
        props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                setActiveTabIconRight(activeKey);
            }
        });
    };

    //

    const onEdit = (targetKey, action) => {
        if (action == 'add') {
            props.form.validateFieldsAndScroll((err, values) => {
                if (!err) {
                    let newId = linkLastKey;
                    newId++;
                    let arrSlides = links.slice();
                    let arrKeys = linksKeys.slice();
                    arrSlides.push({ id: newId });
                    arrKeys.push(newId);
                    setLinksKeys(arrKeys);
                    setLinks(arrSlides);
                    setActiveTabLink('' + newId);
                    setLinkLastKey(newId);
                }
            });
        }
        if (action == 'remove') {
            if (linksKeys.length > 1) {
                setConfirmVisible(true);
                setLinkToDelete(targetKey);
            }
        }
    };

    const tabOnChange = (activeKey) => {
        props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                setActiveTabLink(activeKey);
            }
        });
    };

    const deleteSlide = () => {
        if (linkToDelete) {
            var newArrKeys = linksKeys.filter((key) => key != parseInt(linkToDelete));
            var newArrVideos = links.filter((video) => video.id != parseInt(linkToDelete));
            setLinks(newArrVideos);
            setLinksKeys(newArrKeys);

            if (activeTabLink == linkToDelete) {
                setActiveTabLink('' + newArrKeys[0]);
            }
            setConfirmVisible(false);
            setLinkToDelete(null);
            let deleted = deletedLinks.slice();
            deleted.push(linkToDelete);
            setDeletedLinks(deleted);
        }
    };

    return (
        <React.Fragment>
            <Divider>{geti18nText('component.footercomponent')}</Divider>
            <Form>
                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item hidden={true}>{getFieldDecorator('id')(<Input hidden={true} />)}</Form.Item>

                        <Row style={{ marginTop: '20px ' }}>
                            <Collapse bordered={false} defaultActiveKey={['1', '2', '3', '4']}>
                                <Panel header={geti18nText('component.footercomponent.header.icons.left')} key="1">
                                    <InputByLanguage
                                        fieldName={'title_left_icons'}
                                        label={geti18nText('component.footercomponent.edit.text.icons')}
                                        langJson={titleLeftIconJson}
                                        notRequred
                                        {...props}
                                    />
                                    <Popconfirm
                                        placement="rightTop"
                                        title={geti18nText('app.default.button.delete')}
                                        visible={confirmLeftIconVisible}
                                        okText={geti18nText('app.default.button.yes')}
                                        cancelText={geti18nText('app.default.button.no')}
                                        onCancel={() => {
                                            setLConfirmLeftIconVisible(false);
                                        }}
                                        onConfirm={deleteLeftIcon}
                                    >
                                        <span></span>
                                    </Popconfirm>

                                    <Tabs
                                        type="editable-card"
                                        onEdit={onEditLeftIcons}
                                        onChange={tabLeftIconsOnChange}
                                        activeKey={activeTabIconLeft}
                                        key="left_icon_tabs"
                                    >
                                        {leftIcons.map((image, index) => (
                                            <TabPane
                                                tab={
                                                    geti18nText('component.footercomponent.type.icon') +
                                                    ' ' +
                                                    (index + 1)
                                                }
                                                key={image.id}
                                                forceRender={true}
                                            >
                                                <IconsLeftContent
                                                    index={image.id}
                                                    tileLangJson={image.titleLangJson}
                                                    textLangJson={image.textLangJson}
                                                    linkTextJson={image.linkTextJson}
                                                    externalLink={image.externalLink}
                                                    type={image.type}
                                                    {...props}
                                                />
                                            </TabPane>
                                        ))}
                                    </Tabs>
                                </Panel>
                                <Panel header={geti18nText('component.footercomponent.header.icons.right')} key="2">
                                    <InputByLanguage
                                        fieldName={'title_right_icons'}
                                        label={'Tekst ikona'}
                                        langJson={titleRightIconJson}
                                        notRequred
                                        {...props}
                                    />
                                    <Popconfirm
                                        placement="rightTop"
                                        title={geti18nText('app.default.button.delete')}
                                        visible={confirmRightIconVisible}
                                        okText={geti18nText('app.default.button.yes')}
                                        cancelText={geti18nText('app.default.button.no')}
                                        onCancel={() => {
                                            setLConfirmRightIconVisible(false);
                                        }}
                                        onConfirm={deleteRightIcon}
                                    >
                                        <span></span>
                                    </Popconfirm>

                                    <Tabs
                                        type="editable-card"
                                        onEdit={onEditRightIcons}
                                        onChange={tabRightIconsOnChange}
                                        activeKey={activeTabIconRight}
                                        key="right_icon_tabs"
                                    >
                                        {rightIcons.map((image, index) => (
                                            <TabPane
                                                tab={
                                                    geti18nText('component.footercomponent.type.icon') +
                                                    ' ' +
                                                    (index + 1)
                                                }
                                                key={image.id}
                                                forceRender={true}
                                            >
                                                <IconsRightContent
                                                    index={image.id}
                                                    tileLangJson={image.titleLangJson}
                                                    textLangJson={image.textLangJson}
                                                    linkTextJson={image.linkTextJson}
                                                    externalLink={image.externalLink}
                                                    type={image.type}
                                                    {...props}
                                                />
                                            </TabPane>
                                        ))}
                                    </Tabs>
                                </Panel>
                                <Panel header={geti18nText('component.footercomponent.header.icons.middle')} key="3">
                                    <Col span={24}>
                                        <InputByLanguage
                                            fieldName={'text'}
                                            label={geti18nText('component.footercomponent.header.icons.middle.text')}
                                            langJson={textContent}
                                            type="rteEditor"
                                            notRequred
                                            {...props}
                                        />
                                        <Row>
                                            <Col span={24}>
                                                <Popconfirm
                                                    placement="rightTop"
                                                    title={geti18nText('app.default.button.delete')}
                                                    visible={confirmVisible}
                                                    okText={geti18nText('app.default.button.yes')}
                                                    cancelText={geti18nText('app.default.button.no')}
                                                    onCancel={() => {
                                                        setConfirmVisible(false);
                                                    }}
                                                    onConfirm={deleteSlide}
                                                >
                                                    <span></span>
                                                </Popconfirm>
                                            </Col>
                                        </Row>
                                        <Tabs
                                            type="editable-card"
                                            onEdit={onEdit}
                                            onChange={tabOnChange}
                                            activeKey={activeTabLink}
                                        >
                                            {links.map((image, index) => (
                                                <TabPane
                                                    tab={
                                                        geti18nText('component.footercomponent.type.link') +
                                                        ' ' +
                                                        (index + 1)
                                                    }
                                                    key={image.id}
                                                    forceRender={true}
                                                >
                                                    <CentarContent
                                                        index={image.id}
                                                        tileLangJson={image.titleLangJson}
                                                        textLangJson={image.textLangJson}
                                                        linkTextJson={image.linkTextJson}
                                                        externalLink={image.externalLink}
                                                        type={image.type}
                                                        {...props}
                                                    />
                                                </TabPane>
                                            ))}
                                        </Tabs>
                                    </Col>
                                </Panel>
                                <Panel header={geti18nText('component.footercomponent.header.text.bottom')} key="4">
                                    <InputByLanguage
                                        fieldName={'footer_text'}
                                        label={geti18nText('component.footercomponent.header.text.bottom')}
                                        langJson={footerText}
                                        notRequred
                                        {...props}
                                    />
                                    <Col span={4}>
                                        <Form.Item
                                            label={geti18nText('component.footercomponent.header.text.bottom.position')}
                                        >
                                            <Select
                                                value={footerTextPosition}
                                                onSelect={(value) => {
                                                    setFooterTextPosition(value);
                                                }}
                                                style={{ width: '90%' }}
                                            >
                                                {['flex-start', 'center', 'flex-end'].map((value, index) => {
                                                    return (
                                                        <Option key={index} value={value}>
                                                            {geti18nText(
                                                                'component.footercomponent.header.text.bottom.position.' +
                                                                    value
                                                            )}
                                                        </Option>
                                                    );
                                                })}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Panel>
                            </Collapse>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col span={24} style={{ textAlign: 'right', marginTop: '1rem' }}>
                        <Button style={{ marginRight: '1em' }} onClick={(e) => props.closePopup()}>
                            {geti18nText('app.default.button.cancel')}
                        </Button>
                        <Button style={{ marginRight: '1em' }} type="primary" onClick={(e) => save(e, false)}>
                            {geti18nText('app.default.button.save')}
                        </Button>
                        <Button
                            type="primary"
                            style={{ background: '#31b531', borderColor: '#31b531', textColor: 'white' }}
                            onClick={(e) => {
                                save(e, true);
                            }}
                        >
                            {geti18nText('app.default.button.save.close')}
                        </Button>
                    </Col>
                </Row>
            </Form>
        </React.Fragment>
    );
});

const CentarContent = (props) => {
    const LINK_TYPE = {
        LINK: 'LINK',
        WEBPAGE: 'WEBPAGE',
    };
    const { getFieldDecorator, setFieldsValue } = props.form;
    const [linkType, setLinkType] = useState(props.type ? props.type : LINK_TYPE.WEBPAGE);

    return (
        <>
            <Row gutter={24}>
                <Col span={24}>
                    <Form.Item>
                        {getFieldDecorator('type_' + props.index, { initialValue: LINK_TYPE.WEBPAGE })(
                            <Radio.Group
                                onChange={(e) => {
                                    setLinkType(e.target.value);
                                }}
                            >
                                <Radio value={LINK_TYPE.WEBPAGE}>
                                    {geti18nText('component.footercomponent.edit.radio_button.webPage')}
                                </Radio>
                                <Radio value={LINK_TYPE.LINK}>
                                    {geti18nText('component.footercomponent.edit.external_link')}
                                </Radio>
                            </Radio.Group>
                        )}
                    </Form.Item>

                    {linkType == LINK_TYPE.LINK ? (
                        <Row gutter={24} type="flex" justify="space-between" align="bottom">
                            <Col span={16}>
                                <Form.Item label={geti18nText('component.footercomponent.edit.text_link')}>
                                    {getFieldDecorator('external-link_left_' + props.index)(
                                        <Input defaultValue={props.externalLink} />
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                    ) : (
                        <Form.Item label={geti18nText('component.footercomponent.edit.radio_button.webPage')}>
                            {getFieldDecorator('link_left_' + props.index)(
                                <SearchField
                                    url={CONSTANTS_REQ.WEBPAGE.SEARCH}
                                    map={{ id: 'id', label: 'name' }}
                                    searchBy="name"
                                    disabled={false}
                                    translate={true}
                                    addedData={{ projectId: Session.getProjectId(), forMap: true }}
                                />
                            )}
                        </Form.Item>
                    )}

                    <InputByLanguage
                        notRequred
                        fieldName={'link_text_left_' + props.index}
                        label={geti18nText('component.slidecontent.edit.text_link')}
                        notRequred={true}
                        langJson={props.linkTextJson}
                        {...props}
                    />
                </Col>
            </Row>
        </>
    );
};

const IconsRightContent = (props) => {
    const LINK_TYPE = {
        LINK: 'LINK',
        WEBPAGE: 'WEBPAGE',
    };
    const { getFieldDecorator, setFieldsValue } = props.form;
    const [linkType, setLinkType] = useState(props.type ? props.type : LINK_TYPE.WEBPAGE);

    return (
        <>
            <Row gutter={24}>
                <Col span={24}>
                    <Form.Item label={geti18nText('component.footercomponent.edit.icon')}>
                        {getFieldDecorator('icon-right-path_' + props.index)(
                            <FileUpload
                                fileType={CONSTANTS_FILE_TYPE.IMAGE}
                                map={{ id: 'id', label: 'name' }}
                                searchBy="name"
                                disabled={false}
                                addedData={{ projectId: Session.getProjectId() }}
                            />
                        )}
                    </Form.Item>
                    <Form.Item>
                        {getFieldDecorator('type_icon_right_' + props.index, { initialValue: LINK_TYPE.WEBPAGE })(
                            <Radio.Group
                                onChange={(e) => {
                                    setLinkType(e.target.value);
                                }}
                            >
                                <Radio value={LINK_TYPE.WEBPAGE}>
                                    {geti18nText('component.footercomponent.edit.radio_button.webPage')}
                                </Radio>
                                <Radio value={LINK_TYPE.LINK}>
                                    {geti18nText('component.footercomponent.edit.external_link')}
                                </Radio>
                            </Radio.Group>
                        )}
                    </Form.Item>

                    {linkType == LINK_TYPE.LINK ? (
                        <Row gutter={24} type="flex" justify="space-between" align="bottom">
                            <Col span={16}>
                                <Form.Item label={geti18nText('component.footercomponent.edit.external_link')}>
                                    {getFieldDecorator('external-icon-link_right_' + props.index)(
                                        <Input defaultValue={props.externalLink} />
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                    ) : (
                        <Form.Item label={geti18nText('component.footercomponent.edit.radio_button.webPage')}>
                            {getFieldDecorator('link_icon_right_' + props.index)(
                                <SearchField
                                    url={CONSTANTS_REQ.WEBPAGE.SEARCH}
                                    map={{ id: 'id', label: 'name' }}
                                    searchBy="name"
                                    disabled={false}
                                    translate={true}
                                    addedData={{ projectId: Session.getProjectId(), forMap: true }}
                                />
                            )}
                        </Form.Item>
                    )}
                </Col>
            </Row>
        </>
    );
};

const IconsLeftContent = (props) => {
    const LINK_TYPE = {
        LINK: 'LINK',
        WEBPAGE: 'WEBPAGE',
    };
    const { getFieldDecorator, setFieldsValue } = props.form;
    const [linkType, setLinkType] = useState(props.type ? props.type : LINK_TYPE.WEBPAGE);

    return (
        <>
            <Row gutter={24}>
                <Col span={24}>
                    <Form.Item label={geti18nText('component.footercomponent.edit.icon')}>
                        {getFieldDecorator('icon-left-path_' + props.index)(
                            <FileUpload
                                fileType={CONSTANTS_FILE_TYPE.IMAGE}
                                map={{ id: 'id', label: 'name' }}
                                searchBy="name"
                                disabled={false}
                                addedData={{ projectId: Session.getProjectId() }}
                            />
                        )}
                    </Form.Item>
                    <Form.Item>
                        {getFieldDecorator('type_icon_left_' + props.index, { initialValue: LINK_TYPE.WEBPAGE })(
                            <Radio.Group
                                onChange={(e) => {
                                    setLinkType(e.target.value);
                                }}
                            >
                                <Radio value={LINK_TYPE.WEBPAGE}>
                                    {geti18nText('component.footercomponent.edit.radio_button.webPage')}
                                </Radio>
                                <Radio value={LINK_TYPE.LINK}>
                                    {geti18nText('component.footercomponent.edit.external_link')}
                                </Radio>
                            </Radio.Group>
                        )}
                    </Form.Item>

                    {linkType == LINK_TYPE.LINK ? (
                        <Row gutter={24} type="flex" justify="space-between" align="bottom">
                            <Col span={16}>
                                <Form.Item label={geti18nText('component.footercomponent.edit.external_link')}>
                                    {getFieldDecorator('external-icon-link_left_' + props.index)(
                                        <Input defaultValue={props.externalLink} />
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                    ) : (
                        <Form.Item label={geti18nText('component.footercomponent.edit.radio_button.webPage')}>
                            {getFieldDecorator('link_icon_left_' + props.index)(
                                <SearchField
                                    url={CONSTANTS_REQ.WEBPAGE.SEARCH}
                                    map={{ id: 'id', label: 'name' }}
                                    searchBy="name"
                                    disabled={false}
                                    translate={true}
                                    addedData={{ projectId: Session.getProjectId(), forMap: true }}
                                />
                            )}
                        </Form.Item>
                    )}
                </Col>
            </Row>
        </>
    );
};

export default FooterComponentEdit;
