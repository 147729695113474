import { Col, Form, Row } from 'antd';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { SearchField } from '../../../components//Search';
import { DataEdit } from '../../../components/DataEdit';
import { refreshMenu } from '../../../slices/menuSlice';
import { CONSTANTS_REQ } from '../../../utils/Constants';
import { geti18nText, Session } from '../../../utils/Session';
import { selectPageType } from '../../../slices/webPageSlice';

const WebPageExternalAdd = Form.create({ name: 'WebPageEdit' })((props) => {
    const { getFieldDecorator, setFieldsValue } = props.form;
    const [loading, setLoading] = useState(false);
    const [closeWebPageModeal, setCloseWebPageModeal] = useState(false); // kod kreiranje novog page-a, prvi save ne zatvara popup - prikazuje komponente stranica
    const [pageID, setPageID] = useState(0);
    const [pageSearchDisabled, setPageSearchDisabled] = useState(true);
    const [projectSearchDisabled, setProjectSearchDisabled] = useState(false);
    const [selectedProjectId, setSelectedProjectId] = useState(0);
    const { selectedPageType } = useSelector((state) => state.webPageSlices);
    const [menuPageType, setMenuPageType] = useState(0);

    const dispatch = useDispatch();
    let history = useHistory();

    function shouldCloseModal() {
        return closeWebPageModeal;
    }

    function pageSaved(page) {
        setPageSearchDisabled(!page.active ? true : false);
        setProjectSearchDisabled(!page.active ? true : false);
        setFieldsValue(page);
    }

    function setValues(webPage) {
        setPageID(webPage.id);
        if (webPage.project.id) {
            setPageSearchDisabled(!webPage.active ? true : false);
            setProjectSearchDisabled(!webPage.active ? true : false);
            setPageID(webPage.page.id);
            setSelectedProjectId(webPage.project.id);
        }
        setMenuPageType(webPage.pageType.id);
        delete webPage.id;
        delete webPage.active;
        console.log('setFields:' + webPage);
        setFieldsValue(webPage);
    }

    function setDefaultValues() {
        props.form.resetFields();
        setProjectSearchDisabled(false);
        setPageSearchDisabled(true);
        setMenuPageType(parseInt(selectedPageType));
        dispatch(
            selectPageType({
                selectedPageType: 0,
            })
        );
    }

    function normalizeValues(values) {
        let normalized = null;
        normalized = {
            webPagePageType: {
                id: {
                    webPage: parseInt(values.page.id ? values.page.id : values.page),
                    pageType: parseInt(menuPageType),
                },
                active: values.active,
            },
            webPageId: pageID,
        };
        return normalized;
    }

    function onSelectComponentProject(component) {
        if (component) {
            setFieldsValue({ page: undefined });
            setPageSearchDisabled(false);
            setSelectedProjectId(parseInt(component.key));
        } else {
            setFieldsValue({ page: undefined });
            if (!pageSearchDisabled) setPageSearchDisabled(true);
        }
    }

    function onSelectPage(page) {
        if (!page) {
            page = null;
        }
    }

    function onSaveGetId(id) {
        setPageID(id.webPage);
        history.push('/webPage/external/' + id.webPage + '-' + menuPageType);
    }

    function onSave() {
        let refreshNodes = [];
        refreshNodes.push('TYPE-' + menuPageType);
        dispatch(
            refreshMenu({
                refreshLastNode: false,
                refreshProjectNode: false,
                refreshNodes: refreshNodes,
            })
        );
    }

    return (
        <DataEdit
            editHeader={geti18nText('webpage.page.type.header')}
            loading={loading}
            setLoading={setLoading}
            url={CONSTANTS_REQ.WEB_PAGE_PAGE_TYPE.EDIT}
            shouldCloseModal={shouldCloseModal}
            setValues={setValues}
            setDefaultValues={setDefaultValues}
            width={1200}
            {...props}
            onSaveAndGetData={pageSaved}
            onSaveAndGetID={onSaveGetId}
            buttonsPosition="left"
            hideCancelButton={true}
            onSave={onSave}
            normalize={normalizeValues}
        >
            <Row gutter={24} type="flex" justify="space-between" align="bottom">
                <Col span={12}>
                    <Form.Item label={geti18nText('webpage.project')}>
                        {getFieldDecorator('project', {
                            rules: [{ required: true, message: geti18nText('app.default.required') }],
                        })(
                            <SearchField
                                onChange={onSelectComponentProject}
                                url={CONSTANTS_REQ.PROJECT.SEARCH}
                                map={{ id: 'id', label: 'text' }}
                                addedSearch={{
                                    field: 'id',
                                    condition: 'not_equals',
                                    value: Session.getProjectId(),
                                }}
                                searchBy="name"
                                disabled={projectSearchDisabled}
                                translate={false}
                            />
                        )}
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label={geti18nText('webpage.page')}>
                        {getFieldDecorator('page', {
                            rules: [{ required: true, message: geti18nText('app.default.required') }],
                        })(
                            <SearchField
                                url={CONSTANTS_REQ.WEBPAGE.SEARCH}
                                map={{ id: 'id', label: 'name' }}
                                searchBy="name"
                                onChange={onSelectPage}
                                disabled={pageSearchDisabled}
                                translate={true}
                                addedData={{ projectId: selectedProjectId, forPage: true }}
                            />
                        )}
                    </Form.Item>
                </Col>
            </Row>
        </DataEdit>
    );
});

export default WebPageExternalAdd;
