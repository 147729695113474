import { CONSTANTS_REQ } from './Constants';
import { requestGet, requestPost, RESPONSE } from './RequestResolver';
import { loadDefaults, loadSession, saveSession } from './Utils';

export const Session = (function () {
    let session = { app: undefined, user: undefined, project: undefined };
    return {
        init: async () => {
            const defaults = loadDefaults();
            session = loadSession();
            if (!session) {
                session = { app: undefined, user: undefined, project: undefined };
            }
            const resp = await requestGet(CONSTANTS_REQ.INIT, defaults);
            if (resp.status === RESPONSE.OK) {
                session.app = resp.data;
                saveSession(session);
            }
        },
        getSetting: (key) => {
            if (session && session.app && session.app.SETTINGS) {
                return session.app.SETTINGS[key];
            }
            return undefined;
        },
        getAppValue: (key) => {
            if (session && session.app) {
                return session.app[key];
            }
            return '';
        },
        getUser: () => {
            return session.user;
        },
        getUserToken: () => {
            if (session.user && session.user.access_token) {
                return session.user.access_token;
            }
            return undefined;
        },
        isUserAuthenticated: () => {
            if (session.user && Session.getUserToken()) {
                return true;
            }
            return false;
        },
        loginUser: async (username, password) => {
            const loginData = {
                username: username,
                password: password,
            };

            const response = await requestPost(CONSTANTS_REQ.LOGIN, undefined, loginData);
            if (response.status === RESPONSE.OK) {
                session.user = response.data;
                saveSession(session);
                return true;
            }
            return false;
        },
        logoutUser: () => {
            session.user = undefined;
            saveSession(session);
        },
        isLanugageLoaded() {
            return session.app && session.app.I18N && session.app.I18N.messages;
        },
        isTranslationsLoaded() {
            return session.app && session.app.translations;
        },
        setProject(project) {
            session.project = project;
            saveSession(session);
        },
        getRefreshToken: () => {
            session = loadSession();
            if (session && session.user && session.user.refresh_token) {
                return session.user['refresh_token'];
            }
            return undefined;
        },
        getTokenExpiration: () => {
            if (session && session.user && session.user.expires_at) {
                return session.user['expires_at'];
            }
            return undefined;
        },
        requestRefreshToken: async () => {
            if (Session.getRefreshToken() === undefined) {
                return false;
            }
            const response = await fetch(CONSTANTS_REQ.REFRESHTOKEN, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    grant_type: 'refresh_token',
                    refresh_token: Session.getRefreshToken(),
                }),
            });
            if (response.status === RESPONSE.OK) {
                const data = await response.json();
                if (data.expires_in != undefined) {
                    session.user.expires_in = data.expires_in;
                    session.user.expires_at = Date.now() + (data.expires_in - 5) * 1000;
                }
                session.user.access_token = data.access_token;
                session.user.token_type = data.token_type;
                saveSession(session);
                return true;
            } else {
                return false;
            }
        },
        getProjectId() {
            if (session && session.project) {
                return session.project.id;
            }
            return undefined;
        },
        getProjectInfo() {
            if (session && session.project) {
                return session.project;
            }
            return '';
        },
        getProjectDefaultLanguage() {
            if (session && session.project && session.project.defaultLanguage) {
                return session.project.defaultLanguage;
            }
            return session.app.I18N.language.default ? session.app.I18N.language.default : 'hr';
        },
        setProjectPageTypes(pageTypes) {
            session.pageTypes = pageTypes;
            saveSession(session);
        },
        getProjectPageTypes() {
            if (session && session.pageTypes) {
                return session.pageTypes;
            }
            return [];
        },
        getComponentsForSearch() {
            if (session && Session.getAppValue('COMPONENTS') != null) {
                return Session.getAppValue('COMPONENTS')
                    .filter((fObj) => {
                        if (!fObj.componentFooterMenu) {
                            return fObj;
                        }
                    })
                    .map((obj) => {
                        let rObj = {};
                        rObj.id = obj.id;
                        rObj.text = geti18nText('component.' + obj.name);
                        return rObj;
                    });
            }
            return [];
        },
        // napisani novi return za listaje u tablicu
        //f6123
        getComponentsForTable() {
            if (session && Session.getAppValue('COMPONENTS') != null) {
                return Session.getAppValue('COMPONENTS')
                    .filter((fObj) => {
                        if (!fObj.componentFooterMenu) {
                            return fObj;
                        }
                    })
                    .map((obj) => {
                        let rObj = {};
                        rObj.id = obj.id;
                        rObj.text = geti18nText('component.' + obj.name);
                        rObj.name = obj.name;
                        rObj.order = obj.order;
                        rObj.description = obj.description;
                        rObj.type = obj.type;
                        rObj.active = obj.active;
                        rObj.adminComponent = obj.adminComponent;
                        rObj.userComponent = obj.userComponent;
                        return rObj;
                    });
            }
            return [];
        },
        getComponentTypes() {
            if (session && Session.getAppValue('COMPTYPES') != null) {
                return Session.getAppValue('COMPTYPES').map((obj) => {
                    let rObj = {};
                    rObj.type = obj;
                    return rObj;
                });
            }
            return [];
        },
        getComponentName(id) {
            if (session && Session.getAppValue('COMPONENTS') != null) {
                let comp;
                for (comp of Session.getAppValue('COMPONENTS')) {
                    if (comp.id == id) {
                        return geti18nText('component.' + comp.name);
                    }
                }
            }
            return '';
        },
        getComponentAdmin(id) {
            if (session && Session.getAppValue('COMPONENTS') != null) {
                let comp;
                for (comp of Session.getAppValue('COMPONENTS')) {
                    if (comp.id == id) {
                        return comp.adminComponent;
                    }
                }
            }
            return '';
        },
        getComponentUser(id) {
            if (session && Session.getAppValue('COMPONENTS') != null) {
                let comp;
                for (comp of Session.getAppValue('COMPONENTS')) {
                    if (comp.id == id) {
                        return comp.userComponent;
                    }
                }
            }
            return '';
        },
        setAppLanguages: async () => {
            const resp = await requestGet(CONSTANTS_REQ.LANG.FORAPP);
            if (resp.status === RESPONSE.OK) {
                let project = Session.getProjectInfo();
                if (project == '') {
                    project = {
                        projectId: 0,
                        projectName: '',
                        themeShortName: '',
                    };
                }
                project.languages = resp.data;
                if (resp.data && resp.data.length > 0) {
                    project.defaultLanguage = Object.keys(resp.data[0])[0];
                }
                Session.setProject(project);
            }
        },
    };
})();

export const geti18nText = (key, args = null) => {
    if (Session.isLanugageLoaded()) {
        let value = Session.getAppValue('I18N').messages[key];
        if (value) {
            if (args) {
                for (var i = 0; i < args.length; i++) {
                    value = value.replace('{' + i + '}', args[i]);
                }
            }
            return value;
        }
    }
    return '';
};

export const getTranslation = (key, lang = Session.getProjectDefaultLanguage()) => {
    if (key == undefined || key == '') {
        return '';
    }
    if (Session.isTranslationsLoaded()) {
        let value = Session.getAppValue('translations')[key];
        if (value) {
            let translations = JSON.parse(value);
            return translations[lang];
        }
    }
    return '';
};

export const hasAnyRole = (roles) => {
    if (Session.isUserAuthenticated() && Session.getUser().roles) {
        let role;
        for (role of roles) {
            if (Session.getUser().roles.includes(role)) {
                return true;
            }
        }
    }
    return false;
};

export const ENUMS = (function () {
    return {
        ACTIVE: () => {
            return [
                { id: 0, text: geti18nText('app.default.active.0') },
                { id: 1, text: geti18nText('app.default.active.1') },
            ];
        },
    };
})();
