import React, { useState, useEffect } from 'react';
import { Form, Row, Col, Input } from 'antd';
import { CONSTANTS_REQ, CONSTANTS_FILE_TYPE } from '../../../utils/Constants';
import { DataEdit } from '../../../components/DataEdit';
import { InputByLanguage, translationsToObject, getTranslated } from '../../../components/InputByLanguage';
import { geti18nText } from '../../../utils/Session';
import { FileUpload } from '../../../components/fileUpload/FileUpload';

const TranslationsEdit = Form.create({ name: 'TranslationsEdit' })((props) => {
    const { getFieldDecorator, setFieldsValue } = props.form;
    const [editHeader, setEditHeader] = useState(geti18nText('facility.edit.new')); 
    const [loading, setLoading] = useState(false);
    const [textJson, setTextJson] = useState(null);
    const [translationKey, setTranslationKey] = useState(null);
    const [disabledTranslationKey, setDisabledTranslationKey] = useState(false);

    function setDefaultValues() {
        setEditHeader(geti18nText('translations.edit.new'));
        props.form.resetFields();
        setTextJson(null);
        setDisabledTranslationKey(false);
        setTranslationKey("");
    }


    function setValues(data) {
        setEditHeader(geti18nText('translations.edit.edit'));
        data = translationsToObject(data, 'text', data.text);
        setTextJson(data.text);
        setTranslationKey(data.key);
        setFieldsValue(data);
        setDisabledTranslationKey(true);
    }

    function onSave() {
        props.onSave();
    }

    return (
        <DataEdit
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            url={CONSTANTS_REQ.TRANSLATIONS.EDIT}
            setDefaultValues={setDefaultValues}
            setValues={setValues}
            width={1200}
            {...props}
            onSave={onSave}
            hideActivationButtons={true}
        >
            <Row gutter={24}>
                <Col span={24}>
                    <Form.Item hidden={true}>{getFieldDecorator('id')(<Input hidden={true} />)}</Form.Item>
                    <Form.Item label={geti18nText('translations.edit.key')} >
                        {getFieldDecorator('key', { rules: [{ required: true, message: geti18nText("app.default.required") }]} )(<Input disabled={disabledTranslationKey} />)}
                    </Form.Item>
                    <Form.Item>
                        <InputByLanguage
                            disabled={loading}
                            fieldName="text"
                            label={geti18nText('translations.edit.text')}
                            langJson={textJson}
                            {...props}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </DataEdit>
    );
});

export default TranslationsEdit;