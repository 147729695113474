import { Col, Divider, Form, Row, Tabs, notification,Button,Input,Popconfirm,Radio } from 'antd';
import React, { useEffect, useState } from 'react';
import { normalizeJSONForComponent } from '../../utils/ComponentHelper';
import { CONSTANTS_FILE_TYPE, CONSTANTS_REQ } from '../../utils/Constants';
import { RESPONSE, requestGet, requestPut } from '../../utils/RequestResolver';
import { Session, geti18nText } from '../../utils/Session';
import { normalizeComponents } from '../../utils/Utils';
import { InputByLanguage, translationsToObject } from '../InputByLanguage';
import { Spinner } from '../Spinner';
import { FileUpload } from '../fileUpload/FileUpload';
import { SearchField } from '../../components/Search';


const BrandAreaYlEdit = Form.create({ name: 'BrandAreaYlEdit' })((props) => {
    const { getFieldDecorator, setFieldsValue } = props.form;
    const [fetching, setFetching] = useState(false);
    const [tabKeys, setTabKeys] = useState([0,1,2,3,4]);
    const [tabs, setTabs] = useState([]);
    const [lastKey, setLastKey] = useState(0);
    const [formValues, setFormValues] = useState(null);
    const [activeTab, setActiveTab] = useState('0');
    const [deleteConfirmVisible, setDeleteConfirmVisible] = useState(false);
    const [tabsToDelete, setTabsToDelete] = useState(null);
    const [tabsArrSize, setTabsArrSize] = useState();
    const [deletedTabs, setDeletedTabs] = useState([]);
    const [title, setTitle] = useState(undefined);

    const { TabPane } = Tabs;
    useEffect(() => {
        fetch();
    }, [props.visible]);

    useEffect(() => {
        props.form.resetFields();
        setFieldsValue(formValues);
    }, [formValues]);

    function fetch() {
        setFetching(true);
        requestGet(CONSTANTS_REQ.WEBPAGECOMPONENT.EDIT + '/' + props.id).then((result) => {
            setFetching(false);
            if (result.status === RESPONSE.OK) {
                setValues(result.data);
            }
        });
    }
    
    function showNotification(title, message, duration, type) {
        notification[type]({
            message: title,
            description: message,
            duration: duration,
        });
    }

    function okNotification() {
        showNotification(geti18nText('app.default.save.ok'), geti18nText('app.default.save.ok.desc'), 3, 'success');
    }

    function error(message) {
        showNotification(geti18nText('app.default.save.nok'), message, 3, 'error');
    } 

    function setValues(component_data) {
        let tabArray = [];
        var json;
        if (component_data.json) {
            json = JSON.parse(component_data.json);

            let max_key = 0;
            for (const key of json.tab_keys) {
                tabArray.push({
                    id: key,
                    linkType: json['tab_' + key].linkType
                   
                });
                
                if(json['tab_' + key].file_path) {
                    json['tab_' + key + '-file_path'] = {
                        key: json['tab_' + key].file_path,
                        file_path: json['tab_' + key].file_path,
                    };
                }
                if(json['tab_' + key].linkType) {
                    json['tab_' + key + '-linkType'] = json['tab_' + key].linkType
                }
                if(json['tab_' + key].link) {
                    json['tab_' + key + '-link'] = {
                        id: json['tab_' + key].link,
                    };
                    setWepPageValue('tab_' + key + '-link',parseInt(json['tab_' + key].link) )
                }
                if(json['tab_' + key].externalLink) {
                    json['tab_' + key + '-externalLink'] = json['tab_' + key].externalLink
                }
                max_key = key > max_key ? key : max_key;
            }
            
            setLastKey(max_key);
            //json = translationsToObject(json, 'title', json['title']);
            setTabs(tabArray);
            setTabKeys(json.tab_keys);
            setTabsArrSize(tabArray.length);

            setActiveTab('' + json.tab_keys[0]);
            
            json = translationsToObject(json, 'title', json.title);
            setFormValues(json);
        }
        else {
            setTabKeys([0,1,2,3,4]);
            setTabs([{ id: 0 },{ id: 1 }, { id: 2 }, {id:3},{id:4}]);
        }
    }

    function normalize(values, translationKeys) {
        let jsonFieldGroups = ['tab_keys','title'];
        let jsonFieldKeys = {};
        let imageJsonArr = ['file_path','linkType', 'link', 'externalLink'];
        tabKeys.forEach((imageIndex) => {
            jsonFieldGroups.push('tab_' + imageIndex.toString());
            jsonFieldKeys['tab_' + imageIndex.toString()] = imageJsonArr;
        });
        return normalizeJSONForComponent(values, undefined, jsonFieldGroups, jsonFieldKeys, props);
    }

    function setWepPageValue(key, id) {
        requestGet(CONSTANTS_REQ.WEBPAGE.EDIT + '/' + id).then((result) => {
            if (result.status === RESPONSE.OK) {
                let field = {};
                field[key] = { id: id, name: result.data.name };
                setFieldsValue(field);
            }
        });
    }

    const deleteTabs = () => {
        if (tabsToDelete) {
            var newArrKeys = tabKeys.filter((key) => key != parseInt(tabsToDelete));
            var newArrTabs = tabs.filter((image) => image.id != parseInt(tabsToDelete));
            setTabKeys(newArrKeys);
            setTabs(newArrTabs);
            setTabsArrSize(tabsArrSize - 1);
            setLastKey(lastKey - 1);

            if (activeTab == tabsToDelete) {
                setActiveTab('' + newArrKeys[0]);
            }

            setFieldsValue(newArrTabs);
            setDeleteConfirmVisible(false);
            setTabsToDelete(null);
            let deleted = deletedTabs.slice();
            deleted.push(tabsToDelete);
            setDeletedTabs(deleted);
        }
    };

    

    function save(e, close) {
        e.preventDefault();
        console.log(tabKeys)
        props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                values = normalizeComponents(values);
                values.tab_keys = tabKeys;                
                values = normalize(values);
                requestPut(CONSTANTS_REQ.WEBPAGECOMPONENT.UPDATEJSON + '/' + props.id, null, {
                    id: props.id,
                    json: values.json,
                }).then((result) => {
                    if (result && result.status === RESPONSE.CREATED) {
                        console.log('okNotification');
                        okNotification();
                        close && props.closePopup();
                    } else {
                        error();
                        if (result && result.status === RESPONSE.BAD_REQUEST) {
                            if (result.data && result.data.field) {
                                switch (result.data.field) {
                                    case 'mandatory_fields_required':
                                        console.log(geti18nText('app.default.mandatory_fields_required'));
                                        return;
                                    default:
                                        break;
                                }
                            }
                        }
                        if (result.data && result.data.error) {
                            console.log(JSON.stringify(result.data.error));
                        }
                    }
                   
                });
            }
        });
    }

    function normalizeFileUpload(field) {
        let file = props.form.getFieldsValue([field])[field];
        if (file instanceof Array) {
            file = file[0];
        }
        return file.id ? file : { file_path: file.file_path, name: file.name };
    }

   

    const onEdit=(targetKey, action)=>{
        if (action == 'add') {
                    let newId = tabKeys[(tabKeys.length - 1)];
                    newId++;
                   
                    let arrImages = tabs.slice();
                    let arrKeys = tabKeys.slice();
                    
                    arrImages.push({ id: newId });
                    arrKeys.push(newId);
                    setTabKeys(arrKeys);
                    setTabs(arrImages);
                    setActiveTab('' + newId);
                    setLastKey(newId);
            
        }
        if (action == 'remove') {
            if (tabKeys.length > 1) {
                setDeleteConfirmVisible(true);
                setTabsToDelete(targetKey);
                //deleteTabs();
            }
        }
    }

    const tabOnChange = (activeKey) => {
        setActiveTab(activeKey);
        
    };

    if (fetching) {
        return <Spinner />;
    } else {
        return (
            <React.Fragment>
                <Divider>{geti18nText('component.brandAreaYl')}</Divider>
                <Form>
                <InputByLanguage
                        notRequred
                        fieldName={'title'}
                        label={geti18nText('component.featureAreaYl.title')}
                        langJson={title}
                        {...props}
                />
                <Row >
                        <Col span={24}>
                            <Popconfirm
                                placement="rightTop"
                                title={geti18nText('app.default.button.delete')}
                                visible={deleteConfirmVisible}
                                okText={geti18nText('app.default.button.yes')}
                                cancelText={geti18nText('app.default.button.no')}
                                onCancel={() => {
                                    setDeleteConfirmVisible(false);
                                }}
                                onConfirm={deleteTabs}
                            >
                                <span></span>
                            </Popconfirm>
                        </Col>
                    </Row>
                <Tabs type="editable-card" onEdit={onEdit} onChange={tabOnChange} activeKey={activeTab}>
                                {tabs &&
                                    tabs.map((tab, index) => (
                                        <TabPane tab={'Tab ' + (index + 1)} key={tab.id} forceRender={true}>
                                            <TabItem
                                                index={tab.id}
                                                smallTitleJson={tab.smallTitleJson}
                                                titleJson={tab.titleJson}
                                                priceCurrencyJson={tab.priceCurrencyJson}
                                                priceValueJson={tab.priceValueJson}
                                                pricePeriodJson={tab.pricePeriodJson}
                                                priceDescriptionJson={tab.priceDescriptionJson}
                                                buttonJson={tab.buttonJson}
                                                {...props}
                                            />
                                        </TabPane>
                                    ))}
                            </Tabs>
                    
                </Form>
                <Row>
                        <Col span={24} style={{ textAlign: 'right', marginTop: '1rem' }}>
                            <Button style={{ marginRight: '1em' }} onClick={(e) => props.closePopup()}>
                                {geti18nText('app.default.button.cancel')}
                            </Button>
                            <Button style={{ marginRight: '1em' }} type="primary" onClick={(e) => save(e, false)}>
                                {geti18nText('app.default.button.save')}
                            </Button>
                            <Button
                                type="primary"
                                style={{ background: '#31b531', borderColor: '#31b531', textColor: 'white' }}
                                onClick={(e) => {
                                    save(e, true);
                                }}
                            >
                                {geti18nText('app.default.button.save.close')}
                            </Button>
                        </Col>
                    </Row>
            </React.Fragment>
        );
    }
});
const TabItem = (props) => {
    const { getFieldDecorator, setFieldsValue } = props.form;
    const LINK_TYPE = {
        LINK: 'LINK',
        WEBPAGE: 'WEBPAGE',
    };
    const [linkTypeTerms, setLinkTypeTerms] = useState(props.type ? props.type : LINK_TYPE.WEBPAGE);

    return (
        <>
            <Row gutter={24}>
                <Col span={24}>
                <Form.Item label={geti18nText('component.featureAreaYl.image')}>
                        {getFieldDecorator('tab_' + props.index + '-file_path', )(
                            <FileUpload
                                fileType={CONSTANTS_FILE_TYPE.IMAGE}
                                map={{ id: 'id', label: 'name' }}
                                searchBy="name"
                                disabled={false}
                                addedData={{ projectId: Session.getProjectId() }}
                            />
                        )}
                    </Form.Item>
                    <Form.Item>
                                {getFieldDecorator('tab_' + props.index + '-linkType', { initialValue: LINK_TYPE.WEBPAGE })(
                                    <Radio.Group
                                        onChange={(e) => {
                                            setLinkTypeTerms(e.target.value);
                                        }}
                                    >
                                        <Radio value={LINK_TYPE.WEBPAGE}>
                                            {geti18nText('component.footercomponent.edit.radio_button.webPage')}
                                        </Radio>
                                        <Radio value={LINK_TYPE.LINK}>
                                            {geti18nText('component.footercomponent.edit.external_link')}
                                        </Radio>
                                    </Radio.Group>
                                )}
                            </Form.Item>
                    

                            {linkTypeTerms == LINK_TYPE.LINK ? (
                                <Row gutter={24} type="flex" justify="space-between" align="bottom">
                                    <Col span={16}>
                                        <Form.Item label={geti18nText('component.footercomponent.edit.text_link')}>
                                            {getFieldDecorator('tab_' + props.index + '-externalLink')(
                                                <Input defaultValue={props.externalLink} />
                                            )}
                                        </Form.Item>
                                    </Col>
                                </Row>
                            ) : (
                                <Form.Item label={geti18nText('component.footercomponent.edit.radio_button.webPage')}>
                                    {getFieldDecorator('tab_' + props.index + '-link')(
                                        <SearchField
                                            url={CONSTANTS_REQ.WEBPAGE.SEARCH}
                                            map={{ id: 'id', label: 'name' }}
                                            searchBy="name"
                                            disabled={false}
                                            translate={true}
                                            addedData={{ projectId: Session.getProjectId(), forMap: true }}
                                        />
                                    )}
                                </Form.Item>
                            )}
                    
                </Col>
            </Row>
        </>
    );
};
export default BrandAreaYlEdit;
