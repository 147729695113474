import React, { useState } from "react";
import { Upload, message, Button, Modal, Icon } from "antd";
import { Session, geti18nText } from "../../../utils/Session";
import { CONSTANTS_REQ } from "../../../utils/Constants";
import { Spinner } from "../../../components/Spinner";

const { Dragger } = Upload;

const DiskFileUpload = props => {
    const [uploadVisible, setUploadVisible] = useState(false);
    const [uploading, setUploading] = useState(false);

    const showUploadModal = () => {
        setUploadVisible(true);
    };

    const closeUploadModal = () => {
        setUploadVisible(false);
    };

    const uploadFile = {
        name: "file",
        action: CONSTANTS_REQ.DISK_FILE.UPLOAD,
        showUploadList: false,
        multiple: false,
        data: { projectId: Session.getProjectId() },
        headers: {
            Authorization: "Bearer " + Session.getUserToken()
        },
        onChange(info) {
            if (info.file.status === "uploading" && uploading === false) {
                setUploading(true);
            }
            if (info.file.status === "done") {
                setUploading(false);
                props.onPopupSave();
                message.destroy();
                message.success(`${geti18nText("upload.text.file")} ${geti18nText("upload.text.file")} ${info.file.name} ${geti18nText("upload.text.done")}`);
                setUploadVisible(false);
            } else if (info.file.status === "error") {
                setUploading(false);
                message.destroy();
                message.error(`${geti18nText("upload.text.file")} ${info.file.name} ${geti18nText("upload.text.error")}`);
            }
        }
    };

    return (
        <React.Fragment>
            <Button className="margin-left" type="primary" disabled={props.loading || uploading} onClick={showUploadModal}>
                {geti18nText("upload.add")}
            </Button>

            <Modal title={geti18nText("upload.title")} visible={uploadVisible} onCancel={closeUploadModal} destroyOnClose={true} footer={null}>
                <Dragger {...uploadFile}>
                    {uploading ? (
                        <React.Fragment>
                            <Spinner></Spinner>
                            <p className="ant-upload-hint">{geti18nText("upload.text.uploading")}</p>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <p className="ant-upload-drag-icon">
                                <Icon type="inbox" />
                            </p>
                            <p className="ant-upload-hint">{geti18nText("upload.text")}</p>
                        </React.Fragment>
                    )}
                </Dragger>
            </Modal>
        </React.Fragment>
    );
};

export default DiskFileUpload;
