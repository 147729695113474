import AccountList from '../../views/admin/account/AccountList';
import PageTypeList from '../../views/admin/page_type/PageTypeList';
import PageTypeEdit from '../../views/admin/page_type/PageTypeEdit';
import LocationList from '../../views/admin/location/LocationList';
import MapPinList from '../../views/admin/mapPin/MapPinList';
import WebPageEdit from '../../views/admin/web_page/WebPageEdit';
import DiskFileList from '../../views/admin/diskFile/DiskFileList';
import LangList from '../../views/admin/lang/LangList';
import FacilityList from '../../views/admin/facility/FacilityList';
import MainFacilityList from '../../views/admin/facility/MainFacilityList';
import DefaultPage from '../../views/admin/defaultPage';
import SettingsIndex from '../../views/admin/settings/SettingsIndex';
import ProjectManagementIndex from '../../views/admin/projectManagement/ProjectManagementIndex';
import SearchTypeEdit from '../../views/admin/searchContentType/SearchTypeEdit';
import BulletinList from '../../views/admin/bulletin/BulletinList';
import LocationEdit from '../../views/admin/location/LocationEdit';
import FacilityGroupEdit from '../../views/admin/facility/FacilityGroupEdit';
import DirectoryContent from '../../views/admin/directory/Directory';
import File from '../../views/admin/file/file';
import WebPageExternalAdd from '../../views/admin/web_page/WebPageExternalAdd';
import WebPageExternalLinkAdd from '../../views/admin/web_page/WebPageExternalLinkAdd';
import TranslationsList from '../../views/admin/translations/TranslationsList';
import DomainList from '../../views/admin/domain/DomainList';
import MobileApp from '../../views/admin/mobileApp/MobileApp';
import ReportedProblemList from '../../views/admin/reportedProblem/ReportedProblemList';
import DesignSettings from '../../views/admin/designSettings/DesignSettingsIndex';
import ComponentList from '../../views/admin/componentList/ComponentList';
import BlogList from '../../views/admin/blog/BlogList';
import FaqCategoryList from '../../views/admin/faq-category/FaqCategoryList';
import FaqQuestionAnswerList from '../../views/admin/faq-question-answer/FaqQuestionAnswerList';
import ShopCategoryList from '../../views/admin/shop-category/ShopCategoryList';
import ShopList from '../../views/admin/shop/ShopList';
import PackageList from '../../views/admin/package/PackageList';

export const adminMainRoutes = [
    {
        path: '/accounts',
        exact: true,
        component: AccountList,
        role: ['ROLE_ADMIN', 'ROLE_CUSTOMER'],
    },
    {
        path: '/WebPage/:id',
        exact: true,
        component: WebPageEdit,
        role: ['ROLE_ADMIN', 'ROLE_CUSTOMER', 'ROLE_PROJECT_ADMIN'],
    },
    {
        path: '/webPageTypes/:id',
        exact: true,
        component: PageTypeEdit,
        role: ['ROLE_ADMIN', 'ROLE_CUSTOMER', 'ROLE_PROJECT_ADMIN'],
    },
    {
        path: '/blog/:id',
        exact: true,
        component: BlogList,
        role: ['ROLE_ADMIN', 'ROLE_CUSTOMER'],
    },
    {
        path: '/shop/:id',
        exact: true,
        component: ShopList,
        role: ['ROLE_ADMIN', 'ROLE_CUSTOMER'],
    },
    {
        path: '/faqCategory/:id',
        exact: true,
        component: FaqCategoryList,
        role: ['ROLE_ADMIN', 'ROLE_CUSTOMER'],
    },
    {
        path: '/package/:id',
        exact: true,
        component: PackageList,
        role: ['ROLE_ADMIN', 'ROLE_CUSTOMER'],
    },
    {
        path: '/shopCategory/:id',
        exact: true,
        component: ShopCategoryList,
        role: ['ROLE_ADMIN', 'ROLE_CUSTOMER'],
    },
    {
        path: '/faqQuestionAnswer/:id',
        exact: true,
        component: FaqQuestionAnswerList,
        role: ['ROLE_ADMIN', 'ROLE_CUSTOMER'],
    },
    {
        path: '/locations',
        exact: false,
        strict: true,
        component: LocationList,
        role: ['ROLE_ADMIN', 'ROLE_CUSTOMER'],
    },
    {
        path: '/location/:id',
        exact: true,
        component: LocationEdit,
        role: ['ROLE_ADMIN', 'ROLE_CUSTOMER'],
    },
    {
        path: '/mapPins',
        exact: false,
        strict: true,
        component: MapPinList,
        role: ['ROLE_ADMIN', 'ROLE_CUSTOMER', 'ROLE_PROJECT_ADMIN'],
    },
    {
        path: '/pageTypes',
        exact: false,
        strict: true,
        component: PageTypeList,
        role: ['ROLE_ADMIN', 'ROLE_CUSTOMER', 'ROLE_PROJECT_ADMIN'],
    },
    {
        path: '/files',
        exact: true,
        component: DiskFileList,
        role: ['ROLE_ADMIN', 'ROLE_CUSTOMER', 'ROLE_PROJECT_ADMIN'],
    },
    {
        path: '/lang',
        exact: false,
        strict: true,
        component: LangList,
        role: ['ROLE_ADMIN', 'ROLE_CUSTOMER', 'ROLE_PROJECT_ADMIN'],
    },
    {
        path: '/settings/',
        exact: false,
        strict: true,
        component: SettingsIndex,
        role: ['ROLE_ADMIN', 'ROLE_CUSTOMER', 'ROLE_PROJECT_ADMIN'],
    },
    {
        path: '/projectManagement/:id',
        exact: true,
        component: ProjectManagementIndex,
        role: ['ROLE_ADMIN', 'ROLE_CUSTOMER', 'ROLE_PROJECT_ADMIN'],
    },
    {
        path: '/searchtypes/:parentId/:id',
        exact: true,
        component: SearchTypeEdit,
        role: ['ROLE_ADMIN', 'ROLE_CUSTOMER'],
    },
    {
        path: '/mainfacilities',
        exact: true,
        component: MainFacilityList,
        role: ['ROLE_ADMIN', 'ROLE_CUSTOMER'],
    },
    {
        path: '/facilities',
        exact: true,
        component: FacilityList,
        role: ['ROLE_ADMIN', 'ROLE_CUSTOMER'],
    },
    {
        path: '/facilitygroup/:id',
        exact: true,
        component: FacilityGroupEdit,
        role: ['ROLE_ADMIN', 'ROLE_CUSTOMER'],
    },
    {
        path: '/facilitygroup/:parentId/:id',
        exact: true,
        component: FacilityGroupEdit,
        role: ['ROLE_ADMIN', 'ROLE_CUSTOMER'],
    },
    {
        path: '/news',
        exact: true,
        component: BulletinList,
        role: ['ROLE_LOCAL_USER'],
    },
    {
        path: '/directory',
        exact: true,
        component: DirectoryContent,
        role: ['ROLE_ADMIN', 'ROLE_CUSTOMER', 'ROLE_CONTENT_UPLOAD', 'ROLE_PROJECT_ADMIN'],
    },
    {
        path: '/',
        exact: true,
        component: DefaultPage,
        role: ['ROLE_ADMIN', 'ROLE_CUSTOMER', 'ROLE_CONTENT_UPLOAD', 'ROLE_PROJECT_ADMIN'],
    },
    {
        path: '/file',
        exact: true,
        component: File,
        role: ['ROLE_ADMIN', 'ROLE_CUSTOMER', 'ROLE_CONTENT_UPLOAD', 'ROLE_PROJECT_ADMIN'],
    },
    {
        path: '/webPage/external/:id',
        exact: true,
        component: WebPageExternalAdd,
        role: ['ROLE_ADMIN', 'ROLE_CUSTOMER', 'ROLE_PROJECT_ADMIN'],
    },
    {
        path: '/webPage/externalLink/:id',
        exact: true,
        component: WebPageExternalLinkAdd,
        role: ['ROLE_ADMIN', 'ROLE_CUSTOMER', 'ROLE_PROJECT_ADMIN'],
    },
    {
        path: '/translations',
        exact: true,
        component: TranslationsList,
        role: ['ROLE_ADMIN'],
    },
    {
        path: '/domains',
        exact: true,
        component: DomainList,
        role: ['ROLE_ADMIN'],
    },
    {
        path: '/mobileApp',
        exact: true,
        component: MobileApp,
        role: ['ROLE_ADMIN', 'ROLE_MOBILE_APP_DOWNLOAD'],
    },
    {
        //6122
        path: '/compEdit',
        exact: true,
        component: ComponentList,
        role: ['ROLE_ADMIN'],
    },
    {
        path: '/problemReport/:projectId',
        exact: true,
        component: ReportedProblemList,
        role: ['ROLE_ADMIN', 'ROLE_PROJECT_ADMIN'],
    },
    {
        path: '/designSettings/:projectId',
        exact: true,
        component: DesignSettings,
        role: ['ROLE_ADMIN','ROLE_PROJECT_ADMIN'],
    },
];
